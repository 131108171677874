<template>
    <div>
        <loading v-if="loading" />
        <div v-else>
            <div class="d-flex justify-content-between mb-3 pt-2">
                <div class="d-flex">
                    <i class="far fa-temperature-high mr-2" style="font-size:18px;"></i> <h4 class="mb-0" style="margin-top:-2px;">{{$t('Temperature')}}</h4>
                </div>
                <div v-if="temperatures.total == 0">
                    <button type="button" @click="newTemperature" class="btn btn-primary">
                        {{$t('Add new')}}
                        <i class="far fa-plus-circle" />
                    </button>
                </div>
            </div>

            <temperature-form :visible="showForm" v-model="form" v-on:submit="onSubmit" v-on:close="onFormClose"/>
            <div v-if="!showForm" class="my-5">
                <empty-results v-if="temperatures.total === 0 && loading === false"  :subtitle="$t('No temperatures created')" />
                <div v-else class="row justify-content-between pb-2 text-center font-weight-bold">
                    <div class="col-3">{{ $t('Product name') }}</div>
                    <div class="col-2">{{ `${$t('Maximum temperature')} (${temperatures.results[0].unit ? temperatures.results[0].unit : 'F°'})` }}</div>
                    <div class="col-2">{{ `${$t('Maximum temperature')} (${temperatures.results[0].unit ? temperatures.results[0].unit : 'F°'})` }}</div>
                    <div class="col-3">{{ $t('Created at') }}</div>
                    <div class="col-2">{{ $t('Actions') }}</div>
                </div>
                <template v-for="item in temperatures.results">
                    <div class="card" :key="item.id">
                        <div class="card-body p-0">
                            <div class="row align-items-center p-4 text-center">
                                <div class="col-md-3 col-sm-1">{{ item.description }}</div>
                                <div class="col-md-2 col-sm-1">{{ item.max_temperature }}˚ {{ item.unit }}</div>
                                <div class="col-md-2 col-sm-1">{{ item.min_temperature }}˚ {{ item.unit }}</div>
                                <div class="col-md-3 col-sm-1">{{ item.created_at | timestamp('YYYY-MM-DD') }}</div>
                                <div class="col-md-2 col-sm-1">
                                    <div class="d-flex justify-content-sm-end px-2">
                                        <a  class=" cursor-pointer"  v-b-tooltip.hover :title="$t('Delete')"  @click="confirmDelete(item)" >
                                            <i class="far fa-trash-alt btn-icon text-danger" />
                                        </a>
                                        <a class=" cursor-pointer pl-2"  v-b-tooltip.hover :title="$t('Edit')"  @click="confirmEdit(item)" >
                                            <i class="far fa-edit btn-icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import TemperatureForm from '@/components/form/TemperatureForm';
import Swal from "sweetalert2";
import formMixin from '@/mixins/formMixin';


export default {
    name: 'TemperaturesTable',
    components:{
        TemperatureForm
    },
    props:{
        load: {
            type: Object
        }
    },
    data(){
        return {
            form: {unit: 'F', load_id: this.load.id},
            showForm: false,
            loading: true
        }
    },
    mixins: [formMixin],
    computed: {
        ...mapState('temperatures',['temperatures']),
        list(){
            return this.temperatures
        }
    },
    created(){
        this.fetchData();
    },
    methods: {
        ...mapActions('temperatures',['getTemperatures','storeTemperature','updateTemperature','deleteTemperature']),
        newTemperature(){
            this.showForm = true;
        },
        onFormClose(){
            this.showForm = false;
        },
        resetForm(){
            this.form = {unit: 'F', load_id: this.load.id}
        },
        confirmEdit(item){
            this.showForm = true
            this.form = _.clone(item)
        },
        fetchData(){
            this.loading = true;
            let payload = {load_id: this.load.id};
            this.getTemperatures(payload)
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit(payload){
            this.storeTemperature(payload)
                .then((data)=>{
                    this.fetchData();
                    this.resetForm();
                })
                .catch(error =>{
                    this.processServiceError(error);
                })
                .finally(()=>{
                    this.showForm = false;
                })
        },
        confirmDelete(item){
            Swal.fire({
              title: this.$t("Are you sure?"),
              text: this.$t("You won't be able to revert this!"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: this.$t("Yes, delete it!"),
              cancelButtonText: this.$t("Cancel")
            }).then(result => {
              if(result.value) {
                this.deleteTemperature({id: item.id, load_id: this.load.id})
                    .then(data=>{
                      this.fetchData();
                      this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("item temperature")}), {
                        title: this.$t('Success'),
                        variant: 'success',
                        solid: true
                      });
                    })
                    .catch(error =>{
                      Swal.fire(
                          "Error",
                          this.$t("Something went wrong!"),
                          "error"
                      );
                    });
              }
            });
          }
    }
}
</script>
<style scoped>
  .color_blue{
    color: #00B3E6;
    }
  .br{
      border-right: solid 2px #dddddd;
  }
</style>