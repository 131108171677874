import axios from "../../api/client";


// initial state
export const state = () => ({
    transactions : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    transaction: null,
    wallet: null,
    last_filters: {},
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchTransactions({commit}, query, wallet_id){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/wallets/${query.wallet_id}/transactions`,{params: query.filters})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTransactions({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/wallets/${query.wallet_id}/transactions`,{params: query.filters})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query.filters));
                    commit('setTransactions', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getWallet({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/wallets/${params.id}`)
                .then(response=>{
                    commit('setWallet', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getWalletByProfile({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/wallet`)
                .then(response=>{
                    commit('setWallet', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTransaction({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/wallets/${params.wallet_id}/transactions/${params.transaction_id}`)
                .then(response=>{
                    commit('setTransaction', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setTransactions(state, data){
        state.transactions = data;
    },
    setTransaction(state, data){
        state.transaction = data;
    },
    setWallet(state, data){
        state.wallet = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    }
};

