<template>
    <div class="card">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <th
                    v-for="title,index in columns"
                    :key="`title-${index}`"
                    >
                    {{title}}
                    </th>
                </thead>
                <tr v-if="rows.length == 0">
                    <td colspan="100%">
                        <empty-results title="" :subtitle="$t('No items')" />
                    </td>
                </tr>
                <tr
                v-for="row,index in rows"
                :key="`row-${row.field_id}-${index}`"
                >
                    <td
                    v-for="field, idx in row"
                    :key="`row-child-${row.field_id}-${index}-${idx}`"
                    >
                            <span v-if="row.type === 'boolean'">
                              <i  :class="[row.value === true ? 'fas fa-check-square text-primary':'far fa-square']" />
                            </span>
                            <span v-else>
                                {{field.value}}
                            </span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputDataTable',
    props: {
        rows: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            columns: []
        }
    },
    created(){
        if(this.rows.length > 0){
            let cols = [];
            for(let field of this.rows[0]){
                cols.push(field.title)
            }
            this.columns = cols;
        }
    }
}
</script>