<template>
    <b-modal 
        id="pos-modal" 
        ref="pos-modal"
        size="sm" 
        :title="$t('New location')"
        :visible="visible"
        hide-footer
        v-on:hidden="onClose"
        body-class="p-0"
        >
        <validation-observer ref="observer" v-slot="{ validate }">
            <b-form @submit.stop.prevent="onSubmit(validate)">
                <div class="row">
                    <div class="col-md-12">
                            <form-input
                                :title="$t('Latitude')"
                                v-model="form.latitude"
                                rules="required|min_value:-90|max_value:90"
                                type="number"
                                name="latitude"
                                layout="vertical"
                                :step="0.0000001"
                                :disabled="submit"
                            />
                            <form-input
                                :title="$t('Longitude')"
                                v-model="form.longitude"
                                rules="required|min_value:-180|max_value:180"
                                type="number"
                                name="longitude"
                                layout="vertical"
                                :step="0.0000001"
                                :disabled="submit"
                            />
                    </div>
                </div>

                <div class="d-flex justify-content-end m-2">
                    <button type="button" @click="onClose" :disabled="submit" class="btn btn btn-light mr-3 ">{{$t('Cancel')}}</button>
                    <button type="submit" :disabled="submit" class="btn btn-primary">{{$t('Save')}}</button>
                </div>

            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'IssuesModal',
    components:{
    },
    props:{
        visible: {
            type: Boolean,
            default(){
                return false;
            }
        },
        loadId: {
            type: [Number,String]
        }
    },
    data(){
        return {
           form: {},
           submit: false
        }
    },
    created(){
        this.resetForm();
    },
    methods: {
        ...mapActions('controltower',['storePosition']),
        resetForm(){
            this.form = {
                device_type: 'gps',
                reference_type: 'id',
                reference: this.loadId,
            }
        },
        onClose(){
            this.$bvModal.hide('pos-modal');
            this.$emit('close');
            this.resetForm();
        },
        async onSubmit(validate){
            if(!(await validate()) || this.submit){
                return;
            }
            this.submit = true;
            this.form.gps_datetime = this.$moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
            this.storePosition(this.form)
                .then(data=>{
                    this.onClose();
                    this.$emit('success');
                })
                .catch(error =>{

                })
                .finally(()=>{
                    this.submit = false;
                });
           
        }
    }
}
</script>