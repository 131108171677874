<template>
    <div>
        <filter-bar v-if="showFilterBar" :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved">
          <template  v-slot:right>
            <div class="btn-group" role="group" aria-label="toolbar">
              <button  v-b-tooltip.hover :title="$t('Refresh')" @click="fetchData()" type="button" class="btn btn-light">
                <i class="far fa-sync"></i>
              </button>
            </div>
          </template>
        </filter-bar>
        <loading v-if="fetchingIssues"/>
        <div class="issues_list" v-else>
          <div class="issues_item" v-for="(item, index) in list.results" :key="'item-'+index">

            <div class="card" >
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-md-1 col-sm-1">
                    <img height="35px" src="@/assets/images/txp/icon_desktop_incidencia.png"/>
                  </div>
                  <div class="col-md-2 col-sm-2">
                    <div>
                      <h6 class="text-muted">{{$t('Issue type')}}</h6>
                    </div>
                    <div>
                      <h6 class="one-line">{{item.type.name}}</h6>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3">
                    <div>
                      <h6 class="text-muted">{{$t('Description')}}</h6>
                    </div>
                    <div>
                      <h6 class="one-line">{{item.description}}</h6>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2">
                    <div>
                      <h6 class="text-muted">{{$t('Status')}}</h6>
                    </div>
                    <div>
                      <div>
                        <div class="badge" :class="['badge-'+issuesStatusLabel[item.status]]" v-if="canBeEditStatusIssues || !$can('issue.status') || item.status === 'closed' ">{{$t(item.status)}}</div>
                        <b-dropdown :id="'dropdown-'+item.id" class="" size="sm" :variant="issuesStatusLabel[item.status]" v-else>
                          <template v-slot:button-content>
                            {{$t(item.status)}}<i class="mdi mdi-chevron-down ml-2"></i>
                          </template>
                          <b-dropdown-item-button
                              @click="updateStatusIssue(status,item)"
                              :class="status===item.status?'active':''"
                              v-for="(status,index) in issuesStatus"
                              :key="item.id+'_'+index" >{{$t(status)}}</b-dropdown-item-button>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2">
                    <div>
                      <h6 class="text-muted">{{$t('Location')}}</h6>
                    </div>
                    <div>
                      <a v-if="item.address" target="_blank" :href="`https://www.google.com.mx/maps/@${item.address.latitude},${item.address.longitude}`" class="one-line">
                        {{item.address.latitude ? `${item.address.latitude},${item.address.longitude}` :'- -'}}
                      </a>
                      <div v-else>- -</div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2">
                    <div class="d-flex justify-content-sm-end px-2">
                      <a v-can="'issue.delete'" class="cursor-pointer text-black" :class="[item.status !== 'closed' ? '' : 'd-none']"  v-b-tooltip.hover :title="$t('Delete')"  @click="confirmDelete(item)"  v-if="canBeEdit(item.user_id)">
                        <i class="far fa-trash-alt btn-icon" />
                      </a>
                      <a v-can="'issue.update'" class="ml-4 cursor-pointer text-black" :class="[item.status !== 'closed' ? '' : 'd-none']" v-b-tooltip.hover :title="$t('Edit')"  @click="editIssueAction(item)"  v-if="canBeEdit(item.user_id)">
                        <i class="far fa-edit btn-icon"  />
                      </a>
                      <a class="ml-4 cursor-pointer" href="javascript:void(0);" @click="issueActive=null" v-if="issueActive==item.id">
                        <i class="far fa-chevron-circle-up btn-icon"></i>
                      </a>
                      <a  class="ml-4 cursor-pointer" href="javascript:void(0);" @click="showRow(item)" v-else>
                        <i class="far fa-chevron-circle-down btn-icon"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card" v-if="issueActive == item.id">
              <div class="card-body">
                <loading v-if="fetchingIssue"/>
                <div v-else class="main_content" >

                  <div class="row mb-3">
                    <div class="col-md-12">
                      <div class="field-wrapper border-bottom-none p-0">
                        <h6 class="field-label">{{$t('Description')}}</h6>
                        <div class="field-help">{{item.description}}</div>
                      </div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="row mx-1 field-row-top-radius">
                      <div class="col-md-12 text-center py-2">
                        <i class="far fa-search mr-2 text-primary" />{{$t('Details')}}
                      </div>
                    </div>
                    <div class="row mx-1 field-row" :key="'cusField'+idxRow" v-for="(row,idxRow) in customFields"
                            :class="{'field-row-bottom-radius': (idxRow+1) === customFields.length }" >
                      <div class="col-md-3 text-center py-1 field-col " :key="'cf'+idxRow+'_'+idxField" v-for="(field, idxField) in row">
                        <div v-if="field">
                          <div><b>{{field.title}}</b></div>
                          <div>
                            <span v-if="field.type === 'boolean'">
                              <i  :class="[field.value === true ? 'fas fa-check-square text-primary':'far fa-square']" />
                            </span>
                            <span v-else-if="field.type ==='table'">
                              <template v-if="field.value.length == 0">
                                - -
                              </template>
                              <template v-else>
                                <a href="javascript:void(0)" @click="showDataTable(`data-table-modal-${field.field_id}`)">{{$t('Show table')}}</a>

                                <b-modal
                                    :id="`data-table-modal-${field.field_id}`"
                                    :title="field.title"
                                    hide-footer
                                    size="lg"
                                    >
                                    <input-data-table :rows="field.value" />
                                </b-modal>
                              </template>

                            </span>
                            <span v-else>
                              {{field.value}}
                            </span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div >
                        <h6>Comentarios</h6>
                        <loading v-if="fetchingComment"/>
                        <div v-else class="comments_container">
                          <div class="" v-if="issueComments.length"  >
                            <vue-perfect-scrollbar :ref="'commentList'" class="scroll main-board" :settings="{ suppressScrollX: true, wheelPropagation: false }" @ps-y-reach-end="handleScroll">
                              <comment-component v-for="(comment,index) in issueComments" :resource_id="load_id"  v-on:delete="deleteCommentAction" v-on:updateComments="fetchComment"  :key="comment.id" :comment="comment"/>
                            </vue-perfect-scrollbar>
                          </div>
                          <empty-results v-else/>
                          <div class="d-flex justify-content-end">
                            <a @click="addComment=true" class="btn btn-link "> <i class="far fa-plus"  />  {{$t("Add Comment")}}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h6>Pruebas fotograficas</h6>
                      <div class="gallery_container">
                        <gallery-component :images="issue.files"></gallery-component>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div  v-if="list.total > 0"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results  :subtitle="$t('No events to show')"/>
            </div>
        </div>

      <issue-modal :issue_id="dataEdit" :load_id="load_id" :update="true" :visible="editIssue"  v-on:updateData="updateData" />
      <edit-comment :visible="addComment" :update="false"  :resource_id="load_id"  :owner_id="issueActive"   v-on:closeModal="closeModalCreateComment"   ></edit-comment>

    </div>
</template>

<script>

import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import addressFormat from '@/helpers/address-format';
    import GalleryComponent from "../GalleryComponent";
    import CommentComponent from "../CommentComponent";
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import IssueModal from '@/components/modals/EditIssue'
    import Swal from "sweetalert2";
    import EditComment from "../modals/EditComment";
    import Multiselect from 'vue-multiselect'
import filterMixin from "../../mixins/filterMixin";
import FilterBar from "../filters/FilterBar";
import InputDataTable from "./InputDataTable"


    export default {
        name: "IssuesTable",
        mixins:[selectAllMixin,filterMixin],
        components: {
          EditComment,
          CommentComponent,
          GalleryComponent,
          VuePerfectScrollbar,
          IssueModal,
          Multiselect,
          FilterBar,
          InputDataTable
        },
        props:{
          showFilterBar:{
            type: Boolean,
            default(){
              return true;
            }
          },
          loadId:{
            type: [String, Number],
            required: true
          }
        },
        data(){
            return {
                page: 1,
                init: false,
                editIssue: false,
                addComment: false,
                selected: [],
                statusModel: [],
                issueComments: [],
                issue:null,
                issueActive:null,
                load_id: this.loadId,
                fetchingComment: false,
                fetchingIssue: false,
                fetchingIssues: false,
                dataEdit: null,
                customFields: []
            }
        },
        computed: {
            ...mapState('issues',['issues','fetching']),
            ...mapState('loads',['load']),
            ...mapState('profile', ['profile']),
            ...mapGetters('reverselogistic', ['issueCatalogs']),
            list(){
                return this.issues
            },
            issuesStatus(){
                return ["open","in_progress","closed"];
            },
            options(){
              return [{
                icon: 'far fa-key',
                label: 'ID',
                type: 'text',
                filterProperty: 'id',
              }]
            },
            canBeEditStatusIssues(){
                return this.load.status === "pod"
            },
            issuesStatusLabel(){
                return {
                  "open": "outline-danger",
                  "in_progress": "outline-info",
                  "closed": "outline-success",
                };
            }
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
            this.fetchData();
            if (this.$route.query.id){
              let issueparam = this.$route.query.id;
              this.toolbarFilters.id = issueparam;
              this.showRow({id: issueparam})
            }
        },
        mounted() {
          Echo.private(`loads.${this.load_id}`)
              .listen('CreateIssueEvent', (e) => {
                if(this.load_id && this.profile.id !== e.issue.user_id)
                  this.fetchData();
              })
              .listen('CreateCommentEvent', (e) => {
                if (this.load_id === e.load_id && this.issueActive === e.issueData.id && this.profile.id !== e.commentData.user.id){
                  this.fetchComment()
                }
              });
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal) || this.init === false){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.fetchData()
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('issues',['getIssues','getIssue','getComments','deleteIssues','deleteComment','updateIssue']),
            ...mapMutations("issues",["updateIssueState"]),
            ...mapActions('loads', ['getStatus', 'getLoad', 'getEvents']),
            ...mapActions('reverselogistic', ['getReverseLogistics']),
            fetchData(loading = true,callback=null){
                let filters = _.cloneDeep(this.filters);
                filters.page = this.page
                filters.load_id =this.load_id
                this.fetchingIssues =loading?true:false;
                this.getIssues(filters)
                    .then(response=>{

                    })
                    .catch(error =>{

                    })
                    .finally(()=>{
                      this.fetchingIssues = false;
                      this.init = true;
                    })
            },
            fetchIssue(loading = true){
              this.fetchingIssue = loading?true:false;
              this.getIssue({id:this.issueActive})
                  .then(data=>{
                    this.issue = data;
                    let rows = [[]];
                    let idx = 0;
                    for(let field of this.issue.custom_fields){
                      if(rows[idx].length < 4){
                        rows[idx].push(field)
                      }else{
                        idx++
                        rows[idx] = [field]
                      }
                    }
                    do{
                      rows[rows.length -1 ].push(null)
                    }while(rows[rows.length -1 ].length < 4);


                    this.customFields = rows;
                  })
                  .catch(error =>{

                  })
                  .finally(error =>{
                    this.fetchingIssue = false;
                  })
            },
            fetchComment(){
              this.getComments({load_id: this.load_id,issue_id: this.issueActive})
                  .then(data=>{
                    this.issueComments = data.results;
                    this.scrollComment()
                  })
                  .catch(error =>{

                  })
                  .finally(error =>{
                    this.fetchingComment = false;
                  })
            },
            showRow(item){
                this.issueActive = item.id;
                this.fetchingComment = true;
                this.fetchingIssue = true;
                this.fetchIssue(this.issueActive);
                this.fetchComment()
            },
            closeModalCreateComment(data){
              this.addComment = false;
              if (data.updated){
                this.fetchComment()
              }
            },
            confirmDelete(item){
            Swal.fire({
              title: this.$t("Are you sure?"),
              text: this.$t("You won't be able to revert this!"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: this.$t("Yes, delete it!"),
              cancelButtonText: this.$t("Cancel")
            }).then(result => {
              if(result.value) {
                this.deleteIssues({...{id: item.id},...{load_id: this.load_id}})
                    .then(data=>{
                      this.fetchData(false);
                      this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Comment").toLowerCase()}), {
                        title: this.$t('Success'),
                        variant: 'success',
                        solid: true
                      });
                    })
                    .catch(error =>{
                      Swal.fire(
                          "Error",
                          this.$t("Something went wrong!"),
                          "error"
                      );
                    });
              }
            });
          },
            handleScroll(item){

            },
            editIssueAction(model){
              this.$router.push({name: 'loads.detail.issues.edit', params:{issue_id: model.id}});
            },
            deleteCommentAction(data){
              this.deleteComment(data)
                  .then(data=>{
                    this.fetchComment()
                    this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Issue").toLowerCase()}), {
                      title: this.$t('Success'),
                      variant: 'success',
                      solid: true
                    });
                  })
                  .catch(error =>{
                    Swal.fire(
                        "Error",
                        this.$t("Something went wrong!"),
                        "error"
                    );
                  });
            },
            updateData(data){
              this.editIssue = false
              if (data.updated){
                this.fetchData(false);
                if (this.issueActive === data.id){
                    this.fetchIssue(false)
                }
              }
            },
            canBeEdit(user_id){
              return this.profile.id === user_id || this.profile.roles.includes("admin");
            },
            updateStatusIssue(status,item){
              let issue = { id: item.id , load_id: item.load_id, status: status }
              this.updateIssue(issue).then(()=>{
                this.updateIssueState({issue:item.id,status:status});
              }).catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              }).finally(() => {
                  if(status === 'closed' && this.issueCatalogs.some(e => e.id === item.type.id)){
                    this.getStatus({id : this.$route.params.load_id}).then(data => {}).catch(error => {});
                    this.getLoad({id : this.$route.params.load_id}).then(data => {}).catch(error => {});
                    this.getEvents({id : this.$route.params.load_id}).then(data => {}).catch(error => {});
                  }
              });
            },
            showDataTable(id){
              this.$bvModal.show(id);
            }
        },
        created() {
            if(this.issueCatalogs.length === 0){
                this.getReverseLogistics({id : this.$route.params.load_id})
                    .then(data => {}).catch(error => {});
            }
        }
    }
</script>

<style scoped>
  .main-board{
    height: calc(350px);
  }
  .text-black{
    color: #343a40!important;
  }
  .active{
    background-color: #F2F2F2!important
  }

  .field-row:nth-of-type(2n){

    border-left: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
  }

  .field-row:nth-of-type(2n+1){
    background: #F2F2F2;
    border-bottom: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
  }

  .field-row-bottom-radius{
    border-radius: 0 0 20px 20px;
  }

  .field-row-top-radius{
    border: 1px solid #DDDDDD;
    border-radius: 20px 20px 0 0;
  }

  .field-col{
    border-right: 1px solid #DDDDDD;
  }

  .field-row-bottom-radius>.field-col:last-child{
    border-radius: 0 0 20px 20px;
  }


</style>
