<template>
    <div >
        <navbar title="Equipo añadido" class="border-bottom" :total="equipments.length" />
        <div class="equipmentContainer">
            <empty-results
                title=""
                :subtitle="$t('You have not added equipment')"
                class="font-size-15 mt-4 text-gray-888"
                v-if="equipments.length == 0"
            />
            <div class="row line-height-18 font-size-13">
                <div class="rectangle offset-1 col-10 mt-3 p-3" v-for="(equipment, index) in equipments" :key="index">
                    <div class="row">
                        <div class="col-3 text-right text-gray-888 p-0">
                            <div>{{$t('Equipment')}} {{index+1}}:</div>
                            <div>{{ $t("Information") }}:</div>
                        </div>
                        <div class="col-8">
                            <div class="row">
                                <div class="col-12">
                                    <div class="font-weight-bolder">
                                        {{ equipment.name }}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div>{{ equipment.configuration_code }}</div>
                                    <div>{{ trailerName(equipment.trailer_type_code) }}</div>
                                    <div>{{ equipment.trailer_size_code }}</div>
                                </div>
                                <div class="col-6 d-none">
                                    <div>{{$t('Truck code')}}: {{ equipment.truck_type_code }}</div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-1 d-flex justify-content-center align-items-center"
                            role="button"
                            @click="removeEquipment(index)"
                            v-if="step == 1"
                        >
                            <i class="ri-close-fill font-size-18 font-weight-bolder text-primary"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mb-1" style="margin-top: calc(5%);">
            <router-link
                :to="{name:'welcome'}"
                class="btn btn-light text-uppercase font-size-12 font-weight-bolder px-4"
            >
                {{$t('Cancel')}}
            </router-link>
            <button
                type="button"
                class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder px-4 ml-3"
                :class="[equipments.length == 0 && 'disabled']"
                @click="setStep(step+1)"
            >
                {{$t('Continue')}}
            </button>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import Navbar from '@/views/recommendations/Navbar.vue';

export default {
    name: "Equipments",
    components: { Navbar },
    props: {
        equipments: { type: Array },
        removeEquipment: { type: Function },
    },
    computed: {
        ...mapState('recommendations', ['step']),
        ...mapGetters('catalogs', ['truck_types_marketplace', 'trailer_types_marketplace']),
    },
    methods: {
        ...mapMutations('recommendations', ['setStep']),
        trailerName(code){
            return this.trailer_types_marketplace.find(e => e.external_id === code).name;
        }
    }
}
</script>

<style lang="css" scoped>

.rectangle {
    width: 450px;
    border-radius: 20px;
    background-color: #F2F2F2;
}

.title{
    padding: 0.8rem !important;
}

.disabled{
    pointer-events: none !important;
}

.h-300{
    height: 300px;
}
.equipmentContainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  overflow-y: scroll;
  height: 300px;
}
.equipmentContainer::-webkit-scrollbar {
    display: none;
}
</style>
