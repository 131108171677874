export default [{
    path:'shipments',
    name:'shipments',
    redirect: {name: 'shipments.index'},
    component: () => import('../views/Content'),
    meta: {
        breadcrumb:{text:'Shipments', to:{name:'shipments.index'}}
    },
    children: [
        {
            path: '',
            name: 'shipments.index',
            component: () => import('../views/shipments/Shipments'),
            meta: {
                filters:{}, bgWhite: true,
                title: 'Shipments'
            },

        },
        {
            path: 'add',
            name: 'shipments.add',
            component: () => import('../views/Wrapper'),
            redirect: { name: 'shipments.add.index' },
            meta: { title: 'Shipment' },
            children: [
                {
                    path: '',
                    name: 'shipments.add.index',
                    component: () => import('../views/shipments/ShipmentForm'),
                    meta: { title: 'Shipment' },
                },
                {
                    path: 'ftl',
                    name: 'shipments.add.ftl',
                    component: () => import('../views/shipments/ShipmentFTL'),
                    meta: { title: 'Shipment', bgWhite: true },
                },
            ],
        },
        {
            path: ':load_id',
            name: 'shipments.detail',
            component: () => import('../views/shipments/ShipmentContainer'),
            redirect:{name:'shipments.detail.general'},
            meta: {
                title: 'Shipment', breadcrumb:{text:'Shipment', param: 'load_id', to:{name:'shipments.detail.general'}}
            },
            children: [
                {
                    path: 'general',
                    name: 'shipments.detail.general',
                    component: () => import('../views/shipments/ShipmentGeneral'),
                    meta: {title: 'Shipment',type: 'shipment'},
                },
                {
                    path: 'events',
                    name: 'shipments.detail.events',
                    meta: {title: 'Shipment'},
                },
                {
                    path: 'pod',
                    name: 'shipments.detail.pod',
                    component: () => import('../views/loads/POD'),
                    meta: {title: 'Shipment'},
                },
                {
                    path: 'checklist',
                    name: 'shipments.detail.checklist',
                    component: () => import('../views/Wrapper'),
                    redirect: {name: 'shipments.detail.checklist.index'},
                    children: [
                        {
                            path: '',
                            name:'shipments.detail.checklist.index',
                            component: () => import('../views/shipments/Checklist'),
                            meta: {title: 'Shipment', type: 'shipment'},
                        },
                        {
                            path: ':stop_id/add',
                            name:'shipments.detail.checklist.add',
                            component: () => import('../views/loads/ChecklistForm'),
                            meta: {title: 'Checklist', type: 'shipment'},
                        },
                        {
                            path: ':stop_id/:checklist_id/edit',
                            name:'shipments.detail.checklist.edit',
                            component: () => import('../views/loads/ChecklistForm'),
                            meta: {title: 'Edit', type: 'shipment'},
                        }
                    ]
                },
                {
                    path: 'documents',
                    name: 'shipments.detail.documents',
                    component: () => import('../views/shipments/ShipmentDocument'),
                    meta: {type: 'legal_doc', title: 'Documents'}
                },
                {
                    path: "extracosts",
                    name: "shipments.detail.extracosts",
                    component: () => import("../views/loads/Extracosts"),
                    meta: { title: "Extra charge", bgWhite: true },
                  },
                {
                    path: 'tenders',
                    name: 'shipments.detail.tenders',
                    component: () => import('../views/loads/LoadTender'),
                    meta: {title: 'Shipment'},
                },
                {
                    path: 'loads',
                    name: 'shipments.detail.loads',
                    meta: {title: 'Shipment',filters:{}, bgWhite: true},
                    component: () => import('../views/shipments/ShipmentLoads')
                },
                {
                    path: 'issues',
                    name: 'shipments.detail.issues',
                    meta: {title: 'Shipment' , type: 'shipment'},
                    component: () => import('../views/issues/Issues')
                },
                {
                    path: 'merchandises',
                    name: 'shipments.detail.merchandises',
                    meta: {title: 'Shipment' },
                    component: () => import('../views/merchandise/Merchandises')
                },
                {
                    path: 'externalrequirements',
                    name: 'shipments.detail.externalrequirements',
                    meta: {title: 'Shipment' },
                    component: () => import('../views/externalrequirements/externalRequirements')
                },
            ]
        },
        {
            path: ':load_id/edit',
            name: 'shipments.edit',
            component: () => import("../views/shipments/ShipmentForm"),
            meta: {
                title: 'Edit shipment', breadcrumb:{text:'Edit shipment',param:'load_id', to:{name:'shipments.index'}}, routes:{list:'shipments.detail'}
            }
        },
    ]
}];
