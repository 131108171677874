import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'

const clearCookies = () => {
    const date = new Date();
    // yesterday
    date.setDate(date.getDate() - 1);
    document.cookie
        .split(';')
        .forEach(
            c =>
                (document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${date.toUTCString()};path=/`)),
        );
}

function getUser() {
    return new Promise(async (resolve, reject) => {
        Auth.currentAuthenticatedUser()
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}

async function getAccessToken() {
    return (await Auth.currentSession()).getAccessToken().getJwtToken();
}

async function getEmailVerified() {
    return await Auth.userAttributes(await Auth.currentAuthenticatedUser()).then(e=>{
        let attr = e.filter(i=>i.Name==="email_verified");
        return attr?attr[0].Value:false
    }).catch(()=>{
        return false
    })
}

function signUp(username, password,attrs) {
    let params = {
        username,
        password,
        attributes: attrs,
        autoSignIn: {
            enabled: true,
        }
    };
    return new Promise(async (resolve, reject) => {
        Auth.signUp(params)
        .then(data => {
            signIn(username,password).then((user)=>{
                resolve({...user,...data});
            }).catch((err)=>{
                reject(err)
            })
        })
        .catch(err => {
            reject(err)
        });
    });
}


function resendSignUp(username) {
    return Auth.resendSignUp(username).then(() => {
        return 'SUCCESS';
    }).catch(err => {
        console.log(err);
        return err;
    });
}

async function signIn(username, password) {
    return new Promise(async (resolve, reject) => {
        const user = await Auth.signIn(username, password).then((user)=>{
            if(user.challengeName === "NEW_PASSWORD_REQUIRED") {
                resolve({NEW_PASSWORD_REQUIRED:true, user: user})
            }else{
                resolve({NEW_PASSWORD_REQUIRED:false, user: user})
            }
        }).catch((err)=>{
            reject({ success:false, code: err.code, message: err.message  })
        })
    });
}

async function refreshToken() {
    return new Promise(async (resolve, reject) => {
        const session = await Auth.currentSession();
        const user = await Auth.currentAuthenticatedUser();
        user.refreshSession(session.getRefreshToken(),()=>{
            resolve();
        });
    });
}

function signOut() {
    return Auth.signOut()
        .then(data => {
            AmplifyEventBus.$emit('authState', 'signedOut');
            clearCookies()
            return data;
        })
        .catch(err => {
            console.log(err);
            return err;
        });
}

function forgotPassword(username){
    return new Promise(async (resolve, reject) => {
        Auth.forgotPassword(username)
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}



function sendEmailConfirmation(username){
    return new Promise(async (resolve, reject) => {
        Auth.verifyCurrentUserAttribute('email')
            .then(() => {
                resolve()
            }).catch((e) => {
                reject(e)
            });
    });
}


function submitEmailConfirmation(code){
    return new Promise(async (resolve, reject) => {
       await Auth.verifyCurrentUserAttributeSubmit('email',code)
            .then(() => {
                resolve()
            }).catch((e) => {
                reject(e)
            });
    });
}


function resetPassword(username, code, new_password){
    return new Promise(async (resolve, reject) => {
        Auth.forgotPasswordSubmit(username, code, new_password)
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}

function completeNewPassword(user,new_password){
    return new Promise(async (resolve, reject) => {
        Auth.completeNewPassword(user, new_password)
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}


function updatePassword(oldPassword,newPassword){
    return new Promise(async (resolve, reject) => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}


export {
    getUser, signUp, resendSignUp, signIn,
    signOut,refreshToken,forgotPassword,
    resetPassword,completeNewPassword,getAccessToken,
    getEmailVerified,sendEmailConfirmation,submitEmailConfirmation,
    updatePassword
};