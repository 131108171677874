<template>
    <date-picker :disabled="disabled" v-model="date"  :type="type" :placeholder="placeholder">
        <template v-slot:icon-calendar>
            <i :class="[icon]" style="font-size: 18px;" />
        </template>
    </date-picker>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    export default {
        name: "DateInput",
        components:{
            DatePicker
        },
        props:{
            type:{
                default(){
                    return 'date'
                }
            },
            format: {
                type:String,
                default(){
                    return 'YYYY-MM-DD';
                }
            },
            placeholder:{
                type: String,
                default(){
                    return '';
                }
            },
            icon: {
                type:String,
                default(){
                    return 'far fa-calendar-alt';
                }
            },
            value:{
                required: true
            },
            disabled:{
                type : Boolean,
                default(){
                    return false;
                }
            },
        },
        computed:{
            formattedValue(){
                if(!this.date ) return null;
                return this.$moment(this.date).format(this.format)
            }
        },
        watch:{
            date(){
                this.$emit('input', this.formattedValue);
                this.$emit('change', this.formattedValue);
            },
            value(newValue, oldValue){
                if(!_.isEqual(newValue, oldValue))
                    this.date = this.$moment(newValue,  this.format).toDate();
            }
        },
        data(){
            return {
                date: null
            }
        },
        created() {
            if(this.value){
                this.date = this.$moment(this.value, this.format).toDate();
            }
        }
    }
</script>

<style scoped>

</style>
