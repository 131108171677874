<template>
  <div class="time-picker-container">
    <outside-click-handler :focused="focused">
      <time-picker-modal
          :init-hour="hour" :init-minute="minute" slot="modal"
          :handle-hour-change="handleHourChange" :handle-minute-change="handleMinuteChange"
          :handle-time-change="handleTimeChange" v-if="focused"
      />
    </outside-click-handler>
  </div>
</template>

<script>
import OutsideClickHandler from './OutsideClickHandler'
import TimePickerModal from './TimePickerModal'
export default {
  components: {
    OutsideClickHandler,
    TimePickerModal
  },
  props: {
    value:{
      required: true
    },
    mode: {
      type: [String, Number],
      default: '24',
      validator: value => value === 24 || value === 12
    },
    defaultHour: {
      type: [String, Number],
      default: new Date().getHours(),
      validator: value => value >= 0 && value < 24
    },
    defaultMinute: {
      type: [String, Number],
      default: 0,
      validator: value => value >= 0 && value < 60
    },
    defaultFocused: {
      type: Boolean,
      default: false,
    },
    onFocusChange: {
      type: Function,
      default: () => {}
    },
    onHourChange: {
      type: Function,
      default: () => {}
    },
    onMinuteChange: {
      type: Function,
      default: () => {}
    },
    onTimeChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      hour: this.defaultHour,
      minute: this.defaultMinute,
      focused: true,
      overElement: false,
      selected : null,
    }
  },
  computed: {
    hourString() {
      return this.hour < 10 ? '0' + this.hour : this.hour + ''
    },
    minuteString() {
      return this.minute < 10 ? '0' + this.minute : this.minute + ''
    }
  },
  created() {
    this.selected = {
      hour: this.hour,
      minute: this.minute
    };
  },
  methods: {
    handleHourChange(hour) {
      this.hour = hour
      this.onHourChange && this.onHourChange(this.hour)
      this.handleTimeChange({
        hour: this.hour
      })
    },
    handleMinuteChange(minute) {
      this.minute = minute
      this.onMinuteChange && this.onMinuteChange(this.minute)
      this.handleTimeChange({
        minute: this.minute
      })
    },
    handleTimeChange(timeObj) {
      this.hour = timeObj['hour'] ? timeObj['hour'] : this.hour
      this.minute = timeObj['minute'] ? timeObj['minute'] : this.minute
      this.onTimeChange && this.onTimeChange({
        hour: this.hour,
        minute: this.minute
      })
    }
  },
  watch:{
    selected: {
      deep:true,
      handler(newValue, oldValue){
        this.$emit('input', newValue);
        this.$emit('change', newValue);
      }
    },
    value: {
      deep:true,
      handler(newValue){
        this.selected = newValue;
      }
    }
  }
}
</script>

<style lang="css">
.time-picker-container {
  position: relative;
}

</style>
