<template>
    <div class="px-3 mt-3">
        <truck-loading v-if="fetching" />
        <div class="truck_table" v-else >
        <div class="truck_table_item " v-for="item in list.results" :key="'user-'+item.id">
          <div class="truck_table_item_title">
            <div class="truck_table_item_title_checkbox">
              <b-form-checkbox
                  v-model="selected"
                  name="checkbox-1"
                  :value="item.id"
              >
              </b-form-checkbox>
            </div>
            <div class="truck_table_item_title_icon">
              <div class="truck_table_item_title_icon_avatar">

              </div>
              <div class="truck_table_item_title_icon_span">
                  <span class="badge " :class="['badge-outline-'+ (item.enabled ? 'success':'danger')]">
                                <i class="far " :class="[(item.enabled ? 'fa-check-circle':'fa-times-circle')]" />
                                {{item.enabled ? $t('enabled'):$t('disabled')}}
                  </span>
              </div>
            </div>
            <div class="truck_table_item_title_name">
              <p class="text-muted mb-2 ">ID: <span class="font-weight-semibold">{{item.id}}</span>  <template v-if="item.tms_id"> TMS ID: <span class="font-weight-semibold">{{item.tms_id}}</span></template>   </p>
              <router-link v-can="'trucks.read'" :to="{name: 'trucks.detail', params:{truck_id: item.id}}" class="mr-2">
                <h5 class="text-primary font_bold font-size-15 text-uppercase ">{{item.type ? item.type.name : '- -'}}</h5>
              </router-link>
            </div>
          </div>
          <div class="truck_table_item_detail">
            <div class="truck_table_item_detail_details">
              <div class="truck_table_item_detail_details_item" v-if="$route.params.profile === 'admin'">
                <div class="truck_table_item_detail_details_item_label">{{$t('Carrier')}}</div>
                <div class="truck_table_item_detail_details_item_value font_semi_bold">
                  <router-link v-if="item.carrier" :to="{name:'carriers.detail',params:{carrier_id: item.carrier.id}}" >
                    <div class="two-lines">
                      {{item.carrier.name}}
                    </div>
                  </router-link>
                  <span v-else>- -</span>
                </div>
              </div>
              <div class="truck_table_item_detail_details_item">
                <div class="truck_table_item_detail_details_item_label">{{$t('Brand')}}</div>
                <div class="truck_table_item_detail_details_item_value font_semi_bold">{{item.brand}}</div>
              </div>
              <div class="truck_table_item_detail_details_item" >
                <div class="truck_table_item_detail_details_item_label">{{$t('Model')}}</div>
                <div class="truck_table_item_detail_details_item_value font_semi_bold">{{item.model}}</div>
              </div>
              <div class="truck_table_item_detail_details_item">
                <div class="truck_table_item_detail_details_item_label">{{$t('Year')}}</div>
                <div class="truck_table_item_detail_details_item_value font_semi_bold">{{item.year}}</div>
              </div>
              <div class="truck_table_item_detail_details_item">
                <div class="truck_table_item_detail_details_item_label">{{$t('Plate')}}</div>
                <div class="truck_table_item_detail_details_item_value font_semi_bold">{{item.plate}}</div>
              </div>
              <div class="truck_table_item_detail_details_item">
                <div class="truck_table_item_detail_details_item_label">{{$t('Configuration')}}</div>
                <div class="truck_table_item_detail_details_item_value font_semi_bold">{{item.configuration ? item.configuration.name :'- -'}}</div>
              </div>
            </div>
            <div class="truck_table_item_detail_actions text-black">
              <template v-if="!isDocumentModule">
                <router-link v-can="'trucks.update'" :to="{name: edit ? edit :'trucks.edit', params:{truck_id: item.id}}" class="mr-2">
                  <i class="far fa-edit btn-icon text-black"></i>
                </router-link>

                <a v-can="'trucks.update'" v-b-tooltip.hover :title="item.enabled ? $t('disable'):$t('enable')" style="cursor: pointer;" @click="confirmActivateDesactivate(item)" :class="item.enabled ? 'text-danger mr-2' : 'mr-2'" >
                  <i class="text-black" :class="[item.enabled ? 'far fa-thumbs-down' : 'far fa-thumbs-up']" style="font-size:20px;" />
                </a>
              </template>
              <template v-else>
                <router-link v-can="'documents.read'" :to="{name:'documents.resource.detail', params:{resource: 'trucks', resource_id: item.id}}" class="mr-2">
                  <i v-b-tooltip.hover :title="$t('Documents')" class="far fa-book-alt btn-icon text-black"  />
                </router-link>
              </template>


            </div>
          </div>
        </div>
      </div>
        <div  v-if="list.total > 0"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    import Loading from "../common/Loading";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import Swal from "sweetalert2";
    import TruckLoading from "../common/loadings/TruckLoading.vue";

    export default {
        name: "TrucksTable",
        mixins:[selectAllMixin],
        components: {
          TruckLoading,
            'loading':Loading
        },
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            },
            edit: {
                type: String
            },
            isDocumentModule: null
        },
        data(){
            return {
                page: 1,
                init: false,
                truck: null,
                fetchingTruck: false,
            }
        },
        computed: {
            ...mapState('trucks', ['last_filters', 'trucks','fetching']),
            ...mapState('profile',['profile']),
            list(){
                return this.trucks
            }
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal) || this.init === false){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('trucks',['getTrucks','getTruck','deleteTruck','updateTruck']),
            fetchData(){
                this.getTrucks(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.init = true;
                    })
            },
            showRow(load){
                this.fetchingLoad = true;
                this.getTruck({id: load.id})
                    .then(data=>{
                    })
                    .catch(error =>{
                    })
                    .finally(error =>{
                        this.fetchingLoad = false;
                    })
            },
            confirmDelete(item){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteTruck({id: item.id})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Truck").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                        });
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            },
            confirmActivateDesactivate(item){
                let action = item.enabled ? 'desactivate' : 'activate'
                let status = item.enabled ? 0 : 1
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t(`Yes, ${action} it!`),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.updateTruck({id: item.id, status: status})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t(`Your item has been ${action}d.`, {resource: this.$t("Truck").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                    });
                            })
                            .catch(data=>{
                                Swal.fire(
                                    "Error", 
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .truck-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }




</style>


<style lang="scss">

.truck_table{
  &_item{
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid hsla(0,0%,86.7%,.4117647059)!important;
    box-shadow: 0 6px 10px hsl(0deg 0% 80% / 50%);
    margin-bottom: 20px;
    border-right: 1px solid hsla(0,0%,86.7%,.4117647059);
    &_title{
      padding: 15px 10px;
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      &_checkbox{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
      }
      &_icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(30% + 40px);
        flex-wrap: wrap;
        &_span{
          display: block;
          width: 100%;
          text-align: center;
          padding-top: 10px;
        }
      }
      &_name{
        width: calc(65% + 39px);
      }
    }
    &_detail{
      padding: 15px 10px;
      background: #f9f9f9;
      width: calc(70%);
      display: flex;
      justify-content: center;
      align-items: center;
      &_details{
        width: calc(100% - 85px);
        display: flex;
        justify-content: flex-start;
        &_item{
          flex: 1;
          text-align: center;
          &_label{
            color: #666666!important;
          }
          &_value{

          }
        }
      }
      &_actions{
        width: 80px
      }
    }
  }
}


@media (max-width: 992px) {
  .truck_table_item{
    flex-wrap: wrap!important;
  }
  .truck_table_item_title{
    width: 100%!important;
  }
  .truck_table_item_detail,.truck_table_item_detail_details{
    width: 100%!important;
    flex-wrap: wrap!important;
  }
  .truck_table_item_detail_details_item{
    padding-top: 15px;
    width: 50%!important;
    flex: auto!important;
  }
  .truck_table_item_detail_actions{
    width: 100%;
    text-align: center;
    padding-top: 15px;
  }
}


</style>

