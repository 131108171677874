<template>
    <div class="mt-4">
      <loading v-if="loading"></loading>
      <div class="" v-else>
          <div v-if="count" class="loadAssociated">
            <div class="loadAssociatedGroup" >
              <div class="loadAssociatedItem pl-3" v-for="(subload,subindex) in loads" :key="'group_'+subindex">
                <div class="row">
                  <div class="col-md-6 d-flex align-items-center">
                    <div class="badge  badge-outline-primary">{{$t(subload.type)}}</div>
                  </div>
                </div>
                <div class="row mt-2 ">
                  <div class="col-md-6">
                    <router-link v-if="$route.params.profile !== 'shipper'" target="_blank" :to="{name:'loads.detail.general', params:{load_id: subload.id}}">
                      <b class="btn btn-link p-0 font-size-17 font-weight-bold">{{subload.id}}</b> <br>
                    </router-link>
                    <b v-else class=" p-0 font-size-17 font-weight-bold">{{subload.id}}</b> <br>
                  </div>
                </div>
                <div class="table_loads mt-3">
                  <div class="row">
                    <div class="col-md-2 h30 position-relative text-center p-2">
                      <div class="font-weight-extra-bold font-size-14 ">{{ $t("TMS ID") }} <span class="text-muted font-size-12 ">  {{subload.tms_id}}</span></div>
                      <div class="division"></div>
                    </div>
                    <div class="col-md-4 h30 position-relative text-center p-2">
                      <div class="division"></div>
                      <div class="font-weight-extra-bold font-size-14 color_blue ">{{$t("Carrier")}}: <span class="text-muted font-size-12 ">  {{subload.carrier.name}}</span></div>
                    </div>
                    <div class="col-md-4 h30 position-relative text-center p-2">
                      <div class="division"></div>
                      <div class="font-weight-extra-bold font-size-14 color_blue d-flex align-items-center justify-content-center ">
                        <avatar :item="subload.driver?subload.driver:{}" size="20" class="mr-2" :key="'avatar'+subload.id"></avatar>
                        {{$t("Driver")}}:  <span class="text-muted font-size-12 "> &nbsp; {{subload.driver?subload.driver.name:'--'}} {{ subload.driver?subload.driver.last_name:'--'}}</span>
                      </div>
                    </div>
                    <div class="col-md-2 h30 position-relative text-center p-2">
                      <div class="font-weight-extra-bold font-size-14 color_blue ">{{$t("Status")}}:
                        <badge-status :status="subload.status" />
                      </div>
                    </div>
                  </div>
                  <hr class="mt-0 mb-0">
                  <div class="row">
                    <div class="col-md-12 p-2 ">
                      <div class="badge_custom d-inline-flex ">
                        <div class="font-weight-extra-bold font-size-13">{{ $t("Truck type") }}: <span class="text-muted font-size-11 ">{{subload.truck_type.name}}</span></div>
                        <div class="font-weight-extra-bold font-size-13 ml-4">{{ $t("Plate") }}: <span class="text-muted font-size-11 ">{{subload.truck?subload.truck.plate:'--'}}</span></div>
                        <div class="font-weight-extra-bold font-size-13 ml-4">{{ $t("ID") }}: <span class="text-muted font-s ize-11 "> {{subload.truck?subload.truck.id:'--'}}</span></div>
                      </div>
                      <div class="badge_custom d-inline-flex  ml-2">
                        <div class="font-weight-extra-bold font-size-13">{{ $t("Trailer type") }}: <span class="text-muted font-size-11 ">{{subload.trailer?subload.trailer.name:'--'}}</span></div>
                        <div class="font-weight-extra-bold font-size-13 ml-4">{{ $t("Plate") }}: <span class="text-muted font-size-11 ">{{subload.trailer?subload.trailer.plate:'--'}}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="stops_list">
                      <div class="stops_item" v-for="(substop,index) in subload.stops" :key="substop.id">
                        <div class="stops_item_detail">{{index+1}}</div>
                        <div class="d-flex justify-content-between">
                          <div class="">
                            <h4 class="font-size-18">{{substop.address.city}}, {{substop.address.state}}</h4>
                            <p>{{substop.address | address-format}} </p>
                          </div>
                          <div class="mr-4">
                            <div class="d-flex align-items-center">
                              <div class="mr-4">
                                <i class="fa fa-calendar-check fa-xxl"></i>
                              </div>
                              <div class="">
                                <span class="text-muted small">Programado para:</span> <br>
                                {{substop.schedule_from}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </div>
          <empty-results v-else></empty-results>
      </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Avatar from "../../components/common/Avatar";


export default {
  name: "ShipmentLoads",
  components: {
    Avatar
  },
  computed:{
    ...mapState('shipments',['shipment']),
    count(){
      return this.loads.length;
    }
  },
  data(){
    return{
      loads:[],
      loading:true
    }
  },
  created() {
    this.getShipmentLoads({id:this.shipment.id})
        .then((data)=>{
          this.loads = data;
        })
        .catch(()=>{

        })
        .finally(()=>{
          this.loading =  false;
        });
  },
  methods:{
    ...mapActions('shipments',['getShipmentLoads']),
    ...mapActions('loads',['getStops']),
  }
}
</script>

<style scoped>
.stops_list{
  padding-left: 60px;
}
.stops_item{
  margin-top: 50px;
  padding-right: 30px;
  position: relative;
}
.stops_item:last-child:before{
  display: none;
}
.stops_item:before{
  content: "";
  width: 25px;
  height: calc(100% + 25px);
  top: 25px;
  left: -39px;
  position: absolute;
  border-left: 2px dashed #00B3E6;
}
.stops_item_detail{
  color: white;
  text-align: center;
  position: absolute;
  left: -50px;
  top: 0;
  background: #00B3E6;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: bottom;
  line-height: 25px;
}
.fa-xxl{
  font-size: 30px;
  color: #00B3E6;
}
.table_loads{
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.font-weight-extra-bold{
  font-weight: 900;
  font-size: 15px;
}
.division{
  width: 1px;
  height: calc(100% - 20px);
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  top: 10px;
}
.h30{
  height: 40px;
}
.color_blue{
  color: #00B3E6;
}
.badge_custom{
  background: #f2f2f2;
  margin-left: 25px;
  padding: 1px 15px;
  border-radius: 15px;
}
</style>