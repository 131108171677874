<template>
    <GmapMap
            ref="mapRef"
            :center="{lat:19.4795587, lng:-99.3274917}"
            :zoom="9"
            :options="mapOptions"

    >
        <GmapMarker
                v-if="load.pickup"
                :position="{lat: load.pickup.address.latitude, lng: load.pickup.address.longitude}"
                :icon="pickupIcon"
        ></GmapMarker>
        <template v-if="load.pickup_addresses">
            <GmapMarker
                    :key="`${load.id}-pickup-add-${address.id}`"
                    v-for="address in load.pickup_addresses"
                    :position="{lat: address.latitude, lng: address.longitude}"
                    :icon="pickupIcon"
            ></GmapMarker>
        </template>
        <GmapMarker :key="'stop-'+stop.id" v-for="(stop,index) in this.load.stops"
                    :position="{lat: stop.address.latitude, lng: stop.address.longitude}"
                    :icon="stopIcon"
                    :label="{text: `${index+1}`, fontSize: '10px'}"
        >
        </GmapMarker>
        <GmapMarker
            v-if="load.delivery"
                :position="{lat: load.delivery.address.latitude, lng: load.delivery.address.longitude}"
                :icon="deliveryIcon"
        ></GmapMarker>
        <template v-if="load.delivery_addresses">
            <GmapMarker
                    :key="`${load.id}-delivery-add-${address.id}`"
                    v-for="address in load.delivery_addresses"
                    :position="{lat: address.latitude, lng: address.longitude}"
                    :icon="deliveryIcon"
            ></GmapMarker>
        </template>
      <template v-if="load.position && load.position.latitude && load.position.longitude && trackingStatus.indexOf(load.status) > -1">
          <truck-marker :position="load.position" :icon="iconUrl" />
      </template>
    </GmapMap>
</template>

<script>
    import mapStyle from "@/constants/mapStyle";
    import TruckMarker from '@/components/common/TruckMarker'
    export default {
        name: "RouteMap",
        components:{
            TruckMarker
        },
        props:{
            load: {
                type: Object,
                required: true,
            }
        },
        data(){
            return {
                stopIcon: {
                    scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
                    url: require('../../assets/images/txp/common/point_stop.png'),
                    labelOrigin: {x: 20, y:15}
                },
                pickupIcon: {
                    scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
                    url: require('../../assets/images/txp/origen.png'),
                },
                deliveryIcon: {
                    scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
                    url: require('../../assets/images/txp/destino.png'),
                    anchor:{x:20,y:20}
                },
                truckIcon: {
                  scaledSize: {width: 18, height: 49, f: 'px', b: 'px'},
                  url: require('../../assets/images/txp/common/truck-marker.png'),
                  anchor:{x:20,y:20}
                },
                mapOptions: {
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false,
                    styles: mapStyle
                },
                map:null,
                trackingStatus : ['started','checkin_pickup','loading','loaded','in_transit','checkin_delivery','checkin_delivery','unloading','unloaded']
            }
        },
        mounted() {
            this.$refs.mapRef.$mapPromise.then((map) => {
                this.map = map;
                this.fitMap();
            });
        },
        computed:{
            iconUrl(){
                if(this.load.eta_status === 'late'){
                    return require('../../assets/images/txp/late.png')
                }
                if(this.load.eta_status === 'delayed'){
                    return require('../../assets/images/txp/delayed.png')
                }
                if(this.load.eta_status === 'early'){
                    return require('../../assets/images/txp/early.png')
                }
                if(this.load.eta_status === 'on_time'){
                    return require('../../assets/images/txp/on_time.png')
                }
                return null;
            }
        },
        methods:{
            fitMap(){
                if(this.map){
                    let bounds = new google.maps.LatLngBounds();
                    if(this.load.stops){
                        for(let item of this.load.stops){
                            bounds.extend({lat: item.address.latitude, lng: item.address.longitude});
                        }
                    }
                    if(this.load.pickup){
                        bounds.extend({lat: this.load.pickup.address.latitude, lng: this.load.pickup.address.longitude});
                    }
                    if(this.load.delivery){
                        bounds.extend({lat: this.load.delivery.address.latitude, lng: this.load.delivery.address.longitude});
                    }
                    if(this.load.pickup_addresses){
                        for(let item of this.load.pickup_addresses){
                            bounds.extend({lat: item.latitude, lng: item.longitude});
                        }
                    }
                    if(this.load.delivery_addresses){
                        for(let item of this.load.delivery_addresses){
                            bounds.extend({lat: item.latitude, lng: item.longitude});
                        }
                    }
                    this.map.fitBounds(bounds);
                }
            }
        }
    }
</script>

<style scoped>

</style>
