<template>
  <div>
    <div class="cursor-pointer drop-zone mt-3 d-flex justify-content-center align-items-center"
         :class="[hover ? 'drop-zone-hover':'']"
         @dragover="dragover"
         @dragleave="dragleave"
         @drop="drop"
         @click="$refs.files.click()">
      <img src="@/assets/images/txp/cloud.png" class="cloud" :class="[hover ? 'zoom-in-zoom-out':'']"  />
      <input :multiple="multiple" type="file" id="import" ref="files" v-on:change="handleFiles()" accept=".pdf,.jpg,.jpeg,.png,.xml" style="display: none" />
    </div>
    <div class="text-center my-3">
      <h5 v-if="options.dictDefaultMessage" class="card-title font-weight-bold">{{$t(options.dictDefaultMessage)}}</h5>
      <p class="dict-msg mb-1">{{dropOptions.dictDefaultMessage}}</p>
    </div>
    <div v-if="loading" class="d-flex justify-content-center">
      <div class="card-progress text-center">
        <div class="d-flex justify-content-between align-items-center" style="height: 30px;">
          <div style="width:40px;">
            <span style="font-size:11px;color:#888888;font-family: Helvetica;">{{progress}}%</span>
          </div>
          <div class="progress" style="width: 300px;">
            <div class="progress-bar" role="progressbar"  :style="{'width': `${progress}%`}" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div style="width:40px;">
            <span style="font-size:11px;color:#888888;font-family: Helvetica;">100%</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {pkce} from "@/api/client";
import cryptoRandomString from 'crypto-random-string';
import axios from "@/api/client";
import client from 'axios';

const axiosClient = client.create();

export default {
  name: "FileInput",
  props:{
    options:{
      type: Object,
      default(){
        return {}
      }
    },
    path: {
      type: String,
      default(){
        return null
      }
    },
    usePresignedUrl: {
      type: Boolean,
      default(){
        return false;
      }
    },
    payload: {
      type: Object,
      default(){
        return {}
      }
    },
    paramName: {
      type: String,
      default(){
        return 'file'
      }
    },
    multiple:{
      type: Boolean,
      default(){
        return false
      }
    }
  },
  data(){
    return {
      hover: false,
      loading: false,
      id: cryptoRandomString({length:6}),
      progress: 0,
      progressArray: []
    }
  },
  computed:{
    dropzoneOptions(){
      return {
        thumbnailWidth: 150,

        ...this.options,
        url: `${process.env.VUE_APP_API_URL}${this.path}`,
        paramName: "file",
        params: this.payload,
        headers:{
          "Authorization": `Bearer ${pkce.token}`,
          'Cache-Control': '',
        }
      }
    },
    dropOptions(){
      return {
        'height': '150px',
        'width': '100%',
        dictDefaultMessage: this.$t('Drop files here or click to upload'),
      }
    },
  },
  methods: {
    fileAdded(file){
      this.$emit('file-added',file);
    },
    sending(file, xhr, formData){
      this.$emit('sending',file,xhr, formData);
    },
    removedFile(file, error, xhr){
      this.$emit('removed-file',file, error, xhr);
    },
    success(file, response){
      this.$emit('success', response);
    },
    totalUploadProgress(totaluploadprogress){
      this.$emit('total-upload-progress', totaluploadprogress);
    },
    async handleFiles(){
        this.progress = 0;
        this.progressArray = [];
        this.loading = true;

        if(this.usePresignedUrl === true){
          let result = [];
          const uploadPromises = Array.from(this.$refs.files.files).map(async (file, index) => {
            const response = await this.getS3Url(file);
            let formData = new FormData();
            formData.append(this.paramName, file);
            result.push(response.data.file);
            return this.uploadFile(response.data.temporary_url,formData, index);
          });

          await Promise.all(uploadPromises);
          this.$emit('success', result);
        }else{
          for(let file of this.$refs.files.files){
            if(file.size === 0){
              this.$emit('error', this.$t('The file is empty. Please upload a valid file.'));
              break;
            }
            console.log("File added")
            let url = this.path;

            this.$emit('file-added',file);
            let formData = new FormData();
            formData.append(this.paramName, file);

            if(this.path){
              try{
                for(let item in this.payload){
                    formData.append(item, this.payload[item]);
                }
                let result = await axios.post(url,formData,{headers: { 'Content-Type': 'multipart/form-data' }});
                this.$emit('success', result.data);
              }catch (error) {
                this.$emit('error', error.response);
              }
              this.$refs.files.value=""
            }
          }
        }


        this.loading = false;

    },
    async getS3Url(file){
      return await axios.post(this.path, {
              ...this.payload,
              'filename': file.name,
              'content_type': file.type});
    },
    async uploadFile(signedUrl, body, index){
      const options = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.progressArray[index] = percentCompleted;
          const totalProgress = parseInt(this.progressArray.reduce((a, b) => a + b) / this.progressArray.length);
          this.progress = totalProgress
        },
        headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': `inline; filename="${body.get('file').name}"`
        },
      };
      await axiosClient.put(signedUrl, body, options);
    },
    dragover(event) {
      event.preventDefault();
      this.hover = true;
    },
    dragleave(event) {
      this.hover = false;
    },
    drop(event) {
      event.preventDefault();
      this.$refs.files.files = event.dataTransfer.files;
      this.hover = false;
      this.handleFiles();
    }

  }
}
</script>

<style scoped>
.drop-zone{
  border: 2px dashed #CCCCCC;
  box-sizing: border-box;
  border-radius: 3px;
  height: 100px;
  width: 100%;
}
.drop-zone-hover{
  background-color: #FAF9FB!important;
  border: 2px dashed #888888!important;
}
.dict-msg{
  color: #888888;
}

.cloud{
  width: 70px;
}

.zoom-in-zoom-out{
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}
.card-progress {
  height: 30px;
  width: 380px;
  border-radius: 40px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(204,204,204,0.5);
}
.progress-bar {
    background: #00B3E6;
}
</style>
