import { _ } from "core-js";
import axios from "../../api/client";


// initial state
export const state = () => ({
    forms : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    fields : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    externalRequirements : [],
    customLoadForm: null,
    carrierLoadForm: null,
    shipperLoadForm: null,
    adminLoadForm: null,
    systemLoadForm:null,
    loadForm:null,
    fetching:null
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchForms({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/forms`, {params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeForm({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/forms`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getForm({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/forms/${params.id}`, {params: _.omit(params, ['id'])})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    fetchExternalRequirements({commit}, params){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shippers/${params.shipper_id}/externalRequirements`)
                .then(response=>{
                    commit('setForms', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                }).finally(()=>{
                commit('setFetching', false);
            });
        });
    },
    updateForm({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/forms/${payload.id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteForm({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/forms/${params.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getFields({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/forms/${params.form_id}/fields`, {params: params})
                .then(response=>{
                    commit('setFields', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getField({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/forms/${params.form_id}/fields/${params.field_id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeField({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/forms/${payload.form_id}/fields`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateField({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/forms/${payload.form_id}/fields/${payload.field_id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteField({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/forms/${params.form_id}/fields/${params.field_id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setFields(state,payload){
        state.fields = payload;
    },
    setCarrierLoadForm(state, data){
        state.carrierLoadForm = data;
    },
    setShipperLoadForm(state, data){
        state.shipperLoadForm = data;
    },
    setAdminLoadForm(state, data){
        state.adminLoadForm = data;
    },
    setCustomLoadForm(state, data){
        state.customLoadForm = data;
    },
    setSystemLoadForm(state, data){
        state.systemLoadForm = data;
    },
    setLoadForm(state, data){
        state.loadForm = data;
    },
    setForms(state,payload){
        state.externalRequirements = payload;
    },
    setFetching(state, payload){
        state.fetching = payload;
    },

};

