<template>
    <b-modal id="modal-create-shipment" ref="modal-create-shipment" size="md" hide-footer :title="title"
        :visible="visible" v-on:hidden="closeAction">
        <div v-if="admin" class="d-flex flex-column justify-content-center align-items-center">
            <div class="p-3 row border element text-center">
                <div class="col-3 d-flex justify-content-center align-items-center">
                    <div class="bg-gradient-txp rounded-circle p-1 d-flex justify-content-center align-items-center"
                        style="width:50px;height:50px;">
                        <img class="" src="@/assets/images/marketplace/FTL.svg" width="100%" />
                    </div>
                </div>
                <div class="col-8 font-weight-bold">
                    {{ message }}
                </div>
            </div>
            <div>
                <h1 class="text-center font-weight-bold title p-3">{{ $t('Seleccionar cliente') }}</h1>
                <div>
                    <validation-observer ref="observer" v-slot="observer">
                        <form @submit.stop.prevent="test(observer)">
                            <form-input rules="required" layout="vertical" class="col-md-12" :name="`client`"
                                :title="$t('Selecciona al cliente al que se le ofrecera este servicio')" :border="false"
                                :placeholder="$t('Select option')" type="select" @input="onChange" :options="clients"
                                :selectText="'name'" v-model="client" selectValue="socials" />

                            <form-input v-if="!socials && unique" rules="required" layout="vertical" class="col-md-12"
                                :name="`social`" :disabled="true" :border="false"
                                :title="$t('Selecciona la razon social para este servicio')" v-model="social"
                                type="text" />

                            <form-input v-if="socials && !unique" rules="required" layout="vertical" class="col-md-12"
                                :name="`client`" :title="$t('Selecciona la razon social para este servicio')"
                                :border="false" :placeholder="$t('Select option')" type="select"
                                @change="onSelectSocial" :options="socials" selectValue="id" v-model="social"
                                :selectText="'name'" />
                        </form>
                    </validation-observer>
                    <div v-if="select" class="row mt-2">
                        <div class="col-md-5 offset-md-1">
                            <button @click="closeAction()" class="btn btn-outline-dark btn-block">
                                {{ $t('Cancel') }}
                            </button>
                        </div>
                        <div class="col-md-5">
                            <button @click="validateShipmentAction()" class="btn btn-primary_v2 btn-block">
                                {{ $t('Continue') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="p-2">
            <div class="row">
                <div class="col-12">
                    <h4 class="text-center font-weight-bold font-size-24">{{ message }}</h4>
                    <div class="d-flex justify-content-center m-3">
                        <div class="bg-gradient-txp rounded-circle d-flex justify-content-center align-items-center"
                            style="width:120px;height:120px;">
                            <img class="" src="@/assets/images/marketplace/FTL.svg" width="100" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-5 offset-md-1">
                    <button @click="closeAction()" class="btn btn-outline-dark btn-block">
                        {{ $t('Cancel') }}
                    </button>
                </div>
                <div class="col-md-5">
                    <button @click="validateShipmentAction()" class="btn btn-primary_v2 btn-block">
                        {{ $t('Continue') }}
                    </button>
                </div>
            </div>
        </div>
    </b-modal>

</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import FormInput from '../form/FormInput.vue'
import Multiselect from 'vue-multiselect';

export default {
    name: "CreateShipmentModal",
    comments: {
        FormInput,
        Multiselect
    },
    data() {
        return {
            activeButton: true,
            clients: [],
            client: null,
            socials: null,
            social: null,
            select: null,
            unique: false,
        }
    },
    computed: {
        ...mapState('profile', ['profile']),
    },
    props: {
        title: {
            type: String
        },
        visible: {
            type: Boolean
        },
        message: {
            type: String
        },
        admin: {
            type: Boolean
        }
    },
    created() {
        if (this.admin) {
            this.fetchClients()
                .then(response => {
                    this.clients = response;
                })
        }
    },
    methods: {
        ...mapActions('shipments', ['validateShipment']),
        ...mapMutations('marketplace', ['resetShipment', 'setCustomer']),
        ...mapMutations('shipmentform', ['resetShipmentform']),
        ...mapActions('accounts', ['fetchClients']),
        closeAction() {
            this.client = null;
            this.socials = null;
            this.select = null;
            this.activeButton = true;
            this.$emit('close', { visible: true });
        },
        onChange(e) {
            if (e.length > 1) {
                this.unique = false;
                this.socials = e;
            }
            else {
                this.unique = true;
                this.socials = null;
                this.select = e[0];
                this.social = e[0].name;
            }
        },
        onSelectSocial(e) {
            this.select = this.socials.find(element => element.id == e);
        },
        validateShipmentAction() {
            if (this.profile.profile.profile_percentage < 100) {
                if (this.profile.counter_quote >= 3) {
                    this.$root.$bvToast.toast(this.$t("Es necesario que completes tu perfil para continuar"), {
                        title: this.$t('Error'),
                        variant: 'danger',
                        solid: true
                    });
                    this.closeAction();
                    this.$router.push({ name: 'settings.profile' })
                    return;
                }
            }
            this.resetShipment();
            this.resetShipmentform();
            if (this.admin) {
                this.setCustomer({ code: this.select.tms_id, name: this.select.name })
            }
            this.$router.push({ name: 'shipments.add.ftl' })

        }
    },
}
</script>
<style lang="scss">
.modal-title {
    font-size: 16px;
    font-weight: bold;
}

.element {
    border-radius: 20px;
    background-color: #F2F2F2;
    width: 90%;
}

.title {
    font-size: 24px;
}
</style>
