import { render, staticRenderFns } from "./EditIssue.vue?vue&type=template&id=0d5018c7&scoped=true&"
import script from "./EditIssue.vue?vue&type=script&lang=js&"
export * from "./EditIssue.vue?vue&type=script&lang=js&"
import style0 from "./EditIssue.vue?vue&type=style&index=0&id=0d5018c7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d5018c7",
  null
  
)

export default component.exports