<template>
    <b-dropdown variant="light" ref="dropdown" class="d-inline-block "  toggle-class="filter-button">
        <template #button-content>
            <i class="mr-1" :class="[filter.icon]"></i> <strong>{{filter.label}}:</strong> <span> {{getFormattedValue()}}</span>
        </template>
        <b-dropdown-form style="min-width: 300px;">
            <multiselect v-model="selectedCountries"  track-by="city" placeholder="Type to search" open-direction="bottom" :options="options" :multiple="true"
                         :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3"
                         :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="onChange">
                <template slot="option" slot-scope="{option}"><span class="option__title">{{ option.city }}, {{option.state}}, {{option.country_code}}</span></template>
                <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag"><span>{{ option.city }}</span><span class="custom__remove" @click="remove(option)">❌</span></span>
                </template>
                <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="selectedCountries.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                </template>
                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>x
            </multiselect>
        </b-dropdown-form>
    </b-dropdown>
</template>

<script>
    import Multiselect from 'vue-multiselect'

export default {
    name: 'AsyncFilter',
    components:{
        Multiselect
    },
    props: {
        filter: {
            type: Object,
            required: true
        },
        value: {
            required:true
        },
        search : {
            type: Function,
            required : true
        }
    },
    data () {
        return {
            selectedCountries: [],
            options: [],
            isLoading: false
        }
    },
    created() {
        this.doSearch = _.debounce(this.asyncFind,500);

        if(this.value){

        }else{
            setTimeout(()=>{
                this.$refs.dropdown.visible = true;
            },100);
        }
    },
    methods: {
        limitText (count) {
            return `and ${count} other options`
        },
        onChange(query){
            this.doSearch(query);
        },
        asyncFind (query) {
            this.isLoading = true;
            this.search(query)
                .then(data=>{
                    this.options = data.results;
                })
                .catch(error =>{
                    console.log(error);
                })
                .finally(()=>{
                    this.isLoading = false;
                });
        },
        clearAll () {
            this.selectedCountries = []
        }
    }
}
</script>

<style scoped>

</style>
