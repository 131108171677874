<template>
    <validation-observer ref="observer" v-slot="{ validate }">
        <b-form v-if="visible" @submit.stop.prevent="onSubmit(validate)">
            <div class="row">
                <div class="col-md-12">
                    <form-input
                        class="px-0"
                        :title="$t('Product name')"
                        v-model="form.description"
                        rules="required"
                        type="text"
                        name="description"
                        layout="vertical"
                        :placeholder="$t('Enter a product')"
                        :border="false"
                    />
                </div>
                    <div class="col-md-6">
                        <form-input
                            class="px-0"
                            :title="$t('Minimum temperature (˚F)')"
                            v-model="form.min_temperature"
                            rules="required"
                            type="number"
                            name="min_temperature"
                            layout="vertical"
                            :border="false"
                        />
                    </div>
                    <div class="col-md-6">
                        <form-input
                            class="px-0"
                            :title="$t('Maximum temperature (˚F)')"
                            v-model="form.max_temperature"
                            rules="required"
                            type="number"
                            name="max_temperature"
                            layout="vertical"
                            :border="false"
                        />
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end mt-4 mb-2">
                <a @click="close" class="btn btn btn-light mr-3 ">{{$t('Cancel')}}</a>
                <button type="submit" class="btn btn-primary">{{$t('Save')}}</button>
            </div>
        </b-form>
    </validation-observer>
</template>

<script>

export default {
    name: 'TemperatureForm',
    props:{
        value: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            default(){
                return false;
            }
        },
        editable: {
            type: Boolean,
            default(){
                return true
            }
        },
        load: {
            type: Object,
        },
    },
    data(){
        return {
            form: {unit: 'F'}
        }
    },
    watch: {
        value(newValue, oldValue){
            this.form = newValue;
        },
        form:{
            deep: true,
            handler(value){
                this.$emit('input', value);
            }
        }
    },
    created(){
        if(this.value){
            this.form = this.value;
        }
    },
    methods: {
        async onSubmit(validate){
            if(!(await validate())){
                return;
            }

           this.$emit('submit', this.form);
        },
        close(){
            this.$emit('close');
        }
    }
}
</script>
