<template>
  <div class="card card-txp">
    <div class="card-body">
      <div class="row" v-if="!profile">
        <div class="col-10">
            <b-skeleton animation="wave" width="90%" />
            <b-skeleton animation="wave" width="70%" />
        </div>
        <div class="col-1">
            <b-skeleton type="avatar" />
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-7">
          <h5 class="font-weight-bold m-0 font-size-17">
            {{ $t('Profile information') }} <span v-if="!profileCompleted" class="dot"></span>
          </h5>
          <div class="opacity fz-14">
            <span v-if="profileCompleted">
              {{ $t('Completed') }}
            </span>
            <span v-else>
              {{ $t('Pending') }}
            </span>
          </div>
        </div>
        <div class="col-4">
          <h4 class="c-right m-0">
            <span class="badge badge-pill badge-white">
              <p class="m-0 small font-weight-bold">{{ profile.profile.profile_percentage }}%</p>
            </span>
          </h4>
          <div class="opacity fz-14 c-right">
            <span v-if="profileCompleted">{{ $t('Completed profile') }}</span>
            <span v-else>{{ $t('Incomplete profile') }}</span>
          </div>
        </div>
        <div class="col-1 c-right">
            <router-link :to="{name:'settings.profile'}">
                <i style="color: black;" class="fa fa-chart-pie fa-2x font-size-20"></i>
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  name: "ProfilePercentage",
  computed: {
    ...mapState("profile", ["profile"]),
    profileCompleted() {
      return this.profile.profile.profile_percentage === 100;
    }
  },
};
</script>

<style scoped>
.opacity {
  opacity: 0.6;
}
.fz-14 {
  font-size: 13px;
}
.card-txp {
  border-radius: 15px;
  box-shadow: 1px 5px 3px #dcdcdc;
}
.c-right {
  display: flex;
  align-items: center;
  justify-content: right;
}
.badge-white {
  color: #00b3e6;
  background-color: #ffffff;
  border: solid 1px #dcdcdc;
}
.dot {
  height: 5px;
  width: 5px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 5px 3px 7px;
}
</style>
