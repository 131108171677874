<template>
  <div class="gallery mt-3">
    <div class="gallery_container">
      <div v-for="i in [currentIndex]" class="gallery_container_item" :key="'item_gc'+i">
        <div class="days_of_week">
          <div class="day text-center" :class="[currentDay==day.num?'active':'',day.disabled?'':'disable']" v-for="(day,index) in currentWeek" @click="setDay(day)" :key="'week'+index">
            <div class="day_name">{{day.name}}</div>
            <div class="day_number">{{day.num}}</div>
          </div>
        </div>
      </div>
      <a class="prev" :class="disablePrev?'':'disable'" @click="prev" >&#10094;</a>
      <a class="next" :class="disableNext?'':'disable'" @click="next" >&#10095;</a>
    </div>
  </div>
</template>

<script>
import moment from "moment";


export default {
  name: "horizontalCalendar",
  data() {
    return {
      currentIndex: 0,
      currentDay: null,
      choosedWeek: null,
    }
  },
  props:{
    date:{
      default: null
    },
  },
  created(){
    this.currentDay = this.date.getDate();
    this.currentIndex = Math.ceil(this.currentDay/7)-1;
    this.$emit('changeDate', {date:moment(this.date).format("Y-M-D")});
  },
  computed:{
    listOfDays: function() {
      let today = this.dateOfMonth.date();
      let days = [];
      let firstDay = moment(this.dateOfMonth.startOf('month'));
      let lastDate = moment(this.dateOfMonth.endOf('month'));
      for(let i=firstDay.date()-1; i < lastDate.date();i++){
        let day = i===0  ? firstDay: moment(firstDay.add(1, 'd'));
        days.push({num:day.date(),name:day.format("dd").toUpperCase(),date:day.format("Y-M-D"),disabled:day.isBefore(new Date())})
      }
      return _.chunk(days,7);
    },
    weeks: function(){
      return this.listOfDays.length
    },
    dateOfMonth: function(){
      return moment(this.date)
    },
    disableNext: function(){
      return this.currentIndex !== this.weeks-1
    },
    disablePrev: function(){
      return this.currentIndex !== 0
    },
    currentWeek: function(){
      return this.listOfDays[Math.abs(this.currentIndex) % this.weeks];
    }
  },
  methods: {
    next: function() {
      if (this.currentIndex !== this.weeks-1){
        this.currentIndex += 1
      }
      this.choosedWeek = null
    },
    prev: function() {
      if (this.currentIndex !== 0){
        this.currentIndex -= 1
      }
      this.choosedWeek = null
    },
    setDay(day){
      if (day.disabled){
        this.currentDay =  day.num;
        this.$emit('changeDate', day);
      }
    },
  },
  watch:{
    listOfDays(e){
      this.currentDay = this.date.getDate();
      this.currentIndex = Math.ceil(this.currentDay/7)-1;
      this.$emit('changeDate', {date:moment(this.date).format("Y-M-D")});
    }
  }
}
</script>

<style scoped>
.gallery_container_item > img{
  height: 100%;
}
.gallery_container{
  position: relative;
  margin: auto;
}
.gallery_container .fade-enter-active,
.gallery_container .fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width:100%;
  opacity: 1;
}

.gallery_container .fade-enter, .gallery_container .fade-leave-to {
  visibility: hidden;
  width:100%;
  opacity: 0;
}

.gallery_container_item {
  text-align: center;
  position: relative;
}

.gallery_container .prev.disable, .gallery_container .next.disable {
  opacity: .2;
  pointer-events: none;
  transition: 0.7s ease;
}
.gallery_container .prev, .gallery_container .next {
  cursor: pointer;
  position: absolute;
  top: 20%;
  color: #999;
  font-weight: 100;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
  pointer-events: auto;
}

.gallery_container .next {
  right: 0;
}

.gallery_container .prev {
  left: 0;
}

.days_of_week{
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 80%;
  margin: auto;
}
.day:hover{
  cursor: pointer;
}
.day{
  text-align: center;
  width: 50px;
  padding-bottom: 5px;
  border-bottom: 2px solid #faf9fb
}
.day_name{
  color: #888888;
  font-size:  11px;
  font-weight: 700;
  line-height: 13px;
}
.day_number{
  padding-top: 8px;
  font-weight: 800;
  font-size:  18px;
  line-height: 21px;
}

.day.active .day_number,.day.active .day_name{
  color:#00B3E6;
}
.day.active{
  color:#00B3E6;
  padding-bottom: 5px;
  border-bottom: 2px solid #00B3E6;
}
.day.disable{
  opacity: .5;
  pointer-events: none;
}
</style>