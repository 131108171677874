<template>
    <span class="badge " :class="['badge-outline-'+statusColor[status]]">{{statusName ? statusName : $t(status)}}</span>
</template>

<script>
    const statusColor = {
        not_assigned:'success',
        accepted:'primary',
        in_transit:'warning',
        delivered:'info',
        paid:'success',
        cancelled:'danger',
        disabled:'secondary',
        loading:'warning',
        loaded:'warning',
        unloading:'warning',
        unloaded:'warning',
        pod:'success',
        started:'warning',
        checkin_pickup:'warning',
        checkin_delivery:'warning',
        assigned:'primary',
        published:'success',
        pending:'secondary',
        in_review: 'warning',
        rejected: 'danger',
        completed: 'success',
        in_progress: 'warning',
        na: 'info',
        delivering:'info',
        partial_rejection: 'danger',
        total_rejection: 'danger',

    };
    export default {
        name: "BadgeStatus",
        props:{
            status:{
                required: true,
                type: String
            },
            statusName: {
                required : false,
                type: String
            }
        },
        data(){
            return{
                statusColor: statusColor
            }
        }
    }
</script>

<style scoped>

</style>
