import axios from "../../api/client";

// initial state
export const state = () => ({
  accounts: {
    results: [],
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1,
    count: 0,
    next_page_url: null,
    prev_page_url: null,
    from: 1,
    to: 1,
  },
  shippers: {
    results: [],
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1,
    count: 0,
    next_page_url: null,
    prev_page_url: null,
    from: 1,
    to: 1,
  },
  carriers: {
    results: [],
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1,
    count: 0,
    next_page_url: null,
    prev_page_url: null,
    from: 1,
    to: 1,
  },
  drivers: {
    results: [],
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1,
    count: 0,
    next_page_url: null,
    prev_page_url: null,
    from: 1,
    to: 1,
  },
  account: null,
  fetching: false,
});

// getters
export const getters = {};

// actions
export const actions = {
  getAccounts({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/accounts`, { params: params })
        .then((response) => {
          commit("setAccounts", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  createAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v2/accounts`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/accounts/${payload.id}`)
        .then((response) => {
          commit("setAccount", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  updateAccount({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v2/accounts/${payload.id}`, payload)
        .then((response) => {
          let items = _.cloneDeep(state.accounts);
          let index = items.results.findIndex(
            (item) => item.id === response.data.id
          );
          if (index > -1) {
            items.results[index] = response.data;
            commit("setAccounts", items);
          }
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  deleteAccount({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v2/accounts/${params.id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  fetchShippers({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/shippers`, { params: params })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  fetchCarriers({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/carriers`, { params: params })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  fetchDrivers({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/drivers`, { params: params })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  fetchClients({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/clients`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getShippers({ commit }, params) {
    commit("setFetching", true);
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/shippers`, { params: params })
        .then((response) => {
          commit("setShippers", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        })
        .finally(() => {
          commit("setFetching", false);
        });
    });
  },
  getShipper({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/shippers/${params.shipper_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getCarriers({ commit }, params) {
    commit("setFetching", true);
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/carriers`, { params: params })
        .then((response) => {
          commit("setCarriers", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        })
        .finally(() => {
          commit("setFetching", false);
        });
    });
  },
  getCarrier({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/carriers/${params.carrier_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getDrivers({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/drivers`, { params: params })
        .then((response) => {
          commit("setDrivers", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  unlinkDriver({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v2/drivers/unlink", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

// mutations
export const mutations = {
  setFetching(state, data) {
    state.fetching = data;
  },
  setAccounts(state, data) {
    state.accounts = data;
  },
  setAccount(state, data) {
    state.account = data;
  },
  setShippers(state, data) {
    state.shippers = data;
  },
  setCarriers(state, data) {
    state.carriers = data;
  },
  setDrivers(state, data) {
    state.drivers = data;
  },
};
