<template>
    <div class="alert">
      <div class="image_icon"><img src="@/assets/images/txp/common/alert_warning.svg" alt=""></div>
      <div class="alert_text">{{text}}</div>
    </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    icon: {
      type: String,
      default: ""
    },
    text :  {
      type: String,
      default: ""
    }
  },
}
</script>

<style scoped>
  .alert{
    display: flex;
    align-items: center;
    background: #F2F2F2;
  }
  .image_icon{
    margin-right: 15px;
  }
</style>