<template>
    <b-button-group>
        <b-dropdown variant="light" ref="dropdown" class="d-inline-block "  toggle-class="filter-button">
            <template #button-content>
                <table>
                    <tr>
                        <td valign="top" style="width:24px;">
                            <small><i class="pr-1 text-muted" :class="[filter.icon]"></i></small>
                        </td>
                        <td valign="top" class="text-left" >
                            <small class="text-muted">{{filter.label}}:</small> <div class="filter-button-value"> {{formattedValue}}</div>
                        </td>
                    </tr>
                </table>
            </template>

            <b-dropdown-form style="min-width: 300px;">
                <multiselect v-model="selected" :options="filter.values" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                            label="text" track-by="value" :placeholder="$t('Select')" select-label="" :selected-label="$t('Selected')" >
                </multiselect>
            </b-dropdown-form>
        </b-dropdown>
        <b-button variant="light" @click="$emit('remove')"><i class="far fa-times"></i></b-button>
    </b-button-group>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import convertDataType from "@/helpers/convertDataType";

    export default {
        name: 'MultiSelectFilter',
        components:{
            Multiselect
        },
        props: {
            filter: {
                type: Object,
                required: true
            },
            value: {
                required:true
            }
        },
        data () {
            return {
                selected: []
            }
        },
        computed:{
            formattedValue(){
                if(this.selected.length === 0)
                    return '- -';

                if(this.selected.length === 1){
                    return this.selected[0].text;
                }
                return `${this.selected.length} ${this.$t('options selected')}`;
            }
        },
        watch:{
            selected:{
                deep: true,
                handler(newValue, oldValue){
                    this.$emit('input', newValue.map(item => (item.value)).join(','));
                }
            }
        },
        created() {
            if(this.value){
                let values = this.value.split(',');
                values = values.map(item => (convertDataType(item, this.filter.valueType)));
                this.selected = this.filter.values.filter(item => (values.indexOf(item.value) > -1));
            }else{
                setTimeout(()=>{
                    this.$refs.dropdown.visible = true;
                },100);
            }
        },
    }
</script>

<style scoped>
    .multiselect__content-wrapper{
        z-index: 10000!important;
    }
</style>
