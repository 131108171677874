<template>
  <div>
    <page-header :title="$t('Documents')" :items="breadcrumb" />
    <documents-grid :resourceTxt="resource" :resource_id="profile.id"></documents-grid>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DocumentsGrid  from "@/views/documents/DocumentsGrid";

export default {
  name: "Documents",
  components: {
    DocumentsGrid
  },
  data() {
    return {
      resource: this.$route.params.profile + "s"
    };
  },
  computed: {
    ...mapState("profile", ["profile"]),
    breadcrumb() {
      const profile = this.$route.params.profile;
      return [
        {
          text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
          to: { name: "profile" },
        },
        {
          text: this.$t("Settings"),
          to: { name: "settings" },
        },
        {
          text: this.$t("Documents"),
          to: { name: "settings.documents" },
          active: true,
        },
      ];
    }
  }
};
</script>

<style scoped>

</style>