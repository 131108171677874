<template>
    <div>
        <loading v-if="loading"></loading>
        <div v-else >
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center ">
                        <div class="col-md-8">
                            <div class="field-label">
                                <b>{{ $t("Telemetry") }}</b>
                            </div>
                        </div>
                        <div class="col-md-4 d-flex justify-content-end">
                            <a
                                href="javascript:void(0);"
                                @click="collapseTelemetry = !collapseTelemetry"
                            >
                                <i
                                    class="far btn-icon"
                                    :class="[
                                        true
                                            ? 'fa-chevron-circle-down'
                                            : 'fa-chevron-circle-up',
                                    ]"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <table
                    class="table"
                    :class="[collapseTelemetry ? 'collapse' : '']"
                >
                    <thead>
                        <tr class="text-primary">
                            <th scope="col"></th>
                            <th scope="col">{{ $t("Estimate") }}</th>
                            <th scope="col">Real</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ $t("Mileage") }}</td>
                            <td class="font-weight-bold">
                                <b-form-input style="width:60px;" size="sm"  v-if="editKm" type="text" v-model.number="telemetry.real_distance"></b-form-input>
                                <span v-else >{{ telemetry.real_distance ? telemetry.real_distance : '--' }}</span>
                            </td>
                            <td class="font-weight-bold">
                                <b-form-input style="width:60px;" size="sm"  v-if="editKm" type="text" v-model.number="telemetry.assigned_distance"></b-form-input>
                                <span v-else >{{ telemetry.assigned_distance ? telemetry.assigned_distance : '--' }}</span>
                            </td>
                            <td class="text-center">
                                <a v-if="!editKm" @click="editKm=!editKm" href="javascript:void(0);">
                                    <i class="far fa-pencil mr-2"></i>
                                </a>
                                <a v-else @click="save('Km');editKm=!editKm" href="javascript:void(0);">
                                    <i class="far fa-save mr-2"></i>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Diesel</td>
                            <td class="font-weight-bold">
                                <b-form-input style="width:60px;" size="sm"  v-if="editDl" type="text" v-model.number="telemetry.real_diesel"></b-form-input>
                                <span v-else >{{ telemetry.real_diesel ? telemetry.real_diesel : '--' }}</span>
                            </td>
                            <td class="font-weight-bold">
                                <b-form-input style="width:60px;" size="sm"  v-if="editDl" type="text" v-model.number="telemetry.assigned_diesel"></b-form-input>
                                <span v-else >{{ telemetry.assigned_diesel ? telemetry.assigned_diesel : '--' }}</span>
                            </td>
                            <td class="text-center">
                                <a v-if="!editDl" @click="editDl=!editDl" href="javascript:void(0);">
                                    <i class="far fa-pencil mr-2"></i>
                                </a>
                                <a v-else @click="save('Dl');editDl=!editDl" href="javascript:void(0);">
                                    <i class="far fa-save mr-2"></i>
                                </a>
                            </td>
                        </tr>
                        <tr class="text-primary">
                            <th scope="col"></th>
                            <th scope="col">{{ $t("Initial") }}</th>
                            <th scope="col">{{ $t("Final") }}</th>
                            <th scope="col"></th>
                        </tr>
                        <tr>
                            <td>{{ $t("Odometer") }}</td>
                            <td class="font-weight-bold">
                                <b-form-input style="width:60px;" size="sm"  v-if="editOd" type="text" v-model.number="telemetry.start_odometer"></b-form-input>
                                <span v-else >{{ telemetry.start_odometer ? telemetry.start_odometer : '--' }}</span>
                            </td>
                            <td class="font-weight-bold">
                                <b-form-input style="width:60px;" size="sm"  v-if="editOd" type="text" v-model.number="telemetry.end_odometer"></b-form-input>
                                <span v-else >{{ telemetry.end_odometer ? telemetry.end_odometer : '--' }}</span>
                            </td>
                            <td class="text-center">
                                <a v-if="!editOd" @click="editOd=!editOd" href="javascript:void(0);">
                                    <i class="far fa-pencil mr-2"></i>
                                </a>
                                <a v-else @click="save('Od');editOd=!editOd" href="javascript:void(0);">
                                    <i class="far fa-save mr-2"></i>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t("Performance") }}</td>
                            <td class="font-weight-bold">
                                <b-form-input style="width:60px;" size="sm"  v-if="editRo" type="text" v-model.number="telemetry.performance"></b-form-input>
                                <span v-else >{{ telemetry.performance ? telemetry.performance : '--' }}</span>
                            </td>
                            <td class="font-weight-bold"></td>
                            <td class="text-center">
                                <a v-if="!editRo" @click="editRo=!editRo" href="javascript:void(0);">
                                    <i class="far fa-pencil mr-2"></i>
                                </a>
                                <a v-else @click="save('Ro');editRo=!editRo" href="javascript:void(0);">
                                    <i class="far fa-save mr-2"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: "TelemetryTable",
    props:{
        load_id: null
    },
    data() {
        return {
            collapseTelemetry: false,
            editKm: false,
            editDl: false,
            editOd: false,
            editRo: false,
            telemetry: null,
            loading: true
        }
    },
    created() {
        this.getTelemetry({id: this.load_id})
            .then(data =>  {
                this.telemetry = data
                this.loading = false
            });
    },
    methods: {
        ...mapActions('telemetries', ['getTelemetry', 'createTelemetry', 'updateTelemetry']),
        save(item) {
            let telemetry = _.pickBy(this.telemetry, _.identity);
            telemetry.id = this.load_id
            this.createTelemetry(telemetry)
        }
    },
}
</script>