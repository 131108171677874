import axios from "@/api/client";

// initial state
export const state = () => ({

});

// getters
export const getters = {};

// actions
export const actions = {
    getShippingLocationg({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/addresses`,{params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    }
};

// mutations
export const mutations = {

};

