<template>
    <div>
        <page-header :title="$route.params.requirement_id ? $t('Edit requirement'): $t('New requirement')" :items="breadcrumb"/>

        <div class="row">
            <div class="col-md-12">
                <validation-observer ref="observer" v-slot="{ validate }">
                    <b-form @submit.stop.prevent="onSubmit(validate)">
                        <div class="card">
                            <div class="card-header">
                                <h6>{{ $t("Requirement information") }}</h6>
                            </div>

                            <form-input
                                    :title="$t('Name')"
                                    :help="$t('The requirement name')"
                                    v-model="form.name"
                                    rules="required"
                                    type="text"
                                    name="name"
                            />
                            
                            <form-input
                                    :title="$t('Format')"
                                    :help="$t('The requirement format')"
                                    v-model="form.format"
                                    rules=""
                                    type="text"
                                    name="format"
                            />

                            <form-input
                                    :title="$t('Description')"
                                    :help="$t('The requirement description')"
                                    v-model="form.description"
                                    rules=""
                                    type="textarea"
                                    name="description"
                            />
                            <form-input
                                    :title="$t('Resource')"
                                    :help="$t('The resource to whom the requirement belongs')"
                                    v-model="form.resource"
                                    rules="required"
                                    type="select"
                                    :options="Resources"
                                    name="resource"
                                    :placeholder="$t('Select option')"
                            />

                            <form-input
                                    :title="$t('Required?')"
                                    :help="$t('The requirement is required?')"
                                    v-model="form.required"
                                    rules=""
                                    type="boolean"
                                    name="required"
                            />

                            <form-input
                                    :title="$t('Expiration?')"
                                    :help="$t('The requirement has expiration?')"
                                    v-model="form.expiration"
                                    rules=""
                                    type="boolean"
                                    name="expiration"
                            />

                            <form-input
                                    :title="$t('Hidden?')"
                                    :help="$t('The requirement is hidden?')"
                                    v-model="form.hidden"
                                    rules=""
                                    type="boolean"
                                    name="hidden"
                            />

                            <form-input
                                    :title="$t('Editable?')"
                                    :help="$t('The requirement is editable?')"
                                    v-model="form.editable"
                                    rules=""
                                    type="boolean"
                                    name="editable"
                            />

                            <form-input
                                    :title="$t('Validate?')"
                                    :help="$t('The requirement needs validation?')"
                                    v-model="form.validation"
                                    rules=""
                                    type="boolean"
                                    name="validation"
                            />

                            <form-input
                                    :title="$t('Validator')"
                                    :help="$t('Validator')"
                                    v-model="form.validation_provider"
                                    rules=""
                                    type="select"
                                    :options="Validators"
                                    name="validation_provider"
                                    :placeholder="$t('Select option')"
                            />

                            <form-input
                                    :title="$t('Template')"
                                    :help="$t('The requirement template')"
                                    v-model="form.template"
                                    rules=""
                                    type="file"
                                    name="template"
                            />
                                        
                        </div>

                        <div class="d-flex justify-content-end">
                            <button :disabled="loading" type="button" class="btn btn-light mr-3 " @click="$router.push({name:'requirements.index'})">{{$t('Cancel')}}</button>
                            <button :disabled="loading" v-if="!$route.params.requirement_id" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew">{{$t('Save and create new')}}</button>
                            <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t('Save')}}</button>
                        </div>

                    </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import Multiselect from 'vue-multiselect';

    export default {
        name:'RequirementForm',
        beforeRouteEnter(to, from, next) {
            next((vm) =>  vm.from = from)
        }, 
        components:{
            Multiselect,
        },
        mixins: [formMixin],
        data(){
            return {
                title: '...',
                form: {},
                createNew: false,
                loading: false,
                from: null
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapGetters('catalogs',['resources']),
            ...mapGetters('catalogs',['validators']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Requirements"),
                        to: {name:'requirements.index'}
                    },
                   {
                        text: this.$route.params.requirement_id ? this.$t('Edit requirement'): this.$t('New requirement'),
                        active: true
                    }
                ]
            },
            Resources(){
                return this.resources.map(item=>({
                    text:this.$t(item.text),
                    value:item.value
                }))
            },
            Validators(){
                return this.validators.map(item=>({
                    text:this.$t(item.text),
                    value:item.value
                }))
            },
        },
        created() {
            if(this.$route.params.requirement_id){
                this.loading = true;
                this.getRequirement({id: this.$route.params.requirement_id})
                    .then(data=>{
                        let form = {...data}
                        this.form = form;
                        this.loading = false;
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    })
            }
        },
        methods: {
            ...mapActions('requirements',['getRequirement','storeRequirement','updateRequirement']),
            resetForm() {
                this.form = {};
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },
            saveAndCreateNew(){
                this.createNew = true;
                this.$refs.submit.click();
            },
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    return;
                }

                this.loading = true;
                let form = {...this.form};

                if(this.$route.params.requirement_id){
                    this.updateRequirement(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            this.redirect();
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }else{
                    this.storeRequirement(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Created successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            if(this.createNew){
                                this.resetForm();
                            }else{
                                this.redirect();
                            }
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }
            },
            redirect(){
                if(this.from.name){
                    this.$router.back();
                }else{
                    this.$router.push({name:'requirements.index'});
                }                
            }
        }
    }
</script>

<style>

</style>
