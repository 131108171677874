<template>
    <div class="font-family-helvetica card text-white mb-3">
        <div class="card-body text-center position-relative">
            <h5 class="text-primary_v2 font-size-24">Total:</h5>
            <h3 class="text-white font-size-31 font-weight-bold" v-if="rate">
                ${{rate.amount | money}} <span class="text-primary font-size-16">{{rate.currency}}</span>
            </h3>
            <p class="text-break font-size-11 mt-3">
                *It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "TotalCard",
    props: {
        rate: Object,
    }
}
</script>

<style lang="scss" scoped>

.card{
    opacity: 1;
    border: none;
    border-radius: 20px 0 20px 0!important;
    background-image: url("~@/assets/images/trailer_one.jpg");
    background-size: cover;
    height: 210px;
    width: 300px;

    &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 20px 0 20px 0!important;
    }

}

</style>
