<template>
  <div>
    <page-header  :title="$t('Factoring')" :items="breadcrumb"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-12">
            <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
              <template v-slot:right>
                <div class="btn-group" role="group" aria-label="toolbar">
                  <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getFactoring(filters)" type="button" class="btn btn-light">
                    <i class="far fa-sync"></i>
                  </button>
                </div>
              </template>
            </filter-bar>
          </div>
        </div>

        <factoring-table v-model="selected" :filters="filters" v-on:page-change="pageChange" />

      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import FactoringTable from "@/components/tables/FactoringTable";

export default {
  name: "Factoring",
  components: {
    FilterBar,
    FactoringTable
  },
  mixins: [filterMixin],
  data(){
    return {
      selected: [],
      options: [],
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapState('factoring', ['factoring']),
    breadcrumb(){
      const profile = this.$route.params.profile;
      return [
        {
          text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
          to: {name:'profile'}
        },
        {
          text: this.$t('Finance'),
          to: {name:'finance'}
        },
        {
          text: this.$t("Factoring"),
          active: true,
        }
      ]
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('factoring',['getFactoring']),
    init(){
      this.options = [
        {
          icon: 'far fa-key',
          label: 'ID',
          type: 'text',
          filterProperty: 'id',
        }
      ];

    }
  }
}
</script>

<style scoped>

</style>