<template>
    <div>
        <page-header :title="$t('Requirements')" :items="breadcrumb"/>
        <div class="row">
            <div class="col-md-12">
                <filter-bar :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved">
                    <template v-slot:right>
                        <div class="btn-group mb-3" role="group" aria-label="toolbar">
                            <router-link v-can="'requirements.create'" v-b-tooltip.hover :title="$t('Add')" :to="{name: 'requirements.add'}" class="btn btn-light">
                                <i class="far fa-fw fa-plus"></i>
                            </router-link>
                            <!-- <button v-can="'requirements.delete'" v-bind:disabled="selected.length === 0" v-b-tooltip.hover :title="$t('Delete')" type="button" class="btn btn-light">
                                <i class="far fa-fw fa-trash-alt"></i>
                            </button> -->
                        </div>
                    </template>
                </filter-bar>
            </div>
        </div>

        <requirement-table v-model="selected" :filters="filters" v-on:page-change="pageChange" />

    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import RequirementTable from "@/components/tables/RequirementTable";
    import FilterBar from "@/components/filters/FilterBar";
    import filterMixin from "@/mixins/filterMixin";

    export default {
        name:'Requirement',
        components:{
            RequirementTable,
            FilterBar
        },
        mixins: [filterMixin],
        data(){
            return {
                tabs: [],
                currentTab: null,
                selected: []
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapState('requirements', ['last_filters', 'requirements']),
            ...mapGetters('catalogs', ['resources']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Settings"),
                        to: {name:'settings'}
                    },
                    {
                        text: this.$t("Requirements"),
                        active: true,
                        to: {name: 'requirements.index'}
                    }
                ]
            },
            options(){
                return [
                    {
                        icon: 'far fa-key',
                        label: 'ID',
                        type: 'text',
                        filterProperty: 'id',
                    },
                    {
                        icon: 'far fa-sliders-h-square',
                        label: 'Name',
                        type: 'text',
                        filterProperty: 'name',
                    },
                    {
                        icon: 'far fa-sliders-h-square',
                        label: 'Resource',
                        type: 'single',
                        filterProperty: 'resource',
                        values: this.resources ? this.resources.map(item => ({text: this.$t(item.text), value: item.value})) : [],
                        valueType: 'number'
                    },
                ];
            }
        },
        created() {

            let toolbarFilters = {};
            for(let item of this.options){
                if(this.$route.query.hasOwnProperty(item.filterProperty)){
                    toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
                }
            }
            this.toolbarFilters = toolbarFilters;
        },
        methods: {

        }
    }
</script>

<style>

</style>
