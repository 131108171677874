import axios from "../../api/client";


// initial state
export const state = () => ({
    fetching: false,
    transfers : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    transfer: null
});

// getters
export const getters = {};

// actions
export const actions = {
    getTransfers({commit}, params){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/transfers`, {params: params})
                .then(response=>{
                    commit('setTransfers', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error.response);
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                 });
        });
    },
    complete({commit}, params){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/transfers/${params.transfer_id}/complete`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    
                 });
        });
    },
    decline({commit}, params){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/transfers/${params.transfer_id}/decline`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    
                 });
        });
    },
    downloadTemplate({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/transfers/${params.transfer_id}/template`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setTransfers(state, data){
        state.transfers = data;
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

