<template>
    <div>
        <div class="header_title_lg">
            <div class="row">
                <div class="col-12">
                    <slot />
                    <h1 class="text-white font-weight-bolder mt-5">{{title}}</h1>
                </div>
            </div>
        </div>
        <div v-if="headband" class="header_title_banner">
            <div class="header_title_banner_content">
                <div class="header_title_banner_content_separated" v-for="i in 12" :key="i" :style="{left: (i*8.333)+'%'}"/>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "HeaderTitleLarge",
    props:{
        headband : {
            type: Boolean,
            default(){
                return false
            }
        },
        title : {
            type: String,
            default(){
                return this.$t("Shipments");
            }
        }
    }
}
</script>

<style scoped>

</style>
