<template>
  <div v-else class="rate-container-loading">
    <div class="rate-title">
      &nbsp;
    </div>
    <div  class="row">
      <div class="col-10 row" >
        <div v-for="i in 2" :key="'lt'+i" class="col-md-5 column">
          <div class="secondary-rate">
            <div class="header">
              <b-skeleton  width="40%" class="px-5 mt-3" />
              <b-skeleton  width="20%" class="px-5 mt-2" />
            </div>
            <hr>
            <div class="rate">
              <b-skeleton height="70px"  width="80%" class="px-5 mt-2" />
            </div>
            <hr>
            <div class="px-5 text-center" >
              <b-skeleton  style="margin: auto" width="20%" class="px-5 mt-2" />
              <b-skeleton  style="margin: auto" width="80%" class="px-5 mt-4" />
              <b-skeleton  style="margin: auto" width="100%" class="px-5 mt-2" />
              <b-skeleton  style="margin: auto" width="60%" class="px-5 mt-2" />
              <b-skeleton  style="margin: auto" width="30%" class="px-5 mt-2" />
              <div>
                <b-skeleton type="button" width="100%" class="px-5 mt-4 mb-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RateLoading"
}
</script>

<style scoped lang="scss">

.rate-container-loading {
  padding: 2em;

  .rate-title {
    display: flex;
    padding: 1em;
    align-items: center;
    width: 100%;
    color: #999999;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }

  .principal-rate {
    box-sizing: border-box;
    height: 434px;
    width: 495px;
    border: 1px solid #DDDDDD;
    border-radius: 10px 100px 10px 10px;
    background: linear-gradient(180deg, #00B3E6 0%, #00B3E6 100%);
    box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.5);

    .rates-title {
      height: 100px;
      display: flex;
      /*justify-content: center;*/
      align-items: center;

      .title-header {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        font-family: Helvetica;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
      }

      .ribbon-wrapper {
        overflow: hidden;
        position: absolute;
        /*left: 56px;*/
        height: 100px;

        .ribbon {
          background-color: #E3ED55;
          text-align: center;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          position: relative;
          padding: 7px 0;
          top: 23px;
          left: -75px;
          width: 250px;
          color: #434619;
          font-weight: bold;
        }
      }
    }

    .rate {
      height: 87px;
      width: 498px;
      background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%);
      box-shadow: 0 2px 10px 0 rgba(153, 153, 153, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;

      strong {
        color: #00B3E6;
        font-family: Helvetica;
        font-size: 55px;
        font-weight: bold;
        letter-spacing: 0;
      }

      small {
        color: #00B3E6;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 14px;
        margin-left: 5px;
        margin-top: 20px;
      }
    }

    .beneficts {
      padding: 1em;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      span {
        color: #FFFFFF;
        font-family: Helvetica;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        font-weight: bold;
      }

      div {
        height: 28px;
        width: 416px;
        color: #FFFFFF;
        font-family: Helvetica;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        margin: 1px;
      }

      hr {
        margin: 0;
        box-sizing: border-box;
        height: 1px;
        width: 100%;
        border: 1px solid #DDDDDD;
        opacity: 0.4;
      }
    }
  }

  .secondary-rate {
    box-sizing: border-box;
    height: 100%;
    width: 495px;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.5);

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1em;
    }

    .rate {
      display: flex;
      justify-content: center;
      align-items: center;

      strong {
        color: #00B3E6;
        font-family: Helvetica;
        font-size: 55px;
        font-weight: bold;
        letter-spacing: 0;
      }

      small {
        color: #00B3E6;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 14px;
        margin-left: 5px;
        margin-top: 20px;
      }
    }

    .beneficts {
      padding: 1em;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      span {
        text-align: center;
        padding: 10px;
        font-weight: bold;
      }


      hr {
        margin: 0;
        box-sizing: border-box;
        height: 1px;
        width: 100%;
        border: 1px solid #DDDDDD;
        opacity: 0.4;
      }

      div {
        height: 28px;
        width: 416px;
        font-family: Helvetica;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        margin: 1px;
      }
    }
  }
}

</style>