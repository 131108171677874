import axios from "@/api/client";

// initial state
export const state = () => ({
    telemetry: null,
    loadId: null
});

// getters
export const getters = {
    getAssignedFuel: (state)=>(loadId)=>{
        return Number(state.loadId) === Number(loadId) ? state.telemetry.assigned_diesel : null;
    },
};

// actions
export const actions = {
    getTelemetry({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/telemetry`)
                .then(response=>{
                    commit('setTelemetry', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    fetchTelemetry({commit}, params){
        commit('setLoadId', params.id);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/telemetry`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    createTelemetry({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.id}/telemetry`, payload)
                .then(response=>{
                    commit('setTelemetry', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error)
                    reject(error.response)
                });
        });
    },
    updateTelemetry({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/loads/${payload.id}/telemetry`, payload)
                .then(response=>{
                    commit('setTelemetry', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error)
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setTelemetry(state, data){
        state.telemetry = data;
    },
    setLoadId(state, data){
        state.loadId = data;
    }
};

