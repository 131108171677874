<template>
  <div>
    <div class="row mt-4">
      <div class="col-md-8 offset-md-2">
            <div class="text-right mb-2" v-if="this.$route.name.indexOf('loads') > -1 && viewBOL">
              <button @click="confirmGenerateDocument($route.params.load_id, 'bol')" class="btn btn-primary mr-1">
                <i class="far fa-file mr-2"></i>
                {{ getTextBol }}
              </button>
            </div>
            <div v-if="invoices.length > 0 && invoices[0].status === 'failed'" class="alert alert-danger mt-2" role="alert">
              <span class="font-weight-bold">{{ $t('An error occurred when generating the waybill: ') }}</span>
              <span class="font-weight-light">
                      {{ getErrorInvoice }}
                  </span>
            </div>
            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': type === 'legal_doc'}" href="javascript:void(0);" @click="type = 'legal_doc'">{{$t('Legal')}}</a>
                    </li>
                    <li class="nav-item" v-if="this.$route.params.profile !== 'driver'">
                        <a class="nav-link" :class="{'active': type === 'invoice'}" href="javascript:void(0);" @click="type = 'invoice'">{{$t('Invoices')}}</a>
                    </li>
                    </ul>
                </div>
                <div class="card-body">
                  <div v-show="type === 'legal_doc'">
                    <documents :title="$t('Documents')" type="legal_doc" />
                  </div>
                  <div v-show="type === 'invoice'" v-if="this.$route.params.profile !== 'driver'">
                    <documents :title="$t('Invoices')" type="invoice" />
                  </div>
                </div>
            </div>
      </div>
    </div>
  </div>

</template>

<script>

import {mapActions, mapState} from 'vuex';

import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import fancyBox from 'vue-fancybox';
import Swal from 'sweetalert2';
import Documents from "@/components/common/LoadDocuments";


export default {
  name: "LoadDocument",
  components: {
    Documents
  },
  data() {
    return {
      loading:true,
      galleryVisible: false,
      items: [],
      index: 0,
      type: 'legal_doc'
    }
  },
  computed: {
    ...mapState('loads',['documents', 'load', 'merchandises','invoices']),
    ...mapState('profile',['profile']),
    viewBOL() {
      let status = ['not_assigned', 'pending', 'cancelled', 'disabled'];
      let invoicesCertified = this.invoices.filter(item => item.status === 'certified');
      if (status.includes(this.load.status) || invoicesCertified.length >= 2)
        return false;
      else
        return true;
    },
    getTextBol() {
      if (!this.invoices || this.invoices.length === 0 || this.invoices[0].status === 'pending')
        return this.$t('BOL')
      else if (this.invoices[0].status === 'failed')
        return this.$t('Retry BOL')
      else if (this.invoices.length === 1 && this.invoices[0].status === 'certified')
        return this.$t('Create substitution')
      else if (this.invoices.length > 1 && (this.invoices[1].status === 'pending' || this.invoices[1].status === 'failed')){
        return this.$t('Retry substitution')
      }

      return this.$t('Create substitution');
    },
    getErrorInvoice() {
      if (this.invoices.length > 0  && this.invoices[0].status === 'failed') {
        return this.invoices[0].error?.user_error_description ?? this.invoices[0].error?.cause?.type ?? this.invoices[0].error?.Error;
      }
      return undefined
    },
  },
  created() {
      const getDocuments = this.getDocuments({ id: this.$route.params.load_id})
      const getMerchandises = this.getMerchandises({id: this.load.shipment_id ? this.load.shipment_id : this.load.id });
      const getInvoices = this.getInvoices({id: this.load.shipment_id });

      Promise.all([getDocuments, getMerchandises, getInvoices])
              .finally(() => {
                this.loading = false;
              });
  },
  methods: {
      ...mapActions('loads',['getDocuments','storeDocument', 'generateDocuments', 'getMerchandises', 'getLoad','getInvoices']),
    appendFile(){
      this.loading = true;
      this.getDocuments({ id: this.$route.params.load_id}).then(data =>{
        this.loading = false;
      });
    },
    showFile(index){
      this.index = index
      this.galleryVisible = true
    },
    open (e) {
      fancyBox(e.target, this.imageList);
    },
    getPath (path) {
      try {
        let data = JSON.parse(path)
        return data[0].normal
      }catch (e){
        return path;
      }
    },
    confirmGenerateDocument(load_id, type){
                if (!this.validateBOL(type)) return;
                Swal.fire({
                    title: this.$t("Are you sure to generate the document?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, generate it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                      if (type === 'bol') {
                        this.generateDocuments({id: load_id, type: type})
                            .then(data=>{
                              Echo.private(`jobs.${data.job_id}`)
                                  .listen('JobProcessed', (e) => {
                                      this.getDocuments({ id: this.$route.params.load_id});
                                      this.getInvoices({id: this.load.shipment_id });
                                      this.getLoad({ id: this.$route.params.load_id});
                                      Echo.leave(`jobs.${data.job_id}`);
                                  });
                                this.$root.$bvToast.toast(this.$t("Document creation has been submitted successfull."), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                    });
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                      } else {
                        this.generateDocuments({id: load_id})
                            .then(data=>{
                              Echo.private(`jobs.${data.job_id}`)
                                  .listen('JobProcessed', (e) => {
                                      this.getDocuments({ id: this.$route.params.load_id });
                                      Echo.leave(`jobs.${data.job_id}`);
                                  });
                                this.$root.$bvToast.toast(this.$t("Your document will be created."), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                    });
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                      }
                    }
                });
            },
      validateBOL(type){

        let error = false;
        let message = "";

        if (type === 'bol') {
          if (Object.keys(this.merchandises).length === 0) {
            error = true;
            message = "There are no merchandises linked to the shipment";
          }

          if (!this.load.driver) {
            error = true;
            message = "There are no driver assigned to the load";
          }

          if (!this.load.truck) {
            error = true;
            message = "There are no truck assigned to the load";
          }

          if (!this.load.carrier) {
            error = true;
            message = "There are no carrier assigned to the load";
          }
        }

        if (error) {
          Swal.fire(
              "Error",
              this.$t(message),
              "error"
          );
          return false;
        } else {
          return true;
        }
      }
  }
}
</script>

<style scoped>

</style>