<template>
    <div class="row px-4 py-2 col-10">
        <div class="col-md-8">
            <div class="font-weight-bolder font-size-18 mb-4">
                {{ $t('Do you want to add any of our additional products to your shipment?') }}
            </div>
            <gatas-card />
            <insurance-card />
            <certificate-card />
        </div>
        <div class="col-md-4">
            <pay-list />
            <div style="width: 300px;">
                <button type="button"
                    class="btn btn-primary_v2 btn-block text-uppercase font-size-12 font-weight-bolder mt-3"
                    @click="$emit('next')">
                    {{ $t('Continue') }}
                </button>
                <button type="button"
                    class="btn btn-outline-light btn-block text-uppercase font-size-12 font-weight-bolder mt-3"
                        @click="$emit('back')">
                    {{ $t('Go Back') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GatasCard from '@/components/marketplace/accesories/GatasCard.vue';
import InsuranceCard from '@/components/marketplace/accesories/InsuranceCard.vue';
import CertificateCard from '@/components/marketplace/accesories/CertificateCard.vue';
import PayList from '@/components/marketplace/accesories/PayList.vue';

/**
 * Componente que renderiza los detalles del servicio
 */
export default {
    name: "ServiceDetailForm",
    components: {
        GatasCard,
        PayList,
        InsuranceCard,
        CertificateCard
    },
}
</script>

<style scoped>
.font-family-helvetica {
    font-family: Helvetica;
}
</style>
