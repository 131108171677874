<template>
    <div class="row px-3">
        <div class="col-12 mb-4">
            <polyline-map :center="center" :path="path" :class="'map'" :markers="markers" />
        </div>
        <div class="col-md-6 col-12">
            <shipment-info :edit="true" />
        </div>
        <div class="col-md-6 col-12">
            <summary-equipment :edit="true" />
            <buttons-steps :textNext="'Confirm'" :complete="true" :next="true" :back="true"
                @eventBack="$emit('preview', 3)" @eventNext="specialProject()" />
        </div>
    </div>
</template>

<script>
import PolylineMap from "../../common/PolylineMap.vue";
import HeaderAddress from "../HeaderAddress.vue";
import ButtonsSteps from "../../common/ButtonsSteps.vue";
import { mapGetters, mapMutations } from "vuex";
import SummaryEquipment from '@/components/marketplace/equipment/SummaryEquipment';
import ShipmentInfo from "../ShipmentInfo.vue";

export default {
    name: "ConfirmLoadView",
    components: {
        PolylineMap,
        HeaderAddress,
        ButtonsSteps,
        SummaryEquipment,
        ShipmentInfo
    },
    computed: {
        ...mapGetters('marketplace', ['route', 'shipment', 'origin', 'destination'])
    },
    created() {
        this.center = { lat: this.origin.address.location[0], lng: this.origin.address.location[1] };
        this.markers = [
            { lat: this.origin.address.location[0], lng: this.origin.address.location[1] },
            { lat: this.destination.address.location[0], lng: this.destination.address.location[1] }
        ]
        this.path = this.route.map((item) => {
            return {
                lat: item[0],
                lng: item[1]
            }
        });
    },
    data() {
        return {
            center: {},
            markers: {},
            path: [],
        }
    },
    methods: {
        ...mapMutations('marketplace', ['validateConfirmation', 'setStep']),
        specialProject() {
            //validate if is special project
            let origin_country = this.shipment.origin.address.country
            let destination_country = this.shipment.origin.address.country
            let trailer_type_code = this.shipment.equipment_type.trailer_type_code

            if (origin_country === 'MX' && destination_country === 'MX' && ['R_REF', 'R_SEC'].includes(trailer_type_code)) {
                this.validateConfirmation();
                this.next()
            } else {
                this.setStep(9);
            }
        },
    },
    props: {
        preview: {
            type: Function
        },
        next: {
            type: Function
        },
        equipmentStep: {
            type: Function
        },
        originStep: {
            type: Function
        },
        destinationStep: {
            type: Function
        }
    },
}
</script>
<style lang="scss">
.confirm-container {
    width: 100%;
}

.map-container {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;

    .add-stop-button {
        margin-top: 10px;
        position: absolute;
        z-index: 9;
        align-self: flex-end;
        border: solid 1px;
        border-radius: 50%;
        padding: 5px;
        height: 30px;
        width: 180px;
        border-radius: 15px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(136, 136, 136, 0.5);
        display: flex;
        align-items: center;

        &:hover {
            box-shadow: 10px 5px 5px black;
            cursor: pointer;
        }

        strong {
            height: 14px;
            width: 200px;
            color: #000000;
            font-family: Helvetica;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 14px;
            padding: 2px;
        }

        span {
            position: absolute;
            z-index: 9;
            height: 50px;
            width: 50px;
            top: -11px;
            right: -1px;
            background-color: #1CBB8C;
            box-shadow: 0 2px 6px 0 rgba(87, 87, 87, 0.5);
            color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
}

.buttons-container {
    display: flex;
    justify-content: flex-end;

    .buttons {
        display: flex;

        button {
            margin-left: 5px;
        }
    }
}

.data_container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 20px;


    &_equipment {
        display: flex;
        flex-direction: column;
        padding: 2em;
        width: 30%;

        &.blue {
            border-right: 3px solid #00B3E6;
        }

        &.purple {
            border-right: 3px solid #1163EA;
        }

        &_item {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;

            &_title {
                height: 14px;
                width: 32px;
                color: #888888;
                font-family: Helvetica;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 14px;
            }

            &_body {
                width: 100%;
                display: flex;

                &.column {
                    flex-direction: column;
                    margin-bottom: 1em;
                    justify-content: flex-start;
                }

                &_title {
                    height: 17px;
                    width: 153px;
                    color: #000000;
                    font-family: Helvetica;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 17px;
                    margin-right: 10px;
                    width: 100%;
                    margin-bottom: 10px;
                }

                &_content {
                    width: 100%;
                    color: #888888;
                    font-family: Helvetica;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 14px;

                    &_shipment {
                        margin-bottom: 5px;
                    }
                }

                &_link {
                    color: #00B3E6;
                    width: 150px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

        }
    }

}
</style>
