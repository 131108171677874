<template>
    <multiselect v-model="selected" :options="options" open-direction="bottom" :close-on-select="true" 
                :clear-on-select="false" :preserve-search="true"
                label="name" track-by="id" :placeholder="$t('Select')" select-label="" :selected-label="$t('Selected')" 
                :searchable="true" :loading="isLoading" :internal-search="false" @search-change="onChange">
        <template slot="singleLabel" slot-scope="props">
            <span class="option__title">{{ props.option.name }}</span>
        </template>
        <template slot="option" slot-scope="props">
            <span class="option__title">{{ props.option.name }}</span>
        </template>
    </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'

export default {
    name:'CarrierInput',
    components: {
        Multiselect
    },
    props: {
        filters: {
            type: Object,
            default(){return {}}
        },
        value:{
            required: true
        }
    },
    data(){
        return {
            selected : null,
            options: [],
            isLoading: false,
            query: null
        }
    },
    computed: {
        localFilters(){
            if(this.query)
                return {...this.filters, search: this.query};
            return this.filters;
        }
    },
    watch:{
        selected: {
            deep:true,
            handler(newValue, oldValue){
                this.$emit('input', newValue);
                this.$emit('change', newValue);
            }
        },
        value: {
            deep:true,
            handler(newValue){
                this.selected = newValue;
            }
        }
    },
    created(){
        this.selected = this.value;
        this.doSearch = _.debounce(this.fetchData, 500);
        this.fetchData();
    },
    methods: {
        ...mapActions('accounts',['fetchCarriers']),
        onChange(query){
            this.query = query;
            this.doSearch();
        },
        fetchData(){
          this.fetchCarriers(this.localFilters)
            .then(data=>{
                this.options = data.results;
            })
            .catch(error =>{

            })   
        }
    }
}
</script>
