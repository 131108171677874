import axios from "../../api/client";


// initial state
export const state = () => ({
    fetching: false,
    ediCarrier: null,
});

// getters
export const getters = {

};

// actions
export const actions = {
    getEdiCarrier({ commit }, carrier_code) {
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios
                .get(`/edi/carriers/${carrier_code}`)
                .then(response => {
                    commit('setEdiCarrier', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    commit('setEdiCarrier', null);
                    reject(error.response);
                })
                .finally(() => {
                    commit('setFetching', false);
                });
        });
    },
    storeEdiCarrierLocation({ commit, state }, payload) {
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios
                .put(`/edi/carriers/${state.ediCarrier.id}/locations`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response);
                })
                .finally(() => {
                    commit('setFetching', false);
                });
        });
    },
};

// mutations
export const mutations = {
    setFetching(state, data){
        state.fetching = data;
    },
    setEdiCarrier(state, data){
        state.ediCarrier = data;
    }
};
