<template>
  <div>
    <page-header :title="$t($route.meta.title)" :items="breadcrumb"/>
    <p>
      {{$t($route.meta.description)}}
    </p>

    <div v-if="form_id" class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-12">
            <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
              <template  v-slot:right>
                <div class="btn-group" role="group" aria-label="toolbar">
                  <button  v-b-tooltip.hover :title="$t('Refresh')" @click="fetchFields" type="button" class="btn btn-light">
                    <i class="far fa-sync"></i>
                  </button>
                  <router-link v-b-tooltip.hover :title="$t('Add')" :to="{name: $route.meta.routes.add, params:{form_id: form_id}}" class="btn btn-light">
                    <i class="far fa-plus"></i>
                  </router-link>
                  <!--
                  <button v-b-tooltip.hover :title="$t('Import')" @click="$refs.import.click()" type="button" class="btn btn-light">
                    <i class="far fa-file-import"></i>
                  </button>
                  <button v-b-tooltip.hover :title="$t('Export')" type="button" class="btn btn-light" @click="download">
                    <i class="far fa-file-export"></i>
                  </button>
                  -->
                </div>
                <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
              </template>
            </filter-bar>
          </div>
        </div>

        <field-table v-model="selected" :filters="filters" :edit="$route.meta.routes.edit" :form-id="form_id" />

      </div>
    </div>
    <loading v-else />
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import deleteManyMixin from "@/mixins/deleteMany";
import FieldTable from "@/components/tables/FieldTable";

export default {
  name:'LoadFields',
  components:{
    FilterBar,
    FieldTable
  },
  mixins: [filterMixin, deleteManyMixin],
  data(){
    return {
      options: [],
      selected: [],
      form_id: null
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    breadcrumb(){
      const profile = this.$route.params.profile;
      return [
        {
          text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
          to: {name:'profile'}
        },
        {
          text: this.$t(this.$route.meta.title),
          active: true,
        }
      ]
    },
  },
  async created() {
    this.form_id = this.profile.profile.load_form_id
    if(!this.form_id){
      // Create load form
      let payload = {name:'Load form',type:'load-form'};
      try{
        let form = await this.storeForm(payload);
        let account = _.cloneDeep(this.profile.account);
        account.profile.load_form_id = form.id;
        await this.updateAccount(account);
        await this.getProfile();
        this.form_id = form.id;
      }catch (e) {
        // TODO: Show error message
      }
    }
    this.init();
  },
  watch:{
    '$route': 'init'
  },
  methods: {
    ...mapActions('forms',['storeForm','getFields']),
    ...mapActions('accounts',['updateAccount']),
    ...mapActions('profile',['getProfile']),
    init(){
      let filters = {form_id: this.form_id};

      // Use meta filters
      if(this.$route.meta.paramFilters){
        for(let key of this.$route.meta.paramFilters){
          filters[key] = this.$route.params[key];
        }
      }
      if(this.$route.meta.filters){
        filters = {...filters, ...this.$route.meta.filters};
      }

      this.defaultFilters = filters;

      this.options = [

      ];

      let toolbarFilters = {};
      for(let item of this.options){
        if(this.$route.query.hasOwnProperty(item.filterProperty)){
          toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
        }
      }
      this.toolbarFilters = toolbarFilters;
    },
    fetchFields(){
      this.getFields(this.filters)
        .then(data=>{

        })
        .catch(error =>{
        })
    },
    download(){
      this.exportTrucks(this.filters)
          .then(()=>{

          })
          .catch(error =>{

          })
    },
    deleteMany(){
      return new Promise((resolve, reject)=>{
        this.deleteManyFields({ids: this.selected.join(',')})
            .then(data=>{
              this.selected = [];
              this.fetchFields();
              resolve(data);
            })
            .catch(error =>{
              reject(error);
            })
      })
    },
    clearFile(){
      this.$refs.import.value=null;
      this.carrier = null;
    },
    handleFile(){
      let payload= {template : this.$refs.import.files[0]};
      if(this.defaultFilters.carrier_id){
        payload.carrier_id = this.defaultFilters.carrier_id;
      }else{
        if(this.$route.params.profile ==='admin'){

        }
      }
      this.importFields(payload)
          .then(data=>{

          })
          .catch(error =>{

          })
          .finally(()=>{
            this.clearFile();
          });

    }
  }
}
</script>

<style>

</style>
