<template>
    <multiselect v-model="selected" :disabled="disabled" :multiple="multiple" :options="options" open-direction="bottom" :close-on-select="true"
                :clear-on-select="false" :preserve-search="true"
                label="plate" track-by="id" :placeholder="$t('Select')" select-label="" :selected-label="$t('Selected')"
                :searchable="true" :loading="isLoading" :internal-search="false" @search-change="onChange">
      <template slot="singleLabel" slot-scope="props">
        <span class="option__title">{{ props.option.plate }} {{ props.option.tms_id ? `(${props.option.tms_id})`: ''}}</span>
      </template>
      <template slot="option" slot-scope="props">
        <span class="option__title">{{ props.option.plate }} {{ props.option.tms_id ? `(${props.option.tms_id})`: ''}}</span>
      </template>
    </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'

export default {
    name:'TrailerInput',
    components: {
        Multiselect
    },
    props: {
        filters: {
            type: Object,
            default(){return {}}
        },
        value:{
            required: true
        },
      multiple:{
        type: Boolean,
        default(){
          return false
        }
      },
    disabled:{
            type : Boolean,
            default(){
                return false;
            }
        },
    },
    data(){
        return {
            selected : null,
            options: [],
            isLoading: false,
            query: null
        }
    },
    computed: {
        localFilters(){
            if(this.query)
                return {...this.filters, search: this.query};
            return this.filters;
        }
    },
    watch:{
        selected: {
            deep:true,
            handler(newValue, oldValue){
                this.$emit('input', newValue);
            }
        },
        value: {
            deep:true,
            handler(newValue){
                this.selected = newValue;
            }
        }
    },
    created(){
        this.selected = this.value;
        this.doSearch = _.debounce(this.fetchData, 500);
        this.fetchData();
    },
    methods: {
        ...mapActions('trailers',['fetchTrailers']),
        onChange(query){
            this.query = query;
            this.doSearch();
        },
        fetchData(){
          this.fetchTrailers(_.cloneDeep(this.localFilters))
            .then(data=>{
                this.options = data.results;
            })
            .catch(error =>{

            })
        }
    }
}
</script>
