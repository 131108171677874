<template>

  <div class="main_container">
    <div class="header_line"></div>
    <div class="background_container">
      <div class="background_container_logo">
        <img src="@/assets/images/txp/txp_gray.png" class="background_container_logo_img"  alt="">
      </div>
      <div class="background_container_card">
        <h3>
          <span>La nueva forma de </span> <br>
          mover al munto
        </h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid atque debitis dolores</p>
      </div>
      <div class="background_container_bagde">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </div>
    <div class="content">
      <div class="btn btn-primary out_btn" @click="logOutAction"> {{ $t('Logout') }}</div>
      <over-view v-if="step===0"  v-on:next="next" />

      <form-onboarding v-if="step===1" :profile="role" v-on:back="back" />
    </div>
  </div>



</template>

<script>
import "@/assets/scss/style.scss";
import OverView from "./OverView";
import Form from "./FormOnboarding";
import FormOnboarding from "./FormOnboarding";
import {signOut,getUser} from '@/utils/auth.js'
export default {
  name: "OnBoarding",
  components: {FormOnboarding, Form, OverView},
  data(){
    return {
      step:0,
      role: null
    }
  },
  methods:{
    next(role){
      this.step = 1;
      this.role = role;
    },
    back(){
      this.step = 0;
      this.role = null;
    },
    logOutAction(role){
      signOut().then((data) => {
        console.log('DONE', data)}).catch((err) => console.log('SIGN OUT ERR', err));
    },
  }
}
</script>
<style scoped>
.out_btn{
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 12px;
}
</style>