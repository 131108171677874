<template>
    <div>
        <checklist-form v-if="false" />
        <div class="row mt-4">
            <div class="col-md-8 offset-md-2">
                <loading v-if="loading" />
                <div v-else class="d-flex justify-content-end mb-3 mt-3">
                    <button @click="download" class="btn btn-secondary mr-2">
                      <i class="far fa-download mr-2" />{{$t('Download')}}
                    </button>
                    <button v-can="'loads.checklist.management'" @click="confirmPodCompleted" class="btn btn-secondary ">
                      <i class="far fa-clipboard-list-check mr-2" />{{$t('POD completed')}}
                    </button>

                    <b-modal no-close-on-backdrop
                             id="modal-backdrop"
                             :title="$t('Select a stop to continue')"
                             title-class="font-18"
                             cancel-variant="light"
                             :ok-title="$t('Next')"
                             :cancel-title="$t('Cancel')"
                             :ok-disabled="!stop"
                             v-on:ok="createNewChecklist"
                    >
                       <div>
                           <multiselect v-model="stop" :options="formattedStops" :placeholder="$t('Select an option')"
                                         track-by="value" label="text" select-label="" selected-label="" />
                       </div>
                    </b-modal>

                </div>
                <div v-if="empty">
                    <empty-results :subtitle="$t('This shipment does not have a checklist')"/>
                </div>
                <div class="card" :key="'stop'+instance.id" v-for="(instance, cIndex) in items">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                          <h6 class="mb-0" >{{instance.form.name}}</h6>
                          <div class="type_load" v-if="instance.owner.load">
                            <span class="font-weight-bold" v-if="instance.owner.load.type === 'SHIPMENT'">{{$t('Shipment')}}</span>
                            <span class="font-weight-bold" v-else>{{$t('Load')}}</span>
                            <span class="load_id font-weight-bold text-primary " style="color: #00B3E6 !important" >{{ instance.owner.load.tms_shipment_id ? instance.owner.load.tms_shipment_id : instance.owner.load_id }}</span>
                          </div>
                        </div>
                    </div>
                    <div class="form-fields">

                        <template v-for="(field, idx) in instance.form.fields">
                            <template v-if="['pod'].indexOf(field.type) > -1">
                                <div class="field-wrapper" :key="'field-pod-'+field.id" >
                                    <div >
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{field.title}}</div>
                                                <div class="field-help">{{field.help}}</div>
                                            </div>
                                            <div class="col-md-6">
                                              <div v-if="field.answer_updated_at" class="field-label float-right">{{ $t('Last update') }}: {{ field.answer_updated_at | timestamp }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="readOnly === false" class="p-4">
                                      <file-input ref="input_pod"
                                                  path="/api/v2/upload/temporary-url"
                                                  :usePresignedUrl="true"
                                                  :payload="{type: 'pod', load_id: load._id}"
                                                  :options="{dictDefaultMessage: field.title, acceptedFiles: 'image/*'}"
                                                  :multiple="true"
                                                  v-on:success="data => uploadPdf(instance.owner.id, instance.id, field.id, data)"
                                      />
                                    </div>
                                    <div class="">
                                      <loading v-if="loading_files"/>
                                      <div class="" >

                                        <template v-if="field.answer">
                                          <div class="d-flex justify-content-center flex-wrap p-3">
                                            <vue-easy-lightbox
                                                :visible="preview_pod"
                                                :index="0"
                                                :imgs="[url_active]"
                                                @hide="preview_pod = false"
                                            ></vue-easy-lightbox>
                                            <div class="card" style="width: 100%"  :key="'fl'+gIndex" v-for="(item, gIndex) in field.answer_metadata">
                                              <div class="card-body">
                                                <div class="row align-items-center">
                                                  <div class="col-md-1 col-sm-1">
                                                    <template v-if="getExtension(item) === 'pdf'">
                                                      <a class="mr-0"  v-if="item.preview"   href="javascript:void(0);"  @click="showPdf(item.path)"  >
                                                        <img :src="item.preview"
                                                             alt class="hover_zoom"
                                                             width="45"
                                                             height="52"
                                                             style="object-fit: cover;" />
                                                      </a>
                                                      <a class="mr-0" v-else target="popup" href="javascript:void(0);" @click="showPdf(item.path)" >
                                                        <i class="far fa-file-pdf" style="font-size: 3rem;" />
                                                      </a>
                                                    </template>
                                                    <template v-if="getExtension(item) === 'xml'">
                                                      <a class="mr-0" target="popup" href="javascript:void(0);" @click="showPdf(item.path)" >
                                                        <i class="far fa-file-excel" style="font-size: 2rem;" />
                                                      </a>
                                                    </template>
                                                    <template v-if="getExtension(item) === 'xls' || item.extension === 'xlsx'">
                                                      <a class="mr-0" target="popup" href="javascript:void(0);" @click="showPdf(item.path)" >
                                                        <i class="far fa-file-spreadsheet" style="font-size: 2rem;" />
                                                      </a>
                                                    </template>
                                                    <template v-if="imgExtensions.indexOf(getExtension(item)) > -1">
                                                      <a class="mr-0"  href="javascript:void(0);" @click="showImage(item.path)" >
                                                        <img :src="getPath(item.path)"
                                                             alt
                                                             width="45"
                                                             height="52"
                                                             style="object-fit: cover;" />
                                                      </a>
                                                    </template>
                                                  </div>
                                                  <div class="col-md-4 col-sm-4">
                                                    <div>
                                                      <h6 class="text-muted">{{$t('Name')}}</h6>
                                                    </div>
                                                    <div>
                                                      <h6 class="one-line">
                                                        <template v-if="getExtension(item) === 'pdf'">
                                                          <a class="mr-0 text-primary" target="popup" href="javascript:void(0);" @click="showPdf(item.path)">
                                                            {{item.filename}}
                                                          </a>
                                                        </template>
                                                        <template v-else-if="imgExtensions.indexOf(getExtension(item)) > -1">
                                                          <a class="mr-0 text-primary" href="javascript:void(0);" @click="showImage(item.path)" >
                                                            {{item.filename}}
                                                          </a>
                                                        </template>
                                                        <template v-else>
                                                          <a class="mr-0 text-primary" target="popup" href="javascript:void(0);" @click="showPdf(item.path)">
                                                            {{item.filename}}
                                                          </a>
                                                        </template>
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-3 col-sm-3">
                                                    <div>
                                                      <h6 class="text-muted">{{$t('Upload at')}}:</h6>
                                                    </div>
                                                    <div>
                                                      <h6 class="one-line">{{item.created_at | timestamp('YYYY-MM-DD HH:mm')}}</h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-3 col-sm-4">
                                                    <div>
                                                      <h6 class="text-muted">{{$t('Upload by')}}:</h6>
                                                    </div>
                                                    <div>
                                                      <h6 class="one-line" v-if="item.user">{{item.user.fullname}}</h6>
                                                      <h6 class="one-line" v-else>--</h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-1 col-sm-1">
                                                    <div class="d-flex justify-content-sm-end px-2">
                                                      <a class=" cursor-pointer text-black"  v-b-tooltip.hover :title="$t('Delete')"  @click="confirmDelete(instance.owner.id, instance.id, field.id, item.path)" >
                                                        <i class="far fa-trash-alt btn-icon" />
                                                      </a>
                                                    </div>
                                                  </div>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                        <template v-else>
                                          <empty-results :title="$t('No photos')" :subtitle="$t('No photos have been uploaded')" />
                                        </template>
                                      </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="['gallery'].indexOf(field.type) > -1">

                                <div class="field-wrapper" :key="'field-'+field.id" >
                                    <div >
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{field.title}}</div>
                                                <div class="field-help">{{field.help}}</div>
                                            </div>
                                            <div class="col-md-6">
                                              <div v-if="field.answer_updated_at" class="field-label float-right">{{ $t('Last update') }}: {{ field.answer_updated_at | timestamp }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="readOnly === false" class="p-4">
                                        <file-input
                                                path="/api/v2/upload"
                                                :options="{dictDefaultMessage: field.title, acceptedFiles: 'image/*'}"
                                                v-on:success="data=>appendImage(instance.owner.id, instance.id, field.id, data.path)"
                                        />

                                    </div>
                                    <template v-if="field.answer">
                                        <div class="d-flex justify-content-center flex-wrap p-3">
                                            <a
                                                    v-for="(item, gIndex) in field.answer"
                                                    :key="field.id+'-'+gIndex"
                                                    @click="() => showGallery(cIndex, idx, gIndex)"
                                                    class="cursor-pointer"
                                            >
                                                <div class="img-fluid">
                                                    <img :src="`${item}`" alt width="80" height="80" style="object-fit: cover;" />
                                                </div>
                                            </a>
                                        </div>
                                        <vue-easy-lightbox
                                                :visible="field.galleryVisible"
                                                :index="index"
                                                :imgs="field.answer"
                                                @hide="field.galleryVisible = false"
                                        ></vue-easy-lightbox>
                                    </template>
                                    <template v-else>
                                        <empty-results :title="$t('No photos')" :subtitle="$t('No photos have been uploaded')" />
                                    </template>
                                </div>
                            </template>
                            <form-input
                                    v-else
                                    :key="'field-'+field.id"
                                    :title="field.title"
                                    :help="field.help"
                                    v-model="field.answer"
                                    :type="field.type"
                                    :name="'field.'+idx+'.title'"
                                    :options="field.options"
                                    :read-only="readOnly"
                                    v-on:change="(value)=>saveAnswer(instance.owner.id, instance.id, field.id, value)"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ChecklistForm from "./ChecklistForm";
    import {mapActions, mapState} from 'vuex';
    import FileInput from "../../components/form/FileInput";
    import VueEasyLightbox from "vue-easy-lightbox";
    import Multiselect from 'vue-multiselect';
    import addressFormat from "../../helpers/address-format";
    import formMixin from '@/mixins/formMixin';
    import Swal from "sweetalert2";


    export default {
        name: "Checklist",
        components:{
            ChecklistForm,
            FileInput,
            VueEasyLightbox,
            Multiselect
        },
        mixins:[formMixin],
        data(){
            return {
                loading: false,
                loading_files: false,
                url_active: null,
                preview_pod: null,
                items: [],
                index : 0,
                readOnly: false,
                stop: null,
                empty: false,
                downloading: false,
                imgExtensions : ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'svg', 'svgz', 'cgm', 'djv', 'djvu', 'ico', 'ief','jpe', 'pbm', 'pgm', 'pnm', 'ppm', 'ras', 'rgb', 'tif', 'tiff', 'wbmp', 'xbm', 'xpm', 'xwd']
            }
        },
        mounted() {

        },
        computed:{
            ...mapState('loads',['checklist','load','stops','issues_pending']),
            ...mapState('profile',['profile']),
            formattedStops(){
                return this.stops.map((item,index) => {
                    let lines = addressFormat(item.address);
                    let  prefix = `${this.$t('Stop')} ${item.order}`
                    if(index === 0){
                        prefix = `${prefix} - ${this.$t('Pickup')}`;
                    }else if(index === (this.stops.length -1)){
                        prefix = `${prefix} - ${this.$t('Delivery')}`;
                    }
                    return {
                        text: `[${prefix}] ${lines.join(', ')}`,
                        value: item.id
                    }
                })
            },
            isEditable(){
              if(!this.load)
                return false;
              if(this.$route.params.profile === 'admin' && this.load.carrier){
                return true;
              }
              if(this.$route.params.profile === 'shipper'){
                return this.load.shipper.id === this.profile.profile.id;
              }
              return false;
            },
            canBeSendPOD(){
              return this.issues_pending.length === 0  && this.load.status === 'delivered';
            }
        },
        watch: {
            checklist: {
                deep: true,

                handler(value){
                    this.items = _.cloneDeep(value);
                }
            }
        },
        created() {
          this.loading = true;
          this.getIssuesPending({load_id: this.$route.params.load_id});
          if(this.profile.profile.object === 'shipper'){
            this.readOnly = true;
          }
          this.getAllChecklist({id: this.$route.params.load_id}).then(data=>{
            this.items = _.cloneDeep(this.checklist);
            if(data.length === 0){
              this.empty = true;
            }
          }).catch(error =>{

          }).finally(()=>{
            this.loading = false;
          });
        },
        methods:{
            ...mapActions('loads',['getAllChecklist','storeChecklistAnswer','downloadPOD','podCompleted','getLoad',"getIssuesPending"]),
            onClickGallery(slide){
                // TODO: remove image  (slide.index)

            },
            saveAnswer(stopId, checklistId,fieldId, value,msg="Your item has been updated.")
            {
                let payload = {
                    load_id : this.$route.params.load_id,
                    stop_id: stopId,
                    checklist_id : checklistId,
                    field_id: fieldId,
                    value: value
                };
                this.storeChecklistAnswer(payload)
                    .then(data=>{
                        this.getAllChecklist({id: this.$route.params.load_id}).finally(()=>{
                          this.loading_files = false;
                          this.$root.$bvToast.toast(this.$t(msg, {resource: this.$t("Field").toLowerCase()}), {
                            title: this.$t('Success'),
                            variant: 'success',
                            solid: true
                          });
                        })
                    })
                    .catch(error =>{

                    })
            },
            appendImage(stopId, checklistId,fieldId, url){
                let checklist = _.cloneDeep(this.checklist);
                let form = checklist.find(item => item.id === checklistId);
                let field = form.form.fields.find(item => item.id === fieldId);
                let answer = field.answer ? field.answer : [];
                if(Array.isArray(url)){
                  answer = answer.concat(url)
                }else{
                  answer.push(url);
                }
                
                this.loading_files = true;
                this.saveAnswer(stopId, checklistId, fieldId, answer);
            },
            showGallery(checklistIndex, fieldIndex, index) {
                this.index = index;
                let items = _.cloneDeep(this.items);
                items[checklistIndex].form.fields[fieldIndex].galleryVisible = true;
                this.items = items;
            },
            createNewChecklist(){
                this.$router.push({
                    name: 'loads.detail.checklist.add',
                    params: {load_id: this.$route.params.load_id, stop_id: this.stop.value}
                });
            },
            download(){
              let payload = {
                id : this.$route.params.load_id,
              };
              this.downloading = true;
              this.downloadPOD(payload)
                  .then(data =>{

                  })
                  .catch(error =>{
                    this.processServiceError(error);
                  })
                  .finally(()=>{
                    this.downloading = false;
                  });
            },
            confirmPodCompleted(){
              if (this.canBeSendPOD){
                Swal.fire({
                  title: this.$t("Are you sure?"),
                  html: this.$t("You won't be able to revert this!"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: this.$t("Accept"),
                  cancelButtonText: this.$t("Cancel"),
                  reverseButtons: true
                })
                    .then(result => {
                      if(result.value) {
                        this.podCompleted({
                          id : this.$route.params.load_id,
                        })
                            .then(data =>{
                              this.getLoad({id: this.$route.params.load_id});
                              this.$root.$bvToast.toast(this.$t("The action was completed successfully"), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                              });
                            })
                            .catch(error =>{
                              this.$root.$bvToast.toast(this.$t("There are issues not closed"), {
                                title: this.$t('Error'),
                                variant: 'danger',
                                solid: true
                              });
                              this.processServiceError(error);
                            });
                      }

                    });
              }else{
                let msgs = [];
                this.issues_pending.map((item)=>{
                  msgs.push(this.$t("The issues '{issue_name}' is in the {status} status", {issue_name: item.type.name.toUpperCase() ,status:this.$t(item.status).toUpperCase()  }))
                });
                Swal.fire({
                  icon: 'error',
                  title: this.$t("There are issues not closed"),
                  html: "<ul><li>"+msgs.join("  </li><li> ")+"</ul>",
                })
              }

            },
            getExtension(item) {
              return item.extension ?  item.extension.toLowerCase() : item.filename.match(new RegExp('[^.]+$'))[0].toLowerCase();
            },
            getPath (path) {
              try {
                let data = JSON.parse(path)
                return data[0].normal
              }catch (e){
                return path;
              }
            },
            showImage(url){
              this.url_active = this.getPath(url);
              this.preview_pod = true
            },
            showPdf(url){
              window.open(url,'popup','width=600,height=600');
            },
            confirmDelete(stopId, checklistId,fieldId, url){
              Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("Yes, delete it!"),
                cancelButtonText: this.$t("Cancel")
              }).then(result => {
                if(result.value) {
                  let checklist = _.cloneDeep(this.checklist);
                  let form = checklist.find(item => item.id === checklistId);
                  let field = form.form.fields.find(item => item.id === fieldId);
                  let answer = field.answer ? field.answer : [];
                  let new_answer = answer.filter(item=>item !== url)
                  this.saveAnswer(stopId, checklistId, fieldId, new_answer,"Your item has been deleted.");

                }
              });
            },
            uploadPdf(stopId, checklistId,fieldId, data){
                // 
                // Echo.private('file.processed.channel.'+data.id)
                //     .listen('FileProcessed', (e) => {
                //       this.getAllChecklist({id: this.$route.params.load_id}).finally(()=>{
                //         Echo.leave('file.processed.channel.'+data.id);
                //       })
                //     });
                
                this.appendImage(stopId, checklistId,fieldId, data.map(file=>file.path))
            }
        }
    }
</script>

<style scoped lang="scss">
.type_load{
  background: white;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgb(153 153 153 / 50%);
  border: 1px solid #DDDDDD;

  .load_id{
    border-left: 1px solid #DDDDDD;
    padding: 0 0 0 10px;
    margin-left: 10px;
  }

}

.hover_zoom:hover{
  cursor: pointer;
  transform: scale(1.5);
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
}

</style>
