<template>
  <GmapMap
      ref="mapRef"
      :center="{lat: addressData.latitude, lng: addressData.longitude}"
      :zoom="13"
      :options="mapOptions"
  >
    <GmapMarker
        :position="{lat: addressData.latitude, lng: addressData.longitude}"
        :icon="stopIcon"
    ></GmapMarker>
  </GmapMap>
</template>

<script>
import mapStyle from "@/constants/mapStyle";
export default {
  name: "AddressMap",
  props:{
    address: {
      type: Object,
      required: true,
    }
  },
  data(){
    return {
      stopIcon: {
        scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
        url: require('../../assets/images/txp/common/point_stop.png'),
        labelOrigin: {x: 20, y:15}
      },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
        styles: mapStyle
      },
      map:null
    }
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      this.map = map;
    });
  },
  computed:{
    addressData(){
      return {
        latitude: parseFloat(this.address.latitude),
        longitude: parseFloat(this.address.longitude)
      }
    }
  },
  methods:{
  }
}
</script>

<style scoped>

</style>
