<template>
    <div>
        <page-header :title="$t('Branch offices')" :items="breadcrumb"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-12">
                        <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
                            <template v-slot:right>
                                <router-link v-b-tooltip.hover :title="$t('Add')" :to="{name: 'branchoffices.add'}" class="btn btn-light">
                                    <i class="far fa-fw fa-plus"></i>
                                </router-link>
                                <button v-bind:disabled="selected.length === 0" v-b-tooltip.hover :title="$t('Delete')" @click="confirmDelete" type="button" class="btn btn-light">
                                    <i class="far fa-fw fa-trash-alt"></i>
                                </button>
                            </template>
                        </filter-bar>
                    </div>
                </div>

                <branchOffice-table v-model="selected" :filters="filters" v-on:page-change="pageChange" />

            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import BranchOfficeTable from "@/components/tables/BranchOfficeTable";
    import FilterBar from "@/components/filters/FilterBar";
    import filterMixin from "@/mixins/filterMixin";
    import deleteManyMixin from "@/mixins/deleteMany";

    export default {
        name:'Branchoffices',
        components:{
            BranchOfficeTable,
            FilterBar
        },
        mixins: [filterMixin, deleteManyMixin],
        data(){
            return {
                tabs: [],
                currentTab: null,
                options: [],
                selected: []
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapState('branchoffices', ['last_filters', 'branchoffices']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Branch offices"),
                        active: true,
                        to: {name:'branchoffices.index'}
                    }
                ]
            },
        },
        created() {
            this.resourceName = this.$t("Branch offices").toLowerCase();
            this.options = [
                {
                    icon: 'far fa-key',
                    label: 'ID',
                    type: 'text',
                    filterProperty: 'id',
                },
                {
                    icon: 'far fa-sliders-h-square',
                    label: 'Name',
                    type: 'text',
                    filterProperty: 'name',
                }
            ];
            let toolbarFilters = {};
            for(let item of this.options){
                if(this.$route.query.hasOwnProperty(item.filterProperty)){
                    toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
                }
            }
            this.toolbarFilters = toolbarFilters;
            this.defaultFilters = {seller_id: this.profile.profile.id};
        },
        methods: {
            ...mapActions('branchoffices', ['getBranchoffices', 'deleteManyBranchoffices']),
            deleteMany() {
                return new Promise((resolve, reject) => {
                    this.deleteManyBranchoffices({ids: this.selected.join(',')})
                        .then(data => {
                            this.selected = []
                            this.getBranchoffices(this.filters)
                            resolve(data)
                        })
                        .catch(error=> {
                            reject(error)
                        })
                });
            }
        }
    }
</script>

<style>

</style>
