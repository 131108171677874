<template>
  <div class="especial_project_container">
    <div class="especial_project_container-icon">
      <img src="@/assets/images/proyecto_especial.svg" alt="Proyecto especial">
    </div>
    <div class="especial_project_container-title font_bold mb-1">
      {{ $t('Project') }} <span>{{ $t('Especial') }}</span>
    </div>
    <div class="especial_project_container-label mb-5">
      {{ $t('We have detected that your shipment requires special conditions.') }}
    </div>
    <div class="especial_project_container-banner font_bold mt-4 mb-3">
      <div class="content_banner">
        "{{ $t('At') }} &nbsp;<span> Traxporta </span> &nbsp;{{ $t('we carry everything you need') }}"
      </div>
    </div>
    <div class="especial_project_container-sub_label mb-5">
      {{ $t('By sending your request we will be able to follow up on your project and thus provide it with the attention
      it needs.')}}
    </div>
    <div class="especial_project_container-actions">
      <div class="especial_project_container-actions_label ">
        {{ $t("Do you want to continue with the creation of your shipment?") }}
      </div>
      <div class="especial_project_container-actions_buttons">
        <button @click="cancel" class="btn min-w-170 btn-sm btn-outline-dark px-4 mr-4 text-uppercase padding-btn-small font_bold">
          {{ $t('Cancel') }}
        </button>
        <button @click="accept" class="btn min-w-170 btn-sm btn-primary_v2 px-4 text-uppercase padding-btn-small font_bold">
          {{ $t('Accept and Continue') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EspecialProject",
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    accept() {
      this.$emit('accept');
    }
  }
}
</script>

<style scoped lang="scss">
.especial_project_container {
  padding-top: 5px;
  &-icon {
    text-align: center;

    img {
      width: 40px;
    }
  }

  &-title {
    text-align: center;
    font-size: 60px;

    span {
      font-size: 25px;
      color: white;
      background: #00B3E6;
      padding: 10px;
      border-radius: 20px;
      vertical-align: middle;
    }
  }

  &-label {
    color: #888888;
    font-size: 18px;
    text-align: center;
    position: relative;
    font-family: Helvetica;
    &:before{
      content: "";
      width: 50px;
      height: 1px;
      background: #DDDDDD;
      position: absolute;
      top: 180%;
      left: calc(50% - 25px);
    }
  }

  &-banner {
    text-align: center;

    .content_banner {
      border-radius: 20px;
      background-color: #F2F2F2;
      text-align: center;
      display: inline-flex;
      padding: 10px 45px;
      line-height: 2;

      span {
        color: #00B3E6;
      }
    }
  }

  &-sub_label {
    text-align: center;
    font-size: 18px;
  }

  &-actions {
    margin: 30px auto;
    box-sizing: border-box;
    padding: 25px 15px;
    max-width: 960px;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(153, 153, 153, 0.5);
    display: flex;
    align-content: center;
    justify-content: space-around;

    &_label {
      display: flex;
      justify-content: center;
      color: #000000;
      font-family: Helvetica;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 2;
    }
  }
  .min-w-170{
    min-width: 170px;
    line-height: 1;
    padding: 13px 25px;
  }
}
</style>