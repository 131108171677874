<template>
    <div class="font-family-helvetica">
        <div class="font-size-18 mb-4 font-weight-bold font-family-inter">{{ $t('Recoleccion y entrega') }}</div>
        <div class="row w-100 mb-4">
            <div class="col-12 origin mb-4">
                <div>
                    <span class="font-size-12 font-weight-lighter text-gray-888">
                        {{$t('Pickup address')}}:
                    </span>
                    <span class="font-size-12 font-weight-bold font-family-inter ml-3">{{ pickupAddress }}</span>
                </div>
                <div>
                    <span class="font-size-12 font-weight-lighter text-gray-888">
                        {{$t('Pickup date')}}:
                    </span>
                    <span class="font-size-12 font-weight-bold font-family-inter ml-3">
                        {{ `${origin.schedule_from} - ${origin.schedule_to}` }}
                    </span>
                </div>
                <div class="row mt-3" v-if="edit">
                    <div class="col-12">
                        <div class="font-size-12 text-primary font-weight-bold" role="button" @click="() => setStep(2)">
                            <i class="ri-pencil-fill" /> {{ $t('Edit') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 destination mb-4">
                <div>
                    <span class="font-size-12 font-weight-lighter text-gray-888">
                        {{$t('Delivery address')}}:
                    </span>
                    <span class="font-size-12 font-weight-bold font-family-inter ml-3">{{ deliveryAddress }}</span>
                </div>
                <div>
                    <span class="font-size-12 font-weight-lighter text-gray-888">
                        {{$t('Delivery date')}}:
                    </span>
                    <span class="font-size-12 font-weight-bold font-family-inter ml-3">
                        {{ `${destination.schedule_from} - ${destination.schedule_to}` }}
                    </span>
                </div>
                <div class="row mt-3" v-if="edit">
                    <div class="col-12">
                        <div class="font-size-12 text-primary font-weight-bold" role="button" @click="() => setStep(3)">
                            <i class="ri-pencil-fill" /> {{ $t('Edit') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import addressFormat from '@/helpers/address-format';

export default {
    name: "ShipmentInfo",
    props: {
        edit: { type: Boolean }
    },
    methods: {
        ...mapMutations('marketplace', ['setStep']),
        changeStep(step) {
            this.setStep(step)
        }
    },
    computed: {
        ...mapGetters('marketplace', ['origin', 'destination']),
        pickupAddress() {
            if (this.origin && this.origin.address) {
                return addressFormat(this.origin.address).join(" ")
            } else {
                return this.$t('Empty data')
            }
        },
        deliveryAddress() {
            if (this.destination && this.destination.address) {
                return addressFormat(this.destination.address).join(" ")
            } else {
                return this.$t('Empty data')
            }
        },
    }
}
</script>
<style lang="scss">
.origin {
    border-left: 3px solid #00B3E6;
}

.destination {
    border-left: 3px solid #1163EA;
}
</style>
