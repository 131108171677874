export default {
    data(){
      return {
          selected: []
      }
    },
    computed : {
        selectAll: {
            get(){
                let ids = this.list.results.map(item => item.id);
                let inter = _.intersection(this.selected, ids);
                return inter.length === this.list.results.length && this.list.results.length > 0;
            },
            set(value){
                let selected = _.cloneDeep(this.selected);
                let ids = this.list.results.map(item => item.id);
                if (value) {
                    selected = _.union(selected, ids);
                }else{
                    selected = _.pullAll(selected, ids);
                }
                this.selected = selected;
            }
        },
    },
    watch:{
        selected:{
            deep: true,
            handler(newValue, oldValue){
               this.$emit('input', newValue);
            }
        }
    }
}
