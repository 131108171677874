<template>
    <div class="card">
        <div class="card-header">
            <h6>{{$t(('Organization'))}}</h6>
        </div>
        <div class="form-fields">
            <form-input
                    :title="$t('Name')"
                    :help="$t('Organization name')"
                    v-model="form.name"
                    rules="required"
                    type="text"
                    :name="id+'name'"
            />
            <form-input
                :title="$t('RFC')"
                :help="'Registro Federal de Contribuyentes'"
                v-model="form.rfc"
                type="text"
                rules="rfc"
                :name="id+'profile.rfc'"
                placeholder=""
                :upper="true"
            />
            <form-input
                    :title="$t('TMS')"
                    v-model="form.tms_id"
                    rules="max:32"
                    type="text"
                    :name="id+'profile.tms_id'"
            />

            <form-input
                    :title="$t('SAP')"
                    v-model="form.sap_id"
                    rules="max:32"
                    type="text"
                    :name="id+'profile.sap_id'"
            />

            <template v-if="form.object === 'carrier'">
                <form-input
                        :title="$t('Permition')"
                        v-model="form.license"
                        type="text"
                        :name="id+'profile.license'"
                />

                <form-input
                        :title="$t('Number of Permition')"
                        v-model="form.license_number"
                        type="text"
                        :name="id+'profile.license_number'"
                />
            </template>

            <form-input
                    :title="$t('Logo')"
                    v-model="form.logo"
                    rules=""
                    type="avatar"
                    :name="id+'logo'"
            />
            <form-input
                v-if="isCarrier && this.$route.params.profile === 'admin'"
                :title="$t('Percent Advance')"
                :help="$t('Set de percentage of salary advance to drivers')"
                v-model="form.percent_advance"
                type="number"
                :name="'percent_advance'"
            />
            <slot name="append"></slot>
        </div>
    </div>
</template>

<script>

    export default {
        name: "OrganizationForm",
        props:{
            value:{
                type: Object,
                required: true
            },
            id:{
                type: String,
                default(){
                    return '';
                }
            },
        },
        data(){
            return {
                form: {
                    
                },
            }
        },
        watch:{
            form: {
                deep: true,
                handler(value){
                    this.$emit('input', value);
                }
            },
            value(newValue){
                this.form = newValue;
            }
        },
        created() {
            if(this.value){
                this.form = this.value;
            }
        },
        methods: {

        },
        computed: {
          isCarrier(){
            return this.form.object=="carrier";
          }
        }
    }
</script>

<style scoped>

</style>
