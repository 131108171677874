export function useEventsTracker(vm){
    function trackEvent(event, target, action){
        vm.$gtm.trackEvent({
            event: event,
            target: target,
            action: action
        });
    }
    function trackUserProfile(userProfile){
        vm.$gtm.trackEvent({
            event: 'userProfile',
            userProfile: userProfile
        });
    }

    return {
        trackEvent: trackEvent,
        trackUserProfile: trackUserProfile
    };
}