<template>
    <multiselect v-model="selected" :options="currencies"
                 layout="vertical"
                :placeholder="$t('Select')" select-label="" selected-label="" >
    </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'
import {mapGetters} from 'vuex';

export default {
    name:'CurrencyInput',
    components: {
        Multiselect
    },
    props: {
        value:{
            required: true
        },
        disabled:{
            type : Boolean,
            default(){
                return false;
            }
        },
    },
    data(){
        return {
            selected : null,
        }
    },
    computed: {
        ...mapGetters('catalogs',['countries']),
        currencies(){
          return this.countries.map(item => item.currency)
        }
    },
    watch:{
        selected: {
            deep:true,
            handler(newValue, oldValue){
                this.$emit('input', newValue);
                this.$emit('change', newValue);
            }
        },
        value: {
            deep:true,
            handler(newValue){
                this.setCurrency(newValue);
            }
        }
    },
    created(){
        let code = 'MXN';
        if(this.value){
            code = this.value;
        }
        this.setCurrency(code);
    },
    methods:{
        setCurrency(code){
            this.selected = code;
        }
    }
}
</script>
