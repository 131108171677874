<template>
    <div>
        <page-header :title="$t('Preferences')" :items="breadcrumb"/>

        <div class="card">
            <div class="card-header">
                <h6>{{$t('Preferences')}}</h6>
            </div>
            <div class="form-fields">
                <form-input
                        :title="$t('Language')"
                        v-model="form.locale"
                        rules="required"
                        type="select"
                        :options="locales"
                        name="preferences.language"
                />
                <form-input
                        :title="$t('Timezone')"
                        v-model="form.timezone"
                        rules="required"
                        type="select"
                        :options="timezones"
                        name="preferences.timezone"
                />
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h6>{{$t(('Notification channels'))}}</h6>
            </div>
            <div class="form-fields">
                <form-input
                        :title="$t('Mail')"
                        :help="$t('Receive mail notifications')"
                        v-model="form.notification_channels.mail"
                        rules=""
                        type="boolean"
                        :name="'notifications.mail'"
                />
                <form-input
                        :title="$t('Push notifications')"
                        :help="$t('Receive push notifications in mobile app')"
                        v-model="form.notification_channels.fcm"
                        rules=""
                        type="boolean"
                        :name="'notifications.mail'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import locale from "@/constants/locale";
    import timezones from "@/constants/timezones.json";
    import {mapState, mapActions} from 'vuex'

    export default {
        name: "Preferences",
        data(){
            return {
                form: {

                },
                init : false
            }
        },
        watch: {
            form: {
                deep: true,
                handler(newValue){
                    if(this.init){
                        this.update();
                    }else{
                        this.init = true;
                    }
                }
            }
        },
        computed: {
            ...mapState('profile',['profile']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Settings"),
                        to: {name:'settings'}
                    },
                    {
                        text: this.$t('Preferences'),
                        to: {name:'settings.preferences'},
                        active: true
                    }
                ]
            },
            locales(){
                return locale.map(item => ({text: this.$t(item.text), value: item.value}))
            },
            timezones(){
                let options = [];
                for (const [key, value] of Object.entries(timezones)) {
                    options.push({text: key, value: key})
                }
                return options;
            }
        },
        created() {
            this.form = _.cloneDeep(this.profile.preferences);
        },
        methods: {
            ...mapActions('profile',['updatePreferences']),
            update(){
                this.updatePreferences(this.form)
                    .then(()=>{
                        this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                            title: this.$t('Success'),
                            variant: 'success',
                            solid: true
                        });
                    })
                    .catch(error =>{

                    });
            }
        }
    }
</script>

<style scoped>

</style>
