import axios from "../../api/client";



// initial state
export const state = () => ({
    stats:{
        unknown: 0,
        late: 0,
        delayed: 0,
        early: 0,
        on_time: 0
    },
    fetching: false,
    loads: [],
    routing: null,
    timeZoneSelected: true,
    historyTemperature: [],
    filters: {},
    search: [],
    source: null,
    shipments: [],
    db: null
});

// getters
export const getters = {};

// actions
export const actions = {
    getStats({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/control-tower/eta`)
                .then(response=>{
                    commit('setStats', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    searchLoads({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/control-tower/search', {params: query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    getLoads({commit, state}, payload){
        commit('setFetching', true);
        if(state.source){
            state.source.cancel('Cancelled by user');
        }
        const source = axios.CancelToken.source();
        commit('addRequest', source);
        return new Promise((resolve, reject) => {
            axios.post('/api/v2/control-tower/tracking',payload, {cancelToken: source.token})
                .then(response=>{
                    commit('setLoads', response.data.tracking);
                    commit('addRequest', null);
                    resolve(response.data);
                })
                .catch(error =>{
                    if (axios.isCancel(error)) {
                        
                    }else{
                        commit('addRequest', null);
                    }
                    reject(error.response);
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    getRouting({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/routing`)
                .then(response=>{
                    commit('setRouting', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    getHistoryTemperature({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/routingHistory`)
                .then(response=>{
                    commit('setHistoryTemperature', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    storePosition({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/tracking`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    applyFilters({commit,state},payload){
        let filters = _.cloneDeep(state.filters);
        commit("setFilters",{...filters,...payload});
    },
    addFilter({commit,state},payload){
        let filters = _.cloneDeep(state.filters);
        commit("setFilters",{...filters,...payload});
    },
    deleteFilter({commit,state},payload){
        let filters = _.cloneDeep(state.filters);
        delete filters[payload];
        commit("setFilters",_.cloneDeep(filters));
    },
    clearFilter({commit,state},payload){
        commit("setFilters",{});
    },
    changeTimeZone({commit,state},payload){
        commit("setTimeZone",payload);
    },
    getShipments({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/control-tower/shipments', {params: query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error)
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    getShipmentTemperatures({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/control-tower/shipments/${query.shipment_id}/temperatures`, {params: query})
                .then(response=>{
                    commit('setHistoryTemperature', response.data.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
};

// mutations
export const mutations = {
    setStats(state, data){
        state.stats = data;
    },
    setFetching(state, data){
        state.fetching = data;
    },
    setLoads(state, data){
        state.loads = data;
    },
    setRouting(state, data){
        state.routing = data;
    },
    setHistoryTemperature(state, data){
        state.historyTemperature = data;
    },
    setFilters(state, data){
        state.filters = data;
    },
    setSearch(state, data){
        state.search = data;
    },
    addRequest(state, data){
        state.source = data;
    },
    setTimeZone(state, data){
        state.timeZoneSelected = data;
    }
};

