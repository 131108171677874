export default {
    carrier: [
        {
            label: 'Account',
            isTitle: true,
        },
        {
            label: 'Profile',
            route: 'settings.profile'
        },
        {
            label: 'Security',
            route: 'settings.security'
        },
        {
            label: 'Account',
            route: 'settings.account'
        },
        {
            label: 'Preferences',
            route: 'settings.preferences'
        },
        {
            label: 'Manage',
            isTitle: true,
        },
        {
            label: 'Members',
            route: 'settings.members.index',
            permission: 'members.management'
        },
        {
            label: 'Teams',
            route: 'settings.teams.index',
            permission: 'teams.management'
        },
        {
            label: 'Documents',
            route: 'settings.documents'
        },
        {
            label: 'Load fields',
            route: 'settings.loadfields'
        },
        {
            label: 'Views',
            route: 'settings.views.list'
        },
        {
            label: 'Settlement',
            route: 'settings.settlement'
        },
        {
            label: 'Addresses',
            route: 'settings.addresses'
        }

    ],
    shipper: [
        {
            label: 'Account',
            isTitle: true,
        },
        {
            label: 'Profile',
            route: 'settings.profile'
        },
        {
            label: 'Security',
            route: 'settings.security'
        },
        {
            label: 'Account',
            route: 'settings.account'
        },
        {
            label: 'Preferences',
            route: 'settings.preferences'
        },
        {
            label: 'Manage',
            isTitle: true,
        },
        {
            label: 'Members',
            route: 'settings.members.index',
            permission: 'members.management'
        },
        {
            label: 'Teams',
            route: 'settings.teams.index',
            permission: 'teams.management'
        },
        {
            label: 'Documents',
            route: 'settings.documents'
        },
        {
            label: 'Load fields',
            route: 'settings.loadfields'
        },
        {
            label: 'Views',
            route: 'settings.views.list'
        },
        {
            label: 'Proof of delivery',
            route: 'settings.pod'
        },
        {
            label: 'Settlement',
            route: 'settings.settlement'
        },
        {
            label: 'Addresses',
            route: 'settings.addresses'
        }
    ],
    driver: [
        {
            label: 'Account',
            isTitle: true,
        },
        {
            label: 'Profile',
            route: 'settings.profile'
        },
        {
            label: 'Security',
            route: 'settings.security'
        },
        {
            label: 'Preferences',
            route: 'settings.settlement'
        },
    ],
    admin: [
        {
            label: 'Account',
            isTitle: true,
        },
        {
            label: 'Profile',
            route: 'settings.profile'
        },
        {
            label: 'Security',
            route: 'settings.security'
        },
        {
            label: 'Preferences',
            route: 'settings.preferences'
        },
        {
            label: 'System',
            isTitle: true,
            permission: ['members.management', 'teams.management','catalogs.management', 'requirements.management','views.management','addresses.management'],
        },
        {
            label: 'Members',
            route: 'settings.members.index',
            permission: 'members.management'
        },
        {
            label: 'Teams',
            route: 'settings.teams.index',
            permission: 'teams.management'
        },
        {
            label: 'Truck types',
            route: 'settings.trucktypes',
            permission: 'catalogs.management'
        },
        {
            label: 'Trailer types',
            route: 'settings.trailertypes',
            permission: 'catalogs.management'
        },
        {
            label: 'Commodities',
            route: 'settings.commodities',
            permission: 'catalogs.management'
        },
        {
            label: 'Requirements',
            route: 'requirements.index',
            permission: 'requirements.management'
        },
        {
            label: 'Settlement',
            route: 'settings.settlement',
            permission: 'catalogs.management'
        },
        {
            label: 'Catalogs',
            route: 'settings.catalogs',
            permission: 'catalogs.management'
        },
        {
            label: 'Addresses',
            route: 'settings.addresses',
            permission: 'addresses.management'
        },
        {
            label: 'Views',
            route: 'settings.views.list',
            permission: 'views.management'
        },
    ],
    seller: [
        
    ]
}
