<template>
    <div class="row mt-5 mb-5">
        <div class="col-12 d-flex justify-content-center">
            <div class="card rectangle">
                <div class="card-body text-center font-size-24 font-weight-bold">
                    <div class="text-primary mt-3 d-flex justify-content-center align-items-center font-size-28">
                        <i class="ri-check-line font-size-30 mr-2" />
                        Gracias por tu confirmación
                    </div>
                    <div class="line-height mt-4">
                        <p>Para continuar con los detalles de tu envío</p>
                        <p>necesitas ingresar con tu cuenta</p>
                    </div>
                    <div class="px-md-5 mt-5">
                        <button
                            class="btn btn-block btn-primary_v2 text-uppercase font-size-12 font-weight-bolder py-2"
                            @click="() => this.$router.push('/login')"
                        >
                            {{ $t("Log in") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AcceptedCard"
}
</script>

<style lang="scss" scoped>
.line-height { line-height: 20px; }
.rectangle {
    box-sizing: border-box;
    height: 340px;
    width: 620px;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    background-color: #FFFFFF;
}
</style>
