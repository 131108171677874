export default [
    {
        path:'finance',
        name:'finance',
        component: () => import('../views/Content'),
        redirect: {name: 'finance.factoring'},
        meta: {breadcrumb:{text:'Finance', to:{name:'finance'}}},
        children : [
            {
                path:'factoring',
                name:'finance.factoring',
                component: () => import('../views/finance/Factoring'),
                meta: {title:'Factoring'}
            },
            {
                path:'advance',
                name:'finance.advance',
                component: () => import('../views/finance/Advances'),
                meta: {title:'Advances', breadcrumb:{text:'Advances', to:{name:'finance.advance'}}, bgWhite: true}
            },
            {
                path:'transfers',
                name:'finance.transfers',
                component: () => import('../views/finance/Transfers'),
                meta: {title:'Transfers', breadcrumb:{text:'Transfers', to:{name:'finance.transfers'}}, bgWhite: true}
            },
            {
                path:'operational-deductions',
                name: 'finance.operational-deductions',
                component: () => import('../views/Wrapper'),
                redirect: {name: 'finance.operational-deductions.index'},
                meta: {breadcrumb:{text:'Operational deductions', to:{name:'finance.operational-deductions'}}}, 
                children: [
                    {
                        path:'',
                        name:'finance.operational-deductions.index',
                        component: () => import('../views/finance/OperationalDeductions'),
                        meta: {title:'Operational deductions', bgWhite: true, routes:{add: 'finance.operational-deductions.add'}}
                    },
                    {
                        path:'add',
                        name:'finance.operational-deductions.add',
                        component: () => import('../views/finance/OperationalDeductionForm'),
                        meta: {title:'New deduction', routes:{list: 'finance.operational-deductions.index'}, breadcrumb:{text:'New', to:{name:'finance.operational-deductions.add'}}}
                    },
                ]
            },

            {
                path:'payments',
                name:'finance.payments',
                component: () => import('../views/finance/Factoring'),
                meta:{title: 'Factoring'}
            },
            {
                path:'fuel-vouchers',
                name:'finance.fuelvouchers',
                component: () => import('../views/finance/FuelOrders'),
                meta:{title: 'Fuel vouchers'}
            }
        ]
    }
];