<template>
    <div class="font-family-helvetica">
        <div class="line-height-50">
            <h4 class="text-primary font-weight-bold inter font-size-25">{{$t('Confirm rate')}}</h4>
            <div class="font-size-42 font-weight-bold">¿{{$t('You accept the rate and')}}</div>
            <div class="font-size-42 font-weight-light">{{$t('the Terms and Conditions')}}?</div>
        </div>
        <div class="font-size-13 d-flex justify-content-start align-items-center mt-4">
            <b-form-checkbox
                name="checkbox-1"
                button-variant="secondary"
                v-model="aceptedConditions"
            />
            <div>
                {{$t('I have read and accept the')}} <span class="text-primary">{{$t('Terms and Conditions')}}</span>
            </div>
        </div>
        <div class="mt-3">
            <div class="d-flex mt-4" v-if="fetching">
                <b-skeleton type="button" width="37%" />
                <b-skeleton type="button" width="37%" class="ml-3" />
            </div>
            <div v-else>
                <button
                    type="button"
                    class="btn btn-outline-dark text-uppercase font-size-12 font-weight-bolder px-5 mt-3"
                    :class="[!aceptedConditions ? 'disabled':'']"
                    @click="() => confirm('declined')"
                >
                    {{ $t('Decline') }}
                </button>
                <button
                    type="button"
                    class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder px-4 mt-3 ml-3"
                    :class="[!aceptedConditions ? 'disabled':'']"
                    @click="() => confirm('confirmed')"
                >
                    {{ $t('Accept rate') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConfirmRate",
    props: {
        fetching: Boolean,
        confirm: Function,
    },
    data() {
        return {
            aceptedConditions: false,
        }
    },
}
</script>

<style scoped>
.inter{ font-family: 'Inter'; }
.line-height-50{ line-height: 50px; }
</style>
