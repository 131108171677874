<template >
    <div v-if="step === 1">
        <address-picker-map ref="address-components" :id="'destination'" :formProp="destination.address"
            @addressPickerBack="$emit('setStep', 2)" @addressPickerNext="saveAddress" />
    </div>
    <div v-else-if="step === 2">
        <address-form :id="'destination-form'" v-model="form.address" v-if="!loading" @addressFormBack="backStep"
            @addressFormNext="confirmAddress" :formProp="destination.address" :complete="!loading" />
        <div v-if="loading">
            <calendar-loading />
        </div>
    </div>
    <div v-else-if="step === 3">
        <DestinationCalendarView
            :id="'destination-date'" @calendarBack="backStep" @calendarNext="setDate"
            :originDate="originDate" :suggestedDate="suggestedDate" :destinationDate="destinationDate"
        />
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
/**
 * Formulario para las direcciones
 */
import AddressForm from '@/components/common/AddressForm.vue';
import AddressPickerMap from '@/components/common/AddressPickerMap.vue';
import CalendarPicker from '../CalendarPicker.vue';
import CalendarLoading from "../../common/CalendarLoading";
import DestinationCalendarView from "./DestinationCalendarView.vue";

/**
 * Esta view se encarga de agregar información de la dirección de entrega al store
 */
export default {
    name: "DestinationView",
    components: {
      DestinationCalendarView,
        CalendarLoading,
        AddressForm,
        CalendarPicker,
        AddressPickerMap
    },
    data() {
        return {
            step: 1,
            form: {},
            explaind: false,
            createNew: false,
            stopsCollapsed: false,
            originDate: null,
            destinationDate: null,
            suggestedDate: {},
            loading: false
        }
    },
    computed: {
        ...mapGetters('marketplace', ['destination', 'origin']),
    },
    created() {
        this.form = this.destination;
        this.originDate = this.origin.date;
        this.destinationDate = this.destination.date;
    },
    methods: {
        ...mapMutations('marketplace', ['setDestinationAddres', 'setDestinationDate', 'setRoute', 'setDestinationTypeAndStopNumber', 'validateDestination']),
        ...mapActions('marketplace', ['routeCalculator']),
        /**
         *  Esta funcion se ejecuta dentro de las funciones para almacenar
         *  valores en el store de la aplicacion
         *
         * @param {Number} step Paso para setear
         */
        setComponetStep(step) {
            this.step = step;
        },
        /**
         * Funcion de control de retroceso de los steps internos de la view
         */
        backStep() {
            this.step--;
        },
        /**
         * Setea la direccion de recoleccion desde el mapa
         *
         * @param {Object} address Objeto con la direccion
         * @see /src/state/modules/marketplace.js
         */
        saveAddress(address) {
            this.setDestinationAddres(address);
            this.setComponetStep(2);
        },
        /**
         * Setea la direccion de la recoleccion desde el formulario
         *
         * @param {Object} address Objeto con la direccion de recoleccion
         */
        async confirmAddress(address) {
            this.setDestinationAddres(address);
            this.loading = true;
            const payload = {
                start_date: this.origin.schedule_to,
                origin: {
                    latitude: this.origin.address.location[0],
                    longitude: this.origin.address.location[1]
                },
                destination: {
                    latitude: this.destination.address.location[0],
                    longitude: this.destination.address.location[1]
                }
            }
            const response = await this.routeCalculator(payload)
            this.setRoute(response.route)
            this.suggestedDate = this.fragmentDate(response.suggest_date);
            this.loading = false;
            this.setDestinationTypeAndStopNumber();
            this.setComponetStep(3);
        },
        /**
         * Setea los datos de la fecha de entrega
         *
         * @param {Object} date Objeto con la fecha de entrega
         */
        setDate(date) {
            this.setDestinationDate(date);
            this.validateDestination();
            this.$emit('setStep', 4);
        },
        /**
         * Convierte la fecha obtenida de un string en formato YY-MM-DD H:i:s
         * a un arreglo
         *
         * @param {String} stringDate String de la fecha a obtener
         */
        fragmentDate(stringDate) {
            const arrayDate = stringDate.split(' ');
            const days = arrayDate[0].split('-');
            const time = arrayDate[1].split(':')
            const finalDate = {
                day: days[2],
                month: days[1],
                year: days[0],
                hour: time[0],
                minute: time[1]
            }
            return finalDate;
        }
    },
    watch: {
        step(newValue) {
            switch (newValue) {
                case 1:
                    this.$emit('setTitle', 3)
                    break;
                case 2:
                    this.$emit('setTitle', 92)
                    break;
                case 3:
                    this.$emit('setTitle', 93)
                    break;
            }
        }
    }
}
</script>
<style lang="scss">

</style>
