<template>
<div class="loading_address_picker">
  <div class="loading_address_picker_text text-center">
    <b-skeleton class="loading_address_picker_text-item" width="75%" height="20px"/>
    <b-skeleton class="loading_address_picker_text-item" width="50%" height="20px"/>
  </div>
  <div class="loading_address_picker_button mt-3">
    <b-skeleton height="30px"/>
  </div>
</div>
</template>

<script>
export default {
  name: "AddressPickerLoading"
}
</script>

<style scoped lang="scss">
.loading_address_picker_text-item{
  margin: 5px auto 5px auto !important;
}
.loading_address_picker{
  width: 100%;
}
.loading_address_picker_text{
  b-skeleton{
    margin: auto;
  }
}
</style>