<template>
    <suggested-equipment v-if="suggested_equipment" />
    <div class="row ml-md-3 font-family-helvetica min-h-400 p-0 mt-4" v-else>
        <div class="col-md-7 col-12">
            <select-commodity v-if="!shipment.commodity.code" />
            <div v-else>
                <commodity-options :reset="resetCommodity" :commodity_option="commodity_option" :setOption="setOption" />
                <select-truck v-if="commodity_option === 1" />
                <find-truck   v-if="commodity_option === 2" :reset="resetCommodity" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import SelectCommodity from './SelectCommodity';
import CommodityOptions from './CommodityOptions';
import SelectTruck from './SelectTruck';
import FindTruck from './FindTruck';
import SuggestedEquipment from './SuggestedEquipment';
import Buttons from './Buttons';

export default {
    name: "EquipmentType",
    components: {
        SelectCommodity,
        CommodityOptions,
        SelectTruck,
        FindTruck,
        SuggestedEquipment,
        Buttons
    },
    data() {
        return {
            commodity_option: 1,
        }
    },
    computed: {
        ...mapGetters('catalogs', ['equipments_marketplace', 'commodity_trailer_type']),
        ...mapGetters('marketplace', ['shipment', 'equipment_type']),
        ...mapState('shipmentform', ['suggested_equipment']),
    },
    methods: {
        ...mapMutations('shipmentform', ['setCommodityTrailerType']),
        ...mapMutations('marketplace', ['setCommodity', 'setEquipmentType', 'setShipmentDetail', 'setShipment']),
        resetCommodity(){
            this.setCommodity({code: null, name: null});
            this.commodity_option = 1;
        },
        setOption(option){
            this.setEquipmentType({
                code: null, name: null, configuration_code: null,
                truck_type_code: null, trailer_type_code: null, trailer_size_code: null,
            });
            this.setShipmentDetail({
                quantity: null, container_type: null, width: null,
                length: null, height: null, weight: null, stacking: false,
            });
            this.commodity_option = Number(option);
        }
    },
}
</script>

<style scoped>
.font-family-helvetica {
    font-family: Helvetica;
}

.min-h-400 {
    min-height: 400px;
}
</style>
