<template>
    <div>
        <navbar color="celeste" title="Equipamiento preferente" image="truck" />
        <div class="row mt-3 pb-4" style="min-height: 300px;">
            <div class="col-8 offset-2">
                <div class="my-3 text-left">
                    <b class="font-size-14">{{$t('Selecciona los equipamientos con los que cuenta')}}</b>
                    <multiselect
                        v-model="equipmentSelected"
                        track-by="id"
                        label="name"
                        value="id"
                        :options="list"
                        :searchable="true"
                        :placeholder="$t('Selecciona un equipamiento')"
                        class="mt-2"
                        :clearOnSelect="false"
                    />
                </div>
                <button
                    type="button"
                    class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder float-right mt-3"
                    :class="[isDisabled && 'disabled']"
                    @click="addEquipment"
                >
                    {{$t('Añadir equipamiento')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import Navbar from '@/views/recommendations/Navbar.vue';

export default {
    name: "EquipmentsForm",
    components: {
        Navbar,
        Multiselect
    },
    props: {
        addEquipments: { type: Function },
        equipments: {type: Array}
    },
    data(){
        return {
            equipmentsCatalog:[],
            equipmentSelected: null
        }
    },
    computed: {
        ...mapState('catalogs', ['catalogs']),
        isDisabled(){
            if (this.equipmentSelected)
                return false
            else 
                return true
        },
        list(){
            let codes = this.equipments.map(item => item.code)
            return this.catalogs.equipments_marketplace.filter(equipment => !codes.includes(equipment.code))
        }
    },
    methods: {
        async addEquipment() {
            this.addEquipments(this.equipmentSelected);
            this.equipmentSelected = null;
        }
    }
}
</script>

<style lang="css" scoped>
.bg-celeste{
    background: rgba(0, 179, 230, 0.1);
}

.logo{
    width: 8%;
}

.multiselect__input{
    position: inherit!important;
    width: auto!important;
}

.disabled{
    pointer-events: none !important;
}

</style>
