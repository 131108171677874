<template>
    <div>
        <page-header :title="$t('Catalogs')" :items="breadcrumb"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-12">
                        <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
                            <template v-slot:right>
                                <button v-b-tooltip.hover :title="$t('Import')" type="button" class="btn btn-light"  @click="$refs.import.click()">
                                <i class="far fa-file-import"></i>
                                </button>
                                <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
                            </template>
                        </filter-bar>
                    </div>
                </div>

                <catalog-table v-model="selected" :filters="filters" v-on:page-change="pageChange" />

            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

import CatalogTable from "../../../components/tables/CatalogTable";
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import Swal from "sweetalert2";

export default {
  name: "Catalog",
  components: {CatalogTable, FilterBar},
  mixins: [filterMixin],
    data(){
        return {
            tabs: [],
            currentTab: null,
            options: [],
            selected: []
        }
    },
  computed:{
    ...mapState("profile", ["profile"]),
    breadcrumb() {
      const profile = this.$route.params.profile;
      return [
        {
          text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
          to: { name: "profile" },
        },
        {
          text: this.$t("Settings"),
          to: { name: "settings" },
        },
        {
          text: this.$t("Catalogs"),
          to: { name: "settings.catalogs" },
          active: true,
        },
      ];
    }
  },
  created() {
            this.resourceName = this.$t("Catalogs").toLowerCase();
            this.options = [
                {
                    icon: 'far fa-key',
                    label: 'ID',
                    type: 'text',
                    filterProperty: 'id',
                },
                {
                    icon: 'far fa-sliders-h-square',
                    label: this.$t('Value'),
                    type: 'text',
                    filterProperty: 'value',
                },
                {
                    icon: 'far fa-sliders-h-square',
                    label: this.$t('Text'),
                    type: 'text',
                    filterProperty: 'text',
                },
                {
                    icon: 'far fa-sliders-h-square',
                    label: this.$t('Type'),
                    type: 'text',
                    filterProperty: 'type',
                }
            ];
            let toolbarFilters = {};
            for(let item of this.options){
                if(this.$route.query.hasOwnProperty(item.filterProperty)){
                    toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
                }
            }
            this.toolbarFilters = toolbarFilters;
        },
    methods: {
        ...mapActions('catalogs',['importCatalogs', 'getCatalogList']),
        fetchData(){
                this.getCatalogList(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        
                    })
            },
        handleFile(){
        let payload= {template : this.$refs.import.files[0]};
        this.importCatalogs(payload)
            .then((data)=>{
                this.clearFile()
                Echo.private(`jobs.${data.job_id}`)
                    .listen('JobProcessed', (e) => {
                    this.fetchData();
                    Echo.leave(`jobs.${data.job_id}`);
                    });
                this.$root.$bvToast.toast(this.$t("File uploaded successfully", {resource: this.resourceName}), {
                title: this.$t('Success'),
                variant: 'success',
                solsid: true
                });
            })
            .catch(()=>{
                this.clearFile()
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
            })
        },
        clearFile(){
        this.$refs.import.value=null;
        },
    },
}
</script>

<style scoped>

</style>