<template>
  <header id="page-topbar">
    <div class="row">
        <div class="col-12">
          <div style="background-color:blue;height: 5px;background: linear-gradient(270deg, #00B3E6 0%, #E3ED55 100%);">

          </div>
        </div>
    </div>

    <div v-if="!show_email_verified" class="fixed-alert">
      <b-alert class="mb-0 text-center" dismissible show variant="info">
        <i class="far fa-envelope mr-2"></i>
        {{ $t('Thank you for registering with Traxporta, to be able to use the platform it is necessary to confirm your email.') }}
        <b-button @click="showEmailVerification" variant="primary" class="btn-sm ml-2">{{ $t('Resend verification email') }}</b-button>
      </b-alert>
    </div>

    <div v-if="!profile.enabled" class="fixed-alert">
      <router-link :to="{name: 'settings.documents'}">
        <b-alert class="mb-0 text-center" dismissible show variant="warning">
          <i class="far fa-exclamation-triangle mr-2"></i>
          {{ $t('Currently your account is disabled, to enable your account it is necessary to upload some documents and request their review.') }}
        </b-alert>
      </router-link>
    </div>

    <div class="navbar-header">
      <div class="d-flex">





        <!-- LOGO -->
        <div class="navbar-brand-box text-center show_md icon_topbar " style="background: #5c5c5c!important;" >
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img class="bitmap" src="@/assets/images/txp/icono.png" alt height="50" />
            </span>
            <span class="logo-lg">
              <img class="bitmap"  src="@/assets/images/txp/icono.png" alt height="50" />
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img class="bitmap"  src="@/assets/images/txp/icono.png" alt height="50" />
            </span>
            <span class="logo-lg">
              <img class="bitmap"  src="@/assets/images/txp/txp_gray.png" alt height="50" />
            </span>
          </a>
        </div>

        <button @click="toggleMenu" class="hidde_sm menu_btn">
          <div id="hamburger" :class="isCondensed?'open':''">
            <svg width="45px" height="45px" viewBox="0 0 100 100">
              <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
              <path class="line line2" d="M 20,50 H 80" />
              <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
            </svg>
          </div>
        </button>

      </div>
      <ControlTowerFilter v-if="showFilterBar"/>
      <div class="d-flex">
        <div class="dropdown d-inline-block d-lg-none ml-2">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            id="page-header-search-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="ri-search-line"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
            aria-labelledby="page-header-search-dropdown"
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search ..." />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="ri-search-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>


        <b-dropdown
                v-if="false"
          class="d-none d-lg-inline-block d-lg-inline-block ml-1"
          menu-class="dropdown-menu-lg"
          right
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="ri-apps-2-line"></i>
          </template>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="@/assets/images/brands/github.png" alt="Github" />
                  <span>{{ $t('navbar.dropdown.site.list.github') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="@/assets/images/brands/bitbucket.png" alt="bitbucket" />
                  <span>{{ $t('navbar.dropdown.site.list.github') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="@/assets/images/brands/dribbble.png" alt="dribbble" />
                  <span>{{ $t('navbar.dropdown.site.list.dribbble') }}</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="@/assets/images/brands/dropbox.png" alt="dropbox" />
                  <span>{{ $t('navbar.dropdown.site.list.dropbox') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="@/assets/images/brands/mail_chimp.png" alt="mail_chimp" />
                  <span>{{ $t('navbar.dropdown.site.list.mailchimp') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="@/assets/images/brands/slack.png" alt="slack" />
                  <span>{{ $t('navbar.dropdown.site.list.slack') }}</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            @click="initFullScreen"
          >
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>

        <template v-if="false && $route.params.profile === 'carrier'">
          <div v-if="profile.account.type === 'carrier'" class="dropdown d-none d-lg-inline-block ml-1">
            <router-link
                :to="{name: 'wallet'}"
                tag="button"
                class="btn header-item noti-icon waves-effect">
              <i class="far fa-wallet text-success pr-1"></i>
              ${{ wallet ? wallet.balance : '0' | money }}
            </router-link>
          </div>
        </template>


        <notifications />
        <confirm-email />

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block user-dropdown"
        >
          <template v-slot:button-content>
            <img
              v-if="profile.avatar"
              class=" header-profile-user"
              :src="profile.avatar"
              width="36" height="36" style="object-fit: cover;padding:0!important;border-radius:10px;"
            />
            <vue-letter-avatar v-else :name="profile.name" size='36' :rounded=true />
          </template>
          <!-- item-->
          <div class="dropdown-user-title">

          </div>

          <h6 class="dropdown-header dropdown-user-title">{{profile.name}} {{profile.last_name}}</h6>
          <b-dropdown-item :to="{name:'settings.profile'}">
            <i class="ri-settings-2-line align-middle mr-1"></i>
            {{ $t('Profile') }}
          </b-dropdown-item>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript:void(0);" @click="logout">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t('Logout') }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
$stroke-color: #636e75;

.menu_btn{
  background: white;
  border: none;
}


#hamburger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;

  .line {
    fill: none;
    stroke: $stroke-color;
    stroke-width: 4;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

    &1 {
      stroke-dasharray: 60 207;
      stroke-width: 4;
    }
    &2 {
      stroke-dasharray: 60 60;
      stroke-width: 4;
    }
    &3 {
      stroke-dasharray: 60 207;
      stroke-width: 4;
    }
  }
  &.open .line {
    &1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 4;
    }
    &2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 4;
    }
    &3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width:4;
    }
  }
}

.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
.dropdown-user-title{
  text-transform: uppercase!important;
  font-size: 12px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bitmap {
  height: 40px;
  width: 40px;
  box-shadow: 0 2px 4px 0 rgba(153,153,153,0.5);
  border-radius: 10px;
}
.navbar-brand-box{
  padding: 5px!important;
}
.topbar{
  box-shadow: none!important;
}
</style>

<script>
  import simplebar from "simplebar-vue";
  import i18n from "../i18n";
  import {mapState, mapActions, mapGetters} from 'vuex';
  import Notifications from '@/components/Notifications';
  import ControlTowerFilter from "./filters/ControlTowerFilter";
  import ConfirmEmail from '@/components/modals/ConfirmEmail'
  import {signOut,getUser} from '@/utils/auth.js'
  import {getEmailVerified, sendEmailConfirmation} from "../utils/auth";
  export default {
    data() {
      return {
        languages: [
          {
            flag: require("@/assets/images/flags/us.jpg"),
            language: "en",
            title: "English"
          },
          {
            flag: require("@/assets/images/flags/french.jpg"),
            language: "fr",
            title: "French"
          },
          {
            flag: require("@/assets/images/flags/spain.jpg"),
            language: "es",
            title: "spanish"
          },
          {
            flag: require("@/assets/images/flags/chaina.png"),
            language: "zh",
            title: "Chinese"
          },
          {
            flag: require("@/assets/images/flags/arabic.png"),
            language: "ar",
            title: "Arabic"
          }
        ],
        current_language: "en",
        show_email_verified: false,
      };
    },
    components: { simplebar, Notifications ,ControlTowerFilter, ConfirmEmail },
    props: {
      isCondensed: {
        type: Boolean,
        default: false,
      }
    },
    computed:{
      ...mapState('profile',['profile']),
      ...mapState('notification',['notifications','unread_notifications']),
      ...mapState('wallet',['wallet']),
      showFilterBar(){
        if (this.$route.meta){
          return this.$route.meta.showControlTowerFilter
        }
        return false;
      },
    },
    async created() {
      if (this.$route.params.profile === 'carrier') {
        this.getWalletByProfile({})
            .then(() => {
            })
            .catch(error => {
            })
      }
      this.show_email_verified = await getEmailVerified() == 'true';
    },
    methods: {
      ...mapActions('profile',['sendEmailVerification']),
      ...mapActions('notification',['getUnreadNotifications','getNotifications','markAsRead']),
      ...mapActions('wallet',['getWalletByProfile']),
      ...mapActions('emailverification', ['showEmailVerification']),
      logout() {
        signOut().then((data) => {}).catch((err) => console.log('SIGN OUT ERR', err));
      },
      toggleMenu() {
        this.$emit('toggleMenu');
      },
      initFullScreen() {
        document.body.classList.toggle("fullscreen-enable");
        if (
                !document.fullscreenElement &&
                /* alternative standard method */ !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
        ) {
          // current working methods
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
            );
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
        }
      },
      setLanguage(locale) {
        i18n.locale = locale;
        this.current_language = i18n.locale;
      },
      getNotificationIcon(notification){
        if(notification.resource === 'profile'){
          return 'far fa-user-alt';
        }
        if(notification.resource === 'load'){
          return 'far far fa-boxes';
        }
        if(notification.resource === 'truck'){
          return 'far fa-truck-moving';
        }
        if(notification.resource === 'trailer'){
          return 'far fa-container-storage';
        }
        return 'far fa-envelope-open-text';
      },
    }
  };
</script>
<style scoped>
.icon_topbar{
  width: 80px;
}

.fixed-alert {
  position:fixed;
  bottom:0;
  width:100vw;
}


@media (min-width: 992px) {
  .hidde_sm{
    display: none;
  }
}

@media (max-width: 992px) {
  .show_md{
    display: none;
  }
}


</style>
