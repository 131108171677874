import axios from "../../api/client";


// initial state
export const state = () => ({

});

// getters
export const getters = {};

// actions
export const actions = {
    storeFile({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/upload`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {

};

