<template>
    <div class="row font-family-helvetica">
        <div class="col-12">
            <div class="min-h-400">
                <div class="card-body p-0">
                    <service-detail-form @back="$emit('back')" @next="$emit('next')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">

import ServiceDetailForm from './ServiceDetailForm.vue';

/**
 * Este componente renderiza los detalles finales de los envíos
 *
 */
export default {
    name: "ServiceDetailCard",
    components: {
        ServiceDetailForm,
    }
}
</script>

<style scoped>

</style>
