<template>
    <div class="row">
        <div class="col-md-10 offset-md-1 col-12">
            <div class="card mt-3">
                <div class="card-header centered font-weight-bold text-capitalize">{{$t('Returns')}}</div>
                <div class="card-body">
                    <loading class="py-md-3" v-if="fetching" />
                    <template v-else>
                        <reason-type-select
                            :type="type"
                            :types="types"
                            :reason="reason"
                            :reasons="reasons"
                            :setType="setType"
                            :setReason="setReason"
                        />
                        <select-products
                            :shipments="shipments"
                            :fetching="fetching"
                            :onSave="onSave"
                            :onBack="onBack"
                            :quantity_required="quantity_required"
                            :comments_required="comments_required"
                            :disabledNext="reason ? false : true"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import ReasonTypeSelect from '@/components/shipmentreturn/ReasonTypeSelect.vue';
import SelectProducts from '@/components/shipmentreturn/SelectProducts.vue';

export default {
    name: "Update",
    components: {
        SelectProducts,
        ReasonTypeSelect
    },
    data(){
        return {
            fetching: false,
            shipments: [],
            type: null,
            reason: null,
            comments_required: false,
            quantity_required: false,
        }
    },
    computed: {
        ...mapGetters('shipmentreturn', ['types', 'reasons', 'shipments_details', 'load_return']),
        ...mapState('loads', ['load', 'load_shipments']),
    },
    methods: {
        ...mapActions('shipmentreturn', ['putShipmentReturn', 'getTypes', 'getReasons', 'getShipmentReturn']),
        setType(code){
            if(!code) return false;
            this.reason = null;
            let id_type = this.types.find(e => e.code === code).id;
            this.getReasons({id: id_type});
            this.type = code;
        },
        setReason(code){
            if(!code){
                this.reason = null;
                this.comments_required = false;
                this.quantity_required = false;
                return false
            }
            let selected = this.reasons.find(e => e.code === code);
            this.quantity_required = selected.quantity_required;
            this.comments_required = selected.comments_required;
            this.reason = code;
        },
        async setTypeReason(type_code, reason_code){
            let selected_type = this.types.find(e => e.code === type_code);
            if(!selected_type) return false;
            this.getReasons({id: selected_type.id})
                .then(result => {
                    let selected_reason = result.find(e => e.code === reason_code);
                    this.type = type_code;
                    this.quantity_required = selected_reason.quantity_required;
                    this.comments_required = selected_reason.comments_required;
                    //this.reason = reason_code;
                })
                .finally(() => {
                    this.reason = reason_code;
                });
        },
        onSave(){
            this.fetching = true;
            let copy_shipments = _.cloneDeep(this.shipments);
            let payload = {}
            copy_shipments[0].details[0].quantity = Number(copy_shipments[0].details[0].quantity_send)
            delete copy_shipments[0].details[0].quantity_available
            delete copy_shipments[0].details[0].quantity_send
            delete copy_shipments[0].details[0].checked
            payload.id = copy_shipments[0].id;
            payload.details = copy_shipments[0].details;
            payload.type = this.type;
            payload.reason = this.reason;
            this.putShipmentReturn(payload)
                .then(result => {
                    Swal.fire({
                        title: this.$t("Updated successfully"),
                        icon: "success",
                    }).then(result => {
                        this.$router.push({name:'loads.detail.returns', params: { load_id: this.$route.params.load_id }});
                    });
                })
                .catch(error => {
                    this.$root.$bvToast.toast(this.$t("An error has occurred, please try again later"), {
                        title: this.$t('Error'),
                        variant: 'danger',
                        solid: true
                    });
                })
                .finally(() => {
                    this.fetching = false;
                });
        },
        onBack(){
            this.$router.push({name:'loads.detail.returns', params: { load_id: this.$route.params.load_id }});
        },
        getShipperExternalId(_id){
            let find = this.load_shipments.find(e => e._id === _id)
            return find ? find.shipper_external_id : ' - ';
        },
        getQuantityReturn(shipment){
            const { shipment_number, lot, code } = shipment
            let { shipment_returns } = this.load_return;
            let _shipments = shipment_returns.filter(e => e.shipment_number === shipment_number);
            let sum = 0;
            if(_shipments.length > 0){
                _shipments.map(_shipment => {
                    _shipment.details.map(detail => {
                        if(detail.lot === lot && detail.code === code){ // check lote && code
                            sum += Number(detail.quantity)
                        }
                    });
                });
            }
            return this.shipments_details.data.find(e => e.shipment_number === shipment_number).details[0].quantity - sum;
        }
    },
    created() {
        this.fetching = true;
        if(this.types.length === 0) this.getTypes();
        this.getShipmentReturn({id: this.$route.params.shipment_return})
            .then(result => {
                // console.log("datos devolucion", result);
                let copy_result = _.cloneDeep(result);
                //copy_result.checked = true;
                copy_result.details.map(detail => {
                    detail.checked = true;
                    detail.shipment_number = result.shipment_number;
                    detail.shipper_external_id = this.getShipperExternalId(detail.shipment_id);
                    detail.quantity_available = this.getQuantityReturn(detail)
                    detail.quantity_send = 0;
                });
                this.setTypeReason(copy_result.type.code, copy_result.reason.code);
                // copy_result.shipper_external_id = this.getShipperExternalId(copy_result.shipment_id)
                this.shipments = [copy_result];
            })
            .catch(error => {
                console.log("error", error);
                this.$root.$bvToast.toast(this.$t("An error has occurred, please try again later"), {
                    title: this.$t('Error'),
                    variant: 'danger',
                    solid: true
                });
            })
            .finally(() => {
                this.fetching = false;
            });
    },
}
</script>

<style lang="scss" scoped>
.card-header{
    text-transform: capitalize!important;
}
</style>
