<template>
    <div>
        <checklist-form v-if="false" />
        <div class="row mt-4">
            <div class="col-md-8 offset-md-2">
                <loading v-if="loading" />
                <div v-else class="d-flex justify-content-end mb-3 mt-3">
                    <button v-if="isEditable" v-b-modal.modal-backdrop class="btn btn-primary mr-2">
                        <i class="far fa-plus-circle mr-2" />{{$t('Add')}}
                    </button>
                    <button @click="download" class="btn btn-secondary mr-2">
                      <i class="far fa-download mr-2" />{{$t('Download')}}
                    </button>

                    <b-modal no-close-on-backdrop
                             id="modal-backdrop"
                             :title="$t('Select a stop to continue')"
                             title-class="font-18"
                             cancel-variant="light"
                             :ok-title="$t('Next')"
                             :cancel-title="$t('Cancel')"
                             :ok-disabled="!stop"
                             v-on:ok="createNewChecklist"
                    >
                       <div>
                           <multiselect v-model="stop" :options="formattedStops" :placeholder="$t('Select an option')"
                                         track-by="value" label="text" select-label="" selected-label="" />
                       </div>
                    </b-modal>

                </div>
                <div v-if="empty">
                    <empty-results :subtitle="$t('This shipment does not have a checklist')"/>
                </div>
                <div class="card" :key="'stop'+instance.id" v-for="(instance, cIndex) in items">
                    <div class="card-header">
                        <div class="d-flex justify-content-between">
                           <div>
                               <h6 style="display: inline;">{{instance.form.name}}</h6>
                           </div>
                            <router-link v-if="isEditable && instance.editable" :to="{name: 'shipments.detail.checklist.edit', params:{checklist_id: instance.id, stop_id: instance.owner.id}}" class="btn btn-light btn-sm">
                                <i class="far fa-edit mr-2" />{{$t('Edit')}}
                            </router-link>
                        </div>
                    </div>
                    <div class="form-fields">
                        <template v-for="(field, idx) in instance.form.fields">
                            <template v-if="['pod'].indexOf(field.type) > -1">
                            <div class="field-wrapper" :key="'field-pod-'+field.id" >
                              <div >
                                <div class="row align-items-center ">
                                  <div class="col-md-6">
                                    <div class="field-label">{{field.title}}</div>
                                    <div class="field-help">{{field.help}}</div>
                                  </div>
                                  <div class="col-md-6">
                                    <div v-if="field.answer_updated_at" class="field-label float-right">{{ $t('Last update') }}: {{ field.answer_updated_at | timestamp }}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="">
                                <loading v-if="loading_files"/>
                                <div class="" >

                                  <template v-if="field.answer">
                                    <div class="d-flex justify-content-center flex-wrap p-3">
                                      <vue-easy-lightbox
                                          :visible="preview_pod"
                                          :index="0"
                                          :imgs="[url_active]"
                                          @hide="preview_pod = false"
                                      ></vue-easy-lightbox>
                                      <div class="card" style="width: 100%"  :key="'fl'+gIndex" v-for="(item, gIndex) in field.answer_metadata">
                                        <div class="card-body">
                                          <div class="row align-items-center">
                                            <div class="col-md-1 col-sm-1">
                                              <template v-if="getExtension(item) === 'pdf'">
                                                <a class="mr-0"  v-if="item.preview"   href="javascript:void(0);"  @click="showPdf(item.path)"  >
                                                  <img :src="item.preview"
                                                       alt class="hover_zoom"
                                                       width="45"
                                                       height="52"
                                                       style="object-fit: cover;" />
                                                </a>
                                                <a class="mr-0" v-else target="popup" href="javascript:void(0);" @click="showPdf(item.path)" >
                                                  <i class="far fa-file-pdf" style="font-size: 3rem;" />
                                                </a>
                                              </template>
                                              <template v-if="getExtension(item) === 'xml'">
                                                <a class="mr-0" target="popup" href="javascript:void(0);" @click="showPdf(item.path)" >
                                                  <i class="far fa-file-excel" style="font-size: 2rem;" />
                                                </a>
                                              </template>
                                              <template v-if="getExtension(item) === 'xls' || item.extension === 'xlsx'">
                                                <a class="mr-0" target="popup" href="javascript:void(0);" @click="showPdf(item.path)" >
                                                  <i class="far fa-file-spreadsheet" style="font-size: 2rem;" />
                                                </a>
                                              </template>
                                              <template v-if="imgExtensions.indexOf(getExtension(item)) > -1">
                                                <a class="mr-0"  href="javascript:void(0);" @click="showImage(item.path)" >
                                                  <img :src="getPath(item.path)"
                                                       alt
                                                       width="45"
                                                       height="52"
                                                       style="object-fit: cover;" />
                                                </a>
                                              </template>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
                                              <div>
                                                <h6 class="text-muted">{{$t('Name')}}</h6>
                                              </div>
                                              <div>
                                                <h6 class="one-line">
                                                  <template v-if="getExtension(item) === 'pdf'">
                                                    <a class="mr-0 text-primary" target="popup" href="javascript:void(0);" @click="showPdf(item.path)">
                                                      {{item.filename}}
                                                    </a>
                                                  </template>
                                                  <template v-else-if="imgExtensions.indexOf(getExtension(item)) > -1">
                                                    <a class="mr-0 text-primary" href="javascript:void(0);" @click="showImage(item.path)" >
                                                      {{item.filename}}
                                                    </a>
                                                  </template>
                                                  <template v-else>
                                                    <a class="mr-0 text-primary" target="popup" href="javascript:void(0);" @click="showPdf(item.path)">
                                                      {{item.filename}}
                                                    </a>
                                                  </template>
                                                </h6>
                                              </div>
                                            </div>
                                            <div class="col-md-3 col-sm-3">
                                              <div>
                                                <h6 class="text-muted">{{$t('Upload at')}}:</h6>
                                              </div>
                                              <div>
                                                <h6 class="one-line">{{item.created_at | timestamp('YYYY-MM-DD HH:mm')}}</h6>
                                              </div>
                                            </div>
                                            <div class="col-md-3 col-sm-4">
                                              <div>
                                                <h6 class="text-muted">{{$t('Upload by')}}:</h6>
                                              </div>
                                              <div>
                                                <h6 class="one-line" v-if="item.user">{{item.user.fullname}}</h6>
                                                <h6 class="one-line" v-else>--</h6>
                                              </div>
                                            </div>
                                            <div class="col-md-1 col-sm-1">

                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <empty-results :title="$t('No photos')" :subtitle="$t('No photos have been uploaded')" />
                                  </template>
                                </div>
                              </div>
                            </div>
                          </template>
                            <template v-else-if="['gallery'].indexOf(field.type) > -1">

                                <div class="field-wrapper" :key="'field-'+field.id" >
                                    <div >
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{field.title}}</div>
                                                <div class="field-help">{{field.help}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <template v-if="field.answer">
                                        <div class="d-flex justify-content-center flex-wrap p-3">
                                            <a
                                                    v-for="(item, gIndex) in field.answer"
                                                    :key="field.id+'-'+gIndex"
                                                    @click="() => showGallery(cIndex, idx, gIndex)"
                                                    class="cursor-pointer"
                                            >
                                                <div class="img-fluid">
                                                    <img :src="`${item}`" alt width="80" height="80" style="object-fit: cover;" />
                                                </div>
                                            </a>
                                        </div>
                                        <vue-easy-lightbox
                                                :visible="field.galleryVisible"
                                                :index="index"
                                                :imgs="field.answer"
                                                @hide="field.galleryVisible = false"
                                        ></vue-easy-lightbox>
                                    </template>
                                    <template v-else>
                                        <empty-results :title="$t('No photos')" :subtitle="$t('No photos have been uploaded')" />
                                    </template>
                                </div>
                            </template>

                            <form-input
                                    v-else
                                    :key="'field-'+field.id"
                                    :title="field.title"
                                    :help="field.help"
                                    v-model="field.answer"
                                    :type="field.type"
                                    :name="'field.'+idx+'.title'"
                                    :options="field.options"
                                    :read-only="readOnly"
                                    v-on:change="(value)=>saveAnswer(instance.owner.id, instance.id, field.id, value)"
                            />
                        </template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ChecklistForm from "@/views/loads/ChecklistForm";
    import {mapActions, mapState} from 'vuex';
    import FileInput from "@/components/form/FileInput";
    import VueEasyLightbox from "vue-easy-lightbox";
    import Multiselect from 'vue-multiselect';
    import addressFormat from "@/helpers/address-format";
    import formMixin from '@/mixins/formMixin';
    import Swal from "sweetalert2";

    export default {
        name: "Checklist",
        components:{
            ChecklistForm,
            FileInput,
            VueEasyLightbox,
            Multiselect
        },
        mixins:[formMixin],
        data(){
            return {
                loading: false,
                items: [],
                index : 0,
                loading_files: false,
                preview_pod: null,
                url_active: null,
                readOnly: true,
                stop: null,
                empty: false,
                downloading: false,
                imgExtensions : ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'svg', 'svgz', 'cgm', 'djv', 'djvu', 'ico', 'ief','jpe', 'pbm', 'pgm', 'pnm', 'ppm', 'ras', 'rgb', 'tif', 'tiff', 'wbmp', 'xbm', 'xpm', 'xwd']
            }
        },
        computed:{
            ...mapState('shipments',['checklist','shipment','stops']),
            ...mapState('profile',['profile']),
            formattedStops(){
                return this.stops.map((item,index) => {
                    let lines = addressFormat(item.address);
                    let  prefix = `${this.$t('Stop')} ${item.order}`
                    if(index === 0){
                        prefix = `${prefix} - ${this.$t('Pickup')}`;
                    }else if(index === (this.stops.length -1)){
                        prefix = `${prefix} - ${this.$t('Delivery')}`;
                    }
                    return {
                        text: `[${prefix}] ${lines.join(', ')}`,
                        value: item.id
                    }
                })
            },
            isEditable(){
              if(['delivered','pod','completed','cancelled'].indexOf(this.shipment.status) > -1)
                return false;
              if(this.$route.params.profile === 'admin'){
                return true;
              }
              if(this.$route.params.profile === 'shipper'){
                return this.shipment.shipper.id === this.profile.profile.id;
              }
              return false;
            }
        },
        watch: {
            checklist: {
                deep: true,
                handler(value){
                    this.items = _.cloneDeep(value);
                }
            }
        },
        created() {
            if(this.checklist.length > 0){
                this.items = _.cloneDeep(this.checklist);
            }else{
                this.loading = true;
            }
            this.getAllChecklist({id: this.$route.params.load_id}).then(data=>{
                if(data.length === 0){
                    this.empty = true;
                }
            }).catch(error =>{

            }).finally(()=>{
                this.loading = false;
            });
        },
        methods:{
            ...mapActions('shipments',['getAllChecklist','storeChecklistAnswer','downloadPOD','podCompleted','getLoad']),
            onClickGallery(slide){
                // TODO: remove image  (slide.index)

            },
            showPdf(url){
              window.open(url,'popup','width=600,height=600');
            },
            saveAnswer(stopId, checklistId,fieldId, value)
            {
                let payload = {
                    load_id : this.$route.params.load_id,
                    stop_id: stopId,
                    checklist_id : checklistId,
                    field_id: fieldId,
                    value: value
                };
                this.storeChecklistAnswer(payload);
            },
            appendImage(stopId, checklistId,fieldId, url){
                let checklist = _.cloneDeep(this.checklist);
                let form = checklist.find(item => item.id === checklistId);
                let field = form.form.fields.find(item => item.id === fieldId);
                let answer = field.answer ? field.answer : [];
                answer.push(url);
                this.saveAnswer(stopId, checklistId, fieldId, answer);
            },
            showGallery(checklistIndex, fieldIndex, index) {
                this.index = index;
                let items = _.cloneDeep(this.items);
                items[checklistIndex].form.fields[fieldIndex].galleryVisible = true;
                this.items = items;
            },
            createNewChecklist(){
                this.$router.push({
                    name: 'shipments.detail.checklist.add',
                    params: {load_id: this.$route.params.load_id, stop_id: this.stop.value}
                });
            },
            download(){
              let payload = {
                id : this.$route.params.load_id,
              };
              this.downloading = true;
              this.downloadPOD(payload)
                  .then(data =>{

                  })
                  .catch(error =>{
                    this.processServiceError(error);
                  })
                  .finally(()=>{
                    this.downloading = false;
                  });
            },
            getExtension(item){
              return item.extension ?  item.extension.toLowerCase() : item.filename.match(new RegExp('[^.]+$'))[0].toLowerCase();
            },
            confirmPodCompleted(){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    html: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: this.$t("Accept"),
                    cancelButtonText: this.$t("Cancel"),
                    reverseButtons: true
                })
                .then(result => {
                    if(result.value) {
                        this.podCompleted({
                                id : this.$route.params.load_id,
                        })
                        .then(data =>{
                            this.getLoad({id: this.$route.params.load_id});
                            this.$root.$bvToast.toast(this.$t("The action was completed successfully"), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        });
                    }

                });
            },
        }
    }
</script>

<style scoped>

</style>
