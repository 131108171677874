import { render, staticRenderFns } from "./ReasonTypeSelect.vue?vue&type=template&id=75a7648c&scoped=true&"
import script from "./ReasonTypeSelect.vue?vue&type=script&lang=js&"
export * from "./ReasonTypeSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75a7648c",
  null
  
)

export default component.exports