<template>
  <div class="tpx_calendar">
    <div class="actions_btn">
      <div class="text-primary font_bold cursor-pointer mr-3" @click="lastMonth">
        <i class="ri-arrow-left-s-line font-size-24"></i>
      </div>
      <div class="text-primary font_bold mr-3 title">
        {{ localDate.format("MMMM YYYY") }}
      </div>
      <div class="text-primary font_bold cursor-pointer mr-3" @click="nextMonth">
        <i class="ri-arrow-right-s-line font-size-24"></i>
      </div>
    </div>

    <div class="dates ml-n4" ref="month">
      <div class="item dow" v-for="dow in days">{{ $t(dow) }}</div>
      <div v-for="blank in firstDayOfMonth" class="day day_blank"></div>
      <div v-for="numberDay in daysInMonth" @click="openday(numberDay)" :class="{
        disable: isActive(numberDay)
      }" :ref="'day_' + numberDay" class="day">
        <div class="item" :class="{
          active: numberDay === currentDay || numberDay === origin_date.day,
          pickup: numberDay === origin_date.day,
          extra_day: origin_date.day < numberDay && numberDay < currentDay || (id === 'destination-date' && numberDay === origin_date.day),
          delivery: numberDay === currentDay && numberDay != origin_date.day && originDate && id != 'pickup-date',
        }">

          <div class="label">
            <div class="label_element">
              <span v-if="(origin_date.day < numberDay && numberDay < currentDay && numberDay >= suggested_date.day)">
                {{ $t("Extra day") }}
              </span>

              <span
                v-if="(numberDay == suggested_date?.day) && !(origin_date?.day < numberDay && numberDay < currentDay) && !(numberDay === currentDay)">
                {{ $t("Fecha sugerida") }}
              </span>

              <div v-if="numberDay === origin_date.day" class="label_element marker">
                <div class="label_element marker_items">
                  <span><i class="far fa-map-marker-alt"></i></span>
                  <span><i class="fas fa-check"></i></span>
                </div>
              </div>

              <div v-if="(numberDay === currentDay) || (id === 'pickup-date' && numberDay === origin_date.day)">
                <span>
                  {{ $t(origin_date?.day && id == "destination-date" ? "Delivery" : "Pickup") }}
                </span>
              </div>

              <b class="number-day">{{ numberDay }}</b>

              <div
                v-if="(numberDay == suggested_date?.day) && !(origin_date?.day < numberDay && numberDay < currentDay) && !(numberDay === currentDay)"
                class="label_element_extra">
                <div class="label_element_extra better_time">
                  <span>{{ $t('Best time') }}</span>
                </div>
                <span class="label_element_extra check"><i class="fas fa-check"></i></span>
              </div>

              <div v-if="(origin_date.day < numberDay && numberDay < currentDay && numberDay >= suggested_date.day)"
                class="label_element_extra">
                <div class="label_element_extra extra_day">
                  <span>$2,500</span>
                </div>
                <span class="label_element_extra coin"><i class="fa fa-dollar-sign"></i></span>
              </div>

              <div v-if="numberDay === currentDay || (id === 'pickup-date' && numberDay === origin_date.day)">
                <span>
                  {{ `${hourFormat(time_from.hour, time_from.minute)} - ${hourFormat(time_to.hour, time_to.minute)}` }}
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <TransparentModal :showModal="showModal" :position_x="postitionXModal" :position_y="postitionYModal"
      @close="closeModal">
      <div class="date_from" v-if="step_time_picker === 0">
        <div class="date_from_label font_semi_bold font-size-13 text-center pt-2">
          {{ $t(origin_date ? "Enter or select the pickup time" : "Enter or select the delivery time") }}
        </div>
        <time-picker-txp v-model="time_from" key="from" :mode="12" :onTimeChange="timeChangeHandlerFrom"
          :defaultFocused="false" :defaultHour="time_from.hour">
        </time-picker-txp>
      </div>
      <div class="date_from" v-if="step_time_picker === 1">
        <div class="date_from_label font_semi_bold font-size-13 text-center pt-2">
          {{ $t(origin_date ? "Enter or select the pickup time" : "Enter or select the delivery time") }}
        </div>
        <div v-if="timeError" class="time_error">{{ $t('Error') }}</div>
        <time-picker-txp v-model="time_to" key="to" :mode="12" :onTimeChange="timeChangeHandlerTo"
          :defaultFocused="false" :defaultHour="time_to.hour">
        </time-picker-txp>
      </div>
      <div class="container_intervals">
        <div class="container_interval" @_click="step_time_picker = 0" :class="step_time_picker === 0 ? 'active' : ''">
          <div class="container_interval_title">{{ $t('Desde') }}</div>
          <div class="container_interval_value upper">
            {{ hourFormat(time_from.hour, time_from.minute) }}
          </div>
        </div>
        <div class="container_interval" @_click="nextStep(time_to)" :class="step_time_picker === 1 ? 'active' : ''">
          <div class="container_interval_title">{{ $t('Hasta') }}</div>
          <div class="container_interval_value upper">
            {{ hourFormat(time_to.hour, time_to.minute) }}
          </div>
        </div>
      </div>
      <div class="container_buttons">
        <div class="container_button cursor-pointer" @click="closeModal">
          {{ $t("Cancel") }}
        </div>
        <div class="container_button cursor-pointer" @click="nextStep(time_to)">
          {{ $t(step_time_picker === 0 ? "Next" : "Accept") }}
        </div>
      </div>
    </TransparentModal>
    <buttons-steps @eventBack="$emit('calendarBack')" @eventNext="confirm()" :back="true" :next="true"
      :complete="complete" />
  </div>
</template>

<script>
import moment from "moment";
import TransparentModal from "../common/TransparentModal";
import TimePickerTxp from "../TimePicker/TimePickerTxp";
import ButtonsSteps from "../common/ButtonsSteps.vue";
import { mapMutations } from "vuex";

/**
 * Este componete tiene la responsabilidad de mostrar un calendario
 * y realizar las acciones para seleccionar fechas y horas de entrega y recoleccion
 */
export default {
  name: "CalendarPicker",
  components: {
    TimePickerTxp,
    TransparentModal,
    ButtonsSteps
  },
  props: {
    id: {
      type: String,
    },
    originDate: {
      type: Object,
    },
    suggestedDate: {
      type: Object
    },
    destinationDate: {
      type: Object
    }
  },
  data() {
    return {
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      localDate: moment(),
      step_time_picker: 0,
      currentDay: 0,
      currentMonth: 0,
      postitionYModal: 0,
      postitionXModal: 0,
      time_from: {
        hour: 12,
        minute: '00'
      },
      time_to: {
        hour: 12,
        minute: '00'
      },
      showModal: false,
      origin_date: this.originDate ?? {},
      destination_date: this.destinationDate ?? {},
      suggested_date: this.suggestedDate ?? null,
      complete: true,
      timeError: false,
      localDay: moment().date(),
      selectDestination: false,
    };
  },
  created() {
    if (Object.entries(this.origin_date).length > 0) {
      const time_from = this.origin_date?.schedule_from.split(' ')[1].split(':');
      const time_to = this.origin_date?.schedule_to.split(' ')[1].split(':')
      this.time_from = {
        hour: time_from[0],
        minute: time_from[1]
      }
      this.time_to = {
        hour: time_to[0],
        minute: time_to[1]
      }
    }
    if (Object.entries(this.destination_date).length > 0) {
      this.currentDay = this.destination_date.day;
      const time_from = this.destination_date?.schedule_from.split(' ')[1].split(':');
      const time_to = this.destination_date?.schedule_to.split(' ')[1].split(':')
      this.time_from = {
        hour: time_from[0],
        minute: time_from[1]
      }
      this.time_to = {
        hour: time_to[0],
        minute: time_to[1]
      }
    }
  },
  computed: {
    daysInMonth: function () {
      return moment(this.localDate).daysInMonth();
    },
    firstDayOfMonth: function () {
      return moment(this.localDate).startOf("month").weekday();
    },
  },
  methods: {
    ...mapMutations('marketplace', ['addAccessory', 'removeAccessory']),
    confirm() {
      let local = this.localDate.format("DD MM YYYY"),
        schedule_from = null,
        schedule_to = null;
      let date = null,
        day = null,
        year = null,
        month = null;
      if (!this.selectDestination && this.id == "destination-date" && this.suggestedDate) {
        if (this.destination_date.day) {
          day = this.destination_date.day;
          year = this.destination_date.year;
          month = this.destination_date.month;
          date = [day, parseInt(month), year];
        }
        else {
          day = this.suggestedDate.day;
          year = this.suggestedDate.year;
          month = this.suggestedDate.month;
          date = [day, parseInt(month), year];
        }

      }
      else {
        const tempdate = local.split(" ");
        day = tempdate[0];
        year = tempdate[2];
        month = tempdate[1];
        date = tempdate;
      }

      schedule_from = new Date(year, parseInt(month) - 1, day, this.time_from.hour, this.time_from.minute);
      schedule_to = new Date(year, parseInt(month) - 1, day, this.time_to.hour, this.time_to.minute);

      let data = {
        day: parseInt(date[0]),
        month: parseInt(date[1]),
        year: parseInt(date[2]),
        schedule_from: moment(schedule_from).format('YYYY-MM-DD HH:mm'),
        schedule_to: moment(schedule_to).format('YYYY-MM-DD HH:mm')
      };
      if (this.suggestedDate) {
        let suggested_date = moment({ years: this.suggestedDate.year, months: parseInt(this.suggestedDate.month) - 1, date: this.suggestedDate.day });
        let selected_date = moment(schedule_from);
        let quantity = selected_date.diff(moment(suggested_date), 'days');
        if (quantity) {
          this.addAccessory({
            code: "O_ESTANCIA",
            name: "Extra days",
            unit_cost: 2500,
            amount: 2500 * quantity,
            quantity: quantity
          });
        } else {
          this.removeAccessory("O_ESTANCIA");
        }
      }
      this.$emit("calendarNext", data);
    },
    timeChangeHandlerFrom(e) {
      this.time_from = e;
      this.hourValidate("from");
    },
    timeChangeHandlerTo(e) {
      this.time_to = e;
      this.hourValidate("to");
      this.timeValidate(e, (e) => {
        this.timeError = false;
        this.time_to = e;
      })
    },
    nextStep(time_to) {
      if (this.step_time_picker === 0) {
        this.hourValidate("from");
        if (!this.timeError) {
          this.step_time_picker = 1;
        }
      } else {
        this.hourValidate("to");
        this.timeValidate(time_to, (time_to) => {
          this.showModal = false;
        })
      }
    },
    hourValidate(type) {
      if (type && type == "from") {
        const now = moment().format("HH:mm");
        const day = moment().format("YYYY MM DD");
        if (this.time_from.hour <= now.split(":")[0] && this.id == "pickup-date" && this.currentDay <= day.split(" ")[2]) {
          this.calendarError("La hora no puede ser seleccinada ya que esta hora ya paso")
        } else {
          this.timeError = false;
        }
      }
      /*
      if (this.id == "destination-date") {
        if (this.time_from.hour < this.suggestedDate.hour) {
          console.log('test')
          this.calendarError("La hora tiene que ser mayor a la hora aproximada de llegada")
        } else {
          this.timeError = false;
        }
      }*/
    },
    timeValidate(time, callback) {

      const actual = moment(`${this.time_from.hour}:${this.time_from.minute}`, 'hh:mm').format('hh:mm A'),
        validate = moment(`${time.hour}:${time.minute}`, 'hh:mm').format('hh:mm A');

      //console.log(actual, validate)
      if (actual >= validate) {
        this.calendarError("No puedes seleccionar una hora final menor o igual a la hora inicial")
      }
      else {
        callback(time)
      }
    },
    calendarError(message) {
      this.timeError = true;
      this.$root.$bvToast.toast(this.$t(message), {
        title: this.$t('Error'),
        variant: 'danger',
        solid: true
      });
    },
    isActive(day) {
      let month = moment().format("MM");
      let _month = this.localDate.format("MM");
      let _day = moment().format("DD");
      if (_month > month) {
        return false;
      } else if (_month === month) {
        return day < _day;
      } else {
        return true;
      }
    },
    nextMonth: function () {
      this.localDate = moment(this.localDate).add(1, "month");
      this.currentDay = null;
      this.currentMonth = Number(this.localDate.format("MM"));
    },
    lastMonth: function () {
      this.localDate = moment(this.localDate).subtract(1, "month");
      this.currentDay = null;
      this.currentMonth = Number(this.localDate.format("MM"));
    },
    openday(day) {
      if (this.id == "pickup-date") {
        this.origin_date = {}
      }
      if (this.id == "destination-date") {
        if (day < this.suggested_date.day) {
          this.calendarError("No es posible seleccionar una fecha antes de la fecha estimada de llegada");
          return;
        } else {
          this.selectDestination = true;
        }
      }
      this.localDate = moment(this.localDate).set("date", day);
      this.currentMonth = Number(this.localDate.format("MM"));
      this.currentDay = day;
      this.openModal(day);
    },
    closeModal() {
      this.showModal = false;
    },
    openModal(d) {
      let parent = this.$refs.month;
      let day = this.$refs["day_" + d][0];
      let parent_width = parent.offsetWidth;
      let day_width = day.offsetWidth;
      let day_left = day.offsetLeft;
      let day_index = Math.round(day_left / day_width);
      if (parent_width - (day_index + 1) * day_width < 280) {
        this.postitionXModal = (day_index - 1) * day_width - 80;
      } else {
        this.postitionXModal = (day_index + 1) * day_width + 20;
      }
      let total_vertical = Math.round(parent.offsetHeight / day.offsetHeight);
      let index_vertical = Math.round(day.offsetTop / day.offsetHeight);
      this.postitionYModal = (index_vertical * 100) / total_vertical;
      this.step_time_picker = 0;
      this.showModal = true;
      this.hourValidate();
    },
    hourFormat(hour, minute) {
      return moment(`${hour}:${minute}`, 'hh:mm').format('hh:mm A')
    }
  },
};
</script>

<style scoped lang="scss">
.time_error {
  width: 100%;
  padding: 10px;
  text-align: center;
  color: red;
  font-family: Helvetica;
}

.number-day {
  font-size: 18px;
}

.title {
  color: #00B3E6;
  width: 200px;
  font-family: Helvetica !important;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
  margin-bottom: 20px;
}

.container_intervals {
  border-top: 1px solid #dddddd;
  display: flex;
  justify-content: center;
  align-content: space-between;
  padding: 5px 0;
  margin-top: 20px;
}

.container_interval {
  flex: 1;
  text-align: center;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #888888;

  &_title {
    font-size: 11px;
  }

  &_value {
    font-size: 11px;
  }
}

.container_interval:hover {
  cursor: pointer;
}

.container_interval.active {
  font-weight: bold;
  cursor: pointer;
  color: #000000;

  .container_interval_title {
    font-size: 11px;
  }

  .container_interval_value {
    font-size: 11px;
    color: #00b3e6;
  }
}


.tpx_calendar {
  position: relative;

  .container_buttons {
    border-top: 1px solid #dddddd;
    display: flex;
    justify-content: center;
    align-content: space-between;
    padding: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 12px;
  }

  .container_button {
    flex: 1;
    text-align: center;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 0;

    &:nth-child(2) {
      color: #00b3e6;
    }
  }



  .actions_btn {
    font-family: "Inter", sans-serif;
    color: #00b3e6;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    display: flex;
    justify-content: flex-end;
    text-transform: capitalize;
  }

  .dates {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .item {
    text-align: center;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .label {
      font-size: 12px;

      b {
        font-size: 18px;
      }
    }
  }

  .dow {
    color: #888888 !important;
    font-family: Helvetica;
    font-size: 14px;
  }

  .day {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;

    &.disable {
      pointer-events: none;
      opacity: 0.5;

      .item {
        background-color: #F2F2F2;
        border: 1px solid #DDDDDD;
      }
    }

    .item {
      border: 1px solid #bababa;
      border-radius: 12px;
      position: relative;
      box-sizing: border-box;
      height: 90px;
      width: 90%;
      border: 1px solid #DDDDDD;
      border-radius: 20px;
      background-color: #FFFFFF;

      &.active {
        background: #00b3e6;
        border: 1px solid #00b3e6;
        color: white;
      }

      &.delivery {
        background-color: #1163EA;
        border: 1px solid #1163EA;
      }

      &.extra_day {
        background-color: #EDFBFF;
        color: black;
      }


      .label {
        width: 100%;
        height: 100%;

        &_element {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &.marker {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: flex-end;


            &_items {
              color: #00B3E6;
              font-weight: bold;
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              padding: 1em;
            }

          }

          &_extra {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.extra_day {
              background-color: #FFFFFF;
              color: #434619;
              font-family: Helvetica;
              font-size: 15px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 12px;
              padding: 5px;
              border-radius: 18px;
              width: 80px;
              border-radius: 10px 0 0 10px;
              position: absolute;
            }

            &.coin {
              background-color: red;
              color: white;
              height: 25px;
              width: 25px;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              left: 40px;
            }

            &.better_time {
              font-family: Helvetica;
              font-size: 10px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 12px;
              border-radius: 18px;
              width: 100px;
              border-radius: 10px 0 0 10px;
              border-radius: 10px 0 0 10px;
              background-color: #E3ED55;
              position: absolute;
            }

            &.check {
              background-color: #00B3E6;
              color: white;
              height: 15px;
              width: 15px;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              left: 50px;
            }
          }
        }
      }
    }
  }
}
</style>
