<template>
    <b-modal id="modal-assign-load" ref="modal-assign-load" size="md" hide-footer :title="title" :visible="visible"
        v-on:hidden="closeAction">
        <div class="p-2">
            <div class="" v-if="step===1" >
              <div class="col-12 mb-4" v-if="loading">
                <div class="d-flex justify-content-center m-3">
                  <div class="rounded-circle d-flex justify-content-center align-items-center"
                       style="width:80px;height:80px;">
                    <img src="/loading-image.gif" width="80px" />
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <h4 class="text-center font-weight-bold font-size-18">{{$t("Applying to load")}}</h4>
                  <small class="text-center" style="color: #888888; font-weight: bold;">
                    {{$t("Wait a moment, we are applying to your load")}}
                  </small>
                </div>
              </div>
              <div class="row" v-else-if="error">
                <div class="col-12 mb-4">
                  <div class="d-flex justify-content-center m-3">
                    <div class="rounded-circle d-flex justify-content-center align-items-center"
                         style="width:80px;height:80px;">
                      <img src="@/assets/images/txp/common/alert_warning.svg" width="80px" />
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <h4 class="text-center font-weight-bold font-size-18">{{$t("Failed to apply load")}}</h4>
                    <small class="text-center" style="color: #888888; font-weight: bold;">
                      {{$t("Something went wrong try again, if problem persists contact us")}}
                    </small>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-2">
                    <button class="form-control btn btn-sm  btn-outline-light" @click="closeAction">{{$t("Cancel")}}</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="step===2" class="">

              <div class="col-12 mb-4" v-if="loading">
                <div class="d-flex justify-content-center m-3">
                  <div class="rounded-circle d-flex justify-content-center align-items-center"
                       style="width:80px;height:80px;">
                    <img src="/loading-image.gif" width="80px" />
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <h4 class="text-center font-weight-bold font-size-18">{{$t("You applied correctly, generating load")}}</h4>
                  <small class="text-center" style="color: #888888; font-weight: bold;">
                    {{$t("Wait a moment, we are generating your load")}}
                  </small>
                </div>
              </div>
              <div class="row"  v-else-if="!error">
                  <div class="col-12 mb-4">
                    <div class="d-flex justify-content-center m-3">
                      <div class="rounded-circle d-flex justify-content-center align-items-center"
                           style="width:80px;height:80px;">
                        <img src="@/assets/images/txp/box.png" width="80px" />
                      </div>
                    </div>
                    <div class="d-flex flex-column">
                      <h4 class="text-center font-weight-bold font-size-18">{{$t("You applied correctly")}}</h4>
                      <small class="text-center" style="color: #888888; font-weight: bold;"  v-if="loading" >
                        {{$t("")}}
                      </small>
                      <small class="text-center" style="color: #888888; font-weight: bold;" v-else>
                        {{$t("You can see the details of the load in the following link:")}}
                      </small>
                    </div>
                  </div>


                  <div class="col-12" >
                    <div class="mb-2">
                      <button class="form-control btn btn-sm  btn-primary_v2" @click="getLoadCreated">{{$t("Go to detail")}}</button>
                    </div>
                    <div>
                      <button class="form-control btn btn-sm  btn-outline-light"  @click="closeAction" >{{$t("Search loads")}}</button>
                    </div>
                  </div>


                </div>
              <div class="row" v-else-if="error">
                <div class="col-12 mb-4">
                  <div class="d-flex justify-content-center m-3">
                    <div class="rounded-circle d-flex justify-content-center align-items-center"
                         style="width:80px;height:80px;">
                      <img src="@/assets/images/txp/common/alert_warning.svg" width="80px" />
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <h4 class="text-center font-weight-bold font-size-18">{{$t("Failed to apply load")}}</h4>
                    <small class="text-center" style="color: #888888; font-weight: bold;">
                      {{$t("Something went wrong try again, if problem persists contact us")}}
                    </small>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-2">
                    <button class="form-control btn btn-sm  btn-outline-light" @click="closeAction">{{$t("Cancel")}}</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import {mapActions} from "vuex";

export default {
    name: "AssignModal",
    props: {
        visible: Boolean,
        title: String,
        load: String,
        loading: {
          type: Boolean,
          default(){
            return true
          }
        },
        error: {
          type: Boolean,
          default(){
            return true
          }
        },
        step: {
          type: Number,
          default(){
            return 1
          }
        },
        loadAttach: {
          type: Number,
          default(){
            return 1
          }
        },
    },
    data() {
        return {
            activeButton: true
        }
    },
    methods: {
        ...mapActions('marketplace', ['getLoadByLoadNumber']),
        closeAction() {
            this.activeButton = true
            this.$emit('close', true)
        },
        getLoadCreated(){
          this.$router.push({
            name:'loads.detail.general',
            params: {
              load_id: this.loadAttach
            }
          })
        }
    }
}
</script>
<style lang="">
    
</style>