export default {
    data(){
        return {
            notification: {
                visible:false,
                type: false,
                title:'',
                message:'',
                sub_message:'',
            },
        }
    },
    computed : {

    },
    methods : {
        closeNotification(){
            this.notification.visible = false;
        },
        openNotification(){
            this.notification.visible = true;
        },
        notificationSuccess(title,message,sub_message){
            this.notification.title = title;
            this.notification.message = message;
            this.notification.sub_message = sub_message;
            this.notification.success = true
            this.openNotification();
        },
        notificationError(title,message,sub_message){
            this.notification.title = title;
            this.notification.message = message;
            this.notification.sub_message = sub_message;
            this.notification.success = false
            this.openNotification();
        },
        notificationErrorDefault(title,message,sub_message){
            this.notification.title = this.$t("Error");
            this.notification.message =  this.$t("Something went wrong when action processed");
            this.notification.sub_message = this.$t("Something went wrong try again, if problem persists contact us");
            this.notification.success = false
            this.openNotification();
        },
    },
    watch:{

    }
}
