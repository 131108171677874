<template>
    <div class="row">
        <div class="col-12 d-flex justify-content-between">
            <span class="text-gray-888 font-weight-lighter font-size-16">{{$t('Select one of the following options')}}:</span>
            <span class="badge badge-pill badge-primary px-3 py-1 font-size-12" role="button" @click="reset">
                <span class="align-middle">{{$t('Commodity')}}: {{commodity.name}} </span>
                <i class="ri-pencil-fill align-middle"></i>
            </span>
        </div>
        <div class="col-12 mb-3">
            <b-form-group v-slot="{ ariaDescribedby }" class="mt-3">
                <b-form-radio-group
                    id="radio-group-2"
                    :aria-describedby="ariaDescribedby"
                    name="radio-sub-component"
                    v-model="option"
                >
                    <div class="d-flex align-items-center">
                        <span class="font-size-12 font-weight-bold mr-2">{{$t('I know the type of truck I require')}}</span>
                        <b-form-radio value="1" />
                        <span class="font-size-12 font-weight-bold mr-2">{{$t('I know the dimensions of the load')}}</span>
                        <b-form-radio value="2" />
                    </div>
                </b-form-radio-group>
            </b-form-group>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "CommodityOptions",
    props: {
        reset: { type: Function },
        commodity_option: {type: Number},
        setOption: { type: Function },
    },
    computed: {
        ...mapGetters('marketplace', ['commodity']),
        option: {
            get() { return this.commodity_option },
            set(option) { this.setOption(option) }
        }
    }
}
</script>

<style scoped>

</style>
