<template>
<div>
    <div :key="'or-'+order.id+'-it-'+item.id" v-for="item in order.items">
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <h6 class="text-primary">{{$t(item.product.category.name)}}</h6>
                <h3>{{item.product.name}}</h3>
            </div>
            <div class="col-md-6 col-sm-6 text-right">
                <button v-if="$route.params.profile === 'seller'" class="btn btn-primary">{{$t('Confirm Delivery')}}</button>
            </div>
        </div>
            <hr class="my-0" />

            <div class="row py-3 align-items-center">
                <div class="col-md-3 col-sm-3">
                    <div>{{$t('Price')}}:</div>
                     <b style="font-size: 20px;">${{item.unit_price | money}} </b> <b class="text-primary">{{item.currency}}</b>
                </div>
                <div class="col-md-3 col-sm-3">
                    <div>{{$t('Quantity')}}: </div>
                    <b style="font-size: 20px;">{{item.quantity}} </b> <b class="text-primary">L</b>
                </div>
                <div class="col-md-3 col-sm-3">
                    <div>{{$t('Total')}}:</div>
                    <b style="font-size: 20px;">${{item.total | money}} </b> <b class="text-primary">{{item.currency}}</b>
                </div>
                <div class="col-md-3 col-sm-3">
                    <div>{{$t('Branch office')}}:</div>
                    <b >{{order.branch_office.name}}</b> 
                </div>
            </div>
            <hr class="my-0" />

            <div class="row py-3">
                <div class="col-md-4 col-sm-3">
                    <div class="text-center rounded-lg p-3" style="background-color: #f2f2f2">
                        <div>{{$t('Created at')}}</div>
                        <div><b>{{order.created_at | timestamp}}</b></div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-3">
                    <div class="text-center rounded-lg p-3" style="background-color: #f2f2f2">
                        <div>{{$t('Delivery date')}}</div>
                        <div><b v-if="order.delivery">{{order.delivery.scheduled_date | timestamp('YYYY-MM-DD')}}</b></div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-3">
                    <div class="text-center rounded-lg p-3" style="background-color: #f2f2f2">
                        <div>{{$t('Created by')}}</div>
                        <div><b>{{order.created_by.fullname}}</b></div>
                    </div>
                </div>
            </div>
    </div>
</div>
</template>


<script>

export default {
    name: 'FuelOrderDetail',
    props:{
        order:{
            type: Object,
            required: true
        }
    }
}
</script>
