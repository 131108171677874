<template>
    <div class="main-content">
        <loading v-if="loading" />
        <div v-else class="page-content p-0 pl-4 pr-4">
            <div class="row">
                <div class="col-12">
                    <router-link :to="{name:'search'}"  >< {{ $t("Go Back") }}</router-link>
                </div>
                <div class="col-12 pt-3">
                    <h2 class="title font_bold font-size-24 ">{{$t("Load")}} {{ load.load_number }}</h2>
                </div>
            </div>
          <div class="row mt-3">
            <div class="col-md-7 pt-2">
              <polyline-map  :center="center" :markers="markers" :extra_options="extra_options" />
              <div class="pt-2">
                <div style="border-bottom: solid #DDD 1px; border-top: solid #DDD 1px" class="font-size-18 my-4 py-3 font-weight-bold font-family-inter">{{ $t('Pickup and delivery') }}</div>

                <div class="row w-100 mb-4 pl-3">
                  <div class="col-12 origin mb-4">
                    <div>
                                    <span class="font-size-12 font-weight-lighter text-gray-888">
                                        {{$t('Pickup address')}}:
                                    </span>
                      <span class="font-size-12 font-weight-bold font-family-inter ml-3">{{ pickupAddress }}</span>
                    </div>
                    <div>
                                    <span class="font-size-12 font-weight-lighter text-gray-888">
                                        {{$t('Pickup date')}}:
                                    </span>
                      <span class="font-size-12 font-weight-bold font-family-inter ml-3">
                                        {{ originDate }}
                                    </span>
                    </div>
                  </div>
                  <div class="col-md-12 destination mb-4">
                    <div>
                                    <span class="font-size-12 font-weight-lighter text-gray-888">
                                        {{$t('Delivery address')}}:
                                    </span>
                      <span class="font-size-12 font-weight-bold font-family-inter ml-3">{{ deliveryAddress }}</span>
                    </div>
                    <div>
                                    <span class="font-size-12 font-weight-lighter text-gray-888">
                                        {{$t('Delivery date')}}:
                                    </span>
                      <span class="font-size-12 font-weight-bold font-family-inter ml-3">
                                        {{ destinationDate }}
                                    </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="font-family-helvetica">
                <div class="title font-size-18 my-3 py-3 font-weight-bold font-family-inter">{{$t('Team')}}</div>
                <div class="equipment_container">

                  <div class="equipment_container_item">
                    <div class="equipment_container_item_label">
                      <div class="font-size-12 font-weight-lighter text-gray-888">{{$t('Team')}}</div>
                      <div class="font-size-12 text-primary font-weight-bold font-family-inter text-break">{{equipment}}</div>
                    </div>
                    <div class="equipment_container_item_icon ml-3">
                      <div class="rounded-lg bg-gradient-txp d-flex justify-content-center">
                        <img :src="getSrc('truck', load.equipment_type)" width="60" height="50" />
                      </div>
                    </div>
                  </div>
                  <div class="equipment_container_item">
                    <div class="equipment_container_item_label">
                      <div class="font-size-12 font-weight-lighter text-gray-888 text-break">{{$t('Trailer type')}}</div>
                      <div class="font-size-12 text-primary font-weight-bold font-family-inter">{{trailer}}</div>
                    </div>
                    <div class="equipment_container_item_icon ml-3">
                      <div class="rounded-lg bg-gradient-txp d-flex justify-content-center">
                        <img :src="getSrc('trailer', load.equipment_type)" width="40" height="50" />
                      </div>
                    </div>
                  </div>

                </div>
                <div class="row font-size-12 bg-light-gray border-lg rounded p-3 mt-4" v-if="details">
                  <div class="col-4">
                    <div class="text-gray-888">{{$t('Weight of each')}} {{details.container_type}} (Kg.)</div>
                    <div class="font-weight-bolder font-family-inter">{{details.weight}} Kg.</div>
                  </div>
                  <div class="col-4">
                    <div class="text-gray-888">{{$t('Total of')}} {{details.container_type}}s</div>
                    <div class="font-weight-bolder font-family-inter">{{details.quantity}}</div>
                  </div>
                  <div class="col-4">
                    <div class="text-gray-888">{{$t('Dimensions')}}</div>
                    <div class="font-weight-bolder font-family-inter">
                      {{details.width}}m x {{details.height}}m x {{details.length}}m
                    </div>
                  </div>
                </div>
              </div>


              <div class="mb-5"></div>
            </div>
            <div class="col-md-5   pt-2">
              <div class="row">
                <div class="col-md-8 offset-md-2">
                  <div class="card border_radius_20">
                    <div class="card-header bg_blue_sky">
                      <p class="font-size-12  text-center  mb-1 " >{{$t("Company name")}}</p>
                      <div class="d-flex justify-content-between p-0" style="border-top-left-radius: 20px;">
                        <div class=" d-flex align-items-center justify-content-center pl-2">
                          <div class="rounded-circle d-flex align-items-center justify-content-center icon">
                            <img src="../../../assets/images/txp/company.svg" />
                          </div>
                        </div>
                        <div class="col-10 p-0 d-flex align-items-center pl-2 title font_semi_bold">
                          {{ load.shipments[0].customer_name }}
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-3">
                      <p class="font-size-12  text-center  mb-1 " >{{$t("Purchase price")}}</p>
                      <div class=" price font_semi_bold  ">
                        $ {{ load.price | money }} <span> {{load.currency}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mb-3">
                    <div class="font-size-12 d-flex justify-content-start align-items-center mt-1">
                      <b-form-checkbox v-model="accepted" name="checkbox-1" button-variant="secondary" />
                      <div>
                        {{ $t('I have read and accept the') }}
                        <span class="text-primary">
                            {{ $t('Terms and Conditions') }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-primary_v2 form-control" :disabled="!accepted" @click="onAccept">
                      <span style="font-family:Helvetica; font-size:12px" class="font-weight-bold">{{ $t('I WANT TO CARRY THIS LOAD') }}</span>
                    </button>
                  </div>


                  <div class="contact_container mt-5 cursor-pointer"  @click="showHelp" >
                    <div class="contact_container_icon">
                        <i  class="fa far fa-comment-dots "  />
                    </div>
                    <div class="contact_container_title">
                      <p class="font-size-12  text-center  mb-1 "   >{{$t("Need help?")}}</p>
                      <p class="font_semi_bold text-center font-size-17 title cursor-pointer "    >{{$t("Customer service")}}</p>
                    </div>
                  </div>




                </div>
              </div>
            </div>
          </div>
        </div>


        <assign-modal :load="load.load_number"  :loadAttach="loadAttach" :step="step"  :visible="visible" :title="`${$t('Load')}: ${load.load_number}`" :error="errorApply" :loading="loadingApply" @close="closeModal" />

    </div>
</template>

<script>
import PolylineMap from "@/components/common/PolylineMap.vue";
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from "moment-timezone";
import AssignModal from "../../../components/loads/AssignModal";


export default {
    components: {
        PolylineMap,
        AssignModal
    },
    name: "LoadSearchDetail",
    data() {
        return {
            load: {
              load_number:null
            },
            visible: null,
            loadAttach: null,
            step: 1,
            accepted: false,
            loadingApply: false,
            errorApply: false,
            center: null,
            markers: null,
            loading: true,
            extra_options: { scrollwheel: false, zoomControl: false, fullscreenControl: false, draggable: false }
        }
    },
    computed: {
        ...mapState('profile',['profile']),
        ...mapGetters('catalogs', ['truck_types_marketplace', 'trailer_types_marketplace']),
        equipment() {
          return this.truck_types_marketplace.find(e => (
              e.truck_type_code === this.load.equipment_type.truck_type_code ||
              e.trailer_size_code === this.load.equipment_type.trailer_size_code
          ))?.name;
        },
        trailer() {
          return this.trailer_types_marketplace.find(e => e.external_id === this.load.equipment_type.trailer_type_code)?.name;
        },
        details(){
          return this.load.shipments[0].shipment_details[0]
        },
        userInfo(){
          return {
            user_id: this.profile.id,
            name: this.profile.name+' '+this.profile.last_name,
            email:  this.profile.email ,
          };
        },
        originDate() {
            let origin = this.load.stops[0]
            let date = moment(origin.schedule_from).format('YYYY-MM-DD')
            let ini_hour = moment(origin.schedule_from).format('h:mm A')
            let fin_hour = moment(origin.schedule_to).format('h:mm A')
            return  date + ' ' +  ini_hour + ' - ' + fin_hour
        },
        destinationDate() {
            let destination = this.load.stops[1]
            let date = moment(destination.schedule_from).format('YYYY-MM-DD')
            let ini_hour = moment(destination.schedule_from).format('h:mm A')
            let fin_hour = moment(destination.schedule_to).format('h:mm A')
            return  date + ' ' +  ini_hour + ' - ' + fin_hour
        },
        pickupAddress() {
            if (this.load.origin) {
                return this.getFormattedAddress(this.load.origin)
            } else {
                return this.$t('Empty data')
            }
        },
        deliveryAddress() {
            if (this.load.destination) {
                return this.getFormattedAddress(this.load.destination)
            } else {
                return this.$t('Empty data')
            }
        },
    },
    created() {
        this.fetchData()
    },
    mounted() {
      this.$intercom.boot(this.userInfo);
    },
    methods: {
        ...mapActions('loads', ['getSearchLoad']),
        ...mapActions('marketplace', ['assingLoad']),
        ...mapActions('marketplace', ['getLoadByLoadNumber']),
        fetchData() {
            this.loading = true;
            this.getSearchLoad({ id: this.$route.params.load_id })
                .then(data => {
                    this.load = data
                    this.center = { lat: this.load.origin.location[0], lng: this.load.origin.location[1] }
                    this.markers = [
                        { lat: this.load.origin.location[0], lng: this.load.origin.location[1] },
                        { lat: this.load.destination.location[0], lng: this.load.destination.location[1] }
                    ]
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getFormattedAddress(address){
            return `${address.city}${address.state ? ', '+address.state : ''}`;
        },
        onAccept(){
          this.loadingApply =  true;
          this.visible = true;
          this.step = 1;
          this.errorApply = false;

          this.getLoadByLoadNumber(this.load.load_number).then(d=>{
            this.loadAttach = d.id;
            this.assingLoad(this.load.id).then((d)=>{
              this.loadingApply=false;
              this.step = 2;
              this.loadingApply=true;

              Echo.private(`load_assing.${this.load.id}`)
                  .listen('LoadAssignedEvent', (e) => {
                    this.errorApply = false;
                    this.loadingApply = false;

                  });


              setTimeout( ()=>{
                this.errorApply = false;
                this.loadingApply = false;
              } , 8500);


            }).catch((e)=>{
              this.loadingApply = false;
              this.errorApply = true;
            })
          }).catch(e=>{
            this.loadingApply = false;
            this.errorApply = true;
          })
        },
        closeModal(){
         this.visible = false;
        },
        showHelp(){
          this.$intercom.show();
        },
        getSrc(type, entity) {
          let code
          try {
            switch (type) {
              case 'truck':
                code = entity.truck_type_code === 'TRA' ?  entity.trailer_size_code : entity.truck_type_code;
                return require('@/assets/images/marketplace/trucks/' + code + '.svg');
              case 'trailer':
                return require('@/assets/images/marketplace/trailer/' + entity.trailer_type_code + '_W.svg');
              case 'container':
                return require('@/assets/images/marketplace/containers/' + entity.container_type + '_W.svg');
            }
          } catch (e) {
            return require('@/assets/images/marketplace/trailer/R_SEC_W.svg');
          }
        }
    },
}
</script>

<style lang="scss" scoped>

.contact_container{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin: auto;

  &_icon{
    background: #00B3E6;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      font-size: 26px;
      color: white;
    }

  }
  &_title{
    p.title{
      color: #00B3E6 !important;
    }
  }
}

.equipment_container{
  display: flex;
  justify-content: flex-start;
  &_item{
    display: flex;
    align-items: center;
    padding-right: 10px;
    max-width: 260px;
    &:last-child{
      padding-right: 0;
    }
    &_icon{
      width: 100px
    }
  }
}

.content {
    font-family: Helvetica;
}
.origin {
    border-left: 3px solid #00B3E6;
}

.destination {
    border-left: 3px solid #1163EA;
}

.title {
  font-size: 12px;
  color: #434619;
  line-height: 1;
  letter-spacing: 0;
  font-weight: 600;
}

.border_radius_20{
  border-radius: 20px;
  overflow: hidden;
}

.price{
  color: #00B3E6 !important;
  font-size: 30px;
  text-align: center;
  span{
    font-size: 20px;
  }
}

.icon {
  width:30px;
  height:30px;
  background:#00B336
}

.bg_blue_sky{
  background: #edfbfe!important;
}

</style>
