<template>
  <b-dropdown ref="dropdown" right menu-class="dropdown-menu-lg p-0" toggle-class="header-item noti-icon" variant="black"
    v-on:show="() => setUnread(0)">
    <template v-slot:button-content>
      <i class="ri-notification-3-line"></i>
      <span class="" :class="[unread_notifications > 0 ? 'noti-dot' : '']"></span>
    </template>
    <div class="p-3">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="m-0">{{ $t('Notifications') }}</h6>
        </div>
        <div class="col-auto">
          <a href="javascript:void(0);" class="small" @click="markAllAsRead">{{ $t('Mark as read') }}</a>
        </div>
      </div>
    </div>
    <simplebar style="max-height: 230px;">
      <a href="javascript:void(0);" @click="showNotification(item, index)" class="text-reset notification-item"
        :key="'not-' + index" v-for="(item, index) in allNotifications">
        <div class="media" :class="{ 'unread': !item.read_at }">
          <div class="avatar-xs mr-3">
            <span class="avatar-title bg-primary rounded-circle font-size-16">
              <i class="" :class="[getNotificationIcon(item)]"></i>
            </span>
          </div>
          <div class="media-body">
            <h6 class="mt-0 mb-1" v-if="item.subject">{{ item.subject }}</h6>
            <div class="font-size-12 text-muted">
              <p class="mb-1 two-lines">{{ item.message }}</p>
              <p class="mb-0 ">
                <i class="mdi mdi-clock-outline"></i>
                {{ $moment.utc(item.created_at).tz(profile.preferences.timezone).fromNow() }}
              </p>
            </div>
          </div>
        </div>
      </a>
    </simplebar>
    <div class="p-2 border-top">
      <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0);"
        @click="fetchNotifications()">
        <i class="mdi mdi-arrow-right-circle mr-1"></i>
        {{ $t('Load more...') }}
      </a>
    </div>
  </b-dropdown>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}

.dropdown-user-title {
  text-transform: uppercase !important;
  font-size: 12px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import simplebar from "simplebar-vue";
import { mapState, mapActions, mapMutations } from 'vuex';
const notificatiionSound = require('@/assets/sounds/notification.mp3');
const sound = new Audio(notificatiionSound);
import Pusher from 'pusher-js';
import { getAccessToken } from "../utils/auth";

export default {
  components: { simplebar },
  data() {
    return {
      fetchingNotifications: false,
      marking: false,
      allNotifications: [],
      hasMoreNotifications: true,
      next_token: null
    };
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapState('notification', ['notifications', 'unread_notifications'])
  },
  async created() {
  
      const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        authEndpoint: `${process.env.VUE_APP_API_URL}/notifications/auth`,
        auth: {
          headers: {
            "Authorization": `Bearer ${await getAccessToken()}`,
            "x-workspace": `dms:${this.profile.tenant_id}`
          }
        }
      });

      const channel = pusher.subscribe('private-users-' + this.profile.user_id);
      channel.bind('database-notification', (notification) => {
        sound.play();
        this.allNotifications.unshift(notification);
        this.addNewNotification(notification);
      });
    
    if (['shipper', 'carrier', 'driver'].indexOf(this.profile.profile.object) > -1) {
      Echo.private(`${this.profile.profile.object}s.${this.profile.profile.id}`)
        .notification((notification) => {
          sound.play();
          this.allNotifications.unshift(notification);
          this.addNewNotification(notification);
        });
    }
    this.getUnreadNotifications(this.profile.tenant_id);
    this.fetchNotifications();
  },
  methods: {
    ...mapActions('notification', ['getUnreadNotifications', 'getNotifications', 'getNotification', 'markAsRead', 'addNewNotification']),
    ...mapMutations('notification', ['setUnread']),
    ...mapActions('wallet', ['getWalletByProfile']),
    fetchNotifications() {
      if (this.fetchingNotifications === true || !this.hasMoreNotifications) {
        return;
      }

      this.fetchingNotifications = true;

      const params = {
        next_token: this.next_token,
        tenant_id: this.profile.tenant_id
      };

      this.getNotifications(params)
        .then((data) => {
          this.allNotifications = _.concat(this.allNotifications, _.cloneDeep(data.data));
          this.allNotifications.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          if (data.next_token !== null) {
            this.next_token = data.next_token;
          } else {
            this.next_token = null
            this.hasMoreNotifications = false;
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => this.fetchingNotifications = false);

    },
    markAllAsRead() {
      if (this.marking === true)
        return;
      this.marking = true
      this.markAsRead(this.profile.tenant_id).finally(() => { this.marking = false; });
      for (let i = 0; i < this.allNotifications.length; i++) {
        if (!this.allNotifications[i].read_at) {
          let notification = this.allNotifications[i];
          notification.read_at = this.$moment().format('YYYY-MM-DD HH:mm:ss');
          this.$set(this.allNotifications, i, notification);
        }
      }
    },
    getNotificationIcon(notification) {
      if (notification.context.resource === 'profile') {
        return 'far fa-user-alt';
      }
      if (notification.context.resource === 'load') {
        return 'far far fa-boxes';
      }
      if (notification.context.resource === 'truck') {
        return 'far fa-truck-moving';
      }
      if (notification.context.resource === 'trailer') {
        return 'far fa-container-storage';
      }
      return 'far fa-envelope-open-text';
    },
    showNotification(notification, index) {
      this.$refs.dropdown.hide();
      if (!notification.read_at) {
        notification.read_at = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        this.$set(this.allNotifications, index, notification);
        this.getNotification({ id: notification.id }, this.profile.tenant_id);
      }
      switch (notification.context.resource) {
        case "load":
          this.$router.push({ name: 'loads.detail', params: { load_id: notification.context.resource_id } });
          break;
        case "shipment":
          this.$router.push({ name: 'shipments.detail', params: { load_id: notification.context.resource_id } });
          break;
        case "profile":
          break;
        case "transaction":
          this.$router.push({ name: 'wallet.index' });
          break;
        case "issue":
          this.$router.push({ name: 'loads.detail.issues', params: { load_id: notification.context.body.load_id }, query: { id: notification.resource_id } });
          break;
        case "carrier":
          this.$router.push({ name: 'carriers.detail', params: { carrier_id: notification.context.resource_id } });
          break;
        case "shipper":
          this.$router.push({ name: 'shippers.detail', params: { shipper_id: notification.context.resource_id } });
          break;
        case "Comment":
          this.$router.push({ name: 'loads.detail.issues', params: { load_id: notification.context.body.load_id }, query: { id: notification.context.body.issue_id } });
          break;
        case "driver":
          this.$router.push({ name: 'drivers.detail', params: { driver_id: notification.context.resource_id } });
          break;
        case "truck":
          if (notification.resource_id)
            this.$router.push({ name: 'trucks.detail', params: { truck_id: notification.context.resource_id } });
          else
            this.$router.push({ name: 'trucks.index' });
          break;
        case "trailer":
          if (notification.resource_id)
            this.$router.push({ name: 'trailers.detail', params: { trailer_id: notification.context.resource_id } });
          else
            this.$router.push({ name: 'trailers.index' });
          break;
      }
    },
    getWallet() {
      this.getWalletByProfile({})
        .then(() => { })
        .catch(error => { })
    }
  }
};
</script>
