<template>
    <div class="card shadow">
        <div class="card-body">
            <div class="row no-gutters font-size-13">
                <div class="col-md-1 icon-div">
                    <div class="rounded-circle bg-gradient-txp d-flex justify-content-center align-items-center">
                        <i class="fas fa-undo text-white" />
                    </div>
                </div>
                <div class="col-6 col-md d-flex align-items-center text-break">
                    <div>
                        <div class="text-muted">{{$t('Shipment')}}</div>
                        <div class="font-weight-bold">{{shipment.shipment_number}}</div>
                    </div>
                </div>
                <div class="col-5 offset-1 col-md offset-md-0 d-flex align-items-center text-break">
                    <div>
                        <div class="text-muted">{{$t('Customer reference')}}</div>
                        <div class="font-weight-bold">{{shipper_external_id}}</div>
                    </div>
                </div>
                <div class="col-6 mt-xs col-md d-flex align-items-center text-break">
                    <div>
                        <div class="text-muted">{{$t('Type')}}</div>
                        <div class="">{{shipment.type.name}}</div>
                    </div>
                </div>
                <div class="col-5 offset-1 col-md offset-md-0 mt-xs col-md-2 d-flex align-items-center text-break">
                    <div>
                        <div class="text-muted">{{$t('Reason')}}</div>
                        <div class="">{{shipment.reason.name}}</div>
                    </div>
                </div>
                <div class="col-6 mt-xs col-md d-flex align-items-center text-break">
                    <div>
                        <div class="text-muted">{{$t('Status')}}</div>
                        <div
                            class="badge font-size-10 px-2 py-1"
                            :class="[shipment.status === 'confirmed' ? 'badge-success' : 'badge-warning']"
                        >
                            {{shipment.status}}
                        </div>
                    </div>
                </div>
                <div class="col-5 offset-1 offset-md-0 mt-xs col-md d-flex align-items-center text-break">
                    <div>
                        <div class="text-muted">{{$t('Quantity')}}</div>
                        <div class="">{{shipment.details[0].quantity}} de {{total_quantity}}</div>
                    </div>
                </div>
                <div class="col-6 mt-xs col-md d-flex align-items-center text-break">
                    <div>
                        <div class="text-muted">{{$t('Remission order')}}</div>
                        <div class="font-weight-bold">{{shipment.details[0].remission_order}}</div>
                    </div>
                </div>
                <div class="col-5 offset-1 offset-md-0 mt-xs col-md d-flex align-items-center text-break">
                    <div>
                        <div class="text-muted">{{$t('Replenishment order')}}</div>
                        <div class="font-weight-bold">{{shipment.details[0].replenishment_order}}</div>
                    </div>
                </div>
                <div
                    class="col-6 mt-xs offset-1 offset-md-0 mt-xs col-md d-flex justify-content-md-center align-items-center"
                    v-if="!isEditable && shipment.status !== 'confirmed'"
                >
                    <a href="javascript:void(0);" class="text-dark" @click="() => onUpdate(shipment.id)">
                        <i class="far fa-edit font-size-22" />
                    </a>
                    <a href="javascript:void(0);" class="ml-3 text-dark" @click="onDelete(shipment.id)">
                        <i class="far fa-trash-alt font-size-22" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ShipmentReturnCard",
    props: {
        shipment: Object,
        onUpdate: Function,
        onDelete: Function,
        shipper_external_id: String,
        isEditable: Boolean,
        total_quantity: Number
    },
}
</script>

<style lang="scss" scoped>
.rounded-circle {
    width:35px;
    height:35px;
}

.icon-div{
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .icon-div{
        display: none !important;
        border: 2px solid red;
    }
    .mt-xs{
        margin-top: 7px;
    }
}

</style>
