<template>
    <div>
        <page-header :title="$t('Documents of') + ' ' + $t(this.$route.params.resource)" :items="breadcrumb"/>
        <loading v-if="loading" />
        <div v-else>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h6><i class="far fa-user mr-3"></i>{{ $t('General information') }}</h6>
                        </div>
                        <div class="p-3">
                            <template v-if="this.$route.params.resource === 'carriers'">
                                <carrier-information></carrier-information>
                            </template>
                            <template v-else-if="this.$route.params.resource === 'shippers'">
                                <shipper-information></shipper-information>
                            </template>
                            <template v-else-if="this.$route.params.resource === 'drivers'">
                                <driver-information></driver-information>
                            </template>
                            <template v-else-if="this.$route.params.resource === 'trucks'">
                                <truck-information></truck-information>
                            </template>
                            <template v-else-if="this.$route.params.resource === 'trailers'">
                                <trailer-information></trailer-information>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <document-table></document-table>

        </div>
    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex';
    import Loading from "@/components/common/Loading";
    import CarrierInformation from "@/views/documents/CarrierInformation";
    import ShipperInformation from "@/views/documents/ShipperInformation";
    import DriverInformation from "@/views/documents/DriverInformation";
    import TruckInformation from "@/views/documents/TruckInformation";
    import TrailerInformation from "@/views/documents/TrailerInformation";
    import DocumentTable from "@/components/tables/DocumentTable"

    export default {
        name:'DocumentsResource',
        components:{
            "loading": Loading,
            "CarrierInformation": CarrierInformation,
            "ShipperInformation": ShipperInformation,
            "DriverInformation": DriverInformation,
            "TruckInformation": TruckInformation,
            "TrailerInformation": TrailerInformation,
            "DocumentTable":DocumentTable
        },
        data(){
            return {
               loading: false,
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapState('requirements', ['resource']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                const resource = this.$route.params.resource;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t(resource.charAt(0).toUpperCase() + resource.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t('Documents'),
                        active: true,
                    }
                ]
            }
        },
        created() {
                this.fetchResource(this.$route.params.resource, this.$route.params.resource_id) 
        },
        watch:{
            '$route': 'init'
        },
        methods: {
            ...mapActions('requirements', ['getResource']),
            fetchResource(resource, resource_id){
                this.getResource({resource:resource, resource_id: resource_id})
                        .then(data=>{
                        })
                        .catch(error =>{
                        })
            }
        }
    }
</script>

<style scoped>
    .requirement-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }
</style>
