<template>

  <div class="">

    <input type="text" v-model="model" >
    <resource-input
        :title="$t('Filter por carrier')"
        v-model="model"
        name="category_id"
        :placeholder="$t('Select an option')"
        label="name"
        :fetch-method="fetchMethod"
        layout="vertical"
        :autoSelectFirst="true"
        :border="false"
        :filters="{search:query}"
    />
  </div>
</template>

<script>
export default {
  name: "ResourceInputFilter",
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    fetchMethod:{
      type: Function,
      required: true
    },
  },
  data(){
    return {
      model:{},
      query: "",
    }
  },
  created() {
      this.query = this.value;
  },
  watch:{
    model: {
      deep: true,
      handler(newVal, oldVal){
        if (newVal){
          if (newVal.id){
            this.$emit('input', newVal.id);
          }
        }
      }
    }
  }

}
</script>

<style scoped>

</style>