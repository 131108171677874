import axios from "../../api/client";


// initial state
export const state = () => ({
    fetchingCosts: false,
    expenses: {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
});

// getters
export const getters = {};

// actions
export const actions = {
    getExpenses({commit}, query){
        commit('setFetchingCosts', true);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${query.load_id}/expenses`,  {params:query})
                .then(response=>{
                    commit('setExpenses', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetchingCosts', false);
                });
        });
    },
    deleteExpense({commit}, query){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/loads/${query.load_id}/expenses/${query.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateExpense({commit, state}, query){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/loads/${query.load_id}/expenses/${query.id}`, query.form)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeExpense({commit, state}, query){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${query.load_id}/expenses`, query.form)
                .then(response=>{
                    console.log("response")
                    console.log(response.data)
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    acceptedExpense({commit}, query) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${query.load_id}/expenses/${query.expense_id}/accepted`,query.form)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    rejectedExpense({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.load_id}/expenses/${payload.expense_id}/rejected`,payload.form)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getExpensesByShipment({commit}, query){
        commit('setFetchingCosts', true);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${query.load_id}/expenses`,  {params:query})
                .then(response=>{
                    commit('setExpenses', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetchingCosts', false);
                });
        });
    },
};

// mutations
export const mutations = {
    setFetchingCosts(state, data){
        state.fetchingCosts = data;
    },
    setExpenses(state, data){
        state.expenses = data;
    },
};

