import { render, staticRenderFns } from "./AddressCollectionForm.vue?vue&type=template&id=8dea3500&scoped=true&"
import script from "./AddressCollectionForm.vue?vue&type=script&lang=js&"
export * from "./AddressCollectionForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dea3500",
  null
  
)

export default component.exports