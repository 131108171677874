<template>
  <router-link :to="{name:'loads.detail', params:{load_id: load.id}}" target="_blank"  class="card cursor-pointer" >
      <div class="card-header d-flex align-items-center justify-content-between p-1">
          <div>
              <div class="d-flex ">
                  <div>
                      <img v-if="!!load.shipper.logo"  :src="`${load.shipper.logo}`" alt 
                      width="32" height="32" style="object-fit: cover;" />
                      <img v-else src="@/assets/images/txp/building.png" width="32px" />
                  </div>
                  <div class="ml-2">
                      <div class="one-line mr-1 text-body" style="font-size:90%;line-height:1.2;">{{load.shipper.name}}</div>
                      <div >
                          <star-rating :rating="load.shipper.rating" :read-only="true" :star-size="12" :show-rating="false"></star-rating>
                      </div>
                  </div>
              </div>
          </div>
          <div class="text-right">
              <span class="badge badge-outline-success">$ {{load.price | money}} {{load.currency}}</span> <br/>
              <small class="text-muted">{{$moment.utc(load.created_at).tz(profile.preferences.timezone).fromNow()}}</small>
          </div>
      </div>
      <div style="min-height:64px;" class="d-flex  justify-content-between p-1 border-bottom">
          <div class="text-center" style="width: calc(50% - 8px)">
              <div>
                  <small class="text-muted"><i class="far fa-calendar-alt mr-2" />{{load.pickup.date}}</small>
              </div>
              <h6 class="two-lines text-uppercase" style="font-size:80%" v-b-tooltip.hover :title="$t('Pickup')+': '+getFormattedAddress(load.pickup.address)">
              {{getFormattedAddress(load.pickup.address)}}
              </h6>
          </div>
          <div style="width:20px;">
              <i class="far fa-arrow-circle-right text-primary" />
          </div>
          <div class="text-center" style="width: calc(50% - 8px)">
              <div>
                  <small class="text-muted"><i class="far fa-calendar-alt mr-2" />{{load.delivery.date}}</small>
              </div>
              <h6 class="two-lines text-uppercase" style="font-size:80%" v-b-tooltip.hover :title="$t('Delivery')+': '+getFormattedAddress(load.delivery.address)">
                  {{getFormattedAddress(load.delivery.address)}}
              </h6>
          </div>
      </div>
      
      <div class="d-flex justify-content-between">
          <div class="text-center p-1" style="width:33.33%">
              <small class="text-muted">{{$t('Configuration')}}</small>
              <div>
                  <small class="text-body">{{load.configuration ? load.configuration.name:'- -'}}</small>
              </div>
          </div>
          <div class="text-center border-left p-1" style="width:33.33%">
              <small class="text-muted">{{$t('Truck type')}}</small>
              <div>
                  <small class="text-body">{{load.truck_type ? load.truck_type.name:'- -'}}</small>
              </div>      
          </div>
          <div class="text-center border-left p-1" style="width:33.33%">
              <small class="text-muted">{{$t('Weight')}}</small>
              <div>
                  <small class="text-body">{{load.weight ? load.weight:'- -'}} {{load.weight_unit}}</small>
              </div>
          </div>
      </div>
      
  </router-link>
</template>

<script>
import {mapState} from 'vuex';
import StarRating from "vue-star-rating";
export default {
  name: "LoadCard",
  components:{StarRating},
  created() {},
  data() {
    return {};
  },
  props: {
    load: {
      type: Object,
      required:true
    }
  },
  computed: {
    ...mapState('profile',['profile'])
  },
  methods: {
    getFormattedAddress(address){
      return `${address.city}${address.state ? ', '+address.state : ''}`;
    }
  },
};
</script>

<style lang="scss" scoped></style>