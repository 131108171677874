<template>
    <div class="card">
        <div class="card-header">
            <h6>{{title}}</h6>
        </div>
        <div class="form-fields">
            <slot name="section-start"></slot>
            <div class="field-wrapper" :class="[show?'border-bottom-none':'']">
                <div class="row align-items-center ">
                    <div class="col-md-8">
                        <div class="field-label"><b>{{$t('Address')}}:</b></div>
                        <div class="field-help">{{ form | address-format }}</div>
                    </div>
                    <div class="col-md-4 d-flex justify-content-end">
                        <a href="javascript:void(0);"  @click="show=!show" >
                            <i class="far  btn-icon" :class="[show ? 'fa-chevron-circle-up':'fa-chevron-circle-down']" />
                        </a>
                    </div>
                </div>
            </div>
            <address-components ref="address_components"  v-if="show" :id="id" v-model="form" :disabled="disabled" :show-map="showMap" :required="required" :prefix_name="prefix_name" />
        </div>
        <slot name="section-end"></slot>
    </div>
</template>

<script>
    import AddressComponents from "./AddressComponents";

    export default {
        name: "AddressForm",
        components:{
            AddressComponents
        },
        props:{
            title:{
                type: String,
                default(){
                    return 'Address';
                }
            },
            value:{
                type: Object,
                required: true
            },
            id:{
                type: String,
                default(){
                    return '';
                }
            },
            collapsed: {
                type : Boolean,
                default(){
                    return false;
                }
            },
            disabled:{
                type : Boolean,
                default(){
                    return false;
                }
            },
            prefix_name: {
                type: String,
                default(){
                    return ''
                }
            },
            showMap:{
                type : Boolean,
                default(){
                    return true;
                }
            },
            required: {
            type: Boolean,
            default(){
                return true;
            }
            }
        },
        data(){
            return {
                form: {
                    line_1: null,
                    line_2: null,
                    line_3: null,
                    city: null,
                    state: null,
                    country: 'MX',
                    description: null,
                    latitude: null,
                    longitude: null
                },
                show: false
            }
        },
        watch:{
            form: {
                deep: true,
                handler(value){
                    this.$emit('input', value);
                }
            },
            value(newValue){
                if (newValue===null){
                  this.clear();
                }else{
                  this.form = newValue;
                }
            },
            collapsed(value){
                this.show = !this.collapsed;
            },
        },
        created() {
            if(this.value){
                this.form = this.value;
            }
            this.show = !this.collapsed;
        },
      methods: {
        clear(){
          this.form = {
            line_1: null,
            line_2: null,
            line_3: null,
            city: null,
            state: null,
            country: 'MX',
            description: null,
            latitude: null,
            longitude: null
          };
          this.$refs.address_components.clear();
        }
      }

    }
</script>

<style scoped>

</style>
