<template>
    <div class="pl-4 pr-4 mt-4">
      <div class="row">
        <div class="col-md-12 header_background pt-4 pb-4">
          <div class="row mt-2 mb-2">
            <div class="col-md-5 pl-5 offset-md-3">
              <h5 class="text-white text-uppercase">{{ resource.name + ' ' + resource.last_name }}</h5>
              <span class="text-white">{{ $t("Account created at") }} {{resource.account.created_at}}</span>
            </div>
            <div class="col-md-4 header_driver_right">
              <div class="header_driver_right_item">
                <h6 class="">{{$t('ID')}}</h6>
                <span class="">{{ resource.id }}</span>
              </div>
              <div class="header_driver_right_item">
                <h6 class="">{{$t('ID TMS')}}</h6>
                <span class="">{{ resource.tms_id}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 p-0 ">
          <div class="card card_user">
            <div class="img_logo_user">
              <img v-if="resource.avatar" :src="resource.avatar" alt="">
              <img v-else src="@/assets/images/txp/common/default-user.png" alt="">
            </div>
            <div class="travel_count mb-4">
              <h3 class="text-primary text-center m-0 ">{{ resource.loads_count }}</h3>
              <p class="text-center m-0">{{$t("Made Trips")}}</p>
            </div>
            <div class="mb-4">
              <h6 class="text-center font-weight-bold font-size-14 ">{{resource.address ? `${resource.address.city}, ${resource.address.country}`: '' }}</h6>
            </div>
            <div class="mb-4 text-center">
              <div class="one-line mr-1 text-body">{{$t("Score")}}</div>
              <div >
                <star-rating :rating="0" :read-only="true" :star-size="12" :show-rating="false"></star-rating>
              </div>
            </div>
            <div class="mb-4 text-center">
              <router-link v-can="'users.update'" class="mr-2" :to="{name: 'drivers.edit', params:{user_id: resource.id}}">
                <button class="btn btn-primary">{{$t("Update information")}}</button>
              </router-link>
              <button @click="unlink" v-if="$route.params.profile === 'admin' && this.resource.load_active" class="btn btn-primary">{{ $t('Unlink driver') }}</button>
            </div>
          </div>
          <div class="">
            <loading v-if="fetching" />
            <div class="card" v-else-if="activeLoad">
              <div class="card-header header_background d-flex justify-content-between align-items-center ">
                <h5 class="mb-0 text-white">{{$t("Trip in Progress")}}</h5>
                <img src="@/assets/images/txp/common/rutaSVG.svg" alt="">
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-5">{{activeLoad.pickup.address.city}}, {{activeLoad.pickup.address.country}}</div>
                  <div class="col-md-2"><i class="fa fa-arrow-right"></i> </div>
                  <div class="col-md-5">{{activeLoad.delivery.address.city}}, {{activeLoad.delivery.address.country}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 mt-4 pl-5">
          <div class="row mt-4">
            <div class="col-md-12">
              <h4><i class="fa fa-user-circle "></i>  {{$t("General information")}}</h4>
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('Name')}}</div>
                    <div class="field-help">{{ resource.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('Country')}}</div>
                    <div class="field-help">{{ resource.id }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('Phone')}}</div>
                    <div class="field-help">{{ resource.phone }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('Assigned transports')}}</div>
                    <div class="field-help">{{ resource.id }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('Email')}}</div>
                    <div class="field-help">{{ resource.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <h4><i class="far fa-steering-wheel  "></i> {{$t("Driver information")}}</h4>
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('ID')}}</div>
                    <div class="field-help">{{ resource.id }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="resource.tms_id">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('ID TMS')}}</div>
                    <div class="field-help">{{ resource.tms_id}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('Insurance company')}}</div>
                    <div class="field-help">{{resource.insurance_company?resource.insurance_company:"--" }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('Insurance number')}}</div>
                    <div class="field-help">{{ resource.insurance_number?resource.insurance_number:"--" }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('Email')}}</div>
                    <div class="field-help">{{ resource.email }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mt-1">
                <div class="card-body">
                  <div class="field-wrapper border-bottom-none p-0">
                    <div class="field-label">{{$t('License')}}</div>
                    <div class="field-help">{{ resource.driver_license?resource.driver_license:"--" }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <h4 class="text-center mt-4 ">{{$t("Made Trips")}}</h4>
          <p class="text-center mb-5">{{$t("Last 3 month")}}</p>
          <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
              <empty-results />
            </div>
          </div>
          <table class="table mb-0" v-else>
            <thead>
            <tr class="">
              <th class="text-center">ID</th>
              <th class="text-center">{{$t('External ID')}}</th>
              <th class="text-center">{{$t('From')}}</th>
              <th class="text-center">{{$t('To')}}</th>
              <th class="text-center">{{$t('Pickup date')}}</th>
              <th class="text-center">{{$t('Truck type')}}</th>
              <th class="text-center">{{$t('Status')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="fetching">
              <td colspan="100%">
                <loading />
              </td>
            </tr>
            <template v-for="item in list.results">
              <tr :key="'loads-'+item.id"  class="driver-row">
                <td class="text-center">{{item.id}}</td>
                <td class="text-center">{{item.tms_id}}</td>
                <td class="text-center">{{item.pickup.address.city}}, {{item.pickup.address.country}} </td>
                <td class="text-center">{{item.delivery.address.city}}, {{item.delivery.address.country}} </td>
                <td class="text-center">{{item.created_at | timestamp('YYYY-MM-DD')}}</td>
                <td class="text-center">{{item.truck_type.name}}</td>
                <td class="text-center"><badge-status :status="item.status" /></td>
                <td class="text-center">
                  <router-link :to="{name:'loads.detail', params:{load_id: item.id}}">
                    <b>{{$t("Go to detail")}}</b>
                  </router-link>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 text-center mt-4">
          <router-link :to="{name:'loads'}" tag="button" class="btn btn-primary" >
            {{$t("View all Loads")}}
          </router-link>
        </div>
      </div>
    </div>

</template>

<script>

    import {mapActions, mapState} from 'vuex';
    import StarRating from "vue-star-rating";
    import Swal from "sweetalert2";

    export default {
        name: "DriverGeneral",
        components:{
          StarRating
        },
        data(){
            return {
              list: {},
              activeLoad: null,
              fetching:false
            }
        },
        created() {
            this.getData();
        },
        computed: {
              ...mapState('requirements', ['resource']),
        },
        methods:{
          ...mapActions('loads',["getLoads","getLoad"]),
          ...mapActions('requirements', ["getResource"]),
          ...mapActions('accounts', ["unlinkDriver"]),
          getData(){
            this.fetching = true;
            const getLoads = this.getLoads({driver:this.$route.params.user_id,months:3}).then((data)  =>{
              this.list = data
            })
            if (this.resource.load_active){
              const getLoadActive = this.getLoad({id:this.resource.load_active}).then((data)=>{
                this.activeLoad = data;
              })
              Promise.all([getLoads,getLoadActive])
              .finally(() => {
                this.fetching = false;
              });
            } else {
              Promise.all([getLoads])
              .finally(() => {
                this.fetching = false;
              });
            }
          },
          unlink() {
            Swal.fire({
              title: this.$t("Are you sure?"),
              text: this.$t("You won't be able to revert this!"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: this.$t("Yes, unlink it!"),
              cancelButtonText: this.$t("Cancel")
            }).then(result => {
              if(result.value) {
                this.unlinkDriver({driver_id: this.$route.params.user_id})
                .then(data => {
                  this.$bvToast.toast(this.$t('Driver unlinked success'), {
                          title: this.$t('Success'),
                          variant: 'success',
                          solid: true
                      });
                  this.getResource({resource: 'drivers', resource_id: this.$route.params.user_id})
                  this.activeLoad = null
                })
                .error(error => {
                  this.processServiceError(error);
                })
              }
            });
          }
        }
    }
</script>

<style>
    .header_background{
      background: linear-gradient(90.3deg, #00B3E6 1.2%, #E3ED55 100%);
    }
    .header_driver_right{
      display: flex;
      justify-content: space-around;
    }
    .header_driver_right_item{
      width: 50%;
      text-align: center;
      border-left:1px solid rgba(0, 0, 0, 0.1);
    }
    .card_user{
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .img_logo_user{
      text-align: center;
      position: relative;
      height: 120px;
    }
    .img_logo_user img{
      width: 160px;
      height: 160px;
      border-radius: 50%;
      margin: -80px auto;
    }
    .travel_count{
      border: 1px solid #cccccc75;
      width: 60%;
      margin: auto;
      padding: 5px;
      border-radius: 6px;
    }
    tbody > tr:nth-child(2n-1){
      background: white;
    }
</style>
