<template>
    <GmapInfoWindow
        :opened="show"
        :options="{pixelOffset: {width: 190,height: -45}}"
        :position="load.position ? {lat: load.position.latitude, lng: load.position.longitude} : {}">
        <div class="px-3" style="width:350px;">
            <div class="d-flex justify-content-end" >
                <a @click="onClose" href="javascript:void(0);" class="text-muted">
                    <b>{{$t('Close')}}</b>
                </a>
            </div>
            <div class="my-4">
                <div class="d-flex justify-content-between">
                    <h5>ID: {{load.id}}</h5>
                        <p v-if="load.tms_id">TMS ID: <b class="text-body">{{load.tms_id}}</b></p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <div class="text-primary mb-1">{{$t('Origin')}}</div>
                    <div class="text-muted">
                        {{load.pickup.address.city}}, {{load.pickup.address.state}}
                    </div>
                    <div class="text-muted">
                        {{load.pickup.address.external_id}}
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-primary mb-1">{{$t('Pickup date')}}</div>
                    <div class="text-muted">
                        {{load.delivery.schedule_from}}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <div class="text-primary mb-1">{{$t('Delivery')}}</div>
                    <div class="text-muted">
                        {{load.delivery.address.city}}, {{load.delivery.address.state}}
                    </div>
                    <div class="text-muted">
                        {{load.delivery.address.external_id}}
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-primary mb-1">{{$t('Delivery date')}}</div>
                    <div class="text-muted">
                        {{load.delivery.schedule_from}}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <div class="text-primary mb-1">{{$t('Carrier')}}</div>
                    <div class="text-muted">
                        {{load.carrier ? load.carrier.name : '- -'}}
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-primary mb-1">{{$t('Driver')}}</div>
                    <div class="text-muted">
                        {{load.driver ? `${load.driver.name} ${load.driver.last_name}`: '- -'}}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <div class="text-primary mb-1">{{$t('Truck')}}</div>
                    <div class="text-muted">
                        {{load.truck ? load.truck.plate: '- -'}}
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-primary mb-1">{{$t('Last update')}}</div>
                    <div class="text-muted" v-if="load.position">
                        {{load.position.created_at | timestamp}}
                    </div>
                </div>           
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <div class="text-primary mb-1">{{$t('Last location')}}</div>
                    <div class="text-muted">
                        <a v-if="load.position" target="_blank" :href="`https://www.google.com/maps/search/?api=1&query=${load.position.latitude},${load.position.longitude}`">{{`${load.position.latitude},${load.position.longitude}`}}</a>
                        <span v-else>- -</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-primary mb-1">{{$t('Temperature')}}</div>
                    <div class="text-muted" v-if="load.position">
                        {{load.position.temperature ? load.position.temperature: '- -' }}
                    </div>
                </div>           
            </div>
            <div class="row mb-3">
                <div class="col-md-12">
                    <button type="button" @click="showMore" class="btn btn-primary btn-block">
                        {{$t('Show more')}}
                    </button>
                </div>
            </div>
        </div>
    </GmapInfoWindow>

</template>
<script lang="ts">

export default {
    name: 'LoadDetailWindow',
    props:{
        load:{
            type:Object,
            required:true,
        }
    },
    data(){
        return {
            show: true
        }
    },
    methods:{
        showMore(){
            this.show = false;
            this.$emit('show');
        },
        onClose(){
            this.show = false;
            this.$emit('close');
        }
    }
}
</script>
