<template>
    <router-link :to="{ name: 'loads.search.detail', params: { load_id: load.id } }" _target="_blank">
        <div class="load-card m-1 cursor-pointer mb-3">
            <div style="height: 51px;border-bottom: 1px solid #dddddd;"
            class="d-flex justify-content-between load-card-header">
            <div class="d-flex justify-content-between bg-white col-7 p-0" style="border-top-left-radius: 20px;">
                <div class=" d-flex align-items-center justify-content-center pl-2">
                    <div class="rounded-circle d-flex align-items-center justify-content-center icon">
                        <img src="../../../assets/images/txp/company.svg" />
                    </div>
                </div>
                <div class="col-10 p-0 d-flex align-items-center pl-2 title">
                    {{ load.shipments[0].customer_name }}
                </div>
            </div>
            <div class="bg-primary pl-3 col-5 p-0" style="border-top-right-radius: 20px;">
                <div class="rate">{{ $t('Rate') }}:</div>
                <div class="d-flex align-items-center">
                    <span class="text-white pr-1 font-weight-bold" style="font-size: 16px;color:white!important;">${{
                            load.price | money
                    }}</span>
                    <span class="font-weight-bold" style="font-size: 16px;color:#E3ED55!important">{{load.currency}}</span>
                </div>
            </div>
        </div>
        <div class="pt-1 px-3">
            <div class="d-flex justify-content-between">
                <div class="txt-gray">{{ $t('Route') }}:</div>
                <div class="txt-gray">{{ $t('Published') }} {{ $moment.utc(load.created_at).tz(profile.preferences.timezone).fromNow() }}</div>
            </div>
            <div class="d-flex justify-start pt-2">
                <div class="txt-blue">
                    {{ getFormattedAddress(load.origin) }} -
                    {{ getFormattedAddress(load.destination) }}
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between my-2 mx-3">
            <div class="d-flex align-items-center p-2 bagdes">
                <i class="fas fa-boxes mx-2 text-black" />
                <span class="txt-gray">{{$t('Pickup')}}:</span>
                <span class="ml-2 txt-black">{{load.origin.date | date}}</span>
            </div>
            <div class="d-flex align-items-center p-1 bagdes">
                <i class="fas fa-shipping-fast mx-2 text-black" />
                <span class="txt-gray">{{$t('Delivery')}}:</span>
                <span class="ml-2 txt-black">{{load.destination.date | date}}</span>
            </div>
        </div>

        <div class="mt-3 py-1 text-center equipment">
            <span class="mr-2 txt-gray">{{$t('Equipment')}}:</span>
            <span class="txt-black">
                {{load.equipment_type?.name}}
            </span>
        </div>

        </div>
    </router-link>
</template>
  
<script>
import { mapState } from 'vuex';
export default {
    name: "LoadCard",
    created() { },
    data() {
        return {};
    },
    props: {
        load: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState('profile', ['profile'])
    },
    methods: {
        getFormattedAddress(address) {
            let text = `${address.city}${address.state ? ', '+address.state : ''}`;
            if (text.length > 18)
                return `${address.state}`;
            return text
            
        }
    },
};
</script>
  
<style lang="scss" scoped>
.rate {
    color: #FFFFFF;
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
}

.load-card {
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(204,204,204,0.5);
  max-width: 380.5px;
  height: 192px;
  font-family: Helvetica;
}

.load-card-header{
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.txt-gray{
    color: #888888;
    font-size: 12px
}

.txt-blue{
    color: #00B3E6;
    font-size: 18px;
    font-weight: 600;
}

.txt-black{
    font-size:12px;letter-spacing: 0;
    color: black;
}

.equipment {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #F2F2F2;
    border-top: 1px solid #dddddd;
}

.bagdes {
    border-radius: 15px;
    background-color: #F2F2F2;
}

.title {
    font-size: 12px;
    color: #434619;
    line-height: 14px;
    letter-spacing: 0;
    font-weight: 600;
}

.icon {
    width:30px;
    height:30px;
    background:#00B336
}

</style>