<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <th class="text-center" style="width: 48px;">
                        <b-form-checkbox
                                v-model="selectAll"
                                name="checkbox-1"
                                button-variant="secondary"
                        >
                        </b-form-checkbox>
                    </th>
                    <th class="text-center">ID</th>
                    <th class="text-center">TMS ID</th>
                    <th class="text-center">{{$t('Status')}}</th>
                    <th class="text-center">{{$t('Plate')}}</th>
                    <th class="text-center">{{$t('Type')}}</th>
                    <th class="text-center">{{$t('Size')}}</th>
                    <th class="text-center">{{$t('Truck')}}</th>
                    <th class="text-center" v-if="$route.params.profile === 'admin'">{{$t('Carrier')}}</th>
                    <th class="text-center" style="min-width: 120px;max-width:140px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fetching">
                    <td colspan="100%">
                        <loading />
                    </td>
                </tr>

                <template v-else v-for="item in list.results">
                    <tr v-if="page === list.current_page" :key="'load-'+item.id"  class="truck-row">
                        <td>
                            <b-form-checkbox
                                    v-model="selected"
                                    name="checkbox-1"
                                    :value="item.id"
                            >
                            </b-form-checkbox>
                        </td>
                        <td class="text-center">
                            <router-link :to="{name: 'trailers.detail', params:{trailer_id: item.id}}">
                                <b>{{item.id}}</b>
                            </router-link>
                        </td>
                        <td class="text-center"> {{item.tms_id}} </td>
                        <td class="text-center">
                            <span class="badge " :class="['badge-outline-'+ (item.enabled ? 'success':'danger')]">
                                <i class="far " :class="[(item.enabled ? 'fa-check-circle':'fa-times-circle')]" />
                                {{item.enabled ? $t('enabled'):$t('disabled')}}
                            </span>
                        </td>
                        <td class="text-center">{{item.plate}}</td>
                        <td class="text-center">{{item.type ? item.type.name : '- -'}}</td>
                        <td class="text-center">{{item.length ? item.length.name : '- -'}}</td>
                        <td class="text-center">
                            <router-link v-if="item.truck" :to="{name:'trucks.detail', params:{truck_id: item.truck.id}}">
                                <b>{{item.truck.id}}</b>
                            </router-link>
                            <span v-else >- -</span>
                        </td>
                        <td class="text-center" v-if="$route.params.profile === 'admin'">
                            <router-link v-if="item.carrier" :to="{name:'carriers.detail',params:{carrier_id: item.carrier.id}}" >
                                <div class="two-lines">
                                     {{item.carrier.name}}
                                </div>
                            </router-link>
                            <span v-else>- -</span>
                        </td>
                        <td class="text-center">
                            <template v-if="!isDocumentModule">
                                <!-- <a v-can="'trailers.update'" v-b-tooltip.hover :title="item.enabled ? $t('disable'):$t('enable')" style="cursor: pointer;" @click="confirmActivateDesactivate(item)" :class="item.enabled ? 'text-danger mr-2' : 'mr-2'" >
                                    <i :class="[item.enabled ? 'far fa-thumbs-down' : 'far fa-thumbs-up']" style="font-size:20px;" />
                                </a> -->
                                <router-link v-can="'trailers.update'" class="mr-2" :to="{name: edit ? edit :'trailers.edit', params:{trailer_id: item.id}}">
                                    <i v-b-tooltip.hover :title="$t('Edit')" class="far fa-edit" style="font-size:20px;"></i>
                                </router-link>
                                
                                <a v-can="'trailers.update'" v-b-tooltip.hover :title="item.enabled ? $t('disable'):$t('enable')" style="cursor: pointer;" @click="confirmActivateDesactivate(item)" :class="item.enabled ? 'text-danger mr-2' : 'mr-2'" >
                                    <i :class="[item.enabled ? 'far fa-thumbs-down' : 'far fa-thumbs-up']" style="font-size:20px;" />
                                </a>

                            </template>
                            <template v-else>
                                <router-link v-can="'documents.read'" :to="{name:'documents.resource.detail', params:{resource: 'trailers', resource_id: item.id}}" class="mr-2">
                                    <i v-b-tooltip.hover :title="$t('Documents')" class="far fa-book-alt btn-icon"  />
                                </router-link>
                            </template>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div  v-if="list.total > 0"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills  
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    import Loading from "../common/Loading";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import Swal from "sweetalert2";

    export default {
        name: "TrailerTable",
        mixins:[selectAllMixin],
        components: {
            'loading':Loading
        },
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            },
            edit: {
                type: String
            },
            isDocumentModule: null
        },
        data(){
            return {
                page: 1,
                init: false,
                truck: null,
                fetchingTruck: false,
            }
        },
        computed: {
            ...mapState('trailers', ['last_filters', 'trailers','fetching']),
            ...mapState('profile',['profile']),
            list(){
                return this.trailers
            }
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal) || this.init === false){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('trailers',['getTrailers','getTrailer','deleteTrailer', 'updateTrailer']),
            fetchData(){
                this.getTrailers(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.init = true;
                    })
            },
            show(load){
                this.fetchingLoad = true;
                this.getTrailer({id: load.id})
                    .then(data=>{
                    })
                    .catch(error =>{
                    })
                    .finally(error =>{
                        this.fetchingLoad = false;
                    })
            },
            confirmDelete(item){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteTrailer({id: item.id})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Trailer").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                    });
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error", 
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            },
            confirmActivateDesactivate(item){
                let action = item.enabled ? 'desactivate' : 'activate'
                let status = item.enabled ? 0 : 1
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t(`Yes, ${action} it!`),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.updateTrailer({id: item.id, status: status})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t(`Your item has been ${action}d.`, {resource: this.$t("Trailer").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                    });
                            })
                            .catch(data=>{
                                Swal.fire(
                                    "Error", 
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .truck-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }
</style>
