<template>
    <div class="font-family-helvetica">
        <div class="font-size-18 mb-4 font-weight-bold font-family-inter">{{$t('Team')}}</div>
        <div class="equipment_container">
            <div class="equipment_container_item">
                <div class="equipment_container_item_label">
                    <div class="font-size-12 font-weight-lighter text-gray-888">{{$t('Team')}}</div>
                    <div class="font-size-12 text-primary font-weight-bold font-family-inter text-break">{{equipment}}</div>
                </div>
                <div class="equipment_container_item_icon">
                    <div class="rounded-lg bg-gradient-txp d-flex justify-content-center">
                        <img :src="getSrc('truck', equipment_type)" width="60" height="50" />
                    </div>
                </div>
            </div>
            <div class="equipment_container_item">
                <div class="equipment_container_item_label">
                    <div class="font-size-12 font-weight-lighter text-gray-888 text-break">{{$t('Trailer type')}}</div>
                    <div class="font-size-12 text-primary font-weight-bold font-family-inter">{{trailer}}</div>
                </div>
                <div class="equipment_container_item_icon">
                    <div class="rounded-lg bg-gradient-txp d-flex justify-content-center">
                        <img :src="getSrc('trailer', equipment_type)" width="40" height="50" />
                    </div>
                </div>
            </div>
            <div class="equipment_container_item" v-if="shipment_detail.container_type">
                <div class="equipment_container_item_label">
                    <div class="font-size-12 font-weight-lighter text-gray-888">{{$t('Packaging')}}</div>
                    <div class="font-size-12 text-primary font-weight-bold font-family-inter">{{shipment_detail.container_type}}</div>
                </div>
                <div class="equipment_container_item_icon">
                    <div class="rounded-lg bg-gradient-txp d-flex justify-content-center">
                        <img :src="getSrc('container', shipment_detail)" width="40" height="50" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row font-size-12 bg-light-gray border-lg rounded p-3" v-if="shipment_detail.container_type">
            <div class="col-4">
                <div class="text-gray-888">{{$t('Weight of each')}} {{shipment_detail.container_type.toLowerCase()}} (Kg.)</div>
                <div class="font-weight-bolder font-family-inter">{{shipment_detail.weight}} Kg.</div>
            </div>
            <div class="col-4">
                <div class="text-gray-888">{{$t('Total of')}} {{shipment_detail.container_type.toLowerCase()}}s</div>
                <div class="font-weight-bolder font-family-inter">{{shipment_detail.quantity}}</div>
            </div>
            <div class="col-4">
                <div class="text-gray-888">{{$t('Dimensions')}}</div>
                <div class="font-weight-bolder font-family-inter">
                    {{shipment_detail.width}}m x {{shipment_detail.height}}m x {{shipment_detail.length}}m
                </div>
            </div>
        </div>
        <div class="row mt-3" v-if="edit">
            <div class="col-12 text-center">
                <div class="font-size-12 text-primary font-weight-bold">
                    <span role="button" @click="() => setStep(1)">
                        <i class="ri-pencil-fill" /> {{$t('Edit')}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: "SummaryEquipment",
    props: {
        edit: { type: Boolean }
    },
    computed: {
        ...mapGetters('marketplace', ['equipment_type', 'shipment_detail']),
        ...mapGetters('catalogs', ['truck_types_marketplace', 'trailer_types_marketplace']),
        equipment() {
            return this.truck_types_marketplace.find(e => (
                e.configuration_code === this.equipment_type.configuration_code &&
                e.truck_type_code === this.equipment_type.truck_type_code &&
                e.trailer_size_code === this.equipment_type.trailer_size_code
            )).name;
        },
        trailer() {
            return this.trailer_types_marketplace.find(e => e.external_id === this.equipment_type.trailer_type_code).name;
        },
    },
    methods: {
        ...mapMutations('marketplace', ['setStep']),
        getSrc(type, entity) {
            try {
                switch (type) {
                    case 'truck':
                        let code = entity.truck_type_code === 'TRA' ?  entity.trailer_size_code : entity.truck_type_code;
                        return require('@/assets/images/marketplace/trucks/' + code + '.svg');
                    break;
                    case 'trailer':
                        return require('@/assets/images/marketplace/trailer/' + entity.trailer_type_code + '_W.svg');
                    break;
                    case 'container':
                        return require('@/assets/images/marketplace/containers/' + entity.container_type + '_W.svg');
                    break;
                }
            } catch (e) {
                return require('@/assets/images/marketplace/trailer/R_SEC_W.svg');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.rounded-lg, .rounded { border-radius: 20px!important; }

.equipment_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  &_item{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
    max-width: 260px;
    &:last-child{
      padding-right: 0;
    }
    &_label{
      text-align: right;
      width: calc(100% - 115px);
    }
    &_icon{
      width: 100px
    }
  }
}


</style>
