<template>
    <div class="row font-family-helvetica">
        <div class="col-12 text-center">
            <img src="@/assets/images/txp/triangle.png" width="60" class="m-3" />
            <h3 class="font-weight-bolder d-flex justify-content-center align-items-center">
                <i class="ri-check-line text-primary mr-2" /> Solicitud enviada
            </h3>
            <p class="font-size-19 font-weight-bold">
                Tu solicitud se ha enviado a un área especializada.
            </p>
            <p class="text-muted font-weight-light font-size-18">
                En breve nuestro equipo se pondrá en contacto contigo <br> para finalizar los últimos detalles de tu
                envío.
            </p>
            <router-link :to="{ name: 'shipments' }"
                class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder px-5 mt-4">
                Ir a envíos
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RequestSent'
}
</script>


<style scoped>
.font-family-helvetica {
    font-family: Helvetica;
}

.ri-check-line {
    font-size: 2.6rem;
}
</style>
