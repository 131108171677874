import axios from "../../api/client";
import axiossls from "../../api/clientsls";
import moment from "moment";

// initial state
export const state = () => ({
  fetching: false,
  step: 1,
  route: [],
  statusSteps: {
    equipment: false,
    origin: false,
    destination: false,
    confirmation: false,
  },
  shipment: {
    customer: {
      code: null,
      name: null,
    },
    origin: {
      schedule_from: null,
      schedule_to: null,
      type: null, // origin, destination, stop
      stop_number: null,
      date: {

      },
      address: {
        line_1: null,
        line_2: null,
        line_3: null,
        city: null,
        state: null,
        postal_code: null,
        country: null,
        description: null,
        location: [], //lat,lng
      },
    },
    destination: {
      schedule_from: null,
      schedule_to: null,
      type: null, // origin, destination, stop
      stop_number: null,
      date: {

      },
      address: {
        line_1: null,
        line_2: null,
        line_3: null,
        city: null,
        state: null,
        postal_code: null,
        country: null,
        description: null, //Referencias
        location: [], //lat,lng
      },
    },
    stops: [], //estructura igual que origin o destination
    equipment_type: {
      code: null,
      name: null,
      configuration_code: null,
      truck_type_code: null,
      trailer_type_code: null,
      trailer_size_code: null,
    },
    commodity: {
      code: null,
      name: null,
    },
    service_type: null, //LTL,FTL
    rate: null,
    shipment_value: {
      amount: null,
      currency: null,
    },
    accesories: [], // {name, quantity, amount}
    amount: null,
    currency: null,
    notes: null,
    profit_center_code: null,
    shipment_detail: {
      quantity: null,
      container_type: null, //PALLET, CAJA...
      width: null,
      length: null,
      height: null,
      weight: null,
      stacking: false,
    },
    consignment_value: 0,
  },
});

// getters
export const getters = {
  step: (state) => {
    return state.step;
  },
  shipment: (state) => {
    return state.shipment;
  },
  equipment_type: (state) => {
    return state.shipment.equipment_type;
  },
  shipment_detail: (state) => {
    return state.shipment.shipment_detail;
  },
  accesories: (state) => {
    return state.shipment.accesories;
  },
  origin: (state) => {
    return state.shipment.origin;
  },
  destination: (state) => {
    return state.shipment.destination;
  },
  stops: (state) => {
    return state.shipment.stops;
  },
  suggested_date: (state) => {
    return state.shipment.destination.suggested_date;
  },
  route: (state) => {
    return state.route;
  },
  rate: (state) => {
    return state.shipment.rate;
  },
  rateValidate: (state) => {
    return state.shipment.rate;
  },
  equipmentValidate: (state) => {
    return state.statusSteps.equipment;
  },
  originValidate: (state) => {
    return state.statusSteps.origin;
  },
  destinationValidate: (state) => {
    return state.statusSteps.destination;
  },
  confirmationValidate: (state) => {
    return state.statusSteps.confirmation;
  },
  commodity: (state) => {
    return state.shipment.commodity;
  },
  shipment_request: (state) => {
    // let copy_shipment = {...state.shipment};
    let copy_shipment = _.cloneDeep(state.shipment);
    // transform origin
    delete copy_shipment.origin.date;
    delete copy_shipment.origin.address.country;
    delete copy_shipment.origin.address.localityArray;
    delete copy_shipment.origin.address.locality;
    copy_shipment.origin.type = "PICK";
    copy_shipment.origin.schedule_from = moment(
      copy_shipment.origin.schedule_from
    ).format("YYYY-MM-DD HH:mm:ss");
    copy_shipment.origin.schedule_to = moment(
      copy_shipment.origin.schedule_to
    ).format("YYYY-MM-DD HH:mm:ss");
    // transform destination
    delete copy_shipment.destination.date;
    delete copy_shipment.destination.address.country;
    delete copy_shipment.destination.address.localityArray;
    delete copy_shipment.destination.address.locality;
    copy_shipment.destination.type = "DROP";
    copy_shipment.destination.schedule_from = moment(
      copy_shipment.destination.schedule_from
    ).format("YYYY-MM-DD HH:mm:ss");
    copy_shipment.destination.schedule_to = moment(
      copy_shipment.destination.schedule_to
    ).format("YYYY-MM-DD HH:mm:ss");

    let accessories = [];
    if (copy_shipment.accesories.length > 0) {
      accessories = copy_shipment.accesories.map((e) => {
        delete e.name;
        delete e.amount;
        delete e.unit_cost;
        return e;
      });
    }

    let shipment_details = state.shipment.shipment_detail.container_type
      ? [state.shipment.shipment_detail]
      : [];

    return {
      customer_code: null,
      equipment_type_code: state.shipment.equipment_type.code,
      commodity_code: state.shipment.commodity.code,
      service_type: "FTL",
      rate_id: state.shipment.rate?.id,
      consignment_value: {
        amount: state.shipment.consignment_value,
        currency: "MXN",
      },
      accessories: accessories,
      notes: "",
      shipment_details: shipment_details,
      stops: [copy_shipment.origin, copy_shipment.destination],
    };
  },
};

// actions
export const actions = {
  routeCalculator({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/v2/shipping-estimate", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v2/rate/`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  storeShipment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiossls
        .post(`/shipments`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  specialProject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiossls
        .post(`/marketplace/special-project`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  assingLoad({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiossls.post(`/marketplace/loads/${payload}/assign`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLoadByLoadNumber({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`api/v2/loads/load_number/${payload}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
export const mutations = {
  setFetching(state, data) {
    state.fetching = data;
  },
  setStep(state, data) {
    state.step = data;
  },
  setShipment(state, data) {
    state.shipment = data;
  },
  setCommodity(state, data) {
    state.shipment.commodity = data;
  },
  setEquipmentType(state, data) {
    state.shipment.equipment_type = data;
  },
  setShipmentDetail(state, data) {
    state.shipment.shipment_detail = data;
  },
  setAccesories(state, data) {
    state.shipment.accesories = data;
  },
  addAccessory(state, data) {
    _.remove(state.shipment.accesories, (item) => item.code === "O_ESTANCIA");
    state.shipment.accesories.push(data);
  },
  removeAccessory(state, key) {
    _.remove(state.shipment.accesories, (item) => item.code === key);
  },
  setCustomer(state, data) {
    state.shipment.customer = data;
  },
  setOrigin(state, data) {
    state.shipment.origin = data;
  },
  setDestination(state, data) {
    state.shipment.destination = data;
  },
  setStops(state, data) {
    state.shipment.stops = data;
  },
  setRate(state, data) {
    state.shipment.rate = data;
  },
  setOriginAddress(state, data) {
    state.shipment.origin.address = data;
  },
  setOriginDate(state, data) {
    state.shipment.origin.date = data;
    state.shipment.origin.schedule_from = data.schedule_from;
    state.shipment.origin.schedule_to = data.schedule_to;
  },
  setOriginTypeAndStopNumber(state) {
    state.shipment.origin.type = "origin";
    state.shipment.origin.stop_number = 1;
  },
  setDestinationAddres(state, data) {
    state.shipment.destination.address = data;
  },
  setDestinationDate(state, data) {
    state.shipment.destination.date = data;
    state.shipment.destination.schedule_from = data.schedule_from;
    state.shipment.destination.schedule_to = data.schedule_to;
  },
  setDestinationTypeAndStopNumber(state) {
    state.shipment.destination.type = "destination";
    state.shipment.destination.stop_number = 2;
  },
  setSuggestedDate(state, data) {
    state.shipment.destination.suggested_date = data;
  },
  setRoute(state, data) {
    state.route = data;
  },
  validateEquipment(state) {
    state.statusSteps.equipment = true;
  },
  validateOrigin(state) {
    state.statusSteps.origin = true;
  },
  validateDestination(state) {
    state.statusSteps.destination = true;
  },
  validateConfirmation(state) {
    state.statusSteps.confirmation = true;
  },
  setConsignmentValue(state, data) {
    state.shipment.consignment_value = data;
  },
  resetShipment(state, data) {
    let copy_shipment = {
      customer: { code: null, name: null },
      origin: {
        schedule_from: null,
        schedule_to: null,
        type: null, // origin, destination, stop
        stop_number: null,
        date: {},
        address: {
          line_1: null,
          line_2: null,
          line_3: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          description: null,
          location: [], //lat,lng
        },
      },
      destination: {
        schedule_from: null,
        schedule_to: null,
        type: null, // origin, destination, stop
        stop_number: null,
        date: {},
        address: {
          line_1: null,
          line_2: null,
          line_3: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          description: null, //Referencias
          location: [], //lat,lng
        },
      },
      stops: [], //estructura igual que origin o destination
      equipment_type: {
        code: null,
        name: null,
        configuration_code: null,
        truck_type_code: null,
        trailer_type_code: null,
        trailer_size_code: null,
      },
      commodity: { code: null, name: null },
      service_type: null, //LTL,FTL
      rate: null,
      shipment_value: { amount: null, currency: null },
      accesories: [], // {name, quantity, amount}
      amount: null,
      currency: null,
      notes: null,
      profit_center_code: null,
      shipment_detail: {
        quantity: null,
        container_type: null, //PALLET, CAJA...
        width: null,
        length: null,
        height: null,
        weight: null,
        stacking: false,
      },
      consignment_value: 0,
    };

    state.step = 1;
    state.shipment = copy_shipment;
    state.statusSteps = {
      equipment: false,
      origin: false,
      destination: false,
      confirmation: false,
    };
  },
};
