<template>
  <div class="dates ml-n4" ref="month">
    <div class="item dow" v-for="dow in days"  :key="'dow'+dow"  >{{ $t(dow) }}</div>
    <div v-for="blank in firstDayOfMonth"  :key="'day_blank'+blank"  class="day day_blank"></div>
    <day-item v-for="numberDay in daysInMonth"
              :key="'day'+numberDay"
              :ref="'d_' + numberDay"
              :day="numberDay"
              :month="month"
              :year="year"
              :events="events"
              @setDate="setDateActive"
    />
  </div>
</template>

<script>
import moment from "moment/moment";
import DayItem from "./DayItem.vue";



export default {
  name: "Month",
  components: {DayItem},
  props:{
    month: {
      type: Number,
      default: 12,
    },
    year: {
      type: Number,
      default: 2022,
    },
    events: {
      type: Array,
      default: [],
    },
  },
  data(){
    return{
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      localDate: null,
      activeDay: null,
    }
  },
  created() {
    this.setDate();
  },
  computed: {
    daysInMonth: function () {
      return moment(this.localDate).daysInMonth();
    },
    firstDayOfMonth: function () {
      return moment(this.localDate).startOf("month").weekday();
    },
  },
  methods:{
    setDate(){
      this.localDate = moment(new Date(this.year,this.month,1));
    },
    setDateActive(date){
      let localDate = _.cloneDeep(date);
      this.$emit('setDateActive',{
        date: localDate,
        position: this.getModalPosition(date.date())
      })
    },
    getModalPosition(d){
      let positionXModal = 0
      let positionYModal = 0
      let parent = this.$refs.month;
      let day = this.$refs["d_" + d][0].$el;
      let parent_width = parent.offsetWidth;
      let day_width = day.offsetWidth;
      let day_left = day.offsetLeft;
      let day_index = Math.round(day_left / day_width);
      if (parent_width - (day_index + 1) * day_width < 280) {
        positionXModal = (day_index - 1) * day_width - 80;
      } else {
        positionXModal = (day_index + 1) * day_width + 20;
      }
      let total_vertical = Math.round(parent.offsetHeight / day.offsetHeight);
      let index_vertical = Math.round(day.offsetTop / day.offsetHeight);
      positionYModal = (index_vertical * 100) / total_vertical;
      return {
        x : positionXModal,
        y : positionYModal,
      }
    }
  },
  watch:{
    year(e){
      this.setDate()
      this.activeDay = null
    },
    month(e){
      this.setDate()
      this.activeDay = null
    },
  }
}
</script>

<style scoped>
.dow{
  letter-spacing: 0;
  line-height: 22px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #888888 !important;
  font-family: Helvetica;
  font-size: 14px;
}
.dates {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
</style>