<template>
    <multiselect v-model="selected" :disabled="disabled" :multiple="multiple" :options="list" track-by="id" label="name"
                :placeholder="$t('Select')" select-label="" selected-label="">
    </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'
import {mapGetters} from 'vuex';

export default {
    name:'TruckTypeInput',
    components: {
        Multiselect
    },
    props: {
        value:{
            required: true
        },
        disabled:{
            type : Boolean,
            default(){
                return false;
            }
        },
        configuration: {
            type: Number,
            default() {
                return 1;
            }
        },
      multiple:{
        type: Boolean,
        default(){
          return false
        }
      }
    },
    data(){
        return {
            selected : undefined,
        }
    },
    computed: {
        ...mapGetters('catalogs',['truckTypes']),
        list(){
            if (!this.configuration) return [];

            if (this.configuration === 1) {
                return this.truckTypes;
            } else {
                return this.truckTypes.filter(item => { return item.configuration === 'f'})
            }
        }
    },
    watch:{
        selected: {
            deep:true,
            handler(newValue, oldValue){
                this.$emit('input', newValue);
            }
        },
        value: {
            deep:true,
            handler(newValue){
                this.selected = newValue;
            }
        }
    },
    created(){
        this.selected = this.value;
    }
}
</script>
