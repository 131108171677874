<template>
  <div class="my-3">
    <div class="row">
      <div class="col-md-8 offset-md-2">

        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <div>
              <h6>{{$t('Payment')}}</h6>
            </div>
            <div>
              <button class="btn btn-primary btn-sm" @click="paymentConfirm"><i class="far fa-wallet mr-2" />{{$t('Transfer to wallet')}}</button>
            </div>
          </div>
          <div class="card-body">
            <loading v-if="loading" />
            <empty-results v-if="!loading && !payment" :subtitle="$t('Payment has not been scheduled')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Swal from "sweetalert2";

export default {
  components: {  },
  name: "LoadPayment",
  created() {
    this.fetchData();
  },
  data() {
    return {
      payment: null,
      loading: true,
    };
  },
  props: {},
  computed: {

  },
  methods: {
    ...mapActions('loads', ['getPayment','pay']),
    fetchData(){
      this.loading = true;
      this.getPayment({id: this.$route.params.load_id})
          .then(data =>{
            this.payment = _.cloneDeep(data);
          })
          .catch(error =>{

          })
          .finally(()=>{
            this.loading = false;
          });
    },
    paymentConfirm(){
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("Confirm to transfer"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, confirm"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.pay({id:this.$route.params.load_id})
              .then(data=>{
                this.fetchData();
                this.$root.$bvToast.toast(this.$t("Transfer completed"), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>