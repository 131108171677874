<template>
    <div :class="[twoColumns && 'row']">
        <div
            :class="[twoColumns && 'col-6 mx-2']"
            class="row rectangle border line-height-18 font-size-13 py-3 mt-3 border-0"
            v-for="(equipment, index) in equipments"
            :key="index"
        >
            <div class="col-4 text-right text-gray-888 p-0">
                <div>{{ $t("Equipment") }} {{ index + 1 }}:</div>
                <div>{{ $t("Information") }}:</div>
            </div>
            <div class="col-8">
                <div class="row">
                    <div class="col-12">
                        <div class="font-weight-bolder">
                            {{ equipment.name }}
                        </div>
                    </div>
                    <div class="col-12">
                        <div>{{ equipment.configuration_code }}</div>
                        <div>{{ trailerName(equipment.trailer_type_code) }}</div>
                        <div>{{ equipment.trailer_size_code }}</div>
                    </div>
                    <div class="col-6 d-none">
                        <div>{{ $t("Code") }}: {{ equipment.code }}</div>
                        <div>
                            {{ $t("Configuration") }}:
                            {{ equipment.configuration_code }}
                        </div>
                        <div>
                            {{ $t("Trailer size") }}:
                            {{ equipment.trailer_size_code }}
                        </div>
                    </div>
                    <div class="col-6 d-none">
                        <div>
                            {{ $t("Truck code") }}:
                            {{ equipment.truck_type_code }}
                        </div>
                        <div>
                            {{ $t("Trailer code") }}:
                            {{ equipment.trailer_type_code }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "EquipmentsList",
    props: {
        equipments: Array,
        remove: Function,
        twoColumns: Boolean,
    },
    computed: {
        ...mapGetters('catalogs', ['trailer_types_marketplace']),
    },
    methods: {
        trailerName(code){
            return this.trailer_types_marketplace.find(e => e.external_id === code).name;
        }
    }
};
</script>

<style lang="css" scoped>
.rectangle {
    max-width: 470px;
    border-radius: 20px;
    background-color: #f2f2f2;
}
</style>
