<template>
    <b-modal
        ref="modal-edit-issue"
        id="modal-edit-issue"
        size="md"
        :title="update?$t('Edit event'):$t('Add event')"
        :visible="visible"
        v-on:hidden="closeModal"
        body-class="p-0"
        cancel-variant="light"
        :cancel-title="$t('Cancel')"
        :ok-title="$t('Save')"
        hide-footer
    >
        <loading v-if="fetchingIssue"/>
        <validation-observer v-else ref="observer" v-slot="{ validate }">
            <b-form @submit.stop.prevent="onSubmit(validate)">
                <div class="row">
                    <div class="col-md-12">
                            <resource-input
                                :title="$t('Category')"
                                v-model="form.category"
                                rules=""
                                name="category_id"
                                :placeholder="$t('Select an option')"
                                label="name"
                                :fetch-method="getIssueCategories"
                                layout="vertical"
                                :autoSelectFirst="true"
                                v-on:change="onCategoryChange"
                                :hiddenItems="true"
                            />
                            <form-input
                                :title="$t('Subject')"
                                v-model="form.type"
                                rules="required"
                                type="select"
                                :options="issueTypes"
                                name="type"
                                layout="vertical"
                                :disabled="!form.category"
                                :placeholder="$t('Select option')"
                                v-on:change="onTypeChange"
                            />
                            <form-input
                                :title="$t('Description')"
                                v-model="form.description"
                                rules="required"
                                type="textarea"
                                name="description"
                                layout="vertical"
                                :disabled="!form.type"
                            />
                            <file-input
                                    class="p-4"
                                    :path="uploadPath"
                                    :options="{dictDefaultMessage: 'Titulo test', acceptedFiles: 'image/*'}"
                                    v-on:success="fileUpload" v-on:error="errorUpload"
                            />
                    </div>
                    <div class="col-md-12">
                      <div  class="gallery_list">
                        <div class="gallery_list_item" :key="image.id" v-for="(image,index) in images"  >
                          <img height="35px" width="35px" :key="index" :src="image.path"/>
                          <a  @click="confirmImageDelete(image)" href="javascript:void(0);" class="text-danger pointer-event delete-img">
                            <i class="far fa-times-circle btn-icon btn-icon-mini"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="d-flex justify-content-end m-4">
                    <a @click="$bvModal.hide('modal-edit-issue')" :disabled="submit" class="btn btn btn-light mr-3 ">{{$t('Cancel')}}</a>
                    <button type="submit" :disabled="submit" class="btn btn-primary">{{$t('Save')}}</button>
                </div>
            
            </b-form>
        </validation-observer>

    </b-modal>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex';
import FileInput from "@/components/form/FileInput";

export default {
    name: 'EditIssueModal',
    components:{
        FileInput,
    },
    props: {
        visible:{
            type: Boolean,
            default(){
                return true;
            }
        },
        update:{
            type: Boolean,
            default(){
                return false;
            }
        },
        issue_id:{
          type: Number,
          default(){
            return null
          }
        },
        load_id:{
          type: [Number,String]
        },
    },
    data() {
        return {
            fetchingIssue : false,
            images: [],
            submit: false,
            isUpdated: false,
            form :{
              type: null,
              description: null,
              address: {},
              files: [],
              }
        }
    },
    computed: {
        ...mapState('issues',['types']),
        issueTypes(){
            return this.types.map(item =>({text: item.name, value: item.id}))
        },
        uploadPath(){
            if (this.update){
              return '/api/v2/loads/'+this.load_id+'/issues/'+this.issue_id+'/files';
            }else{
              return '/api/v2/upload';
            }
        }
    },
    watch:{
      visible(value){
        if (value){
          if (this.update){
            this.fetchIssue(this.issue_id,this.load_id);
          }else {
            this.fetchingIssue = false;
          }

        }
      }
    },
    created(){

    },
    methods:{
        ...mapActions('issues',['getIssueTypes','storeIssue','updateIssue','getIssue','deleteFile','getIssueCategories']),
        ...mapMutations('issues',['setIssueTypes']),
        closeModal(){
          let data = {id:this.form.id, updated: this.isUpdated}
          this.$emit('updateData', data);
          this.form ={
            type: null,
            description: null,
            address: {},
            files: [],
          }
          this.images = null;
          this.isUpdated = false;
        },
        async onSubmit(validate){
          if(!(await validate())){
            return;
          }
          if(this.submit === true)
            return;

          this.submit = true;
          let form = _.cloneDeep(this.form);
          form.load_id = this.load_id
          if (this.update){
            let formUpdate = {type:form.type,description:form.description,load_id:form.load_id,id:form.id}
            this.updateIssue(formUpdate)
                .then(data=>{
                  this.isUpdated = true;
                  this.$bvModal.hide('modal-edit-issue');
                })
                .catch(error =>{

                })
                .finally(()=>{
                  this.submit = false;
                });
          }else{
            this.storeIssue(form)
                .then(data=>{
                  this.isUpdated = true;
                  this.$bvModal.hide('modal-edit-issue');
                })
                .catch(error =>{

                })
                .finally(()=>{
                  this.submit = false;
                });
          }

        },
        fileUpload(data){
          if (!this.update){
            this.form.files = [...this.form.files,data.id]
          }
          this.images = [...this.images,data]
          this.isUpdated = true;
        },
        errorUpload(data){
          alert("IMAGEN NO SE PUEDO SUBIR");
        },
        confirmImageDelete(data){
          this.deleteFile({id:data.id})
              .then(response=>{
                this.isUpdated = true;
                this.images = this.images.filter(item=> item.id !== data.id);
              })
              .catch(error =>{

              })
              .finally(()=>{
                this.submit = false;
              });
        },
        fetchIssue(id,load_id){
          this.fetchingIssue = true;
          this.getIssue({id:id,load_id:load_id}).then((data)=>{
            this.form = data;
            this.form.type = data.type.id
            this.images = data.files
          }).finally(()=>{
            this.fetchingIssue = false;
          })
        },
        onCategoryChange(value){
            if(value){
              this.getIssueTypes({load_id: this.load_id, category_id: value.id});
            }
        },
        onTypeChange(value){
          let issueType = this.types.find(item => item.id == value)
          console.log(issueType.form_id)
        }
    }
}
</script>
<style scoped>

.delete-img{
  position: absolute;
  left: calc(50% - 10px);
  bottom: -30px;
}

.gallery_list_item img{
  width: 45px;
  height: 45px;
}

.gallery_list{
  display: flex;
  width: 100%;
  padding: 0 20px;
}
.gallery_list_item{
  margin-right: 10px;
  border: 2px solid white;
  transition: all .5s;
  border-radius: 5px;
  position: relative;
}
.gallery_list_item:hover{
  cursor: pointer;
  opacity: 1;
  border: 2px solid #2bb3e7;
}
.gallery_list_item.active{
  border: 2px solid #2bb3e7;
  opacity: 1;
}
</style>