<template>
  <div class="d-flex image_product align-items-center"  v-b-tooltip.hover :title="image.filename">
    <div class="image_wrap pointer-event" @click="showImage(image)">
      <div class="image_container">
        <img :src="image.path" class="thumbnail" alt="">
      </div>
    </div>
    <div class="ml-3">
      <h5 class="card-title font-size-13 " >{{image.filename | truncate(10, '...'+image.extension) }}</h5>
      <a  @click="confirmDeleteFile(image)"  class="text-primary pointer-event d-flex ">
        <i class="far fa-times-circle btn-icon btn-icon-mini mr-2"></i> Eliminar
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "imageProduct",
  props:{
    "image": {
      type: Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    confirmDeleteFile(image){
      this.$emit('deleteImage', image);
    },
    showImage(image){
      this.$emit('showImage', image);
    }
  }
}
</script>

<style scoped>
  .image_product{
    width: 250px;
    margin-top: 15px;
  }
  .image_wrap{
    padding: 5px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    border: 1px solid #dddddd;
  }
  .image_container{
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image_container img{
    max-width: 100%;
    max-height: 100%;
  }
  .pointer-event:hover{
    cursor: pointer;
  }
</style>