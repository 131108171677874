<template>
  <div>
    <div class="table-responsive">
      <table class="table mb-0">
        <thead>
          <tr class="">
            <th class="text-center">ID</th>
            <!--
            <th class="text-center">{{ $t("Authorization code") }}</th>
            -->
            <th class="text-center">{{ $t("Date") }}</th>
            <th class="text-center">{{ $t("Amount") }}</th>
            <!--
            <th class="text-center">{{ $t("Currency") }}</th>
            -->
            <th class="text-center">{{ $t("Description") }}</th>
            <th class="text-center">{{ $t("Status") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="fetching">
            <td colspan="100%">
              <loading />
            </td>
          </tr>

          <template v-for="item in list.results">
            <tr
              v-if="init || page === list.current_page"
              :key="'transaction-' + item.id"
              class="transaction-row"
            >
              <td class="text-center">
                <router-link
                  :to="{
                    name: 'transaction.detail',
                    params: { transaction_id: item.id },
                  }"
                >
                  <b>{{ item.id }}</b>
                </router-link>
              </td>
              <td class="text-center">{{ item.created_at | timestamp('YYYY-MM-DD') }}</td>
              <!--
              <td class="text-center">{{ item.authorization }}</td>
              -->
              <td :class="[item.type === 'in' ? 'text-success': 'text-danger', 'text-center']">
                ${{
                  item.type === "in"
                    ? item.amount
                    : item.amount * -1 | money
                }}
              </td>
              <!--
              <td class="text-center">{{ item.currency }}</td>
              -->
              <td class="text-center">{{ item.description }}</td>
              <td class="text-center">
                <span class="badge badge-outline-primary">
                  {{ $t(item.status) }}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="list.total > 0 && init" class="row align-items-center">
      <div class="col-md-6 col-sm-6">
        <b-pagination
          v-model="page"
          :total-rows="list.total"
          :per-page="list.per_page"
          aria-controls="my-table"
          first-number
          last-number
          pills
        ></b-pagination>
      </div>
      <div class="col-md-6 col-sm-6 text-sm-right">
        <p>
          {{
            $t("pagination", {
              from: list.from,
              to: list.to,
              total: list.total,
            })
          }}
        </p>
      </div>
    </div>
    <div v-if="list.total === 0 && !fetching" class="row">
      <div class="col">
        <empty-results />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from "../common/Loading";
import selectAllMixin from "@/mixins/selectAllMixin";

export default {
  name: "TransactionTable",
  mixins: [selectAllMixin],
  components: {
    loading: Loading,
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      page: 1,
      init: false,
      fetching: false,
      transaction: null,
      fetchingTransaction: false,
      wallet_id: null,
    };
  },
  computed: {
    ...mapState("wallet", ["last_filters", "transactions", "wallet"]),
    list() {
      return this.transactions;
    },
  },
  created() {
    this.page = this.filters.page ? Number(this.filters.page) : 1;
    this.fetchData();
  },
  watch: {
    filters: {
      deep: true,
      handler(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          this.fetchData();
        }
      },
    },
    page(newVal) {
      this.$emit("page-change", newVal);
    },
  },
  methods: {
    ...mapActions("wallet", ["getTransactions"]),
    fetchData() {
      let wallet_id = this.wallet.id;
      this.fetching = true;
      let filters = _.cloneDeep(this.filters);
      this.getTransactions({filters, wallet_id })
        .then((response) => {})
        .catch((error) => {})
        .finally(() => {
          this.fetching = false;
          this.init = true;
        });
    },
  },
};
</script>

<style scoped>
.transaction-row > td {
  vertical-align: middle !important;
  height: 50px !important;
  max-height: 50px !important;
  text-align: center !important;
}
</style>
