<template>
  <div>
    <page-header  :title="$t($route.meta.title)"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-12">
            <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
              <template v-slot:right>
                <div class="btn-group" role="group" aria-label="toolbar">
                  <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getAdvances(filters)" type="button" class="btn btn-light">
                    <i class="far fa-sync"></i>
                  </button>
                  <b-dropdown id="dropdown-dropleft" :disabled="$route.query.status !== 'in_review'" right variant="light" class="">
                    <template #button-content>
                      <i class="far fa-check-circle"></i> {{$t('Accept all')}}
                    </template>
                    <b-dropdown-text>
                      {{$t('Transfer method')}}
                    </b-dropdown-text>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item :key="'method_'+method.value" v-for="method in transfer_methods" @click="acceptAll(method.value)">{{method.text}}</b-dropdown-item>
                  </b-dropdown>
                  <button :disabled="$route.query.status !== 'in_review'" @click="rejectAll" type="button" class="btn btn-light">
                    <i class="far fa-times-circle"></i> {{$t('Reject all')}}
                  </button>
                </div>
              </template>
            </filter-bar>
          </div>
        </div>

        <advance-table v-model="selected" :filters="filters" v-on:page-change="pageChange" />

      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import AdvanceTable from "@/components/tables/AdvanceTable";
import Swal from "sweetalert2";


export default {
  name: "Advances",
  components: {
    FilterBar,
    AdvanceTable
  },
  mixins: [filterMixin],
  data(){
    return {
      selected: [],
      options: [],
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapState('factoring', ['factoring']),
    ...mapState('configuration',['transfer_methods'])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('advances',['getAdvances','acceptAdvances','rejectAdvances']),
    init(){
      this.options = [
        {
          icon: 'far fa-key',
          label: 'ID',
          type: 'text',
          filterProperty: 'id',
        },
        {
            icon: 'far fa-sliders-h-square',
            label: this.$t('Status'),
            type: 'single',
            filterProperty: 'status',
            values: [
                {
                    text: this.$t('status.factoring.in_review'),
                    value: 'in_review'
                },
                {
                    text: this.$t('status.factoring.completed'),
                    value: 'completed'
                },
                {
                    text: this.$t('status.factoring.rejected'),
                    value: 'rejected'
                }
            ],
            valueType: 'string'
        },
        {
          icon: 'far fa-key',
          label: this.$t('Load'),
          type: 'text',
          filterProperty: 'load_id',
        },
      ];

      let filters = {};
      // Use meta filters
      if(this.$route.meta.paramFilters){
          for(let key of this.$route.meta.paramFilters){
              filters[key] = this.$route.params[key];
          }
      }
      if(this.$route.meta.filters){
          filters = {...filters, ...this.$route.meta.filters};
      }
      
      this.defaultFilters = filters;

      let toolbarFilters = {};
      for(let item of this.options){
          if(this.$route.query.hasOwnProperty(item.filterProperty)){
              toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
          }
      }
      if(!toolbarFilters.status){
        toolbarFilters.status = 'in_review';
      }
      this.toolbarFilters = toolbarFilters;

    },
    acceptAll(method){
      Swal.fire({
        title: this.$t("Confirm"),
        text: this.$t("I want to accept advances"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, continue"),
        cancelButtonText: this.$t("Cancel"),
        reverseButtons: true
      }).then(result => {
        if(result.value) {
          this.acceptAdvances({...this.filters, method})
              .then(data =>{
                this.getAdvances(this.filters);
                this.$root.$bvToast.toast(this.$t(`The action was completed successfully`), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                  Swal.fire(
                      "Error",
                      this.$t("Something went wrong!"),
                      "error"
                  );
              });
        }
      });
    },
    rejectAll(){
      Swal.fire({
        title: this.$t("Confirm"),
        text: this.$t("I want to reject advances"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, continue"),
        cancelButtonText: this.$t("Cancel"),
        reverseButtons: true
      }).then(result => {
        if(result.value) {
          this.rejectAdvances({...this.filters})
              .then(data =>{
                this.getAdvances(this.filters);
                this.$root.$bvToast.toast(this.$t(`The action was completed successfully`), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                  Swal.fire(
                      "Error",
                      this.$t("Something went wrong!"),
                      "error"
                  );
              });
        }
      });
    }
  }
}
</script>

<style scoped>

</style>