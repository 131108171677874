<template>
    <div class="card card-txp">
        <div class="card-body">
            <div class="row">
                <div class="col-6">
                    <h5 class="font-weight-bold m-0">
                        {{ $t('Calendar') }}
                    </h5>
                    <div class="opacity fz-14"> {{ $t('travels') }}</div>
                </div>
                <div class="col-6 c-right">
                    <router-link :to="{name:'calendar.index'}">
                        <i style="color: black;" class="fa fa-calendar fa-2x"></i>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "Calendar"
};

</script>

<style scoped>
    .opacity { opacity: 0.60; }
    .fz-14{ font-size: 13px; }
    .card-txp {
        border-radius: 15px;
        box-shadow: 1px 5px 3px #dcdcdc;
    }
    .c-right{
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .badge-white {
        color: #00B3E6;
        background-color: #ffffff;
        border: solid 1px #dcdcdc;
    }
</style>
