<template>
    <div class="mt-5">
      <loading v-if="loading"  />
      <div v-else class="">
        <loading-overlay :loading="submit" >
          <template>
            <div class="row mb-3">
                <div class="col-md-12  ">
                  <div class="d-flex justify-content-end">
                    <div class="btn-group" role="group" aria-label="toolbar">
                        <button  v-b-tooltip.hover :title="$t('Refresh')" @click="fetchData" type="button" class="btn btn-light">
                            <i class="far fa-sync"></i>
                        </button>
                        <import-data :action="importMerchandises" v-on:success="fetchData" :payload="{shipment_id: $route.params.load_id}" />
                    </div>
                  </div>
                </div>
            </div>
            <validation-observer ref="observer" v-slot="observer">
              <b-form @submit.stop.prevent="onSubmit(observer)">
                <div class="overlay_content">
                  <div class="card">
                    <div class="card-header">
                      <h6>{{$t('Tax products or services')}}
                        <span class="badge bg-primary text-white">{{ form.products.length}}</span>
                      </h6>
                    </div>

                      <div>
                          <form-input
                              type="radio"
                              :title="$t('Merchandise type')"
                              help=" "
                              v-model="form.type"
                              rules="required"
                              :options="merchandiseTypeOptions"
                              :name="'type'"
                          />
                      </div>

                    <template v-for="(product,index) in form.products" >
                      <div :key="product.key" class="field-wrapper" :class="[(!form.products[index].collapse) ? 'border-bottom-none':'']">
                        <div class="row align-items-center ">
                          <div class="col-md-8">
                            <div class="field-label"><b>{{$t('Product')}} {{index+1}}</b>: {{product.product.product_key}} - {{product.product.product ? product.product.product.text: ''}}</div>
                          </div>
                          <div class="col-md-4 d-flex justify-content-end">
                            <a href="javascript:void(0);" class="text-danger mr-2" @click="deleteMerchandise(product.product, index)" v-b-tooltip.hover :title="$t('Delete')" >
                              <i class="far fa-trash-alt btn-icon"  />
                            </a>
                            <a href="javascript:void(0);" @click="form.products[index].collapse=!product.collapse">
                              <i class="far  btn-icon" :class="[form.products[index].collapse ? 'fa-chevron-circle-down':'fa-chevron-circle-up']" />
                            </a>
                          </div>
                        </div>
                        <div v-if="!form.products[index].collapse">

                          <resource-input
                               :title="$t('Product')"
                              v-model="form.products[index].product.product"
                              rules="required"
                              name="product"
                              :placeholder="$t('Select an option')"
                              label="text"
                              :fetch-method="getCatalogList"
                              :filters="{type:'c_products',per_page: 30}"
                          />

                          <form-input
                              :title="$t('Description')"
                              :help="$t('The description of product or service')"
                              v-model="form.products[index].product.description"
                              rules="required"
                              type="text"
                              :name="'description'"
                          />

                          <form-input
                              :title="$t('Quantity')"
                              :help="$t('The quantity of product or service')"
                              v-model="form.products[index].product.quantity"
                              rules="required"
                              type="number"
                              :name="'quantity'"
                          />

                          <form-input
                              :title="$t('Unit')"
                              :help="$t('The unit of product or service')"
                              selectText='text'
                              selectValue='value'
                              v-model="form.products[index].product.unit_key"
                              rules="required"
                              type="select"
                              :options="c_unit"
                              name="unit"
                              :placeholder="$t('Select option')"
                              :searchable="true"
                          />

                          <form-input
                              :title="$t('Origin')"
                              :help="$t('Origin of products')"
                              selectText='text'
                              selectValue='value'
                              v-model="form.products[index].product.origin"
                              rules="required"
                              type="select"
                              :options="origins"
                              name="origin"
                              :placeholder="$t('Select option')"
                              :searchable="true"
                          />

                          <form-input
                              :title="$t('Destiny')"
                              :help="$t('Destiny of products')"
                              selectText='text'
                              selectValue='value'
                              v-model="form.products[index].product.destiny"
                              rules="required"
                              type="select"
                              :options="destinies"
                              :name="'destiny'"
                              :placeholder="$t('Select option')"
                              :searchable="true"
                          />

                          <div class="field-wrapper">
                            <div class="row align-items-center ">
                              <div class="col-md-6">
                                <div class="field-label">{{$t('Is it hazardous material?')}}</div>
                                <div class="field-help"></div>
                              </div>
                              <div class="col-md-6">
                                <switches :key="'sw'+index" v-model="form.products[index].product.is_dangerous" theme="bootstrap" color="info" class="float-right" />
                              </div>
                            </div>
                          </div>

                          <form-input
                              v-if="form.products[index].product.is_dangerous"
                              :title="$t('Hazardous material')"
                              :help="$t('The hazardous material')"
                              selectText='text'
                              selectValue='value'
                              v-model="form.products[index].product.dangerous_key"
                              rules="required"
                              type="select"
                              :options="c_dangerous"
                              name="dangerous"
                              :placeholder="$t('Select option')"
                              :searchable="true"
                          />

                          <form-input
                              v-if="false"
                              :title="$t('Packaging')"
                              :help="$t('The packaging of product or service')"
                              selectText='text'
                              selectValue='value'
                              v-model="form.products[index].product.packaging_key"
                              rules=""
                              type="select"
                              :options="c_packaging"
                              name="packaging"
                              :placeholder="$t('Select option')"
                              :searchable="true"
                          />

                          <form-input
                              :title="$t('Weight')"
                              :help="$t('The weight of product or service')"
                              v-model="form.products[index].product.weight"
                              rules="required"
                              type="number"
                              :name="'weight'"
                          />

                          <form-input
                              :title="$t('SKU')"
                              :help="$t('The SKU of product or service')"
                              v-model="form.products[index].product.sku"
                              type="text"
                              :name="'sku'"
                          />

                          <template v-if="productForm">
                            <template v-for="(field, idx) in productForm.fields">
                              <form-input
                                  :key="'field-'+idx+field.id"
                                  :title="field.title"
                                  :help="field.help"
                                  v-model="form.products[index].product.custom_fields[field.id]"
                                  :type="field.type"
                                  :name="'products.'+idx+'.'+field.id"
                                  :options="field.options"
                              />
                            </template>
                          </template>

                          <template v-if="is_international">
                            <form-input
                                :title="$t('Tariff')"
                                :help="$t('The tariff of product or service')"
                                selectText='text'
                                selectValue='value'
                                v-model="form.products[index].product.tariff_key"
                                rules="required"
                                type="select"
                                :options="c_arancelaria"
                                name="tariff"
                                :placeholder="$t('Select option')"
                                :searchable="true"
                            />

                            <form-input
                                :title="$t('UUID foreign trade')"
                                :help="$t('The uuid foreign trade of product')"
                                v-model="form.products[index].product.uuid_foreign_trade"
                                rules="required"
                                type="text"
                                :name="'uuid'"
                            />

                            <div class="row align-items-center pt-2 ">
                                <div class="col-md-12">
                                    <b>{{$t('Motions')}}</b>
                                    <button type="button" v-b-tooltip.hover :title="$t('Add')" class="btn btn-primary btn-sm float-right" @click="addMotion(index)"><i class="fas fa-plus-circle"></i></button>
                                </div>
                            </div>
                            <div v-for="(motion,indexm) in form.products[index].product.motions" :key="motion.key" class="field-wrapper border-bottom-none">
                                <div class="field-wrapper">
                                    <div class="row align-items-center ">
                                        <div class="col-md-6">
                                            <div class="field-label">{{$t('Motion number')}}</div>
                                            <div class="field-help"></div>
                                        </div>
                                        <div class="col-md-6">
                                            <validation-provider :name="'motion' + (indexm + 1)" rules="required" v-slot="{ errors }">
                                                <b-input-group class="mb-3">
                                                    <b-form-input :id="'motion' + (indexm + 1)" v-model="form.products[index].product.motions[indexm]" type="text" placeholder="21  43  3073  1010933" v-mask="'##  ##  ####  #######'"></b-form-input>
                                                    <b-input-group-append v-if="indexm !== 0">
                                                        <button type="button" class="btn btn-danger btn-sm float-right" @click="form.products[index].product.motions.splice(indexm,1)" v-b-tooltip.hover :title="$t('Delete')"><i class="fas fa-trash"></i></button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                            </validation-provider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>

                    <div class="field-wrapper">
                      <div class="row align-items-center ">
                        <div class="col-md-8"></div>
                        <div class="col-md-4 ">
                          <button type="button" class="btn btn-primary btn-sm float-right" @click="addProduct">{{$t('Add')}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end overlay_fotter">
                  
                  <button ref="submit" type="submit" class="btn btn-primary float-right">{{ $t('Save') }}</button>
                </div>
              </b-form>
            </validation-observer>
          </template>
        </loading-overlay>
      </div>
    </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex';
import Switches from 'vue-switches';
import cryptoRandomString from 'crypto-random-string';
import addressFormat from '../../helpers/address-format'
import LoadingOverlay from "../../components/common/LoadingOverlay";
import ImportData from "@/components/common/ImportData";

export default {
    name: 'Merchandises',
    components:{
      LoadingOverlay,
      Switches,
      ImportData
    },
    data(){
        return {
            form : {
                type: "NUEVO",
                products: [
                    {
                        key: cryptoRandomString({length:5}),
                        collapse: false,
                        product: {motions: [""],custom_fields:{}}
                    }
                ]
            },
            add : false,
            loading : false,
            submit : false,
            c_dangerous: [],
            c_products: [],
            c_arancelaria: [],
            c_unit: [],
            c_packaging: [],
            c_configautotransport: [],
            merchandiseTypeOptions: [
                {text: this.$t('New'), value: 'NUEVO'},
                {text: this.$t('Return'), value: 'DEVOLUCION'}
            ]
        }
    },
    computed: {
        ...mapState('loads',['events','stops','status','load']),
        ...mapGetters('configuration',['productForm']),
        is_international(){
            return !(['MX', 'MEX'].includes(this.load.pickup.address.country) && ['MX', 'MEX'].includes(this.load.delivery.address.country))
        },
        origins(){
          let stops = _.cloneDeep(this.stops);
          stops.splice(stops.length - 1,1);
          let origins = [];
          stops.map((item,index)=>{origins.push({text: addressFormat(item.address).join(" ") , value:item.id, order:item.order})});
          return origins;
        },
        destinies(){
          let stops = _.cloneDeep(this.stops);
          stops.splice(0,1);
          let destinies = [];
          stops.map((item,index)=>{destinies.push({text: addressFormat(item.address).join(" ") , value:item.id, order:item.order})});
          return destinies;
        },
    },
    created(){
      this.init();
    },
    methods: {
        ...mapActions('catalogs', ['fetchCatalogTax','getCatalogList']),
        ...mapActions('loads', ['getLoad','getMerchandises','updateMerchandises']),
        ...mapActions('shipments',['importMerchandises','deleteMerchandises']),
        addProduct(){
            let products = this.form.products.map(item => ({...item, collapse: true}));
            products.push({
              key: cryptoRandomString({length:5}),
              collapse: false,
              product: {motions: [""],custom_fields:{}}
            });
            this.form.products = products;
        },
        addMotion(index){
            this.form.products[index].product.motions.push("");
        },
        init(){
          this.loading = true;
          this.load_id = this.$route.params.load_id;
          const fetchCatalogTax = this.fetchCatalogTax({})
              .then(data => {
                this.c_dangerous = data.c_dangerous
                this.c_unit = data.c_unit
                this.c_products = data.c_products
                this.c_arancelaria = data.c_arancelaria
                this.c_packaging = data.c_packaging
                this.c_configautotransport = data.c_configautotransport
              });
          const getMerchandises= this.getMerchandises({id:this.load_id}).then((data)=>{
            let products = [];
            let d = _.cloneDeep(data);
            d.products.map((item,index)=>{
              let answers = {};
              item.custom_fields.map((data)=>{
                answers[data.field_id] = data.value.value
              });
              item.custom_fields = answers;
              products.push({
                key: cryptoRandomString({length:5}),
                collapse: index!==(d.products.length-1),
                product: item
              });
            });
            if (products.length){
              this.form.products = products;
            }
            this.form.type = d.type ?? "NUEVO";
          }).catch((d)=>{
            console.log(d);
          })
          const getLoad =this.getLoad({id: this.$route.params.load_id});

          Promise.all([getLoad,getMerchandises,fetchCatalogTax])
              .finally(() => {
                this.loading = false;
              });
        },
        async onSubmit(observer){
          if(!(await observer.validate()) || this.loading){
            this.$bvToast.toast(this.$t('Please, check the form'), {
              title: "Error",
              variant: 'danger',
              solid: true
            });
            return;
          }
          this.submit = true;
          let form = _.cloneDeep(this.form);
          let products = [];
          form.products.map((item)=>{
            products.push({...item.product,...{form_id:this.productForm.id, product_key: item.product.product.value}})
          });
          this.updateMerchandises({id:this.load_id,products:products,motions:form.motions,type: form.type}).then((data)=>{
            this.submit = false;
            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
              title: this.$t('Success'),
              variant: 'success',
              solid: true
            });
          })

        },
        fetchData(){
          this.loading = true;
          this.load_id = this.$route.params.load_id;
          this.getMerchandises({id:this.load_id}).then((d)=>{
            let products = [];
            d.products.map((item,index)=>{
              let answers = {};
              item.custom_fields.map((data)=>{
                answers[data.field_id] = data.value.value
              });
              item.custom_fields = answers;
              products.push({
                key: cryptoRandomString({length:5}),
                collapse: index!==(d.products.length-1),
                product: item
              });
            });
            if (products.length){
              this.form.products = products;
            }
          }).catch((d)=>{
            console.log(d);
          }).finally(()=>{
            this.loading = false;
          })
        },
        deleteMerchandise(product, index){
          if (product.id) {
            this.deleteMerchandises({'id': this.$route.params.load_id, 'merchandiseId': product.id })
          } 

          this.form.products.splice(index,1)
        }
    }
}
</script>