import axiossls from "../../api/clientsls";

// initial state
export const state = () => ({
    rates: null,
});

// getters
export const getters = {
    getRates: (state) => state.rates,
};

// actions
export const actions = {
    getRates({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .post(`rate`, payload)
                .then((response) => {
                    commit('setRates', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
    verifyToken({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .post(`rate-confirmation/verify`, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
    rateConfirmation({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .post(`/rate-confirmation`, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
};

// mutations
export const mutations = {
    setRates(state, data){
        state.rates = data;
    }
};
