<template>
  <div class="">
    <div class="table-responsive">
      <table class="table mb-0">
        <thead>
        <tr class="">
          <th class="text-center">ID</th>
          <th class="text-center">{{$t('Name')}}</th>
          <th class="text-center">{{$t('Type')}}</th>
          <th class="text-center">{{$t('Created at')}}</th>
          <th class="text-center">{{$t('Actions')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="fetching">
          <td colspan="100%">
            <loading />
          </td>
        </tr>
        <template v-else>
          <template v-if="list.length === 0">
            <tr>
              <td colspan="100%" ><empty-results/></td>
            </tr>
          </template>
          <template  v-else v-for="item in list" >
            <tr class="product-row">
              <td class="text-center">{{item.id}}</td>
              <td class="text-center">{{item.name}}</td>
              <td class="text-center text-capitalize ">{{$t(item.type)}}</td>
              <td class="text-center">{{item.created_at}}</td>
              <td class="text-center">
                <a @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-2">
                  <i class="far fa-trash-alt btn-icon"></i>
                </a>
                <router-link  :to="{name:'shippers.externalRequirementsForm', params:{form_id: item.id}}" class="mr-2">
                  <i class="far fa-edit btn-icon"></i>
                </router-link>
              </td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Swal from "sweetalert2";

export default {
  name: "FormTable",

  computed: {
    ...mapState('forms',['externalRequirements','fetching']),
    list(){
      return this.externalRequirements
    }
  },
  methods: {
    ...mapActions('forms', ['fetchExternalRequirements','deleteForm']),
    confirmDelete(item){
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.deleteForm(item)
              .then(data=>{
                this.$emit('getData');
                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Form").toLowerCase()}), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
