<template>
  <div>
    <page-header :title="$t($route.meta.title) + ': ' + $route.params.load_id" />
    <div class="scroll_container">
      <a v-show="end" class="prev">&#10094;</a>
      <a v-show="start" class="next">&#10095;</a>
      <vue-custom-scrollbar class="scroll-area" :settings="settings" @ps-x-reach-start="isStart" @ps-x-reach-end="isEnd"
        @ps-scroll-x="scrollHandle">
        <div class=" scrollmenu ">
          <div v-can="item.permission" class="nav-item text-black" v-for="item in tabs" :key="item.route">
            <router-link :to="{ name: item.route }" class="nav-link" active-class="active">
              <span class="text-black">{{ $t(item.title) }}</span>
            </router-link>
          </div>
        </div>
      </vue-custom-scrollbar>
    </div>

    <loading v-if="loading" />
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
export default {
  name: "LoadContainer",
  components: {
    vueCustomScrollbar
  },
  metaInfo() {
    return {
      title: `${this.$t('Load')} ${this.$route.params.load_id}`,
    }
  },
  data() {
    return {
      tabs: [],
      loading: true,
      start: true,
      end: false,
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      }
    }
  },
  watch: {
    '$route.params.load_id': function (id) {
      this.loading = true;
      this.getLoad({ id: this.$route.params.load_id })
        .then(data => {
          this.init();
        })
        .catch(error => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
    load(newValue){
        this.init();
    },
  },
  computed: {
    ...mapState('loads', ['load', 'events']),
    ...mapState('profile', ['profile']),
    ...mapState('forms', ['customLoadForm']),
    getApply() {

    }
  },
  created() {
    this.loading = true;
    this.getLoad({ id: this.$route.params.load_id })
      .then(data => {
        this.init();
      })
      .catch(error => {
      })
      .finally(() => {
        this.loading = false;
      });
    this.getStatus({ id: this.$route.params.load_id })
      .then(data => {
      })
      .catch(error => {
      });
  },
  mounted() {
    Echo.private(`loads.${this.$route.params.load_id}`)
      .listen('CreateIssueEvent', (e) => {
        if (this.load && this.profile.id !== e.issue.user_id)
          this.$root.$bvToast.toast(this.$t("Issue created in load") + " " + this.load.id, {
            title: this.$t('Success'),
            variant: 'success',
            solid: true
          });
      })
      .listen('CreateCommentEvent', (e) => {
        if (this.profile.id !== e.commentData.user.id) {
          this.$root.$bvToast.toast(e.commentData.user.name + " comento en la incidencia " + e.issueData.type.name + " de la carga " + e.load_id, {
            title: this.$t('Success'),
            variant: 'success',
            solid: true
          });
        }
      });
  },
  methods: {
    ...mapActions('loads', ['getLoad', 'getAllChecklist', 'getStops', 'getStatus', 'getTenders']),
    ...mapActions('forms', ['getForm']),
    ...mapMutations('forms', ['setCustomLoadForm']),
    init() {
      switch (this.$route.params.profile) {
        case "carrier":
          if (this.load.carrier && this.load.carrier.id === this.profile.account.profile.id) {
            this.tabs = [
              { title: 'General', route: 'loads.detail.general' },
              { title: 'Shipments', route: 'loads.detail.shipments' },
              { title: 'Proof of delivery', route: 'loads.detail.checklist' },
              //{title: 'POD',route: 'loads.detail.pod'},
              { title: 'Documents', route: 'loads.detail.documents' },
              { title: 'Extra charges', route: 'loads.detail.extracosts', permission: 'extracosts.module' },
              //{ title: 'Costs', route: 'loads.detail.costs', permission: 'settlement.module' },
              //{ title: 'Settlement', route: 'loads.detail.settlement', permission: 'settlement.module' },
              { title: 'Events', route: 'loads.detail.issues' },
              { title: 'Bol', route: 'loads.detail.bol' },
              { title: 'External requirements', route: 'loads.detail.externalrequirements' },
            ];
            this.addReturnTab();
          } else {
            this.tabs = [
              { title: 'General', route: 'loads.detail.general' },
            ];
          }
          break;
        case "admin":
          this.tabs = [
            { title: 'General', route: 'loads.detail.general' },
            { title: 'Shipments', route: 'loads.detail.shipments' },
            { title: 'Proof of delivery', route: 'loads.detail.checklist' },
            //{title: 'POD',route: 'loads.detail.pod'},
            { title: 'Documents', route: 'loads.detail.documents' },
            { title: 'Extra charges', route: 'loads.detail.extracosts', permission: 'extracosts.module' },
            //{ title: 'Costs', route: 'loads.detail.costs', permission: 'settlement.module' },
            //{ title: 'Settlement', route: 'loads.detail.settlement', permission: 'settlement.module' },
            { title: 'Events', route: 'loads.detail.issues' },
            //{title: 'Payment',route: 'loads.detail.payment'}
            { title: 'Bol', route: 'loads.detail.bol' },
            { title: 'External requirements', route: 'loads.detail.externalrequirements' },
          ];
          this.addReturnTab();
          break;
        case "driver":
          this.tabs = [
            { title: 'General', route: 'loads.detail.general' },
            { title: 'Shipments', route: 'loads.detail.shipments' },
            { title: 'Proof of delivery', route: 'loads.detail.checklist' },
            { title: 'Documents', route: 'loads.detail.documents' },
            { title: 'Expenses Verification', route: 'loads.detail.expenses', permission: 'settlement.module' },
            { title: 'Issues', route: 'loads.detail.issues' },
            { title: 'External requirements', route: 'loads.detail.externalrequirements' },
          ];
          break;
      }
    },
    addReturnTab(){
        if(['partial_rejection', 'total_rejection'].includes(this.load.status)){
          let tabs0 = this.tabs.slice(0, 8);
          let tabs1 = this.tabs.slice(8, 10);
          this.tabs = [...tabs0, { title: 'Returns', route: 'loads.detail.returns' }, ...tabs1];
        }
    },
    isStart(evt) {
      this.start = true;
      this.end = false;

    },
    isEnd(evt) {
      this.start = false;
      this.end = true;
    },
    scrollHandle(evt) {
      this.start = true;
      this.end = true;
    }
  }
}
</script>

<style scoped lang="scss"  >
.scroll_container {
  position: relative;
  padding: 0 30px;
}

.prev,
.next {
  position: absolute;
  font-size: 25px;
  top: 10px;
  display: block;
}

.prev {
  left: -10px;
}

.next {
  right: -10px;
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: auto;
  overflow: auto;
  white-space: nowrap;
  padding-bottom: 10px;
}

.nav-link.active {
  background: #e3ed56;

  * {
    color: black;
  }
}

.nav-link {
  padding: 0.5rem;
}

.nav-item {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 5px 4px;
  text-decoration: none;

  * {
    color: #505d69;
    font-weight: 500;
  }
}

@media (min-width: 992px) {
  .scroll-area {
    padding-bottom: 0 !important;
  }

  .scroll_container {
    padding: 0;
  }

  .prev,
  .next {
    display: none;
  }

  .nav-item {
    padding: 0;
  }

  .nav-link {
    padding: 0.5rem 1.5em;
  }
}
</style>
