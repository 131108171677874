<template>
  <div class="">
    <div class="date_from" v-if="step_time_picker === 0">
      <div class="date_from_label font_semi_bold font-size-13 text-center pt-2">
        {{ $t(pickup ? "Enter or select the pickup time" : "Enter or select the delivery time") }}
      </div>
      <time-select :key="'time_'+1" v-model="time_from" />
      <div v-if="timeError" class="time_error">{{ $t('Error time is not valid') }}</div>
    </div>
    <div class="date_from" v-if="step_time_picker === 1">
      <div class="date_from_label font_semi_bold font-size-13 text-center pt-2">
        {{ $t(pickup ? "Enter or select the pickup time" : "Enter or select the delivery time") }}
      </div>
      <time-select :key="'time_'+2"  v-model="time_to" />
      <div v-if="timeError" class="time_error">{{ $t('Error time is not valid') }}</div>
    </div>
    <div class="container_intervals">
      <div class="container_interval" @click="step_time_picker = 0" :class="step_time_picker === 0 ? 'active' : ''">
        <div class="container_interval_title">{{ $t('Desde') }}</div>
        <div class="container_interval_value upper">
          {{ hourFormat(time_from.hour, time_from.minute) }}
        </div>
      </div>
      <div class="container_interval" @click="nextStep()" :class="step_time_picker === 1 ? 'active' : ''">
        <div class="container_interval_title">{{ $t('Hasta') }}</div>
        <div class="container_interval_value upper">
          {{ hourFormat(time_to.hour, time_to.minute) }}
        </div>
      </div>
    </div>
    <div class="container_buttons">
      <div class="container_button cursor-pointer" @click="closeModal">
        {{ $t("Cancel") }}
      </div>
      <div class="container_button cursor-pointer" @click="nextStep()">
        {{ $t(step_time_picker === 0 ? "Next" : "Accept") }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import TimeSelect from "./TimeSelect.vue";

export default  {
  name: "TimePicker",
  components: {TimeSelect},
  props:{
    pickup: {
      type: Boolean,
      default: true
    },
    validDate: {
      type: Function,
    },
    from: {
      type: Object,
    },
    to: {
      type: Object,
    }
  },
  data(){
    return {
      step_time_picker: 0,
      timeError: false,
      time_from: {
        hour: 18,
        minute: 50
      },
      time_to: {
        hour: 18,
        minute: 55
      },
    }
  },
  computed:{
  },
  created() {
    this.time_from = _.cloneDeep(this.from)
    this.time_to = _.cloneDeep(this.to)
  },
  watch:{
    time_from(e){

    },
    time_to(e){

    }
  },
  methods:{
    hourFormat(hour, minute) {
      return moment(`${hour}:${minute}`, 'hh:mm').format('hh:mm A')
    },
    closeModal() {
      this.$emit('closeModal');
    },
    nextStep() {
      if (this.step_time_picker === 0) {
        if (this.validDate({...this.time_from,...{step:'from'}})){
          this.step_time_picker = 1;
          this.timeError = false;
          this.$emit('setFrom', `${this.hourStringFormat(this.time_from.hour)}:${this.minuteString(this.time_from.minute)}`)
        }else{
          this.timeError = true;
        }
      } else {
        if (this.validDate({...this.time_to,...{step:'to'}})){
          this.$emit('setTo', `${this.hourStringFormat(this.time_to.hour)}:${this.minuteString(this.time_to.minute)}`)
        }else{
          this.timeError = true;
        }
      }
    },
    hourStringFormat(hour) {
      return hour < 10 ? '0' + hour : hour
    },
    minuteString(minute) {
      return minute < 10 ? '0' + minute : minute
    },
  }
}
</script>

<style scoped lang="scss">

.time_error{
  text-align: center;
  padding: 5px 7px;
  display: block;
  color: #f90000;
  font-size: 13px;
}

.container_intervals {
  border-top: 1px solid #dddddd;
  display: flex;
  justify-content: center;
  align-content: space-between;
  padding: 5px 0;
}

.container_buttons {
  border-top: 1px solid #dddddd;
  display: flex;
  justify-content: center;
  align-content: space-between;
  padding: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 12px;
}
.container_button {
  flex: 1;
  text-align: center;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 0;

  &:nth-child(2) {
    color: #00b3e6;
  }
}

.container_interval {
  flex: 1;
  text-align: center;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #888888;

  &_title {
     font-size: 11px;
   }

  &_value {
     font-size: 11px;
   }
}
.container_interval:hover {
  cursor: pointer;
}
.container_interval.active {
  font-weight: bold;
  cursor: pointer;
  color: #000000;

.container_interval_title {
  font-size: 11px;
}

.container_interval_value {
  font-size: 11px;
  color: #00b3e6;
}
}

</style>