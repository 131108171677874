<template>
  <div class="">
    <div class="table-responsive">
      <table class="table mb-0">
        <thead>
        <tr class="">
          <th class="text-center">ID</th>
          <th class="text-center">{{ $t('Name') }}</th>
          <th class="text-center">{{ $t('Address') }}</th>
          <th class="text-center">{{ $t('External ID') }}</th>
          <th class="text-center" style="width: 150px;">{{ $t('Actions') }}</th>
        </tr>
        </thead>
        <tbody>
        <template  v-if="fetching">
          <tr>
            <td colspan="10"><loading/></td>
          </tr>
        </template>
        <template v-else  v-for="item in list.results">
          <tr v-if="page === list.current_page" :key="'expense-'+item.id"  class="expense-row">
            <td class="text-center">
              <b>{{item.id}}</b>
            </td>
            <td class="text-center">
              {{ item.customer_code }}
            </td>
            <td class="text-center">
              {{ addressFormat(item) }}
            </td>
            <td class="text-center">
              {{ item.external_id }}
            </td>
            <td class="text-center">
              <a @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-4">
                <i class="far fa-trash-alt btn-icon"  />
              </a>
              <a v-b-modal.modal_create_settlementCatalog @click="editAddress(item)" class="mr-4">
                <i class="far fa-edit btn-icon"></i>
              </a>
              <a v-if="addressId==item.id" href="javascript:void(0);" @click="addressId = false">
                <i class="far fa-chevron-circle-up btn-icon"></i>
              </a>
              <a v-else href="javascript:void(0);" @click="showRow(item)">
                <i class="far fa-chevron-circle-down btn-icon"></i>
              </a>
            </td>
          </tr>
          <template v-if="addressId === item.id">
            <tr :key="'file-detail-'+item.id">
              <td colspan="100%" class="p-0">
                <div class="card shadow-none border-0 p-4">
                  <div class="row">
                    <div class="col-md-6">
                      <AddressMap style="width:100%;height:400px;"  :address="item" ></AddressMap>
                    </div>
                    <div class="col-md-6">
                      <h6>{{ $t("Address detail") }}</h6>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="field-wrapper border-bottom-none p-0 mt-3">
                            <h6 class="field-label">{{$t('Country')}}</h6>
                            <div class="field-help">{{item.country}}</div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field-wrapper border-bottom-none p-0 mt-3">
                            <h6 class="field-label">{{$t('Street')}}</h6>
                            <div class="field-help">{{item.line_1}}</div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field-wrapper border-bottom-none p-0 mt-3">
                            <h6 class="field-label">{{$t('Zip code')}}</h6>
                            <div class="field-help">{{item.zip_code}}</div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field-wrapper border-bottom-none p-0 mt-3">
                            <h6 class="field-label">{{$t('City')}}</h6>
                            <div class="field-help">{{item.city}}</div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field-wrapper border-bottom-none p-0 mt-3">
                            <h6 class="field-label">{{$t('State')}}</h6>
                            <div class="field-help">{{item.state}}</div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field-wrapper border-bottom-none p-0 mt-3">
                            <h6 class="field-label">{{$t('References')}}</h6>
                            <div class="field-help">{{item.description}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
    </div>
    <div  v-if="list.total > 0"  class="row align-items-center">
      <div class="col-md-6 col-sm-6 ">
        <b-pagination
            v-model="page"
            :total-rows="list.total"
            :per-page="list.per_page"
            aria-controls="my-table"
            first-number
            last-number
            pills
        ></b-pagination>
      </div>
      <div class="col-md-6 col-sm-6 text-sm-right">
        <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
      </div>
    </div>

    <div v-if="!fetching && list.total == 0" class="row">
      <div class="col">
        <empty-results />
      </div>
    </div>



    <EditAddress :visible="visible"  :address="address"  :update="true" v-on:updateData="updateData"></EditAddress>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import addressesFormat from '@/helpers/address-format';
import AddressMap from "../common/AddressMap";
import filterMixin from "@/mixins/filterMixin";
import codeGenerate from "../../helpers/code-generate";
import EditAddress from "../modals/EditAddress";
import Swal from "sweetalert2";

export default {
  name: "AddressesTable",
  mixins: [filterMixin],
  components: {AddressMap,EditAddress},
  data(){
    return{
      addressId: null,
      address: {},
      page: 1,
      visible: false,
    }
  },
  computed: {
    ...mapState('addresses',["fetching","addresses"]),
    list(){
      return this.addresses
    }
  },
  methods:{
    ...mapActions("addresses",["deleteAddresses"]),
    addressFormat(item){
      return addressesFormat(item).join(" ");
    },
    showRow(item){
      this.addressId = item.id
    },
    updateData(data){
      this.$emit('updateData', data);
      this.visible = false
    },
    editAddress(data){
      this.address = data;
      this.visible = true;
    },
    confirmDelete(item){
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.deleteAddresses(item)
              .then(data=>{
                this.$emit('updateData', {updated: true});
                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Address").toLowerCase()}), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    },
  },
  watch: {
    page(newVal){
      this.pageChange(newVal)
    },
  },
}
</script>

<style scoped>

</style>