<template>
    <div class="nopadding font-family-helvetica">
        <header-title :headband="true"  title="Calendario de viajes"></header-title>
        <div style="padding: 1rem 2rem;" class="row m-0">
            <div class="col-8">
                <monthly-calendar :setDate="setDate" :parentDate="date" />
            </div>
            <div class="col-4">
                <list-loads :date="date" />
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from 'vuex';
import HeaderTitleVue from '@/components/common/HeaderTitle.vue';
import MonthlyCalendar from '@/components/calendar/MonthlyCalendar';
import ListLoads from '@/components/calendar/ListLoads';
import moment from "moment";

export default {
    name: "Caldendar",
    components:{
        HeaderTitle: HeaderTitleVue,
        MonthlyCalendar,
        ListLoads
    },
    data(){
        return {
            date: moment()
        }
    },
    methods: {
        ...mapActions('loads', ['getCalendarLoads']),
        setDate: function (data) {
            this.date = data
        },
    },
    created(){
        this.getCalendarLoads({});
    },
    watch: {
        'date': function() {
            let params = { pickup_date: this.date.format('YYYY-MM-DD') }
            this.getCalendarLoads(params);
        }
    }
}
</script>

<style scoped>

.font-family-helvetica{
    font-family: Helvetica !important;
}

</style>
