<template>
  <div class="">
    <div class="" v-if="fetching">
      <loading-loads />
    </div>
    <div class="table-responsive mt-2" v-else>
      <div class="card load_item" v-for="item in list.results" :key="'load-' + item.id">
        <div class="card_header">
          <div class="info_left">
            <div class="info_left_first">
              <p class="text-muted text-capitalize ">
                {{ $moment.utc(item.created_at).tz(profile.preferences.timezone).fromNow() }}</p>
              <badge-status :status="item.status" />
            </div>
            <div class="info_left_second">
              <p class="text-muted mb-2 textwrap">
                {{ item.tms_id ? "TMS ID:" : "ID:" }} <span class="font-weight-bold pr-2">{{ item.tms_id ??
                  item.id }}</span>
                <span v-if="['carrier', 'admin'].includes($route.params.profile)" data-bs-toggle="tooltip"
                  data-bs-placement="top" :title="item.shipper?.name">{{ $t('Shipper') }}: <span
                    class="font-weight-bold">{{ item.shipper?.name }}</span></span>
              </p>
              <router-link
                :to="{ name: item.type === 'SHIPMENT' ? 'marketplace.detail.general' : 'loads.detail.general', params: { load_id: item.id } }">
                <h5 class="text-primary font_bold font-size-15 text-uppercase ">{{ item.pickup.address.city }},
                  {{ item.pickup.address.state }}, {{ item.pickup.address.country }} - {{ item.delivery.address.city }},
                  {{ item.delivery.address.state }}, {{ item.delivery.address.country }}</h5>
              </router-link>
              <h6 v-can="'loads.showprice'" v-if="$route.params.profile !== 'driver'" class="font_bold">
                ${{ item.purchase_price | money }} {{ item.currency }}</h6>
            </div>
          </div>
          <div class="info_right">
            <div class="info_right_first">
              <div class="info_item">
                <div class="info_item_icon"><i class="far fa-dolly-flatbed-alt" style="font-weight: 800;"></i></div>
                <div class="info_item_label">{{ $t('Pickup') }}</div>
                <div class="info_item_sub_label text-capitalize">{{ item.pickup.date | human_date_min }}</div>
              </div>
              <div class="info_item">
                <div class="info_item_icon"><i class="far fa-shipping-fast" style="font-weight: 800;"></i></div>
                <div class="info_item_label">{{ $t('Delivery') }}</div>
                <div class="info_item_sub_label text-capitalize">{{ item.delivery.date | human_date_min }}</div>
              </div>
              <div class="info_item">
                <div class="info_item_icon"><i class="fa fa-barcode" style="font-weight: 800"></i></div>
                <div class="info_item_label">{{ $t('Tracking Number') }}</div>
                <div class="info_item_sub_label">{{ item.shipper_external_id ? item.shipper_external_id : '- -' }}</div>
              </div>
              <div class="info_item">
                <div class="info_item_icon  "> <i :class="[`${etaIcon[item.eta_status]} t-eta-${item.eta_status}`]"
                    style="font-weight: 800;" class="fas fa-exclamation-circle"></i> </div>
                <div class="info_item_label">ETA</div>
                <div class="info_item_sub_label">{{ item.eta | timestamp }}</div>
              </div>
              <div v-if="$route.params.profile == 'admin'" class="info_item">
                <div class="info_item_icon"><i class="fa fa-truck" style="font-weight: 800"></i></div>
                <div class="info_item_label">{{ $t('Carrier') }}</div>
                <div data-bs-toggle="tooltip" data-bs-placement="top" :title="item.carrier?.name ?? ''"
                  class="info_item_sub_label textwrapmulti">{{ item.carrier?.name ?? '- -' }}</div>
              </div>
            </div>
            <div class="info_right_second">

              <button class="btn btn-sm btn-outline-dark mr-4 text-uppercase padding-btn-small" @click="showRow(item)">{{
                $t('Show Preview') }} <i class="fa fa-eye ml-1"></i></button>


              <router-link
                :to="{ name: item.type === 'SHIPMENT' ? 'marketplace.detail.general' : 'loads.detail.general', params: { load_id: item.id } }">

                <button class="btn btn-sm btn-primary_v2 text-uppercase padding-btn-small"> {{ $t('Show Detail') }} <i
                    class="fa fa-search ml-1"></i></button>
              </router-link>

            </div>
          </div>
        </div>
        <template v-if="load && load.id === item.id">
          <div class="card_footer">
            <route-map style="width:100%;height:300px;" :load="load"></route-map>
            <div class="info_detail">
              <div class="info_detail_data pt-4">
                <div class="card card-primary bg_gray detail_banner">
                  <div class="card_list">
                    <div class="card_list_item">
                      <div class="card_title font-weight-bold  ">TMS ID</div>
                      <div class=" ">{{ item.tms_id ? item.tms_id : '- -' }}</div>
                    </div>
                    <div class="card_list_item"
                      v-if="['admin', 'staff'].indexOf($route.params.profile) > -1 && item.shipments">
                      <div class="card_title font-weight-bold  ">{{ $t('Shipments') }}</div>
                      <div class="container-scroll">
                        <div class="shipments-list">
                          <router-link v-for="shipment in item.shipments" :key="item.id + 'sh-link-' + shipment.id"
                            :to="{ name: 'shipments.detail.general', params: { load_id: shipment.id } }" class="mr-2"
                            target="_blank">
                            <b class="text-black">{{ shipment.id }}</b>
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="card_list_item" v-if="['admin', 'staff'].indexOf($route.params.profile) > -1">
                      <div class="card_title font-weight-bold  ">{{ $t('Shipper') }}:</div>
                      <router-link v-for="shipment in getShippers(item.shipments)"
                        :key="item.id + 'sh-link-' + shipment.id"
                        :to="{ name: 'shippers.detail', params: { shipper_id: shipment.shipper.id } }" class="mr-2"
                        target="_blank">
                        <b class="text-black">{{ shipment.shipper.name }}</b>
                      </router-link>
                    </div>
                    <div class="card_list_item" v-if="$route.params.profile === 'admin'">
                      <div class="card_title font-weight-bold  ">{{ $t('Carrier') }}:</div>
                      <router-link v-if="item.carrier"
                        :to="{ name: 'carriers.detail', params: { carrier_id: item.carrier.id } }" class="two-lines"
                        target="_blank">
                        <b class="text-black">{{ item.carrier.name }}</b>
                      </router-link>
                      <div v-else>
                        <b class="">- -</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <load-preview :load="load" :stops="stops" />
                </div>
              </div>
              <div class="info_detail_log ">
                <load-timeline class="" :load="load" :stops="stops" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="list.total > 0 && list.total > list.per_page" class="row align-items-center">
      <div class="col-md-6 col-sm-6 ">
        <b-pagination v-model="page" :total-rows="list.total" :per-page="list.per_page" aria-controls="my-table"
          first-number last-number pills></b-pagination>
      </div>
      <div class="col-md-6 col-sm-6 text-sm-right">
        <p>{{ $t('pagination', { from: list.from, to: list.to, total: list.total }) }}</p>
      </div>
    </div>
    <div v-if="list.total === 0 && !fetching" class="row">
      <div class="col">
        <empty-results />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import selectAllMixin from "@/mixins/selectAllMixin";
import addressFormat from '@/helpers/address-format';
import RouteMap from "@/components/common/RouteMap";
import LoadPreview from "./LoadPreview";
import LoadTimeline from "../../components/common/controltower/LoadTimeline";
import LoadingLoads from "../common/LoadingLoads";
export default {
  name: "LoadsTable",
  mixins: [selectAllMixin],
  components: {
    LoadingLoads,
    'route-map': RouteMap,
    LoadPreview,
    LoadTimeline
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      }
    },
    tender: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      page: 1,
      init: false,
      selected: [],
      load: null,
      fetchingLoad: false,
      markers: [],
      etaIcon: {
        late: 'fas fa-exclamation-circle',
        delayed: 'fas fa-hourglass',
        early: 'fas fa-clock',
        on_time: 'fas fa-check-circle'
      },
      form: {},
      showIssueModal: false,
      showIssues: false,
      shipments: []
    }
  },
  computed: {
    ...mapState('loads', ['last_filters', 'loads', 'fetching', 'stops']),
    ...mapState('profile', ['profile']),
    list() {
      return this.loads
    }
  },
  created() {
    this.doSearch = _.debounce(this.fetchData, 50);
    this.page = this.filters.page ? Number(this.filters.page) : 1;
  },
  watch: {
    filters: {
      deep: true,
      handler(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal) || this.init === false) {
          this.fetchData();
        }
      }
    },
    page(newVal) {
      this.$emit('page-change', newVal);
    }
  },
  methods: {
    ...mapActions('loads', ['getLoads', 'getLoad', 'getLoadsByTenders', 'getLoadShipments']),
    fetchData() {
      this.page = _.cloneDeep(this.filters.page);
      let filters = _.cloneDeep({ ...this.filters, ...this.defaultFilters });
      delete filters.tab;
      if (this.tender) {
        this.getLoadsByTenders(filters)
          .then(response => {
          })
          .catch(error => {
          })
          .finally(() => {
            this.init = true;
          })
      } else {
        this.getLoads(filters)
          .then(response => {
          })
          .catch(error => {
          })
          .finally(() => {
            this.init = true;
          })
      }
    },
    showRow(item) {
      if (this.load && this.load.id === item.id) {
        this.load = null;
        return
      }
      this.fetchingLoad = true;
      this.load = item;
      this.getLoad({ id: item.id })
        .then(data => {
          this.load = data;
          this.getLoadShipments({ id: item.id }).then(data => this.shipments = data);
        })
        .catch(error => {
        })
        .finally(error => {
          this.fetchingLoad = false;
        })
    },
    getAddressFormat(address) {
      return addressFormat(address)
    },
    getShippers(shipments) {
      return _.uniqBy(shipments, item => item.shipper.id);
    }
  }
}
</script>

<style scoped lang="scss">
.detail_banner {
  background: #f6f6f6 !important;
  border: 0;
  border-left: 7px solid #e3ed56;
}

.load_item {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #dddddd69 !important;
  box-shadow: 0 6px 10px rgb(204 204 204 / 50%);
}

@media (max-width: 1320px) {

  .info_right {
    .info_right_first {
      width: 75%;
    }

    .info_right_second {
      width: 25%;
      display: inline-grid;
    }
  }

}

@media (max-width: 1150px) {
  .card_header {
    flex-wrap: wrap;

    .info_left,
    .info_right {
      width: 100% !important;
    }

    .info_right_second {
      display: flex;
    }
  }

  .detail_banner {
    margin-bottom: 0 !important;
    background: #f6f6f6 !important;
  }

  .info_right {
    flex-wrap: wrap;

    .info_right_first,
    .info_right_second {
      width: 100% !important;
      justify-content: center;
    }

    .info_right_second {
      padding: 15px 0;
    }
  }

  .info_detail {
    flex-wrap: wrap;

    .info_detail_data,
    .info_detail_log {
      width: 100% !important;
    }
  }
}

.card_header {
  display: flex;
  width: 100%;
}

.info_left {
  width: 35%;
  display: flex;
  align-content: center;
  padding: 15px 15px;
  border-right: 1px solid #dddddd69;
}

.info_left_first {
  width: 30%;
  text-align: center;
  max-width: 180px;
}

.info_left_second {
  width: 70%;
}

.info_right {
  width: 65%;
  background: #F9F9F9;
  padding: 15px 15px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.info_right_first {
  width: 65%;
  display: flex;
  justify-content: flex-start;
}

.info_item {
  padding: 5px 15px;
  text-align: center;
}

.info_item_label {
  color: #666666 !important;
}

.info_item_icon {
  font-weight: bold;
  font-size: 18px;
}

.info_item_sub_label {
  font-weight: bold;
}

.info_right_second {
  width: 35%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.info_detail_data {
  width: 70%;
  padding: 15px;
  border-right: 1px solid #DDDDDD;
}

.info_detail_log {
  width: 30%;
}

.info_detail {
  display: flex;
}

.card_list {
  display: flex;
  justify-content: flex-start;
}

.card_list_item {
  padding: 0 25px;
  border-right: 1px solid #cfcfcf;
}

.card_list_item:last-child {
  border: 0;
}

.card-primary {
  padding: 10px;
  background: #00b3e6;
}

.t-eta-delayed {
  color: #fcb92c;
}

.t-eta-late {
  color: #FF0000;
}

.t-eta-early {
  color: #09aaf7;
}

.t-eta-on_time {
  color: #1cbb8c;
}

.t-eta-unknown {
  color: #dddddd;
}

.t-eta-null {
  color: #dddddd;
}

.container-scroll {
  display: block;
  width: 350px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.shipments-list {
  display: flex;
  white-space: nowrap;
}

.container-scroll::-webkit-scrollbar {
  display: none;
}

.textwrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  /* some width */
}

.textwrapmulti {
  overflow: hidden;
  width: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
