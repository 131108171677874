<template>
    <div class="row">
        <div class="col-md-3">
            <b>ID:</b> {{ resource.id }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Type')}}:</b> {{ resource.account.is_internal ? $t('Internal') : $t('Public') }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Name')}}:</b> {{ resource.name }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Created at')}}:</b> {{ resource.account.created_at | timestamp('YYYY-MM-DD')}}
        </div>
        <div class="col-md-3">
            <b>{{$t('TMS ID')}}:</b> {{ resource.tms_id ? resource.tms_id : '--'}}
        </div>
        <div class="col-md-3">
            <b>{{$t('SAP ID')}}:</b> {{ resource.sap_id ? resource.sap_id : '--' }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Documents')}}:</b> {{ $t(resource.document_status) }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Account')}}:</b> {{ resource.account.is_active ? $t('enabled'):$t('disabled') }}
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex';

export default {
    name: "ShipperInformation",
    computed: {
        ...mapState('requirements', ['resource']),
    }
}
</script>
