var GmapsCubicBezier = function(latlong1, latlong2, latlong3, latlong4, resolution) {

  var lat1 = latlong1.lat;
  var long1 = latlong1.lng;
  var lat2 = latlong2.lat;
  var long2 = latlong2.lng;
  var lat3 = latlong3.lat;
  var long3 = latlong3.lng;
  var lat4 = latlong4.lat;
  var long4 = latlong4.lng;

  var points = [];

  for (var it = 0; it <= 1; it += resolution) {
    points.push(this.getBezier({
      x: lat1,
      y: long1
    }, {
      x: lat2,
      y: long2
    }, {
      x: lat3,
      y: long3
    }, {
      x: lat4,
      y: long4
    }, it));
  }
  var path = [];
  for (var i = 0; i < points.length - 1; i++) {
    path.push({lat: points[i].x, lng: points[i].y});
    path.push({lat: points[i + 1].x, lng: points[i + 1].y});
  }

  return path;
};


GmapsCubicBezier.prototype = {

  B1: function(t) {
    return t * t * t;
  },
  B2: function(t) {
    return 3 * t * t * (1 - t);
  },
  B3: function(t) {
    return 3 * t * (1 - t) * (1 - t);
  },
  B4: function(t) {
    return (1 - t) * (1 - t) * (1 - t);
  },
  getBezier: function(C1, C2, C3, C4, percent) {
    var pos = {};
    pos.x = C1.x * this.B1(percent) + C2.x * this.B2(percent) + C3.x * this.B3(percent) + C4.x * this.B4(percent);
    pos.y = C1.y * this.B1(percent) + C2.y * this.B2(percent) + C3.y * this.B3(percent) + C4.y * this.B4(percent);
    return pos;
  }
};

const drawCurve = (P1, P2)=>{
  var lineLength = google.maps.geometry.spherical.computeDistanceBetween(P1, P2);
  var lineHeading = google.maps.geometry.spherical.computeHeading(P1, P2);
  if (lineHeading < 0) {
    var lineHeading1 = lineHeading + 45;
    var lineHeading2 = lineHeading + 135;
  } else {
    var lineHeading1 = lineHeading + -45;
    var lineHeading2 = lineHeading + -135;
  }
  var pA = google.maps.geometry.spherical.computeOffset(P1, lineLength / 3.5, lineHeading1);
  var pB = google.maps.geometry.spherical.computeOffset(P2, lineLength / 3.5, lineHeading2);

  return new GmapsCubicBezier(P1, {lat: pA.lat(), lng: pA.lng()}, {lat: pB.lat(), lng: pB.lng()}, P2, 0.01);
}

export default drawCurve;