<template>
    <div>
        <page-header :title="$t($route.meta.title)"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                <div class="col-md-12">
                    <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
                    <template  v-slot:right>
                        <div class="btn-group" role="group" aria-label="toolbar">
                            <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getTruckTypes(filters)" type="button" class="btn btn-light">
                                <i class="far fa-sync"></i>
                            </button>

                            <button  v-b-tooltip.hover :title="$t('Add')" v-b-modal.modal-edit type="button" class="btn btn-light">
                                <i class="far fa-plus"></i>
                            </button>
                            <button v-can="'trucks.create'" v-b-tooltip.hover :title="$t('Import')" @click="$refs.import.click()" type="button" class="btn btn-light">
                                <i class="far fa-file-import"></i>
                            </button>
                        </div>
                        <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
                    </template>
                    </filter-bar>
                </div>
                </div>

                <truck-type-table  :filters="filters" v-on:edit="editItem" v-on:page-change="pageChange"/>

            </div>
        </div>
         <validation-observer ref="observer" v-slot="{ validate }">
            <b-modal
                    id="modal-edit"
                    ref="modal-edit"
                    size="md"
                    :title="$t(form.id ? 'Edit':'New')"
                    body-class="p-0"
                    cancel-variant="light"
                    :ok-title="$t('Save')"
                    :cancel-title="$t('Cancel')"
                    v-on:close="resetForm"
                    v-on:cancel="resetForm"
                    :ok-disabled="!form.name || !form.configuration"
                    v-on:ok="onSubmit(validate)"
            >
            
                <b-form @submit.stop.prevent="onSubmit(validate)">
                    <div class="form-fields">
                    <div>
                        <form-input
                            :title="$t('Name')"
                            v-model="form.name"
                            rules="required"
                            type="text"
                            name="name"
                            layout="vertical"
                        />
                        <form-input
                            :title="$t('Configuration')"
                            v-model="form.configuration"
                            rules="required"
                            type="select"
                            :options="configurations"
                            name="configuration"
                            layout="vertical"
                        />
                        <form-input
                            :title="$t('External ID')"
                            v-model="form.external_id"
                            rules=""
                            type="text"
                            name="external_id"
                            layout="vertical"
                        />
                    </div>
                    </div>
                </b-form>
            </b-modal>
         </validation-observer>

    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import deleteManyMixin from "@/mixins/deleteMany";
import formMixin from "@/mixins/formMixin";
import TruckTypeTable from "@/components/tables/TruckTypeTable";

export default {
    name: 'TruckType',
    components:{
        FilterBar,
        TruckTypeTable
    },
    mixins: [filterMixin, deleteManyMixin, formMixin],
    data(){
        return {
            selected : [],
            options: [],
            form:{},

            configurations: [
                {text: this.$t('Simple'), value: 's'},
                {text: this.$t('Full'), value: 'f'}
            ],
            loading: false
        }
    },
    created(){
        this.init();
    },
    methods: {
        ...mapActions('trucktypes',['getTruckTypes','storeTruckType','updateTruckType','deleteTruckType','importTruckTypes']),
        resetForm(){
            this.form = {};
        },
        init(){
            let filters = {form_id: this.form_id};

            // Use meta filters
            if(this.$route.meta.paramFilters){
                for(let key of this.$route.meta.paramFilters){
                filters[key] = this.$route.params[key];
                }
            }
            if(this.$route.meta.filters){
                filters = {...filters, ...this.$route.meta.filters};
            }

            this.defaultFilters = filters;

            this.options = [

            ];

            let toolbarFilters = {};
            for(let item of this.options){
                if(this.$route.query.hasOwnProperty(item.filterProperty)){
                toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
                }
            }
            this.toolbarFilters = toolbarFilters;
        },
        editItem(item){
            this.form = _.cloneDeep(item);
            this.$refs['modal-edit'].show();
        },
        async onSubmit(validate){
            if (!(await validate()) || this.loading) {
                return;
            }
            this.loading = true;
            let form = _.cloneDeep(this.form);
            if(form.id){
                this.updateTruckType(form)
                    .then(data=>{
                        this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                            title: this.$t('Success'),
                            variant: 'success',
                            solid: true
                        });
                        this.resetForm();
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });
            }else{
                this.storeTruckType(form)
                    .then(data=>{
                        this.$root.$bvToast.toast(this.$t('Created successfully'), {
                            title: this.$t('Success'),
                            variant: 'success',
                            solid: true
                        });
                        this.resetForm();
                        this.getTruckTypes(this.filters);
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });
            }
        },
        handleFile(){
            let payload= {template : this.$refs.import.files[0]};
            this.importTruckTypes(payload)
                .then(data=>{
                    Echo.private(`jobs.${data.job_id}`)
                        .listen('JobProcessed', (e) => {
                            this.getTruckTypes(this.filters);
                            Echo.leave(`jobs.${data.job_id}`);
                        });
                    this.$root.$bvToast.toast(this.$t("File uploaded successfully", {resource: this.resourceName}), {
                            title: this.$t('Success'),
                            variant: 'success',
                            solid: true
                            });
                            
                })
                .catch(error =>{

                })
                .finally(()=>{
                    this.$refs.import.value=null;
                });
            
        }
    }
    
}
</script>
