<template>
    <div class="gallery">
        <div :key="index+'-carousel-img'" v-for="(image,index) in images" class="gallery_item">
            <img
                v-if="image.extension !== 'pdf'"
                @click="showSingle(image)"
                height="300" style="object-fit: cover;margin: auto"
                :src="image.path"
                class="gallery_item_img cursor-pointer"
            >
            <a href="javascript:void(0);" target="popup" @click="showPdf(image.path)" class="mr-2" v-else>
                <i class="far fa-file-pdf font-size-40" />
            </a>
            <div class="galley_item_info">
                <div class="gallery_item_name">{{image.filename}}</div>
                <div class="gallery_item_delete cursor-pointer "  @click="deleteFileAction(image)" v-if="deleted" >Eliminar</div>
            </div>
        </div>

        <vue-easy-lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            @hide="handleHide"
        ></vue-easy-lightbox>
    </div>
</template>

<script>


import VueEasyLightbox from "vue-easy-lightbox";
import {mapActions} from "vuex";
import Swal from "sweetalert2";

export default {
  name: "GalleryInfoComponent",
  components:{
    VueEasyLightbox
  },
  data() {
    return {
      currentIndex: 0,
      visible: false,
      imgs: "",
    }
  },

  props:{
    images:{
      type:Array
    },
    deleted:{
      type: Boolean,
      default(){
        return true
      }
    }
  },

  created: function() {

  },

  methods: {
    ...mapActions('files',['deleteFile']),
    showSingle(file_data) {
      this.imgs = file_data.path;
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    deleteFileAction(item) {

      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.deleteFile(item)
              .then(data=>{
                this.$emit('update_data')
                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("File").toLowerCase()}), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                this.processServiceError(error);
              });
        }
      });
    },
    showPdf(url){
        window.open(url,'popup','width=600,height=600');
    },
  },

  computed: {

  }
}
</script>

<style scoped>
.gallery{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -15px;
}
.gallery_item{
  display: flex;
  align-items: center;
  min-width: 180px;
  margin-right: 15px;
  margin-top: 15px;
}
.gallery_item_img{
  display: inline-block;
  width: 50px;
  height: 50px;
}
.gallery_item_name{
  color: #888888;
  font-size: 12px;
}

.gallery_item_delete{
  color: #00B3E6;
  font-weight: bold;
  font-size: 12px;
  font-family: "Inter", sans-serif;
}
.galley_item_info{
  padding-left: 5px;
  width: calc(100% - 60px);
}
</style>
