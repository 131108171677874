<template>
    <div class="mb-3">
        <div class="load-timeline bg-gray">
            <div class="d-flex justify-content-end">
             <b-dropdown  variant="link" toggle-class="text-decoration-none" right no-caret>
                <template #button-content>
                    <i class="fa fa-ellipsis-v text-black-50"></i>
                </template>
                <b-dropdown-item :active="showAddressTimezone === false" @click="showAddressTimezone = false">{{$t('User timezone')}}</b-dropdown-item>
                <b-dropdown-item :active="showAddressTimezone === true" @click="showAddressTimezone = true">{{$t('Location timezone')}}</b-dropdown-item>
             </b-dropdown>
            </div>
            <div v-if="loading" class="pb-4">
                <loading />
            </div>
            <div v-else class="wrapper-timeline pt-1">
                <div v-for="(item,index) in stops" :key="`timeline-item-${item.id}`" class="wrapper-item ">
                    <div class="left-section text-right">
                        <b class="text-primary item-text">
                            {{$t(getTitleItem(index))}}
                        </b>
                        <div class="text-body item-text" v-for="(leg,stopIndex) in getAddressFormat(item.address)" :key="`item-${item.id}-${stopIndex}`">
                            {{leg}}
                        </div>
                    </div>
                    <div class="right-section" :class="[index < (stops.length -1) ? 'timeline-border-left':'']">
                        <span :style="getBackgroundColour(item)" class="dot" />
                        <span :style="getTruckStyle(index)" class="truck-advance d-flex align-items-center justify-content-center">
                            <i class="fas fa-truck-moving text-primary" />
                        </span>
                        <b class="text-primary item-text">{{$t('Arrival')}}</b>
                        <div class="text-body item-text" >{{item.schedule_from}}</div>
                        <template v-if="item.status === 'in_transit' || item.status === 'started'">
                            <b class="text-primary item-text">{{$t('ETA')}}</b>
                            <div class="text-body item-text">{{item.eta | timestamp('YYYY-MM-DD HH:mm:ss', (item.address && showAddressTimezone) ? item.address.timezone : 'America/Tijuana')}}</div>
                        </template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import addressFormat from '@/helpers/address-format';
import {mapActions} from "vuex";
export default {
    components: {

    },
    name: 'LoadTimeline',
    props:{
        load:{
            type: Object,
            required: true
        },
        stops:{
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default(){
                return false
            }
        }
    },
    data(){
        return {
            showAddressTimezone: true
        }
    },
    methods: {
      ...mapActions('controltower',["changeTimeZone"]),
        getAddressFormat(address){
            return addressFormat(address)
        },
        getTitleItem(index){
            if(index === 0){
                return 'Pickup';
            }
            if(index === (this.stops.length -1)){
                return 'Delivery'
            }
            return 'Stop';
        },
        getBackgroundColour(stop) {
            if(stop.status === 'in_transit'){
                return 'background:#979797'
            }
            return 'background:#00B3E6'
        },
        getTruckStyle(index){
            if(index < (this.stops.length - 1 ) && this.stops[index+1].status === 'in_transit'){
                return 'top:calc(50% - 15px);'
            }else{
                return 'display:none!important;'
            }
        }
    },
    watch:{
      showAddressTimezone: {
        deep: true,
        handler(value){
          this.changeTimeZone(value);
        }
      }
    }
}
</script>
<style lang="scss" scoped>
.bg-gray{
    background-color: #f9f9f9;
}
.load-timeline{
    .wrapper-timeline{
       
        .wrapper-item{
             display: flex;
            .left-section{
                width: 50%;
                padding: 0px 20px 50px 10px;
            }
            .right-section{
                width: 50%;
                padding: 0px 20px 50px 20px;
                position: relative;
            }
        }
    }
}
.timeline-border-left{
    border-left: 1px solid #00B3E6;
}
.dot {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: -5px;
    top: 0;
}
.item-text{
    font-size: 11px;
}
.truck-advance{
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    left: -16px;
    border: 1px solid #00B3E6;
    background-color: #f9f9f9;
}
</style>