<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h6>{{$t('Stops')}}</h6>
        </div>
        <div class="form-fields">
            <div class="field-wrapper">
                <div class="row align-items-center ">
                    <div class="col-md-6">
                        <div class="field-label">{{$t('Intermediate stops?')}}</div>
                        <div class="field-help"></div>
                    </div>
                    <div class="col-md-6">
                        <switches v-model="add" theme="bootstrap" color="info" class="float-right" />
                    </div>
                </div>
            </div>

            <form-input
                v-if="add"
                :title="$t('Stop type')"
                :help="$t('Select an option')"
                v-model="type"
                rules="required"
                type="select"
                :options="stopTypes"
                :name="'multistop_type'"
                :upper="true"
                :disabled="!add"
                v-on:change="updateStopType"
            />
            
            <template v-for="(stop,index) in stops">
                <div :key="'stop-header-'+ (stop.id ? stop.id : stop.key)"  class="field-wrapper" :class="[(!stops[index].collapse || !collapsed) ? 'border-bottom-none':'']">
                    <div class="row align-items-center ">
                        <div class="col-md-8">
                            <div class="field-label"><b>{{$t('Address')}}</b></div>
                            <div class="field-help">{{ stop.address | address-format }}</div>
                        </div>
                        <div class="col-md-4 d-flex justify-content-end">
                            <a href="javascript:void(0);" class="text-danger mr-2" @click="stops.splice(index,1)" v-b-tooltip.hover :title="$t('Delete')" >
                                <i class="far fa-trash-alt btn-icon"  />
                            </a>
                            <a href="javascript:void(0);"  @click="stops[index].collapse=!stop.collapse" >
                                <i class="far  btn-icon" :class="[stops[index].collapse ? 'fa-chevron-circle-down':'fa-chevron-circle-up']" />
                            </a>
                        </div>
                    </div>
                </div>
                <div :key="'stop-slot-'+ (stop.id ? stop.id : stop.key)" class="field-wrapper" >
                    <div class="row align-items-center ">
                        <div class="col-md-6">
                            <div class="field-label">{{$t('Date')}}<span class="field-required-badge"/></div>
                            <div class="field-help">{{$t('Arrival schedule')}}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <validation-provider name="pickup.schedule_from" rules="required" v-slot="{ errors }">
                                        <date-input v-model="stops[index].schedule_from" format="YYYY-MM-DD HH:mm:ss"  type="datetime" />
                                        <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <validation-provider name="pickup.schedule_to" rules="required" v-slot="{ errors }">
                                        <date-input v-model="stops[index].schedule_to" format="YYYY-MM-DD HH:mm:ss" type="datetime" />
                                        <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                    </validation-provider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form-input
                    :key="'stop-rfc-'+ (stop.id ? stop.id : stop.key)"
                    :title="type === 'multipick' ? $t('RFC sender'): $t('RFC recipient')"
                    :help="type === 'multipick' ? $t('The RFC sender'):$t('The RFC recipient')"
                    v-model="stops[index].rfc"
                    rules="required|max:15|rfc"
                    type="text"
                    :name="id+'.'+index+'.rfc'"
                    :upper="true"
                />

                <form-input
                    :key="'stop-tax_registration_id-'+ (stop.id ? stop.id : stop.key)"
                    v-if="!['MX', 'MEX'].includes(stops[index].address.country)"
                    :title="$t('Tax registration id')"
                    :help="$t('The tax id registration number')"
                    v-model="stops[index].tax_registration_id"
                    rules="required"
                    type="text"
                    :name="id+'.'+index+'.tax_registration_id'"
                />

                <address-input :key="'stop-address-search-'+ (stop.id ? stop.id : stop.key)"
                               v-on:change="setAddress"
                               :index="index"
                               v-model="stops[index].address_select"
                               :default-options="defaultOptions ? defaultOptions: []"
                                 />

                <address-components :id="id+'.'+index+'.address.'"
                                    v-show="showLeg(index)"
                                    :key="'stop-'+ (stop.id ? stop.id : stop.key)"
                                    v-model="stops[index].address" :disabled="!stops[index].collapse && stop.id ? true : false "  />
            </template>
            <div class="field-wrapper">
                <div class="row align-items-center ">
                    <div class="col-md-8"></div>
                    <div class="col-md-4 ">
                        <button type="button" :disabled="!add"  class="btn btn-primary btn-sm float-right" @click="addStop">{{$t('Add')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AddressComponents from "./AddressComponents";
    import Switches from 'vue-switches';
    import cryptoRandomString from 'crypto-random-string';
    import DateInput from "@/components/form/DateInput";
    import AddressInput from "@/components/form/AddressInput";

    export default {
        name: "StopForm",
        components:{
            AddressComponents,
            Switches,
            DateInput,
            AddressInput
        },
        computed:{

        },
        props: {
            defaultCountry:{
                type: String,
                default(){
                    return 'MX'
                }
            },
            id:{
                type: String,
                default(){
                    return '';
                }
            },
            value:{
                required: true
            },
            collapsed: {
                type: Boolean,
                default(){
                    return false;
                }
            },
            defaultOptions: {
              type: Array,
              default(){
                return [];
              }
            },
            disabled:{
                type : Boolean,
                default(){
                    return false;
                }
            },
            sender: {
                type: String,
                default(){
                    return null;
                }
            }
        },
        data(){
            return{
                stops: [],
                add : false,
                type: 'multipick',
                stopTypes: [
                    {text: this.$t('Multi-Pick'), value:'multipick'},
                    {text: this.$t('Multi-Drop'), value:'multidrop'}
                ]
            }
        },
        watch:{
            add(value){
                if(value === false){
                    this.stops = [];
                }else{
                    if(this.stops.length === 0){
                        this.addStop();
                    }
                }
            },
            stops: {
                deep: true,
                handler(newValue){
                    this.$emit('input', newValue)
                }
            },
            value(newValue){
                this.stops = newValue;
                if(newValue.length > 0){
                    this.add = true;
                }
            }
        },
        created(){
            this.stops = this.value;
        },
        methods:{
            addStop(){
                let stops = this.stops.map(item => ({
                        ...item,
                        collapse: true,
                        pick: this.type === 'multidrop',
                        drop: this.type === 'multidrop'
                    }));
                stops.push({
                    key: cryptoRandomString({length:5}),
                    collapse: false,
                    address: {
                        country: this.defaultCountry
                    },
                    pick: this.type === 'multidrop',
                    drop: this.type === 'multidrop',
                    rfc: this.sender
                });
                this.stops = stops;
            },
            showLeg(index){
                if([true, false].indexOf(this.stops[index].collapse) > -1){
                    return !this.stops[index].collapse;
                }
                return !this.collapsed;
            },
            updateStopType(value){
                let stops = this.stops.map(item => ({
                        ...item,
                        pick: value === 'multidrop',
                        drop: value === 'multidrop'
                    }));
                this.stops = stops;
            },
            setAddress(value,index){
                if (value){
                  this.stops[index].address_id = value.id;
                  this.stops[index].address = value?value:{country: 'MX'};
                  this.stops[index].rfc = this.stops[index].rfc ? this.stops[index].rfc : value.rfc;
                }else{
                  this.stops[index].address_id = null
                }
            }
        }
    }
</script>

<style scoped>

</style>
