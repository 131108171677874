
<template>
    <div class="row mt-4">
        <div class="col-md-12 text-right">
            <div type="button" v-if="back" class="btn btn-outline-light upper btn_mw" @click="$emit('eventBack')">
                {{ $t("Back") }}
            </div>
            <button v-if="next && complete" class="btn btn-primary_v2 upper btn_mw" @click="$emit('eventNext')">
                {{ $t(textNext) }}
            </button>
            <button v-if="next && !complete" class="btn btn-outline-light upper btn_mw">
                {{ $t(textNext) }}
            </button>
            <button v-if="submit && !complete" class="btn btn-outline-light upper btn_mw">
                {{ $t(textNext) }}
            </button>
            <button v-if="submit && complete" ref="submit" type="submit" class="btn btn-primary_v2 upper btn_mw">
                {{ $t(textNext) }}
            </button>
        </div>
    </div>
</template>
<script>
/**
 * Este componente se puede implementar para avanzar y retroceder en un wizard
 * emite dos eventos y recibe 3 propiedades para su funcionamiento
 *
 * @param {Boolean} next Esta propiedad habilida o deshabilida el boton para dar siguiente
 * @param {Boolean} back Esta propiedad habilida o deshabilida el boton para retroceders
 * @param {Boolean} complete Esta propiedad evalua si el step esta completo para avanzar en el wizard
 *
 * @event eventBack Esta funcion se usa para retroceder en el wizard
 * @event eventNext Esta funcion se usa para avanzar en el wizard
 */
export default {
    name: "ButtonsStep",
    props: {
        next: {
            type: Boolean
        },
        back: {
            type: Boolean
        },
        complete: {
            type: Boolean
        },
        textNext: {
            type: String,
            default: "Next"
        },
        submit: {
            type: Boolean
        }
    }
}
</script>
<style lang="scss">
.btn_mw {
    min-width: 200px;
    margin-left: 20px;
    text-transform: uppercase;
}
</style>
