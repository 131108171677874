<template>
  <div>
    <div class="row mt-4">
      <div class="col-md-8 offset-md-2">
        <loading v-if="fetching" />

        <div v-if="empty">
          <empty-results :subtitle="$t('This shipment does not have a checklist')"/>
        </div>
        <div class="card" :key="'stop'+instance.id" v-for="(instance, cIndex) in items">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0" >{{instance.form.name}} <span class="text-capitalize text-muted font-size-12 ">{{$t(instance.form.type)}} Checklist</span></h6>
              <div class="type_load" v-if="instance.form.owner">
                <span class="font-weight-bold" >{{$t('Shipper')}}</span>
                <span class="load_id font-weight-bold text-primary " style="color: #00B3E6 !important" >{{ instance.form.owner.name }}</span>
              </div>
            </div>
          </div>
          <div class="form-fields">
            <template v-for="(field, idx) in instance.form.fields">
              <template v-if="['gallery','pod'].indexOf(field.type) > -1">

                <div class="field-wrapper" :key="'field-'+field.id" >
                  <div >
                    <div class="row align-items-center ">
                      <div class="col-md-6">
                        <div class="field-label">{{field.title}}  </div>
                        <div class="field-help">{{field.help}}</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="readOnly === false" class="p-4">
                    <file-input
                        path="/api/v2/upload"
                        :options="{dictDefaultMessage: field.title, acceptedFiles: 'image/*'}"
                        v-on:success="data=>appendImage(instance.owner.id, instance.id, field.id, data.path)"
                    />

                  </div>
                  <template v-if="field.answer">
                    <div class="d-flex justify-content-center flex-wrap p-3">
                      <a
                          v-for="(item, gIndex) in field.answer"
                          :key="field.id+'-'+gIndex"
                          class="cursor-pointer"
                      >
                        <div class="img-fluid">
                          <img :src="`${item}`" alt width="80" height="80" style="object-fit: cover;" />
                        </div>
                      </a>
                    </div>
                    <vue-easy-lightbox
                        :visible="field.galleryVisible"
                        :index="index"
                        :imgs="field.answer"
                        @hide="field.galleryVisible = false"
                    ></vue-easy-lightbox>
                  </template>
                  <template v-else>
                    <empty-results :title="$t('No photos')" :subtitle="$t('No photos have been uploaded')" />
                  </template>
                </div>
              </template>

              <form-input
                  v-else
                  :key="'field-'+field.id"
                  :title="field.title"
                  :help="field.help"
                  v-model="field.answer"
                  :type="field.type"
                  :name="'field.'+idx+'.title'"
                  :options="field.options"
                  :read-only="readOnly"
                  v-on:change="(value)=>saveAnswer(instance.owner.id, instance.id, field.id, value)"
              />
            </template>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import FileInput from "@/components/form/FileInput";
import VueEasyLightbox from "vue-easy-lightbox";
import formMixin from '@/mixins/formMixin';
import Swal from "sweetalert2";




export default {
  name: "externalRequirementsLoads",
  components:{
    FileInput,
    VueEasyLightbox
  },
  mixins:[formMixin],
  data(){
    return {
      fetching: true,
      items: [],
      index : 0,
      readOnly: false,
      stop: null,
      empty: false,
      downloading: false
    }
  },
  computed:{
    ...mapState('shipments',['externalRequirements','shipment']),
    ...mapState('profile',['profile']),
  },
  watch: {
    externalRequirements: {
      deep: true,
      handler(value){
        this.items = _.cloneDeep(value);
      }
    }
  },
  created() {
    this.getExternalRequirements({id: this.$route.params.load_id}).then(data=>{
      if(data.length === 0){
        this.empty = true;
      }else{
        this.items = _.cloneDeep(data);
      }

    }).finally(()=>{
      this.fetching = false;
    });
  },
  methods:{
    ...mapActions('shipments',['getExternalRequirements','storeChecklistAnswerShipment','downloadPOD','podCompleted','getLoad']),
    onClickGallery(slide){
      // TODO: remove image  (slide.index)

    },
    saveAnswer(stopId, checklistId,fieldId, value)
    {
      let payload = {
        load_id : this.$route.params.load_id,
        stop_id: stopId,
        checklist_id : checklistId,
        field_id: fieldId,
        value: value
      };
      
      this.storeChecklistAnswerShipment(payload)
          .then(data=>{
            this.getExternalRequirements({id: this.$route.params.load_id}).then(()=>{
              if(data.length === 0){
                this.empty = true;
              }else{
                this.items = _.cloneDeep(data);
              } 
            })
          })
          .finally(()=>{
            //this.fetching = false;
          });
    },
    appendImage(stopId, checklistId,fieldId, url){
      let checklist = _.cloneDeep(this.externalRequirements);
      let form = checklist.find(item => item.id === checklistId);
      let field = form.form.fields.find(item => item.id === fieldId);
      let answer = field.answer ? field.answer : [];
      answer.push(url);
      this.saveAnswer(stopId, checklistId, fieldId, answer);
    },
    showGallery(checklistIndex, fieldIndex, index) {
      this.index = index;
      let items = _.cloneDeep(this.items);
      items[checklistIndex].form.fields[fieldIndex].galleryVisible = true;
      this.items = items;
    },
    createNewChecklist(){
      this.$router.push({
        name: 'loads.detail.checklist.add',
        params: {load_id: this.$route.params.load_id, stop_id: this.stop.value}
      });
    },
    download(){
      let payload = {
        id : this.$route.params.load_id,
      };
      this.downloading = true;
      this.downloadPOD(payload)
          .then(data =>{

          })
          .catch(error =>{
            this.processServiceError(error);
          })
          .finally(()=>{
            this.downloading = false;
          });
    },
    confirmPodCompleted(){
      if (this.canBeSendPOD){
        Swal.fire({
          title: this.$t("Are you sure?"),
          html: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("Accept"),
          cancelButtonText: this.$t("Cancel"),
          reverseButtons: true
        })
            .then(result => {
              if(result.value) {
                this.podCompleted({
                  id : this.$route.params.load_id,
                })
                    .then(data =>{
                      this.getLoad({id: this.$route.params.load_id});
                      this.$root.$bvToast.toast(this.$t("The action was completed successfully"), {
                        title: this.$t('Success'),
                        variant: 'success',
                        solid: true
                      });
                    })
                    .catch(error =>{
                      this.$root.$bvToast.toast(this.$t("There are issues not closed"), {
                        title: this.$t('Error'),
                        variant: 'danger',
                        solid: true
                      });
                      this.processServiceError(error);
                    });
              }

            });
      }else{
        let msgs = [];
        this.issues_pending.map((item)=>{
          msgs.push(this.$t("The issues '{issue_name}' is in the {status} status", {issue_name: item.type.name.toUpperCase() ,status:this.$t(item.status).toUpperCase()  }))
        });
        Swal.fire({
          icon: 'error',
          title: this.$t("There are issues not closed"),
          html: "<ul><li>"+msgs.join("  </li><li> ")+"</ul>",
        })
      }

    },
  }
}
</script>

<style scoped lang="scss">
.type_load{
  background: white;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgb(153 153 153 / 50%);
  border: 1px solid #DDDDDD;

  .load_id{
    border-left: 1px solid #DDDDDD;
    padding: 0 0 0 10px;
    margin-left: 10px;
  }

}
</style>
