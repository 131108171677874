<template >

  <div class="time-picker-modal-container">
    <div class="picker-container">
        <div class="picker-container-hand" v-if="this.step === 0?this.hour:this.minute"  :style="position_rotate"></div>
        <time-picker-generator :handle-time-pointer-click="handleTimePointerClick" :type="timeType" :hour="hour" :minute="minute"></time-picker-generator>
    </div>
    <div class="time-picker-modal-header mt-5">
      <span class="title">Hora seleccionada:</span>
      <div class="label">
        <span class="time-picker-header" @click="handleStepChange(0)" :class="{active:!step}">{{hourStringFormat}}</span>
        <span class="text-white"> : </span>
        <span class="time-picker-header" @click="handleStepChange(1)" :class="{active:step}">{{minuteString}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HOURS,
  MINUTES
} from './constValues.js'
import TimePickerGenerator from './TimePickerGenerator'
export default {
  props: {
    initStep: {
      type: Number,
      default: 0
    },
    initHour: {
      type: [Number, String],
      default: '00'
    },
    initMinute: {
      type: [Number, String],
      default: '00'
    },
    handleHourChange: {
      type: Function,
      default: () => {}
    },
    handleMinuteChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      step: this.initStep,
      pointerRotate: () => {
        this.resetHourDegree()
      },
      hour: this.initHour,
      minute: this.initMinute
    }
  },
  computed: {
    timeType() {
      return this.step === 0 ? 'hour' : 'minute'
    },
    hourString() {
      return this.hour < 12 ? this.hour : this.hour - 12 ; //    this.hour < 10 ? '0' + this.hour : this.hour
    },
    hourStringFormat() {
      if (this.hourString===0) return 12
      return this.hourString < 10 ? '0' + this.hourString : this.hourString
    },
    minuteString() {
      return this.minute < 10 ? '0' + this.minute : this.minute
    },
    position_rotate() {
      let value = null;
      if (this.step === 0 ){
        value = ((360/12)*this.hour)
      }else{
        value = ((((100/60)*this.minute)*360)/100)
      }
      return {
        transform: 'translateX(-50%) rotate('+value+'deg)'
      }
    }
  },
  components: {
    TimePickerGenerator
  },
  methods: {
    handleStepChange(s) {
      if (s !== this.step) {
        this.step = s
        this.pointerRotate = s === 0 ? this.resetHourDegree() : this.resetMinuteDegree()
      }
    },
    handleTimePointerClick(time, rotate) {
      this.pointerRotate = rotate
      this.handleTimeChange(time)
    },
    handleTimeChange(time) {
      time = parseInt(time)
      if (this.step === 0) {
        this.hour = time
        this.handleHourChange && this.handleHourChange(time)
        this.step = 1
      } else {
        this.minute = time
        this.handleMinuteChange && this.handleMinuteChange(time)
      }
    },
    resetHourDegree() {
      let h = parseInt(this.hour)
      let pointerR = 0
      HOURS.forEach((hour, index) => {
        if (h === index + 1) {
          pointerR = index < 12 ? 360 * (index + 1) / 12 : 360 * (index + 1 - 12) / 12
        }
      })
      return pointerR
    },
    resetMinuteDegree() {
      let m = parseInt(this.minute)
      let pointerR = 0
      MINUTES.forEach((minute, index) => {
        if (m === index) {
          pointerR = 360 * index / 60
        }
      })
      return pointerR
    }
  }
}
</script>

<style lang="css">
.time-picker-modal-container {
  width:100%;
  opacity: 1;
  z-index: 2;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: #fff;
  border-radius: 2px;
  top: 100%;
  left: 0;
  transform: translateY(20px);
}
.time-picker-modal-container:hover{

}


.picker-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 1px solid black;
  position: relative;
  margin: auto;
}

.picker-container::before{
  position: absolute;
  content: "";
  background: black;
  width: 10px;
  height: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  border-radius: 50%;
}

.picker-container-hand{
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  width: 2px;
  height: calc(30% - 0px);
  transform-origin: 50% bottom;
  z-index: 10;
  background: black;
}


</style>
