<template>
  <div class="">
    <div class="mb-5">
      <div class=" tpx_calendar txp_calendar_v2">
        <div class="actions_btn">
          <div class="text-primary font_bold cursor-pointer mr-3" @click="lastMonth">
            <i class="ri-arrow-left-s-line font-size-24"></i>
          </div>
          <div class="text-primary font_bold mr-3 title">
            {{ localDate.format("MMMM YYYY") }}
          </div>
          <div class="text-primary font_bold cursor-pointer mr-3" @click="nextMonth">
            <i class="ri-arrow-right-s-line font-size-24"></i>
          </div>
        </div>
        <month :month="_month" :year="_year" :events="events" @setDateActive="setDay" />
        <TransparentModal :showModal="showModal" :position="positionModal" @close="closeModal">
          <time-picker :from="from" :to="to" :validDate="validDateCallBack" @setFrom="setFrom" @setTo="setTo"
            @closeModal="closeModal" />
        </TransparentModal>
      </div>
    </div>
    <buttons-steps @eventBack="$emit('calendarBack')" @eventNext="confirm()" :back="true" :next="true"
      :complete="true" />
  </div>
</template>

<script>
import moment from "moment/moment";
import Month from "../calendarPicker/Month.vue";
import TransparentModal from "../../common/TransparentModal.vue";
import TimePickerTxp from "../../TimePicker/TimePickerTxp.vue";
import TimePicker from "../calendarPicker/TimePicker.vue";
import ButtonsSteps from "../../common/ButtonsSteps.vue";


export default {
  name: "OriginCalendarView",
  components: {
    ButtonsSteps,
    TimePicker,
    Month, TransparentModal, TimePickerTxp
  },
  props: {
    originDate: {
      type: Object,
    },
  },
  data() {
    return {
      localDate: moment(),
      showModal: false,
      event: {},
      date: {},
      from: {
        hour: 19,
        minute: 0
      },
      to: {
        hour: 20,
        minute: 30
      },
      positionModal: { x: 0, y: 0 }
    }
  },
  created() {
    if (this.originDate.schedule_from) {
      let from = moment(this.originDate.schedule_from)
      this.from.hour = from.hour()
      this.from.minute = from.minute()
      let to = moment(this.originDate.schedule_to)
      this.to.hour = to.hour()
      this.to.minute = to.minute();
      this.generateEven(from, from.format("HH:mm"), to.format("HH:mm"))
    }
  },
  computed: {
    _month: function () {
      return moment(this.localDate).month()
    },
    _year: function () {
      return moment(this.localDate).year()
    },
    events: function () {
      return this.event.date ? [this.event] :[]
    },
  },
  methods:{
    nextMonth: function () {
      this.localDate = moment(this.localDate).add(1, "month");
    },
    lastMonth: function () {
      this.localDate = moment(this.localDate).subtract(1, "month");
    },
    generateEven(date,from, to){
      this.event = {
        type: 'pre_pickup',
        date: date ? date: null,
        from: from ? from : null ,
        to: to ? to : null,
        editable: true,
      }
    },
    setDay(data){
      this.positionModal = data.position;
      this.date = data.date;
      this.generateEven(data.date, null,null)
      this.showModal = true
    },
    closeModal(data){
      this.generateEven(null, null ,null)
      this.showModal=false;
    },
    setFrom(data){
      this.generateEven(this.date, data, null)
    },
    setTo(data){
      this.generateEven(this.date, this.event.from, data)
      this.showModal=false;
    },
    validDateCallBack(data){
      if (data.step === 'from'){
        let aux =  moment(this.event.date.format("YYYY MM DD"))
        aux.hour(data.hour)
        aux.minute(data.minute)
        return aux.isAfter(moment())
      }else {
        let aux =  moment(this.event.date.format("YYYY MM DD"))
        let aux_from =  moment(this.event.date.format("YYYY MM DD"))
        aux.hour(data.hour)
        aux.minute(data.minute)
        let info_from = this.event.from.split(':')
        aux_from.hour(info_from[0])
        aux_from.minute(info_from[1])
        return aux.isAfter(aux_from)
      }
    },
    confirm(){
      console.log(this.event)
      let data = {
        day: this.event.date.format("DD"),
        month: this.event.date.format("MM"),
        year: this.event.date.format("YYYY"),
        schedule_from: this.event.date.format('YYYY-MM-DD')+' '+this.event.from,
        schedule_to: this.event.date.format('YYYY-MM-DD')+' '+this.event.to,
      };
      this.$emit("calendarNext", data);
    }
  },
  watch:{
  }
}
</script>

<style scoped>

.actions_btn {
  font-family: "Inter", sans-serif;
  color: #00b3e6;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  display: flex;
  justify-content: flex-end;
  text-transform: capitalize;
}
.txp_calendar_v2{
  position: relative;
}
</style>