import finance from "./finance";
import loads from "./loads";
import shipments from "./shipments";

import BaseLayout from "../views/layouts/BaseLayout";

import Dashboard from '../views/pages/dashboard';

export default [
    {
        path: '/',
        name: 'auth',
        component: () => import('../views/auth/layouts/BaseLayout'),
        redirect: {name: 'login.index'},
        children: [
            {
                path: 'forgot_password',
                name: 'forgot_password',
                meta:{freeAuth:true},
                component: () => import('../views/auth/reset_password/ForgotPassword'),
            },
            {
                path: 'reset-password',
                name: 'reset_password',
                meta:{freeAuth:true},
                component: () => import('../views/auth/reset_password/ResetPassword'),
            },
            {
                path: 'new-password-required',
                name: 'new_password_required',
                meta:{freeAuth:true},
                component: () => import('../views/auth/reset_password/ResetPasswordRequired'),
            },
            {
                path: 'login',
                name: 'login.index',
                meta:{freeAuth:true},
                component: () => import('../views/auth/login/Login.vue'),
            },
            {
                path: 'register',
                name: 'register',
                component: () => import('../views/auth/Content'),
                redirect: {name: 'register.index'},
                meta:{freeAuth:true},
                children: [
                    {
                        path:'index',
                        name:'register.index',
                        meta:{freeAuth:true},
                        component: () => import('../views/auth/register/Register_Phone.vue'),
                    },
                    {
                        path:'code',
                        name:'register.code',
                        meta:{freeAuth:true},
                        component: () => import('../views/auth/register/Register_OTPCode'),
                    },
                    {
                        path:'username',
                        name:'register.username',
                        meta:{freeAuth:true},
                        component: () => import('../views/auth/register/Register_Username'),
                    },
                    {
                        path:'user_info',
                        name:'register.user_info',
                        meta:{freeAuth:true},
                        component: () => import('../views/auth/register/Register_UserInfo'),
                    }
                ]
            }
        ]
    },
    {
        path: '/new-dashboard',
        name: 'new-dashboard',
        component: () => import('../views/home/Move')
    },
    {
        path:'/on_boarding',
        name:'on_boarding',
        component: () => import('../views/on_boarding/OnBoarding'),
    },
    {
        path: '/rate-confirmation/:token',
        name: 'rate_confirmation',
        meta:{freeAuth:true},
        component: () => import('../views/rateconfirmation/Summary.vue'),
    },
    {
        path: '/:profile',
        name: 'profile',
        component: BaseLayout,
        redirect: {name: 'loads'},
        children: [
            {
                path:'dashboard',
                name:'dashboard',
                component: () => import('../views/Content'),
                redirect: {name:'dashboard.index'},
                children: [
                    {
                        path:'',
                        name:'dashboard.index',
                        component: Dashboard,
                    }
                ]
            },
            {
                path:'home',
                name:'home',
                component: () => import('../views/Content'),
                redirect: {name: 'home.index'},
                children: [
                    {
                        path:'',
                        name:'home.index',
                        meta: {title: 'Home', bgWhite: true},
                        component: () => import('../views/home/Move')
                    },
                ]
            },
            {
                path:'welcome',
                name:'welcome',
                component: () => import('../views/Content'),
                redirect: {name: 'welcome.index'},
                children: [
                    {
                        path:'',
                        name:'welcome.index',
                        meta: {title: 'Welcome', bgWhite: true},
                        component: () => import('../views/home/Welcome')
                    },
                ]
            },
            {
                path:'recommendations',
                name:'recommendations',
                component: () => import('../views/Content'),
                redirect: {name: 'recommendations.index'},
                children: [
                    {
                        path:'',
                        name: 'recommendations.index',
                        component: () => import('../views/recommendations/Recommendations'),
                        meta: {title: 'Recommendations', bgWhite: true},
                    },
                    {
                        path:'edit',
                        name: 'recommendations.edit',
                        component: () => import('../views/recommendations/Recommendations'),
                        meta: {title: 'Recommendations', bgWhite: true},
                    },
                ]
            },
            {
                path:'calendar',
                name:'calendar',
                component: () => import('../views/Content'),
                redirect: {name: 'calendar.index'},
                children: [
                    {
                        path:'',
                        name:'calendar.index',
                        meta: {title: 'Home', bgWhite: true},
                        component: () => import('../views/calendar/Calendar')
                    },
                ]
            },
            {
                path:'search',
                name:'search',
                component: () => import('../views/Content'),
                redirect: {name: 'search.index'},
                children: [
                    {
                        path:'',
                        name:'search.index',
                        meta: {title: 'Search', bgWhite: true},
                        component: () => import('../views/loads/search/Search')
                    },
                    {
                        path: ":load_id",
                        name: "loads.search.detail",
                        component: () => import("../views/loads/search/LoadDetail"),
                        meta: { title: "Load", bgWhite: true },
                    },
                ]
            },
            {
                path:'marketplace',
                name:'marketplace',
                component: () => import('../views/Content'),
                redirect: {name: 'marketplace.index'},
                children: [
                    {
                        path:'',
                        name:'marketplace.index',
                        meta: {title: 'Marketplace',paramFilters: ['carrier_id'], bgWhite: true},
                        component: () => import('../views/loads/Loads')
                    },
                    {
                        path: ':load_id',
                        name: 'marketplace.detail',
                        component: () => import('../views/shipments/ShipmentContainer'),
                        redirect:{name:'marketplace.detail.general'},
                        meta: {
                            title: 'Marketplace', breadcrumb:{text:'Shipment', param: 'load_id', to:{name:'marketplace.detail.general'}}
                        },
                        children: [
                            {
                                path: 'general',
                                name: 'marketplace.detail.general',
                                component: () => import('../views/shipments/MarketplaceGeneral'),
                                meta: {title: 'Marketplace', type: 'shipment'},
                            }
                        ]
                    }
                ]
            },
            {
                path:'attendance',
                name:'attendance',
                component: () => import('../views/attendance/Attendance')
            },
            ...shipments,
            ...loads,
            {
                path:'customer-control-tower',
                name:'customer-control-tower',
                meta: {showControlTowerFilter: false},
                component: () => import('../views/controltower/CustomerControlTower.vue')
            },
            {
                path:'control-tower',
                name:'control-tower',
                meta: {showControlTowerFilter: true},
                component: () => import('../views/loads/Tracking')
            },
            {
                path:'history',
                name:'history',
                component: () => import('../views/Content'),
                meta: {title: 'History',filters:{status: 'pod,paid'}, bgWhite: true},
                redirect: {name: 'history.index'},
                children: [
                    {
                        path:'',
                        name:'history.index',
                        component: () => import('../views/loads/Loads'),
                        meta: {filters:{status: 'pod,paid'}, bgWhite: true},
                    },
                ]
            },
            {
                path:'routes',
                name:'routes',
                component: () => import('../views/Content'),
                meta: { bgWhite: true},
                redirect: {name: 'routes.index'},
                children: [
                    {
                        path:'',
                        name: 'routes.index',
                        component: () => import('../views/routes/Routes'),
                        meta: {title: 'Routes and Agreements',paramFilters: ['shipper_id'],filters:{}, bgWhite: true, routes: {}}
                    },
                    {
                        path:'add',
                        name: 'routes.add',
                        component: () => import('../views/routes/RouteForm'),
                        meta: {title: 'New route',bgWhite: true, routes: {list:'routes.index'}}
                    },
                    {
                        path:':route_id/edit',
                        name: 'routes.edit',
                        component: () => import('../views/routes/RouteForm'),
                        meta: {title: 'Edit route',bgWhite: true, routes: {list:'routes.index'}}
                    },
                ]
            },
            {
                path:'trucks',
                name:'trucks',
                component: () => import('../views/Content'),
                redirect: {name: 'trucks.index'},
                children: [
                    {
                        path: '',
                        name: 'trucks.index',
                        meta: {title:'Trucks', filters: {'with_tenant': true}, bgWhite: true, routes:{edit:'trucks.edit',add: 'trucks.add',detail:'truck.detail.general'} },
                        component: () => import('../views/trucks/Trucks')
                    },
                    {
                        path: 'add',
                        name: 'trucks.add',
                        component: () => import('../views/trucks/TruckForm'),
                        meta: {title:'New truck'}
                    },
                    {
                        path: ':truck_id',
                        name: 'trucks.detail',
                        component: () => import('../views/trucks/TruckContainer'),
                        redirect:{name:'truck.detail.general'},
                        children: [
                            {
                                path: 'general',
                                name: 'truck.detail.general',
                                component: () => import('../views/trucks/TruckGeneral'),
                                meta: {title:'Truck',}
                            },
                            {
                                path: 'documents',
                                name: 'truck.detail.documents',
                                component: () => import('../views/trucks/TruckDocuments'),
                                meta: {title:'Documents',}
                            },
                        ]
                    },
                    {
                        path: ':truck_id/edit',
                        name: 'trucks.edit',
                        component: () => import('../views/trucks/TruckForm'),
                        meta: {title: 'Edit truck'}
                    },
                ]
            },
            {
                path:'trailers',
                name:'trailers',
                component: () => import('../views/Content'),
                redirect: {name: 'trailers.index'},
                children: [
                    {
                        path: '',
                        name: 'trailers.index',
                        meta: {title:'Trailers',filters: {'with_tenant': true}, bgWhite: true, routes:{edit:'trailers.edit',add: 'trailers.add',detail:'trailer.detail.general'} },
                        component: () => import('../views/trailers/Trailers')
                    },
                    {
                        path: 'add',
                        name: 'trailers.add',
                        component: () => import('../views/trailers/TrailerForm'),
                        meta:{title:'New trailer',}
                    },
                    {
                        path: ':trailer_id',
                        name: 'trailers.detail',
                        component: () => import('../views/trailers/TrailerContainer'),
                        redirect:{name:'trailer.detail.general'},
                        children: [
                            {
                                path: 'general',
                                name: 'trailer.detail.general',
                                component: () => import('../views/trailers/TrailerGeneral'),
                                meta:{title: 'Trailer'}
                            },
                            {
                                path: 'documents',
                                name: 'trailer.detail.documents',
                                component: () => import('../views/trailers/TrailerDocuments'),
                                meta: {title:'Documents'}
                            },
                        ]
                    },
                    {
                        path: ':trailer_id/edit',
                        name: 'trailers.edit',
                        component: () => import('../views/trailers/TrailerForm'),
                        meta: {title: 'Edit trailer'}
                    },
                ]
            },
            {
                path:'drivers',
                name:'drivers',
                component: () => import('../views/Content'),
                redirect: {name: 'drivers.index'},
                meta: {breadcrumb:{text:'Drivers', to:{name:'drivers.index'}}},
                children: [
                    {
                        path: '',
                        name: 'drivers.index',
                        meta: {title: 'Drivers', bgWhite: true},
                        component: () => import('../views/users/Users')
                    },
                    {
                        path: 'add',
                        name: 'drivers.add',
                        component: () => import('../views/users/UserForm'),
                        meta: {title: 'New driver', breadcrumb:{text:'New', to:{name:'drivers.add'}},bgWhite: true, profile:'driver',parent:{title: 'Drivers', route:'drivers.index'}, class:'col-md-8 offset-md-2' }
                    },
                    {
                        path: ':user_id',
                        name: 'drivers.detail',
                        component: () => import('../views/drivers/DriverContainer'),
                        redirect:{name:'driver.detail.general'},
                        children: [
                            {
                                path: 'general',
                                name: 'driver.detail.general',
                                component: () => import('../views/drivers/DriverGeneral'),
                                meta: {title: 'Driver'}
                            },
                            {
                                path: 'documents',
                                name: 'driver.detail.documents',
                                component: () => import('../views/drivers/DriverDocuments'),
                                meta: {title: 'Documents'}
                            },
                            {
                                path: 'credit',
                                name: 'driver.detail.credit',
                                component: () => import('../views/wallet/WalletCredit'),
                                meta: {title: 'Credit'}
                            },
                        ]
                    },
                    {
                        path: ':user_id/edit',
                        name: 'drivers.edit',
                        meta: { bgWhite: true, title: 'Edit driver', profile:'driver',parent:{title: 'Drivers', route:'drivers.index'}, class:'col-md-8 offset-md-2'  },
                        component: () => import('../views/users/UserForm')
                    }
                ]
            },
            {
                path:'users',
                name:'users',
                component: () => import('../views/Content'),
                redirect: {name: 'users.index'},
                meta: {breadcrumb:{text:'Users', to:{name:'users'}}},
                children: [
                    {
                        path: '',
                        name: 'users.index',
                        meta: {title :'Users',breadcrumb:{text:'Users', to:{name:'users.index'}}, bgWhite: true,  routes:{add:'users.add', edit: 'users.edit',list: 'users.index'} },
                        component: () => import('../views/users/Users')
                    },
                    {
                        path: 'add',
                        name: 'users.add',
                        component: () => import('../views/users/UserForm'),
                        meta: {title: 'New user',breadcrumb:{text:'New', to:{name:'users.add'}},parent:{title: 'Users', route:'users.index'}, class:'col-md-8 offset-md-2' },
                    },
                    {
                        path: ':user_id',
                        name: 'users.detail',
                    },
                    {
                        path: ':user_id/edit',
                        name: 'users.edit',
                        component: () => import('../views/users/UserForm'),
                        meta: {title: 'Edit user',breadcrumb:{text:'Edit', to:{name:'users.edit'}},parent:{title: 'Users', route:'drivers.index'},routes:{list: 'users.index'}, class:'col-md-8 offset-md-2' },
                    },
                ]
            },
            {
                path:'shippers',
                name:'shippers',
                component: () => import('../views/Wrapper'),
                redirect: {name: 'shippers.index'},
                children: [
                    {
                        path:'',
                        name: 'shippers.container',
                        component: () => import('../views/Content'),
                        redirect: {name:'shippers.index'},
                        children: [
                            {
                                path: '',
                                name: 'shippers.index',
                                component: () => import('../views/users/Shippers'),
                                meta: {title: 'Shippers',paramFilters: [], filters:{roles: 'shipper'}, bgWhite: true}
                            }
                        ]
                    },
                    {
                        path:':shipper_id',
                        name: 'shippers.detail',
                        component: () => import('../views/profile/Profile'),
                        redirect: {name: 'shippers.account'},
                        children: [
                            {
                                path:'account',
                                name: 'shippers.account',
                                component: () => import('../views/accounts/Account'),
                                meta: {type: 'shipper', title: 'Account'}
                            },
                            {
                                path:'loads',
                                name: 'shippers.loads',
                                meta: {paramFilters: ['shipper_id'], bgWhite: true, title: 'Loads'},
                                component: () => import('../views/loads/Loads'),
                            },
                            {
                                path:'history',
                                name: 'shippers.history',
                                component: () => import('../views/loads/Loads'),
                                meta: {paramFilters: ['carrier_id'],filters:{status: 'pod,paid'}, bgWhite: true, title: 'History'}
                            },
                            {
                                path: 'documents',
                                name: 'shippers.documents',
                                component: () => import('../views/shippers/ShipperDocuments'),
                                meta: {title: 'Documents'}
                            },
                            {
                                path:'routes',
                                name: 'shippers.routes',
                                component: () => import('../views/routes/Routes'),
                                meta: {title: 'Routes and Agreements',paramFilters: ['shipper_id'],filters:{}, bgWhite: true, routes: {add:'shippers.routes.add'}}
                            },
                            {
                                path:'routes/add',
                                name: 'shippers.routes.add',
                                component: () => import('../views/routes/RouteForm'),
                                meta: {title: 'New route',paramFilters: ['shipper_id'], routes: {list:'shippers.routes'}}
                            },
                            {
                                path:'routes/:route_id/edit',
                                name: 'shippers.routes.edit',
                                component: () => import('../views/routes/RouteForm'),
                                meta: {title: 'Edit route',routes: {list:'shippers.routes'}}
                            },
                            {
                                path: 'pod',
                                name: 'shippers.pod',
                                component: () => import('../views/profile/settings/ProofOfDeliveryForm'),
                                meta:{title:'Proof of delivery',isAdmin: true, breadcrumb:{text:'Proof of delivery', to:{name:'settings.pod'}}},

                            },
                            {
                                path: 'externalRequirements',
                                name: 'shippers.externalRequirements',
                                component: () => import('../views/profile/settings/externalRequirements'),
                                meta:{bgWhite: true,title:'External Requirements',isAdmin: true, breadcrumb:{text:'External Requirements', to:{name:'settings.externalRequirements'}}},

                            },
                            {
                                path: 'externalRequirementsCreate',
                                name: 'shippers.externalRequirementsCreate',
                                component: () => import('../views/profile/settings/externalRequirementsForm'),
                                meta:{bgWhite: true,title:'External Requirements',isAdmin: true, breadcrumb:{text:'External Requirements', to:{name:'settings.externalRequirements'}}},

                            },
                            {
                                path: 'externalRequirements/:form_id',
                                name: 'shippers.externalRequirementsForm',
                                component: () => import('../views/profile/settings/externalRequirementsForm'),
                                meta:{bgWhite: true,title:'External Requirements',isAdmin: true, breadcrumb:{text:'External Requirements', to:{name:'settings.externalRequirements'}}},

                            },
                            {
                                path: 'addresses',
                                name: 'shippers.addresses',
                                component: () => import('../views/profile/settings/Addresses'),
                                meta: {title: 'Addresses',paramFilters: ['shipper_id'],filters:{roles: 'shipper'}, bgWhite: true}
                            }
                        ]
                    },
                ]
            },
            {
                path:'carriers',
                name:'carriers',
                component: () => import('../views/Wrapper'),
                redirect: {name: 'carriers.index'},
                children: [
                    {
                        path:'',
                        name: 'cariers.container',
                        component: () => import('../views/Content'),
                        redirect: {name:'carriers.index'},
                        children: [
                            {
                                path: '',
                                name: 'carriers.index',
                                component: () => import('../views/users/Carriers'),
                                meta: {title: 'Carrier',paramFilters: [], filters:{roles: 'carrier'}, bgWhite: true}
                            }
                        ]
                    },
                    {
                        path:':carrier_id',
                        name: 'carriers.detail',
                        component: () => import('../views/profile/Profile'),
                        redirect: {name: 'carriers.account'},
                        children: [
                            {
                                path:'account',
                                name: 'carriers.account',
                                component: () => import('../views/accounts/Account'),
                                meta: {title: 'Account',type: 'carrier'}
                            },
                            {
                                path:'loads',
                                name: 'carriers.loads',
                                component: () => import('../views/loads/Loads'),
                                meta: {title: 'Loads',paramFilters: ['carrier_id'], bgWhite: true}
                            },
                            {
                                path:'history',
                                name: 'carriers.history',
                                component: () => import('../views/loads/Loads'),
                                meta: {title:'History',paramFilters: ['carrier_id'],filters:{status: 'pod,paid'}, bgWhite: true}
                            },
                            {
                                path: 'documents',
                                name: 'carriers.documents',
                                component: () => import('../views/carriers/CarrierDocuments'),
                                meta: {title: 'Documents'}
                            },
                            {
                                path: 'addresses',
                                name: 'carriers.addresses',
                                component: () => import('../views/profile/settings/Addresses'),
                                meta: {title: 'Addresses',paramFilters: ['carrier_id'],filters:{roles: 'carrier'}, bgWhite: true}
                            },
                            {
                                path: 'attendance',
                                name: 'carriers.attendance',
                                component: () => import('../views/attendance/Attendance'),
                                meta: {title: 'Addresses',paramFilters: ['carrier_id'],filters:{roles: 'carrier'}, bgWhite: true}
                            },
                            {
                                path:'trucks',
                                name: 'carriers.trucks.container',
                                component: () => import('../views/Wrapper'),
                                redirect: {name: 'carriers.trucks.container'},
                                children: [
                                    {
                                        path: '',
                                        name: 'carriers.trucks',
                                        meta: {title: 'Trucks',paramFilters: ['carrier_id'], bgWhite: true, routes:{add:'carriers.trucks.add', edit: 'carriers.trucks.edit',detail:'truck.detail.general'}},
                                        component: () => import('../views/trucks/Trucks')
                                    },
                                    {
                                        path: 'add',
                                        name: 'carriers.trucks.add',
                                        component: () => import('../views/trucks/TruckForm'),
                                        meta: {title: 'New truck',paramFilters: ['carrier_id'],routes:{list: 'carriers.trucks'},class:'col-md-12'}
                                    },
                                    {
                                        path: ':truck_id/edit',
                                        name: 'carriers.trucks.edit',
                                        component: () => import('../views/trucks/TruckForm'),
                                        meta: {title: 'Edit truck',routes:{list: 'carriers.trucks'},class:'col-md-12'}
                                    },
                                ]
                            },
                            {
                                path:'trailers',
                                name: 'carriers.trailers.container',
                                meta: {paramFilters: ['carrier_id'], bgWhite: true},
                                component: () => import('../views/Wrapper'),
                                children: [
                                    {
                                        path: '',
                                        name: 'carriers.trailers',
                                        meta: {title: 'Trailers',paramFilters: ['carrier_id'], bgWhite: true, routes:{add:'carriers.trailers.add', edit: 'carriers.trailers.edit', detail:'trailer.detail.general'}},
                                        component: () => import('../views/trailers/Trailers')
                                    },
                                    {
                                        path: 'add',
                                        name: 'carriers.trailers.add',
                                        component: () => import('../views/trailers/TrailerForm'),
                                        meta: {title:'New trailer',paramFilters: ['carrier_id'],routes:{list: 'carriers.trailers'},class:'col-md-12'}
                                    },
                                    {
                                        path: ':trailer_id/edit',
                                        name: 'carriers.trailers.edit',
                                        component: () => import('../views/trailers/TrailerForm'),
                                        meta: {title: 'Edit trailer',routes:{list: 'carriers.trailers'},class:'col-md-12'}
                                    },
                                ]
                            },
                            {
                                path: 'drivers',
                                name: 'carriers.drivers.container',
                                component: () => import('../views/Wrapper'),
                                children : [
                                    {
                                        path:'',
                                        name: 'carriers.drivers',
                                        component: () => import('../views/users/Users'),
                                        meta: {title: 'Drivers',paramFilters: ['carrier_id'], bgWhite: true, routes:{add:'carriers.drivers.add', edit: 'carriers.drivers.edit'}},
                                    },
                                    {
                                        path: 'add',
                                        name: 'carriers.drivers.add',
                                        component: () => import('../views/users/UserForm'),
                                        meta: { title: 'New driver',parent:{title: 'Drivers', route:'carriers.drivers'},
                                                routes:{list: 'carriers.drivers'}, profile: 'driver',paramFilters: ['carrier_id'], class:'col-md-12'},
                                    },
                                    {
                                        path: ':user_id/edit',
                                        name: 'carriers.drivers.edit',
                                        meta: { title: 'Edit driver',parent:{title: 'Drivers', route:'carriers.drivers'} ,
                                                routes:{list: 'carriers.drivers'},profile:'driver', class:'col-md-12'},
                                        component: () => import('../views/users/UserForm')
                                    }
                                ]
                            },
                        ]
                    },
                ]
            },
            {
                path:'views',
                name:'views',
                component: () => import('../views/Views/ViewLayout'),
                children: [
                    {
                        path:':view_id',
                        name: 'views.detail',
                        meta: {title: 'Views',paramFilters: ['view_id'], bgWhite: true},
                        component: () => import('../views/Views/ViewDetail'),
                    }
                ]
            },
            {
                path:'settings',
                name:'settings',
                component: () => import('../views/profile/Settings'),
                redirect: {name: 'settings.profile'},
                meta: {breadcrumb:{text:'Settings', to:{name:'settings'}}},
                children: [
                    {
                        name: 'settings.profile',
                        path: 'profile',
                        component: () => import('../views/profile/settings/Profile'),
                        meta: {title: 'Profile'}
                    },
                    {
                        name: 'settings.security',
                        path: 'security',
                        component: () => import('../views/profile/settings/Security'),
                        meta: {title: 'Security'}
                    },
                    {
                        name: 'settings.account',
                        path: 'account',
                        component: () => import('../views/profile/settings/Account'),
                        meta: {title: 'Account'}
                    },
                    {
                        name: 'settings.preferences',
                        path: 'preferences',
                        component: () => import('../views/profile/settings/Preferences'),
                        meta: {title: 'Preferences'}
                    },
                    {
                        name: 'settings.members',
                        path: 'members',
                        component: () => import('../views/Wrapper'),
                        redirect: {name: 'staff.index'},
                        meta: {breadcrumb:{text:'Members', to:{name:'settings.members.index'}}},
                        children: [
                            {
                                path: '',
                                name: 'settings.members.index',
                                meta: {title: 'Members', bgWhite: true, breadcrumb:{text:'Members', to:{name:'settings'}},routes:{list:'settings.members.index'} },
                                component: () => import('../views/profile/settings/Users')
                            },
                            {
                                path: 'add',
                                name: 'settings.members.add',
                                component: () => import('../views/users/UserForm'),
                                meta: {profile:'staff', title: 'New member',breadcrumb:{text:'New', to:{name:'settings.members'}}, parent:{title: 'Users', route:'staff.index'},routes:{list:'settings.members.index'}}
                            },
                            {
                                path: ':user_id/edit',
                                name: 'settings.members.edit',
                                component: () => import('../views/users/UserForm'),
                                meta: {profile:'staff',title: 'Edit member', parent:{title: 'Users', route:'staff.index'},routes:{list:'settings.members.index'}}
                            },
                        ]
                    },
                    {
                        name: 'settings.teams',
                        path: 'teams',
                        component: () => import('../views/Wrapper'),
                        redirect: {name: 'teams.index'},
                        children:[
                            {
                                name: 'settings.teams.index',
                                path: '',
                                component: () => import('../views/settings/teams/Teams'),
                                meta: {title: 'Teams', breadcrumb:{text:'Teams', to:{name:'settings.teams'}} },
                            },
                            {
                                path: 'add',
                                name: 'settings.teams.add',
                                component: () => import('../views/settings/teams/TeamForm'),
                                meta: { title: 'New team', breadcrumb:{text:'New team', to:{name:'settings.teams.index'}}}
                            },
                            {
                                path: ':team_id/edit',
                                name: 'settings.teams.edit',
                                component: () => import('../views/settings/teams/TeamForm'),
                                meta: {title: 'Edit team', breadcrumb:{text:'Edit team', to:{name:'settings.teams.index'}}}
                            },
                        ]
                    },
                    {
                        name: 'settings.trucktypes',
                        path: 'truck-types',
                        component: () => import('../views/settings/TruckTypes'),
                        meta: {bgWhite: true, title: 'Truck types', breadcrumb:{text:'Truck types', to:{name:'settings.trucktypes'}} }
                    },
                    {
                        name: 'settings.trailertypes',
                        path: 'trailer-types',
                        component: () => import('../views/settings/TrailerTypes'),
                        meta: {bgWhite: true, title: 'Trailer types', breadcrumb:{text:'Trailer types', to:{name:'settings.trailertypes'}} }
                    },
                    {
                        name: 'settings.commodities',
                        path: 'commodities',
                        component: () => import('../views/settings/Commodities'),
                        meta: {bgWhite: true, title: 'Commodities', breadcrumb:{text:'Commodities', to:{name:'settings.commodities'}} }
                    },
                    {
                        name: 'settings.requirements',
                        path: 'requirements',
                        component: () => import('../views/Wrapper'),
                        redirect: {name: 'requirements.index'},
                        children: [
                            {
                                path: '',
                                name: 'requirements.index',
                                meta: {title: 'Requirements', bgWhite: true },
                                component: () => import('../views/profile/settings/Requirements')
                            },
                            {
                                path: 'add',
                                name: 'requirements.add',
                                component: () => import('../views/requirements/RequirementForm'),
                                meta: {title: 'New requirement'}
                            },
                            {
                                path: ':requirement_id',
                                name: 'requirements.detail',
                            },
                            {
                                path: ':requirement_id/edit',
                                name: 'requirements.edit',
                                component: () => import('../views/requirements/RequirementForm'),
                                meta: {title: 'Edit requirement'}
                            },
                        ]
                    },
                    {
                        name: 'settings.addresses',
                        path: 'addresses',
                        meta: {
                            bgWhite: true,
                            title:'Addresses',
                        },
                        component: () => import('../views/profile/settings/Addresses'),
                    },
                    {
                        name: 'settings.documents',
                        path: 'documents',
                        component: () => import('../views/profile/settings/Documents'),
                        meta: {title: 'Documents', bgWhite: true}
                    },
                    {
                        path:'load-fields',
                        name:'settings.loadfields.container',
                        component: () => import('../views/Wrapper'),
                        children:[
                            {
                                path:'',
                                name:'settings.loadfields',
                                component: () => import('../views/profile/settings/LoadFields'),
                                meta: {
                                    bgWhite: true,
                                    routes:{add:'settings.loadfields.add', edit: 'settings.loadfields.edit'},
                                    title:'Load fields',
                                    description:'Add and manage load fields to customize your load forms.'
                                },
                            },
                            {
                                path:':form_id/add',
                                name:'settings.loadfields.add',
                                component: () => import('../views/profile/settings/FormField'),
                                meta:{
                                    title: 'Load fields',
                                    routes:{list: 'settings.loadfields'}
                                }
                            },
                            {
                                path:':form_id/:field_id/edit',
                                name:'settings.loadfields.edit',
                                component: () => import('../views/profile/settings/FormField'),
                                meta:{
                                    title: 'Load fields',
                                    routes:{list: 'settings.loadfields'}
                                }
                            }
                        ]
                    },
                    {
                        path: 'views',
                        name: 'settings.views',
                        component: () => import('../views/Wrapper'),
                        meta: {breadcrumb:{text:'Views', to:{name:'settings.views.list'}}},
                        redirect: {name:'settings.views.list'},
                        children: [
                            {
                                path: '',
                                name: 'settings.views.list',
                                component: () => import('../views/Views/Views'),
                                meta:{ bgWhite: true, title:'Views',routes:{add:'settings.views.add',edit: 'settings.views.edit'}}
                            },
                            {
                                path: 'add',
                                name: 'settings.views.add',
                                component: () => import('../views/Views/ViewForm'),
                                meta:{bgWhite: true, title:'New view', breadcrumb:{text:'New view', to:{name:'settings.views.add'}}, routes:{edit:'', list:'settings.views.list'}}
                            },
                            {
                                path: ':view_id/edit',
                                name: 'settings.views.edit',
                                component: () => import('../views/Views/ViewForm'),
                                meta:{bgWhite: true, title:'Edit view',breadcrumb:{text:'Edit view', to:{name:'settings.views.edit'}},routes:{edit:'', list:'settings.views.list'}}
                            },
                        ]
                    },
                    {
                        name: 'settings.settlement',
                        path: 'settlement',
                        meta: { bgWhite: true ,title:'Settlement Catalog',},
                        component: () => import('../views/profile/settings/SettlementCatalog'),
                    },
                    {
                        name: 'settings.catalogs',
                        path: 'catalogs',
                        meta: { bgWhite: true ,title:'Catalogs',},
                        component: () => import('../views/profile/settings/Catalogs'),
                    },
                    {
                        path: 'pod',
                        name: 'settings.pod',
                        component: () => import('../views/profile/settings/ProofOfDeliveryForm'),
                        meta:{title:'Proof of delivery', breadcrumb:{text:'Proof of delivery', to:{name:'settings.pod'}}}
                    },

                ]
            },
            {
                path:'products',
                name:'products',
                component: () => import('../views/Content'),
                redirect: {name: 'products.index'},
                children: [
                    {
                        path: '',
                        name: 'products.index',
                        meta: {title: 'Products', bgWhite: true },
                        component: () => import('../views/ecommerce/Products')
                    },
                    {
                        path: 'add',
                        name: 'products.add',
                        meta: {title: 'New product', bgWhite: false },
                        component: () => import('../views/ecommerce/ProductForm')
                    },
                    {
                        path: ':product_id',
                        name: 'products.detail',
                        meta: {title:'Product', bgWhite: true },
                        component: () => import('../views/ecommerce/ProductDetail')
                    },
                    {
                        path: ':product_id/edit',
                        name: 'products.edit',
                        meta: {title:'Edit product', bgWhite: true },
                        component: () => import('../views/ecommerce/ProductForm')
                    }
                ]
            },
            {
                path:'wallet',
                name:'wallet',
                component: () => import('../views/Content'),
                redirect: {name: 'wallet.index'},
                children: [
                    {
                        path: '',
                        name: 'wallet.index',
                        meta: {title: 'Wallet', bgWhite: true },
                        component: () => import('../views/wallet/Wallet')
                    },
                ]
            },
            {
                path:'documents',
                name:'documents',
                redirect: {name: 'documents.approval'},
                component: () => import('../views/Content'),
                children: [
                    {
                        path: 'approval',
                        name: 'documents.approval',
                        meta: { bgWhite: true },
                        component: () => import('../views/documents/DocumentContainer'),
                        redirect:{name:'documents.approval.carriers'},
                        children: [
                            {
                                path: 'carriers',
                                name: 'documents.approval.carriers',
                                meta: {title:'Documents', bgWhite: true, filters:{document_status: 'in_review'}, isDocumentModule:true},
                                component: () => import('../views/users/Carriers'),
                            },
                            {
                                path: 'shippers',
                                name: 'documents.approval.shippers',
                                meta: {title:'Documents', bgWhite: true, filters:{document_status: 'in_review'}, isDocumentModule:true},
                                component: () => import('../views/users/Shippers' )
                            },
                            {
                                path: 'drivers',
                                name: 'documents.approval.drivers',
                                meta: {title:'Documents', bgWhite: true, filters:{document_status: 'in_review'}, isDocumentModule:true},
                                component: () => import('../views/users/Drivers' )
                            },
                            {
                                path: 'trucks',
                                name: 'documents.approval.trucks',
                                meta: {title:'Documents', bgWhite: true, filters:{document_status: 'in_review'}, isDocumentModule:true},
                                component: () => import('../views/trucks/Trucks' )
                            },
                            {
                                path: 'trailers',
                                name: 'documents.approval.trailers',
                                meta: {title:'Documents', bgWhite: true, filters:{document_status: 'in_review'}, isDocumentModule:true},
                                component: () => import('../views/trailers/Trailers' )
                            },
                        ]
                    }
                ],
            },
            {
                path:'branchoffices',
                name:'branchoffices',
                component: () => import('../views/Content'),
                redirect: {name: 'branchoffices.index'},
                children: [
                    {
                        path: '',
                        name: 'branchoffices.index',
                        meta: { bgWhite: true },
                        component: () => import('../views/ecommerce/BranchOffice')
                    },
                    {
                        path: 'add',
                        name: 'branchoffices.add',
                        meta: { bgWhite: true },
                        component: () => import('../views/ecommerce/BranchOfficeForm')
                    },
                    {
                        path: ':branchoffice_id/edit',
                        name: 'branchoffices.edit',
                        meta: { bgWhite: true },
                        component: () => import('../views/ecommerce/BranchOfficeForm')
                    }
                ]
            },
            {
                path: 'orders',
                name: 'orders',
                component: () => import('../views/Content'),
                redirect: {name: 'orders.index'},
                children: [
                    {
                        path: '',
                        name: 'orders.index',
                        meta: {title: 'Orders'},
                        component: () => import('../views/ecommerce/Orders')
                    }
                ]
            },
            {
                name: 'documents.resource',
                path: ':resource',
                component: () => import('../views/Content'),
                redirect: {name: 'documents.resource.detail'},
                children: [
                    {
                        path: ':resource_id/documents',
                        name: 'documents.resource.detail',
                        meta: {title: 'Documents', bgWhite: true },
                        component: () => import('../views/documents/DocumentResource')
                    }
                ]
            },
            ...finance
        ]
    },
]
