<template>
    <div>
        <page-header :title="$t('Products')" :items="breadcrumb"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-12">
                        <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
                            <template v-slot:right>
                                <router-link v-b-tooltip.hover :title="$t('Add')" :to="{name: 'products.add'}" class="btn btn-light">
                                    <i class="far fa-fw fa-plus"></i>
                                </router-link>
                                <button v-bind:disabled="selected.length === 0" v-b-tooltip.hover :title="$t('Delete')" @click="confirmDelete" type="button" class="btn btn-light">
                                    <i class="far fa-fw fa-trash-alt"></i>
                                </button>
                                 <button v-b-tooltip.hover :title="$t('Import')" @click="fetchData"  type="button" class="btn btn-light">
                                    <i class="far fa-fw fa-file-import"></i>
                                </button>
                            </template>
                        </filter-bar>
                    </div>
                </div>
            </div>
        </div>

      <product-table v-model="selected" :filters="filters" v-on:page-change="pageChange" />


    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import ProductTable from "@/components/tables/ProductTable";
    import FilterBar from "@/components/filters/FilterBar";
    import filterMixin from "@/mixins/filterMixin";
    import deleteManyMixin from "@/mixins/deleteMany";
    import VueSlideBar from "vue-slide-bar";
    import ProductCard from "../../components/ecommerce/productCard";

    export default {
        name:'Products',
        components:{
          ProductCard,
            ProductTable,
            FilterBar,
            VueSlideBar
        },
        mixins: [filterMixin, deleteManyMixin],
        data(){
            return {
                tabs: [],
                currentTab: null,
                search: null,
                fetching: false,
                page: 1,
                options: [],
                selected: [],
                categoriesData: [],
                sliderPrice: 0
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapState('products', ['last_filters', 'products','categories']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Products"),
                        active: true,
                        to: {name:'products.index'}
                    }
                ]
            },
            list(){
              return this.products
            },
            filtersBadges(){
              let filters = _.cloneDeep(this.filters);
              delete filters.per_page;
              delete filters.page;
              return filters
            },
            breadcrumb_categories(){
              let breadcrumb = [];
              if (this.filters.category){
                if (this.filterLabel[this.filters.category].parent_id){
                  breadcrumb.push({text: this.filterLabel[this.filterLabel[this.filters.category].parent_id].text})
                }
                breadcrumb.push({text: this.filterLabel[this.filters.category].text})
              }
              return breadcrumb
            },
            categoriesTree(){
              const idMapping = this.categories.reduce((acc, el, i) => {
                acc[el.id] = i;
                return acc;
              }, {});
              let root = [];
              this.categories.forEach(el => {
                if (el.parent_id === null) {
                  root.push(el);
                  return;
                }
                let parentEl = this.categories[idMapping[el.parent_id]];
                parentEl.children = [...(parentEl.children || []), el];
              });
              return root
            },
            filterLabel(){
              return this.categories.reduce((acc, el, i) => {
                acc[el.id] = {text:el.name, parent_id:el.parent_id};
                return acc;
              }, {});
            }
        },
        created() {
            this.resourceName = this.$t("Products").toLowerCase();
            this.options = [
                {
                    icon: 'far fa-key',
                    label: 'ID',
                    type: 'text',
                    filterProperty: 'id',
                },
                {
                    icon: 'far fa-sliders-h-square',
                    label: 'Name',
                    type: 'text',
                    filterProperty: 'name',
                },
                {
                    icon: 'far fa-sliders-h-square',
                    label: 'Brand',
                    type: 'text',
                    filterProperty: 'brand',
                },
                {
                    icon: 'fa fa-search',
                    label: 'Search',
                    type: 'text',
                    filterProperty: 'search',
                },
            ];
            let toolbarFilters = {};
            for(let item of this.options){
                if(this.$route.query.hasOwnProperty(item.filterProperty)){
                    toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
                }
            }
            this.toolbarFilters = toolbarFilters;
            this.init();
        },
        methods: {
            ...mapActions('products', ['getProducts', 'deleteManyProducts', 'getCategories']),
            deleteMany() {
                return new Promise((resolve, reject) => {
                    this.deleteManyProducts({ids: this.selected.join(',')})
                        .then(data => {
                            this.selected = []
                            this.getProducts(this.filters)
                            resolve(data)
                        })
                        .catch(error=> {
                            reject(error)
                        })
                });
            },
            init() {
              if (this.filters.search){
                this.search = this.filters.search
              }
              this.getCategories(this.filters).then((data)=>{
                this.categoriesData = data
              })
              console.log(this.filters);
              this.fetchData();

            },
            fetchData() {
              this.fetching = true;
              this.getProducts(this.filters).finally(()=>{
                this.fetching = false;
              })
            },
            searchAction(a) {
              this.onFiltersChange({search:this.search});
            },
            setCategory(a) {
              this.onFiltersChange({category:a});
            },
            deleteFilter(filter) {
              this.filterRemoved(filter);
              if (filter == "search"){
                this.search = null
              }
              if (filter == "price"){
                this.sliderPrice = 0
              }
            }
        },
        watch: {
          filters : {
            deep: true,
            handler(newVal, oldVal){
              if(!_.isEqual(newVal, oldVal)){
                this.fetchData();
              }
            }
          },
          page(newVal){
            this.pageChange(newVal)
          },
          sliderPrice: _.debounce(function(newVal){
            if (newVal>0){
              this.onFiltersChange({price:newVal});
            }
          }, 100)
        }
    }
</script>

<style>
.filters_container{

}
.filters_container .badge{
  display: inline-flex;
  align-items: center;
}
.filters_container .badge .close{
  color: white;
  font-size: 15px;
  padding: 1px 1px 1px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.breadcrumb_categories{
  background: white;
  padding-left: 0;
}
.delete-img{
  position: absolute;
  left: calc(50% - 10px);
  bottom: -30px;
}

.gallery_list_item img{
  width: 45px;
  height: 45px;
}

.gallery_list{
  display: flex;
  width: 100%;
  padding: 0 20px;
}
.gallery_list_item{
  margin-right: 10px;
  border: 2px solid white;
  transition: all .5s;
  border-radius: 5px;
  position: relative;
}
.gallery_list_item:hover{
  cursor: pointer;
  opacity: 1;
  border: 2px solid #2bb3e7;
}
.gallery_list_item.active{
  border: 2px solid #2bb3e7;
  opacity: 1;
}
.carousel-control-prev, .carousel-control-next{
  background: rgba(0,0,0,.2);
  height: 50px;
  top: calc(50% - 25px);
}
</style>
