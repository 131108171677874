<template>
    <div>
        <page-header :title="$route.params.branchoffice_id ? $t('Edit branch office'): $t('New branch office')" :items="breadcrumb"/>

        <div class="row">
            <div class="col-md-8 offset-md-2">
                <validation-observer ref="observer" v-slot="{ validate }">
                    <b-form @submit.stop.prevent="onSubmit(validate)">
                        <div class="card">
                            <div class="card-header">
                                <h6>{{ $t('Branch office information') }}</h6>
                            </div>

                            <form-input
                                :title="$t('Name')"
                                :help="$t('The branchoffice name')"
                                v-model="form.name"
                                rules="required"
                                type="text"
                                :name="'name'"
                            />

                            <form-input
                                :title="$t('Phone')"
                                :help="$t('The branchoffice phone')"
                                v-model="form.phone"
                                rules="required"
                                type="text"
                                :name="'name'"
                            />
            
                        </div>

                        <address-form id="address." :title="$t('Branch address')" v-model="form.address" :collapsed="stopsCollapsed"></address-form>

                        <div class="d-flex justify-content-end">
                            <button :disabled="loading" type="button" class="btn btn-light mr-3 " @click="$router.push({name:'branchoffices.index'})">{{$t('Cancel')}}</button>
                            <button :disabled="loading" v-if="!$route.params.branchoffice_id" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew">{{$t('Save and create new')}}</button>
                            <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t('Save')}}</button>
                        </div>

                    </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import AddressForm from "../../components/form/AddressForm";

    export default {
        name:'BranchofficeForm',
        beforeRouteEnter(to, from, next) {
            next((vm) =>  vm.from = from)
        }, 
        components:{
            AddressForm
        },
        mixins: [formMixin],
        data(){
            return {
                title: '...',
                form: {address:{}},
                createNew: false,
                loading: false,
                from: null,
                stopsCollapsed : false,
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Branch offices"),
                        to: {name:'branchoffices.index'}
                    },
                   {
                        text: this.$route.params.branchoffice_id ? this.$t('Edit branch office'): this.$t('New branch office'),
                        active: true
                    }
                ]
            }
        },
        created() {
            if(this.$route.params.branchoffice_id){
                this.loading = true;
                this.getBranchoffice({id: this.$route.params.branchoffice_id, seller_id: this.profile.profile.id})
                    .then(data=>{
                        let form = {...data, }
                        this.form = form;
                        this.loading = false;
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    })
            }
        },
        methods: {
            ...mapActions('branchoffices',['getBranchoffice','storeBranchoffice','updateBranchoffice']),
            resetForm() {
                this.form = {address:{}};
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },
            saveAndCreateNew(){
                this.createNew = true;
                this.$refs.submit.click();
            },
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    return;
                }

                this.loading = true;

                let form = {...this.form, seller_id: this.profile.profile.id};

                if(this.$route.params.branchoffice_id){
                    this.updateBranchoffice(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            this.redirect();
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }else{
                    this.storeBranchoffice(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Created successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            if(this.createNew){
                                this.resetForm();
                            }else{
                                this.redirect();
                            }
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }
            },
            redirect(){
                if(this.from.name){
                    this.$router.back();
                }else{
                    this.$router.push({name:'branchoffices.index'});
                }                
            }
        }
    }
</script>

<style>

</style>
