
const awsmobile = {
    "authenticationFlowType": 'USER_PASSWORD_AUTH' ,
    "aws_project_region": process.env.VUE_APP_AWS_REGION,
    "aws_cognito_identity_pool_id": process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.VUE_APP_AWS_REGION,
    "aws_user_pools_id": process.env.VUE_APP_COGNITO_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.VUE_APP_COGNITO_CLIENT_ID,
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "cookieStorage": {
        expires: 1,
        path: "/",
        sameSite: "lax",
        secure: false,
        domain: process.env.VUE_APP_COOKIE
    }
};


export default awsmobile;
