<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <th class="text-center" style="width: 48px;">
                        <!-- <b-form-checkbox
                                v-model="selectAll"
                                name="checkbox-1"
                                button-variant="secondary"
                        >
                        </b-form-checkbox> -->
                    </th>
                    <th class="text-center">ID</th>
                    <th class="text-center">{{$t('Name')}}</th>
                    <th class="text-center">{{$t('Format')}}</th>
                    <th class="text-center">{{$t('Description')}}</th>
                    <th class="text-center">{{$t('Resource')}}</th>
                    <th class="text-center">{{$t('Required?')}}</th>
                    <th class="text-center">{{$t('Expiration?')}}</th>
                    <th class="text-center">{{$t('Hidden?')}}</th>
                    <th class="text-center">{{$t('Editable?')}}</th>
                    <th class="text-center">{{$t('Validate?')}}</th>
                    <th class="text-center">{{$t('Validator')}}</th>
                    <th class="text-center">{{$t('Template')}}</th>

                    <th class="text-center" style="min-width: 120px;max-width:140px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fetching">
                    <td colspan="100%">
                        <loading />
                    </td>
                </tr>

                <template v-for="item in list.results">
                    <tr v-if="init || page === list.current_page" :key="'requirement-'+item.id"  class="requirement-row">
                        <td>
                            <!-- <b-form-checkbox
                                    v-model="selected"
                                    name="checkbox-1"
                                    :value="item.id"
                            >
                            </b-form-checkbox> -->
                        </td>
                        <td class="text-center">
                            <router-link :to="{name:'requirements.edit', params:{requirement_id: item.id}}">
                                <b>{{item.id}}</b>
                            </router-link>
                        </td>
                        <td class="text-center">{{item.name}}</td>
                        <td class="text-center">{{item.format}}</td>
                        <td class="text-center">{{item.description}}</td>
                        <td class="text-center">{{$t(item.resource.charAt(0).toUpperCase() + item.resource.slice(1))}}</td>
                        <td class="text-center"><i :class="['far', item.required ?  'fa-check-circle text-success' : 'fa-times-circle text-danger']"></i></td>
                        <td class="text-center"><i :class="['far', item.expiration ?  'fa-check-circle text-success' : 'fa-times-circle text-danger']"></i></td>
                        <td class="text-center"><i :class="['far', item.hidden ?  'fa-check-circle text-success' : 'fa-times-circle text-danger']"></i></td>
                        <td class="text-center"><i :class="['far', item.editable ?  'fa-check-circle text-success' : 'fa-times-circle text-danger']"></i></td>
                        <td class="text-center"><i :class="['far', item.validation ?  'fa-check-circle text-success' : 'fa-times-circle text-danger']"></i></td>
                        <td class="text-center">
                            <span v-if="item.validation_provider">{{item.validation_provider}}</span>
                            <span v-else>--</span>
                        </td>
                        <td class="text-center">
                            <a v-if="item.template" :href="item.template"><i style="font-size: 20px;" class="far fa-file"></i></a>
                            <span v-else>--</span>
                        </td>

                        <td class="text-center">
                            <a v-can="'requirements.delete'" v-b-tooltip.hover :title="$t('Delete')" style="cursor: pointer;" @click="confirmDelete(item)" class="text-danger mr-2" >
                                <i class="far fa-trash-alt" style="font-size:20px;" />
                            </a>
                            <router-link v-can="'requirements.update'" class="mr-2" :to="{name:'requirements.edit', params:{requirement_id: item.id}}">
                                <i v-b-tooltip.hover :title="$t('Edit')" class="far fa-edit" style="font-size:20px;"></i>
                            </router-link>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div  v-if="list.total > 0  && init"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    import Loading from "../common/Loading";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import Swal from "sweetalert2";

    export default {
        name: "RequirementTable",
        mixins:[selectAllMixin],
        components: {
            'loading':Loading
        },
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            },
        },
        data(){
            return {
                page: 1,
                init: false,
                fetching: false,
                requirement: null,
                fetchingRequirement: false,
            }
        },
        computed: {
            ...mapState('requirements', ['last_filters', 'requirements']),
            ...mapState('profile',['profile']),
            list(){
                return this.requirements
            }
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
            this.fetchData();
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal)){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('requirements',['getRequirements','getRequirement','deleteRequirement']),
            fetchData(){
                this.fetching = true;
                this.getRequirements(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.fetching = false;
                        this.init = true;
                    })
            },
            show(item){
                this.fetchingLoad = true;
                this.getRequirement({id: item.id})
                    .then(data=>{
                    })
                    .catch(error =>{
                    })
                    .finally(error =>{
                        this.fetchingLoad = false;
                    })
            },
            confirmDelete(item){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteRequirement({id: item.id})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Requirement").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                    });
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error", 
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .requirement-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }
</style>
