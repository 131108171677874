<template>
    <div class="step-content">
      <a  class="cursor-pointer mx-2 btn-link" v-for="entry in steps"
          :class="[entry.step > current && !isCompleted(entry.step) ? 'disabled' : '']"
          @click="$emit('setStep', entry.step)">
        <i :class="entry.icon" class="mr-1" />
        <small class="ml-1">{{ $t(entry.name) }}</small>
      </a>
    </div>
</template>

<script>
/**
 * Este componente controla y renderiza los steps del wizard para la creacion de un envío
 */
export default {
    name: "Stepper",
    data() {
        return {
            activeClass: 'element active',
        }
    },
    props: {
        current: Number,
        isCompleted: Function,
        steps: Array
    }
}
</script>

<style scoped lang="scss">
.step-content {
    border-radius: 50%;
    border: solid 1px grey;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 40px;
    width: 700px;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    background-color: #FFFFFF;

    .element {
        display: flex;

        small {
            margin-left: 5px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .active {
        color: #00B3E6;
    }
}
</style>
