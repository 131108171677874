<template>
    <div class="row">
        <div class="col-md-12 mt-2 mb-2" style="text-align: center;">
            <img style="width: 50px;" src="/loading-image.gif">
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loading"
    }
</script>

<style scoped>

</style>
