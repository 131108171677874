<template>
    <div>
        <route-map style="width: calc(100% + 48px); height: 350px;top:-1px;left:-24px;right:-24px;margin-bottom: 24px;"
                   :load="shipment"></route-map>
        <div class="row" v-show="true">
            <div :class="'col-md-'+(12/statData.length)" :key="'stat-'+index" v-for="(item,index) in statData">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="media text-truncate">
                            <div class="media-body overflow-hidden">
                            <p class="text-truncate font-size-14 mb-0">{{$t(item.title)}}</p>
                            <h4 class="mb-0"  ><span v-b-tooltip.hover :title="item.help">{{item.value}}</span></h4>
                            </div>
                            <div class="text-secondary">
                            <i class=" font-size-24" :class="[item.icon]" ></i>
                            </div>
                        </div>
                    </div>
                    <div class="progress" >
                        <div class="progress-bar" role="progressbar" :style="{width: item.progress+'%'}" v-bind:aria-valuenow="item.progress" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <div class="card-body p-3">
                        <div class="text-truncate">
                            <h6 class="mb-0">{{$t(item.subtitle)}}</h6>
                            <span class="text-muted">{{item.subvalue}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-5">
                            <div class="row">
                              <div class="col-md-6">
                                <h5><i class="far mr-3" :class="[$route.meta.type === 'load'? 'fa-dolly-flatbed-alt':'fa-shipping-fast']" />
                                  {{$t($route.meta.type === 'load'? 'Load':'Shipment')}}: {{shipment.id}} 
                                  <span class="badge"  v-bind:class="[shipment.type=='RETURN' ? 'badge-outline-danger' : 'badge-outline-primary' ]">{{$t(shipment.type)}}</span>  
                                </h5>
                              </div>
                              <div class="col-md-6 text-right">

                                <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                                  <template #button-content>
                                    <i class="fa fa-ellipsis-v text-black-50"></i>
                                  </template>
                                  <b-dropdown-item
                                      v-can="'loads.update'"
                                      v-if="editableStatus.indexOf(shipment.status) > -1 || $route.params.profile === 'admin'"
                                      v-show="$route.params.profile !== 'carrier'"
                                      @click="setLinkAction({name: 'shipments.edit', params:{load_id: shipment.id}})">
                                      <i class="far fa-edit mr-2" />{{$t('Edit')}}
                                  </b-dropdown-item>
                                   <b-dropdown-item
                                       v-if="statusActive.length > 0 && (shipment.truck !== null || shipment.driver !== null)"
                                      @click="loadDispatch">
                                    <i class="far fa-check-circle mr-2" /> {{$t("Dispatch")}}
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                      v-if="['SHIPMENT','SHIPMENT_LEG'].indexOf(shipment.type) > -1 && shipment.tms_shipment_id"
                                      v-can="'loads.create'"
                                      v-show="['checkin_delivery','unloaded','unloading','delivered'].indexOf(shipment.status) > -1"
                                      @click="setLinkAction({name: 'loads.add',query:{type:'RETURN',parent_id:shipment.id}})">
                                    <i class="far fa-undo mr-2" /> {{$t("Create Return")}}
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                      v-can="'loads.create'"
                                      v-show="shipment.status=='in_transit'"
                                      @click="setLinkAction({name: 'loads.add', query:{type:'DIVERT_IN_TRANSIT',parent_id:shipment.parent_id}})">
                                    <i class="far fa-truck mr-2" /> {{$t("Create Divert in Transit")}}
                                  </b-dropdown-item>
                                </b-dropdown>
                              </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="field-wrapper border-bottom-none p-0">
                                                <div class="field-label">{{$t('TMS ID')}}</div>
                                               <div class="field-help">{{shipment.tms_shipment_id ? shipment.tms_shipment_id: '- -'}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="field-wrapper border-bottom-none p-0">
                                              <div class="field-label">{{$t('Tracking Number')}}</div>
                                              <div class="field-help">{{shipment.shipper_external_id? shipment.shipper_external_id: '- -'}}</div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="field-wrapper border-bottom-none p-0">
                                              <div class="field-label">{{$t('Project Name')}}</div>
                                              <div class="field-help">{{shipment.project_name}}</div>
                                          </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="field-wrapper border-bottom-none p-0">
                                                <div class="field-label">{{$t('Load type')}}</div>
                                                <div class="field-help">{{shipment.load_type ? shipment.load_type.name : '- -'}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="field-wrapper border-bottom-none p-0">
                                                <div class="field-label">{{$t('Configuration')}}</div>
                                                <div class="field-help">{{shipment.configuration ? shipment.configuration.name: '- -'}}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t('Truck type')}}</div>
                                        <div class="field-help"><i class="far fa-truck mr-2" />{{shipment.truck_type? shipment.truck_type.name: '- -'}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="field-wrapper border-bottom-none p-0">
                                                <div class="field-label">{{$t('Trailer type')}}</div>
                                                <div class="field-help"><i class="far fa-container-storage mr-2" />{{shipment.trailer_type ? shipment.trailer_type.name: '--'}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="field-wrapper border-bottom-none p-0">
                                                <div class="field-label">{{$t('Weight')}}</div>
                                                <div class="field-help"><i class="far fa-weight mr-2" />{{shipment.weight}} {{shipment.weight_unit}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="field-wrapper border-bottom-none p-0">
                                          <div class="field-label">{{$t('Container type')}}</div>
                                          <div class="field-help"><i class="far fa-container-storage mr-2" />{{shipment.container_type}}</div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="field-wrapper border-bottom-none p-0">
                                          <div class="field-label">{{$t('Commodity')}}</div>
                                          <div class="field-help">{{shipment.commodity ? shipment.commodity.name :  '- -'}}</div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                            </div>
                            <div class="row mt-4" v-if="shipment.carrier_skills.length > 0">
                                <div class="col-md-12">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t("Required carrier's skills")}}</div>
                                        <div class="field-help">
                                            <span class="badge badge-outline-info mr-2" :key="'skill-'+item.id" v-for="item in shipment.carrier_skills">{{item.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t('Additional information')}}</div>
                                        <div class="field-help">{{shipment.comments}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5" :key="'stop-detail-'+index" v-for="(stop,index) in stops">
                            <div class="">
                                <h5><i class="far mr-3 fa-location-circle" />{{$t("Stop")}} {{stop.order}}</h5>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t('Date')}}</div>
                                        <div class="field-help">{{stop.schedule_from | date }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{ stop.pick ? $t('Pickup schedule') : $t('Delivery schedule')}}</div>
                                        <div class="field-help"><i class="far fa-clock mr-2" />{{stop.schedule_from | time}} - {{stop.schedule_to | time}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t('Address')}}</div>
                                        <div class="field-help" :key="'pickup-line-'+index" v-for="(item,index) in getAddressFormat(stop.address)">{{item}}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t('References')}}</div>
                                        <div class="field-help">{{stop.address.description}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

              <div class="card" v-show="visible">
                <loading v-show="loading"></loading>
                <div class="card-body" v-show="!loading">

                  <h4 class="card-title mb-4">{{$t('Route log')}}</h4>

                  <div data-simplebar style="max-height: 400px;" v-show="activityData.length > 0">
                    <ul class="list-unstyled activity-wid">
                      <li class="activity-list" v-for="(data, index) in activityData" :key="index">
                        <div class="activity-icon avatar-xs">
                                        <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                          <i :class="`${data.icon}`"></i>
                                        </span>
                        </div>
                        <div>
                          <div>
                            <h5 class="font-size-13">
                              {{data.date | timestamp}}
                              <small class="text-muted">{{data.time}}</small>
                            </h5>
                          </div>
                          <div>
                            <badge-status v-if="false" :status="data.title" />
                            <div >{{data.description}}</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div v-show="activityData.length < 1">
                    <empty-results />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                  <div class="card-header">
                      <div class="d-flex justify-content-between mb-1">
                          <h4 class="card-title">{{$t('Shipper details')}}</h4>
                      </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-3  d-flex justify-content-center align-items-center ">
                          <img
                            v-if="shipment.shipper.logo"
                            class="rounded-circle"
                            :src="shipment.shipper.logo"
                            width="45" height="45" style="object-fit: cover;padding:0!important"
                          />
                          <vue-letter-avatar v-else :name="shipment.shipper.name" size='45' :rounded="true" />
                      </div>
                      <div class="col-9">
                        <p class="">{{ $t('Company name') }}</p>
                        <p class="h4">
                          <template v-if="$route.params.profile === 'admin'" >
                            <router-link target="_blank" :to="{name:'shippers.detail', params:{shipper_id: shipment.shipper.id}}">
                              {{ shipment.shipper.name }}
                            </router-link>
                          </template>
                          <template v-else>
                            {{ shipment.shipper.name }}
                          </template>
                          
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

            <div class="card" v-can="'shipments.showprice'" v-if="$route.params.profile !== 'driver'">
                <div class="card-header">
                    <div class="d-flex justify-content-between mb-1">
                        <h4 class="card-title">{{$t('Price')}}</h4>
                        <!--<button class="btn btn-success btn-sm"
                                @click="applyCarrierLoad"
                                v-can="'tenders.create'">
                            <i class="far fa-check-circle mr-2"></i>{{$t('Apply')}}
                        </button>-->
                        <button class="btn btn-success btn-sm"
                                @click="applyCarrierLoad"
                                v-can="'tenders.create'"
                                v-if="$route.params.profile === 'carrier' && shipment.tendered !== true"
                                v-show="shipment.status === 'not_assigned'">
                            <i class="far fa-check-circle mr-2"></i>{{$t('Apply')}}
                        </button>
                    </div>
                </div>
                <div class="card-body">

                  <div class="row">
                    <div class="col-sm-4">
                      <p>{{$t('Sale price')}}</p>
                    </div>
                    <div class="col-sm-8">
                      <h4 class="text-right text-primary">$ {{shipment.price | money}} {{shipment.currency}}</h4>
                    </div>
                  </div>

                  <!-- <div class="row" v-if="$route.params.profile === 'admin'" >
                    <div class="col-sm-4">
                      <p>{{$t('Purchase price')}}</p>
                    </div>
                    <div class="col-sm-8">
                      <h4 class="text-right text-primary">$ {{shipment.purchase_price | money}} {{shipment.currency}}</h4>
                    </div>
                  </div> -->

                </div>

              </div>


              <div v-if="profileForm && profile_fields" class="card">
                <div class="card-header">
                  <h6>{{$t('Custom fields')}}</h6>
                </div>
                <template v-for="(field, idx) in profileForm.fields">
                  <form-input
                      :key="'field-'+field.id"
                      :title="field.title"
                      :help="field.help"
                      v-model="profile_fields[field.id]"
                      :type="field.type"
                      :name="'field.'+idx+'.title'"
                      :options="field.options"
                      layout="vertical"
                  />
                </template>
                <div class="field-wrapper text-right">
                  <button type="button" @click="updateProfileFields" class="btn btn-primary btn-sm">
                    {{$t('Save')}}
                  </button>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapActions, mapState} from 'vuex';
    import addressFormat from '@/helpers/address-format';
    import RouteMap from "@/components/common/RouteMap";
    import eventIcons from "@/constants/eventIcons";
    import TruckInput from "@/components/form/TruckInput";
    import TrailerInput from "@/components/form/TrailerInput";
    import UserInput from "@/components/form/UserInput";
    import Multiselect from 'vue-multiselect';
    import SalaryAdvance from "@/components/common/SalaryAdvance";
    import Swal from "sweetalert2";


    export default {
        name: "ShipmentGeneral",
        components:{
          'route-map': RouteMap,
          TruckInput,
          TrailerInput,
          UserInput,
          Multiselect,
          SalaryAdvance,
        },
        data(){
            return {
                editableStatus : ['pending','published','not_assigned'],
                activityData   : [],
                visible : true,
                loading: true,
                form: {},
                profile_fields: {},
                payment: null
            }
        },
        computed:{
            ...mapState('profile', ['profile']),
            ...mapState('shipments',['shipment','events','stops','status']),
            ...mapState('forms',['customLoadForm','carrierLoadForm','shipperLoadForm','adminLoadForm']),
            progressStatus(){
                return (this.status.filter(item => item.completed).length * 100)/this.status.length;
            },
            progressCarrier(){
                let advance = 0;
                let step = 100/3;
                if(this.shipment.carrier && ['cancelled','not_assigned','pending'].indexOf(this.shipment.status) === -1){
                    advance += step;
                }
                if(this.shipment.truck){
                    advance += step;
                }
                if(this.shipment.driver){
                    advance += step;
                }
                return advance > 99 ? 100 : advance;
            },
            progressPickup(){
                if(!this.pickup)
                    return 0;
                let items = this.status.filter(item => item.stop_id === this.pickup.id);
                let advance = (items.filter(item => item.completed).length * 100) / items.length;
                return advance > 99 ? 100 : advance;
            },
            progressDelivery(){
                let stop = this.delivery;
                if(!stop)
                    return 0;
                let items = this.status.filter(item => item.stop_id === stop.id);
                let advance = (items.filter(item => item.completed).length * 100) / items.length;
                return advance > 99 ? 100 : advance;
            },
            pickup(){
                return  this.stops.length > 0 ? this.stops[0] : null
            },
            delivery(){
                return  this.stops.length > 1 ? this.stops[this.stops.length - 1] : null;
            },
            nextStatus(){
                let next = '- -';
                for(let item of this.status){
                    if(item.completed === false){
                        next = item.event;
                        break;
                    }
                }
                return next;
            },
            statData(){

                let card = [
                    {title: 'Status',icon:'far fa-ballot-check',progress: this.progressStatus, value: this.$t(this.shipment.status),
                            subtitle: 'Next event', subvalue: this.nextStatus}
                ];
                card.push({title: 'Pickup',icon:'far fa-dolly-flatbed-alt',progress: this.progressPickup, value: this.shipment.pickup.address.city, help: this.shipment.pickup.address.state,
                  subtitle: 'Pickup date', subvalue: this.shipment.pickup.date});
                card.push({title: 'Delivery',icon:'far fa-hand-holding-box',progress: this.progressDelivery, value: this.shipment.delivery.address.city, help: this.shipment.delivery.address.state,
                  subtitle: 'Delivery date', subvalue: this.shipment.delivery.date});

                switch(this.$route.params.profile){
                    case 'shipper':
                        if (this.shipment.shipper && (this.profile.id !== this.shipment.shipper.id) ) {this.visible = false; return [];}
                         this.visible = true;
                         return card;
                        break;
                    case 'carrier':
                        if(this.shipment.carrier === null) {this.visible = false; return [];}
                        if (this.profile.account && (this.profile.account.name !== this.shipment.carrier.name) ) {this.visible = false; return [];}
                        this.visible = true;
                        return card;
                    break;
                    case 'driver':
                        if (this.shipment.driver && (this.profile.id !== this.shipment.driver.id) ) {this.visible = false; return [];}
                         this.visible = true;
                         return card;
                    break;
                    case 'admin':
                        return card;
                    break;

                }
                return ''
            },
            statusActive(){
                let items = [];
                let indexNow    = this.status.findIndex(item => item.completed === false);
                if(indexNow == -1) return [];

                if(this.status[indexNow-1] !== undefined)
                    items.push(this.status[indexNow-1]);

                    items.push(this.status[indexNow]);

                if (this.status[indexNow+1] !== undefined)
                    items.push(this.status[indexNow+1]);

                let disabled = (items.length == 2)? [1] : [0,2];
                for(let i = 0; i < items.length; i++){
                    if(disabled.includes(i)){
                        if(items[i].status === 'delivered' && items.length < 3){
                            items[i-1]['disabled'] = true;
                            items[i]['class'] = 'text-primary';
                            items[i]['disabled'] = false;
                        }if(items.length < 3 && items[i].status !== 'delivered'){
                            items[i-1]['class'] = 'text-primary';
                            items[i]['disabled'] = true;
                            items[i-1]['disabled'] = false;
                        }
                    }
                    if(items.length === 3){
                        items[1]['class']       = 'text-primary';
                        items[1]['disabled']    = false;
                        items[i]['disabled']    = true;
                    }
                }
                return items;
            },
            isCompletedStatus(){
                if(this.shipment.status === 'delivered') return true;
                return false;
            },
          showTruckDriverForm(){
              if(!this.shipment)
                return false;
              if(this.$route.params.profile === 'admin' && this.shipment.carrier){
                return true;
              }
              if(this.$route.params.profile === 'shipper'){
                return this.shipment.shipper.id === this.profile.profile.id;
              }
              if(this.$route.params.profile === 'carrier' &&  this.shipment.carrier){
                return this.shipment.carrier.id === this.profile.profile.id;
              }
              if(this.$route.params.profile === 'driver' && this.shipment.driver){
                return this.shipment.driver.id === this.profile.profile.id;
              }
              return false;
          },
          profileForm(){
            if(this.$route.params.profile === 'admin'){
              return this.adminLoadForm;
            }else if(this.$route.params.profile === 'shipper'){
              return this.shipperLoadForm;
            }
            return null
          },
          viewDriverTruckAssigned() {
              return ['admin'].indexOf(this.$route.params.profile) > -1 ||
                     ['not_assigned','assigned', 'accepted'].indexOf(this.shipment.status) > -1
          }
        },
        created() {

              this.init();
              if(this.shipment.status === 'pod' || this.shipment.status==='paid' && this.shipment.carrier){
                if((this.$route.params.profile === 'carrier' &&
                    this.profile.profile.id === this.shipment.carrier.id) ||
                    this.$route.params.profile === 'admin')
                {
                  this.payment = true;
                }
              }
        },
        mounted() {
          Echo.private(`loads.${this.$route.params.load_id}`)
              .listen('NewLoadPosition', (e) => {
                if(this.shipment)
                  this.updatePosition({load_id: this.shipment.id, position: e.position });
              })
        },
      methods:{
          ...mapActions('shipments',['getEvents','storeTender','getLoad','changeStatus',
                                  'getStatus','updatePosition','updateLoadFields']),
            getAddressFormat(address){
                return addressFormat(address)
            },
            setLinkAction(data){
                this.$router.push(data);
            },
            init(){
              if(['carrier','shipper'].indexOf(this.$route.params.profile) > -1){
                this.profile_fields = this.shipment[`${this.$route.params.profile}_fields`];
              }
                  this.form.driver = this.shipment.driver;
                  this.form.truck  = this.shipment.truck;
                  this.form.trailer  = this.shipment.trailer;

                  this.getEvents({ id: this.$route.params.load_id}).then(data =>{
                      for (const event of this.events) {
                        this.activityData.push({
                          icon: eventIcons[event.status],
                          date: event.created_at,
                          time: event.name,
                          title: event.status,
                          description: event.description,
                          stop: event.stop
                        });
                      }
                      //this.getLoad({id : this.$route.params.load_id}).then(data => {}).catch(error => {});
                })
                .catch(error => {})
                .finally( () => {
                    this.loading = false;
                });
            },
            async onSubmit(observer){
              if(!(await observer.validate()) || this.loading){
                this.$bvToast.toast(this.$t('Please, check the form'), {
                  title: "Error",
                  variant: 'danger',
                  solid: true
                });
                return;
              }
              let form = {
                'id'        : this.$route.params.load_id,
                'driver_id' : this.form.driver.id,
                'truck_id'  : this.form.truck.id
              }
              if(this.form.trailer){
                form.trailer_id = this.form.trailer.id;
              }
              this.assignTruckAndDriver(form).then(data => {
                this.$root.$bvToast.toast(this.$t("Load has been updated success.", {resource: this.$t("Assign").toLowerCase()}), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });

              }).catch(error =>{
                this.$bvToast.toast(this.$t("Something went wrong!"), {
                  title: "Error",
                  variant: 'danger',
                  solid: true
                });
              }).finally( () => {
                this.getLoad({id : this.$route.params.load_id}).then(data => {}).catch(error => {});
              });

            },
            applyCarrierLoad(){
              Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("Are you sure to apply to this load?"),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, accepted it!"),
                cancelButtonText: this.$t("No")
              }).then(result => {
                if(result.value) {
                  this.storeTender({id : this.$route.params.load_id, price : this.shipment.price,currency: this.shipment.currency})
                      .then(data => {
                          this.$root.$bvToast.toast(
                              this.$t("The action was successful!", { resource: this.$t("Tenders").toLowerCase() }),
                              {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                              });
                      })
                      .catch(error => {
                          this.$bvToast.toast(this.$t("Something went wrong!"), {
                            title: "Error",
                            variant: 'danger',
                            solid: true
                          });
                      })
                      .finally( () => {
                        this.getLoad({id : this.$route.params.load_id}).then(data => {}).catch(error => {});
                      });
                }
              });

            },
            onChange(item){
                let payload = {
                    id      : this.$route.params.load_id,
                    stop_id : item.stop_id,
                    status  : item.status,
                };
                this.changeStatus(payload).then(data => {
                    this.$root.$bvToast.toast(
                      this.$t("The action was completed successfully"),
                      {
                        title: this.$t('Success'),
                        variant: 'success',
                        solid: true
                    });
                    this.getStatus({id : this.$route.params.load_id}).then(data => {}).catch(error => {});
                    this.getEvents({id : this.$route.params.load_id}).then(data => {}).catch(error => {});
                    this.getLoad({id : this.$route.params.load_id}).then(data => {}).catch(error => {});


                }).catch(error => {}).finally(() => {});
            },
            updateProfileFields(){
              let payload = {id: this.$route.params.load_id};
              payload[`${this.$route.params.profile}_fields`] = this.profile_fields;
              this.updateLoadFields(payload)
                  .then((data)=>{
                    this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                      title: this.$t('Success'),
                      variant: 'success',
                      solid: true
                    });
                  })
                  .catch(error =>{

                  })
            },
            loadDispatch()
            {
              // TODO: Llamada de despacho
              this.$root.$bvToast.toast(this.$t('Successfully dispatch'), {
                      title: this.$t('Success'),
                      variant: 'success',
                      solid: true
                    });
            }
        }
    }
</script>

<style lang="scss">
.progress{
  height: 10px!important;
  .progress-bar{
    background-color: linear-gradient(270deg, rgb(0, 179, 230) 0%, rgb(227, 237, 85) 100%)!important;
  }
}
</style>
