<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">{{ title }}</h4>

        <div class="page-title-right">
          <b-breadcrumb :items="breadcrumb" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed:{
    breadcrumb(){
      if(this.items.length > 0){
        return this.items;
      }
      let items = [];
      for(let item of this.$route.matched){
        if(item.meta.breadcrumb){
          let element = _.cloneDeep(item.meta.breadcrumb);
          element.text = element.param ? `${this.$route.params[element.param]}` :this.$t(element.text);
          items.push(element);
        }
      }
      return items;
    }
  }
}
</script>
