<template>
    <div class=" ">
        <div class="card card-txp">
            <div class="card-body text-center">
              <img class="montacargas" :src="require('@/assets/images/montacargas.png')" alt="montacargas">
              <router-link :to="{name:'loads'}" tag="button" class="btn btn-light btn-lg btn-block mt-2">
                    <b>¿Buscamos una carga?</b>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "Search",
    computed: {
        ...mapState("profile", ["profile"]),
    },
};

</script>

<style scoped>
    .opacity { opacity: 0.60; }
    .fz-14{ font-size: 13px; }
    .card-txp {
        min-height: 210px;
        border-radius: 15px;
        box-shadow: 1px 5px 3px #dcdcdc;
    }
    .c-right{
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .badge-white {
        color: #00B3E6;
        background-color: #ffffff;
        border: solid 1px #dcdcdc;
    }
    .montacargas{
      width: 50% ;
      max-width: 180px;
    }
    .btn-light{
        background-color: #F2F2F2!important;
        border-color: #F2F2F2!important;
    }
    .btn-light:hover{
        background-color: #e6e6e6!important;
        border-color: #e6e6e6!important;
    }
</style>
