<template>
    <div class="nopadding">
      <header-title :title="$route.name.indexOf('drivers') > -1 ? this.$t('Drivers'):  this.$t('Users')"  >
        <template v-slot:action-button>
          <div>
            <router-link v-can="'users.create'" v-b-tooltip.hover :to="addRoute" class="btn btn-primary_v2 font_bold">
              <i class="far fa-plus"></i> {{$t('Create user')}}
            </router-link>
          </div>
        </template>
        <template slot="buttons" v-if="false">
            <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getUsers(filters)" type="button" class="btn btn-light ml-2">
              <i class="far fa-sync"></i>
            </button>
            <button v-can="'users.delete'" v-bind:disabled="selected.length === 0" v-b-tooltip.hover :title="$t('Delete')" @click="confirmDelete" type="button" class="btn btn-light ml-2 font_bold">
              <i class="far fa-trash-alt"></i>
            </button>
            <button v-if="$route.name.indexOf('drivers') > -1" v-can="'users.create'" v-b-tooltip.hover :title="$t('Import')" @click="$refs.import.click()" type="button" class="btn btn-light ml-2 font_bold">
              <i class="far fa-file-import"></i>
            </button>
            <button v-b-tooltip.hover :title="$t('Export')" type="button" class="btn btn-light ml-2 font_bold" @click="download">
              <i class="far fa-file-spreadsheet"></i>
            </button>
            <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
        </template>
      </header-title>
        <div class="row my-3">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-12 px-4">
                        <filter-bar :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved">
                            <template v-if="!isDocumentModule" v-slot:right>
                                <div class="btn-group" role="group" aria-label="toolbar">
                                    <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getUsers(filters)" type="button" class="btn btn-light">
                                        <i class="far fa-sync"></i>
                                    </button>
                                    <router-link v-can="['users.create','drivers.create']" v-b-tooltip.hover :title="$t('Add')" :to="addRoute" class="btn btn-light">
                                        <i class="far fa-plus"></i>
                                    </router-link>
                                    <button v-can="'users.delete'" v-bind:disabled="selected.length === 0" v-b-tooltip.hover :title="$t('Delete')" @click="confirmDelete" type="button" class="btn btn-light">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                    <button v-if="$route.name.indexOf('drivers') > -1" v-can="['users.create','drivers.create']" v-b-tooltip.hover :title="$t('Import')" @click="$refs.import.click()" type="button" class="btn btn-light">
                                        <i class="fa fa-upload"></i>
                                    </button>
                                    <button v-b-tooltip.hover :title="$t('Export')" type="button" class="btn btn-light" @click="download">
                                        <i class="fa fa-download"></i>
                                    </button>
                                    <a v-if="$route.name.indexOf('drivers') > -1" v-b-tooltip.hover :title="$t('Layout')" type="button" class="btn btn-light" :href="urlTemplate">
                                        <i class="far fa-file-spreadsheet"></i>
                                    </a>
                                </div>
                                <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
                            </template>
                        </filter-bar>
                    </div>
                </div>

                <user-table class="mt-3 px-3" v-model="selected" :filters="filters" v-on:page-change="pageChange" :edit="editRouteName" />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import UserTable from "@/components/tables/UserTable";
    import FilterBar from "@/components/filters/FilterBar";
    import deleteManyMixin from "@/mixins/deleteMany";
    import filterMixin from "@/mixins/filterMixin";
    import HeaderTitle from "../../components/common/HeaderTitle";

    export default {
        name:'Users',
        components:{
          HeaderTitle,
            UserTable,
            FilterBar
        },
        mixins: [filterMixin, deleteManyMixin],
        data(){
            return {
                tabs: [],
                currentTab: null,
                options: [],
                selected: [],
                isDocumentModule: false,
                urlTemplate: `${process.env.VUE_APP_STATIC_URL}/layouts/template_drivers.xlsx`
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$route.name.indexOf('drivers') > -1 ? this.$t("Drivers"):  this.$t("Users"),
                        active: true,
                        to: {name: this.$route.name.indexOf('drivers') > -1 ? 'drivers.index' : 'users.index'}
                    }
                ]
            },
            userProfile(){
                let profile;
                if (this.$route.name.indexOf('drivers') > -1) {
                     profile =  "drivers"
                }

                if (this.$route.name.indexOf('users') > -1) {
                     profile = "users";
                }
                return profile;
            },
            addRoute(){
                if(this.$route.meta.routes){
                    return {name: this.$route.meta.routes.add};
                }
                return {name: `${this.userProfile}.add`}
            },
            editRouteName(){
                if(this.$route.meta.routes){
                    return this.$route.meta.routes.edit;
                }
                return null;
            },
            role(){
                if (this.$route.name.indexOf('drivers') > -1) {
                    return "driver";
                }
                if(this.$route.name.indexOf('staff') > -1){
                    if(this.$route.params.profile ==='admin'){
                        return 'staff';
                    }
                    return `${this.$route.params.profil}.user`
                }
                return 'all';
            },
        },
        created() {
            this.resourceName = this.$t("Users").toLowerCase();
            this.init();
        },
        watch:{
            '$route': 'init'
        },
        methods: {
            init(){
                this.isDocumentModule = this.$route.meta.isDocumentModule ? this.$route.meta.isDocumentModule : false

                if(this.$route.params.profile === 'admin' && !this.isDocumentModule){
                     this.options = [
                        {
                            icon: 'far fa-key',
                            label: 'ID',
                            type: 'text',
                            filterProperty: 'id',
                        },
                        {
                            icon: 'far fa-search',
                            label: this.$t('Search'),
                            type: 'text',
                            filterProperty: 'search',
                        },
                       {
                         icon: 'far fa-envelope',
                         label: this.$t('Email'),
                         type: 'text',
                         filterProperty: 'email',
                       },
                       {
                         icon: 'far fa-phone',
                         label: this.$t('Phone'),
                         type: 'text',
                         filterProperty: 'phone',
                       },
                        {
                            icon: 'far fa-users-class',
                            label: this.$t('Role'),
                            type: 'multiselect',
                            filterProperty: 'roles',
                            values: [
                                {text: this.$t('Shipper'), value: 'shipper'},
                                {text: this.$t('Carrier'), value: 'carrier'},
                                {text: this.$t('Driver'), value: 'driver'},
                                {text: this.$t('Admin'), value: 'admin'},
                            ],
                            valueType: 'number'
                        },
                    ];

                    if (this.$route.name.indexOf('drivers') > -1) {
                         this.options.splice(1,0,{
                            icon: 'far fa-key',
                            label: 'TMS ID',
                            type: 'text',
                            filterProperty: 'tms_id',
                         });
                    }
                }else{
                    this.options = [
                        {
                            icon: 'far fa-key',
                            label: 'ID',
                            type: 'text',
                            filterProperty: 'id',
                        }
                    ];
                }

                let filters = {};
                if(this.$route.name.indexOf('drivers') > -1 ){
                    filters = {roles: 'driver'};
                }

                if(this.$route.params.profile !== 'admin'){
                    filters['account_id'] = this.profile.account.id;
                }

                // Use param filters
                if(this.$route.meta.paramFilters){
                    for(let key of this.$route.meta.paramFilters){
                        filters[key] = this.$route.params[key];
                    }
                }
                if(this.$route.meta.filters){
                    filters = {...filters, ...this.$route.meta.filters};
                }

                this.defaultFilters = filters;


                let toolbarFilters = {};
                for(let item of this.options){
                    if(this.$route.query.hasOwnProperty(item.filterProperty)){
                        toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
                    }
                }
                this.toolbarFilters = toolbarFilters;
            },
            ...mapActions('users',['exportUsers','deleteManyUsers','getUsers','importUsers']),
            download(){
                this.exportUsers(this.filters)
                    .then(()=>{

                    })
                    .catch(error =>{

                    })

            },
            deleteMany(){
                return new Promise((resolve, reject)=>{
                    this.deleteManyUsers({ids: this.selected.join(',')})
                        .then(data=>{
                            this.selected = [];
                            this.getUsers(this.filters);
                            resolve(data);
                        })
                        .catch(error =>{
                            reject(error);
                        })
                })
            },
            handleFile(){
                let payload= {template : this.$refs.import.files[0]};
                if(this.defaultFilters.carrier_id){
                    payload.carrier_id = this.defaultFilters.carrier_id;
                }

                payload.role = this.role;
                this.importUsers(payload)
                     .then(data=>{
                        Echo.private(`jobs.${data.job_id}`)
                            .listen('JobProcessed', (e) => {
                                this.getUsers(this.filters);
                                Echo.leave(`jobs.${data.job_id}`);
                            });
                        this.$root.$bvToast.toast(this.$t("File uploaded successfully", {resource: this.resourceName}), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                                });

                    })
                    .catch(error =>{

                    })
                    .finally(()=>{
                        //this.$refs.import.reset();
                    });

            }
        }
    }
</script>

<style>

</style>
