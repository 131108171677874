<template>
  <div>
    <div class="row">
      <div class="col-12 py-4 text-right">
        <button @click="addExtraChargeClick"  v-if="['carrier','admin'].indexOf(role) > -1 && ['pod', 'delivery', 'paid'].indexOf(load.status) === -1" class="btn btn-primary">
          {{ $t("Add extra charge") }}
          <i class="far fa-plus-circle pl-1"></i>
        </button>
      </div>
      <div class="col-12 ">
        <div class="card cost_container">
          <div style="padding: 1rem 4rem" class="card-header hidden_sm">
            <div class="row align-items-center">
              <div class="col-1">{{ $t('ID') }}</div>
              <div class="col-1">{{ $t('Type') }}</div>
              <div class="col-2">{{ $t('Name') }}</div>
              <div class="col-2">{{ $t('Description') }}</div>
              <div class="col-2">{{ $t('Amount') }}</div>
              <div class="col-1">{{ $t('Date request') }}</div>
              <div class="col-1 text-center">{{ $t('Status') }}</div>
              <div class="col-2 text-right" >
                <button class="btn btn-sm btn-reload" @click="getData()" >
                  <i class="far fa-sync font-weight-bold"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body ">
            <extracost-table v-on:update_data="getData"></extracost-table>
          </div>
        </div>
      </div>
    </div>
    <extra-cost-modal  :load_id="load_id"  :types="['accessories']"   :visible="visible"    v-on:submit="submitAction"   v-on:close="visible = false"  />

  </div>
</template>

<script>
import ExtracostTable from "@/components/tables/ExtracostTable";
import {mapActions, mapState} from "vuex";
import ExtraCostModal from "@/components/modals/ExtraCostModal";
import {useEventsTracker} from "@/composables/analytics";
export default {
  name: "Extracosts",
  components: {
    ExtraCostModal,
    ExtracostTable
  },
  computed:{
    ...mapState('extracost',['expenses']),
    ...mapState('profile',['profile']),
    ...mapState('loads',['load']),
    role(){
      return this.profile.roles[0]
    }
  },
  created() {
    const { trackEvent } = useEventsTracker(this);
    this.trackEvent = trackEvent;
    trackEvent('extra_charges_tab', 'Load details', 'click');
    this.getData();
  },
  data() {
    return {
      visible:false,
      form: {},
      load_id: this.$route.params.load_id,
      filters:{}
    }
  },
  methods: {
    ...mapActions('extracost',['getExpenses','storeExpense','getExpensesByShipment']),
    getData(){
      if (this.role === 'shipper') {
        this.getExpensesByShipment({...this.filters, load_id: this.$route.params.load_id})
      } else {
        this.getExpenses({...this.filters, load_id: this.$route.params.load_id})
      }
    },
    submitAction(data){
      this.visible = false;
      this.getData();
    },
    addExtraChargeClick() {
       this.visible=true
       this.trackEvent('extra_charges_btn', 'Load details', 'click');
    }
  }
}
</script>

<style lang="scss">
.btn-reload{
  background: #dddddd;
  font-weight: bold;
}
.noborder tr th {
  border:none;
}
@media (min-width: 992px) {
  .hidden_sm{
    display: block;
  }
}
@media (max-width: 992px) {
  .hidden_sm{
    display: none;
  }
  .cost_container{
    border: 0!important;
    box-shadow: none!important;
    .card-body{
      padding: 0!important;
    }
  }
}
</style>