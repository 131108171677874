<template>
    <span>
        <async-filter v-if="filter.type === 'async'" v-on:remove="onRemove" :filter="filter" v-model="filterValue"  :search="filter.search"/>
        <date-filter v-if="filter.type === 'date'" :type="filter.dateType"  v-on:remove="onRemove" :filter="filter" v-model="filterValue" />
        <date-range-filter v-if="filter.type === 'date-range'" :type="filter.dateType" v-on:setDate="setDate"  v-on:remove="removeDate" :filter="filter" v-model="filterValue" />
        <multi-select-filter v-if="filter.type === 'multiselect'" v-on:remove="onRemove" :filter="filter" :options="filter.values" v-model="filterValue"/>
        <single-filter v-if="filter.type === 'single'" v-on:remove="onRemove" :filter="filter" v-model="filterValue" />
        <text-filter v-if="filter.type === 'text'" v-on:remove="onRemove" :filter="filter" v-model="filterValue" />
    </span>
</template>

<script>
import DateFilter from "./types/DateFilter";
import DateRangeFilter from "./types/DateRangeFilter";
import AsyncFilter from "./types/AsyncFilter";
import MultiSelectFilter from "./types/MultiSelectFilter";
import TextFilter from "./types/TextFilter";
import SingleFilter from "./types/SingleFilter";

export default {
    name: 'VueFilter',
    components:{
        DateFilter,
        DateRangeFilter,
        AsyncFilter,
        MultiSelectFilter,
        TextFilter,
        SingleFilter
    },
    props: {
        filter:{
            type: Object,
            required: true
        },
        value:{
            required:true
        }
    },
    data(){
        return {
            filterValue: null
        }
    },
    watch: {
        filterValue(newValue, oldValue){
            if (newValue) {
              this.$emit('input', newValue);
            }
        }
    },
    created() {
        this.filterValue = this.value;
    },
    methods: {
        getFormattedValue(){
            if(this.filter.type === 'date'){
                return this.filterValue;
            }
            if(this.filter.type === 'single'){
                let option = this.filter.values.find(item => item.value === this.filterValue);
                if(option)
                    return option.label;
                return '- -'
            }
            return this.filterValue;
        },
        onRemove(){
            this.$emit('remove');
        },
        setDate(date){
            this.$emit('setDateParent', date);
        },
        removeDate(){
            this.$emit('removeDateParent');
        }
    }
}
</script>

<style scoped>

</style>
