<template>
    <div>
        <route-map style="width: calc(100% + 48px); height: 350px;top:-1px;left:-24px;right:-24px;margin-bottom: 24px;"
                   :load="shipment"></route-map>
            <div class="row">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex justify-content-between">
                                <h4 class="card-title">{{$t('Shipper details')}}</h4>
                            </div>
                        </div>
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-3  d-flex justify-content-center align-items-center ">
                                    <img
                                        v-if="shipment.shipper.logo"
                                        class="rounded-circle"
                                        :src="shipment.shipper.logo"
                                        width="45" height="45" style="object-fit: cover;padding:0!important"
                                    />
                                    <vue-letter-avatar v-else :name="shipment.shipper.name" size='45' :rounded="true" />
                                </div>
                                <div class="col-9">
                                    <p class="">{{ $t('Company name') }}</p>
                                    <p class="h4">
                                    <template v-if="$route.params.profile === 'admin'" >
                                        <router-link target="_blank" :to="{name:'shippers.detail', params:{shipper_id: shipment.shipper.id}}">
                                        {{ shipment.shipper.name }}
                                        </router-link>
                                    </template>
                                    <template v-else>
                                        {{ shipment.shipper.name }}
                                    </template>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <div class="col-md-4" :key="'stat-'+index" v-for="(item,index) in statData">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="media text-truncate">
                            <div class="media-body overflow-hidden">
                            <p class="text-truncate font-size-14 mb-0">{{$t(item.title)}}</p>
                            <h4 class="mb-0"  ><span v-b-tooltip.hover :title="item.help">{{item.value}}</span></h4>
                            </div>
                            <div class="text-secondary">
                            <i class=" font-size-24" :class="[item.icon]" ></i>
                            </div>
                        </div>
                    </div>
                    <div class="progress" >
                        <div class="progress-bar" role="progressbar" :style="{width: item.progress+'%'}" v-bind:aria-valuenow="item.progress" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <div class="card-body p-3">
                        <div class="text-truncate">
                            <h6 class="mb-0">{{$t(item.subtitle)}}</h6>
                            <span class="text-muted">{{item.subvalue}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-5">
                            <div class="row">
                              <div class="col-md-6">
                                <h5><i class="far mr-3" :class="[$route.meta.type === 'load'? 'fa-dolly-flatbed-alt':'fa-shipping-fast']" />
                                  {{$t($route.meta.type === 'load'? 'Load':'Shipment')}}: {{shipment.id}} 
                                  <span class="badge"  v-bind:class="[shipment.type=='RETURN' ? 'badge-outline-danger' : 'badge-outline-primary' ]">{{$t(shipment.type)}}</span>  
                                </h5>
                              </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="field-wrapper border-bottom-none p-0">
                                                <div class="field-label">{{$t('Load type')}}</div>
                                                <div class="field-help">{{shipment.load_type ? shipment.load_type.name : '- -'}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="field-wrapper border-bottom-none p-0">
                                                <div class="field-label">{{$t('Configuration')}}</div>
                                                <div class="field-help">{{shipment.configuration ? shipment.configuration.name: '- -'}}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t('Truck type')}}</div>
                                        <div class="field-help"><i class="far fa-truck mr-2" />{{shipment.truck_type? shipment.truck_type.name: '- -'}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="field-wrapper border-bottom-none p-0">
                                                <div class="field-label">{{$t('Trailer type')}}</div>
                                                <div class="field-help"><i class="far fa-container-storage mr-2" />{{shipment.trailer_type ? shipment.trailer_type.name: '--'}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="field-wrapper border-bottom-none p-0">
                                                <div class="field-label">{{$t('Weight')}}</div>
                                                <div class="field-help"><i class="far fa-weight mr-2" />{{shipment.weight}} {{shipment.weight_unit}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="field-wrapper border-bottom-none p-0">
                                          <div class="field-label">{{$t('Container type')}}</div>
                                          <div class="field-help"><i class="far fa-container-storage mr-2" />{{shipment.container_type}}</div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="field-wrapper border-bottom-none p-0">
                                          <div class="field-label">{{$t('Commodity')}}</div>
                                          <div class="field-help">{{shipment.commodity ? shipment.commodity.name :  '- -'}}</div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                            </div>
                            <div class="row mt-4" v-if="shipment.carrier_skills.length > 0">
                                <div class="col-md-12">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t("Required carrier's skills")}}</div>
                                        <div class="field-help">
                                            <span class="badge badge-outline-info mr-2" :key="'skill-'+item.id" v-for="item in shipment.carrier_skills">{{item.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t('Additional information')}}</div>
                                        <div class="field-help">{{shipment.comments}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5" :key="'stop-detail-'+index" v-for="(stop,index) in stops">
                            <div class="">
                                <h5><i class="far mr-3 fa-location-circle" />{{$t("Stop")}} {{stop.order}}</h5>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t('Date')}}</div>
                                        <div class="field-help">{{stop.schedule_from | date }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{ stop.pick ? $t('Pickup schedule') : $t('Delivery schedule')}}</div>
                                        <div class="field-help"><i class="far fa-clock mr-2" />{{stop.schedule_from | time}} - {{stop.schedule_to | time}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t('Address')}}</div>
                                        <div class="field-help" :key="'pickup-line-'+index" v-for="(item,index) in getAddressFormat(stop.address)">{{item}}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="field-wrapper border-bottom-none p-0">
                                        <div class="field-label">{{$t('References')}}</div>
                                        <div class="field-help">{{stop.address.description}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between mb-1">
                            <h4 class="card-title">{{$t('Price')}}</h4>
                        </div>
                    </div>
                    <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4">
                        <p>{{$t('Purchase price')}}</p>
                        </div>
                        <div class="col-sm-8">
                        <h4 class="text-right text-primary">$ {{shipment.price | money}} {{shipment.currency}}</h4>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState} from 'vuex';
    import addressFormat from '@/helpers/address-format';
    import RouteMap from "@/components/common/RouteMap";


    export default {
        name: "MarketplaceGeneral",
        components:{
          'route-map': RouteMap,
        },
        data(){
            return {
                visible : true,
                loading: true,
            }
        },
        computed:{
            ...mapState('profile', ['profile']),
            ...mapState('shipments',['shipment','events','stops','status']),
            pickup(){
                return  this.stops.length > 0 ? this.stops[0] : null
            },
            delivery(){
                return  this.stops.length > 1 ? this.stops[this.stops.length - 1] : null;
            },
            statData(){

                let card = [
                    {title: 'Pickup',icon:'far fa-dolly-flatbed-alt',progress: this.progressPickup, value: this.shipment.pickup.address.city, help: this.shipment.pickup.address.state,
                    subtitle: 'Pickup date', subvalue: this.shipment.pickup.date},
                    {title: 'Delivery',icon:'far fa-hand-holding-box',progress: this.progressDelivery, value: this.shipment.delivery.address.city, help: this.shipment.delivery.address.state,
                    subtitle: 'Delivery date', subvalue: this.shipment.delivery.date}
                ];
                return card;
            },
        },
      methods:{
            getAddressFormat(address){
                return addressFormat(address)
            },
            setLinkAction(data){
                this.$router.push(data);
            }
        }
    }
</script>

<style lang="scss">
.progress{
  height: 10px!important;
  .progress-bar{
    background-color: linear-gradient(270deg, rgb(0, 179, 230) 0%, rgb(227, 237, 85) 100%)!important;
  }
}
</style>
