<template>
    <div class="row">
      <div class="col-6">
        <div class="card mt-5">
          <div class="card-body">
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('ID')}}</div>
                                <div class="field-help">{{ resource.id }}</div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('External ID')}}</div>
                                <div class="field-help">{{ resource.external_id }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="field-wrapper border-bottom-none p-0">
                        <div class="field-label">{{$t('Plate')}}</div>
                        <div class="field-help">{{ resource.plate }}</div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Model')}}</div>
                                <div class="field-help">{{ resource.model }}</div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Year')}}</div>
                                <div class="field-help">{{ resource.year }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="field-wrapper border-bottom-none p-0">
                        <div class="field-label">{{$t('Container type')}}</div>
                        <div class="field-help">{{ resource.type ? resource.type.name : '--' }}</div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Brand')}}</div>
                                <div class="field-help">{{ resource.brand }}</div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Configuration')}}</div>
                                <div class="field-help">{{ resource.configuration ? resource.configuration.name : '--' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="field-wrapper border-bottom-none p-0">
                        <div class="field-label">{{$t('Document status')}}</div>
                        <div class="field-help">
                            <span class="badge badge-outline-info">{{ $t(resource.document_status) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Driver')}}</div>
                                <div class="field-help">{{ resource.driver ? resource.driver.name : '--' }}</div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Weight')}}</div>
                                <div class="field-help">{{ resource.weight + ' ' + resource.weight_unit }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

    import {mapActions, mapState} from 'vuex';

    export default {
        name: "TruckGeneral",
        components:{

        },
        data(){
            return {

            }
        },
        computed: {
            ...mapState('requirements', ['resource'])
        }
    }
</script>

<style>

</style>
