<template>
    <div>
        
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <th class="text-center">ID</th>
                    <th class="text-center">{{ $t('Value') }}</th>
                    <th class="text-center">{{ $t('Text') }}</th>
                    <th class="text-center">{{ $t('Type') }}</th>
                    <th class="text-center">{{ $t('Enabled') }}</th>
                    <th class="text-center">{{ $t('Hidden') }}</th>
                    <th class="text-center">{{ $t('Created at') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fetching">
                    <td colspan="100%">
                        <loading />
                    </td>
                </tr>

                <template v-for="item in list.results">
                    <tr v-if="init || page === list.current_page" :key="'catalog-'+item.id"  class="catalog-row">
                        <td class="text-center">
                            <b>{{item.id}}</b>
                        </td>

                        <td class="text-center">
                            {{ item.value }}
                        </td>

                        <td class="text-center">
                            {{ item.text }}
                        </td>

                        <td class="text-center">
                            {{ item.type }}
                        </td>

                        <td class="text-center">
                            <span :class="['badge ', item.enabled ? 'badge-outline-success' : 'badge-outline-danger']">
                                {{ item.enabled ? $t('Yes') : $t('No')}}
                            </span>
                        </td>

                        <td class="text-center">
                            <span :class="['badge ', item.hidden ? 'badge-outline-success' : 'badge-outline-danger']">
                                {{ item.hidden ? $t('Yes') : $t('No')}}
                            </span>
                        </td>

                        <td class="text-center">
                            {{ item.created_at | timestamp('DD-MMMM-YYYY hh:mm')}}
                        </td>

                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div  v-if="list.total > 0 && init"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";

    export default {
        name: "Catalogs",
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            },
        },
        data(){
            return {
                page: 1,
                init: false,
                fetching: false
            }
        },
        computed: {
            ...mapState('catalogs', ['last_filters', 'catalogList']),
            ...mapState('profile',['profile']),
            list(){
                return this.catalogList
            }
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
            this.fetchData();
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal)){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('catalogs',['getCatalogList']),
            fetchData(){
                this.fetching = true;
                this.getCatalogList(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.fetching = false;
                        this.init = true;
                    })
            },
        }
    }
</script>

<style scoped>
    .catalog-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }
</style>
