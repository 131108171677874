import axios from "../../api/client";


// initial state
export const state = () => ({
    products : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    product: {
        images:[],
        category:{
            name:""
        }
    },
    last_filters: {},
    categories: [],
});

// getters
export const getters = {};

// actions
export const actions = {
    searchProducts({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/products', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    fetchProducts({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/sellers/2/products', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getProducts({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/sellers/2/products',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setProducts', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeProduct({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/sellers/2/products`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getProduct({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/sellers/2/products/${params.id}`)
                .then(response=>{
                    commit('setProduct', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getCategories({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/products/categories`)
                .then(response=>{
                    commit('setCategories',response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateProduct({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/sellers/2/products/${payload.id}`, payload)
                .then(response=>{
                    let products = _.cloneDeep(state.products);
                    let index = products.results.findIndex(item => item.id === response.data.id);
                    if(index > -1){
                        products.results[index] = response.data;
                        commit('setProducts', products);
                    }
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteProduct({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/products/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteManyProducts({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/products/bulk`,{params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteFile({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/upload/${params.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                });
        });
    },
};

// mutations
export const mutations = {
    setProducts(state, data){
        state.products = data;
    },
    setProduct(state, data){
        state.product = data;
    },
    setCategories(state, data){
        state.categories = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    }
};

