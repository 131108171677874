<template>
  <div class="">
    <slot name="content" ></slot>
  </div>
</template>

<script>
export default {
  name: "wizardStep",
}
</script>

<style scoped>

</style>