<template>
  <div class="header_date">
    <div class="header_date_title">
      {{ $t("Final route") }}
    </div>
    <div class="header_date_brand"></div>
    <div class="header_date_container">
      <div class="header_date_container_icon">
        <div class="header_date_container_icon_item" :class="pickupAddressValid ? '' : 'disabled'">
          <div class="header_date_container_icon_item_icon"><i class="fa fa-map-marker" /></div>
        </div>
        <div class="header_date_container_icon_item " :class="deliveryAddressValid ? '' : 'disabled'">
          <div class="header_date_container_icon_item_icon"><i class="fa fa-map-marker" /></div>
        </div>
      </div>
      <div class="header_date_container_title">
        <div class="header_date_container_title_item" :class="pickupAddressValid ? '' : 'disabled'">
          <div class="header_date_container_title_item_title font_semi_bold">{{ $t("Origin address") }}</div>
        </div>
        <div class="header_date_container_title_item " :class="deliveryAddressValid ? '' : 'disabled'">
          <div class="header_date_container_title_item_title font_semi_bold">{{ $t("Destination address") }}</div>
        </div>
      </div>
      <div class="header_date_container_banner">
        <div class="header_date_container_banner_item" :class="pickupAddressValid ? '' : 'disabled'">
          <div class="header_date_container_banner_item_circle"></div>
          <div class="header_date_container_banner_item_line first"></div>
        </div>
        <div class="header_date_container_banner_center">
          <div class="header_date_container_banner_center_line"></div>
        </div>
        <div class="header_date_container_banner_item " :class="deliveryAddressValid ? '' : 'disabled'">
          <div class="header_date_container_banner_item_circle"></div>
          <div class="header_date_container_banner_item_line"></div>
        </div>
      </div>
      <div class="header_date_container_label">
        <div class="header_date_container_label_item">
          <div class="header_date_container_label_item_title two-lines edit">
            <span>
              {{ pickupAddress }}
            </span>
            <a href="">Editar</a>
          </div>
        </div>
        <div class="header_date_container_label_item">
          <div class="header_date_container_label_item_title edit">
            <span>
              {{ deliveryAddress }}
            </span>
            <a v-if="deliveryAddressValid" href="">Editar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from '@/helpers/address-format';

export default {
  name: "HeaderAddress",
  props: {
    origin: {
      type: Object
    },
    destination: {
      type: Object
    }
  },
  computed: {
    pickupAddress() {
      if (this.origin && this.origin.address) {
        return format(this.origin.address).join(" ")
      } else {
        return this.$t('Empty data')
      }
    },
    deliveryAddress() {
      if (this.destination && this.destination.address) {
        return format(this.destination.address).join(" ")
      } else {
        return this.$t('Empty data')
      }
    },
    pickupAddressValid() {
      return this.origin && this.origin.address;
    },
    deliveryAddressValid() {
      return this.destination && this.destination.address;
    }
  }
}
</script>

<style scoped lang="scss">
.edit {
  display: flex;
  flex-direction: column;
}

.header_date {
  width: 100%;
  background-image: url("https://media.gettyimages.com/videos/gray-colored-graphic-design-background-animation-video-id1171926232?s=640x640");
  height: 190px;
  border-radius: 20px;
  border: 2px solid #e1dede;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px 5px;


  &_container {
    width: 100%;
    height: 100%;

    &_banner {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 40px;

      &_center {
        width: calc(100% - 600px);
        display: flex;
        justify-content: center;
        align-items: center;

        &_line {
          width: 100%;
          height: 2px;
          border-top: 2px dashed #979797;
        }
      }

      &_item {
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: relative;

        &.disabled {
          .header_date_container_banner_item_circle {
            background: #888888a6;
          }
        }

        &_circle {
          width: 10px;
          height: 10px;
          background: #00B3E6;
          border-radius: 50%;
        }

        &_line {
          width: calc(50% - 14px);
          height: 2px;
          border-top: 2px dashed #979797;
          position: absolute;
          left: 5px;

          &.first {
            left: calc(50% + 10px);
          }
        }
      }
    }

    &_icon {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;

      &_item {
        width: 300px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &.disabled {
          i {
            color: #cccccc;
          }
        }

        i {
          color: #00B3E6;
          font-size: 20px;
        }
      }
    }

    &_title {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;

      &_item {
        width: 300px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &.disabled {
          .header_date_container_title_item_title {
            color: #888888a6;
          }
        }

        &_title {
          font-size: 15px;
          font-weight: bold;
        }
      }
    }

    &_label {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;

      &_item {
        width: 300px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &_title {
          text-align: center;
          color: #888888;

          a {
            font-weight: bold;
          }
        }
      }
    }
  }

  &_title {
    background: #00B3E6;
    color: white;
    position: absolute;
    min-width: 200px;
    font-weight: bold;
    text-align: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    line-height: 25px;
    left: calc(50% - 100px);
    top: -10px;
    z-index: 100;

    &::before {
      content: "";
      left: -10px;
      top: 0;
      z-index: 99;
      position: absolute;
      transform: rotate(270deg);
      width: 0;
      height: 0;
      border-right: 5px solid transparent;
      border-top: 5px solid transparent;
      border-left: 5px solid #66666675;
      border-bottom: 5px solid #66666675;
    }

  }
}
</style>