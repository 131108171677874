<template>
  <div>
    <div class="">
      <table class="table mb-0">
        <thead>
        <tr class="">
          <th class="text-center">{{$t('Created at')}}</th>
          <th class="text-center">{{$t('Type')}}</th>
          <th class="text-center">{{$t('Status')}}</th>
          <th class="text-center">{{$t('Beneficiary')}}</th>
          <th class="text-center">{{$t('Bank')}}</th>
          <th class="text-center">{{$t('Amount')}}</th>
          <th class="text-center" style="min-width: 170px;max-width:200px;">
            {{$t('Actions')}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="fetching">
          <td colspan="100%">
            <loading />
          </td>
        </tr>
        <template v-for="item in list.results">
          <tr v-if="init || page === list.current_page" :key="'load-'+item.id"  class="truck-row"
                @mouseover="transfer_over = item.id"
                @mouseleave="transfer_over = null"
          >
            <td class="text-center">{{item.created_at | timestamp}}</td>
            <td class="text-center">
                <span class="badge badge-outline-primary">
                    {{item.method ? $t('transfer_types.'+item.method.type) : 'unknown'}} 
                </span>
            </td>
            <td class="text-center">
                <span class="badge mr-1 " :class="[statusColor[item.status]]">
                                    {{$t('status.transfer.'+item.status)}}
                </span>
            </td>
            <td class="text-center">
              <template v-if="item.method && item.method.type === 'h2h'">
                {{item.method.beneficiary_name}}
              </template>
            </td>
            <td class="text-center">
              <template v-if="item.method">
                {{item.method.type === 'template' ? item.method.bank :'- -'}}
              </template>
            </td>
            <td class="text-center">$ {{item.amount | money}} {{item.currency}}</td>
            <td>
                <template v-if="item.method && transfer_over === item.id">
                    <b-dropdown  variant="link" right class="m-0" >
                      <template #button-content>
                        <i class="fa fa-ellipsis-v text-black-50"></i>
                      </template>
                      <b-dropdown-item :disabled="item.status !== 'in_progress'" @click="confirm(item, true)">{{$t('Complete')}}</b-dropdown-item>
                      <b-dropdown-item :disabled="item.status !== 'in_progress'" @click="confirm(item, false)">{{$t('Decline')}}</b-dropdown-item>
                      <b-dropdown-item :disabled="item.method.type !== 'template'" @click="downloadFile(item.method.layout)">{{$t('Download')}}</b-dropdown-item>
                    </b-dropdown>
                    <a v-if="transfer_id === item.id" href="javascript:void(0);" @click="transfer_id = null">
                        <i class="far fa-chevron-circle-up btn-icon"></i>
                    </a>
                    <a v-else href="javascript:void(0);" @click="showRow(item)">
                        <i class="far fa-chevron-circle-down btn-icon"></i>
                    </a>
                </template>

            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div  v-if="list.total > 0"  class="row align-items-center">
      <div class="col-md-6 col-sm-6 ">
        <b-pagination
            v-model="page"
            :total-rows="list.total"
            :per-page="list.per_page"
            aria-controls="my-table"
            first-number
            last-number
            pills
        ></b-pagination>
      </div>
      <div class="col-md-6 col-sm-6 text-sm-right">
        <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
      </div>
    </div>
    <div v-if="list.total === 0 && !fetching" class="row">
      <div class="col">
        <empty-results />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState, mapActions} from "vuex";
import Loading from "../common/Loading";
import selectAllMixin from "@/mixins/selectAllMixin";
import Swal from "sweetalert2";

export default {
  name: "TransferTable",
  mixins:[selectAllMixin],
  components: {
    'loading':Loading
  },
  props: {
    filters : {
      type: Object,
      default(){
        return {}
      }
    },
    edit: {
      type: String
    },
  },
  data(){
    return {
      page: 1,
      init: false,
      statusColor: {
        "declined": "badge-outline-danger",
        "expired": "badge-outline-danger",
        "in_progress": "badge-outline-warning",
        "pending": "badge-outline-primary",
        "completed": "badge-outline-success"
      },
      methodColor:{
        "unknown": "badge-outline-light",
        "template": "badge-outline-primary",
        "host_to_host":"badge-outline-primary"
      },
      transfer_over: null,
      transfer_id: null
    }
  },
  computed: {
    ...mapState('transfers', ['transfers','fetching']),
    ...mapState('profile',['profile']),
    list(){
      return this.transfers
    }
  },
  created() {
    this.page = this.filters.page ? Number(this.filters.page) : 1;
  },
  mounted() {
    if(Object.keys(this.filters).length > 0){
      //this.fetchData();
    }
  },
  watch: {
    filters : {
      deep: true,
      handler(newVal, oldVal){
        if(!_.isEqual(newVal, oldVal) || this.init === false){
          this.fetchData();
        }
      }
    },
    page(newVal){
      this.$emit('page-change', newVal);
    }
  },
  methods: {
    ...mapActions('transfers',['getTransfers','complete','decline']),
    ...mapActions('common',['downloadFile']),
    fetchData(){
      this.getTransfers(_.cloneDeep(this.filters))
          .then(response=>{
          })
          .catch(error =>{
          })
          .finally(()=>{
            this.init = true;
          })
    },
    showRow(item){
      this.transfer_id = item.id;
    },
    confirm(item, complete){
      Swal.fire({
        title: this.$t("Confirm"),
        text: complete ? this.$t("I want to complete the transfer"):this.$t("I want to decline the transfer"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, continue"),
        cancelButtonText: this.$t("Cancel"),
        reverseButtons: true
      }).then(result => {
        if(result.value) {
          if(complete){
            this.complete({transfer_id: item.id})
                .then(data=>{
                  this.fetchData();
                  this.$root.$bvToast.toast(this.$t(`The action was completed successfully`), {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true
                  });
                })
                .catch(error=>{
                  Swal.fire(
                      "Error",
                      this.$t("Something went wrong!"),
                      "error"
                  );
                });
          }else{
             this.decline({transfer_id: item.id})
                .then(data=>{
                  this.fetchData();
                  this.$root.$bvToast.toast(this.$t(`The action was completed successfully`), {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true
                  });
                })
                .catch(error=>{
                  Swal.fire(
                      "Error",
                      this.$t("Something went wrong!"),
                      "error"
                  );
                });           
          }

        }
      });
    },
    confirmDecline(item){

    }
  }
}
</script>

<style scoped>
.truck-row>td{
  vertical-align: middle !important;
  height: 50px!important;
  max-height: 50px!important;
  text-align: center !important;
}
</style>
