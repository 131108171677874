<template >
  <div class="">
    <div>
      <div v-if="type==='minute'" id="picker-pointer-container">
        <picker-points v-for="(m,i) in MINUTES" v-if="i%5===0" :index="i" :key="i" :angle="6*i" :handle-time-change="handleTimePointerClick" :picked="i===minute"></picker-points>
      </div>
      <div v-else id="picker_pointer_container">
        <picker-points v-for="(h,i) in TWELVE_HOURS" :n="h" :i="i"  :index="h" :key="interval==='AM'?i:i+12" :angle="30*i" :handle-time-change="setHour" :picked="interval==='AM'?i===hour:(i+12)===hour"></picker-points>
      </div>
    </div>
    <div class="interval-container">
      <span class="time-picker-interval am" @click="handleIntervalChange('AM')" :class="{active:interval==='AM'}">AM</span>
      <span class="time-picker-interval pm" @click="handleIntervalChange('PM')" :class="{active:interval==='PM'}">PM</span>
    </div>
  </div>

</template>

<script>
import PickerPoints from './PickerPoints'
import {
  MINUTES,
  HOURS,
  TWELVE_HOURS
} from './constValues.js'
export default {
  props: {
    type: {
      type: String,
      default: 'hour'
    },
    mode: {
      type: [Number, String],
      default: 24
    },
    handleTimePointerClick: {
      type: Function,
      required: true,
      default: () => {}
    },
    hour: {
      type: [Number, String],
      default: 0
    },
    minute: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      MINUTES,
      HOURS,
      TWELVE_HOURS,
      interval: this.hour < 12 ? 'AM' : 'PM'
    }
  },
  components: {
    PickerPoints
  },
  methods: {
    handleIntervalChange(itv) {
      this.interval = itv;
    },
    setHour(e) {
      if (e === 12){
        this.interval === 'AM' ? this.handleTimePointerClick(24) :  this.handleTimePointerClick(12);
      }else{
        this.handleTimePointerClick(this.interval === 'AM' ? e : e+12)
      }
    }
  }
}
</script>

<style lang="css">
#picker-pointer-container {
  opacity: 1;
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

#picker-pointer-container.animation {
  opacity: 0;
  transform: scale3d(0.85, 0.85, 1);
}

.interval-container {
  position: absolute;
  bottom: -20px;
  width: 100%;
  height: 20px;
}

.time-picker-interval {
  cursor: pointer;
  position: absolute;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif!important;
  font-weight: 700!important;
  font-size: 12px;
  background: #f2f2f2;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-picker-interval.pm{
  right: 0;
  position: absolute;
}
.time-picker-interval.am{
  left: 0;
  position: absolute;
}



.time-picker-interval.active {
  color: #00B3E6;
  cursor: default;
}
.time-picker-interval:hover {
  color: #00B3E6;
}
</style>
