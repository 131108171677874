<template >
    <div class="card mb-3">
        <div class="row no-gutters">
            <div class="col-7">
                <div class="card-body pl-4">
                    <div class="card-title font-weight-bold d-flex justify-content-start align-items-center">
                        <i class="ri-lock-fill text-primary mr-2 font-size-22" />
                        <span class="font-size-22">{{$t('Merchandise insurance')}}</span>
                    </div>
                    <div class="text-muted font-size-14">
                        <i class="ri-check-line"></i>
                        {{$t('Max coverage')}} 500,000 dls.
                    </div>
                    <div class="text-muted font-size-14">
                        <i class="ri-check-line"></i>
                        {{$t('Cost 6% of invoice value')}}
                    </div>
                </div>
            </div>
            <div class="col-4 card-body my-1" v-if="!estimated_cost">
                <validation-observer ref="observer" v-slot="{ validate }">
                    <b-form @submit.stop.prevent="calculate(validate)">
                        <validation-provider name="weight" rules="required" v-slot="{ errors }">
                            <b-form-input
                                v-model="invoice_value"
                                id="invoice_value"
                                type="number"
                                min="0"
                                max="500000"
                                step="any"
                                placeholder="Ingrese el valor factura MXN"
                                class="form-control form-control-sm"
                            />
                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                        </validation-provider>
                        <button
                            type="subit"
                            class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder btn-sm btn-block mt-2"
                        >{{$t('Add')}}</button>
                    </b-form>
                </validation-observer>
            </div>
            <div class="col-4 card-body ml-3 my-2" v-else>
                <div class="font-weight-bold text-primary text-center">
                    <span class="font-size-28">${{estimated_cost | money}}</span>
                    <span class="font-size-12 ml-1">{{rate.currency}}</span>
                </div>
                <button
                    type="button"
                    class="btn btn-outline-light text-uppercase font-size-12 font-weight-bolder btn-sm btn-block mt-2"
                    @click="remove"
                >
                    {{$t('Delete')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: "InsuranceCard",
    data() {
        return {
            PERCENT_COST: 6,
            invoice_value: null,
        }
    },
    computed: {
        ...mapGetters('marketplace', ['accesories', 'rate']),
        estimated_cost() {
            let insurance = this.accesories.find(e => e.code === 'O_INSURANCE');
            return insurance ? insurance.amount : null ;
        }
    },
    methods: {
        ...mapMutations('marketplace', ['setAccesories', 'setConsignmentValue']),
        remove(){
            let copy_accesories = _.cloneDeep(this.accesories);
            let filter = copy_accesories.filter(e => e.code !== 'O_INSURANCE');
            this.setAccesories(filter);
            this.invoice_value = null;
            this.setConsignmentValue(0);
        },
        async calculate(validate) {
            if(!(await validate())){
                return;
            }
            let amount = this.invoice_value * (this.PERCENT_COST / 100);
            let copy_accesories = _.cloneDeep(this.accesories);
            copy_accesories.push({code:'O_INSURANCE', name: 'Merchandise insurance', quantity: 1, amount: amount});
            this.setAccesories(copy_accesories);
            this.setConsignmentValue(this.invoice_value);
        },
    },
}
</script>

<style scoped>
.card{
    max-width: 640px;
    border-radius: 20px;
}

input, input::placeholder {
    font: 14px/1 sans-serif;
}

</style>
