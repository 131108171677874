<template>
    <div class="card mb-3">
        <div class="row no-gutters">
            <div class="col-7">
                <div class="card-body pl-4">
                    <div class="card-title font-weight-bold d-flex justify-content-start align-items-center">
                        <i class="ri-award-fill text-primary mr-2 font-size-22" />
                        <span class="font-size-22">{{$t('Fumigation certificate')}}</span>
                    </div>
                    <div class="text-muted font-size-14">
                        <i class="ri-check-line"></i>
                        Lorem ipsum dolor sit amet
                    </div>
                    <div class="text-muted font-size-14">
                        <i class="ri-check-line"></i>
                        Excepteur sint occaecat cupidatat
                    </div>
                </div>
            </div>
            <div class="col-md-5 card-body" style="overflow: hidden">
                <div class="ribbon">{{$t('Best price')}}</div>
                <div class="text-muted font-size-14">Precio</div>
                <div class="text-primary font-size-18 font-weight-bold">
                    ${{CERTIFICATE_COST | money}} <span class="font-size-12">{{rate.currency}}</span>
                </div>
                <button
                    type="button"
                    class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder btn-sm btn-block mt-2 mw-85"
                    @click="add"
                    v-if="!certificate"
                >
                    {{$t('Add')}}
                </button>
                <button
                    type="button"
                    class="btn btn-outline-light text-uppercase font-size-12 font-weight-bolder btn-sm btn-block mt-2 mw-85"
                    @click="remove"
                    v-else
                >
                    {{$t('Delete')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: "CertificateCard",
    data() {
        return {
            CERTIFICATE_COST: 1200,
        }
    },
    computed: {
        ...mapGetters('marketplace', ['accesories', 'rate']),
        certificate(){
            return this.accesories.find(e => e.code === 'O_DESINFEFUMIGAR');
        }
    },
    methods: {
        ...mapMutations('marketplace', ['setAccesories']),
        add(){
            let copy_accesories = _.cloneDeep(this.accesories);
            copy_accesories.push({name: 'Fumigation certificate', code: 'O_DESINFEFUMIGAR', quantity: 1, amount: this.CERTIFICATE_COST});
            this.setAccesories(copy_accesories);
        },
        remove(){
            let copy_accesories = _.cloneDeep(this.accesories);
            let filter = copy_accesories.filter(e => e.code !== 'O_DESINFEFUMIGAR');
            this.setAccesories(filter);
        },
    },
}
</script>

<style scoped>
.card{
    max-width: 640px;
    border-radius: 20px;
}


.font-size-28{
    font-size: 28px;
}

.ribbon {
    width: 160px;
    height: 32px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    box-shadow: 0px 2px 3px rgb(136 136 136 / 25%);
    background: #00B3E6;
    transform: rotate(40deg);
    position: absolute;
    right: -42px;
    top: 17px;
    padding-top: 7px;
}

.mw-85{
    max-width: 85%;
}

</style>
