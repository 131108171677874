<template>
  <div class="field-wrapper" >
    <div class="row align-items-center ">
      <div class="col-md-6">
        <div class="field-label">
          {{$t("Address")}}
        </div>
        <div class="field-help">{{$t("Select or search saved address")}}</div>
      </div>
      <div class="col-md-6">
        <multiselect v-model="selected" :options="options" :custom-label="getAddressFormat"
                     layout="vertical" :searchable="true" @search-change="search"  :loading="isLoading" :preserve-search="true"
                     :placeholder="$t('Select')" select-label="" selected-label="" :preselect-first="true" >
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapActions} from "vuex";
import addressFormat from '@/helpers/address-format';
export default {
  name: "ShippingLocationInput",
  components: {
    Multiselect
  },
  props: {
    optionsRoute:{
      required: false
    },
    searchInLocal:{
      required: true
    },
    value:{
      required: true
    }
  },
  data(){
    return {
      selected : null,
      isLoading: true,
      options: [],
      optionsSearch: [],
      query: null
    }
  },
  computed: {

  },
  watch:{
    selected() {
      if (this.selected){
        this.$emit('input', this.selected);
      }
    },
    value: {
      deep:true,
      handler(newValue){
        this.selected = newValue;
      }
    },
    searchInLocal() {
      this.options  = this.getOption([]);
      if (!this.searchInLocal){
        this.searchRemote();
      }
    },
    optionsRoute: {
      deep:true,
      handler(newValue){
        this.selected = {
          address: {
            country: 'MX'
          }
        };
        this.options  = this.getOption(this.optionsRoute);
        if (this.options.length==1){
          this.selected=this.options[0];
        }
        this.optionsSearch  = this.options
        this.isLoading= false
      }
    }
  },
  created(){
    this.selected = this.value;
    this.doLocalSearch = _.debounce(this.searchLocal, 10);
    this.doRemoteSearch = _.debounce(this.searchRemote, 500);
    if (!this.searchInLocal){
      this.doRemoteSearch();
    }
  },
  methods: {
    ...mapActions('shippinglocations', ['getShippingLocationg']),
    search(query) {
      this.query = query;
      if (this.searchInLocal){
        this.doLocalSearch();
      }else{
        this.doRemoteSearch();
      }
    },
    getAddressFormat(option){
      return addressFormat(option.address).join(" ")
    },
    getOption(input){
      return input.map((item)=>{
        return {...{address:{...item,...{address_id:item.id}}},...{value: item.id,}};
      })
    },
    searchLocal(){
      this.isLoading= true;
      if (this.query){
        this.options = this.optionsSearch.filter((e)=>{
          return e.address.line_1.includes(this.query)
        });
      }else{
        this.options  = this.optionsSearch
      }
      this.isLoading= false;
    },
    searchRemote(){
      this.isLoading= true;
      this.getShippingLocationg({search:this.query}).then((data)=>{
        this.options = this.getOption(data.results);
        this.isLoading= false;
      });

    }
  }
}
</script>

<style scoped>

</style>