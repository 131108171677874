<template>
    <div>
        <page-header :title="$t($route.meta.title)"/>
        <loading v-if="loading" />
        <validation-observer ref="observer" v-slot="{ validate }">
            <b-form @submit.stop.prevent="onSubmit(validate)">
              <div class="card">
                  <div class="card-header">
                      {{$t('General')}}
                  </div>
                  <div class="form-fields">
                      <form-input
                            :title="$t('Name')"
                            v-model="form.name"
                            rules="required"
                            type="text"
                            :name="'name'"
                      />
                      <div class="field-wrapper">
                          <div class="row align-items-center ">
                              <div class="col-md-6">
                                  <div class="field-label">{{$t('Permissions')}}</div>
                                  <div class="field-help">{{$t('Select permissions')}}</div>
                              </div>
                          </div>
                        <div class="accordion my-3" role="tablist">
                          <b-card v-for="(item,index) in groups" v-can="item.permissions.map(el => el.value)" :key="'acc-'+index" no-body class="mb-0">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <div class="d-flex justify-content-between p-2">
                                <b>{{item.title}}</b>
                                <a  v-b-toggle="'accordion-'+index" href="javascript:void(0);" >
                                    <i class="far fa-chevron-circle-down btn-icon"></i>
                                </a>
                              </div>
                            </b-card-header>
                            <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
                              <b-card-body class="p-0">
                                <form-input
                                    :key="`p-${index}-${pIndex}-${permission.value}`"
                                    v-for="(permission, pIndex) in item.permissions"
                                    v-can="permission.value"
                                    :title="permission.text"
                                    v-model="permissions[permission.value]"
                                    type="boolean"
                                    rules=""
                                    :disabled="loading"
                                />
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>

                      </div>

                  </div>
              </div>

              <div class="d-flex justify-content-end pb-4">
                  <button :disabled="loading" type="button" class="btn btn-light mr-3 " @click="$router.push({name: 'settings.teams.index'})">{{$t('Cancel')}}</button>
                  <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t(edit ? 'Update team':'Create team')}}</button>
              </div>

            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import formMixin from '@/mixins/formMixin';

export default {
    name: 'TeamForm',
    mixins:[formMixin],
    data(){
        return {
            loading: false,
            edit : false,
            action: null,
            form:{
              name: null,
              permissions: []
            },
            permissions:{},
            groups: [
                {
                    title: this.$t('Accounts'),
                    permissions: [
                        {text: this.$t('Manage account'), value:'accounts.management' }
                    ]
                },
                {
                    title: this.$t('Shipments'),
                    permissions: [
                        {text: this.$t('Create shipments'), value:'shipments.create' },
                        {text: this.$t('Update shipments'), value:'shipments.update' },
                        {text: this.$t('Delete shipments'), value:'shipments.delete' },
                        {text: this.$t('Assign price'), value:'shipments.price' },
                        {text: this.$t('See pricing'), value:'shipments.showprice' },
                        {text: this.$t('Manage tenders'), value:'shipments.tenders' },
                        {text: this.$t('Download list'), value:'shipments.download' },
                    ]
                },
                {
                    title: this.$t('Loads'),
                    permissions: [
                        {text: this.$t('Create loads'), value:'loads.create' },
                        {text: this.$t('Update loads'), value:'loads.update' },
                        {text: this.$t('Delete loads'), value:'loads.delete' },
                        {text: this.$t('Assign driver, truck and trailer'), value:'loads.assets' },
                        {text: this.$t('Update load status'), value:'loads.update.status' },
                        {text: this.$t('See pricing'), value:'loads.showprice' },
                        {text: this.$t('Manage proof of delivery'), value:'loads.checklist.management' },
                        {text: this.$t('Download list'), value:'loads.download' },
                    ]
                },
                {
                    title: this.$t('Trucks'),
                    permissions: [
                        {text: this.$t('Create trucks'), value:'trucks.create' },
                        {text: this.$t('Update trucks'), value:'trucks.update' },
                        {text: this.$t('Delete trucks'), value:'trucks.delete' },
                    ]
                },
                {
                    title: this.$t('Trailers'),
                    permissions: [
                        {text: this.$t('Create trailers'), value:'trailers.create' },
                        {text: this.$t('Update trailers'), value:'trailers.update' },
                        {text: this.$t('Delete trailers'), value:'trailers.delete' },
                    ]
                },
                {
                    title: this.$t('Drivers'),
                    permissions: [
                        {text: this.$t('Create drivers'), value:'drivers.create' },
                        {text: this.$t('Update drivers'), value:'drivers.update' },
                        {text: this.$t('Delete drivers'), value:'drivers.delete' },
                    ]
                },
                {
                    title: this.$t('Issues'),
                    permissions: [
                        {text: this.$t('Create issue'), value:'issue.create' },
                        {text: this.$t('Update issue'), value:'issue.update' },
                        {text: this.$t('Delete issue'), value:'issue.delete' },
                        {text: this.$t('Update status'), value:'issue.status' },
                    ]
                },
                {
                    title: this.$t('Documents'),
                    permissions: [
                        {text: this.$t('Manage documents'), value:'documents.module' }
                    ]
                },
                {
                    title: this.$t('Users'),
                    permissions: [
                        {text: this.$t('Create users'), value:'users.create' },
                        {text: this.$t('Update users'), value:'users.update' },
                        {text: this.$t('Delete users'), value:'users.delete' },
                        {text: this.$t('Show users'), value:'users.read' },
                    ]
                },
                {
                    title: this.$t('Attendance'),
                    permissions: [
                        {text: this.$t('Manage attendance'), value:'attendance.module' }
                    ]
                },
                {
                    title: this.$t('Control tower'),
                    permissions: [
                        {text: this.$t('Manage control tower'), value:'towercontrol.management' }
                    ]
                },
                {
                    title: this.$t('Extra cost'),
                    permissions: [
                        {text: this.$t('Manage extra charges'), value:'extracosts.module' }
                    ]
                },
                {
                    title: this.$t('Settings'),
                    permissions: [
                        {text: this.$t('Manage members'), value:'members.management'},
                        {text: this.$t('Manage teams'), value:'teams.management' },
                        {text: this.$t('Manage addresses'), value:'addresses.management' },
                        {text: this.$t('Manage catalogs'), value:'catalogs.management' },
                        {text: this.$t('Manage requirements'), value:'requirements.management' },
                        {text: this.$t('Manage views'), value:'views.management' },
                    ]
                }
            ]
        }
    },
    created(){
      if(this.$route.params.team_id){
        this.edit = true;
        this.action = this.updateTeam;
        this.loading = true;
        this.getTeam({id: this.$route.params.team_id})
            .then((data)=>{
               this.form = _.cloneDeep(data);
               for(let key of this.form.permissions){
                 this.permissions[key] = true;
               }
            })
            .catch(error =>{

            })
            .finally(()=>{
              this.loading = false;
            });
      }else{
        this.action = this.storeTeam;
      }
    },
    mounted(){

    },
    methods: {
      ...mapActions('teams',['storeTeam','getTeam','updateTeam']),
      async onSubmit(validate){
        if(this.loading)
          return;

        if(!(await validate())){
            this.$bvToast.toast(this.$t('Please, check the form'), {
                title: "Error",
                variant: 'danger',
                solid: true
            });
            return;
        }
        this.loading = true;

        this.form.permissions = [];
        for(let key of Object.keys(this.permissions)){
          if(this.permissions[key] === true){
              this.form.permissions.push(key);
          }
        }

        this.action(_.cloneDeep(this.form))
            .then(data =>{
              this.showSuccessResponse(this.edit);
              this.$router.push({name: 'settings.teams.index'});
            })
            .catch(error =>{
              this.processServiceError(error);
            })
            .finally(()=>{
              this.loading = false;
            });

      }
    }
}
</script>
