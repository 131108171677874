import axios from "@/api/client";

// initial state
export const state = () => ({
    temperatures: {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    temperature: null,
});

// getters
export const getters = {};

// actions
export const actions = {
    getTemperatures({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${query.load_id}/temperatures`, {params:query})
                .then(response=>{
                    commit('setTemperatures', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTemperature({commit},params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.load_id}/temperatures/${params.temperature_id}`)
                .then(response=>{
                    commit('setTemperature', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeTemperature({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.load_id}/temperatures`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error)
                    reject(error.response)
                });
        });
    },
    updateTemperature({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/loads/${payload.load_id}/temperatures/${payload.id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error)
                    reject(error.response)
                });
        });
    },
    deleteTemperature({commit},payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/loads/${payload.load_id}/temperatures/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error)
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setTemperatures(state, data){
        state.temperatures = data;
    },
    setTemperature(state, data){
        state.temperature = data;
    }
};

