<template>
    <div>
        <page-header :title="edit ? $t('Edit load'): $t('New load')" :items="breadcrumb"/>
        <div v-if="!edit" class="row justify-content-md-center">
            <div  class="col-md-8">
                <div style="background-color: #FAF9FB; border-radius: 20px;font-size:14px" class="card">
                    <div class="row m-2 ">
                        <div class="col-4">
                            <div class="row">
                                <div class="col-3">
                                    <span :class="[step === 1 ? 'circle' : 'circle-void']" class="mt-2">
                                        <i v-if="step > 1" class="far fa-check"></i> 
                                        <span v-else>1</span>
                                    </span>
                                </div>
                                <div class="col-5 p-0">
                                    <div><small> {{ $t('Step 1') }}</small></div>
                                    <div :class="[step === 1 ? 'font-weight-bold' : '']">{{  $t('Create load') }}</div>
                                </div>
                                <div class="col-4 mt-2 p-0">
                                    <div>--------></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-5 p-0">
                            <div class="row">
                                <div class="col-2">
                                    <span :class="[step === 2 ? 'circle' : 'circle-void']" class="mt-2">
                                        <i v-if="step > 2" class="far fa-check"></i> 
                                        <span v-else>2</span>
                                    </span>
                                </div>
                                <div class="col-6 p-0">
                                    <div><small>{{ $t('Step 2') }}</small></div>
                                    <div :class="[step === 2 ? 'font-weight-bold' : '']">{{  $t('Merchandise detail') }} </div>
                                </div>
                                <div class="col-4 mt-2 pr-0">
                                    <div>--------></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 p-0">
                            <div class="row">
                                <div class="col-3">
                                    <span :class="[step === 3 ? 'circle' : 'circle-void']" class="mt-2">
                                        <i v-if="step > 3" class="far fa-check"></i> 
                                        <span v-else>3</span>
                                    </span>
                                </div>
                                <div class="col-9">
                                    <div><small>{{ $t('Step 3') }}</small></div>
                                    <div :class="[step === 3 ? 'font-weight-bold' : '']">{{  $t('Finish') }} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-md-9">
                <validation-observer ref="observer" v-slot="observer">

                    <b-form @submit.stop.prevent="calculateFare(observer)">
                        <loading v-if="loading" />
                        <template v-if="step == 1">
                            <div class="card">
                                <div class="card-header">
                                    <h6>{{$t('General')}}</h6>
                                </div>
                                <div v-if="$route.params.profile === 'admin'" class="field-wrapper">
                                    <div class="row align-items-center ">
                                        <div class="col-md-6">
                                            <div class="field-label">{{$t('Shipper')}}<span class="field-required-badge"/></div>
                                            <div class="field-help">{{$t("The load owner")}}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <validation-provider name="shipper_id" rules="required" v-slot="{ errors }">
                                                <shipper-input v-model="form.shipper" :disabled="loading"/>
                                                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                            </validation-provider>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="$route.params.profile === 'admin'" class="field-wrapper">
                                    <div class="row align-items-center ">
                                        <div class="col-md-6">
                                            <div class="field-label">{{$t('Carrier')}}</div>
                                            <div class="field-help">{{$t("The carrier assigned")}}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <validation-provider name="carrier_id"  v-slot="{ errors }">
                                                <carrier-input v-model="form.carrier" :disabled="loading"/>
                                                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                            </validation-provider>
                                        </div>
                                    </div>
                                </div>
                                <form-input
                                    v-if="$route.params.load_id && $route.params.profile === 'admin'"
                                    :title="$t('Status')"
                                    :help="$t('Load status')"
                                    v-model="form.status"
                                    rules="required"
                                    :options="status"
                                    type="select"
                                    :name="'status'"
                                />

                            <form-input
                                v-if="$route.params.load_id && $route.params.profile === 'admin'"
                                title="TMS ID"
                                help=""
                                v-model="form.tms_id"
                                rules="integer"
                                type="number"
                                :name="'tms_id'"
                            />
                            <form-input
                              :title="$t('Tracking Number')"
                              :help="$t('Shipper Tracking Number')"
                              v-model="form.shipper_external_id"
                              rules="alpha_num"
                              type="text"
                              :name="'shipper_external_id'"
                          />
                        </div>

                        <div class="card" v-if="Object.keys(extraParams).length">
                          <div class="card-header">
                            <h6>{{$t("Load Type")}}</h6>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-6 mb-2">
                                <div class="field-label">{{$t("Type")}}</div>
                                <div class="field-help">
                                  <p>{{$t(extraParams.type)}}</p>
                                </div>
                              </div>
                              <div class="col-sm-6 mb-2">
                                <div class="field-label">{{$t("Associated Load")}}</div>
                                <div class="field-help">
                                  <p>{{extraParams.parent_id}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                          <div v-if="edit === false && !Object.keys(extraParams).length" class="card">
                            <div class="card-header">
                              <h6>{{$t('Rate')}}</h6>
                            </div>
                            <div class="card-body">
                              <b-form-group :label="$t('Select an option')" v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    id="radio-group-2"
                                    v-model="rateType"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-sub-component"
                                >
                                  <b-form-radio value="spot">{{$t('Spot')}}</b-form-radio>
                                  <b-form-radio value="agreement">{{$t('Agreement')}}</b-form-radio>
                                </b-form-radio-group>
                              </b-form-group>
                            </div>
                            <div v-show="rateType ==='agreement'" class="field-wrapper">
                              <div class="row align-items-center ">
                                <div class="col-md-6">
                                  <div class="field-label">{{$t('Route')}}</div>
                                  <div class="field-help">{{$t("Select a route")}}</div>
                                </div>
                                <div class="col-md-6">
                                  <validation-provider name="route_id"  v-slot="{ errors }">
                                    <route-input v-model="route" v-on:change="setRouteValues"  :disabled="loading || !form.shipper" :filters="form.shipper ? {shipper_id: form.shipper.id}:{}" />
                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                  </validation-provider>
                                </div>
                              </div>
                            </div>
                          </div>


                            <div v-show="rateType ==='spot' || route">
                              <address-form id="pickup.address." :title="$t('Pickup')" v-model="form.pickup.address" :collapsed="stopsCollapsed" :disabled="form.pickup.address.address_id!=null || loading">
                                    <template v-slot:section-start>
                                        <div class="field-wrapper">
                                            <div class="row align-items-center ">
                                                <div class="col-md-6">
                                                    <div class="field-label">{{$t('Date')}}<span class="field-required-badge"/></div>
                                                    <div class="field-help">{{$t('Pickup schedule')}}</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <validation-provider name="pickup.schedule_from" rules="required" v-slot="{ errors }">
                                                                <date-input v-model="form.pickup.schedule_from" v-on:change="date => form.pickup.date = $moment(date).format('YYYY-MM-DD')" format="YYYY-MM-DD HH:mm:ss" :disabled="loading" type="datetime" />
                                                                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <validation-provider name="pickup.schedule_to" rules="required" v-slot="{ errors }">
                                                                <date-input v-model="form.pickup.schedule_to" format="YYYY-MM-DD HH:mm:ss" :disabled="loading" type="datetime" />
                                                                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                            </validation-provider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <address-input v-model="form.pickup.address" :default-options="(shippingLocations && rateType === 'agreement') ? shippingLocations.pickup_addresses: []" />
                                        <!--
                                        <shipping-location-input ref="shippingLocationPickup" v-show="canBeSearchShippingLocation" :searchInLocal="rateType ==='agreement'" v-model="form.pickup" :optionsRoute="shippingLocations.pickup_addresses" ></shipping-location-input>
                                        -->
                                    </template>
                                </address-form>

                                <stop-form id="stops" v-model="form.stops" :collapsed="stopsCollapsed" :default-country="form.pickup.address.country" :disabled="!!form.route_id || loading" />

                              <address-form id="delivery.address." ref="address_form_delivery"  :title="$t('Delivery')" v-model="form.delivery.address" :collapsed="stopsCollapsed" :disabled="loading  || delivery_parent || form.delivery.address.address_id!=null ">
                                    <template v-slot:section-start>

                                      <div class="field-wrapper">
                                            <div class="row align-items-center ">
                                                <div class="col-md-6">
                                                    <div class="field-label">{{$t('Date')}}<span class="field-required-badge"/></div>
                                                    <div class="field-help">{{$t('Delivery schedule')}}</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <validation-provider name="delivery.schedule_from" rules="required" v-slot="{ errors }">
                                                                <date-input v-model="form.delivery.schedule_from" v-on:change="date => form.delivery.date = $moment(date).format('YYYY-MM-DD')" format="YYYY-MM-DD HH:mm:ss" :disabled="loading" type="datetime" />
                                                                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <validation-provider name="delivery.schedule_to" rules="required" v-slot="{ errors }">
                                                                <date-input v-model="form.delivery.schedule_to" format="YYYY-MM-DD HH:mm:ss" :disabled="loading" type="datetime" />
                                                                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                            </validation-provider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                      </div>
                                       <address-input v-model="form.delivery.address" :default-options="(shippingLocations && rateType === 'agreement') ? shippingLocations.delivery_addresses: []" />
                                        <!--
                                      <shipping-location-input v-if="extraParams.type !== 'DIVERT_IN_TRANSIT'" ref="shippingLocationDelivery" v-show="canBeSearchShippingLocation" :searchInLocal="rateType ==='agreement'" v-model="form.delivery" :optionsRoute="shippingLocations.delivery_addresses" ></shipping-location-input>
                                      -->
                                    </template>
                                </address-form>

                                <div class="card">
                                    <div class="card-header">
                                        <h6>{{$t('Technical information')}}</h6>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Configuration')}}<span class="field-required-badge"/></div>
                                                <div class="field-help">{{$t('The truck configuration')}}</div>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="configuration_id" rules="required" v-slot="{ errors }">
                                                    <truck-configuration-input v-model="form.configuration" :disabled="loading"/>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Truck type')}}<span class="field-required-badge"/></div>
                                                <div class="field-help">{{$t('The truck type')}}</div>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="type_id" rules="required" v-slot="{ errors }">
                                                    <truck-type-input v-model="form.truck_type" :disabled="loading" />
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Trailer type')}}</div>
                                                <div class="field-help">{{$t('The trailer type')}}</div>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="trailer_type_id"  v-slot="{ errors }">
                                                    <trailer-type-input v-model="form.trailer_type" :disabled="loading" />
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="false" class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Size')}}<span class="field-required-badge"/></div>
                                                <div class="field-help">{{$t('The truck size')}}</div>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="size_id" rules="required" v-slot="{ errors }">
                                                    <b-form-input :disabled="loading" v-model="form.size_id" type="text" placeholder=""></b-form-input>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Weight')}}<span class="field-required-badge"/></div>
                                                <div class="field-help">{{$t('The load weight')}} (kg)</div>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="weight" rules="required|numeric" v-slot="{ errors }">
                                                    <b-form-input :disabled="loading" v-model="form.weight" type="number" placeholder=""></b-form-input>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>

                                                <div v-if="false" class="row">
                                                    <div class="col-md-6">
                                                        <validation-provider name="weight" rules="required|numeric" v-slot="{ errors }">
                                                            <b-form-input :disabled="loading" v-model="form.weight" type="number" placeholder=""></b-form-input>
                                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                        </validation-provider>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <validation-provider name="weight_unit" rules="required" v-slot="{ errors }">
                                                            <multiselect v-model="form.weight_unit" :options="weightUnits"
                                                                        placeholder="" select-label="" selected-label=""/>
                                                            <b-form-invalid-feedback >{{errors[0]}}</b-form-invalid-feedback>
                                                        </validation-provider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Dimensions')}}<span class="field-required-badge"/></div>
                                                <div class="field-help">{{$t('The load dimensions (meters)')}}</div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <validation-provider name="width" rules="required|numeric" v-slot="{ errors }">
                                                            <b-form-input :disabled="loading" v-model="form.width" type="number" ></b-form-input>
                                                            <b-form-text id="input-live-help">{{$t('Width')}}</b-form-text>
                                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                        </validation-provider>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <validation-provider name="height" rules="required|numeric" v-slot="{ errors }">
                                                            <b-form-input :disabled="loading" v-model="form.height" type="number"></b-form-input>
                                                            <b-form-text id="input-live-help">{{$t('Height')}}</b-form-text>
                                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                        </validation-provider>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <validation-provider name="length" rules="required|numeric" v-slot="{ errors }">
                                                            <b-form-input :disabled="loading" v-model="form.length" type="number"></b-form-input>
                                                            <b-form-text id="input-live-help">{{$t('Length')}}</b-form-text>
                                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                        </validation-provider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Container type')}}<span class="field-required-badge"/></div>
                                                <div class="field-help">{{$t('The container type of the load')}}</div>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="container_type" rules="required" v-slot="{ errors }">
                                                    <multiselect v-model="form.container_type" :options="containers"
                                                                placeholder="" select-label="" selected-label=""/>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <h6>{{$t('Carrier requirements')}}</h6>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Skills')}}</div>
                                                <div class="field-help">{{$t("Required carrier's skills")}}</div>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="carrier_skills" rules="" v-slot="{ errors }">
                                                    <carrier-skills-input v-model="form.carrier_skills" :disabled="loading"/>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <h6>{{$t('Service')}}</h6>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Load type')}}<span class="field-required-badge"/></div>
                                                
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="load_type_id" rules="required" v-slot="{ errors }">
                                                    <load-type-input v-model="form.load_type" :disabled="!!form.route_id ||loading"/>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Commodity')}}<span class="field-required-badge"/></div>
                                                
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="commodity_id" rules="required" v-slot="{ errors }">
                                                    <commodity-input v-model="form.commodity" :disabled="loading"/>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Consignee')}}</div>
                                                
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="consignee" rules="" v-slot="{ errors }">
                                                            <b-form-input :disabled="loading" v-model="form.consignee" type="text"></b-form-input>
                                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Receiver')}}</div>
                                                
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="receiver" rules="" v-slot="{ errors }">
                                                    <b-form-input  v-model="form.receiver" :disabled="loading" type="text"></b-form-input>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Additional information')}}</div>
                                                <div class="field-help">{{$t("Additional info like temperature or special requirements")}}</div>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="comments" v-slot="{ errors }">
                                                    <b-form-textarea
                                                            v-model="form.comments"
                                                            placeholder=""
                                                            rows="3"
                                                            max-rows="15"
                                                    ></b-form-textarea>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card" v-if="$route.params.profile === 'admin'">
                                    <div class="card-header">
                                        <h6>{{$t('Price')}}</h6>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Purchase price')}}</div>
                                                <div class="field-help"></div>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="purchase_price" rules="" v-slot="{ errors }">
                                                    <b-form-input id="purchase_price" v-model="form.purchase_price" type="number" placeholder=""></b-form-input>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-wrapper">
                                        <div class="row align-items-center ">
                                            <div class="col-md-6">
                                                <div class="field-label">{{$t('Price')}}</div>
                                                <div class="field-help"></div>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="price" rules="" v-slot="{ errors }">
                                                    <b-form-input id="price" v-model="form.price" type="number" placeholder=""></b-form-input>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>

                                <div class="field-wrapper">
                                    <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Currency')}}<span class="field-required-badge"/></div>
                                        <div class="field-help"></div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="price" rules="required" v-slot="{ errors }">
                                        <currency-input id="currency" v-model="form.currency" />
                                        <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                    </div>
                                </div>

                                </div>
                            </div>

                        <b-modal no-close-on-backdrop
                                ref="modal-fare"
                                id="modal-fare"
                                :title="$t('Confirm')"
                                title-class="font-18"
                                cancel-variant="light"
                                :ok-title="$t('Next')"
                                :cancel-title="$t('Cancel')"
                                v-on:ok="step=2"
                        >
                            <div>
                            <div class="text-center">{{$t('Fare')}}: </div>
                            <h4 class="text-center">$ {{fare ? fare.price : '- -' | money}} {{fare ? fare.currency : '- -'}}</h4>
                            <p v-if="!reject" class="text-center">{{$t('Do you accept the fare?')}}</p>

                            <form-input
                                v-if="reject"
                                :title="$t('Price')"
                                :help="$t('Enter suggested price')"
                                v-model="form.price"
                                :rules="`required|numeric|${fare?'|minprice:'+ minPrice: ''}`"
                                :options="status"
                                type="number"
                                :name="'price'"
                            />

                            </div>
                            <template slot="modal-footer">
                            <button type="button" class="btn btn-light" @click="$bvModal.hide('modal-fare')">{{$t('Cancel')}}</button>
                            <button v-if="!reject" type="button" class="btn btn-light" @click="reject=true">{{$t('No, suggest price')}}</button>
                            <button type="button" :disabled="(reject === true && form.price < minPrice )" class="btn btn-primary" @click="step=2">{{$t('Accept')}}</button>
                            </template>
                        </b-modal>
                    </template>
                    <template v-if="edit == false && step == 2">
                        <div class="card">
                            <div class="card-header">
                                <h6>{{$t('Tax information')}}</h6>
                            </div>

                            <form-input
                                :title="$t('RFC sender')"
                                :help="$t('The RFC sender')"
                                v-model="form.rfc_sender"
                                rules="required|max:15|rfc"
                                type="text"
                                :name="'rfc_sender'"
                                :upper="true"
                            />

                            <form-input
                                :title="$t('RFC recipient')"
                                :help="$t('The RFC recipient')"
                                v-model="form.rfc_recipient"
                                rules="required|max:15|rfc"
                                type="text"
                                :name="'rfc_recipient'"
                                :upper="true"
                            />

                            <form-input
                                :title="$t('Total products')"
                                :help="$t('The total value of product or service')"
                                v-model="form.total_products"
                                rules="required"
                                type="number"
                                :name="'value'"
                            />

                            <form-input
                                :title="$t('Trucking configuration')"
                                selectText='text'
                                selectValue='value'
                                v-model="form.config_auto_transport"
                                rules="required"
                                type="select"
                                :options="c_configautotransport"
                                :name="'configautotransport'"
                                :placeholder="$t('Select option')"
                                :searchable="true"
                            />

                        </div>

                         <div v-if="is_international" class="card">
                            <div class="card-header">
                                <h6>{{$t('Customs declaration')}}
                                    <span class="badge bg-primary text-white">{{ form.motions.length}}</span>
                                </h6>
                            </div>

                            <template  v-for="(motion,index) in form.motions">
                                <div :key="motion.key" class="field-wrapper border-bottom-none">
                                    <div class="row align-items-center ">
                                        <div class="col-md-8">
                                            <div class="field-label"><b>{{$t('Motion')}} {{index+1}}</b></div>
                                        </div>
                                        <div class="col-md-4 d-flex justify-content-end">
                                            <a v-if="index !== 0" href="javascript:void(0);" class="text-danger mr-2" @click="form.motions.splice(index,1)" v-b-tooltip.hover :title="$t('Delete')" >
                                                <i class="far fa-trash-alt btn-icon"  />
                                            </a>
                                        </div>
                                    </div>
                                
                                    <form-input
                                        :title="$t('Motion number')"
                                        v-model="form.motions[index]"
                                        rules="required"
                                        type="text"
                                        :name="'motion' + (index + 1)"
                                        placeholder="21  43  3073  1010933"
                                        v-mask="'##  ##  ####  #######'"
                                    />
                                </div>
                                
                                
                            </template>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-8"></div>
                                    <div class="col-md-4 ">
                                        <button type="button" class="btn btn-primary btn-sm float-right" @click="addMotion">{{$t('Add')}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card">
                            <div class="card-header">
                                <h6>{{$t('Tax products or services')}}
                                    <span class="badge bg-primary text-white">{{ products.length}}</span>
                                </h6>
                            </div>

                            <template v-for="(product,index) in products" >
                                <div :key="product.key" class="field-wrapper" :class="[(!products[index].collapse) ? 'border-bottom-none':'']">
                                    <div class="row align-items-center ">
                                        <div class="col-md-8">
                                            <div class="field-label"><b>{{$t('Product')}} {{index+1}}</b></div>
                                        </div>
                                        <div class="col-md-4 d-flex justify-content-end">
                                            <a v-if="index !== 0" href="javascript:void(0);" class="text-danger mr-2" @click="products.splice(index,1)" v-b-tooltip.hover :title="$t('Delete')" >
                                                <i class="far fa-trash-alt btn-icon"  />
                                            </a>
                                            <a href="javascript:void(0);" @click="products[index].collapse=!product.collapse">
                                                <i class="far  btn-icon" :class="[products[index].collapse ? 'fa-chevron-circle-down':'fa-chevron-circle-up']" />
                                            </a>
                                        </div>
                                    </div>
                                    <div :class="[products[index].collapse ? 'collapse':'']">

                                        <form-input
                                            :title="$t('Product')"
                                            :help="$t('The product or service')"
                                            selectText='text'
                                            selectValue='value'
                                            v-model="products[index].product.product_key"
                                            rules="required"
                                            type="select"
                                            :options="c_products"
                                            :name="'product'"
                                            :placeholder="$t('Select option')"
                                            :searchable="true"
                                        />

                                         <form-input
                                            :title="$t('Description')"
                                            :help="$t('The description of product or service')"
                                            v-model="products[index].product.description"
                                            rules="required"
                                            type="text"
                                            :name="'description'"
                                        />

                                        <form-input
                                            :title="$t('Quantity')"
                                            :help="$t('The quantity of product or service')"
                                            v-model="products[index].product.quantity"
                                            rules="required"
                                            type="number"
                                            :name="'quantity'"
                                        />

                                        <form-input
                                            :title="$t('Unit')"
                                            :help="$t('The unit of product or service')"
                                            selectText='text'
                                            selectValue='value'
                                            v-model="products[index].product.unit_key"
                                            rules="required"
                                            type="select"
                                            :options="c_unit"
                                            name="unit"
                                            :placeholder="$t('Select option')"
                                            :searchable="true"
                                        />

                                        <form-input
                                            :title="$t('Origin')"
                                            :help="$t('Origin of products')"
                                            selectText='text'
                                            selectValue='value'
                                            v-model="products[index].product.origin"
                                            rules="required"
                                            type="select"
                                            :options="getOrigins"
                                            name="origin"
                                            :placeholder="$t('Select option')"
                                            :searchable="true"
                                        />

                                        <form-input
                                            :title="$t('Destiny')"
                                            :help="$t('Destiny of products')"
                                            selectText='text'
                                            selectValue='value'
                                            v-model="products[index].product.destiny"
                                            rules="required"
                                            type="select"
                                            :options="getDestinies"
                                            :name="'destiny'"
                                            :placeholder="$t('Select option')"
                                            :searchable="true"
                                        />

                                        <div class="field-wrapper">
                                            <div class="row align-items-center ">
                                                <div class="col-md-6">
                                                    <div class="field-label">{{$t('Is it hazardous material?')}}</div>
                                                    <div class="field-help"></div>
                                                </div>
                                                <div class="col-md-6">
                                                    <switches v-model="products[index].product.is_dangerous" theme="bootstrap" color="info" class="float-right" />
                                                </div>
                                            </div>
                                        </div>

                                        <form-input
                                            v-if="products[index].product.is_dangerous"
                                            :title="$t('Hazardous material')"
                                            :help="$t('The hazardous material')"
                                            selectText='text'
                                            selectValue='value'
                                            v-model="products[index].product.dangerous_key"
                                            rules="required"
                                            type="select"
                                            :options="c_dangerous"
                                            name="dangerous"
                                            :placeholder="$t('Select option')"
                                            :searchable="true"
                                        />

                                        <form-input
                                            :title="$t('Packaging')"
                                            :help="$t('The packaging of product or service')"
                                            selectText='text'
                                            selectValue='value'
                                             v-model="products[index].product.packaging_key"
                                            rules="required"
                                            type="select"
                                            :options="c_packaging"
                                            name="packaging"
                                            :placeholder="$t('Select option')"
                                            :searchable="true"
                                        />

                                        <form-input
                                            :title="$t('Weight')"
                                            :help="$t('The weight of product or service')"
                                            v-model="products[index].product.weight"
                                            rules="required"
                                            type="number"
                                            :name="'weight'"
                                        />

                                      <form-input
                                          :title="$t('SKU')"
                                          :help="$t('The SKU of product or service')"
                                          v-model="products[index].product.sku"
                                          type="text"
                                          :name="'sku'"
                                      />

                                        <template v-if="productForm">
                                            <template v-for="(field, idx) in productForm.fields">
                                                <form-input
                                                    :key="'field-'+idx+field.id"
                                                    :title="field.title"
                                                    :help="field.help"
                                                    v-model="products[index].product.custom_fields[field.id]"
                                                    :type="field.type"
                                                    :name="'products.'+idx+'.'+field.id"
                                                    :options="field.options"
                                                />
                                            </template>
                                        </template>

                                        <template v-if="is_international">
                                            <form-input
                                                :title="$t('Tariff')"
                                                :help="$t('The tariff of product or service')"
                                                selectText='text'
                                                selectValue='value'
                                                v-model="products[index].product.tariff_key"
                                                rules="required"
                                                type="select"
                                                :options="c_arancelaria"
                                                name="tariff"
                                                :placeholder="$t('Select option')"
                                                :searchable="true"
                                            />

                                            <form-input
                                                :title="$t('UUID foreign trade')"
                                                :help="$t('The uuid foreign trade of product')"
                                                v-model="products[index].product.uuid_foreign_trade"
                                                rules="required"
                                                type="text"
                                                :name="'uuid'"
                                            />
                                        </template>


                                    </div>
                                </div>                                
                            </template>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-8"></div>
                                    <div class="col-md-4 ">
                                        <button type="button" class="btn btn-primary btn-sm float-right" @click="addProduct">{{$t('Add')}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="edit == false && step == 3">
                        <load-detail :title="$t('Load creation summary')" :load="form"></load-detail>
                    </template>

                    <div class="d-flex justify-content-end">
                        <button :disabled="loading" type="button" class="btn btn-light mr-3 " @click="$router.push({name:'loads.index'})">{{$t('Cancel')}}</button>
                        <template v-if="step===1">
                            <!-- <button :disabled="loading || (rateType ==='agreement' && !route)" v-if="!edit" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew">{{$t('Save and create new')}}</button>-->
                            <button ref="submit" :disabled="loading || (rateType ==='agreement' && !route)" type="submit" class="btn btn-primary float-right">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {{ $route.params.load_id ? $t('Save'): $t('Next') }}
                            </button>
                        </template>
                        <template v-if="step===2">
                            <button v-if="!edit" type="button" class="btn btn-light mr-3 " @click="changeStep(1)">{{$t('Back')}}</button>
                            <button v-if="!edit" type="button" class="btn btn-light mr-3 " @click="changeStep(3)">{{$t('Skip and continue')}}</button>
                            <button ref="submit" :disabled="loading || (rateType ==='agreement' && !route)" type="submit" class="btn btn-primary float-right">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {{ $t('Next') }}
                            </button>
                        </template>
                        <template v-if="step===3">
                            <button v-if="!edit" type="button" class="btn btn-light mr-3 " @click="changeStep(2)">{{$t('Back')}}</button>
                            <button :disabled="loading || (rateType ==='agreement' && !route)" v-if="!edit" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew(observer)">{{$t('Finish and create new')}}</button>
                            <button @click="onSubmit(observer)" :disabled="loading" type="button" class="btn btn-primary float-right">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {{ $t('Finish') }}
                            </button>
                        </template>
                    </div>

                    </b-form>
                </validation-observer>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';


    import formMixin from '@/mixins/formMixin';
    import Multiselect from 'vue-multiselect';
    import TrailerInput from '@/components/form/TrailerInput';
    import TruckTypeInput from '@/components/form/TruckTypeInput';
    import TrailerTypeInput from '@/components/form/TrailerTypeInput';
    import TruckConfigurationInput from '@/components/form/TruckConfigurationInput';
    import UserInput from '@/components/form/UserInput';
    import CarrierSkillsInput from "@/components/form/CarrierSkillsInput";
    import LoadTypeInput from "@/components/form/LoadTypeInput";
    import CommodityInput from "@/components/form/CommodityInput";
    import CountryInput from "@/components/form/CountryInput";
    import CurrencyInput from "@/components/form/CurrencyInput";
    import AddressForm from "@/components/form/AddressForm";
    import DateInput from "@/components/form/DateInput";
    import StopForm from "../../components/form/StopForm";
    import CarrierInput from '@/components/form/CarrierInput';
    import ShipperInput from '@/components/form/ShipperInput';
    import RouteInput from '@/components/form/RouteInput';
    import events from '@/constants/eventIcons';
    import cryptoRandomString from 'crypto-random-string';
    import Switches from 'vue-switches';
    import loadDetail from '@/views/loads/LoadDetail';
    import addressFormat from '@/helpers/address-format';
    import ShippingLocationInput from "../../components/form/ShippingLocationInput";
    import AddressInput from "@/components/form/AddressInput";

    export default {
        name:'LoadForm',
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.from = from;
            });
        },
        components:{
            ShippingLocationInput,
            Multiselect,
            TrailerInput,
            TruckTypeInput,
            TrailerTypeInput,
            TruckConfigurationInput,
            UserInput,
            CarrierSkillsInput,
            LoadTypeInput,
            CommodityInput,
            CountryInput,
            AddressForm,
            DateInput,
            StopForm,
            CarrierInput,
            ShipperInput,
            CurrencyInput,
            RouteInput,
            Switches,
            loadDetail,
            AddressInput
        },
        mixins: [formMixin],
        props:{
          canBeSearchShippingLocation:{
            required: false,
            type:Boolean,
            default: true
          }
        },
      data(){
            return {
                title: '...',
                edit: false,
                shippingLocations : {},
                shippingLocationDelivery : true,
                shippingLocationPickup : true,
                form:null,
                stopsCollapsed : false,
                isFetchingTrailers: false,
                trailers: [],
                createNew: false,
                loading: false,
                from: null,
                addStops: false,
                status: Object.keys(events).map(item => ({text: this.$t(item), value: item})),
                fare: null,
                reject: false,
                rateType: 'spot',
                route: null,
                extraParams : {},
                delivery_parent: false,
                parentStop : {},
                step:1,
                back: false,
                products: [
                    {
                        key: cryptoRandomString({length:5}),
                        collapse: false,
                        product: {custom_fields:{}}
                    }
                ],
                add : false,
                c_dangerous: [],
                c_products: [],
                c_arancelaria: [],
                c_unit: [],
                c_packaging: [],
                c_configautotransport: []
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapGetters('catalogs',['weight_units','containerTypes']),
            ...mapGetters('configuration',['productForm']),
            ...mapState('loads',['load']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Loads"),
                        to: {name:'loads.index'}
                    },
                    {
                        text: this.edit ? `${this.$route.params.load_id}`: this.$t('New load'),
                        active: true
                    }
                ]
            },
            weightUnits(){
                //return this.weight_units.map(item => item.value);
                return ['kg'];
            },
            containers(){
                return this.containerTypes.map(item => item.value);
            },
            minPrice(){
              if(this.fare){
                return this.fare.price * 0.8;
              }
              return 0;
            },
            getStops(){
                let stops = [];

                if (this.form.pickup && this.form.delivery) {
                    let linesPickup = addressFormat(this.form.pickup.address);
                    let linesDelivery = addressFormat(this.form.delivery.address);
                    let addressPickup =  linesPickup.length > 0 ? linesPickup.join(', ') : '- -';
                    let addressDelivery=  linesDelivery.length > 0 ? linesDelivery.join(', ') : '- -';
                    stops.push({
                        value: 1,
                        text: addressPickup
                    })

                    if (this.form.stops.length > 0) {
                        for(let x = 0; x < this.form.stops.length; x++ ) {
                            let linesStop = addressFormat(this.form.stops[x].address);
                            let addressStop =  linesStop.length > 0 ? linesStop.join(', ') : '- -';
                            stops.push({
                                value: stops.length + 1,
                                text: addressStop
                            })
                        }
                    }

                    stops.push({
                        value: stops.length + 1,
                        text: addressDelivery
                    })
                    
                } else {
                    return stops;
                }
                
                return stops;
            },
            getOrigins(){
                let origins = _.cloneDeep(this.getStops)
                origins.splice(origins.length - 1,1)
                return origins
            },
            getDestinies(){
                let destinies = _.cloneDeep(this.getStops)
                destinies.splice(0,1)
                return destinies
            },
            is_international(){
                if (this.form.pickup.address.country === 'MX' && this.form.delivery.address.country === 'MX')
                    return false;
                else
                    return true;
            }   
        },
        watch:{
            rateType(newValue, oldValue){
                if(newValue === 'spot'){
                  this.resetForm();
                }
            },
            delivery_parent(){
              if (this.delivery_parent){
                this.form.delivery = this.parentStop
              }else{
                this.$refs.address_form_delivery.clear();
                this.form.delivery = {};
              }
            }
        },
        created() {
            if(this.$route.params.load_id){
                this.edit = true;
                this.loading = true;
                this.getLoad({id: this.$route.params.load_id})
                    .then(data=>{
                        let form = _.cloneDeep(data);
                        for(let i=0; i< form.stops.length;i++ ){
                            form.stops[i].collapse = true;
                        }
                        this.stopsCollapsed = true;
                        this.form = form;
                        this.loading = false;
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    });
            }else{
                this.resetForm();
                this.fetchCatalogTax({})
                    .then(data => {
                        this.c_dangerous = data.c_dangerous
                        this.c_unit = data.c_unit
                        this.c_products = data.c_products
                        this.c_arancelaria = data.c_arancelaria
                        this.c_packaging = data.c_packaging
                        this.c_configautotransport = data.c_configautotransport
                        })
            }
            if (this.$route.query){
              this.extraParams = this.$route.query;
              this.form = {...this.form,...this.extraParams}
            }
            if (this.extraParams.type){
              this.getLoad({id:this.extraParams.parent_id}).then((data)=>{
                if (data){
                  this.setDataFromParentLoad(_.cloneDeep(data));
                }
              });
            }
        },
        methods: {
            ...mapActions('loads',['getLoad','storeLoad','updateLoad','getFares']),
            ...mapActions('routes',['getRoute']),
            ...mapActions('catalogs', ['fetchCatalogTax']),
            setDataFromParentLoad(data){
                let form = _.cloneDeep(this.form);
              form = {...form, ..._.pick(data, ['carrier', 'shipper', 'configuration','truck_type','trailer_type','weight',
                                'width','height','length','container_type','carrier_skills','load_type','commodity','consignee','receiver'])}

              form.purchase_price = 0;
              form.price = 0;
              if (this.extraParams.type == 'DIVERT_IN_TRANSIT'){
                form.delivery = data.delivery;
              }else if(this.extraParams.type == 'RETURN'){
                form.pickup = data.delivery;
                form.delivery.address = data.pickup.address;
              }


             this.form = form;
              //this.form = {...this.form,...data}
            },
            resetForm() {
                this.form = {
                    pickup:{
                        address: {
                            country: 'MX'
                        }
                    },
                    delivery: {
                        address: {
                            country: 'MX'
                        }
                    },
                    stops:[],
                    carrier_skills: [],
                    distance_unit: 'm',
                    weight_unit: 'kg',
                    currency: 'MXN',
                    motions: [""]
                };
                this.createNew = false;
                this.stopsCollapsed = false;
                this.route = null;
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },
            getForm(){
              let form = _.cloneDeep(this.form);

              form.configuration_id = form.configuration.id;
              if(form.shipper){
                form.shipper_id = form.shipper.id;
              }
              if(form.carrier){
                form.carrier_id = form.carrier.id;
              }
              if(form.truck_type){
                form.truck_type_id = form.truck_type.id;
              }
              if(form.trailer_type){
                form.trailer_type_id = form.trailer_type.id;
              }
              if(form.driver){
                form.driver_id = form.driver.id;
              }
              if(form.truck){
                form.truck_id = form.truck.id;
              }
              if(form.load_type){
                form.load_type_id = form.load_type.id;
              }
              if(form.commodity){
                form.commodity_id = form.commodity.id;
              }
              if(form.pickup.address.id){
                  form.pickup.address_id = form.pickup.address.id;
              }
              if(form.delivery.address.id){
                  form.delivery.address_id = form.delivery.address.id;
              }
              form.stops = form.stops.map(item =>{
                  if(item.address.id){
                      item.address_id = item.address.id
                  }
                  return item;
              });
              
              let carrier_skills = [];
              for(let skill of form.carrier_skills){
                carrier_skills.push(skill.id);
              }
            
            if (form.rfc_recipient) {
              let products = [];
              for(let product of this.products)  {
                  products.push(product.product)
              }
              form.products = products

              form.rfc_recipient = form.rfc_recipient.toUpperCase()
              form.rfc_sender = form.rfc_sender.toUpperCase()

              form.is_international = this.is_international
              form.in_out = this.is_international ? 'Salida' : 'Entrada'
            }

              form.carrier_skills = carrier_skills;
              delete form.configuration;
              delete form.truck_type;
              delete form.trailer_type;
              delete form.driver;
              delete form.truck;
              delete form.load_type;
              delete form.commodity;
              delete form.shipper;
              delete form.carrier;
              return form;
            },
            saveAndCreateNew(observer){
                this.createNew = true;
                this.onSubmit(observer);
            },
            async calculateFare(observer){
              if(!(await observer.validate()) || this.loading){
                this.stopsCollapsed = false;
                this.$bvToast.toast(this.$t('Please, check the form'), {
                  title: "Error",
                  variant: 'danger',
                  solid: true
                });
                return;
              }
              if(this.form.route_id && this.step == 1){
                  //this.onSubmit(observer);
                  this.step = 2;
                  return;
              }
            //TODO: VALIDAR A NIVEL TENANT SI SE VA A USAR EL TARIFARIO
            if((this.form.type === 'RETURN' || this.form.type === 'DIVERT_IN_TRANSIT') && this.step == 1){
                  this.step = 2;
                  return;
            }

              if (this.step == 2) {
                  this.step = 3
                  return
              }

              if (this.step == 1 && this.back) {
                  this.back = false
                  return;
              } 

              // Calculate if is a shipper and pickup/delivery changes
              if(this.edit){
                    if(_.isEqual(this.form.pickup.address, this.load.pickup.address) && 
                            _.isEqual(this.form.delivery.address, this.load.delivery.address) && this.form.truck_type.id === this.load.truck_type.id){
                            this.onSubmit(observer);
                            return;
                    }else{
                        if(this.$route.params.profile === 'admin' || this.$route.params.profile === 'staff'){
                            this.onSubmit(observer);
                            return;
                        }
                    }
              }else{
                    if((this.$route.params.profile === 'admin' || this.$route.params.profile === 'staff') && this.form.price && this.form.purchase_price){
                        //this.onSubmit(observer);
                        this.step = 2;
                        return;
                    }
              }
              if(this.loading)
                  return;
              this.reject = false;
              this.loading = true;
              this.getFares(this.getForm())
                  .then(data=>{
                    this.fare = data;
                    this.form.price = data.price;
                    this.form.currency = data.currency;
                    this.form.fare_id = data.id;
                    this.$refs['modal-fare'].show()
                  })
                  .catch(error =>{

                  })
                  .finally(()=>{
                    this.loading = false;
                  });
            },
            async onSubmit(observer) {
                if(!(await observer.validate()) || this.loading){
                    this.stopsCollapsed = false;
                    this.$bvToast.toast(this.$t('Please, check the form'), {
                        title: "Error",
                        variant: 'danger',
                        solid: true
                    });
                    return;
                }
                this.$bvModal.hide('modal-fare');
                this.loading = true;

                if(this.edit){
                    this.updateLoad(_.omit(this.getForm(), ['shipments']))
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            this.$router.push({name:'loads.detail.general',params:{load_id: data.id}});
                            //this.redirect();
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                            this.step = 1;
                        });
                }else{
                    this.storeLoad(this.getForm())
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Created successfully'), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            if(this.createNew){
                                this.resetForm();
                            }else{
                              this.$router.push({name:'loads.detail.general',params:{load_id: data.id}});
                                //this.redirect();
                            }
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                            this.step = 1;
                        });
                }
            },
            setRouteValues(item){
                if(!item) return;
                this.stopsCollapsed = true;
                this.getRoute({id: item.id})
                    .then((data)=>{
                      let route = _.cloneDeep(data);
                      this.shippingLocations = {pickup_addresses:data.pickup_addresses,delivery_addresses:data.delivery_addresses}
                      this.setRouteData(route);
                    })
                    .catch(error =>{
                      console.log(error);
                    });

            },
            setRouteData(item){
                this.form = {route_id: item.id,...this.form, ..._.omit(item,[
                            'id','created_at','type','tms_id','external_id','name',
                            'expires_at','has_limit','limit','used','shipper','carrier',
                            ])};
            },
            redirect(){
                if(this.from.name){
                    this.$router.back();
                }else{
                    this.$router.push({name:'loads.index'});
                }
            },
            addProduct(){
                let products = this.products.map(item => ({...item, collapse: true}));
                products.push({
                    key: cryptoRandomString({length:5}),
                    collapse: false,
                    product: {custom_fields:{}}
                });
                this.products = products;
            },
            changeStep(step){
                this.step = step
                this.back = true;
                return;
            },
            addMotion(){
                this.form.motions.push("");
            }
        }
    }
</script>

<style>
    span.circle-void {
        font-size:15px;
        border: 1px solid black;
        border-radius: 1.2em;
        -moz-border-radius: 1.2em;
        -webkit-border-radius: 1.2em;
        color: black;
        border-color: black;
        display: inline-block;
        line-height: 1.4em;
        text-align: center;
        width: 1.4em; 
    }

    span.circle {
        background: linear-gradient(90.3deg, #00B3E6 1.2%, #E3ED55 100%);
        font-size:15px;
        border-radius: 1em;
        -moz-border-radius: 1em;
        -webkit-border-radius: 1em;
        color: #ffffff;
        display: inline-block;
        line-height: 1.8em;
        text-align: center;
        width: 1.8em; 
    }
</style>
