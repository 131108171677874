<template>
<div class="">
  <div class="row mb-4">
    <div class="col-md-12">
      <filter-bar :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved">
        <template  v-slot:right>
          <div class="btn-group" role="group" aria-label="toolbar">
            <button  v-b-tooltip.hover :title="$t('Refresh')" @click="fetchData()" type="button" class="btn btn-light">
              <i class="far fa-sync"></i>
            </button>
            <button  v-b-tooltip.hover :title="$t('Add expense')"  v-b-modal.modal_create_settlementCatalog @click="createSettlementCatalogModal()" class="btn btn-light">
              <i class="far fa-plus"></i>
            </button>
            <button v-b-tooltip.hover :title="$t('Import')" type="button" class="btn btn-light"  @click="$refs.import.click()">
              <i class="far fa-file-import"></i>
            </button>
            <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
          </div>
        </template>
      </filter-bar>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table mb-0">
      <thead>
      <tr class="">
        <th class="text-center">ID</th>
        <th class="text-center">{{ $t('Name') }}</th>
        <th class="text-center">{{ $t('TMS ID') }}</th>
        <th class="text-center">{{ $t('External ID') }}</th>
        <th class="text-center">{{ $t('Type') }}</th>
        <th class="text-center">{{ $t('Status') }}</th>
        <th class="text-center">{{ $t('Actions') }}</th>
      </tr>
      </thead>
      <tbody>
      <template  v-if="fetching">
        <tr>
          <td colspan="10"><loading/></td>
        </tr>
      </template>
      <template v-else  v-for="item in list.results">
        <tr v-if="page === list.current_page" :key="'expense-'+item.id"  class="expense-row">
          <td class="text-center">
            <b>{{item.id}}</b>
          </td>
          <td class="text-center">
            {{ item.name }}
          </td>
          <td class="text-center">
            {{ item.tms_id }}
          </td>
          <td class="text-center">
            {{ item.external_id }}
          </td>
          <td class="text-center">
            {{ $t(item.type) }}
          </td>
          <td class="text-center">
            <span v-if="item.enabled" class="badge badge-outline-success ">{{$t("Enabled")}}</span>
            <span v-else class="badge badge-outline-danger">{{$t("Disabled")}}</span>
          </td>
          <td class="text-center">
            <a @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-4">
              <i class="far fa-trash-alt btn-icon"  />
            </a>
            <a v-b-modal.modal_create_settlementCatalog @click="editSettlementCatalogModal(item)" class="mr-4">
              <i class="far fa-edit btn-icon"></i>
            </a>
            <a v-if="itemID==item.id" href="javascript:void(0);" @click="itemID = false">
              <i class="far fa-chevron-circle-up btn-icon"></i>
            </a>
            <a v-else href="javascript:void(0);" @click="showRow(item)">
              <i class="far fa-chevron-circle-down btn-icon"></i>
            </a>
          </td>
        </tr>
        <template v-if="itemID === item.id">
          <tr :key="'file-detail-'+item.id">
            <td colspan="100%" class="p-0">
              <div class="card shadow-none border-0 p-4">
                <div class="row">
                  <div class="col-md-3">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label font-weight-bold ">{{$t('Code')}}</div>
                      <div class="field-help">{{ item.code  }}</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label font-weight-bold ">{{$t('Is verifiable')}}</div>
                      <div class="field-help">
                        <span v-if="item.is_verifiable" class="badge badge-outline-info  "> {{$t("Yes")}}</span>
                        <span v-else class="badge badge-outline-warning"> {{$t("No")}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label font-weight-bold ">{{$t('Category')}}</div>
                      <div class="field-help">
                        <div class="badges_container">
                          <template v-for="item in item.category">
                            <span class="badge badge-primary">{{$t(item.value)}}</span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label font-weight-bold ">{{$t('Departments')}}</div>
                      <div class="field-help">
                        <div class="badges_container">
                          <template v-for="item in item.departments">
                            <span class="badge badge-primary">{{$t(item)}}</span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </template>
      </tbody>
    </table>
  </div>
  <div  v-if="list.total > 0"  class="row align-items-center">
    <div class="col-md-6 col-sm-6 ">
      <b-pagination
          v-model="page"
          :total-rows="list.total"
          :per-page="list.per_page"
          aria-controls="my-table"
          first-number
          last-number
          pills
      ></b-pagination>
    </div>
    <div class="col-md-6 col-sm-6 text-sm-right">
      <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
    </div>
  </div>

  <div v-if="!fetching && list.total == 0" class="row">
    <div class="col">
      <empty-results />
    </div>
  </div>

  <!-- Start Create Modal -->
  <b-modal
      id="modal_create_settlementCatalog"
      ref="modal_create_settlementCatalog"
      size="lg"
      hide-footer
      :title="update?$t('Edit Settlement Catalog'):$t('Add Settlement Catalog')"
  >
    <validation-observer ref="observer" v-slot="{ validate }">
      <b-form @submit.stop.prevent="onSubmit(validate)">
        <div class="form-fields">
          <div class="row">
            <form-input
                :title="$t('Name')"
                v-model="form.name"
                rules="required"
                type="text"
                name="name"
                layout="vertical"
                class="col-md-6"
            />
            <form-input
                :title="$t('Code')"
                v-model="form.code"
                rules="required"
                type="text"
                name="name"
                layout="vertical"
                class="col-md-6"
            />
            <form-input
                :title="$t('Type')"
                v-model="form.type"
                rules="required"
                type="select"
                name="type"
                :options="catalogsTypeOptions"
                layout="vertical"
                class="col-md-6"
            />
            <form-input
                :title="$t('TMS ID')"
                v-model="form.tms_id"
                rules="required"
                type="text"
                name="tms_id"
                layout="vertical"
                class="col-md-6"
            />
            <form-input
                :title="$t('External ID')"
                v-model="form.external_id"
                rules="required"
                type="text"
                name="tms_id"
                layout="vertical"
                class="col-md-6"
            />
            <form-input
                :title="$t('Category')"
                v-model="form.category"
                rules="required"
                :options="expensesCategories"
                :searchable="true"
                type="select"
                :multiple="true"
                :name="'category'"
                layout="vertical"
                class="col-md-6"
            />
            <form-input
                :title="$t('Departments')"
                v-model="form.departments"
                type="tag"
                :name="'departments'"
                layout="vertical"
                class="col-md-6"
            />
            <form-input
                :title="$t('Is verifiable')"
                v-model="form.is_verifiable"
                rules="required"
                type="boolean"
                name="is_verifiable"
                layout="vertical"
                class="col-md-6"
            />
            <form-input
                :title="$t('Enabled')"
                v-model="form.enabled"
                rules="required"
                type="boolean"
                name="enabled"
                layout="vertical"
                class="col-md-6"
                :border="false"
            />
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4 mb-2">
          <a @click="$bvModal.hide('modal_create_settlementCatalog')"  class="btn btn btn-light mr-3 ">{{$t('Cancel')}}</a>
          <button type="submit" class="btn btn-primary">{{update?$t('Update'):$t('Save')}}</button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
  <!-- End Create Modal -->



</div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Swal from "sweetalert2";
import FilterBar from "../filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import codeGenerate from '../../helpers/code-generate';

export default {
  name: "SettlementCatalogTable",
  components:{FilterBar},
  mixins: [filterMixin],
  methods:{
    ...mapActions('loads',['getSettlementCatalog','getExpenseCategory','storeSettlementCatalog','updateSettlementCatalog','deleteSettlementCatalog','importSettlementCatalog']),
    ...mapActions('catalogs',['getEnum']),
    fetchData(update = false){
      this.fetching = update?false:true;
      this.getSettlementCatalog({...this.filters}).finally(()=>{this.fetching = false;})
    },
    updateRows(){
      this.fetchData(true)
    },
    createSettlementCatalogModal(){
      this.update = false;
      this.form = {};
      this.form.is_verifiable = true;
      this.form.category = [];
      this.form.enabled = true;
    },
    editSettlementCatalogModal(item){
      this.update = true;
      this.form = {...item};
      this.form.category = item.category.map(item =>{
        return item.id
      });
    },
    handleFile(){
      let payload= {template : this.$refs.import.files[0]};
      this.importSettlementCatalog(payload)
          .then((data)=>{
            this.clearFile()
            Echo.private(`jobs.${data.job_id}`)
                .listen('JobProcessed', (e) => {
                  this.updateRows();
                  Echo.leave(`jobs.${data.job_id}`);
                });
            this.$root.$bvToast.toast(this.$t("File uploaded successfully", {resource: this.resourceName}), {
              title: this.$t('Success'),
              variant: 'success',
              solid: true
            });
          })
          .catch(()=>{
            Swal.fire(
                "Error",
                this.$t("Something went wrong!"),
                "error"
            );
          })
    },
    clearFile(){
      this.$refs.import.value=null;
    },
    confirmDelete(item){
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.deleteSettlementCatalog({...{id: item.id}})
              .then(data=>{
                this.updateRows();
                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Settlement").toLowerCase()}), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    },
    showRow(item){
      this.itemID = item.id
    },
    async onSubmit(validate){
      if(!(await validate())){
        return;
      }
      let form = _.cloneDeep(this.form);

      if (this.update){
        this.updateSettlementCatalog(form).then(()=>{
          this.$refs.modal_create_settlementCatalog.hide();
          this.updateRows();
          this.$root.$bvToast.toast(this.$t('Updated successfully'), {
            title: this.$t('Success'),
            variant: 'success',
            solid: true
          });
        });
      }else{
        this.storeSettlementCatalog(form).then( (e)=> {
          this.$refs.modal_create_settlementCatalog.hide();
          this.updateRows();
          this.$root.$bvToast.toast(this.$t('Created successfully'), {
            title: this.$t('Success'),
            variant: 'success',
            solid: true
          });
        })
      }
    },
  },
  created() {
    this.page = this.filters.page ? Number(this.filters.page) : 1;
    this.fetchData();
    this.getEnum("CatalogsElementType").then((data)=>{
      for (let dataKey in data) {
        this.catalogsTypeOptions.push({text:this.$t(dataKey),value:dataKey});
      }
    });
    this.getExpenseCategory().then((data)=>{
      this.expensesCategories = data.map(item=>({
        text: this.$t(item.text),
        value:item.value
      }));
    });
  },
  computed: {
    ...mapState('loads', ["settlementCatalog"]),
    list(){
      return this.settlementCatalog
    },
    options(){
      let filters = []
      return [
        {
          icon: 'far fa-key',
          label: 'ID',
          type: 'text',
          filterProperty: 'id',
        },
        {
          icon: 'far fa-calendar-alt',
          label: this.$t('Name'),
          type: 'text',
          filterProperty: 'name'
        },
        ...filters
      ];
    },
  },
  data(){
    return{
      fetching:false,
      update:false,
      itemID:false,
      page: 1,
      searchModel: "",
      form: {},
      expensesCategories: [],
      catalogsTypeOptions: [],
    }
  },
  watch: {
    filters : {
      deep: true,
      handler(newVal, oldVal){
        if(!_.isEqual(newVal, oldVal)){
          this.fetchData();
        }
      }
    },
    page(newVal){
      this.pageChange(newVal)
    },
    'form.name':function () {
      if (!this.update){
        this.form.code = codeGenerate(this.form.name);
      }
    }
  },
}
</script>

<style scoped>
  .badges_container{
    max-width: 200px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: wrap;
  }
  .badges_container span.badge{
    margin-right: 5px;
    margin-top: 5px;
  }
</style>