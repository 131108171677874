<template>
    <div class="card">
        <div v-if="title" class="card-header">
            <h6>{{ title }}</h6>
        </div>
        <div class="card-body">
            <div class="mb-5">
                <div v-if="load.id" class="row">
                    <div class="col-md-6">
                        <h5>
                            <i class="far fa-dolly-flatbed-alt mr-3" />{{
                                $t("Load")
                            }}: {{ load.id }}
                        </h5>
                    </div>
                    <div class="col-md-6 text-right">
                        <router-link
                            v-can="'loads.update'"
                            v-if="
                                editableStatus.indexOf(load.status) > -1 ||
                                    $route.params.profile === 'admin'
                            "
                            :to="{
                                name: 'loads.edit',
                                params: { load_id: load.id },
                            }"
                            class="btn btn-light btn-sm"
                            v-show="$route.params.profile !== 'carrier'"
                        >
                            <i class="far fa-edit mr-2" />{{ $t("Edit") }}
                        </router-link>

                        <b-dropdown
                            :text="$t('Event log')"
                            variant="light"
                            size="sm"
                            class="m-2"
                            v-if="
                                statusActive.length > 0 &&
                                    (load.truck !== null ||
                                        load.driver !== null)
                            "
                            v-show="
                                $route.params.profile !== 'shipper' &&
                                    load.status !== 'not_assigned'
                            "
                            :disabled="isCompletedStatus"
                        >
                            <b-dropdown-item-button
                                href="#"
                                :key="'st-' + index"
                                :disabled="item.disabled"
                                v-for="(item, index) in statusActive"
                                @click="onChange(item)"
                            >
                                <strong :class="item.class">{{
                                    item.event
                                }}</strong>
                            </b-dropdown-item-button>
                        </b-dropdown>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-5">
                                <div
                                    class="field-wrapper border-bottom-none p-0"
                                >
                                    <div class="field-label">
                                        {{ $t("TMS ID") }}
                                    </div>
                                    <div class="field-help">
                                        {{ load.tms_id ? load.tms_id : "- -" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div
                                    class="field-wrapper border-bottom-none p-0"
                                >
                                    <div class="field-label">
                                        {{ $t("Shipment ID") }}
                                    </div>
                                    <div class="field-help">
                                        {{
                                            load.tms_shipment_id
                                                ? load.tms_shipment_id
                                                : "- -"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">
                                {{ $t("Tracking Number") }}
                            </div>
                            <div class="field-help">
                                {{
                                    load.shipper_external_id
                                        ? load.shipper_external_id
                                        : "- -"
                                }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-5">
                                <div
                                    class="field-wrapper border-bottom-none p-0"
                                >
                                    <div class="field-label">
                                        {{ $t("Load type") }}
                                    </div>
                                    <div class="field-help">
                                        {{
                                            load.load_type
                                                ? load.load_type.name
                                                : "- -"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div
                                    class="field-wrapper border-bottom-none p-0"
                                >
                                    <div class="field-label">
                                        {{ $t("Configuration") }}
                                    </div>
                                    <div class="field-help">
                                        {{
                                            load.configuration
                                                ? load.configuration.name
                                                : "- -"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">
                                {{ $t("Truck type") }}
                            </div>
                            <div class="field-help">
                                <i class="far fa-truck mr-2" />{{
                                    load.truck_type
                                        ? load.truck_type.name
                                        : "- -"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-5">
                                <div
                                    class="field-wrapper border-bottom-none p-0"
                                >
                                    <div class="field-label">
                                        {{ $t("Weight") }}
                                    </div>
                                    <div class="field-help">
                                        <i class="far fa-weight mr-2" />{{
                                            load.weight
                                        }}
                                        {{ load.weight_unit }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div
                                    class="field-wrapper border-bottom-none p-0"
                                >
                                    <div class="field-label">
                                        {{ $t("Container type") }}
                                    </div>
                                    <div class="field-help">
                                        <i
                                            class="far fa-container-storage mr-2"
                                        />{{ load.container_type }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">{{ $t("Commodity") }}</div>
                            <div class="field-help">
                                {{
                                    load.commodity ? load.commodity.name : "- -"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4" v-if="load.carrier_skills.length > 0">
                    <div class="col-md-12">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">
                                {{ $t("Required carrier's skills") }}
                            </div>
                            <div class="field-help">
                                <span
                                    class="badge badge-outline-info mr-2"
                                    :key="'skill-' + item.id"
                                    v-for="item in load.carrier_skills"
                                    >{{ item.name }}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">
                                {{ $t("Additional information") }}
                            </div>
                            <div class="field-help">{{ load.comments }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-5">
                <div class="d-flex justify-content-between">
                    <h5>
                        <i class="far fa-truck-loading mr-3" />{{
                            $t("Pickup")
                        }}
                    </h5>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">{{ $t("Date") }}</div>
                            <div class="field-help">{{ load.pickup.date }}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">
                                {{ $t("Pickup schedule") }}
                            </div>
                            <div class="field-help">
                                <i class="far fa-clock mr-2" />{{
                                    load.pickup.schedule_from | time
                                }}
                                - {{ load.pickup.schedule_to | time }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">{{ $t("Address") }}</div>
                            <div
                                class="field-help"
                                :key="'pickup-line-' + index"
                                v-for="(item, index) in getAddressFormat(
                                    load.pickup.address
                                )"
                            >
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">
                                {{ $t("References") }}
                            </div>
                            <div class="field-help">
                                {{ load.pickup.address.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-5">
                <div class="">
                    <h5>
                        <i class="far fa-shipping-timed mr-3" />{{
                            $t("Delivery")
                        }}
                    </h5>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">{{ $t("Date") }}</div>
                            <div class="field-help">
                                {{ load.delivery.date }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">
                                {{ $t("Delivery schedule") }}
                            </div>
                            <div class="field-help">
                                <i class="far fa-clock mr-2" />{{
                                    load.delivery.schedule_from | time
                                }}
                                - {{ load.delivery.schedule_to | time }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">{{ $t("Address") }}</div>
                            <div
                                class="field-help"
                                :key="'pickup-line-' + index"
                                v-for="(item, index) in getAddressFormat(
                                    load.delivery.address
                                )"
                            >
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div class="field-label">
                                {{ $t("References") }}
                            </div>
                            <div class="field-help">
                                {{ load.delivery.address.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="" v-if="load.stops.length > 0">
                <div class="">
                    <h5>
                        <i class="far fa-location-circle mr-3" />{{
                            $t("Stops")
                        }}
                    </h5>
                </div>
                <div
                    class="row mt-4"
                    :key="'stop-detail-' + index"
                    v-for="(stop, index) in load.stops"
                >
                    <div class="col-md-12">
                        <div class="field-wrapper border-bottom-none p-0">
                            <div
                                class="field-label cursor-pointer d-flex justify-content-between"
                            >
                                <span>{{ $t("Stop") }} {{ index + 1 }}</span>
                                <badge-status :status="stop.status" />
                            </div>
                            <div class="field-help">
                                {{stop.address | address-format}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import addressFormat from '@/helpers/address-format';

export default {
    name: "LoadDetail",
    props:{
        load: {
            type: Object,
            required:true
        },
        title: null,
        editableStatus: null,
        statusActive: null,
    },
    methods: {
        getAddressFormat(address){
            return addressFormat(address)
        },
        isCompletedStatus(){
            if(this.load.status === 'delivered') return true;
            return false;
        },
        onChange(item){
            let payload = {
                id      : this.$route.params.load_id,
                stop_id : item.stop_id,
                status  : item.status,
            };
            this.changeStatus(payload).then(data => {
                this.$root.$bvToast.toast(
                    this.$t("The action was successful!", { resource: this.$t("Tenders").toLowerCase() }),
                    {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true
                });
                this.getStatus({id : this.$route.params.load_id}).then(data => {}).catch(error => {});
                this.getEvents({id : this.$route.params.load_id}).then(data => {}).catch(error => {});
                this.getLoad({id : this.$route.params.load_id}).then(data => {}).catch(error => {});


            }).catch(error => { this.processServiceError(error); }).finally(() => {});
        },
    },
};
</script>

<style scoped></style>
