<template>
    <div class="card card-txp">
        <div class="row no-gutters">
            <div class="col-md-4 d-flex justify-content-center rounded-left bg-gradient-txp">
                <img :src="src" width="200" class="p-3">
            </div>
            <div class="col-md-7 offset-1">
                <div class="row">
                    <div class="col-10">
                        <div v-if="truck" class="mt-md-4">
                            <h5>{{ truck.name }}</h5>
                            <p><i class="ri-information-fill"></i> {{$t('Max load')}}: {{ truck.max_weight }} Kg.</p>
                        </div>
                        <div v-else class="mt-md-5">
                            <p>{{$t('No items')}}.</p>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="float-right mx-3 my-2">
                            <i class="ri-checkbox-circle-line text-primary font-size-22" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "TruckCard",
    props: {
        truck: { type: Object }
    },
    computed: {
        src(){
            try { // validate if exist image
                let code = this.truck.truck_type_code !== 'TRA' ? this.truck.truck_type_code : this.truck.trailer_size_code;
                return require('@/assets/images/marketplace/trucks/' + code + '.svg');
            } catch (e) {
                return require('@/assets/images/marketplace/trucks/48FT.svg');
            }
        }
    }
}
</script>

<style scoped>
img { min-height: 140px; }
.ri-truck-fill { font-size:4.5rem; }
</style>
