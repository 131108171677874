import Swal from "sweetalert2";

export default {
    data(){
        return {
            resourceName: '',
        }
    },
    methods: {
        confirmDelete(){
            Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, delete it!"),
                cancelButtonText: this.$t("Cancel")
            }).then(result => {
                if(result.value && this.deleteMany) {
                    this.deleteMany()
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t("The items have been deleted.", {resource: this.resourceName}), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                        })
                        .catch(error =>{
                            Swal.fire(
                                "Error",
                                this.$t("Something went wrong!"),
                                "error"
                            );
                        });
                }
            });
        }
    }
}