<template>
  <div class="">
    <div class="header_title row">
      <div class="col-md-6 row" style="width: 100%">
        <h1 class="text-white mb-0 pr-5">{{title}}</h1>
        <slot name="action-button" class="col-md-6" />
      </div>
      <div class="col-md-6 d-flex flex-row-reverse" style="width: 100%">
        <slot name="buttons" />
      </div>
    </div>
    <div v-if="headband" class="header_title_banner">
      <div class="header_title_banner_content">
        <div class="header_title_banner_content_separated" v-for="i in 12" :key="i" :style="{left: (i*8.333)+'%'}"/>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "HeaderTitle",
  props:{
    headband : {
      type: Boolean,
      default(){
        return false
      }
    },
    title : {
      type: String,
      default(){
        return this.$t("Shipments");
      }
    }
  }
}
</script>

<style scoped>

</style>
