<template>
    <div>
        <page-header :title="$t('Account')" :items="breadcrumb"/>

        <validation-observer ref="observer" v-slot="{ validate }">
            <b-form @submit.stop.prevent="onSubmit(validate)">
                <div class="card">
                    <div class="card-header">
                        <h6>{{$t(('Organization'))}}</h6>
                    </div>
                    <div class="form-fields">
                        <form-input
                                :title="$t('Name')"
                                :help="$t('Organization name')"
                                v-model="form.profile.name"
                                rules="required"
                                type="text"
                                :name="'name'"
                        />

                        <form-input
                                :title="$t('TMS')"
                                v-model="form.profile.tms_id"
                                rules=""
                                type="text"
                                :name="'profile.tms_id'"
                                :disabled="true"
                        />

                        <form-input
                                :title="$t('SAP')"
                                v-model="form.profile.sap_id"
                                rules=""
                                type="text"
                                :name="'profile.sap_id'"
                                :disabled="true"
                        />
                        <form-input
                                :title="$t('Logo')"
                                v-model="form.logo"
                                rules=""
                                type="avatar"
                                :name="'logo'"
                        />
                    </div>
                </div>

                <address-form v-model="form.profile.address" :title="$t('Address')" />

                <div class="d-flex justify-content-start mb-4">
                    <button type="submit" class="btn btn-primary">{{$t('Save')}}</button>
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import AddressForm from '@/components/form/AddressForm';

    export default {
        name: "Account",
        components:{
            AddressForm
        },
        mixins: [formMixin],
        data(){
            return {
                loading : false,
                form: {}
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Settings"),
                        to: {name:'settings'}
                    },
                    {
                        text: this.$t('Account'),
                        to: {name:'settings.account'},
                        active: true
                    }
                ]
            }
        },
        created () {
            this.form = _.cloneDeep(this.profile.account);
        },
        methods: {
            ...mapActions('accounts',['updateAccount']),
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    return;
                }
                this.loading = true;
                let form = _.cloneDeep(this.form);

                this.updateAccount(form)
                    .then(()=>{
                        this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                    
                    })
                    .catch(error=>{
                        this.processServiceError(error);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });

            }
        },
    }
</script>

<style scoped>

</style>
