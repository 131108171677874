import axios from "../../api/client";


// initial state
export const state = () => ({
    fetching: false,
    settlements : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    settlement: null,
    last_filters: {},
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchSettlements({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/settlements', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
        });
    },
    getSettlements({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/settlements',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setSettlements', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    storeSettlement({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/settlements`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getSettlement({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/settlements/${params.id}`)
                .then(response=>{
                    commit('setSettlement', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getSettlementByLoad({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/settlements`)
                .then(response=>{
                    console.log(response.data)
                    if (!response.data)
                        commit('setSettlement', null);
                    else 
                        commit('setSettlement', response.data);

                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateSettlement({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/settlements/${payload.id}`, payload)
                .then(response=>{
                    let settlements = _.cloneDeep(state.settlements);
                    let index = settlements.results.findIndex(item => item.id === response.data.id);
                    if(index > -1){
                        settlements.results[index] = response.data;
                        commit('setSettlements', settlements);
                    }
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteSettlement({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/settlements/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    autorizedSettlement({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/settlements/authorized/batch`,payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    settlingSettlement({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/settlements/settling/batch`,payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    declinedSettlement({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/settlements/declined/batch`,payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    sendSettlement({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/settlements/send`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getConceptSettlement({commit}, query) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${query.id}/pre-settlements`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    }
};

// mutations
export const mutations = {
    setSettlements(state, data){
        state.settlements = data;
    },
    setSettlement(state, data){
        state.settlement = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

