<template>
  <div class="mb-5" >
    <div v-for="a in number_loads" class="skeleton_load">
      <div class="skeleton_load_left">
        <div class="skeleton_load_left_first">
          <b-skeleton width="50%" height="15px"></b-skeleton>
        </div>
        <div class="skeleton_load_left_left">
          <b-skeleton width="80%" height="15px"></b-skeleton>
        </div>
        <div class="skeleton_load_left_right">
          <b-skeleton width="40%" height="20px"></b-skeleton>
          <b-skeleton width="90%" height="30px"></b-skeleton>
        </div>
      </div>
      <div class="skeleton_load_right">
        <div class="skeleton_load_right_left">
          <b-skeleton width="12%" height="40px"></b-skeleton>
          <b-skeleton width="12%" height="40px"></b-skeleton>
          <b-skeleton width="12%" height="40px"></b-skeleton>
          <b-skeleton width="12%" height="40px"></b-skeleton>
          <b-skeleton width="12%" height="40px"></b-skeleton>
          <b-skeleton width="12%" height="40px"></b-skeleton>
          <b-skeleton width="8%" height="40px"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TruckLoading",
  props : {
    number_loads :  {
      type: [Number, String],
      default: 15
    }
  }
}
</script>


<style scoped lang="scss">

.skeleton_load{
  border-radius: 20px;
  width: 100%;
  border: 1px solid rgba(221, 221, 221, 0.4117647059) !important;
  box-shadow: 0 6px 10px rgb(204 204 204 / 50%);
  display: flex;
  margin-top: 15px;

  &_left{
    width: 30%;
    display: flex;
    &_first{
      width: 80px;
      display: flex;
      justify-content: center;
      align-content: center;
      position: relative;
      .b-skeleton{
        position: absolute;
        top: 40%;
      }
    }
    &_left{
      padding: 15px 20px;
      width: calc(25%);
      text-align: center;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
    &_right{
      padding: 15px 20px;
      width: calc(100% - 25% - 80px);
      .b-skeleton{
        margin-bottom: 10px;
      }
    }
  }
  &_right{
    width: calc(70% - 2px);
    background: #F9F9F9;
    display: flex;
    &_left{
      width: 100%;
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

}

</style>