import axios from "../../api/client";

export const state = {
    type: null,
    message: null,
    notifications: {
        results: [],
    },
    unread_notifications: 0
};

export const mutations = {
    success(state, message) {
        state.type = 'success';
        state.message = message;
    },
    error(state, message) {
        state.type = 'danger';
        state.message = message;
    },
    clear(state) {
        state.type = null;
        state.message = null;
    },
    setNotifications(state, data) {
        state.notifications = data;
    },
    setUnread(state, data) {
        state.unread_notifications = data;
    },
    incrementUnread(state) {
        state.unread_notifications++;
    },
    decrementUnread(state) {
        state.unread_notifications--;
    }
};

export const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    },

    getNotifications({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.post(`/notifications`, { next_token: params.next_token }, {
                headers: {
                    'X-Workspace': `dms:${params.tenant_id}`
                }
            })
                .then(response => {
                    commit('setNotifications', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)

                });
        });
    },
    getNotification({ commit }, params, tenant_id) {
        return new Promise((resolve, reject) => {
            axios.get(`/notifications/${params.id}`, {
                headers: {
                    'X-Workspace': `dms:${tenant_id}`
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                });
        });
    },
    getUnreadNotifications({ commit }, tenant_id) {
        return new Promise((resolve, reject) => {
            axios.get(`/unread/notifications`, {
                headers: {
                    'X-Workspace': `dms:${tenant_id}`
                }
            })
                .then(response => {
                    commit('setUnread', response.data.count);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                });
        });
    },
    markAsRead({ commit }, tenant_id) {
        commit('setUnread', 0);
        return new Promise((resolve, reject) => {
            axios.put(`/unread/notifications`, {}, {
                headers: {
                    'X-Workspace': `dms:${tenant_id}`
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                });
        });
    },
    addNewNotification({ commit, state }, notification) {
        let notifications = _.cloneDeep(state.notifications);
        notifications.data.unshift(notification);
        commit('setNotifications', notifications);
        commit('incrementUnread');
    }
};