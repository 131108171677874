<template>
    <div class="pt-3" v-if="!loading">
        <documents-grid :resourceTxt="resource" :resource_id="resource_id"></documents-grid>
    </div>
    <loading v-else></loading>
</template>

<script>

    import DocumentsGrid from "@/views/documents/DocumentsGrid"

    export default {
        name: "TrailerDocuments",
        components:{
            DocumentsGrid
        },
        data(){
            return {
                resource:'trailers',
                resource_id: parseInt(this.$route.params.trailer_id),
                loading: true
            }
        },
        created() {
            this.loading = false
        }
    }
</script>

<style>

</style>
