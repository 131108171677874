<template>
    <div>
        <page-header :title="$t($route.meta.title)" />

        <div class="row">
            <div :class="[$route.meta.class ? $route.meta.class:'col-md-8 offset-md-2']">
                <validation-observer ref="observer" v-slot="{ validate }">
                    <b-form @submit.stop.prevent="onSubmit(validate)">
                        <div class="card">
                            <div class="card-header">
                                <h6>General</h6>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Load')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('Load ID')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="load_id" rules="required" v-slot="{ errors }">
                                            <b-input-group class="">
                                               <b-form-input  v-model="loadId" type="number" placeholder=""></b-form-input>
                                                <b-input-group-append>
                                                    <b-button :disabled="!loadId" @click="getLoadDetail" variant="primary">
                                                        <i class="far fa-search" />
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Driver')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="plate"  v-slot="{ errors }">
                                            <user-input v-model="form.driver" :filters="{roles: 'driver'}" :disabled="true" />
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <form-input
                              :title="$t('Date')"
                              :help="$t('Event date')"
                              v-model="form.date"
                              rules="required"
                              type="date"
                              :name="'date'"
                              dateFormat="YYYY-MM-DD"
                          />

                            <form-input
                                    :title="$t('Department')"
                                    v-model="form.department"
                                    rules="required"
                                    type="select"
                                    :name="'department'"
                                    :options="departments"
                                    v-on:change="onDepartmentChange"
                            />

                            <form-input
                                    :title="$t('Subject')"
                                    v-model="form.settlement_catalog_id"
                                    rules="required"
                                    type="select"
                                    :name="'subject'"
                                    :options="subjects"
                                    :loading="fetching"
                                    v-on:change="onSubjectChange"
                            />

                            <form-input
                                    :title="$t('Description')"
                                    v-model="form.description"
                                    rules="required|max:255"
                                    type="textarea"
                                    :name="'description'"
                                    
                            />

                            <form-input
                                    v-if="quantity"
                                    :title="$t('Quantity')"
                                    v-model="form.quantity"
                                    rules="required|min_value:1"
                                    type="number"
                                    :name="'quantity'"
                                    :step="1"
                                    v-on:change="onQuantityChange"
                            />

                            <form-input
                                    :title="$t('Amount')"
                                    v-model="form.amount"
                                    :rules="'required|min_value:0.01'+ (subject ? (subject.max_amount ?'|max_value:'+subject.max_amount : ''): '')"
                                    type="number"
                                    name="amount"
                                    :disabled="amountDisabled"
                                    v-on:change="onAmountChange"
                            />
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Currency')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="currency"  v-slot="{ errors }">
                                            <currency-input v-model="form.currency" :disabled="loading" />
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <form-input
                                    :title="$t('Truck damage')"
                                    :help="$t('Turn on if damage was on the truck')"
                                    v-model="truck_damage"
                                    rules=""
                                    type="boolean"
                                    :name="'truck_damage'"
                            />
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Truck')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="truck_id"  v-slot="{ errors }">
                                            <truck-input v-model="form.truck" :disabled="true" />
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <form-input
                                    :title="$t('Trailer damage')"
                                    :help="$t('Turn on if damage was on the trailer')"
                                    v-model="trailer_damage"
                                    rules=""
                                    type="boolean"
                                    :name="'trailer_damage'"
                            />
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Trailer')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="trailer_id"  v-slot="{ errors }">
                                            <trailer-input v-model="form.trailer" :disabled="true" />
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                        </div>

                         <address-form v-model="form.address" :title="$t('Event location')"  :required="false"  />

                        <div class="d-flex justify-content-end pb-4">
                            <button :disabled="loading" type="button" class="btn btn-light mr-3 ">{{$t('Cancel')}}</button>
                            <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t('Save')}}</button>
                        </div>

                    </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import Multiselect from 'vue-multiselect';
    import TruckInput from '@/components/form/TruckInput';
    import TrailerInput from '@/components/form/TrailerInput';
    import CarrierInput from '@/components/form/CarrierInput';
    import UserInput from '@/components/form/UserInput';
    import CurrencyInput from '@/components/form/CurrencyInput';
    import AddressForm from '@/components/form/AddressForm';

    export default {
        name:'OperationalDeductionForm',
        beforeRouteEnter(to, from, next) {
            next((vm) =>  vm.from = from)
        }, 
        components:{
            Multiselect,
            TruckInput,
            TrailerInput,
            CarrierInput,
            UserInput,
            CurrencyInput,
            AddressForm
        },
        mixins: [formMixin],
        data(){
            return {
                title: '...',
                loadId: null,
                form: {address:{}},
                createNew: false,
                loading: false,
                from: null,
                truck_damage: false,
                trailer_damage: false,
                load: null,
                fetching : false,
                subject: null,
                amountDisabled: false,
                quantity : false
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapGetters('catalogs',['operationalDeductionsDepartments']),
            ...mapState('loads', ['settlementCatalogSelectData']),
            loadLength(){
                return this.load_length
            },
            departments(){
                return this.operationalDeductionsDepartments.map(item => ({text: item, value: item}))
            },
            subjects(){
                if(!this.settlementCatalogSelectData){
                    return [];
                }
                return this.settlementCatalogSelectData.map(item =>({text: item.name, value:item.id}));
            }
        },
        watch: {
            truck_damage(value){
                this.assignData();
            },
            trailer_damage(value){
                this.assignData();
            }
        },
        created() {

            this.resetForm();

            if(this.$route.query.load_id){
                this.loadId = this.$route.query.load_id;
                this.getLoadDetail();
            }

        },
        methods: {
            ...mapActions('operationaldeductions',['getOperationalDeduction','storeOperationalDeduction']),
            ...mapActions('loads',['getLoad','getSettlementCatalogSelectData']),
            resetForm() {
                this.form = {payments_number: 1, address:{}};
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },
            saveAndCreateNew(){
                this.createNew = true;
                this.$refs.submit.click();
            },
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    this.$bvToast.toast(this.$t('Please, check the form'), {
                        title: "Error",
                        variant: 'danger',
                        solid: true
                    });
                    return;
                }

                this.loading = true;
                let form = {...this.form};

                if(form.truck){
                    form.truck_id = form.truck.id;
                }
                if(form.driver){
                    form.driver_id = form.driver.id;
                }
                if(form.trailer){
                    form.trailer_id = form.trailer.id;
                }

                delete form.truck;
                delete form.trailer;
                delete form.driver;

                this.storeOperationalDeduction(form)
                    .then(data=>{
                        this.$root.$bvToast.toast(this.$t('Created successfully'), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                                });
                        this.redirect();
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });
            },
            redirect(){
                if(this.from.name){
                    this.$router.back();
                }else{
                    this.$router.push({name:'finance.operational-deductions.index'});
                }                
            },
            getLoadDetail()
            {
                this.getLoad({id: this.loadId})
                    .then(data =>{
                        this.load = _.cloneDeep(data);
                        this.assignData();
                    })
                    .catch(error =>{

                    });
            },
            assignData()
            {
                if(this.load){
                    let form = _.cloneDeep(this.form);
                    form.load_id = this.load.id;
                    form.driver = this.load.driver;

                    if(this.truck_damage){
                        form.truck = this.load.truck;
                    }else{
                        form.truck = null;
                    }
                    if(this.trailer_damage){
                        form.trailer = this.load.trailer;
                    }else{
                        form.trailer = null;
                    }
                    this.form = form;
                }
            },
            onDepartmentChange(department){
                this.form.settlement_catalog_id = null;
                this.amountDisabled = false;
                this.form.amount = null;
                this.form.quantity = 1;
                this.quantity = false;

                this.fetching = true;
                this.getSettlementCatalogSelectData({department: department})
                    .then(()=>{
                    }).catch(error =>{

                    }).finally(()=>{
                        this.fetching = false;
                    });
                this.form.department = department;
            },
            resetData(){
                
            },
            onSubjectChange(settlementCatalogId){
                this.subject = this.settlementCatalogSelectData.find(item => item.id === settlementCatalogId);
                
                this.form.payments_number = 1;
                this.form.amount = 0;
                this.amountDisabled = false;
                this.quantity = false;

                if(this.subject.payment_type === 'instant'){
                    if(this.subject.amount){
                        this.form.amount = this.subject.amount;
                        this.amountDisabled = true;
                    }
                }else if(this.subject.payment_type === 'per_event'){
                    this.form.amount = this.subject.amount;
                    this.amountDisabled = true;
                }else if(this.subject.payment_type === 'per_device'){
                    this.form.amount = this.subject.amount;
                    this.quantity = true;
                    this.form.quantity = 1;
                    this.amountDisabled = true;
                }else if(this.subject.payment_type === 'deferred'){
                    
                }
                //console.log(this.subject, settlementCatalogId);
            },
            onQuantityChange(value){
                let form = _.cloneDeep(this.form);
                form.amount = this.subject.amount * value;
                this.form = form;
            },
            onAmountChange(amount){
                if(this.subject.payment_type === 'deferred'){
                    this.form.payments_number = Math.ceil(amount/this.subject.amount);
                }
            }
        }
    }
</script>

<style>

</style>
