<template>
  <div class="main_container">
    <div class="header_line"></div>
    <div class="background_container">
      <div class="background_container_logo">
        <img src="@/assets/images/txp_gray.png" class="background_container_logo_img"  alt="">
      </div>
      <div class="background_container_card">
        <h3>
          <span>La nueva forma de </span> <br>
          mover al mundo
        </h3>
        <p>No importa donde estés, Traxporta lo hace más fácil.</p>
      </div>
      <div class="background_container_bagde">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </div>
    <div class="content">
      <router-view/>
    </div>
    <div class="locales">
      <a class="text-info font-weight-bold cursor-pointer"  v-if="$i18n.locale==='es'"  @click="setLocaleAction('en')">
        <span class="text-sm">English</span>
      </a>
      <a class="text-info font-weight-bold cursor-pointer"  v-if="['en','en-US'].indexOf($i18n.locale) > -1  "  @click="setLocaleAction('es')">
        <span class="text-sm">Español</span>
      </a>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/style.scss";
import {mapMutations} from "vuex";

export default {
  name: "RegisterLayout",
  data () {
    return { langs: ['es', 'en'] }
  },
  methods:{
    ...mapMutations('locale',['setLocale']),
    setLocaleAction(locale){
      this.setLocale(locale)
    }
  }
}
</script>

<style scoped>
.locales{
  position: absolute;
  bottom: 30px;
  right: 50px;
}
</style>