<template>
    <div id="map">
        <GmapMap ref="mapRef" :center="{ lat: 19.4795587, lng: -99.3274917 }" :zoom="9" :options="options"
            :style="{ height: '300px' }" class="polyline">
            <GmapMarker>
                <GmapPolyline :path.sync="path" :options="polylineOptions" />
                <GmapMarker v-for="(marker, index) in markers" :key="index"
                    :position="{ lat: marker.lat, lng: marker.lng }" :icon="icons[index]" />
            </GmapMarker>
        </GmapMap>
    </div>
</template>
<script>

import mapStyle from "@/constants/mapStyle";

export default {
    name: "PolylineMap",
    //TODO: agregar el heigth como prop para reutilizar
    props: {
        center: {
            type: Object,
        },
        path: {
            type: Array
        },
        markers: {
            type: Array
        },
        extra_options: {
            type: Object
        }
    },
    mounted() {
        this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = map;
            this.fitMap();
        });
    },
    computed: {
        options() {
            return {...this.default_options, ...this.extra_options}
        }
    },
    data() {
        return {
            default_options: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                styles: mapStyle
            },
            polylineOptions: {
                icons: [{
                    icon: {
                        url: require('../../assets/images/txp/common/end_point.png'),
                        scale: 2,
                        fillColor: '#22bf15'
                    },
                    offset: '0',
                    repeat: '100px'
                }]
            },
            icons: [
                {
                    scaledSize: { width: 50, height: 60, f: 'px', b: 'px' },
                    url: require('../../assets/images/txp/common/entrega.png')
                },
                {
                    scaledSize: { width: 50, height: 60, f: 'px', b: 'px' },
                    url: require('../../assets/images/txp/common/recoleccion.png')
                }
            ],
            map: null,
        }
    },
    methods: {
        fitMap() {
            if (this.map) {
                let bounds = new google.maps.LatLngBounds();
                if (this.markers) {
                    for (let item of this.markers) {
                        bounds.extend({ lat: item.lat, lng: item.lng });
                    }
                }
                if (this.path){
                  for (let item of this.path) {
                    bounds.extend({ lat: item.lat, lng: item.lng });
                  }
                }
                this.map.fitBounds(bounds);
            }
        }
    }
}
</script>
<style lang="scss">
.polyline {
    div {
        border-radius: 20px;
    }
}
</style>
