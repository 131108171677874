<template>
    <div class="font-family-helvetica bg-fc main-card">
        <div class="text-center py-3 bg-white border-top-txp position-relative">
            <img src="@/assets/images/txp/logo.png" alt="traxporta" width="200">
        </div>
        <br>
        <div class="row mt-5" v-if="!status">
            <div class="col-md-7 col-12">
                <preview :shipment="shipment" />
            </div>
            <div class="col-md-4 ml-md-5 col-12">
                <confirm-rate :confirm="confirm" :fetching="fetching" />
            </div>
        </div>
        <accepted-card v-if="status === 'confirmed'" />
        <declined-card v-if="status === 'declined'" />
        <error-card v-if="status === 'has-confirmed'" status="has-confirmed" />
        <error-card v-if="status === 'expired'" status="expired" />
        <br>
        <div class="text-center font-size-12 p-3 mt-5">
            <img src="@/assets/images/txp/icono.png" alt="traxporta" width="50" class="shadow-lg rounded-lg">
            <div class="mt-3">
                Traxporta 2023. <span class="text-muted">Todos los derechos reservados.</span>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import Preview from "./Preview.vue";
import Overview from "./Overview.vue";
import ConfirmRate from "@/components/marketplace/rate/ConfirmRate.vue";
import AcceptedCard from "@/components/marketplace/rate/AcceptedCard.vue";
import DeclinedCard from "@/components/marketplace/rate/DeclinedCard.vue";
import ErrorCard from "@/components/marketplace/rate/ErrorCard.vue";

export default {
    name: "Summary",
    components: { ConfirmRate, Preview, Overview, AcceptedCard, DeclinedCard, ErrorCard  },
    data() {
        return {
            fetching: false,
            shipment: {},
            status: null
        }
    },
    created() {
        this.fetching = true;
        this.verifyToken({token: this.$route.params.token})
            .then(data => {
                this.shipment = data.data;
            })
            .catch(error => {
                switch (error.status) {
                    case 402:
                        this.status = "has-confirmed"
                        break;
                    case 500:
                        this.status = "expired"
                        break;
                    default:
                }
                console.log("error", error);
            })
            .finally(() => {
                this.fetching = false;
            });
    },
    methods: {
        ...mapActions('rate', ['verifyToken', 'rateConfirmation']),
        confirm(status){
            let params = {
                'token': this.$route.params.token,
                'status': status
            }
            this.fetching = true;
            this.rateConfirmation(params)
                .then(data => {
                    console.log("result", data);
                    this.status = status
                })
                .catch(error => {
                    console.log("error", error);
                    this.$root.$bvToast.toast(this.$t("Error while rate confirmation"), {
                        title: this.$t('Error rate'),
                        variant: 'danger',
                        solid: true
                    });
                })
                .finally(() => {
                    this.fetching = false;
                });
        }
    },
}
</script>

<style lang="scss" scoped>

.bg-fc{ background-color: #FCFCFC; }
.bg-ff{ background-color: #FFFFFF; }
.border-top-txp{
    border-top: 5px solid!important;
    border-image: repeating-linear-gradient(270deg, #00B3E6 0%, #E3ED55 100%) 10;
}

.main-card {
    background-image: url('~@/assets/images/marketplace/rate/back_rate_confirmation.png');
    background-size: 50% 50%;
    background-repeat: no-repeat;
}

.main-card_{
    &::before {
        transform: rotate(180deg);
        background-image:
        linear-gradient(90deg, #FFFFFF 0%, rgba(255,255,255,0) 100%),
        url('~@/assets/images/marketplace/rate/back_rate_confirmation.png');
        background-size: cover;
        background-position: top;
        max-width: 720px;
        content: "";
        position: absolute;
        top: 65px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.40;
    }
}
</style>
