<template>
    <div class="row">
        <div class="col-md-10 offset-md-1 col-12">
            <div class="card mt-3">
                <div class="card-header centered font-weight-bold">{{$t('Returns')}}</div>
                <div class="card-body">
                    <loading class="py-md-3" v-if="fetching" />
                    <template v-else>
                        <select-shipments
                            v-if="step === 1"
                            :shipments="shipments"
                            :selectAll="selectAll"
                            :onNext="() => this.step = 2"
                            :fetching="fetching"
                        />
                        <div v-if="step === 2">
                            <reason-type-select
                                :type="type"
                                :types="types"
                                :reason="reason"
                                :reasons="reasons"
                                :setType="setType"
                                :setReason="setReason"
                            />
                            <select-products
                                :shipments="shipments"
                                :fetching="fetching"
                                :onSave="onSave"
                                :onBack="onBack"
                                :quantity_required="quantity_required"
                                :comments_required="comments_required"
                                :disabledNext="reason ? false : true"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import SelectShipments from '@/components/shipmentreturn/SelectShipments.vue';
import SelectProducts from '@/components/shipmentreturn/SelectProducts.vue';
import ReasonTypeSelect from '@/components/shipmentreturn/ReasonTypeSelect.vue';
import Swal from "sweetalert2";

export default {
    name: "New",
    components: {
        SelectShipments,
        SelectProducts,
        ReasonTypeSelect
    },
    data(){
        return {
            fetching: false,
            step: 1,
            shipments: [],
            type: null,
            reason: null,
            comments_required: false,
            quantity_required: false,
        }
    },
    computed: {
        ...mapState('loads', ['load', 'load_shipments']),
        ...mapGetters('shipmentreturn', ['types', 'reasons', 'shipments_details', 'load_return']),
    },
    methods: {
        ...mapActions('loads', ['getLoadShipments']),
        ...mapActions('shipmentreturn', ['getShipmentsDetails', 'getTypes', 'getReasons', 'storeShipmentReturn', 'getShipmentReturn']),
        selectAll(checked){
            this.shipments.map(shipment => {
                shipment.details.map(detail => {
                    detail.checked = checked
                });
            });
        },
        onSave(){
            this.fetching = true;
            let copy_shipments = _.cloneDeep(this.shipments);
            let payload = [];
            copy_shipments.map(shipment => {
                shipment.details.map(detail => {
                    if(detail.checked){
                        let item = {
                            shipment_id: detail.shipment_id,
                            load_id: this.load._id,
                            type: this.type,
                            reason: this.reason,
                            details: [{
                                shipment_detail_id: detail.id,
                                customer_reference: detail.customer_reference,
                                code: detail.code,
                                lot: detail.lot,
                                description: detail.description,
                                quantity: Number(detail.quantity_send),
                                expiration_date: detail.expiration_date,
                                replenishment_order: detail.replenishment_order,
                                replenishment_order_type: detail.replenishment_order_type,
                                remission_order: detail.remission_order,
                                sku: detail.sk,
                                comments: detail.comments ? detail.comments : "",
                                //quantity_send: Number(detail.quantity_send),
                                //quantity_available: Number(detail.quantity_send),
                            }]
                        }
                        payload.push(item)
                    }
                })
            });

            this.storeShipmentReturn(payload) //validar
                .then(result => {
                    Swal.fire({
                        title: this.$t("Created successfully"),
                        icon: "success",
                    }).then(result => {
                        this.$router.push({name:'loads.detail.returns', params: { load_id: this.$route.params.load_id }});
                    });
                })
                .catch(error => {
                    this.$root.$bvToast.toast(this.$t("An error has occurred, please try again later"), {
                        title: this.$t('Error'),
                        variant: 'danger',
                        solid: true
                    });
                })
                .finally(() => {
                    this.fetching = false
                });
        },
        onBack(){
            this.type = null;
            this.reason = null;
            this.comments_required = false;
            this.quantity_required = false;
            this.step = 1;
        },
        setType(code){
            if(!code) return false;
            this.reason = null;
            let id_type = this.types.find(e => e.code === code).id;
            this.getReasons({id: id_type})
            this.type = code;
        },
        setReason(code){
            if(!code){
                this.reason = null;
                this.comments_required = false;
                this.quantity_required = false;
                return false
            }
            let selected = this.reasons.find(e => e.code === code);
            this.quantity_required = selected.quantity_required;
            this.comments_required = selected.comments_required;
            this.shipments.forEach(s => {
                s.reason = selected
            })
            this.reason = code;
        },
        getShipperExternalId(_id){
            let find = this.load_shipments.find(e => e._id === _id)
            return find ? find.shipper_external_id : ' - ';
        },
        getQuantityReturn(shipment){
            const { shipment_number, lot, code } = shipment
            let { shipment_returns } = this.load_return;
            let _shipments = shipment_returns.filter(e => e.shipment_number === shipment_number);
            let sum = 0;
            if(_shipments.length > 0){
                _shipments.map(_shipment => {
                    _shipment.details.map(detail => {
                        if(detail.lot === lot && detail.code === code){ // check lote && code
                            sum += Number(detail.quantity)
                        }
                    });
                });
            }
            return sum
        }
    },
    created() {
        if(this.types.length === 0) this.getTypes();
        this.shipments = [];
        let copy_shipments = _.cloneDeep(this.shipments_details.data);
        copy_shipments.map(entry => {
            entry.details.map(detail => {
                detail.checked = false;
                detail.shipper_external_id = this.getShipperExternalId(detail.shipment_id);
                detail.quantity_available = detail.quantity - this.getQuantityReturn(detail)
                detail.quantity_send = 0;
            })
        });
        this.shipments = copy_shipments;
    },
}
</script>

<style lang="scss" scoped>
</style>
