<template>
    <div class="field-wrapper" :class="[border?'':'border-0']">
        <div class="row align-items-center ">
            <div v-if="title" :class="[layout ==='horizontal' ?'col-md-6':'col-md-12']">
                <div class="field-label">{{title}} <span v-if="rules.indexOf('required') > -1" class="field-required-badge"/> </div>
                <div class="field-help">{{help}}</div>
            </div>
            <div :class="[layout ==='horizontal' ?'col-md-6':'col-md-12']">
                <validation-provider :name="name" :rules="rules" v-slot="{ errors }" :vid="name">
                    <multiselect v-model="selected" :disabled="disabled" :multiple="multiple" :options="options" open-direction="bottom" :close-on-select="true"
                                :clear-on-select="false" :preserve-search="true"
                                track-by="id" :placeholder="$t('Select')" select-label="" :selected-label="$t('Selected')"
                                :searchable="true" :loading="isLoading" :internal-search="false" @search-change="onChange"
                    >
                    <template slot="singleLabel" slot-scope="props">
                        <span class="option__title">{{ typeof label === 'string' ?  props.option[label] : label(props.option) }}</span>
                    </template>
                    <template slot="tag" slot-scope="props">
                        <span class="badge badge-primary mr-1">
                            {{ typeof label === 'string' ?  props.option[label] : label(props.option) }}
                        </span>
                    </template>
                    <template slot="option" slot-scope="props">
                       <span class="option__title">{{ typeof label === 'string' ?  props.option[label] : label(props.option) }}</span>
                    </template>
                    </multiselect>
                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                </validation-provider>
                
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'

export default {
    name:'ResourceInput',
    components: {
        Multiselect
    },
    props: {
        filters: {
            type: Object,
            default(){return {}}
        },
        value:{
            required: true
        },
        fetchMethod:{
            type: Function,
            required: true
        },
        title:{
            type: String,
            default(){return null}
        },
        help:{
            type: String,
            default(){return ''}
        },
        placeholder:{
            type: String,
            default(){return ''}
        },
        label:{
            type: [String, Function],
            default(){
                return 'id'
            }
        },
        rules:{
            type: String,
            default(){return ''}
        },
        name: {
            type: String,
        },
        layout:{
            type: String,
            default(){
            return 'horizontal'
            }
        },
        border:{
            type: Boolean,
            default(){
            return true
            }
        },
        multiple:{
            type: Boolean,
            default(){
            return false
            }
        },
        readOnly:{
            type: Boolean,
            default(){
                return false
            }
        },
        disabled: {
            type: Boolean,
            default(){
                return false
            }
        },
        autoSelectFirst: {
            type: Boolean,
            default(){
                return false
            }
        },
        hiddenItems: {
            type: Boolean,
            default(){
                return false
            }
        },
    },
    data(){
        return {
            selected : null,
            options: [],
            isLoading: false,
            query: null
        }
    },
    computed: {
        localFilters(){
            if(this.query)
                return {...this.filters, search: this.query};
            return this.filters;
        }
    },
    watch:{
        selected: {
            deep:true,
            handler(newValue, oldValue){
                if(!_.isEqual(newValue, oldValue)){
                    this.$emit('input', newValue);
                    this.$emit('change', newValue);
                }
            }
        },
        value: {
            deep:true,
            handler(newValue){
                this.selected = newValue;
            }
        },
        localFilters:{
            deep: true,
            handler(newValue, oldValue){
                if(!_.isEqual(newValue, oldValue)){
                    this.doSearch();
                }
            }
        },
        multiple:{
            type: Boolean,
            default(){
                return false
            }
        }
    },
    created(){
        this.selected = this.value;
        this.doSearch = _.debounce(this.fetchData, 200);
        this.doSearch();
    },
    methods: {
        onChange(query){
            this.query = query;
            this.doSearch();
        },
        fetchData(){
            if(this.disabled)
                return;
            this.isLoading = true;
            this.fetchMethod(_.cloneDeep(this.localFilters))
                .then(data=>{
                    if (this.hiddenItems)
                        data = data.filter(item => !item.hidden)
                    this.options = Array.isArray(data) ? data: data.results;
                    if(this.autoSelectFirst && this.options.length === 1){
                        this.selected = this.options[0];
                    }
                })
                .catch(error =>{

                })
                .finally(()=>{
                    this.isLoading = false;
                })
        }
    }
}
</script>
