<template>
    <div
        class="card card-txp"
        :class="isSelected ? 'selected' : ''"
        role="button"
        @click="selectTrailer(trailer.external_id)"
    >
        <div class="row no-gutters">
            <div
                class="col-md-4 d-flex justify-content-center align-items-center rounded-left border-right p-3 h-80"
                :class="isSelected ? 'bg-gradient-txp' : ''"
            >
                <img :src="getSrc(trailer.external_id, isSelected)" width="50" />
            </div>
            <div class="col-md-6 offset-1 d-flex align-items-center">
                <div>
                    <b class="font-size-14">{{ trailer.name }}</b><br>
                </div>
            </div>
            <div class="col-1" v-if="isSelected">
                <div class="float-right mx-2 my-1">
                    <i class="ri-checkbox-circle-line text-primary font-size-22" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "TrailerCard",
    props: {
        trailer: { type: Object },
        selectTrailer: { type: Function },
    },
    computed: {
        ...mapGetters('marketplace', ['equipment_type']),
        isSelected(){
            return this.trailer.external_id === this.equipment_type.trailer_type_code;
        }
    },
    methods: {
        getSrc(code, selected) {
            try {
                code = selected ? code + '_W' : code;
                return require('@/assets/images/marketplace/trailer/' + code + '.svg');
            } catch (e) {
                code = selected ? 'DEFAULT_W' : 'DEFAULT'
                return require('@/assets/images/marketplace/trailer/' + code + '.svg');
            }
        }
    }
}
</script>

<style scoped>
.rounded-left {
    border-top-left-radius: 13% !important;
    border-bottom-left-radius: 13% !important;
}
.selected {
    box-shadow: 0px 0px 5px 10px #eee;
    border-radius: 15px;
}
.h-80{
    height: 80px;
}

</style>
