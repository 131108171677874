<template>
    <div class="row">
        <div class="col-6" v-for="trailer in trailers" v-if="trailers">
            <trailer-card :trailer="trailer" :selectTrailer="selectTrailer" />
        </div>
        <div class="col-12" v-if="trailers.length === 0">
            <empty-results title="" :subtitle="$t('No items')" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import TrailerCard from './TrailerCard';
export default {
    name: "SelectTrailer",
    components: { TrailerCard },
    computed: {
        ...mapGetters('catalogs', ['trailer_types_marketplace', 'equipments_marketplace']),
        ...mapGetters('marketplace', ['equipment_type']),
        ...mapGetters('shipmentform', ['available']),
    },
    props: {
        trailers: { type: Array },
    },
    methods:{
        ...mapMutations('marketplace', ['setEquipmentType']),
        selectTrailer(code){
            let equipment = this.equipments_marketplace.filter(entry => (
                entry.trailer_type_code === code &&
                entry.configuration_code === this.equipment_type.configuration_code &&
                entry.truck_type_code === this.equipment_type.truck_type_code ? this.equipment_type.truck_type_code : '' &&
                entry.trailer_size_code === this.equipment_type.trailer_size_code ? this.equipment_type.trailer_size_code : ''
            ));

            let copy_equipment_type = _.cloneDeep(this.equipment_type);
            copy_equipment_type.code = equipment[0].code;
            copy_equipment_type.name = equipment[0].name;
            copy_equipment_type.trailer_type_code = code;
            this.setEquipmentType(copy_equipment_type);
        }
    }

}
</script>
