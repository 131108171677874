<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <th class="text-center" style="width: 48px;">
                        <b-form-checkbox
                                v-model="selectAll"
                                name="checkbox-1"
                                button-variant="secondary"
                        >
                        </b-form-checkbox>
                    </th>
                    <th class="text-center">ID</th>
                    <th class="text-center">{{$t('Status')}}</th>
                    <th class="text-center">{{$t('Category')}}</th>
                    <th class="text-center">{{$t('Type')}}</th>
                    <th class="text-center">{{$t('Name')}}</th>
                    <th class="text-center">{{$t('SKU')}}</th>
                    <th class="text-center">{{$t('Price')}}</th>
                    <th class="text-center">{{$t('Brand')}}</th>
                    <th class="text-center" style="width: 150px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fetching">
                    <td colspan="100%">
                        <loading />
                    </td>
                </tr>

                <template v-for="item in list.results">
                    <tr v-if="init || page === list.current_page" :key="'product-'+item.id"  class="product-row">
                        <td>
                            <b-form-checkbox
                                    v-model="selected"
                                    name="checkbox-1"
                                    :value="item.id"
                            >
                            </b-form-checkbox>
                        </td>
                        <td class="text-center">
                            <router-link :to="{name:'products.detail', params:{product_id: item.id}}">
                                <b>{{item.id}}</b>
                            </router-link>
                        </td>
                        <td class="text-center">
                            <span class="badge " :class="['badge-outline-'+ (item.enabled ? 'success':'danger')]">
                                <i class="far " :class="[(item.enabled ? 'fa-check-circle':'fa-times-circle')]" />
                                {{item.enabled ? $t('enabled'):$t('disabled')}}
                            </span>
                        </td>
                        <td class="text-center">{{ item.category ? item.category.name : '--'}}</td>
                        <td class="text-center">{{item.type}}</td>
                        <td class="text-center">{{item.name}}</td>
                        <td class="text-center">{{item.sku}}</td>
                        <td class="text-center">{{item.price | money}}</td>
                        <td class="text-center">{{item.brand}}</td>
                        <td class="text-center">
                            <a v-can="'trailers.update'" v-b-tooltip.hover :title="item.enabled ? $t('disable'):$t('enable')" style="cursor: pointer;" @click="confirmActivateDesactivate(item)" :class="item.enabled ? 'text-danger mr-2' : 'mr-2'" >
                              <i :class="[item.enabled ? 'far fa-thumbs-down' : 'far fa-thumbs-up']" style="font-size:20px;" />
                            </a>
                            <router-link v-can="'products.update'"  :to="{name:'products.edit', params:{product_id: item.id}}" class="mr-2">
                                <i class="far fa-edit btn-icon"></i>
                            </router-link>
                            <router-link v-can="'products.read'" :to="{name:'products.detail', params:{product_id: item.id}}" class="mr-2">
                                <i class="far fa-eye btn-icon"></i>
                            </router-link>
                            <a v-can="'products.delete'" @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-2">
                              <i class="far fa-trash-alt btn-icon"  />
                            </a>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div  v-if="list.total > 0 && init"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    import Loading from "../common/Loading";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import Swal from "sweetalert2";

    export default {
        name: "ProductTable",
        mixins:[selectAllMixin],
        components: {
            'loading':Loading
        },
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            },
        },
        data(){
            return {
                page: 1,
                init: false,
                fetching: false,
                product: null,
                fetchingProduct: false,
            }
        },
        computed: {
            ...mapState('products', ['last_filters', 'products']),
            ...mapState('profile',['profile']),
            list(){
                return this.products
            }
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
            this.fetchData();
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal)){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('products',['getProducts','getProduct','deleteProduct','updateProduct']),
            fetchData(){
                this.fetching = true;
                this.getProducts(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.fetching = false;
                        this.init = true;
                    })
            },
            show(item){
                this.fetchingLoad = true;
                this.getProduct({id: item.id})
                    .then(data=>{
                    })
                    .catch(error =>{
                    })
                    .finally(error =>{
                        this.fetchingLoad = false;
                    })
            },
            confirmActivateDesactivate(item){
              let action = item.enabled ? 'desactivate' : 'activate'
              let status = !item.enabled;
              Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t(`Yes, ${action} it!`),
                cancelButtonText: this.$t("Cancel")
              }).then(result => {
                if(result.value) {
                  console.log(result);

                  this.updateProduct({id: item.id, enabled: status})
                      .then(data=>{
                        this.fetchData();
                        this.$root.$bvToast.toast(this.$t(`Your item has been ${action}d.`, {resource: this.$t("Product").toLowerCase()}), {
                          title: this.$t('Success'),
                          variant: 'success',
                          solid: true
                        });
                      })
                      .catch(data=>{
                        Swal.fire(
                            "Error",
                            this.$t("Something went wrong!"),
                            "error"
                        );
                      })
                }
              })
            },
            confirmDelete(item){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteProduct({id: item.id})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Product").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                        });
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .product-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }
</style>
