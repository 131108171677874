<template>
    <div>
        <loading-user v-if="fetching" />
        <div class="user_table" v-else>
          <div class="user_table_item " v-for="item in list.results" :key="'user-'+item.id">
            <div class="user_table_item_title">
                <div class="user_table_item_title_checkbox">
                  <b-form-checkbox
                      v-model="selected"
                      name="checkbox-1"
                      :value="item.id"
                  >
                  </b-form-checkbox>
                </div>
              <div class="user_table_item_title_icon">
                <div class="user_table_item_title_icon_avatar">
                  <avatar :size="40" :item="item"></avatar>
                </div>
                <div class="user_table_item_title_icon_span">
                  <span class="badge " :class="['badge-outline-'+ (item.enabled ? 'success':'danger')]">
                  <i class="far " :class="[(item.enabled ? 'fa-check-circle':'fa-times-circle')]" />
                  {{item.enabled ? $t('enabled'):$t('disabled')}}
                </span>
                </div>
              </div>
              <div class="user_table_item_title_name">
                <p class="text-muted mb-2 ">ID: <span class="font-weight-semibold">{{item.id}}</span>  </p>
                <router-link :to="{name: item.profile && item.profile.object == 'driver' ? `${item.profile.object}s.detail` : edit, params:{user_id: item.id}}">
                  <h5 class="text-primary font_bold font-size-15 text-uppercase ">{{item.name}} {{item.last_name}}</h5>
                </router-link>
              </div>
            </div>
            <div class="user_table_item_detail">
                <div class="user_table_item_detail_details">
                  <div class="user_table_item_detail_details_item">
                    <div class="user_table_item_detail_details_item_label">{{$t('Email')}}</div>
                    <div class="user_table_item_detail_details_item_value font_semi_bold">{{item.email}}</div>
                  </div>
                  <div class="user_table_item_detail_details_item">
                    <div class="user_table_item_detail_details_item_label">{{$t('Phone')}}</div>
                    <div class="user_table_item_detail_details_item_value font_semi_bold">{{item.phone}}</div>
                  </div>
                  <div class="user_table_item_detail_details_item" v-if="$route.name.indexOf('drivers') > -1">
                    <div class="user_table_item_detail_details_item_label">{{$t('License')}}</div>
                    <div class="user_table_item_detail_details_item_value font_semi_bold">{{item.profile.driver_license}}</div>
                  </div>
                  <div class="user_table_item_detail_details_item">
                    <div class="user_table_item_detail_details_item_label">{{$t('Company')}}</div>
                    <div class="user_table_item_detail_details_item_value font_semi_bold">
                      <router-link v-if="item.account" :to="getProfileRoute(item)" >
                        <div class="two-lines">
                          {{item.account.name}}
                        </div>
                      </router-link>
                      <div v-else>
                        - -
                      </div>
                    </div>
                  </div>
                  <div class="user_table_item_detail_details_item">
                    <div class="user_table_item_detail_details_item_label">{{$t('Documents')}}</div>
                    <div class="user_table_item_detail_details_item_value ">
                      <div v-if="item.account">
                          <span class="badge  font_semi_bold " :class="[getDocumentStatusColor(item.account.profile.document_status)]">
                              {{$t(item.account.profile.document_status)}}
                          </span>
                      </div>
                      <div v-else>
                        - -
                      </div>
                    </div>
                  </div>
                  <div class="user_table_item_detail_details_item">
                    <div class="user_table_item_detail_details_item_label">{{$t('Role')}}</div>
                    <div class="user_table_item_detail_details_item_value">
                      <span class="badge  font_semi_bold" :class="[getRoleColor(role)]" :key="'b-r-'+item.id+'-'+role" v-for="role in item.roles" >
                          {{$t(role)}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="user_table_item_detail_actions">
                  <router-link v-can="'users.update'" class="text-black" :to="{name: edit ? edit : `${profile}.edit`, params:{user_id: item.id}}">
                    <i v-b-tooltip.hover :title="$t('Edit')" class="far fa-edit" style="font-size:20px;"></i>
                  </router-link>
                </div>
            </div>
          </div>
        </div>

        <div  v-if="list.total > 0"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    import Loading from "../common/Loading";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import Swal from "sweetalert2";
    import Avatar from "../common/Avatar";
    import LoadingUser from "../common/LoadingUsers";

    export default {
        name: "UserTable",
        mixins:[selectAllMixin],
        components: {
          LoadingUser,
          Avatar,
            'loading':Loading
        },
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            },
            edit: {
                type: String
            }
        },
        data(){
            return {
                page: 1,
                init: false,
                user: null,
            }
        },
        computed: {
            ...mapState('users', ['last_filters', 'users','fetching']),
            ...mapState('profile',['profile']),
            list(){
                return this.users
            },
            profile(){
                let profile;
                if (this.$route.name.indexOf('drivers') > -1) {
                     profile =  "drivers"
                }

                if (this.$route.name.indexOf('users') > -1) {
                     profile = "users";
                }

                if (this.$route.name.indexOf('staff') > -1) {
                     profile = "staff";
                }
                return profile;
            },
            entity(){
            let entity;
                if (this.$route.name.indexOf('drivers') > -1) {
                     entity = this.$t("Driver")
                }

                if (this.$route.name.indexOf('users') > -1) {
                     entity = this.$t("User")
                }
                return entity;
            },
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal) || this.init === false){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('users',['getUsers','getUser', 'deleteUser', 'updateUser']),
            fetchData(){
                this.getUsers(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.init = true;
                    })
            },
            showRow(load){
                this.getUser({id: load.id})
                    .then(data=>{
                    })
                    .catch(error =>{
                    })
                    .finally(error =>{

                    })
            },
            confirmDelete(item){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteUser({id: item.id})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.entity.toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                    });
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error", 
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            },
            confirmActivateDesactivate(item){
                let action = item.enabled ? 'desactivate' : 'activate'
                let status = item.enabled ? 0 : 1
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t(`Yes, ${action} it!`),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.updateUser({id: item.id, enabled: status})
                            .then(data=>{
                                this.$root.$bvToast.toast(this.$t(`Your item has been ${action}d.`, {resource: this.entity.toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                    });
                            })
                            .catch(data=>{
                                Swal.fire(
                                    "Error", 
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            })
                    }
                })
            },
            getRoleColor(role){
                if(role === 'admin')
                    return 'badge-outline-danger';
                
                if(role === 'driver')
                    return 'badge-outline-secondary';
                
                if(role === 'carrier')
                    return 'badge-outline-success';
                if(role === 'shipper')
                    return 'badge-outline-primary';

                return 'badge-outline-primary'
            },
            getDocumentStatusColor(status){
                if(status === 'accepted'){
                    return 'badge-outline-success';
                }
                if(status === 'rejected'){
                    return 'badge-outline-danger';
                }
                if(status === 'pending'){
                    return 'badge-outline-secondary';
                }
                if(status === 'in_review'){
                    return 'badge-outline-warning';
                }
            },
            getProfileRoute(item){
                if(item.account.type === 'shipper'){
                    return {name: 'shippers.detail', params:{shipper_id: item.account.profile.id}};
                }else if(item.account.type === 'carrier'){
                    return {name: 'carriers.detail', params:{carrier_id: item.account.profile.id}};
                }else{
                    return {name: 'root'};
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .truck-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }

    .user_table{
      &_item{
        display: flex;
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid hsla(0,0%,86.7%,.4117647059)!important;
        box-shadow: 0 6px 10px hsl(0deg 0% 80% / 50%);
        margin-bottom: 20px;
        border-right: 1px solid hsla(0,0%,86.7%,.4117647059);
        &_title{
          padding: 15px 10px;
          width: 35%;
          display: flex;
          justify-content: center;
          align-items: center;
          &_checkbox{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
          }
          &_icon{
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(30% + 40px);
            flex-wrap: wrap;
            &_span{
              display: block;
              width: 100%;
              text-align: center;
              padding-top: 10px;
            }
          }
          &_name{
            width: calc(65% + 39px);
          }
        }
        &_detail{
          padding: 15px 10px;
          background: #f9f9f9;
          width: calc(65%);
          display: flex;
          justify-content: center;
          align-items: center;
          &_details{
            width: calc(100% - 85px);
            display: flex;
            justify-content: flex-start;
            &_item{
              flex: 1;
              text-align: center;
              &_label{
                color: #666666!important;
              }
              &_value{

              }
            }
          }
          &_actions{
            width: 80px
          }
        }
      }
    }


    @media (max-width: 992px) {
      .user_table_item{
        flex-wrap: wrap!important;
      }
      .user_table_item_title{
        width: 100%!important;
      }
      .user_table_item_detail,.user_table_item_detail_details{
        width: 100%!important;
        flex-wrap: wrap!important;
      }
      .user_table_item_detail_details_item{
        padding-top: 15px;
        width: 50%!important;
        flex: auto!important;
      }
      .user_table_item_detail_actions{
        width: 100%;
        text-align: center;
        padding-top: 15px;
      }

    }




</style>
