<template>
  <div class="modal-vue"  v-if="showModal" >
    <!-- overlay -->
    <div class="_overlay" @click="closeModal"></div>

    <!-- modal -->
    <div class="_modal"  :style="style" >
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransparentModal",
  props:{
    position:{
      type: [Array, Object],
      default(){
        return {x:0,y:0};
      }
    },
    showModal:{
      type: Boolean,
      default(){
        return false;
      }
    }
  },
  created(){

  },
  computed:{
    style(){
      if ( this.position.y < 50) {
        return { left: this.position.x+'px', top: '0' }
      }else{
        return { left: this.position.x+'px', bottom: '35px' }
      }
    }
  },
  methods:{
    closeModal(){
      this.$emit('close')
    }
  }
}
</script>

<style scoped>


.modal-vue{
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
}

.modal-vue ._overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  background-color: transparent;
}

.modal-vue ._modal {
border-radius: 10px;
position: absolute;
width: 235px;
height: auto;
z-index: 9999;
margin: 0 auto;
padding: 0;
background-color: #fff;
box-shadow: 0px 2px 22px #dddddd;
border: 1px solid #dddddd;
}

.modal-vue .close{
position: absolute;
top: 10px;
right: 10px;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>