<template>
 <div>
   <page-header :title="edit ? $t('Edit field'): $t('New field')" :items="breadcrumb"/>

   <validation-observer ref="observer" v-slot="{ validate }">
     <b-form @submit.stop.prevent="onSubmit(validate)">
       <loading v-if="loading" />
       <div class="card">
         <div class="card-header">
           <h6>{{$t('General')}}</h6>
         </div>
         <div class="form-fields">
           <form-input
               :title="$t('Title')"
               :help="$t('Enter the field title')"
               v-model="form.title"
               rules="required"
               type="text"
               :name="'title'"
           />
           <form-input
               :title="$t('Help')"
               :help="$t('Enter an optional help text')"
               v-model="form.help"
               rules=""
               type="text"
               :name="'help'"
           />
           <form-input
               :title="$t('Type')"
               :help="$t('The input type')"
               v-model="form.type"
               rules="required"
               type="select"
               :name="'type'"
               :options="types"
               v-on:change="()=>onFieldTypeChange"
           />
           <template v-if="['checkbox','select'].indexOf(form.type) > -1 ">
             <div class="field-wrapper">
               <div class="row">
                 <div class="col-md-6">
                   <div class="field-label">{{$t('Options')}}</div>
                   <div class="field-help">{{$t('Define the available options')}}</div>
                 </div>
                 <div class="col-md-6">
                   <div class="row mb-2" :key="'field-op-'+optIdx" v-for="(option, optIdx) in form.options" >
                     <div class="col-md-12">
                       <validation-provider :name="'form.options.'+optIdx+'.value'" rules="required" v-slot="{ errors }">
                         <b-input-group >
                           <b-form-input v-model="option.value" @change="setOptionText(optIdx)" type="text" />
                           <template #append>
                             <button class="btn btn-light" @click="form.options.splice(optIdx, 1)">
                               <i class="far fa-minus-circle" />
                             </button>
                           </template>
                         </b-input-group>
                         <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                       </validation-provider>
                     </div>
                   </div>

                   <div class="mt-2">
                     <button @click="form.options.push({text:'',value:''})" class="btn btn-light btn-sm ">{{$t('Add option')}}</button>
                   </div>
                 </div>
               </div>
             </div>
           </template>
           <div class="field-wrapper">
             <b-row class="justify-content-end">
               <b-col md="6" sm="6" class="text-right">
                 <div class="d-inline-block pl-3 pr-3">
                   <b-form-checkbox
                       v-model="form.required"
                       name="checkbox-1"
                   >
                     {{$t('Required')}}
                   </b-form-checkbox>
                 </div>
               </b-col>
             </b-row>
           </div>
         </div>
       </div>

       <div class="d-flex justify-content-end mb-4">
         <button :disabled="loading" type="button" class="btn btn-light mr-3 " @click="$router.push({name:$route.meta.routes.list})">{{$t('Cancel')}}</button>
         <button :disabled="loading" v-if="!edit" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew">{{$t('Save and create new')}}</button>
         <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t('Save')}}</button>
       </div>

     </b-form>
   </validation-observer>

 </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import formMixin from '@/mixins/formMixin';
export default {
  name: "FormField",
  props:{

  },
  mixins: [formMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  },
  data(){
    return {
      from: null,
      form: {
        options:[]
      },
      types: [
        {text: this.$t('fields.text'), value:'text'},
        {text: this.$t('fields.number'), value:'number'},
        {text: this.$t('fields.checkbox'), value:'checkbox'},
        {text: this.$t('fields.select'), value:'select'},
      ],
      loading : false,
      edit: false,
      createNew: false,
    }
  },
  computed:{
    breadcrumb(){
      const profile = this.$route.params.profile;
      return [
        {
          text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
          to: {name:'profile'}
        },
        {
          text: this.$t('Load fields'),
          to: {name: this.$route.meta.routes.list}
        },
        {
          text: this.edit ? this.$t('Edit field'): this.$t('New field'),
          active: true,
        }
      ]
    },
  },
  created() {
    if(this.$route.params.field_id){
      this.edit = true;
      this.loading = true;
      this.getField({form_id: this.$route.params.form_id, field_id: this.$route.params.field_id})
          .then(data=>{
            this.form = _.cloneDeep(data);
          })
          .catch(error =>{
            this.processServiceError(error);
          })
          .finally(()=>{
            this.loading = false;
          });
    }
  },
  methods: {
    ...mapActions('forms',['storeField','getField','updateField']),
    onFieldTypeChange(){
      if(['checkbox','select'].indexOf(this.form.type)){
        this.form.options = [];
      }
    },
    setOptionText(optionIndex){
      this.form.options[optionIndex].text = this.form.options[optionIndex].value;
    },
    resetForm() {
      this.form = {options:[]};
      this.createNew = false;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    saveAndCreateNew(){
      this.createNew = true;
      this.$refs.submit.click();
    },
    async onSubmit(validate) {
      if (!(await validate()) || this.loading) {
        return;
      }

      this.loading = true;
      let form = _.cloneDeep(this.form);
      form.form_id = this.$route.params.form_id;
      if(this.edit){
        form.field_id = form.id;
        this.updateField(form)
            .then(data=>{
              this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                title: this.$t('Success'),
                variant: 'success',
                solid: true
              });
              this.redirect();
            })
            .catch(error =>{
              this.processServiceError(error);
            })
            .finally(()=>{
              this.loading = false;
            });
      }else{

        this.storeField(form)
            .then(data=>{
              this.$root.$bvToast.toast(this.$t('Created successfully'), {
                title: this.$t('Success'),
                variant: 'success',
                solid: true
              });
              if(this.createNew){
                this.resetForm();
              }else{
                this.redirect();
              }
            })
            .catch(error =>{
              this.processServiceError(error);
            })
            .finally(()=>{
              this.loading = false;
            });
      }

    },
    redirect(){
      if(this.from.name){
        this.$router.back();
      }else{
        this.$router.push({name: this.$route.meta.routes.list});
      }
    }
  }
}
</script>

<style scoped>

</style>
