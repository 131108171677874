<template>
    <div class="row">
        <div class="col-12">
            <empty-results title="" :subtitle="$t('No items')" class="py-md-2" v-if="shipments.length === 0"  />
            <div class="table-responsive" v-else>
                <table class="table table-striped table-hover font-size-14">
                    <thead>
                        <tr>
                            <th>
                                <b-form-checkbox @change="selectAll" class="text-gray-888" />
                            </th>
                            <th scope="col">{{$t('Shipment')}}</th>
                            <th scope="col">{{$t('Customer reference')}}</th>
                            <th scope="col">{{$t('Batch')}}</th>
                            <th scope="col">SKU</th>
                            <th scope="col">{{$t('Description')}}</th>
                            <th scope="col">{{$t('Remission order')}}</th>
                            <th scope="col">{{$t('Replenishment order')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(shipment, index) in shipments">
                            <tr v-for="(detail, i) in shipment.details">
                                <td>
                                    <b-form-checkbox
                                        v-model="shipments[index].details[i].checked"
                                    />
                                </td>
                                <td>{{shipment.shipment_number}}</td>
                                <td>{{detail.shipper_external_id}}</td>
                                <td>{{detail.lot}}</td>
                                <td>{{detail.code}}</td>
                                <td>{{detail.description}}</td>
                                <td>{{detail.remission_order}}</td>
                                <td>{{detail.replenishment_order}}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-5 offset-md-7 col-12">
            <buttons
                :labelPrevious="$t('Cancel')"
                :back="cancel"
                :disabledNext="!disabledNext"
                :next="onNext"
                :fetching="fetching"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import Buttons from '@/components/marketplace/equipment/Buttons.vue';

export default {
    name: "SelectShipments",
    components: { Buttons },
    props: {
        selectAll: Function,
        onNext: Function,
        shipments: Array,
        fetching: Boolean,
    },
    computed: {
        disabledNext(){
            let atLeastOneHaveDetails = this.shipments.some(e => e.details.length > 0);
            let atLeastOneChecked = this.shipments.some(e => e.details.some(i => i.checked));
            return atLeastOneHaveDetails && atLeastOneChecked;
        }
    },
    methods: {
        cancel(){
            this.$router.push({name:'loads.detail.returns', params: { load_id: this.$route.params.load_id }});
        },
    }
}
</script>

<style lang="scss" scoped>
.table > thead > tr > th { border-top: 0; border-bottom: 0; }
</style>
