import { render, staticRenderFns } from "./ShipperTable.vue?vue&type=template&id=a181634a&scoped=true&"
import script from "./ShipperTable.vue?vue&type=script&lang=js&"
export * from "./ShipperTable.vue?vue&type=script&lang=js&"
import style0 from "./ShipperTable.vue?vue&type=style&index=0&id=a181634a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a181634a",
  null
  
)

export default component.exports