<template>
    <div>
        <div class="card">
            <button type="button" class="btn btn-primary btn-block" @click="() => showModal = true" :disabled="!disabled">
                <div class="row">
                    <div class="col-md-10 d-flex justify-content-center align-items-center">
                        <div
                            class="font-size-19 font-weight-bold"
                            :class="[!disabled ? 'text-gray-888':'']"
                        >
                            {{$t('Confirm Delivery')}}
                        </div>
                    </div>
                    <div class="col-md-2 d-flex justify-content-center align-items-center">
                        <i
                            class="ri-checkbox-circle-fill  font-size-30"
                            :class="[!disabled ? 'text-muted':'text-yellow']"
                        />
                    </div>
                </div>
            </button>
        </div>
        <confirm-delivery-modal :visible="showModal" v-on:close="() => showModal = false" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ConfirmDeliveryModal from "@/components/modals/ConfirmDeliveryModal.vue";

export default {
    name: "ConfirmDeliveryCard",
    components: { ConfirmDeliveryModal },
    computed:{
        ...mapState('loads', ['status', 'load', 'events']),
        disabled(){
            let isAllStatusCompleted = this.status.every(e => e.completed === true);
            let delivered = this.events.find(e => ['partial_rejection', 'total_rejection', 'delivered','delivering'].indexOf(e.status) > -1)
            return isAllStatusCompleted && !delivered
        }
    },
    data(){
        return {
            showModal: false
        }
    },
}
</script>

<style lang="scss" scoped>
.btn-primary{
    background-image: linear-gradient(to right, #1EC4F3 82%, #03a7d6 18%);
    &:hover:enabled {
       box-shadow: 0 2px 4px rgb(0 0 0 / 12%);
       border-color: #03a7d6;
    }
}
</style>
