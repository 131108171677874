<template>
  <div class="">
    <div class="mb-5">
      <div class=" tpx_calendar txp_calendar_v2">
        <div class="actions_btn">
          <div class="text-primary font_bold cursor-pointer mr-3" @click="lastMonth">
            <i class="ri-arrow-left-s-line font-size-24"></i>
          </div>
          <div class="text-primary font_bold mr-3 title">
            {{ localDate.format("MMMM YYYY") }}
          </div>
          <div class="text-primary font_bold cursor-pointer mr-3" @click="nextMonth">
            <i class="ri-arrow-right-s-line font-size-24"></i>
          </div>
        </div>
        <month :month="_month" :year="_year" :events="events" @setDateActive="setDay" />
        <TransparentModal :showModal="showModal" :position="positionModal" @close="closeModal">
          <time-picker :from="from" :to="to" :validDate="validDateCallBack" @setFrom="setFrom" @setTo="setTo"
            @closeModal="closeModal" />
        </TransparentModal>
      </div>
    </div>
    <buttons-steps @eventBack="$emit('calendarBack')" @eventNext="confirm()" :back="true" :next="true"
      :complete="true" />
  </div>
</template>

<script>


import ButtonsSteps from "../../common/ButtonsSteps.vue";
import TimePicker from "../calendarPicker/TimePicker.vue";
import Month from "../calendarPicker/Month.vue";
import TransparentModal from "../../common/TransparentModal.vue";
import TimePickerTxp from "../../TimePicker/TimePickerTxp.vue";
import moment from "moment/moment";
import tr from "vue2-datepicker/locale/es/tr";
import { mapMutations } from "vuex";

export default {
  name: "DestinationCalendarView",
  components: {
    ButtonsSteps,
    TimePicker,
    Month, TransparentModal, TimePickerTxp
  },
  data() {
    return {
      localDate: moment(),
      showModal: false,
      event: {},
      extraDaysEvent: [],
      date: {},
      pickupEvent: null,
      suggestedDateEvent: null,
      positionModal:{ x: 0 , y:0},
      from: {
        hour: 19,
        minute: 0
      },
      to: {
        hour: 20,
        minute: 30
      },
    }
  },
  props: {
    originDate: {
      type: Object,
    },
    suggestedDate: {
      type: Object
    },
    destinationDate: {
      type: Object
    }
  },
  created() {
    this.setOrigin();
    this.setSuggestedDate();
    this.setDestinationDate();
  },
  computed: {
    _month: function () {
      return moment(this.localDate).month()
    },
    _year: function () {
      return moment(this.localDate).year()
    },
    events: function () {
      let array = []
      if (this.pickupEvent){
        array.push(this.pickupEvent)
      }
      if (this.suggestedDateEvent){
        array.push(this.suggestedDateEvent)
      }
      if (this.event.date){
        array.push(this.event)
      }
      if (this.extraDaysEvent.length){
        array = [...array , ...this.extraDaysEvent]
      }
      return array
    },
  },
  methods:{
    ...mapMutations('marketplace', ['addAccessory','removeAccessory']),
    setSuggestedDate(){
      if (this.suggestedDate){
        let data = _.cloneDeep(this.suggestedDate)
        this.suggestedDateEvent =  this.generateEven(
            moment(new Date(data.year,data.month-1,data.day,data.hour,data.minute)),
            null,null ,'better_time', true
        )
      }
    },
    setOrigin(){
      if (this.originDate){
        let from = moment(this.originDate.schedule_from)
        let to = moment(this.originDate.schedule_to)
        let data = _.cloneDeep(this.originDate)
        data.month -=1
        this.pickupEvent = this.generateEven(
            from, from.format("HH:mm"),
            to.format("HH:mm"),'pickup', false)
      }
    },
    setDestinationDate(){
      if (this.destinationDate){
        let from = moment(this.destinationDate.schedule_from)
        let to = moment(this.destinationDate.schedule_to)
        this.event = this.generateEven(
            from, from.format("HH:mm"),
            to.format("HH:mm"),'delivery', true)
        this.generateExtraDays(from)
      }
    },
    nextMonth: function () {
      this.localDate = moment(this.localDate).add(1, "month");
    },
    lastMonth: function () {
      this.localDate = moment(this.localDate).subtract(1, "month");
    },
    generateEven(date, from, to, type, editable) {
      return {
        type: type,
        date: date ? date: null,
        from: from ? from : null,
        to: to ? to : null,
        editable: editable,
      }
    },
    setDay(data) {
      let openDate = moment(data.date)
      let aux = moment(this.suggestedDateEvent.date).format('YYYY-MM-DD')
      let aux2 = moment(openDate.format('YYYY-MM-DD')).format('YYYY-MM-DD')
      if (! moment(aux).isSameOrBefore(aux2)) {
        this.$root.$bvToast.toast(this.$t("No puedes seleccionar una fecha anterior a la fecha estimada"), {
          title: this.$t('Error'),
          variant: 'danger',
          solid: true
        });
      } else {
        this.positionModal = data.position;
        this.date = data.date;
        this.showModal = true;
        this.generateExtraDays(data.date);
        this.event = this.generateEven(data.date, null, null, 'delivery', true);
      }
    },
    closeModal(data) {
      this.generateEven(null, null, null)
      this.showModal = false;
    },
    setFrom(data) {
      this.event = this.generateEven(this.date, data, null, 'delivery', true)
    },
    setTo(data) {
      this.event = this.generateEven(this.date, this.event.from, data, 'delivery', true)
      this.showModal = false;
    },
    validDateCallBack(data) {
      if (data.step === 'from') {
        let aux = moment(this.event.date.format("YYYY MM DD"))
        aux.hour(data.hour)
        aux.minute(data.minute)
        console.log(this.suggestedDate)
        let sug = moment(`${this.suggestedDate.year} ${this.suggestedDate.month} ${this.suggestedDate.day} ${this.suggestedDate.hour}:${this.suggestedDate.minute}`)
        return aux.isAfter(sug, 'minute')
      } else {
        let aux = moment(this.event.date.format("YYYY MM DD"))
        let aux_from = moment(this.event.date.format("YYYY MM DD"))
        aux.hour(data.hour)
        aux.minute(data.minute)
        let info_from = this.event.from.split(':')
        aux_from.hour(info_from[0])
        aux_from.minute(info_from[1])
        return aux.isAfter(aux_from)
      }
    },
    confirm() {
      if (this.event.date.isValid()){
        let data = {
          day: this.event.date.format("DD"),
          month: this.event.date.format("MM"),
          year: this.event.date.format("YYYY"),
          schedule_from: this.event.date.format('YYYY-MM-DD') + ' ' + this.event.from,
          schedule_to: this.event.date.format('YYYY-MM-DD') + ' ' + this.event.to,
        };
        this.$emit("calendarNext", data);
      }

    },
    generateExtraDays(openDate) {
      let dateTo = moment(openDate)
      let suggestedDate =moment(`${this.suggestedDate.year}-${this.suggestedDate.month}-${this.suggestedDate.day}`)
      let diffDays = dateTo.diff(suggestedDate, 'days');
      if (diffDays) {
        let aux = []
        this.addAccessory({
          code: "O_ESTANCIA",
          name: "Extra days",
          unit_cost: 2500,
          amount: 2500 * diffDays,
          quantity: diffDays
        });
        for (let i = 1; i < diffDays; i++) {
          aux.push({
            type: 'extra_day',
            editable: true,
            date: moment(`${this.suggestedDate.year}-${this.suggestedDate.month}-${parseInt(this.suggestedDate.day) + i}`),
          })
        }
        this.extraDaysEvent = aux
      } else {
        this.removeAccessory("O_ESTANCIA");
      }
    }
  },
}
</script>

<style scoped>
.actions_btn {
  font-family: "Inter", sans-serif;
  color: #00b3e6;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  display: flex;
  justify-content: flex-end;
  text-transform: capitalize;
}
.txp_calendar_v2{
  position: relative;
}
</style>