<template>
    <div>
        <page-header :title="$t('General driver information')" :items="breadcrumb"/>
        <ul class="nav nav-tabs nav-tabs-custom " >
            <li class="nav-item" v-for="item in tabs" :key="item.route" >
                <router-link :to="{name: item.route}" class="nav-link" active-class="active">
                    {{$t(item.title)}}
                </router-link>
            </li>
        </ul>
        <loading v-if="loading" />
        <router-view v-else></router-view>
    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex';

    export default {
        name: "DriverContainer",
        data(){
            return {
                tabs: [
                    {title: 'General',route: 'driver.detail.general'},
                    {title: 'Documents',route: 'driver.detail.documents'},
                    {title: 'Credit',route: 'driver.detail.credit'},
                ],
                loading: true
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t('Driver'),
                        to: {name:'drivers.index'}
                    },
                    {
                        text: this.$route.params.user_id,
                        active: true,
                    }
                ]
            },
        },
        created() {
            this.fetchResource()
        },
        methods: {
            ...mapActions('requirements',['getResource']),
            fetchResource() {
                this.getResource({resource:'drivers', resource_id: this.$route.params.user_id })
                    .then(data => {
                        this.loading = false;
                    })
            }
        }
    }
</script>

<style scoped>

</style>
