<template>
    <b-button-group>
        <b-dropdown variant="light" ref="dropdown" class="d-inline-block "  toggle-class="filter-button">
            <template #button-content>
                <table>
                    <tr>
                        <td valign="top" style="width:24px;">
                            <small><i class="pr-1 text-muted" :class="[filter.icon]"></i></small>
                        </td>
                        <td valign="top" class="text-left" >
                            <small class="text-muted">{{filter.label}}:</small> <div class="filter-button-value"> {{formattedValueLabel ? formattedValueLabel : '- -'}}</div>
                        </td>
                    </tr>
                </table>
            </template>

            <b-dropdown-form style="min-width: 300px;">
                <div class="v-date-filter">
                    <date-picker range v-model="date" :inline="true" :type="type"></date-picker>
                </div>
            </b-dropdown-form>
        </b-dropdown>
        <b-button variant="light" @click="$emit('remove')"><i class="far fa-times"></i></b-button>
    </b-button-group>
</template>

<script>
    import DatePicker from 'vue2-datepicker';

export default {
    name: 'DateFilter',
    components:{DatePicker},
    props: {
        filter: {
            type: Object,
            required: true
        },
        type: {
          type: String,
          default: "date"
        },
        value: {
            required:true
        }
    },
    computed:{
        formattedValue(){
            if(!this.date ) return null;
            let data = {}
            data[this.filter.filterProperty[0]] = this.$moment(this.date[0]).format('YYYY-MM-DD');
            data[this.filter.filterProperty[1]] = this.$moment(this.date[1]).format('YYYY-MM-DD');
            return data;
        },
        formattedValueLabel(){
            if(!this.date ) return null;
            return 'De '+this.$moment(this.date[0]).format('YYYY-MM-DD')+' a '+this.$moment(this.date[1]).format('YYYY-MM-DD');
        }
    },
    data(){
        return {
            date: null
        }
    },
    watch: {
        date(){
            //this.$emit('input', this.formattedValue);
            this.$emit('setDate', this.formattedValue);
        },
        value(newValue, oldValue){
            this.date = this.$moment(newValue,'YYYY-MM-DD').toDate();
        }
    },
    created() {
        if(this.value){
            this.date = this.$moment(this.value,'YYYY-MM-DD').toDate();
        }else{
            setTimeout(()=>{
                this.$refs.dropdown.visible = true;
            },100);
        }
    },
    methods:{

    }
}
</script>

<style >
    .b-dropdown-form .mx-datepicker-main {
        background-color: #fff;
        border: none!important;
    }
</style>
