import axios from "@/api/client";
import moment from 'moment';

// initial state
export const state = () => ({
    fetching: false,
    trucks : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    truck: null,
    last_filters: {},
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchTrucks({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/trucks', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTrucks({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/trucks',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setTrucks', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    storeTruck({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/trucks`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTruck({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/trucks/${params.id}`)
                .then(response=>{
                    commit('setTruck', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateTruck({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/trucks/${payload.id}`, payload)
                .then(response=>{
                    let trucks = _.cloneDeep(state.trucks);
                    let index = trucks.results.findIndex(item => item.id === response.data.id);
                    if(index > -1){
                        trucks.results[index] = response.data;
                        commit('setTrucks', trucks);
                    }
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error)
                    reject(error.response)
                });
        });
    },
    deleteTruck({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/trucks/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteManyTrucks({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/trucks/bulk`,{params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },  
    importTrucks({commit}, payload){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for(let [key, value] of Object.entries(payload)){
                formData.append(key, value);  
            }
            axios.post('/api/v2/trucks/import', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then((response)=>{
                    resolve(response.data);
                })
                .catch((error)=>{
                    reject(error.response);
                });
        });
    },
    exportTrucks({commit}, query){
         return new Promise((resolve, reject) => {
            axios({
                url:'/api/v2/trucks/export',
                method:'get',
                responseType: 'blob',
                params: query
                }).then(response=>{
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `trucks.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    resolve();
                })
                .catch(error =>{
                    reject(error.response)
                });
        });       
    }
};

// mutations
export const mutations = {
    setTrucks(state, data){
        state.trucks = data;
    },
    setTruck(state, data){
        state.truck = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    updateTruck(state, data){
        let item = state.trucks.results.find(item => item.id === data.id);
        if(item){
            item = data;
        }
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

