<template>
  <div class="d-flex align-items-center " style="min-width: 400px;margin-left: 138px;">


    <div v-if="this.$route.params.profile === 'admin'" class="btn btn-primary btn-sm px-4 font-size-16  waves-effect h-50 mr-4" @click="show">
      <i class="fa fa-sliders-h text-white "></i>
    </div>
    <div class="search_input">
      <multiselect v-model="form"
                   style="min-width: 450px"
                  
                   track-by="id"
                   :searchable="true"
                   :custom-label="nameWithLang"
                   :placeholder="$t('Search')" :options="options"
                   @search-change="onChange"

                    open-direction="bottom" :multiple="true" :loading="isLoading" :internal-search="false" :clear-on-select="true" :close-on-select="true"  :max-height="600"

      >
        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ $t(option.type) }}</strong>: {{ option.id }}</template>
      </multiselect>
    </div>




  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import Multiselect from "vue-multiselect";
import customFilterMixin from "../../mixins/customFilterMixin";

export default {
  name: "ControlTowerFilter",
  components:{
    Multiselect
  },
  data(){
    return {
      form:[],
      options:[],
      isLoading: false,
      query: false,
    };
  },
  computed: {
    ...mapState("profile", ["show_control_tower_filter"]),
    ...mapState('controltower',['search'])
  },
  created() {
    let options = [];
    if(this.$route.query.shipments){
        const shipments = this.$route.query.shipments.split(',');
        if(shipments.length > 0){
            for(let item of shipments){
              options.push({id: Number(item), type: 'SHIPMENT'});
            }
        }
    }
    if(this.$route.query.loads){
        const loads = this.$route.query.loads.split(',');
        if(loads.length > 0){
            for(let item of loads){
              options.push({id: Number(item), type: 'SINGLE'});
            }
        }
    }
    this.form = options;
    
    this.doSearch = _.debounce(this.fetchData, 100);
    this.doSearch();

  },
  mixins: [customFilterMixin],
  methods:{
    ...mapActions("profile",["toggleControlTowerFilter"]),
    ...mapActions('controltower',['searchLoads']),
    ...mapMutations('controltower',['setSearch']),
    show(){
      this.toggleControlTowerFilter();
    },
    fetchData () {
      this.isLoading = true;
      let query = _.cloneDeep(this.query);
      if (query){
        this.searchLoads({search: _.cloneDeep(this.query)}).then(data => {
          this.options = Array.isArray(data) ? data: data.results;
          this.isLoading = false
        })
      }
      this.isLoading = false
    },
    onChange(query){
      this.query = query;
      this.doSearch();
    },
    clearAll () {
      this.options = []
    },
    nameWithLang (item) {
      if (item.type === "SHIPMENT"){
        return `${this.$t("Shipment")}: ${item.id} ${item.tms_shipment_id ? `(${item.tms_shipment_id})`:''}`
      }
      return `${this.$t("Load")}: ${item.id} ${item.tms_id ? `(${item.tms_id})`:''}` 
    }
  },
  watch:{
    search : {
      deep: true,
      handler(newValue, oldValue){
        if(newValue.length === 0 && this.form.length > 0){
          this.form = [];
        }
      }
    },
    form : {
      deep: true,
      handler(newValue, oldValue){
        let shipments = newValue.filter(item => item.type ==='SHIPMENT');
        let loads = newValue.filter(item => ['SINGLE','DIVERT_IN_TRANSIT','RETURN','MULTILEG'].indexOf(item.type) > -1);
        let filter = {};
        if(shipments && shipments !== ""){
          filter['shipments'] = shipments.map(item => item.id).join(',');
        }
        if(loads && loads !== ""){
          filter['loads'] = loads.map(item => item.id).join(',');
        }
        
        this.setSearch(newValue);
        this.filterRemoved("shipments");this.filterRemoved("loads");
        this.onFiltersChange(filter);
      }
    },
  }
}
</script>

<style>
.custom-control.custom-radio{
  text-align: start;
}
.input_search{
  width: 100%;
  border-radius: 4px;
}
.input_search_radios{
  border-radius: 4px!important;
}
</style>
