<template>
    <div v-if="salaryAdvance" class="card">
        <div class="card-header">
            <h6>{{$t('Salary advance')}}</h6>
        </div>
        <div class="card-body text-center">
            <p>{{$t('Advance amount')}}</p>
          <p v-if="salaryAdvance.status !== 'pending'">
            <span class="badge" :class="[statusColor[salaryAdvance.status]]">{{$t('status.factoring.'+salaryAdvance.status)}}</span>
          </p>
          <div class="d-flex justify-content-center">
            <div class="amount " >
              <div class="center">
                $ {{salaryAdvance.amount}} <span style="font-size: 15px;">{{salaryAdvance.currency}}</span>
              </div>
            </div>
          </div>
          <div class="mt-4" v-if="salaryAdvance.status === 'pending'">
            <div class="d-flex justify-content-center">
              <button type="button" :disabled="accepting || rejecting" class="btn btn-dark mr-2"  @click="confirm(false)">
                 <span v-if="rejecting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{$t('Reject')}}</button>
               <button type="button" :disabled="accepting || rejecting" class="btn btn-secondary ml-2" @click="confirm(true)">
                 <span v-if="accepting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                 {{$t('Accept')}}
                 </button>
            </div>
          </div>

        </div>
    </div>
</template>
<style scoped>
.amount-badge{
  height: 60px;
  background: #F2F2F2;
  border-radius: 100px;
  width: fit-content;
  position: relative;
}
.amount-value{
  font-weight: bold;
  font-size: 25px;
  line-height: 42px;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;


  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

}
.amount{
    height: 60px;
    position: relative;
    background: #F2F2F2;
    border-radius: 100px;

    font-weight: bold;
    font-size: 25px;
    line-height: 42px;
    text-align: center;
    min-width: 150px;
    max-width: 230px;
;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width:100%;
}
</style>
<script>
import {mapActions} from 'vuex';
import Swal from "sweetalert2";

export default {
    name: 'SalaryAdvance',
    props:{
        load: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            salaryAdvanceOptions: null,
            statusColor: {
                "accepted":"badge-outline-primary",
                "rejected": "badge-outline-danger",
                "expired": "badge-outline-danger",
                "in_review": "badge-outline-warning",
                "pending": "badge-outline-primary",
                "completed": "badge-outline-success"
            },
            accepting : false,
            rejecting : false
        }
    },
    computed: {
        salaryAdvance(){
            if(!this.salaryAdvanceOptions)
              return null;
            if(this.salaryAdvanceOptions.results.length > 0 && this.$route.params.profile === 'admin'){
              return this.salaryAdvanceOptions.results[0];
            }
            return null
        }
    },
    created(){
        this.init();
    },
    methods: {
        ...mapActions('loads',['getSalaryAdvance','acceptSalaryAdvance','rejectSalaryAdvance']),
        init(){
                if(this.load.driver){
                  this.getSalaryAdvance({load_id: this.load.id})
                      .then(data=>{
                            this.salaryAdvanceOptions = _.cloneDeep(data);
                      })
                      .catch(error =>{

                      })
                }
        },
        accept(item){
          console.log(item);
          this.acceptSalaryAdvance({load_id: this.load.id, advance_id: item.id})
              .then(data =>{
                this.init();
              })
              .catch(error =>{
              })
        },
      reject(item){
        this.rejectSalaryAdvance({load_id: this.load.id, advance_id: item.id})
            .then(data =>{
              this.init();
            })
            .catch(error =>{

            })
      },
      confirm(accept = true){
        Swal.fire({
          title: this.$t('Confirm'),
          text: accept ? this.$t("Are you sure to accept?"): this.$t("Are you sure to reject?"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("Yes, continue"),
          cancelButtonText: this.$t("Cancel"),
          reverseButtons: true
        }).then(result => {
          if(result.value) {
            
            if(accept){
              this.accepting = true;
              this.accept(this.salaryAdvance)
                  .then(data =>{
                    this.$root.$bvToast.toast(this.$t("The action was completed successfully"), {
                      title: this.$t('Success'),
                      variant: 'success',
                      solid: true
                    });
                  })
                  .catch(error =>{
                    Swal.fire(
                        "Error",
                        this.$t("Something went wrong!"),
                        "error"
                    );
                  })
                  .finally(()=>{
                    this.accepting = false;
                  });
            }else{
              this.rejecting = true;
              this.reject(this.salaryAdvance)
                  .then(data =>{
                    this.$root.$bvToast.toast(this.$t("The action was completed successfully"), {
                      title: this.$t('Success'),
                      variant: 'success',
                      solid: true
                    });
                  })
                  .catch(error =>{
                    Swal.fire(
                        "Error",
                        this.$t("Something went wrong!"),
                        "error"
                    );
                  })
                  .finally(()=>{
                    this.rejecting = false;
                  });
            }
          }
        });

    },
    }
}
</script>