<template>
  <div class="main-content" :class="{'bgWhite' : $route.meta.bgWhite === true,'d-flex': menu.length > 0 && !loading}">
    <template v-if="loading || menu.length === 0">
      <div class="page-content">
        <div class="container-fluid">
          <loading v-if="loading" />
          <div v-else class="text-center m-5">
            <empty-results :subtitle="$t('There are no views to show')"  />
            <router-link class="btn btn-primary mt-4" :to="{name: 'settings.views.add'}">
              <i class="far fa-plus-circle mr-2" />{{$t('Create view')}}
            </router-link>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div  class="nav-sidebar" :class="[collapsed ? 'sidebar-collapsed':'']">
        <div class="d-flex">
          <div style="width: calc(100% - 28px);" id="sidebar-submenu">
            <slot name="prepend"></slot>
            <ul id="side-menu" class="metismenu list-unstyled">
              <li class="menu-title">
                {{ $t('Views') }}
              </li>
              <template v-for="(item, index) in menu">
                <li v-can="item.permission" v-if="!item.isTitle" :key="'sb-'+index" >
                  <router-link
                      :to="item.route"
                      class="side-nav-link-ref  d-flex justify-content-between pr-0"
                      active-class="active submenu-active"
                  >
                    <span class="one-line mr-3" v-b-tooltip.hover :title="item.label">{{ item.label }}</span>
                    <span class="badge badge-light font-weight-normal font-size-12">{{views[item.id] >= 0 ? views[item.id] :'-' }}</span>
                  </router-link>
                </li>
              </template>
            </ul>
          </div>
          <div style="height: 100%;width: 28px;">
            <button class="btn btn-light btn-sm rounded-circle buttonClose" @click="collapsed=!collapsed">
              <i class="far " :class="[collapsed ? 'fa-chevron-right':'fa-chevron-left']"/>
            </button>
          </div>
        </div>
      </div>
      <div class="page-content" :class="[collapsed ? 'side-collapsed':'side-expanded']" style="height: 100%">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
export default {
  name: "ViewLayout",
  components:{},
  data(){
    return {
      collapsed: false,
      loading: true,
      views: {}
    }
  },
  computed: {
    ...mapState('views',['activeViews']),
    menu(){
      return this.activeViews.results.map(item => ({label: item.title,id:item.id, route: {name:'views.detail', params:{view_id: item.id}}}));
    }
  },
  watch:{
     '$route': 'init',
  },
  created() {
    this.init();
  },
  methods:{
    ...mapActions('views',['getActiveViews','executeViews']),
    init(){
      if(this.$route.name !=='views.detail' && this.activeViews.results.length > 0){
        this.loading = false;
        this.$router.push({name:'views.detail', params:{view_id: this.activeViews.results[0].id}});
      }
      this.getActiveViews()
          .then(data=>{
            if(this.$route.name !=='views.detail' && data.results.length > 0){
              this.$router.push({name:'views.detail', params:{view_id: data.results[0].id}})
            }
          })
          .catch(error=>{

          })
          .finally(()=>{
            this.loading = false;
          });

      this.executeViews()
          .then(data=>{
            this.views = data;
          })
          .catch(error =>{

          });
    }
  }
}
</script>

<style scoped>

.has-sidebar{
  margin-left: 220px;
}
.nav-sidebar{
  min-width: 300px;
  /*transition: margin-left .5s;*/
}
.sidebar-collapsed{
  margin-left: -280px;
}

.side-expanded{
  margin-left: 300px;
  width: calc(100% - 300px)
}
.side-collapsed{
  margin-left: 28px;
  width: calc(100% - 28px);
  /* transition: width .5s;*/
}

.buttonClose{
  height: 28px!important;
  width: 28px!important;
  position: absolute;
  right: -14px;
  top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border: 1px solid #E7E1EC;
}
.page-content{
  /*transition: margin-left .5s;*/
}
</style>
