<template>
    <div class="main-content d-flex" :class="{'bgWhite' : $route.meta.bgWhite === true}" v-bind:style="MarginStyle">
        <div  class="nav-sidebar" :class="[collapsed ? 'sidebar-collapsed':'']" v-bind:style="MarginNavStyle">
            <div class="">
                <div style="width: calc(100% ); height: calc(100vh - 70px);overflow-y: auto;" id="sidebar-submenu">
                    <slot name="prepend"></slot>
                    <ul id="side-menu" class="metismenu list-unstyled">
                        <template v-for="(item, index) in menu">
                            <li v-can="item.permission" class="menu-title" v-if="item.isTitle" :key="'sb-'+index">
                                {{ $t(item.label) }}
                            </li>

                            <!--end Layouts menu -->
                            <li v-can="item.permission" v-if="!item.isTitle" :key="'sb-'+index">
                                <router-link
                                        :to="{name: item.route}"
                                        class="side-nav-link-ref"
                                        active-class="active submenu-active"
                                >
                                    <span>{{ $t(item.label) }}</span>
                                </router-link>

                            </li>
                        </template>
                    </ul>
                </div>
                <div style="height: 100%;width: 28px;">
                    <button class="btn btn-light btn-sm rounded-circle buttonClose" @click="collapsed=!collapsed">
                        <i class="far " :class="[collapsed ? 'fa-chevron-right':'fa-chevron-left']"/>
                    </button>
                </div>
            </div>
        </div>
        <div class="page-content" :class="[collapsed ? 'side-collapsed':'side-expanded']" style="height: 100%">
            <div class="container-fluid">
                <loading v-if="loading" />
                <router-view v-else></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SideContent",
        metaInfo(){
          return {
            title: `${this.$t(this.$route.meta.title)} - Traxporta`,
          }
        },
        props:{
            menu:{
                type: Array,
                required:true
            },
            loading: {
                type: Boolean,
                default(){
                    return true;
                }
            },
            margins: {
                type: String,
                default(){
                    return '';
                }
            }
        },
        computed:{
            MarginStyle() {
                if (this.margins ==='no') {
                    return {
                        "margin": "0px",
                        "position": "relative",
                    }
                }

                return {}
            },
            MarginNavStyle() {
                if (this.margins ==='no') {
                    return {
                        "position": "absolute",
                        "height": "calc(100vh)",
                    }
                }

                return {}
            }
        },
        data(){
            return {
                collapsed: false
            }
        }
    }
</script>

<style scoped>

    .has-sidebar{
        margin-left: 220px;
    }
    .nav-sidebar{
        /*transition: margin-left .5s;*/
    }
    .sidebar-collapsed{
        margin-left: -200px;
    }

    .side-expanded{
        margin-left: 220px;
        width: calc(100% - 220px)
    }
    .side-collapsed{
        margin-left: 28px;
         width: calc(100% - 28px);
        /* transition: width .5s;*/
    }

    .buttonClose{
        height: 28px!important;
        width: 28px!important;
        position: absolute;
        right: -14px;
        top: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        border: 1px solid #E7E1EC;
    }
    .page-content{
        /*transition: margin-left .5s;*/
    }
</style>
