<template>
  <div class="product-box">
    <div class="product-img">
      <div class="product-ribbon badge badge-warning text-capitalize ">{{item.type}}</div>
      <div class="product-like">
        <a href="#">
          <i class="mdi mdi-heart-outline"></i>
        </a>
      </div>
      <b-carousel
          style="text-shadow: 0px 0px 2px #000"
          controls
          indicators
          img-width="400"
          img-height="400"

      >
        <b-carousel-slide v-for="(image,index) in item.images"  :key="'pr'+index" >
          <template #img >
            <img
                height="300" style="object-fit: cover;margin: auto"
                :src=image.path
            >
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>

    <div class="text-center">
      <p class="font-size-12 mb-1">{{item.sku}}</p>
      <router-link v-can="'products.read'" :to="{name:'products.detail', params:{product_id: item.id}}" class="mr-2">
        <h5 class="font-size-15">
          <span href="" class="text-dark">{{item.name}}</span>
        </h5>
      </router-link>
      <h5 class="mt-0 mb-0">${{item.price}}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "productCard",
  props:{
    item:{
      default: {}
    }
  },
  created() {

  }
}
</script>

<style>
.carousel-item {
  text-align: center;
  max-height: 400px;
}
</style>