<template>
    <div class="">
        <h5 class="text-primary font-size-18 font-weight-bold text-capitalize">
            {{ date.format('DD MMMM') }}
            <span class="font-size-11 text-gray-600 font-weight-lighter ml-1">
                {{ date.format('dddd') | capitalize }}
            </span>
        </h5>
        <loading v-if="fetching" class="py-lg-5" />
        <div v-else>
            <div class="text-center py-lg-5" v-if="loads.results.length === 0">
                <img src="@/assets/images/txp/triangle.png" style="width: 100px;height: 100px;margin:10px;" />
                <div class="mt-3">
                    <h5>{{$t('There are no scheduled trips')}}</h5>
                    <p class="font-size-13 text-gray-600 px-lg-5">
                        {{$t('If you do not have any trip scheduled you can apply for one of the following offers that Traxporta can offer you')}}:
                        <router-link :to="{name:'loads.index'}">
                            {{$t('See travel offers')}}
                        </router-link>
                    </p>
                </div>
            </div>
            <vue-perfect-scrollbar :ref="'commentList'" class="scroll main-board" :settings="{ suppressScrollX: true, wheelPropagation: false }" @ps-y-reach-end="handleScroll" v-else>
                <ul class="list-group list-group-flush">
                    <router-link
                        tag="li"
                        class="list-group-item d-flex justify-content-between align-items-center pl-0 cursor-pointer"
                        v-for="load in loads.results"
                        :key="load.id"
                        :to="{name:'loads.detail.general', params:{load_id: load.id}}"
                    >
                        <div class="pl-1">
                            <div class="font-size-14">{{load.id}} - {{load.delivery_city}}</div>
                            <div class="font-size-14 font-weight-bold" v-if="load.purchase_price || load.purchase_price === 0">
                                ${{ load.purchase_price | money }} {{ load.currency }}
                            </div>
                            <div class="font-size-12">ID: {{load.id}}</div>
                        </div>
                        <i class="ri-arrow-right-s-line font-size-24 text-gray-500"></i>
                    </router-link>
                </ul>
            </vue-perfect-scrollbar>
        </div>


    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from "moment";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    name: "ListLoads",
    components: {
        VuePerfectScrollbar
    },
    props: {
        date: { type: Object },
    },
    computed: {
        ...mapState('loads', ['fetching', 'loads']),
    },
    methods: {
        handleScroll(item){ }
    }
}
</script>

<style scoped>

.list-group-item:hover  {
    background: #F8F9F9;
}

.main-board{
    height: calc(350px);
}

.centered{
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
