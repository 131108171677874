import axios from "../../api/client";


// initial state
export const state = () => ({
    overview : { },
});

// getters
export const getters = {};

// actions
export const actions = {
    setOverview({commit}, data){
        commit('setOverview',data);
    },
    completeProfile({commit}, query){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/user/complete_profile`,query)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {

    setOverview(state, data){
        state.overview = data;
    },
};

