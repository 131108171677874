export default {
    methods: {
        showSuccessResponse(edit = false){
            this.$root.$bvToast.toast(this.$t(edit ? 'Updated successfully':'Created successfully'), {
                title: this.$t('Success'),
                variant: 'success',
                solid: true
            });
        },
        processServiceError(error){
            let msg = null;
            if(error){
                switch(error.status){
                    case 401:
                        msg = this.$t("You don't have permission to do it");
                        break;
                    case 403:
                        msg = this.$t("You don't have permission to do it");
                        break;
                    case 405:
                        msg = this.$t("Method not allowed");
                        break;
                    case 404:
                    case 409:
                        msg = error.data.message;
                        break;
                    case 422:
                        if(this.$refs.observer) {
                            msg = this.$t("Please, check the form");
                            this.$refs.observer.setErrors(error.data.errors);
                        }else{
                            msg = error.data.message;
                        }
                        break;
                    case 500:
                        msg = this.$t("Something went wrong!");
                        break;
                }
            }else{
                msg = this.$t('Something went wrong, please check your internet connection');
            }
            if(msg){
                // TODO: Show notification
                this.$bvToast.toast(msg, {
                    title: "Error",
                    variant: 'danger',
                    solid: true
                });
            }
        }
    }
}
