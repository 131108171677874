<template>
    <div class="row">
        <div class="col-md-12 p-5">
            <div class="d-inline">
                <h4 class="d-inline font-weight-bold" @click="getData">
                    {{ $t("Recommended loads") }}
                </h4>
                <router-link
                    :to="{ name: 'recommendations.edit' }"
                    tag="button"
                    class="btn btn-link btn-sm"
                >
                <i class="far fa-edit btn-icon"></i>{{ $t('Edit') }}
            </router-link>
            </div>
            <loading-loads :number_loads="3" v-if="loading" />
            <loads-table v-else />
            <div class="text-center">
                <router-link
                    :to="{ name: 'search' }"
                    tag="button"
                    class="btn btn-outline-light text-uppercase btn-lg font-size-14 font-weight-bold px-5"
                >
                    <b>{{ $t("View all loads") }}</b>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import LoadsTable from "../tables/LoadTable";
import LoadingLoads from "../common/LoadingLoads";

export default {
    name: "RecommendedLoads",
    components: { LoadingLoads, LoadsTable },
    data() {
        return {
            loading: true
        };
    },
    created() {
        this.getData();
    },
    computed: {
        ...mapState("loads", ["loads"]),
    },
    methods: {
        ...mapActions("loads", ["getRecommendedLoads"]),
        getData() {
            this.getRecommendedLoads().finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped></style>
