import axios from "@/api/client";

// initial state
export const state = () => ({
    fetching: false,
    activeViews: {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    views : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    view: null,
    last_filters: {},
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchViews({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/views', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getActiveViews({commit}){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/views',{params: {active: true}})
                .then(response=>{
                    commit('setActiveViews', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    executeViews({commit}){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/views/execute',{params: {active: true}})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getViews({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/views',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setViews', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    storeView({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/views`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getView({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/views/${params.id}`)
                .then(response=>{
                    commit('setView', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateView({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/views/${payload.id}`, payload)
                .then(response=>{
                    let views = _.cloneDeep(state.views);
                    let index = views.results.findIndex(item => item.id === response.data.id);
                    if(index > -1){
                        views.results[index] = response.data;
                        commit('setViews', views);
                    }
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteView({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/views/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteManyViews({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/views/bulk`,{params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setViews(state, data){
        state.views = data;
    },
    setActiveViews(state, data){
        state.activeViews = data;
    },
    setView(state, data){
        state.view = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

