<template>
  <multiselect v-model="selected"
              track-by="name"
              label="name"
              :options="options"
              :searchable="false"
              :allow-empty="false">
    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "BranchOfficeInput",
  components:{
    Multiselect
  },
  props:["options","branch_office_selected","value"],
  computed:{

  },
  methods:{
    clear(){
      this.selected= null
    }
  },
  created() {
    this.selected = _.cloneDeep(this.value);
  },
  data () {
    return {
      selected: null,
    }
  },
  watch:{
    selected:{
      deep: true,
      handler(newValue, oldValue){
        if(newValue){
          this.$emit('input', newValue);
        }
      }
    },
    value:{
      deep: true,
      handler(value, oldValue){
        if(value !== undefined){
          if(!_.isEqual(value, oldValue)){
            //this.selected = null
          }
        }
      }
    },

  },
}
</script>

<style scoped>

</style>