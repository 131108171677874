<template>
  <div  class="" :class="{'bgWhite' : $route.meta.bgWhite === true,'main-content':!defaultCarrier}">
    <div :class="defaultCarrier?'':'page-content'" class=" py-0 pb-5">
      <div class="row pt-3">
        <div class="col-md-12">
          <div class="header_background ">
            <div class="row">
              <div class="col-md-8 pl-5 offset-md-2 mt-5 col-sm-12 text-center">
                <h3 class="text-white text-uppercase">Check in: {{today}}</h3>
              </div>
            </div>
          </div>
          <div class="row mtp_40">
            <div class="col-md-10 offset-md-1">
              <div class="row">
                <div class="col-md-4">
                  <div class="wallet-card">
                    <div class="card-badge bg-white d-flex justify-content-center align-items-center">
                      <b>Total de operadores</b>
                    </div>
                    <div class="d-flex bd-highlight" >
                      <div class="p-2 flex-grow-1 bd-highlight text-center text-balance ">
                        {{allDriversToday}}
                      </div>
                    </div>
                  </div>
                  <div class="wallet-card">
                    <div class="card-badge d-flex justify-content-center align-items-center">
                      <b>Operadores reportados</b>
                    </div>
                    <div class="d-flex bd-highlight" >
                      <div class="p-2 flex-grow-1 bd-highlight text-center text-balance ">
                        {{reportedToday}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="card rounded">
                    <div class="card-body">
                      <vue-perfect-scrollbar :ref="'tableScroll'" class="scroll main-board" :settings="{ suppressScrollX: true, wheelPropagation: false }" @ps-y-reach-end="handleScroll">
                        <table class="table">
                          <thead>
                          <tr>
                            <th scope="col">Nombre del operador:</th>
                            <th scope="col">Estatus:</th>
                          </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(driver,index) in attendancesToday.results" :key="'d-'+index">
                              <th >{{driver.name}}</th>
                              <td class="text-center"><div class="btn btn-primary btn-sm btn_in_table" :class="driver.status==='reported'?'':'not_report'">{{$t(driver.status)}}</div></td>
                            </tr>
                          </tbody>
                        </table>
                      </vue-perfect-scrollbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
        </div>
      </div>
      <div class="col-md-12 mt-2">
        <h4 class="text-center">{{historyMonth}}</h4>
        <div class="row mt-3">
          <div class="col-md-4">
            <filter-bar :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved"/>
          </div>
          <div class="col-md-4 text-center">
            <horizontal-calendar :date="historyDate" v-on:changeDate="changeDate"></horizontal-calendar>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-5 mb-5">
        <h3 class="text-center mb-5" >Historial de pases de lista</h3>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">Nombre del operador</th>
              <th scope="col">Reporte 1 </th>
              <th scope="col">Fecha y hora del reporte</th>
              <th scope="col">Reporte 2 </th>
              <th scope="col">Fecha y hora del reporte</th>
            </tr>
            </thead>
            <tbody>
            <template v-if="historyFetch">
              <tr>
                <td colspan="5"> <loading/>  </td>
              </tr>
            </template>
            <template v-else>
              <template v-if="attendancesHistory.total">
                <tr  v-for="(driver,index) in attendancesHistory.results" :key="'ddt-'+index">
                  <th scope="row">{{driver.name}}</th>
                  <template v-if="driver.attendances.length">
                    <template v-for="(attendance,index) in driver.attendances" >
                      <td>{{ attendance.address | address-format }}</td>
                      <td>{{ attendance  | timestamp }}</td>
                    </template>
                  </template>
                  <template v-for="n in (2-driver.attendances.length)">
                    <td>--</td><td>--</td>
                  </template>
                </tr>
              </template>
              <template  v-else >
                <tr>
                  <td colspan="5"><empty-results /></td>
                </tr>
              </template>
            </template>

            </tbody>
          </table>
        </div>
        <div  v-if="attendancesHistoryData.total > 0"  class="row align-items-center">
          <div class="col-md-6 col-sm-6 ">
            <b-pagination
                v-model="page"
                :total-rows="attendancesHistoryData.total"
                :per-page="attendancesHistoryData.per_page"
                aria-controls="my-table"
                first-number
                last-number
                pills
            ></b-pagination>
          </div>
          <div class="col-md-6 col-sm-6 text-sm-right">
            <p>{{$t('pagination', {from: attendancesHistoryData.from, to: attendancesHistoryData.to, total: attendancesHistoryData.total})}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import {mapActions, mapState} from "vuex";
import FilterBar from "../../components/filters/FilterBar";
import filterMixin from "../../mixins/filterMixin";
import HorizontalCalendar from "../../components/horizontalCalendar";

export default {
  name: "Attendance",
  data(){
    return{
      attendancesToday: {
        results: []
      },
      attendancesHistory: {
        results: []
      },
      historyFilterDate: null,
      historyFetch: false,
      defaultCarrier: null,
      page: 0,
      dateHistory: null,
    }
  },
  components: {
    HorizontalCalendar,
    VuePerfectScrollbar,
    FilterBar
  },
  mixins: [filterMixin],
  computed:{
    ...mapState('profile', ['profile']),
    options(){
      let filters = [
        {
          icon: 'far fa-calendar-week',
          label: this.$t('Date'),
          type: 'date',
          filterProperty: 'date',
          dateType: 'month'
        }
      ];
      return filters
    },
    today(){
      return moment().format('LLLL');
    },
    historyDate(){
      return this.historyFilterDate??new Date();
    },
    historyMonth(){
      return moment(this.historyDate).format("MMMM YYYY").toUpperCase()
    },
    carrier_id(){
      return this.defaultCarrier??this.profile.profile.id;
    },
    allDriversToday(){
      return this.attendancesToday.total
    },
    reportedToday(){
      return this.attendancesToday.results.filter(data=>data.status == 'reported').length
    },
    attendancesHistoryData(){
      return this.attendancesHistory?this.attendancesHistory:{total:0,per_page:5}
    }
  },
  created() {
    this.filterRemoved('date')
    if (this.$route.params.carrier_id){
      this.defaultCarrier = this.$route.params.carrier_id
    }
    this.getAttendancesByCarrier({id:this.carrier_id,params:{per_page:10000000}}).then((data)=>{
      this.attendancesToday = data
    })
  },
  methods:{
    ...mapActions('attendance',['getAttendances','getAttendancesByCarrier']),
    handleScroll(item){

    },
    getData(date){
      if (!this.filters.data){
        this.filters.date = moment(this.historyDate).format("Y-M-D")
      }
      this.getAttendancesByCarrier({id:this.carrier_id,params:{date:date,per_page:30}}).then((data)=>{
        this.attendancesHistory = data;
      }).finally(()=>{
        this.historyFetch =  false;
      });
    },
    changeDate(event){
      this.historyFetch =  true;
      this.dateHistory = event.date;
      this.getData( event.date);
    },
  },
  watch: {
    'filters.date' : {
      deep: true,
      handler(newVal, oldVal){
        if(!_.isEqual(newVal, oldVal)){
          this.historyFilterDate = moment(newVal).toDate();
        }
      }
    },
    page(newVal){
      this.historyFetch =  true;
      this.getAttendancesByCarrier({id:this.carrier_id,params:{date:this.dateHistory,per_page:30,page:this.page}}).then((data)=>{
        this.attendancesHistory = data;
      }).finally(()=>{
        this.historyFetch =  false;
      });
      this.$emit('page-change', newVal);
    }
  }
}
</script>

<style scoped>
.header_background{
  background: linear-gradient(90.3deg, #00B3E6 1.2%, #E3ED55 100%);
  height: 154px;
}
.wallet-card:last-child{
  margin-top: 10px;
}
.wallet-card{
  padding-top: 30px;
  width: 100%;
  height: auto;
  background: #00B3E6;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: .5rem;
  position: relative;
}
.card-badge{
  width: 190px;
  height: 30px;
  background: #E3ED55;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  margin-top: 0;
  position: absolute;
  left: -12px;
  top: 12px;
}
.not_report{
  background: #E3ED55!important;
  border-color: #E3ED55!important;
  color: rgb(0,0,0)!important;
}
.text-balance{
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 70px;

  color: #FFFFFF;
}
.rounded{
  border-radius: .5rem;
}
.main-board{
  height: 350px;
}
tr th{
  font-weight: 100;
}
thead tr th{
  font-weight: 600;
  text-align:center ;
}
td{
  text-align: center;
}
.btn_in_table{
  background: #00B3E6;
  min-width: 130px;
  border-radius: 2rem;
}
.mtp_40{
  margin-top: -40px;
}




</style>