import axios from "../../api/client";


// initial state
export const state = () => ({
    types: [],
    issues : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    issue: null,
    categories: [],
    fetching: false,
});

// getters
export const getters = {};

// actions
export const actions = {
    getIssueCategories({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/issue-categories`)
                .then(response=>{
                    commit('setIssueCategories', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
  
                });
        });
    },
    getIssueTypes({commit}, params){
      return new Promise((resolve, reject) => {
          axios.get(`/api/v2/loads/${params.load_id}/catalog/issues`,{params: params})
              .then(response=>{
                  commit('setIssueTypes', response.data);
                  resolve(response.data);
              })
              .catch(error =>{
                  reject(error.response)
              })
              .finally(()=>{

              });
      });
  },
    getIssues({commit}, params){
        commit('setFetching', true);
        const url = `/api/v2/loads/${params.load_id}/issues`;
        let query = _.cloneDeep(params);
        delete query.id;
        delete query.load_id;
        return new Promise((resolve, reject) => {
            axios.get(url,{params: query})
                .then(response=>{
                    commit('setIssues', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    deleteIssues({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/loads/${params.load_id}/issues/${params.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                });
        });
    },
    deleteFile({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/upload/${params.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                });
        });
    },
    getIssue({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.load_id}/issues/${params.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    getComments({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.load_id}/issues/${params.issue_id}/comments`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    deleteComment({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/loads/${params.load_id}/issues/${params.issue_id}/comments/${params.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    updateComment({commit}, params){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/loads/${params.load_id}/issues/${params.issue_id}/comments/${params.id}`,params)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    storeComment({commit}, params){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${params.load_id}/issues/${params.issue_id}/comments`,params)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    storeIssue({commit}, payload){
      return new Promise((resolve, reject) => {
          axios.post(`/api/v2/loads/${payload.load_id}/issues`, payload)
              .then(response=>{
                  resolve(response.data);
              })
              .catch(error =>{
                  reject(error.response)
              })
              .finally(()=>{

              });
      });
    },
    updateIssue({commit}, payload){
      return new Promise((resolve, reject) => {
          axios.put(`/api/v2/loads/${payload.load_id}/issues/${payload.id}`, payload)
              .then(response=>{
                  resolve(response.data);
              })
              .catch(error =>{
                  reject(error.response)
              })
              .finally(()=>{

              });
      });
    },
    getForm({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.load_id}/forms/${params.form_id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },

};

// mutations
export const mutations = {
    setIssues(state, payload){
        state.issues = payload;
    },
    setFetching(state, payload){
        state.fetching = payload;
    },
    setIssueTypes(state, payload){
      state.types = payload;
    },
    updateIssueState(state, payload){
      let issue = state.issues.results.find((item)=> item.id === payload.issue);
      issue.status = payload.status
    },
    setIssueCategories(state, payload){
        state.categories = payload;
    }
};

