<template>
    <side-content :menu="menu" :loading="loading">
        <template v-slot:prepend>
            <div v-if="account" >
                <div class="menu-title">{{title}}</div>
                <div class="ml-3 mb-3">
                    <div class="d-flex align-items-center">
                    <div>
                        <img v-if="!!account.logo"  :src="`${account.logo}`" alt
                        width="32" height="32" style="object-fit: cover;" />
                        <img v-else src="@/assets/images/txp/building.png" width="32px" />
                    </div>
                    <div class="ml-2">
                        <div class="one-line text-body" v-b-tooltip.hover :title="account.name" style="font-size:90%;line-height:1.2;width:150px;">{{account.name}}</div>
                        <div >
                            <star-rating :rating="account.rating" :read-only="true" :star-size="12" :show-rating="false"></star-rating>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <loading v-else/>
        </template>
    </side-content>
</template>

<script>
    import {mapState, mapActions, mapMutations} from 'vuex';
    import SideContent from "../SideContent";
    import StarRating from "vue-star-rating";
    export default {
        name: "Profile",
        metaInfo(){
          return {
            title: `${this.$t(this.$route.meta.title)}: ${this.account ? this.account.name:''}`,
          }
        },
        components:{
            SideContent,
            StarRating
        },
        data(){
            return {
                loading: true
            }
        },
        computed:{
            ...mapState('profile',['profile']),
            ...mapState('accounts',['account']),
            title(){
                if(this.$route.matched.some(({ name }) => name === 'carriers')){
                    return this.$t('Carrier');
                }
                if(this.$route.matched.some(({ name }) => name === 'shippers')){
                    return this.$t('Shipper');
                }
                return '...';
            },
            menu(){
                let menu = [];
                if(this.$route.matched.some(({ name }) => name === 'carriers')){
                    menu = [
                        {
                            label: 'Account',
                            route: 'carriers.account',
                        },
                        {
                            label: 'Loads',
                            route: 'carriers.loads',
                        },
                        {
                            label: 'History',
                            route: 'carriers.history',
                        },
                        {
                            label: 'Trucks',
                            route: 'carriers.trucks',
                        },
                        {
                            label: 'Trailers',
                            route: 'carriers.trailers',
                        },
                        {
                            label: 'Drivers',
                            route: 'carriers.drivers',
                        },
                        {
                            label: 'Documents',
                            route: 'carriers.documents',
                        },
                        {
                              label: 'Addresses',
                              route: 'carriers.addresses',
                          },
                          {
                              label: 'Attendance',
                              route: 'carriers.attendance',
                        }
                    ]
                }
                if(this.$route.matched.some(({ name }) => name === 'shippers')){
                    menu = [
                        {
                            label: 'Account',
                            route: 'shippers.account',
                        },
                        {
                            label: 'Loads',
                            route: 'shippers.loads',
                        },
                        {
                            label: 'History',
                            route: 'shippers.history',
                        },
                        {
                            label: 'Configuration',
                            isTitle: true,
                        },
                        {
                            label: 'Documents',
                            route: 'shippers.documents',
                        },
                        {
                            label: 'Routes and Agreements',
                            route: 'shippers.routes',
                        },
                        {
                            label: 'Proof of delivery',
                            route: 'shippers.pod',
                        },
                        {
                          label: 'External Requirements',
                          route: 'shippers.externalRequirements',
                        },
                        {
                          label: 'Addresses',
                          route: 'shippers.addresses',
                        }
                    ]
                }
                return menu;
            }
        },
        created(){
            this.init();
        },
        methods: {
            ...mapActions('accounts',['getAccount','updateAccount','fetchShippers','fetchCarriers']),
            ...mapMutations('accounts',['setAccount']),
            async init(){
                this.setAccount(null);
                if(this.$route.params.shipper_id){
                    let shippers = await this.fetchShippers({id: this.$route.params.shipper_id});
                    this.getAccount({id: shippers.results[0].account.id}).then(data=>{})
                            .catch(error=>{

                            }).finally(()=>{
                                this.loading = false;
                            });
                }
                if(this.$route.params.carrier_id){
                    let carriers = await this.fetchCarriers({id: this.$route.params.carrier_id});
                    this.getAccount({id: carriers.results[0].account.id})
                            .then(data=>{})
                            .catch(error=>{

                            }).finally(()=>{
                                this.loading = false;
                            });
                }

            },
        },
    }
</script>

<style scoped>

</style>
