<template>
  <div class="register" style="">
    <div class="register_title_back register_section cursor-pointer" @click="back()"> <i class="fa fa-chevron-left"/>
      {{ $t('Back') }} </div>
    <div class="register_title register_section">{{$t('Sign Up')}}</div>
    <div class="register_label register_section">
      <div class="register_label_title">{{$t('Finally, enter your email and set a password')}}</div>
    </div>
    <validation-observer ref="observer" v-slot="{ validate , errors}">
      <loading-overlay :loading="loading">
        <form   @submit.stop.prevent="submit(validate)"   >
          <div class="" >
            <div class="register_input_generic register_section">
              <div class="register_input_generic_label">{{ $t('Email') }}</div>
              <validation-provider name="email" rules="required|email" v-slot="{ errors }">
                <p :class="{ 'control': true }">
                  <input class="login_input_generic_input" v-model="model.email"   :class="{'input': true, 'is-danger': errors.length }"  name="email" type="text" placeholder="ejemplo@mail.com">
                  <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                </p>
              </validation-provider>
            </div>
            <div class="register_input_generic register_section">
              <div class="register_input_generic_label">{{ $t('Password') }}</div>
              <validation-provider name="password" rules="required|min:4" v-slot="{ errors }">
                <p :class="{ 'control': true }">
                  <input class="login_input_generic_input"  v-model="model.password"  :class="{'input': true, 'is-danger': errors.length }"  name="password" type="password" placeholder="">
                  <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                </p>
              </validation-provider>
            </div>
          </div>
          <div class="register_section register_action" >
            <button  :class="{'disabled':(hasErrors(errors))}"   class="btn btn-primary_v2 btn-block  text-uppercase  login_btn" type="submit" >{{$t('continue')}}</button>
          </div>
        </form>
      </loading-overlay>
    </validation-observer>
    <alert-modal :visible="notification.visible" :success="notification.success"  :title="notification.title" :message="notification.message" :sub_message="notification.sub_message" :reload_on_close="false" v-on:close="closeNotificationCustom" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getAccessToken, signUp} from '../../../utils/auth.js'
import Echo from "laravel-echo";
import AlertModalMixin from "../../../mixins/AlertModalMixin";
import AlertModal from "../../../components/modals/AlertModal";
import LoadingOverlay from "../../../components/common/LoadingOverlay";
export default {
  name: "Register_UserInfo",
  data(){
    return {
      model:{},
      loading: false
    }
  },
  components: {
    LoadingOverlay,
    AlertModal
  },
  mixins:[AlertModalMixin],
  created() {
    if (!this.getPhoneAndCodeAndUsernameValid()){
      this.$router.push({name:"register.index"})
    }
  },
  methods:{
    ...mapGetters('register',['getUserInfo']),
    ...mapGetters('register',['getPhoneAndCodeAndUsernameValid']),
    back(){
      this.$router.push({name:"register.index"})
    },
    async submit(validate) {
      if (!(await validate()) || this.loading) {
        this.$bvToast.toast(this.$t('Please, check the form'), {
          title: "Error",
          variant: 'danger',
          solid: true
        });
        return;
      }
      this.loading = true;
      let data = _.cloneDeep(this.model)
      let info = this.getUserInfo();
      let attrs = {
        given_name: info.name,
        family_name: info.last_name,
        phone_number: info.phone_number,
        email: info.email
      }
      signUp(data.email, data.password, attrs).then(async (user) => {
        window.Pusher = require('pusher-js');
        window.Echo = new Echo({
          broadcaster: 'pusher',
          authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          forceTLS: true,
          auth: {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              Accept: 'application/json',
            },
          }
        });
        await this.$router.push({name: "on_boarding"});
      }).catch((err) => {
        let message = err.message.replace("UserLambdaValidationException:", "")
        this.notificationError("Error", this.$t(message), this.$t(message));
      }).finally(()=>{
        this.loading= false;
      })
    },
    hasErrors(err){
      return !!_.findKey(err,o=>o.length>0)
    },
    closeNotificationCustom(){
      this.notification.visible = false;
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>