<template>
<div class="">
    <div class="commet_container">
      <div class=" commment" v-for="(item,index) in comments">
        <div class="comment_item">
          <div class="comment_data">
            <div class="comment_info">
              Enviado el {{item.created_at | human_date }}
            </div>
            <p class="comment_body">
            <span class="comment_user">
              {{$t(item.user.profile_type)}}:
            </span>
              {{item.body}}
            </p>
          </div>
        </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
  name: "CommentsReadOnly",
  props:{
    comments: {
      type: Array,
      default(){
        return [];
      }
    },

  }
}
</script>

<style scoped>
.commment{
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;

}
.commment:first-child{
  margin-top: 0;
}
.commment:last-child{
  margin-bottom: 15px;
}
.commment:nth-child(2n) > .comment_item{
  justify-content: flex-end;
  background: #ffffff;
}
.commment:nth-child(2n){
  justify-content: flex-end;
}
.comment_item{
  max-width: 60%;
  display: flex;
  align-items: center;
  border: 1px solid #F2F2F2;
  border-radius: 30px;
  padding: 5px 20px;
  background: #F2F2F2;
}
.comment_info{
  color: #888888;
  font-weight: 500;
  font-size: 11px;
  width: 100%;
}
.comment_body{
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 12px;
}
.comment_user{
  color: #00B3E6;
  font-weight: bold;
  text-transform: capitalize;
  margin-right: 5px;
}
.commet_container{
  padding: 15px;
}
</style>