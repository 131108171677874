<template>
    <GmapMarker v-if="loaded" :visible="visible"
                :position="{lat: position.latitude, lng: position.longitude}"
                :icon="truckIcon"
                @click="onClick"
                :animation="animation"
                v-on:mouseover="$emit('mouseover')"
                v-on:mouseout="$emit('mouseout')"
    />
</template>

<script>

const WIDTH = 48
const HEIGHT = 48

export default {
    name: 'TruckMarker',
    props:{
        position: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            default(){
                return true;
            }
        },
        icon:{
            type: String,
            default(){
                return null;
            }
        },
        animation:{
            type: Number,
            default(){
                return 0
            }
        }
    },
    data(){
        return {
            canvas: null,
            loaded: false,
            image: null,
        }
    },
    computed:{
        imageUrl(){
            if (!this.loaded) {
                return null
            }

            const ctx = this.canvas.getContext('2d')
            ctx.setTransform(1, 0, 0, 1, 0, 0)
            // resetTransform not working in safari
            // ctx.resetTransform()
            ctx.clearRect(0, 0, WIDTH, HEIGHT)

            ctx.translate(WIDTH / 2, HEIGHT / 2)
            ctx.rotate(this.position.direction*Math.PI/180)
            ctx.translate(-WIDTH / 2, -HEIGHT / 2)

            ctx.drawImage(this.image, 0, 0, WIDTH, HEIGHT)

            return this.canvas.toDataURL()
        },
        truckIcon(){
            return {
                scaledSize: {width: 54, height: 54, f: 'px', b: 'px'},
                url: this.icon || require('../../assets/images/txp/common/marcador1.png'),   
            }
        }
    },
    created(){
        this.canvas = document.createElement('canvas')
        this.canvas.width = WIDTH
        this.canvas.height = HEIGHT

        this.loaded = false
        this.image = new Image()
        this.image.src = require('../../assets/images/txp/common/truck.png')
        this.imageLoadPromise = new Promise(resolve => {
            this.image.onload = resolve
        }).then(() => {
            this.loaded = true
        })

    },

    methods: {
        onClick(){
            this.$emit('click');
        }
    }
}
</script>
