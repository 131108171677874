<template>
    <div class="card equipment-card mb-3">
        <div class="card-body">
            <div class="row">
                <div class="col-6 pl-3" v-if="container">
                    <span class="text-muted font-size-12">{{$t('Packaging')}}</span>
                    <br>
                    <span class="font-weight-bold font-size-18">{{container}}</span>
                </div>
                <div :class="[container ? 'col-6':'col-12']">
                    <span class="text-muted font-size-12">{{$t('Equipment')}}</span>
                    <br>
                    <span class="font-weight-bold font-size-18">{{equipment}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EquipmentCard",
    props: {
        container: String,
        equipment: String,
    },
}
</script>

<style lang="scss" scoped>
.card{
    border: none;
    border-radius: 0 20px 20px 20px;
    background-color: #EBEBEB!important;
}
</style>
