<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <th v-if="false" class="text-center" style="width: 48px;">
                        <b-form-checkbox
                                v-model="selectAll"
                                name="checkbox-1"
                                button-variant="secondary"
                        >
                        </b-form-checkbox>
                    </th>

                    <th class="text-center">ID</th>
                    <th class="text-center">{{$t('Type')}}</th>
                    <th class="text-center">{{$t('Name')}}</th>
                    <th class="text-center">{{$t('Due date')}}</th>
                    <th v-if="['admin','staff'].indexOf($route.params.profile) > -1" class="text-center">TMS ID</th>
                    <!--
                    <th class="text-center" style="width: 200px;">{{$t('From')}}</th>
                    <th class="text-center" style="width: 200px;">{{$t('To')}}</th>
                    -->
                    <th class="text-center">{{$t('Truck type')}}</th>
                    <th class="text-center" v-if="$route.params.profile !=='driver'">{{$t('Price')}}</th>
                    <th class="text-center" style="min-width: 90px;max-width:100px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fetching">
                    <td colspan="100%">
                        <loading />
                    </td>
                </tr>
                <template v-else v-for="item in list.results">
                    <tr :key="'load-'+item.id"  class="load-row">
                        <td v-if="false">
                            <b-form-checkbox
                                    v-model="selected"
                                    name="checkbox-1"
                                    :value="item.id"
                            >
                            </b-form-checkbox>
                        </td>
                        <td class="text-center">
                            <template v-if="['admin','staff'].indexOf($route.params.profile) > -1">
                                <router-link :to="{name: 'shippers.routes.edit', params:{route_id: item.id}}">
                                    <b>{{item.id}}</b>
                                </router-link>
                            </template>
                            <template v-else>
                                {{item.id}}
                            </template>

                        </td>
                        <td class="text-center">
                           <span class="badge badge-outline-secondary">
                                {{$t(item.type)}}
                            </span>
                        </td>
                        <td>
                             <div class="two-lines">
                                 {{item.name}}
                             </div>
                        </td>
                        <td>{{item.expires_at ? $moment(item.expires_at).format('YYYY-MM-DD'):'- -'}}</td>
                        <td v-if="['admin','staff'].indexOf($route.params.profile) > -1">
                            {{item.tms_id ? item.tms_id : '- -'}}
                        </td>
                        <!--
                        <td>
                            <div class="two-lines">
                                {{item.pickup.address.city}}, {{item.pickup.address.state}}
                            </div>
                        </td>
                        <td>
                            <div class="two-lines">
                                {{item.delivery.address.city}}, {{item.delivery.address.state}}, {{item.delivery.address.country}}
                            </div>
                        </td>
                        -->
                        <td>{{item.truck_type ? item.truck_type.name : '- -'}}</td>
                        <td >$ {{item.price | money}} <br/>{{item.currency}}</td>
                        <td>
                            <a v-if="route && route.id === item.id" href="javascript:void(0);" @click="route = null">
                                <i class="far fa-chevron-circle-up btn-icon"></i>
                            </a>
                            <a v-else href="javascript:void(0);" @click="showRow(item)">
                                <i class="far fa-chevron-circle-down btn-icon"></i>
                            </a>
                        
                        </td>
                    </tr>
                    <template v-if="route && route.id === item.id">
                        <tr :key="'load-detail-'+item.id">
                            <td colspan="100%" class="p-0">
                                <loading v-if="fetchingLoad" />
                                <div v-else class="card mb-0">
                                    <div  class="row m-0">
                                        <div class="col-md-4 col-sm-6 px-0">
                                            <route-map style="width:100%;height:400px;" :load="route"></route-map>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="row">
                                                            <div class="col-md-5">
                                                                <div class="field-wrapper border-bottom-none p-0">
                                                                    <div class="field-label">{{$t('Load type')}}</div>
                                                                    <div class="field-help">{{route.load_type ? route.load_type.name : '- -'}}</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-7">
                                                                <div class="field-wrapper border-bottom-none p-0">
                                                                    <div class="field-label">{{$t('Configuration')}}</div>
                                                                    <div class="field-help">{{route.configuration ? route.configuration.name: '- -'}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="field-wrapper border-bottom-none p-0">
                                                            <div class="field-label">{{$t('Truck type')}}</div>
                                                            <div class="field-help"><i class="far fa-truck mr-2" />{{route.truck_type ? route.truck_type.name : '- -'}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-md-6">
                                                        <div class="row">
                                                            <div class="col-md-5">
                                                                <div class="field-wrapper border-bottom-none p-0">
                                                                    <div class="field-label">{{$t('Weight')}}</div>
                                                                    <div class="field-help"><i class="far fa-weight mr-2" />{{route.weight}} {{route.weight_unit}}</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-7">
                                                                <div class="field-wrapper border-bottom-none p-0">
                                                                    <div class="field-label">{{$t('Container type')}}</div>
                                                                    <div class="field-help"><i class="far fa-container-storage mr-2" />{{route.container_type}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="field-wrapper border-bottom-none p-0">
                                                            <div class="field-label">{{$t('Commodity')}}</div>
                                                            <div class="field-help">{{route.commodity ? route.commodity.name: '- -'}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-md-6">
                                                        <div class="d-flex justify-content-between">
                                                            <h5><i class="far fa-truck-loading mr-3" />{{$t('Pickup')}}</h5>
                                                        </div>
                                                        <div class="row mt-2">
                                                            <div class="col-md-12 mt-3" v-for="address in route.pickup_addresses" :key="`${item.id}-pickup-add-${address.id}`">
                                                                <div class="field-wrapper border-bottom-none p-0">
                                                                    <div class="field-label">{{$t('Address')}}</div>
                                                                    <div class="field-help" :key="'pickup-line-'+index" v-for="(item,index) in getAddressFormat(address)">{{item}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="d-flex justify-content-between">
                                                            <h5><i class="far fa-shipping-timed mr-3" />{{$t('Delivery')}}</h5>
                                                        </div>
                                                        <div class="row mt-2">
                                                            <div class="col-md-12 mt-3" v-for="address in route.delivery_addresses" :key="`${item.id}-delivery-add-${address.id}`">
                                                                <div class="field-wrapper border-bottom-none p-0">
                                                                    <div class="field-label">{{$t('Address')}}</div>
                                                                    <div class="field-help" :key="'pickup-line-'+index" v-for="(item,index) in getAddressFormat(address)">{{item}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
                </tbody>
            </table>
        </div>
        <div  v-if="list.total > 0"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    import Loading from "../common/Loading";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import addressFormat from '@/helpers/address-format';
    import RouteMap from "@/components/common/RouteMap";

    export default {
        name: "RouteTable",
        mixins:[selectAllMixin],
        components: {
            'loading':Loading,
            'route-map': RouteMap
        },
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
            return {
                page: 1,
                init: false,
                selected: [],
                route: null,
                fetchingLoad: false,
                markers:[]
            }
        },
        computed: {
            ...mapState('routes', ['last_filters', 'routes','fetching']),
            ...mapState('profile',['profile']),
            list(){
                return this.routes
            }
        },
        created() {

            this.doSearch = _.debounce(this.fetchData,50);
            this.page = this.filters.page ? Number(this.filters.page) : 1;
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal) || this.init === false){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('routes',['getRoutes','getRoute']),
            fetchData(){
                let filters = _.cloneDeep({...this.filters, ...this.defaultFilters});
                
                this.getRoutes(filters)
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.init = true;
                    })
            },
            showRow(item){
                this.fetchingLoad = true;
                this.route = item;
                this.getRoute({id: item.id})
                    .then(data=>{
                        this.route = data;
                    })
                    .catch(error =>{

                    })
                    .finally(error =>{
                        this.fetchingLoad = false;
                    })
            },
            getAddressFormat(address){
                return addressFormat(address)
            }
        }
    }
</script>

<style scoped>
    .load-row>td{
        vertical-align: middle !important;
        height: 67px!important;
        max-height: 67px!important;
        text-align: center !important;
    }
</style>
