<template>
    <ul class="list-group font-size-12 shadow-lg">
        <li class="list-group-item d-flex justify-content-between align-items-center bg-celeste py-4">
            <span class="font-size-13">{{$t('Base rate')}}</span>
            <span class="font-size-14 font-weight-bold">${{rate.sale_price | money }}</span>
        </li>
        <li class="list-group-item d-none justify-content-between align-items-center bg-celeste py-4" v-if="gatas">
            <div class="font-size-13">
                Gatas x {{gatas.quantity}}
            </div>
            <span class="font-size-14 font-weight-bold">${{gatas.amount | money}}</span>
        </li>
        <li class="list-group-item" v-if="accesories.length > 0">
            <div class="font-size-13 d-none">{{$t('Additional')}}</div>
            <div v-for="(accessory, index) in accesories">
                <div
                    class="d-flex justify-content-between align-items-center"
                    :class="[index !== accesories.length - 1 ? 'mb-4' : '']"
                >
                    <div class="font-size-13">
                        {{$t(accessory.name)}}
                        <span v-if="['O_GATAS','O_ESTANCIA'].indexOf(accessory.code) > -1   "> x {{accessory.quantity}}</span>
                    </div>
                    <div class="font-size-14 font-weight-bold">${{accessory.amount | money}}</div>
                </div>
            </div>
        </li>
        <li class="list-group-item d-none justify-content-between align-items-center bg-celeste py-4">
            <span class="font-size-13">IVA</span>
            <span class="font-size-14 font-weight-bold">${{IVA | money }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center py-4">
            <span class="font-size-13">Total:</span>
            <span class="font-size-24 text-primary font-weight-bolder">
                ${{SUBTOTAL | money}} <span class="font-size-15">{{rate.currency}}</span>
            </span>
        </li>
    </ul>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: "PayList",
    computed: {
        ...mapGetters('marketplace', ['accesories', 'rate']),
        gatas() { return this.accesories.find(e => e.name === 'Gatas') },
        total_accesories(){
            if(this.accesories && this.accesories.length > 0){
                return this.accesories.reduce((a, b) => a + (b['amount'] || 0), 0);
            }
            return 0;
        },
        SUBTOTAL() {
            return Number(this.rate.sale_price) + this.total_accesories;
        },
        IVA() {
            const IVA = 0.16;
            return this.SUBTOTAL * IVA;
        },
        TOTAL() {
            return this.SUBTOTAL + this.IVA
        }
    },
}
</script>

<style scoped lang="scss">
.bg-celeste{
    background-color: #EDFBFF!important;
}
.list-group{
    width: 300px;
    border-radius: 20px;
}
</style>
