<template>
    <div>
        <page-header :title="$t($route.meta.title)" />
        <div class="row">
            <div class="col-md-12">
                <filter-bar :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved">
                    <template v-slot:right>
                        <div class="btn-group mb-3" role="group" aria-label="toolbar">
                            <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getUsers(filters)" type="button" class="btn btn-light">
                                <i class="far fa-sync"></i>
                            </button>
                            <button v-if="$route.name.indexOf('members') > -1" v-can="'users.create'" v-b-tooltip.hover :title="$t('Import')" @click="$refs.import.click()" type="button" class="btn btn-light">
                                <i class="far fa-file-import"></i>
                            </button>
                            <router-link v-can="'users.create'" v-b-tooltip.hover :to="{name: 'settings.members.add'}" class="btn btn-light">
                                <i class="far fa-fw fa-plus"></i>
                            </router-link>
                            <button v-can="'users.delete'" v-bind:disabled="selected.length === 0" v-b-tooltip.hover :title="$t('Delete')" type="button" class="btn btn-light">
                                <i class="far fa-fw fa-trash-alt"></i>
                            </button>
                            <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
                        </div>
                    </template>
                </filter-bar>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <user-table v-model="selected" :filters="filters" edit="settings.members.edit" v-on:page-change="pageChange" />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import UserTable from "@/components/tables/UserTable";
    import FilterBar from "@/components/filters/FilterBar";
    import filterMixin from "@/mixins/filterMixin";

    export default {
        name:'Users',
        components:{
            UserTable,
            FilterBar
        },
        mixins: [filterMixin],
        data(){
            return {
                tabs: [],
                currentTab: null,
                options: [],
                selected: []
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapState('trucks', ['last_filters', 'trucks']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$route.name.indexOf('drivers') > -1 ? this.$t("Drivers"):  this.$t("Users"),
                        active: true,
                        to: {name: this.$route.name.indexOf('drivers') > -1 ? 'drivers.index' : 'users.index'}
                    }
                ]
            },
        },
        created() {
            this.init();
        },
        mounted(){
            this.defaultFilters = {roles: this.$route.params.profile === 'admin' ? 'admin,staff': this.$route.params.profile+'.user'};
        },
        watch:{
            '$route': 'init'
        },
        methods: {
            ...mapActions('users',['getUsers','importUsers']),
            init(){
                this.isDocumentModule = this.$route.meta.isDocumentModule ? this.$route.meta.isDocumentModule : false

                if(this.$route.params.profile === 'admin' && !this.isDocumentModule){
                     this.options = [
                        {
                            icon: 'far fa-key',
                            label: 'ID',
                            type: 'text',
                            filterProperty: 'id',
                        },
                        {
                            icon: 'far fa-search',
                            label: this.$t('Search'),
                            type: 'text',
                            filterProperty: 'search',
                        },
                       {
                         icon: 'far fa-envelope',
                         label: this.$t('Email'),
                         type: 'text',
                         filterProperty: 'email',
                       },
                       {
                         icon: 'far fa-phone',
                         label: this.$t('Phone'),
                         type: 'text',
                         filterProperty: 'phone',
                       },
                        {
                            icon: 'far fa-users-class',
                            label: this.$t('Role'),
                            type: 'multiselect',
                            filterProperty: 'roles',
                            values: [
                                {text: this.$t('Shipper'), value: 'shipper'},
                                {text: this.$t('Carrier'), value: 'carrier'},
                                {text: this.$t('Driver'), value: 'driver'},
                                {text: this.$t('Admin'), value: 'admin'},
                            ],
                            valueType: 'number'
                        },
                    ];

                    if (this.$route.name.indexOf('drivers') > -1) {
                         this.options.splice(1,0,{
                            icon: 'far fa-key',
                            label: 'TMS ID',
                            type: 'text',
                            filterProperty: 'tms_id',
                         });
                    }
                }else{
                    this.options = [
                        {
                            icon: 'far fa-key',
                            label: 'ID',
                            type: 'text',
                            filterProperty: 'id',
                        }
                    ];
                }

                let filters = {};
                if(this.$route.name.indexOf('drivers') > -1 ){
                    filters = {roles: 'driver'};
                }

                if(this.$route.params.profile !== 'admin'){
                    filters['account_id'] = this.profile.account.id;
                }

                // Use param filters
                if(this.$route.meta.paramFilters){
                    for(let key of this.$route.meta.paramFilters){
                        filters[key] = this.$route.params[key];
                    }
                }
                if(this.$route.meta.filters){
                    filters = {...filters, ...this.$route.meta.filters};
                }

                this.defaultFilters = filters;


                let toolbarFilters = {};
                for(let item of this.options){
                    if(this.$route.query.hasOwnProperty(item.filterProperty)){
                        toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
                    }
                }
                this.toolbarFilters = toolbarFilters;
            },
            handleFile(){
                let payload= {template : this.$refs.import.files[0]};

                payload.role = 'staff';
                this.importUsers(payload)
                     .then(data=>{
                        Echo.private(`jobs.${data.job_id}`)
                            .listen('JobProcessed', (e) => {
                                this.getUsers(this.filters);
                                Echo.leave(`jobs.${data.job_id}`);
                            });
                        this.$root.$bvToast.toast(this.$t("File uploaded successfully"), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                                });

                    })
                    .catch(error =>{

                    })
                    .finally(()=>{

                    });

            }
        }
    }
</script>

<style>

</style>
