export default [
    {
        text: 'English',
        value: 'en'
    },
    {
        text: 'Spanish',
        value: 'es'
    }
]
