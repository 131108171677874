<template>
  <div>
    <div class="mt-4">
      <h5 class="font-size-14">Reviews :</h5>
      <div class="d-inline-flex mb-3">
        <div class="text-muted mr-3">
          <span class="mdi mdi-star text-warning"></span>
          <span class="mdi mdi-star text-warning"></span>
          <span class="mdi mdi-star text-warning"></span>
          <span class="mdi mdi-star text-warning"></span>
          <span class="mdi mdi-star"></span>
        </div>
        <div class="text-muted">( 132 customer Review)</div>
      </div>
      <div class="border p-4 rounded">
        <div class="media border-bottom pb-3">
          <div class="media-body">
            <p class="text-muted mb-2">
              To an English person, it will seem like simplified English, as a
              skeptical Cambridge
            </p>
            <h5 class="font-size-15 mb-3">James</h5>

            <ul class="list-inline product-review-link mb-0">
              <li class="list-inline-item">
                <a href="#">
                  <i class="mdi mdi-thumb-up align-middle mr-1"></i> Like
                </a>
              </li>
              <li class="list-inline-item">
                <a href="#">
                  <i class="mdi mdi-message-text align-middle mr-1"></i> Comment
                </a>
              </li>
            </ul>
          </div>
          <p class="float-sm-right font-size-12">11 Feb, 2020</p>
        </div>
        <div class="media border-bottom py-3">
          <div class="media-body">
            <p class="text-muted mb-2">
              Everyone realizes why a new common language would be desirable
            </p>
            <h5 class="font-size-15 mb-3">David</h5>

            <ul class="list-inline product-review-link mb-0">
              <li class="list-inline-item">
                <a href="#">
                  <i class="mdi mdi-thumb-up align-middle mr-1"></i> Like
                </a>
              </li>
              <li class="list-inline-item">
                <a href="#">
                  <i class="mdi mdi-message-text align-middle mr-1"></i> Comment
                </a>
              </li>
            </ul>
          </div>
          <p class="float-sm-right font-size-12">22 Jan, 2020</p>
        </div>
        <div class="media py-3">
          <div class="media-body">
            <p class="text-muted mb-2">
              If several languages coalesce, the grammar of the resulting
            </p>
            <h5 class="font-size-15 mb-3">Scott</h5>

            <ul class="list-inline product-review-link mb-0">
              <li class="list-inline-item">
                <a href="#">
                  <i class="mdi mdi-thumb-up align-middle mr-1"></i> Like
                </a>
              </li>
              <li class="list-inline-item">
                <a href="#">
                  <i class="mdi mdi-message-text align-middle mr-1"></i> Comment
                </a>
              </li>
            </ul>
          </div>
          <p class="float-sm-right font-size-12">04 Jan, 2020</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'ProductReviews',
    props: {
        product_id: {
            type: String,
            required: true
        }
    }
}
</script>
