<template>
    <div class="card card-txp">
        <div class="px-2">
            <div class="row text-left bg-primary-linear-gradient rounded">
                <div class="col-lg-8 col-md-6 col-sm-6 col-6 p-125">
                    <h5 class="text-white font-size-18 ml-2 d-flex align-items-center">
                        <i class="ri-map-pin-line"></i> &nbsp;
                        {{$t('Load in progress')}}
                    </h5>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-6 bg-grey-blue rounded-right p-125" role="button" v-b-toggle.collapse-1>
                    <b-skeleton type="button" v-if="loading" />
                    <h5 class="text-white font-size-18 d-flex align-items-center justify-content-center" v-else>
                        {{ total }}
                        <i class="font-size-25 text-yellow" :class="[collapse ? 'ri-arrow-up-s-fill' : 'ri-arrow-down-s-fill']" />
                    </h5>
                </div>
            </div>
            <b-collapse id="collapse-1" class="position-absolute" style="z-index: 9999">
                <vue-perfect-scrollbar :ref="'commentList'" class="scroll main-board" :settings="{ suppressScrollX: true, wheelPropagation: false }" @ps-y-reach-end="handleScroll">
                    <b-card-body class="bg-primary mt-n3">
                        <div class="row">
                            <div class="col-lg-12" v-for="(item, index) in loads">
                                <router-link :to="{name:'loads.detail.general', params:{load_id: item.id}}">
                                    <div class="row bg-grey-blue mt-2 font-size-12 text-white rounded p-2">
                                        <div class="col-4">
                                            {{item.pickup_state_alpha3 }} - {{item.delivery_state_alpha3}}
                                        </div>
                                        <div class="col-4">
                                            <span class="text-yellow font-weight-bold">{{$t(item.status) | uppercase}}</span>
                                        </div>
                                        <div class="col-3 text-white">
                                            ID: {{item.id}}
                                        </div>
                                        <div class="col-lg-1 col-md-1 col-sm-1 col-1">
                                            >
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-12 mt-3 text-center">
                                <router-link :to="{name:'loads.index'}" >
                                    <a href="#" class="text-white font-size-12 font-weight-bold">{{$t('Go to loads') | uppercase}}</a>
                                </router-link>
                            </div>
                        </div>
                    </b-card-body>
                </vue-perfect-scrollbar>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    name: 'InProgressLoads',
    components: {
        VuePerfectScrollbar
    },
    data(){
        return {
            loading: false,
            loads: [],
            total: 0,
            collapse: false
        }
    },
    created(){
        this.loading = true;
        let params = {}
        this.getInProgressLoads(params).then(data => {
            this.loads = _.cloneDeep(data.results)
            this.total = _.cloneDeep(data.total)
        }).catch(() => {

        }).finally(() => {
            this.loading = false;
        });
    },
    methods: {
        ...mapActions('loads', ['getInProgressLoads']),
        handleScroll(item){ },
    }
}
</script>

<style scoped>
    .card-txp {
        padding: 0 2px;
        border-radius: 15px;
        box-shadow: 1px 4px 3px #dcdcdc;
    }
    #collapse-1{
        left: 0;
    }
    .main-board{
        height: calc(180px);
    }
    .p-125{
        padding: 1.5rem;
    }
</style>
