<template>
    <div class="main-content" :class="{'bgWhite' : $route.meta.bgWhite === true}">
        <div :class="{'bgWhite' : $route.meta.bgWhite === true}">
            <div class="container-fluid p-0">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Content",
        metaInfo(){
          return {
            title: `${this.$t(this.$route.meta.title)} - Traxporta`,
          }
        },
    }
</script>

<style scoped>
    .bgWhite{
        background-color: white!important;
    }
</style>
