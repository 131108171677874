const _query = {
    query: {
        bool: {
            must: [],
        },
    },
};

const moment = require('moment');

export default (filters) => {
    const query = _.cloneDeep(_query);
    const must = query.query.bool.must;

    const equipments = filters.equipments;
    const origins = filters.origins;
    const destinies = filters.destinies;
    const pickup_date = filters.pickup_date;

    let should = [];
    for (let index = 0; index < equipments.length; index++) {
        should.push({
            match_phrase: { "equipment_type.code": equipments[index].code },
        });
    }
    
    if (should.length > 0) {
        must.push({
            bool: {
                should: should,
            },
        });
    }

    should = [];
    for (let index = 0; index < origins.length; index++) {
        should.push({
            match_phrase: { "origin.state_code": origins[index].value },
        });
    }
    if (should.length > 0) {
        must.push({
            bool: {
                should: should,
            },
        });
    }

    should = [];
    for (let index = 0; index < destinies.length; index++) {
        should.push({
            match_phrase: {
                "destination.state_code": destinies[index].value,
            },
        });
    }
    if (should.length > 0) {
        must.push({
            bool: {
                should: should,
            },
        });
    }

    if (pickup_date) {
        must.push({
            bool: {
                should: [
                    {
                        range: {
                            "origin.date": {
                                gte: moment(pickup_date).format('YYYY-MM-DD')+"T00:00:00",
                                lte: moment(pickup_date).format('YYYY-MM-DD')+"T23:59:59",
                                format: "strict_date_optional_time",
                            },
                        },
                    },
                ],
            },
        });
    }

    return query;
};
