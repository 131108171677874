<template>
    <div class="font-family-helvetica">
        <h4 class="mb-4 px-4 font-weight-bolder">{{ $t('Create shipments') }} / <span class="text-primary">FTL</span>
        </h4>
        <hr>
        <div class="font-weight-bolder px-4 font-family-inter text-primary font-size-22 mb-4">
            {{ admin? $t('Shipment summary'): $t('Terms and Conditions') }}
        </div>
        <div class="row px-4 py-2 mb-100">
            <div class="col-6">
                <div class="mb-4" style="width:660px">
                    <polyline-map :center="center" :path="path" :class="'map'" :markers="markers" />
                </div>
                <hr>
                <shipment-info />
                <hr>
                <summary-equipment :edit="false" />
            </div>
            <div class="col-4 offset-1 pl-5">
                <pay-list />
            </div>
        </div>
        <div class="fixed-bottom">
            <div class="row">
                <div v-if="!admin" class="col-4 offset-2 py-3">
                    <div class="font-weight-bolder font-size-18">¿{{ $t('You accept the rate and the') }} {{
                        $t('Terms and Conditions')
                    }}?</div>
                    <div class="font-size-12 d-flex justify-content-start align-items-center mt-1">
                        <b-form-checkbox v-model="acepted" name="checkbox-1" button-variant="secondary" />
                        <div>
                            {{ $t('I have read and accept the') }} <span class="text-primary">{{
                                $t('Terms and Conditions')
                            }}</span>
                        </div>
                    </div>
                </div>

                <div v-else class="col-4 offset-2 py-3">

                </div>

                <div class="col-4">
                    <div v-if="!fetching">
                        <button type="button"
                            class="btn btn-outline-light text-uppercase font-size-12 font-weight-bolder px-5 mt-3"
                            @click="setStep(6)">
                            {{ $t('Go Back') }}
                        </button>

                        <button v-if="admin" type="button"
                            class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder px-4 mt-3 ml-3"
                            :class="['']" @click="submit()">
                            {{ $t('Accept and Finish') }}
                        </button>

                        <button v-else type="button"
                            class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder px-4 mt-3 ml-3"
                            :class="[!acepted ? 'disabled' : '']" @click="submit()">
                            {{ $t('Accept and Finish') }}
                        </button>
                    </div>
                    <div class="d-flex align-items-center" v-else>
                        <b-skeleton type="button" width="39%" class="px-5 mt-3" />
                        <b-skeleton type="button" width="39%" class="px-4 mt-3 ml-3" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import PayList from '@/components/marketplace/accesories/PayList.vue';
import SummaryEquipment from '@/components/marketplace/equipment/SummaryEquipment';
import PolylineMap from "@/components/common/PolylineMap.vue";
import ShipmentInfo from "@/components/marketplace/ShipmentInfo.vue";

export default {
    name: "Checkout",
    components: {
        PayList,
        PolylineMap,
        SummaryEquipment,
        ShipmentInfo
    },
    data() {
        return {
            acepted: false,
            fetching: false,
            center: {},
            markers: {},
            path: [],
        }
    },
    computed: {
        ...mapGetters('marketplace', ['shipment_request', 'origin', 'destination', 'route', 'shipment']),
        ...mapState('profile', ['profile']),
        admin() {
            if (this.$route.params.profile == 'admin')
                return true;
            else
                return false;
        },
    },
    created() {
        this.center = { lat: this.origin.address.location[0], lng: this.origin.address.location[1] };
        this.markers = [
            { lat: this.origin.address.location[0], lng: this.origin.address.location[1] },
            { lat: this.destination.address.location[0], lng: this.destination.address.location[1] }
        ]
        console.log(this.admin)
        this.path = this.route.map((item) => {
            return {
                lat: item[0],
                lng: item[1]
            }
        });
    },
    methods: {
        ...mapMutations('marketplace', ['setStep']),
        ...mapActions('marketplace', ['storeShipment']),
        submit() {
            let request = _.cloneDeep(this.shipment_request);
            if (this.admin) {
                request.customer_code = this.shipment.customer.code;
            }
            else {
                request.customer_code = this.profile.account.profile.tms_id ? this.profile.account.profile.tms_id : null;
            }

            if (!request.accessories.some(e => e.code === 'O_INSURANCE')) {
                delete request.consignment_value;
            }

            this.fetching = true;
            this.storeShipment(request)
                .then(data => {
                    this.setStep(8);
                })
                .catch(error => {
                    this.$root.$bvToast.toast(this.$t("Hubo un error al procesar su solicitud"), {
                        title: this.$t('Warning'),
                        variant: 'danger',
                        solid: true
                    });
                })
                .finally(() => {
                    this.fetching = false;
                });
        }
    }
}
</script>

<style scoped>
.mb-100 {
    margin-bottom: 100px;
}

.fixed-bottom {
    height: 100px;
    background: #FFF !important;
    border: 1px solid #DDDDDD;
}

.disabled {
    pointer-events: none !important;
}
</style>
