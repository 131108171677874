<template>
  <div>
    <page-header :title="$t($route.meta.title)"/>
    <loading v-if="loading" />

    <validation-observer v-if="form" ref="observer" v-slot="{ validate }">
      <div class="alert alert-info" role="alert">
        {{$t('Add all the required evidences to confirm delivery.')}}
      </div>
      <b-form @submit.stop.prevent="onSubmit(validate)">
        <dynamic-form v-model="form"  >
          <div class="form-fields">
            <div class="field-wrapper">
              <div class="row align-items-center ">
                <div class="col-md-6">
                  <div class="field-label">{{$t('Type')}}</div>
                  <div class="field-help">{{$t('The form type')}}</div>
                </div>
                <div class="col-md-6">
                  <validation-provider name="name" rules="required" v-slot="{ errors }">
                    <multiselect v-model="form.type" :options="options" :custom-label="nameWithLang"></multiselect>
                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>

        </dynamic-form>
        <div class="d-flex justify-content-between pb-5">
          <div>
            <router-link :disabled="loading"  v-b-tooltip.hover :title="$t('Cancel')" :to="{name: 'shippers.externalRequirements'}" class="btn btn-light float-right">
              {{$t('Cancel')}}
            </router-link>
          </div>
          <div>
            <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t('Save')}}</button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>

</template>

<script>

import {mapActions} from "vuex";
import DynamicForm from "../../../components/form/DynamicForm";
import formMixin from "../../../mixins/formMixin";
import Multiselect from 'vue-multiselect';

export default {
  name: "externalRequirementsForm",
  components:{
    DynamicForm,Multiselect
  },
  mixins: [formMixin],
  data(){
    return {
      loading:false,
      profile_id:null,
      form_id:null,
      form: {},
      options: ['truck', 'driver'],

    }
  },
  created() {
    this.profile_id = this.$route.params.shipper_id;
    this.form_id = this.$route.params.form_id;
    console.log(this.form_id);
    this.init();
  },
  methods: {
    ...mapActions('forms', ['storeForm', 'updateForm', 'getForm', 'getFields', 'storeField']),
    ...mapActions('accounts', ['updateAccount']),
    ...mapActions('profile', ['getProfile']),
    nameWithLang(item){
      return this.capitalize(this.$t(item))
    },
    capitalize(word) {
      return word
          .toLowerCase()
          .replace(/\w/, firstLetter => firstLetter.toUpperCase());
    },
    init() {
      this.loading = true;
      if (this.form_id) {
        this.getForm({id: this.form_id})
            .then(data => {
              this.form = _.cloneDeep(data);

            })
            .catch(error => {

            })
            .finally(() => {
              this.loading = false;
            });
      } else {
        this.form = {
          owner_type: 'shipper',
          owner_id: this.profile_id,
          fields: [
            {
              key: '',
              type: 'text',
              title: null,
              help: '',
              description: null,
              options: [],
              visible: true,
              required: false,
            }
          ]
        }
      }
      this.loading = false;
    },
    async onSubmit(validate) {
      if (!(await validate()) || this.loading) {
        return;
      }

      this.loading = true;
      let form = _.cloneDeep(this.form);
      console.log(form)
      if (form.id) {
        this.updateForm(form)
            .then(data => {
              this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                title: this.$t('Success'),
                variant: 'success',
                solid: true
              });
              this.$router.back();
            })
            .catch(error => {
              this.processServiceError(error);
            })
            .finally(() => {
              this.loading = false;
            });
      } else {
        this.storeForm(form)
            .then(data => {
              this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                title: this.$t('Success'),
                variant: 'success',
                solid: true
              });
              this.$router.push({name: 'shippers.externalRequirements'});
            })
            .catch(error => {
              this.processServiceError(error);
            })
            .finally(() => {
              this.loading = false;
            });
      }
    }

  }

}
</script>

<style scoped>

</style>
