<template>
    <div class="row">
        <div class="col-md-12 my-3">
            <b-alert v-if="settlement" variant="success" show dismissible>
                {{$t('The settlement has been sended.')}}
            </b-alert>
        </div>
        <div class="col-md-12 mb-3">
                <load-costs-table :filters="filters" :paginate="true" v-on:page-change="pageChange"></load-costs-table>
        </div>

        <div v-if="!loading && load.driver" class="col-md-12">
            <button v-if="['delivered','pod','paid'].indexOf(load.status) === -1" :disabled="settlement" type="button" @click="confirm" class="btn btn-primary mr-2">{{$t('Make pending transfer')}}</button>
        </div>
        <create-edit-expense-modal ref="createEditExpenseModal" :hiddenDate="true" v-model="form" :visible="visible" v-on:close="visible = false" v-on:submit="onSubmit"  />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import filterMixin from "@/mixins/filterMixin";
import LoadCostsTable from "@/components/tables/LoadCostsTable";
import Swal from "sweetalert2";
import CreateEditExpenseModal from "@/components/modals/CreateEditExpenseModal";

export default {
    name: "Costs",
    components: { LoadCostsTable, CreateEditExpenseModal},
    mixins: [filterMixin],
    computed:{
        ...mapGetters('catalogs', ['expensesTypes']),
        ...mapState('loads', ['expenses','load']),
        ...mapState('settlements', ['settlement']),
        ...mapState('telemetries', ['telemetry']),
        hasTelemetry() {
            if (this.telemetry && this.telemetry.assigned_distance && this.telemetry.real_distance && this.telemetry.performance)
                return true;
            else 
                return false;
        },
        menu() {
            return this.expensesTypes
        },
    },
    data(){
        return {
            collapsed: false,
            loading: true,
            visible: false,
            fetching:false,
            form: {},
        }
    },
    created(){
        this.init()
    },
    methods:{
        ...mapActions('loads',['getExpenses','deleteExpense','getExpenseByID','updateExpense','storeExpense','getFiles','deleteImageExpense','acceptedExpense',
        'rejectedExpense','getSettlementCatalogSelectData','makePendingTransfers', 'getBonus']),
        ...mapActions('telemetries', ['getTelemetry']),
        ...mapActions('settlements', ['sendSettlement','getSettlementByLoad']),
        init(){
            let filters = {};

            if (this.$route.meta.paramFilters) {
                for(let key of this.$route.meta.paramFilters){
                    filters[key] = this.$route.params[key];
                }
            }

            if(this.$route.meta.filters) {
                filters = {...filters, ...this.$route.meta.filters};
            }

            this.queryFilters = filters;

            const getSettlementByLoad = this.getSettlementByLoad({id: this.$route.params.load_id})
            const getTelemetry = this.getTelemetry({id: this.$route.params.load_id})

            Promise.all([getSettlementByLoad,getTelemetry])
              .finally(() => {
                this.loading = false;
              });
        },
        changeType(item) {
            this.queryFilters.type = item.value
        },
        confirm(){
            Swal.fire({
                title: this.$t('Confirm'),
                text: this.$t("Are you sure to transfer?"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("Yes, continue"),
                cancelButtonText: this.$t("Cancel"),
                reverseButtons: true
            }).then(result => {
                if(result.value) {
                    this.makePendingTransfers({load_id: this.$route.params.load_id})
                        .then(data =>{
                            this.$root.$bvToast.toast(this.$t("The action was completed successfully"), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            this.getExpenses({...this.filters, load_id: this.$route.params.load_id});
                        })
                        .catch(error =>{
                            Swal.fire(
                                "Error",
                                this.$t("Something went wrong!"),
                                "error"
                            );
                        })
                        .finally(()=>{
                            this.accepting = false;
                        });
                }
            });
        },
        confirmGetBonus(){
            Swal.fire({
                title: this.$t('Confirm'),
                text: this.$t("Are you sure to get bonus of the load?"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("Yes, continue"),
                cancelButtonText: this.$t("Cancel"),
                reverseButtons: true
            }).then(result => {
                if(result.value) {
                    this.getBonus({load_id: this.$route.params.load_id})
                        .then(data =>{
                            this.$root.$bvToast.toast(this.$t("The action was completed successfully"), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            this.getExpenses({...this.filters, load_id: this.$route.params.load_id});
                        })
                        .catch(error =>{
                            Swal.fire(
                                "Error",
                                this.$t("Something went wrong!"),
                                "error"
                            );
                        })
                        .finally(()=>{
                            this.accepting = false;
                        });
                }
            });
        },
        onSubmit(){
            let extra_data = {load_id:this.$route.params.load_id, owner_id: this.$route.params.load_id, owner_type:'App\\Load', status:'accepted', final_amount: this.form.amount}
            let form = {...this.form,...extra_data};

            this.storeExpense({load_id: form.load_id, form})
                .then(()=>{
                    this.visible = false;
                    this.$root.$bvToast.toast(this.$t('Created successfully'), {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true
                    });
                    this.getExpenses({...this.filters,load_id: this.$route.params.load_id, status:'accepted'})
                })
                .catch(error=>{
                    Swal.fire(
                        "Error",
                        this.$t("Something went wrong!"),
                        "error"
                    );
                })
                .finally(()=>{
                });
        },
        confirmSendSettlement(){
            Swal.fire({
                title: this.$t("Are you sure to send settlement?"),
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, send it!"),
                cancelButtonText: this.$t("Cancel"),
                reverseButtons: true
            }).then(result => {
                if(result.value) {
                    if (this.expenses.total === 0) {
                        Swal.fire(
                                "Error",
                                this.$t("There are no concepts to liquidate, please create some!"),
                                "error"
                            );
                        return;
                    }

                    if (!this.hasTelemetry) {
                            Swal.fire(
                                "Error",
                                this.$t("There are no telemetry data, please create some!"),
                                "error"
                            );
                        return;
                    }
                    this.loading = true;
                    this.sendSettlement({load_id: this.$route.params.load_id})
                        .then(data=>{

                            Echo.private(`jobs.${data.job_id}`)
                                .listen('JobProcessed', (e) => {
                                    this.getSettlementByLoad({id: this.$route.params.load_id})
                                    Echo.leave(`jobs.${data.job_id}`);
                                });

                            this.$root.$bvToast.toast(this.$t("The settlement has been sended."), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                                });
                        })
                        .catch(error =>{
                            Swal.fire(
                                "Error",
                                this.$t("Something went wrong!"),
                                "error"
                            );
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
    },
}
</script>

<style scoped>


</style>
