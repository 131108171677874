<template>
<div>
  <div class="row">
    <div class="col-md-12">
        <filter-bar :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved">
            <template v-slot:right v-if="['shipper','admin'].indexOf($route.params.profile) > -1 && $route.name.indexOf('history') === -1">
                <div class="btn-group" role="group" aria-label="toolbar">
                    <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getItems(filters)" type="button" class="btn btn-light">
                        <i class="far fa-sync"></i>
                    </button>
                    <button v-b-tooltip.hover :title="$t('Export')" type="button" class="btn btn-light" @click="download">
                        <i class="far fa-file-spreadsheet"></i>
                    </button>
                </div>
                <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
            </template>
        </filter-bar>
    </div>
  </div>
  <load-table v-if="['admin','carrier'].includes(this.$route.params.profile)" v-model="selected" :filters="filters" v-on:page-change="pageChange" />
  <shipments-table v-else v-model="selected" :filters="filters" v-on:page-change="pageChange" />
</div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import LoadTable from "@/components/tables/LoadTable";
import ShipmentsTable from "@/components/tables/ShipmentsTable";
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";

export default {
  name: "ViewDetail",
  components:{
    LoadTable,
    FilterBar,
    ShipmentsTable
  },
  mixins:[filterMixin],
  data(){
    return {
      selected : []
    }
  },
  created() {
    this.init();
  },
  watch:{
    '$route': 'init'
  },
  computed: {
    options(){
        return [
            {
                icon: 'far fa-key',
                label: 'ID',
                type: 'text',
                filterProperty: 'id',
            },
             {
                icon: 'far fa-key',
                label: 'TMS ID',
                type: 'text',
                filterProperty: 'tms_id',
             },
             {
                icon: 'far fa-user',
                label: 'Carrier ID',
                type: 'text',
                filterProperty: 'carrier',
             },
             {
                icon: 'far fa-user',
                label: 'Shipper ID',
                type: 'text',
                filterProperty: 'shipper',
             },
            {
                icon: 'far fa-sliders-h-square',
                label: this.$t('Configuration'),
                type: 'single',
                filterProperty: 'configuration_id',
                values: [
                    {
                        text: 'Sencillo',
                        value: '1'
                    },
                    {
                        text: 'Full',
                        value: '2'
                    }
                ],
                valueType: 'string'
            },
            {
                icon: 'far fa-truck-moving',
                label: this.$t('Truck type'),
                type: 'multiselect',
                filterProperty: 'truck_type_id',
                values: this.truckTypes ? this.truckTypes.map(item => ({text: item.name, value: item.id})) : [],
                valueType: 'number'
            },
            {
                icon: 'far fa-calendar-alt',
                label: this.$t('Published since'),
                type: 'date',
                filterProperty: 'created_at'
            },
            {
                icon: 'far fa-calendar-alt',
                label: this.$t('Pickup date'),
                type: 'date',
                filterProperty: 'pickup_date',
            }
        ];
      }
  },
  methods:{
    ...mapActions('loads',['exportLoads','getLoads']),
    ...mapActions('shipments', ['getShipments']),
    init(){
      let filters = {};
      // Use meta filters
      if(this.$route.meta.paramFilters){
        for(let key of this.$route.meta.paramFilters){
          filters[key] = this.$route.params[key];
        }
      }
      if(this.$route.meta.filters){
        filters = {...filters, ...this.$route.meta.filters};
      }
      this.defaultFilters = filters;

    },
    download(){
      this.exportLoads(this.filters)
          .then(()=>{

          })
          .catch(error =>{

          });
    },
    getItems(filters){
      if(['admin,carrier'].includes(this.$route.params.profile)) {
        this.getLoads(filters)
      } else {
        this.getShipments(filters)
      }
    }
  }
}
</script>

<style scoped>

</style>
