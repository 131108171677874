<template>
    <div>
        <page-header :title="$t('Security')" :items="breadcrumb"/>

        <validation-observer ref="observer" v-slot="{ validate }">
            <b-form @submit.stop.prevent="onSubmit(validate)">
                <div class="card">
                    <div class="card-header">
                        <h6>{{$t(('Password'))}}</h6>
                    </div>
                    <div class="form-fields">
                        <form-input
                                :title="$t('Old password')"
                                v-model="form.old_password"
                                rules="required"
                                type="password"
                                :name="'old_password'"
                        />
                        <form-input
                                :title="$t('New password')"
                                v-model="form.new_password"
                                rules="required"
                                type="password"
                                :name="'new_password'"
                        />
                        <form-input
                                :title="$t('Password confirmation')"
                                :help="$t('Verify your new password')"
                                v-model="form.new_password_confirmation"
                                rules="required|confirmed:new_password"
                                type="password"
                                :name="'new_password_confirmation'"
                        />
                        <div class="field-wrapper d-flex justify-content-end">
                            <button type="submit" class="btn btn-primary">{{$t('Save')}}</button>
                        </div>
                    </div>
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import {updatePassword} from '@/utils/auth.js'

    export default {
        name: "Security",
        mixins: [formMixin],
        data(){
            return {
                loading: false,
                form: {
                    new_password : null,
                    new_password_confirmation: null
                }
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Settings"),
                        to: {name:'settings'}
                    },
                    {
                        text: this.$t('Security'),
                        to: {name:'settings.security'},
                        active: true
                    }
                ]
            },
        },
        created () {
        
                
        },
        methods: {
            resetForm() {
                this.form = {
                    new_password : null,
                    new_password_confirmation: null,
                    old_password: null
                };
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    return;
                }
                this.loading = true;
                let form = _.cloneDeep(this.form);

                updatePassword(form.old_password,form.new_password)
                    .then(()=>{
                        this.resetForm();
                        this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                    
                    })
                    .catch(error=>{
                        this.processServiceError(error);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });
                
            }
        },
    }
</script>

<style scoped>

</style>
