import axios from "../../api/client";
import jwt_decode from "jwt-decode";
import {getEmailVerified, getUser} from "../../utils/auth";

// initial state
export const state = () => ({
    fetching: false,
    visible: false,
});

// getters
export const getters = {
    email_verified: async state => {
        return await getEmailVerified();

    }
};

// actions
export const actions = {
    sendEmailVerification({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/email-verification/send_email', {params: query})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    showEmailVerification({commit}, query){
        commit('setVisible', true);
    }
};

// mutations
export const mutations = {
    setFetching(state, data){
        state.fetching = data;
    },
    setVisible(state, data){
        state.visible = data;
    },
};
