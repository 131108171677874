import axios from "@/api/client";
import moment from 'moment';

// initial state
export const state = () => ({
    fetching: false,
    branchoffices : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    branchoffice: null,
    last_filters: {},
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchBranchoffices({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/sellers/${query.seller_id}/branch-offices`, {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getBranchoffices({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/sellers/${query.seller_id}/branch-offices`,{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setBranchoffices', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    storeBranchoffice({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/sellers/${payload.seller_id}/branch-offices`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getBranchoffice({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/sellers/${params.seller_id}/branch-offices/${params.id}`)
                .then(response=>{
                    commit('setBranchoffice', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateBranchoffice({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/sellers/${payload.seller_id}/branch-offices/${payload.id}`, payload)
                .then(response=>{
                    let branchoffices = _.cloneDeep(state.branchoffices);
                    let index = branchoffices.results.findIndex(item => item.id === response.data.id);
                    if(index > -1){
                        branchoffices.results[index] = response.data;
                        commit('setBranchoffices', branchoffices);
                    }
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error)
                    reject(error.response)
                });
        });
    },
    deleteBranchoffice({commit}, query){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/sellers/${query.seller_id}/branch-offices/${query.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteManyBranchoffices({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/sellers/${params.seller_id}/branch-offices/bulk`,{params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    }
};

// mutations
export const mutations = {
    setBranchoffices(state, data){
        state.branchoffices = data;
    },
    setBranchoffice(state, data){
        state.branchoffice = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    updateBranchoffice(state, data){
        let item = state.branchoffices.results.find(item => item.id === data.id);
        if(item){
            item = data;
        }
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

