<template>
    <div class="row mt-5 mb-5">
        <div class="col-12 d-flex justify-content-center">
            <div class="card rectangle">
                <div class="card-body text-center font-size-24 font-weight-bold">
                    <div class="text-primary mt-3 d-flex justify-content-center align-items-center font-size-28">
                        Gracias por tu respuesta
                    </div>
                    <div class="line-height mt-4">
                        <p>Lamentamos no llegar a un acuerdo, en la</p>
                        <p>página de Traxporta podrás crear una</p>
                        <p>nueva cotización cuando necesites.</p>
                    </div>
                    <div class="px-md-5 mt-5">
                        <a
                            class="btn btn-block btn-primary_v2 text-uppercase font-size-12 font-weight-bolder py-2"
                            href="https://traxporta.com/"
                            role="button"
                        >
                            {{ $t("Go to Traxporta") }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DeclinedCard"
}
</script>

<style lang="scss" scoped>
.line-height { line-height: 20px; }
.rectangle {
    box-sizing: border-box;
    height: 340px;
    width: 620px;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    background-color: #FFFFFF;
}
</style>
