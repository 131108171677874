<template>
  <div>

    <loading-overlay :loading="fetchingCosts">

      <div  class="card" v-for="item in expenses.results" :key="'cost'+item.id">
        <div class="card-body p-0 "  >
          <div class="row align-items-center p-3 px-5 cost_container">
            <div class="col-md-1 col-6 cost_id"><span>ID: </span>{{item.id}}</div>
            <div class="col-md-1 col-6 cost_type"><span>{{$t("Type")}}: </span>{{ $t(item.type) }}</div>
            <div class="col-md-2 col-12 cost_name"><span>{{$t("Name")}} </span>{{item.name}}</div>
            <div class="col-md-2 col-12 cost_description "><span>{{$t("Description")}} </span>{{item.description}}</div>
            <div class="col-md-2 col-6 cost_amount"><span>{{$t("Amount")}} </span>$ {{ amount(item) | money }} {{ item.currency }}</div>
            <div class="col-md-1 col-6 cost_date"><span>{{$t("Date")}} </span>{{item.created_at | timestamp('YY-MM-DD HH:mm')}}</div>
            <div class="col-md-1 col-12 text-center cost_status"><span>{{$t("Status")}} </span> <badge-status :status="item.status" /> </div>
            <div class="col-md-2 col-12 text-right d-flex align-items-center justify-content-center cost_actions ">
              <button v-if="item.status === 'pending' && (['admin','shipper'].indexOf(role) > -1) " @click="cancelledExpenseAction(item)" class="btn btn-sm btn-outline-dark mr-4">{{$t('Reject')}}</button>
              <button v-if="item.status === 'pending' && (['admin','shipper'].indexOf(role) > -1) "  @click="acceptedExpenseAction(item)"  class="btn btn-sm btn-primary mr-4">{{$t('Accept')}}</button>

              <a @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-4" v-if="['carrier'].indexOf(role) > -1 && item.status !== 'completed'">
                <i class="far fa-trash-alt btn-icon"  />
              </a>
              <div class="show" >
                <a v-if="voucherId===item.id" href="javascript:void(0);" @click="hiddenRow()">
                  <i class="far fa-chevron-circle-up btn-icon"></i>
                </a>
                <a v-else href="javascript:void(0);">
                  <i class="far fa-chevron-circle-down btn-icon" @click="showRow(item)"></i>
                </a>
              </div>
            </div>
            <div class="col-12 cost_show">
              <div class="show" >
                <a v-if="voucherId===item.id" href="javascript:void(0);" @click="hiddenRow()">
                  {{$t("Hide")}}
                </a>
                <a v-else href="javascript:void(0);" @click="showRow(item)">
                  {{$t("Show more")}}
                </a>
              </div>
            </div>

          </div>
          <template v-if="voucherId===item.id">
            <div class="col-12" style="border-top: 1px solid #dddddd; "></div>
            <template v-if="['pending','accepted','in_review','completed','rejected','cancelled'].indexOf(item.status) > -1"  >
              <div class="d-flex align-items-center justify-content-between px-4 pt-3 pb-0" v-if="['in_review','completed','rejected'].indexOf(item.status) > -1" >
                <div>
                  <h5 class="font-weight-bold card-title" v-if="['carrier'].indexOf(role) > -1">{{$t('Upload your vouchers')}}</h5>
                  <h5 class="font-weight-bold card-title" v-if="['admin','shipper'].indexOf(role) > -1">{{$t('Uploaded vouchers')}}</h5>
                </div>
                <div style="float:right" class="cursor-pointer" @click="hiddenRow()">
                  <span>{{$t('Close')}}</span>
                  <i class="far fa-times fa-lg pl-2"></i>
                </div>
              </div>

              <div class="row py-2"  v-if="['pending','accepted','in_review','completed','rejected','cancelled'].indexOf(item.status) > -1"  >
                <div class="col-md-6">
                  <div class="gallery_container px-4">
                    <gallery-info-component :deleted="item.status !== 'completed'" v-on:update_data="updateData" :images="item.files"  v-if="item.files.length" />
                  </div>
                  <div v-if="['cancelled'].indexOf(item.status) === -1">
                    <empty-results v-if="item.files.length=== 0 && (['admin'].indexOf(role) > -1)" :title="$t('No vouchers uploaded')" :subtitle="$t('Cannot be do action, because not has updated vouchers')"    />
                  </div>
                  <div v-if="['carrier'].indexOf(role) > -1  && ['accepted','in_review','rejected'].indexOf(item.status) > -1" >
                    <file-input
                        class=" px-4 pb-4"
                        :path="uploadPath"
                        :options="{dictDefaultMessage: 'Add images', acceptedFiles: 'image/*'}"
                        v-on:success="successUpload" v-on:error="errorUpload"
                    />
                  </div>
                </div>
                <div :class="item.status === 'cancelled' ? 'col-md-12':'col-md-6'">
                  <div class="px-4 py-3" v-if="item.comments.length" >
                    <h6>{{ $t('Comments') }}:</h6>
                    <scrollbar :maxHeight="'200px'" :isMobile="isMobile" @reachBottom="log('Reach bottom')" @reachTop="log('Reach top')"
                               @startDrag="log('Drag start')" :scrollTo="scrollData"
                               @endDrag="log('Drag end')">
                      <CommentsReadOnly :comments="item.comments" />
                    </scrollbar>
                  </div>
                </div>
              </div>

              <div class="" v-if="(['admin'].indexOf(role) > -1) && ['accepted','in_review','completed','rejected'].indexOf(item.status) > -1"  >
                <div class="d-flex align-items-center justify-content-between px-4 py-3 mt-4 row">
                  <div class="col-12 col-sm-12 col-md-5">
                    <div class="amounts">
                      <div class="amount">
                        <h4 class="card-title">{{$t('Request Amount')}}</h4>
                        <h3 class="card-title">$ {{item.amount | money}} {{item.currency}} </h3>
                      </div>
                      <div class="amount_final">
                        <h4 class="card-title">{{$t('Amount Approved')}}</h4>
                        <h3 class="card-title">$ {{item.final_amount | money}} {{item.currency}} </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-5 py-3 px-5 text-right actions_btn" v-if="item.status==='in_review' && item.files.length">
                    <button  @click="rejectExpenseAction(item)" class="btn btn-sm btn-v2 btn-outline-dark mr-4">{{ $t('Reject') }}</button>
                    <button  @click="completedExpenseAction(item)"  class="btn btn-sm btn-v2 btn-primary mr-4">{{ $t('Accept') }}</button>
                  </div>
                </div>
                <div v-if="item.status !== 'pending' && item.accepted_by" class="col-12 col-sm-12 col-md-5 py-3 px-5 text-right">
                  <h4 class="card-title">{{$t('Accepted by')}}: {{item.accepted_by ? item.accepted_by.name : '--'}}</h4>
                  <h4 class="card-title">{{$t('Date')}}: {{item.accepted_date | timestamp('YY-MM-DD HH:mm') }}</h4>
                </div>
              </div>


              <div class="" v-if="(['carrier'].indexOf(role) > -1)  && ['accepted','in_review','rejected'].indexOf(item.status) > -1 ">
                <div class="d-flex align-items-center justify-content-between px-4 py-3">
                  <div class="col-3">
                    <form-input
                        :title="$t('Amount Approved')"
                        v-model="expense.final_amount"
                        rules="required"
                        type="text"
                        name="final_amount"
                        layout="vertical"
                        :disabled="true"
                        :border="false"
                    />
                  </div>
                  <div class="col-6">
                    <form-input
                        :title="$t('Comments')"
                        v-model="expense.comment"
                        rules=""
                        type="textarea"
                        name="comments"
                        layout="vertical"
                        :rows="1"
                        :border="false"
                    />
                  </div>
                </div>
                <div class="col-12 py-3 px-5 text-right">
                  <button  @click="SendReviewExpenseAction(item)"  class="btn btn-sm btn-v2 btn-primary mr-4">{{$t('Send')}}</button>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <empty-results v-if="!expenses.results.length"></empty-results>
    </loading-overlay>

    <alert-modal :visible="notification_visible" :success="success_notification"  :title="title_notification" :message="message_notification" :sub_message="sub_message_notification" :reload_on_close="reload_on_close_notification" v-on:close="closeNotification" />
    <extra-cost-actions :action="action_expense" :expense="expense"  :visible="actions_visible"  v-on:updateData="updateData"   v-on:close="actions_visible=false" />
  </div>
</template>

<script>
import FileInput from "@/components/form/FileInput";
import {mapActions, mapState} from "vuex";
import GalleryInfoComponent from "@/components/GalleryInfoComponent";
import Swal from "sweetalert2";
import ExtraCostActions from "@/components/modals/ExtraCostActions";
import CommentsReadOnly from "@/components/common/CommentsReadOnly";
import LoadingOverlay from "@/components/common/LoadingOverlay";
import AlertModal from "@/components/modals/AlertModal";
export default {
  'name':'ExtracostTable',
  components: {
    AlertModal,
    LoadingOverlay,
    CommentsReadOnly,
    ExtraCostActions,
    GalleryInfoComponent,
    FileInput
  },
  props: {
    value: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {
      voucherId:false,
      scrollData:100,
      actions_visible:false,
      isMobile: /Android|webOS|iPhone|iPod|iPad|BlackBerry|Windows Phone/i.test(navigator.userAgent),
      notification_visible:false,
      fetchingComment:false,
      load_id: this.$route.params.load_id,
      expense:{},
      action_expense:"",
      title_notification:"",
      message_notification:"",
      sub_message_notification:"",
      btn_notification:"",
      success_notification:false,
      reload_on_close_notification:false,
      form: {},
      defaultErrorNotification: {
        title:"Error",
        message: this.$t('Something went wrong when action processed'),
        sub_message: this.$t('Something went wrong try again, if problem persists contact us'),
        success: false,
        text_btn: "CERRAR"
      },
    }
  },
  created() {
    this.form = _.cloneDeep(this.value);
  },
  computed:{
    ...mapState('extracost',['expenses','fetchingCosts']),
    ...mapState('profile',['profile']),
    uploadPath(){
      return '/api/v2/loads/'+this.load_id+'/expenses/'+this.expense.id+'/files';
    },
    role(){
      return this.profile.roles[0];
    }
  },
  methods: {
    ...mapActions('extracost',['deleteExpense','updateExpense']),
    showRow(item) {
      this.expense= _.cloneDeep(item)
      this.voucherId = item.id
    },
    hiddenRow() {
      this.form={}
      this.voucherId = null
    },
    updateData(data) {
      this.actions_visible = false;
      this.$emit('update_data');
    },
    confirmDelete(item) {
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.deleteExpense({load_id: this.load_id, id: item.id})
              .then(data=>{
                this.$emit('update_data');
                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Expense").toLowerCase()}), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    },
    acceptedExpenseAction(item) {
      this.expense = item;
      this.action_expense = "accept"
      this.actions_visible = true;
    },
    cancelledExpenseAction(item) {
      this.expense = item;
      this.action_expense = "cancelled"
      this.actions_visible = true;
    },
    rejectExpenseAction(item) {
      this.expense = _.cloneDeep(item);
      if (this.expense.files.length){
        this.action_expense = "reject"
        this.actions_visible = true;
      }else{
        this.$root.$bvToast.toast(this.$t("No vouchers uploaded"), {
          title: this.$t('Error'),
          variant: 'danger',
          solid: true
        });
      }
    },
    editExpenseAction(data) {
      this.$emit('update_data');
    },
    SendReviewExpenseAction(item) {
      let form = _.cloneDeep(this.expense);
      if (form.files.length){
        let  request = {
          status: "in_review",
          comments: form.comment,
          id:form.id
        }
        this.updateItem(request)
      }else{
        this.$root.$bvToast.toast(this.$t("No vouchers uploaded"), {
          title: this.$t('Error'),
          variant: 'danger',
          solid: true
        });
      }
    },
    completedExpenseAction(item) {
      let form = _.cloneDeep(this.expense);
      if (form.files.length){
        let  request = {
          status: "completed",
          id:form.id
        };
        let notification = {
          title: this.$t('Approved extra cost'),
          message: this.$t('Extra cost approved successfully'),
          sub_message: this.$t('Carrier be notificated'),
          success: true,
          text_btn: "CERRA"
        }
        let notificationError =_.cloneDeep(this.defaultErrorNotification);
        this.updateItem(request,notification,notificationError);
      }else{
        alert("Faltan archivos");
      }
    },
    updateItem(form,notificationSuccess=false,notificationError=false){
      this.updateExpense({load_id: this.load_id, id: form.id,form:form})
          .then(data=>{
            this.updateData();
            if (notificationSuccess){
              this.setNotification(notificationSuccess);
            }
          })
          .catch(error =>{
            if (notificationError){
              this.setNotification(notificationError);
            }
          }).finally(()=>{
      });
    },
    setNotification(notification){
      this.title_notification= notification.title;
      this.message_notification= notification.message;
      this.sub_message_notification= notification.sub_message;
      this.success_notification= notification.success;
      this.btn_notification= notification.text_btn;
      this.reload_on_close_notification= notification.update_on_close;
      this.notification_visible=true;
    },
    errorUpload(data) {
      console.log(data)
    },
    successUpload(data) {
      let notification = {
        title: this.$t('Uploaded successfully'),
        message: this.$t('Vouchers uploaded successfully'),
        sub_message: this.$t('Your receipts were sent for review, we will confirm the status shortly'),
        success: true,
        text_btn: "CERRA",
        update_on_close : true
      };
      this.setNotification(notification);
      this.notification_visible = true;
    },
    amount(data) {
      if (data.status === 'pending' || data.status === 'cancelled'){
        return data.amount
      } else{
        return data.final_amount
      }
    },
    closeNotification(data) {
      this.notification_visible = false;
      if (data.reload){
        this.updateData();
      }
      console.log("SE CERRO")
    },
    handleScroll(d){
      console.log(d)
    },
    log: function (e) {
      console.log(e || 1)
    }
  },
  watch:{
    expenses: {
      deep: true,
      handler(value){
        let data = _.cloneDeep(value);
        let expense = data.results.filter((item => item.id === this.voucherId));
        if (expense.length){
          this.expense = expense[0];
          this.expense.comment = null;
        }
      }
    }
  }
}
</script>
<style lang="scss">
.btn-v2{
  min-width: 8rem;
  font-size: .8rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  font-weight: bold;
}
.amounts{
  display: flex;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.amount{
  flex: 1;
  text-align: center;
  padding: 15px;
  height: 80px;
}
.amount_final{
  flex: 1;
  text-align: center;
  padding: 15px 15px 15px 25px;
  background: #00B3E6;
  height: 80px;
  position: relative;
}
.amount_final h3,.amount_final h4{
  color: white;
}
.amounts h3{
  font-weight: 900;
}
.amount_final::before {
  content:"\A";
  height: 100%;
  transform: rotateY(180deg);
  border-style: solid;
  border-width: 40px 20px 40px 0;
  border-color: transparent white transparent transparent;
  position: absolute;
  left: -0px;
  top: 0;
}
.main-board{
  height: calc(150px);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  padding: 15px;
}
.scrollbar.scrollbar-y{
  background:  rgb(0 ,0,0,.4)!important;
  opacity: 1!important;
}
.scrollbar-wrap{
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
  position: relative;
}
.scrollbar-wrap:before{
  content: "";
  position: absolute;
  right: 2px;
  top: 4px;
  width: 10px;
  height: calc(100% - 8px);
  background: #ebebeb!important;
  border-radius: 5px;
}
@media (min-width: 992px) {
  .cost_show{
    display: none!important;
  }
  .cost_actions .show{
    display: flex;
  }
  .hidden_sm{
    display: block;
  }
  .cost_id,.cost_type,.cost_name,.cost_date,.cost_amount,.cost_status,.cost_description{
    span:first-child{
      display: none;
    }
  }
}
@media (max-width: 992px) {
  .actions_btn{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0!important;
  }
  .cost_actions .show{
    display: none!important;
  }
  .cost_container{
    padding: 0 15px 15px 15px !important;
  }
  .cost_id,.cost_type{
    background: #f2f2f2;
    line-height: 35px !important;
    font-weight: 800;
    font-size: 1rem;
    height: 75px;
    display: flex;
    align-items: center;
    span{
      font-weight: 200;
      color: #7c8a96;
      margin-right: 5px;
    }
  }
  .cost_type{
    text-align: right;
    justify-content: end;
  }
  .cost_id{
    text-align: left;
    color: #00B3E6;
  }
  .cost_description{
    text-align: center;
    padding-bottom: 10px;
    padding-top: 8px;
    border-bottom: 2px solid #dddddd;
    font-size: 1.1rem;
    font-weight: 800;
    span{
      color: #32383d;
      font-size: .8em;
      display: block;
    }
  }
  .cost_name{
    text-align: center;
    padding-bottom: 10px;
    padding-top: 8px;
    border-bottom: 2px solid #dddddd;
    font-size: 1.3rem;
    font-weight: 800;
    span{
      color: #32383d;
      font-size: .8em;
      display: block;
    }
  }
  .cost_amount,.cost_status,.cost_date{
    text-align: center;
    padding-bottom: 10px;
    padding-top: 8px;
    border-bottom: 2px solid #dddddd;
    font-size: 1em;
    font-weight: 800;
    span{
      display: block;
      font-size: .9em;
      font-weight: 300;
    }
  }
  .cost_actions{
    font-size: 1.4rem;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #dddddd;
  }
  .cost_show{
    a{
      font-size: 1.3em;
      font-weight: 800;
      padding-top: 8px;
      text-align: center;
      display: block;
    }
  }
  .hidden_sm{
    display: none;
  }
}
</style>