<template>
    <div class="main-content bgWhite">
        <div class="page-content py-0" style="height: 100%;width: 100%;">
            <control-tower-filter-side-bar v-if="this.$route.params.profile === 'admin'" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved"  />
            <div class="row main-board">

                <div class="col-md-12 px-0">
                    <GmapMap
                        class="main-board"
                        style="bottom: 0; top:0;width:100%;"
                        ref="mapRef"
                        :center="center"
                        :zoom="6"
                        :options="mapOptions"
                    >
                                <template v-if="shipment">
                                    <GmapMarker :position="{lat: shipment.origin.address.latitude, lng: shipment.origin.address.longitude}"
                                                :icon="pickupIcon"
                                    />
                                    <GmapMarker :position="{lat: shipment.destination.address.latitude, lng: shipment.destination.address.longitude}"
                                                :icon="deliveryIcon"
                                    />
                                    <GmapInfoWindow v-if="shipment.position && shipment.position.temperature" :options="infoWindowOptions"
                                      :position="shipment.position ? {lat: shipment.position.latitude, lng: shipment.position.longitude} : {}"
                                    >
                                      <i class="far fa-thermometer-three-quarters text-primary" style="font-size: 17px;margin-right: 8px;" />
                                      <span class="text-primary" style="font-size: 16px;font-weight: bold;">{{shipment.position.temperature}} °</span>
                                      <span class="text-primary"  style="font-size: 16px;font-weight: bold;">{{shipment.position.temperature_unit}}</span>
                                    </GmapInfoWindow>
                                    <!--
                                    <load-detail-window :load="load" v-on:show="showSideBar = true" v-on:close="onWindowClose" />
                                    -->
                                </template>

                                <template v-for="(item,idx) in shipmentResults">
                                    <truck-marker v-if="item.position" :visible="load === null" :key="'load-marker-'+item.id+'-'+idx" 
                                             v-on:mouseover="markerover=item.id" v-on:mouseout="markerover=null"
                                            :position="item.position" v-on:click="setShipment(item)" :animation="(shipment && shipment.id == item.id) ? 1 : 0" />
                                    <GmapInfoWindow :key="'temp-marker-'+item.id+'-'+idx"  v-if="item.position && item.position.temperature && item.id == markerover" :options="infoWindowOptions"
                                      :position="item.position ? {lat: item.position.latitude, lng: item.position.longitude} : {}"
                                    >
                                      <i class="far fa-thermometer-three-quarters text-primary" style="font-size: 17px;margin-right: 8px;" />
                                      <span class="text-primary" style="font-size: 16px;font-weight: bold;">{{item.position.temperature}} °</span>
                                      <span class="text-primary"  style="font-size: 16px;font-weight: bold;">{{item.position.temperature_unit}}</span>
                                    </GmapInfoWindow>
                                </template>
                                <template slot="visible">
                                    <div class="loads-container">
                                         <vue-perfect-scrollbar ref="loadList" class="scroll main-board" :settings="{ suppressScrollX: true, wheelPropagation: false }"> 
                                          <div class="m-3">
                                              <b-form-tags class="shipment-search" input-id="tags-basic" v-model="searchTags" :placeholder="$t('Search')"></b-form-tags>
                                          </div>
                                          <loading v-if="loading && shipments.length == 0" />
                                          <div v-for="item in shipmentResults" :key="`sh-${item.id}`" class="shipment-card m-3 shadow cursor-pointer" @click="setShipment(item)">
                                            <div style="height: 50px;border-bottom: 1px solid #dddddd;" class="d-flex  justify-content-between sh-card-header">
                                              <div class="d-flex justify-content-between bg-white" style="border-top-left-radius: 20px;">
                                                <div class=" d-flex align-items-center justify-content-center" style="width: 50px;">
                                                  <div class="rounded-circle " style="width:30px;height:30px;background:#dddddd"></div>
                                                </div>
                                                <div class=" d-flex align-items-center justify-content-center">
                                                  <div style="font-size: 12px;font-weight: bold;color: #434619;line-height: 14px;letter-spacing: 0;font-family: Helvetica;">
                                                    {{item.shipper.name}}
                                                  </div>
                                                </div>
                                              </div>
                                              <div v-if="item.position ? item.position.temperature : false" class="bg-primary text-center" style="width: 90px;border-top-right-radius: 20px;">
                                                <div class="temperature">{{$t('Temperature')}}</div>
                                                <div style="margin-top: 2px;">
                                                  <i class="far fa-thermometer-three-quarters text-white" style="font-size: 17px;margin-right: 8px;" />
                                                  <span class="text-white"  style="font-size: 16px;font-weight: bold;color:white!important;">{{item.position.temperature}} °</span>
                                                  <span style="font-size: 16px;font-weight: bold;color:#E3ED55!important">{{item.position.temperature_unit}}</span>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="bg-white" style="border-bottom: 1px solid #dddddd;">
                                              <table style="width: 100%;">
                                                <tr>
                                                  <td class="px-2 py-1" style="width:60%;">
                                                    <badge-status class="mr-2" :status="item.status" :statusName="$t(item.status)" />
                                                    <span style="color: #888888;font-size:12px;">ID</span>: 
                                                    <span style="color: #00B3E6;font-size: 12px;font-weight: bold;font-weight: bold;font-family: Helvetica;">{{item.shipment_number}}</span>
                                                  </td>
                                                  <td class="pr-2 py-1 text-right " style="width:40%;">
                                                    <span style="color: #888888;font-size:12px;">REF</span>: 
                                                    <span style="color: #00B3E6;font-size: 12px;font-weight: bold;font-weight: bold;font-family: Helvetica;">
                                                      {{item.shipment_tracking_number}}
                                                    </span>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                            <div class="px-2 bg-white py-1" style="border-bottom: 1px solid #dddddd;">
                                              <table style="width: 100%;">
                                                <tr>
                                                  <td>
                                                    <span style="color: #888888;font-size:12px;">{{$t('Route')}}:</span>
                                                  </td>
                                                  <td>
                                                    <span style="color: #00B3E6;font-size: 16px;font-weight: bold;font-weight: bold;font-family: Helvetica;">
                                                      {{item.origin.address.city}}, {{item.origin.address.state}} - 
                                                    </span>
                                                    <span style="color: #00B3E6;font-size: 16px;font-weight: bold;font-weight: bold;font-family: Helvetica;">
                                                      {{item.destination.address.city}}, {{item.destination.address.state}}
                                                    </span>
                                                  </td>
                                                </tr>
                                              </table>
                                              <div class="d-flex justify-content-between my-2">
                                                <div class="d-flex align-items-center" style="height: 30px;width: 160px;border-radius: 15px;background-color: #F2F2F2;">
                                                  <i class="fas fa-boxes mx-2" />
                                                  <span style="color: #888888;font-size:10px;">{{$t('Pickup')}}:</span>
                                                  <span class="ml-2" style="font-size:10px;letter-spacing: 0;">{{item.origin.date}}</span>
                                                </div>
                                                <div class="d-flex align-items-center" style="height: 30px;width: 144px;border-radius: 15px;background-color: #F2F2F2;">
                                                  <i class="fas fa-shipping-fast mx-2" />
                                                  <span style="color: #888888;font-size:10px;">{{$t('Delivery')}}:</span>
                                                  <span class="ml-2" style="font-size:10px;letter-spacing: 0;">{{item.destination.date}}</span>
                                                </div>
                                              </div>
                                            </div>
                                            <div>
                                              <div class="text-center" >
                                                <span class="mr-2" style="color: #888888;font-size:12px;">{{$t('Equipment')}}:</span>
                                                <span style="font-size:12px;">
                                                  {{item.equipment_type ? item.equipment_type.name : '- -'}}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                         </vue-perfect-scrollbar>

                                    </div>
                                </template>
                    </GmapMap>

                </div>
            </div>
            <b-sidebar v-model="showSideBar" id="sidebar-right" aria-controls="sidebar-right" bg-variant="white"  title="Sidebar" right no-header shadow="lg" width="400px" sidebar-class="load-sidebar">
                <div class="mx-3 mt-3">
                  <div class="d-flex justify-content-end" >
                      <a @click="onSidebarClose" href="javascript:void(0);" class="text-muted">
                          <b class="text-body">{{$t('Close')}}</b>
                      </a>
                  </div>
                </div>
                <div v-if="shipment">
                    <div style="height: 50px;border-bottom: 1px solid #dddddd;" class="d-flex  justify-content-between">
                      <div class="d-flex justify-content-between bg-white" style="border-top-left-radius: 20px;">
                        <div class=" d-flex align-items-center justify-content-center" style="width: 50px;">
                          <div class="rounded-circle " style="width:30px;height:30px;background:#dddddd"></div>
                        </div>
                        <div class=" d-flex align-items-center justify-content-center">
                          <div style="font-size: 12px;font-weight: bold;color: #434619;line-height: 14px;letter-spacing: 0;font-family: Helvetica;">
                            {{shipment.shipper.name}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bg-white px-2" style="border-bottom: 1px solid #dddddd;">
                      <table style="width: 100%;">
                        <tr>
                          <td class="px-2 py-2" style="width:60%;">
                            <badge-status class="mr-3" :status="shipment.status" :statusName="$t(shipment.status)" />
                            <span style="color: #888888;font-size:12px;">ID</span>: 
                            <span style="color: #00B3E6;font-size: 12px;font-weight: bold;font-weight: bold;font-family: Helvetica;">{{shipment.shipment_number}}</span>
                          </td>
                          <td class="pr-2 py-2 text-right " style="width:40%;">
                            <span style="color: #888888;font-size:12px;">REF</span>: 
                            <span style="color: #00B3E6;font-size: 12px;font-weight: bold;font-weight: bold;font-family: Helvetica;">
                              {{shipment.shipment_tracking_number}}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="px-3 py-2 bg-white" style="border-bottom: 1px solid #dddddd;">
                      <table style="width: 100%;">
                        <tr>
                          <td>
                            <span style="color: #00B3E6;font-size: 16px;font-weight: bold;font-weight: bold;font-family: Helvetica;">
                              {{shipment.origin.address.city}}, {{shipment.origin.address.state}} - 
                            </span>
                            <span style="color: #00B3E6;font-size: 16px;font-weight: bold;font-weight: bold;font-family: Helvetica;">
                              {{shipment.destination.address.city}}, {{shipment.destination.address.state}}
                            </span>
                          </td>
                        </tr>
                      </table>
                      <div class="d-flex justify-content-between my-2">
                        <div class="d-flex align-items-center" style="height: 30px;width: 160px;border-radius: 15px;background-color: #F2F2F2;">
                          <i class="fas fa-boxes mx-2" />
                          <span style="color: #888888;font-size:10px;">{{$t('Pickup')}}:</span>
                          <span class="ml-2" style="font-size:10px;letter-spacing: 0;">{{shipment.origin.date}}</span>
                        </div>
                        <div class="d-flex align-items-center" style="height: 30px;width: 144px;border-radius: 15px;background-color: #F2F2F2;">
                          <i class="fas fa-shipping-fast mx-2" />
                          <span style="color: #888888;font-size:10px;">{{$t('Delivery')}}:</span>
                          <span class="ml-2" style="font-size:10px;letter-spacing: 0;">{{shipment.destination.date}}</span>
                        </div>
                      </div>
                    </div>

                    <div style="background-color: #F2F2F2;height:28px;border-bottom: 1px solid #dddddd;">
                      <div class="text-center" >
                        <span class="mr-2" style="color: #888888;font-size:12px;">{{$t('Equipment')}}:</span>
                        <span style="font-size:12px;">
                          {{shipment.equipment_type ? shipment.equipment_type.name : '- -'}}
                        </span>
                      </div>
                    </div>

                    <template v-if="shipment">
                      <b-tabs content-class="mt-3" nav-class="nav-tabs-custom nav-tabs-load-detail">
                        <b-tab :title="$t('Events')" class="px-3" active>                        
                         
                          <shipment-timeline :shipmentId="shipment.id" />
                        </b-tab>
                        <b-tab v-if="canBeShowChart" :title="$t('Temperature')" class="p-3">
                            <div class="bg-primary d-flex align-items-center justify-content-between" style="border-radius: 20px;height:40px;padding: 10px 20px;">
                              <div class="text-white" style="font-size: 14px;">{{$t('Temperature')}}</div>
                              <div>
                                <i class="far fa-thermometer-three-quarters text-white" style="font-size: 24px;margin-right: 8px;" />
                                <span class="text-white"  style="font-size: 24px;font-weight: bold;color:white!important;">{{shipment.position.temperature}} °</span>
                                <span style="font-size: 24px;font-weight: bold;color:#E3ED55!important">{{shipment.position.temperature_unit}}</span>
                              </div>
                            </div>
                            <div class="ml-2" style="margin-top:5px;">
                                <span class="mr-2" style="color: #888888;font-size:12px;">{{$t('Last update')}}:</span>
                                <span style="font-size:12px;font-weight: bold;">
                                  {{shipment.position.created_at | timestamp}}
                                </span>
                            </div>
                            <div id="wrapper">
                              <div id="chart-line2">
                                <apexchart type="area" height="230" :options="chartOptions" :series="series"></apexchart>
                              </div>
                            </div>
                        </b-tab>
                      </b-tabs>
                      
                    </template>
                    
                </div>
            </b-sidebar>
        </div>


    </div>
</template>

<script>
import mapStyle from "@/constants/mapStyle";
import {mapState,mapGetters, mapActions, mapMutations} from 'vuex';
import BadgeStatus from '@/components/common/BadgeStatus.vue';
import simplebar from "simplebar-vue";
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import LoadCard from '@/components/common/LoadCard';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import drawCurve from '@/helpers/drawCurve';
import TruckMarker from '@/components/common/TruckMarker'
import LoadTimeline from '@/components/common/controltower/LoadTimeline'
import RouteLog from "@/components/common/RouteLog";
import LoadEvents from "@/components/common/controltower/LoadEvents";
import ControlTowerFilterSideBar from "@/components/filters/ControlTowerFilterSideBar";
import PositionModal from '@/components/modals/PositionModal';
import customFilterMixin from "@/mixins/customFilterMixin";

import ShipmentTimeline from '@/components/common/controltower/ShipmentTimeline.vue'
import db from 'full-text-search-light'


import moment from "moment-timezone";

window.Apex = {
  tooltip: {
    x: {
      format: "dd/MM/yyyy hh:mm:ss",
    },
    z: {
      formatter: undefined,
      title: 'Unidad: '
    },
  },
}
const chartOptions = {
          chart: {
            id: 'chart2',
            type: 'line',
            height: 230,
            toolbar: {
              autoSelected: 'pan',
              show: false
            }
          },
          colors: ['#008FFB'],
          fill: {
            type: 'solid',
            colors: ['transparent'],
          },
          stroke: {
            width: 2
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: false
            }
          },
} 
export default {
    components: {
      ControlTowerFilterSideBar,
      ShipmentTimeline,
      RouteLog,
        BadgeStatus, simplebar, GmapCluster, LoadCard, 
        VuePerfectScrollbar, TruckMarker, LoadTimeline,LoadEvents,
        PositionModal
        },
    name: "CustomerControlTower",
    mixins: [customFilterMixin],
    metaInfo(){
        return {
        title: this.$t('Control tower'),
        }
    },
    data(){
        return {
            searchTags: [],
            shipments: [],
            shipment: null,
            database: null,
            chartOptions: chartOptions,
            shipmentResults: [],
            infoWindowOptions: {
                pixelOffset: {width: -50,height: 35},
            },
            markerover: null,

            loadingRoute: false,
            showInformation: false,
            collapsed: false,
            showMap: true,
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                styles: mapStyle
            },
            load: null,
            items: [],
            loading: false,
            truckIcon: {
                scaledSize: {width: 48, height: 48, f: 'px', b: 'px'},
                url: require('../../assets/images/txp/common/truck.png'),
                
            },
            pickupIcon: {
                scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
                url: require('../../assets/images/txp/origen.png'),
                anchor:{x:20,y:20}
            },
            deliveryIcon: {
                scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
                url: require('../../assets/images/txp/destino.png'),
                 anchor:{x:20,y:20}
            },
            map: null,
            center: {lat:22.527438, lng:-101.506378},
            geo : null,
            lastFilters: {},
            selected: [],
            defaultFilters: {},
            page:1,
            infoWinOpen: false,
            polylineCompleted: {
                strokeColor: "#999999",
                strokeOpacity: 0.6,
                strokeWeight: 4,
            },
            polylinePending: {
                strokeColor: "#00b3e6",
                strokeWeight: 4,
            },
            showSideBar: false,
            showPositionModal: false,
            options: [

            ],
            etaInterval: null,
            fetchingLoadDetail: false
        }
    },
    computed: {
        ...mapGetters('profile',["timezone"]),
        ...mapState('profile',['profile']),
        ...mapGetters('catalogs',['truckTypes']),
        ...mapState('controltower',['stats','loads','routing','search','historyTemperature','timeZoneSelected','db']),
        ...mapState('loads',['stops','events']),
        requestFilters(){
          return {...this.filters, ...this.defaultFilters}
        },
        completed(){
            if(!this.load)
                return [];
            if(this.load.status === 'started' || !this.load.position){
                return [];
            }
            return drawCurve({lat: this.load.pickup.address.latitude, lng: this.load.pickup.address.longitude},
                {lat: this.load.position.latitude, lng: this.load.position.longitude});
        },
        pending(){
            if(!this.load)
                return [];
            if(this.load.status === 'started' || !this.load.position){
                return drawCurve({lat: this.load.pickup.address.latitude, lng: this.load.pickup.address.longitude},
                    {lat: this.load.delivery.address.latitude, lng: this.load.delivery.address.longitude});
            }
            return drawCurve({lat: this.load.position.latitude, lng: this.load.position.longitude},
                {lat: this.load.delivery.address.latitude, lng: this.load.delivery.address.longitude});
        },
      canBeShowChart(){
        return  (this.shipment && this.shipment.position ? this.shipment.position.temperature:false) && this.historyTemperature.length;
      },
      historyTemperatureTimeZone(){
        let tz = this.timezone ? this.timezone : "UTC";
        return this.historyTemperature.map(e => {
          return [ moment.utc(e[0]).tz(tz).format("YYYY-MM-DD HH:mm:ss") , e[1], e[2]]
        });
      },
      series(){
        return [
          {
            name: "Temperatura",
            data: this.historyTemperatureTimeZone,
          }
        ];
      },
      status(){
        let status = [
                {status: 'late', value: this.stats.late, icon: 'fas fa-exclamation-circle'},
                {status: 'delayed', value: this.stats.delayed, icon: 'fas fa-hourglass'},
                {status: 'early', value: this.stats.early, icon: 'fas fa-clock'},
                {status: 'on_time', value: this.stats.on_time, icon: 'fas fa-check-circle'},
                //{status: 'unknown', value: this.stats.unknown, icon: 'fas fa-check-circle'},
          ]

          if (this.$route.params.profile !== 'admin') {
            status.pop()
          }
          return status;
        },
        currentEtaStatus(){
            return this.filters.eta_status ? this.filters.eta_status.split(',') : [];
        },
        pendingPath(){
            return this.routing ? this.routing.pending_path.map(item =>({lat: item[0], lng: item[1]})) : [];
        },
        traveledPath(){
            return this.routing ? this.routing.traveled_path.map(item =>({lat: item.latitude, lng: item.longitude})) : [];
        },
        searchIds(){
            return this.search.map(item =>item.id);
        }
    },
    watch : {
      searchTags:{
        deep: true,
        handler(newValue){
          let results = [];
          if(newValue.length > 0){
            for(let text of newValue){
              results = results.concat(this.database.search(text))
            }
          }else{
            results = this.shipments
          }
          this.shipmentResults = _.uniqBy(results, 'id')
        }
      }
    },
    created () {
        this.doSearch = _.debounce(this.fetchData,500);
        this.init();
        this.interval = setInterval(this.fetchData, 30000);
        this.fetchData();
    },
    mounted() {
        this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = map;
        });
    },
    beforeRouteLeave(to, from, next) {
        clearInterval(this.interval);
        next();
    },
    methods: {
        ...mapActions('loads',['getLoad','getStops','getEvents']),
        ...mapActions('controltower',['getShipments','getShipmentTemperatures','getRouting','getShipmentTemperatures']),
        ...mapMutations('controltower',['setRouting','setSearch']),
        ...mapActions("profile",["setControlTowerFilter"]),
        init(){
          this.toolbarFilters = this.$route.query;
        },
        fetchData(){
            this.loading = true;
            this.database = new db('shipments')
            this.getShipments({})
                .then(data=>{
                    data.shipments = data.shipments.map(item =>({
                      ...item,
                      position: item.position ? {
                        ...item.position,
                        temperature: item.position.temperature ? parseInt(item.position.temperature):null
                      }:null
                    }))
                    for(let obj of data.shipments){
                        this.database.add(obj);
                    }
                    if(this.searchTags.length > 0){
                      let results = [];
                      if(this.searchTags.length > 0){
                        for(let text of this.searchTags){
                          results = results.concat(this.database.search(text))
                        }
                      }else{
                        results = this.shipments
                      }
                      this.shipmentResults = _.uniqBy(results, 'id')
                    }else{
                      this.shipmentResults = data.shipments;
                    }
                    
                    this.shipments = _.cloneDeep(data.shipments)
                })
                .catch(error => {
                })
                .finally(()=>{
                    this.loading = false;
                });
        },
        setShipment(item){
          this.chartOptions = {
            ...chartOptions,
            yaxis: (item.temperature) ? {
              min: parseInt(item.temperature.min_temperature) - 3,
              max: parseInt(item.temperature.max_temperature) + 3
            }: {},
            annotations: {
              yaxis: item.temperature ? [{
                  y: parseInt(item.temperature.min_temperature),
                  borderColor: '#FF0000',
                  label: {
                    show: true,
                    text: 'Min',
                    style: {
                      color: "#fff",
                      background: '#FF0000'
                    }
                  }
                },{
                  y: parseInt(item.temperature.max_temperature),
                  borderColor: '#FF0000',
                  label: {
                    show: true,
                    text: 'Max',
                    style: {
                      color: "#fff",
                      background: '#FF0000'
                    }
                  }
                }] : [],
            }
          }

          this.shipment = item;
          this.fitMap();
          this.showSideBar = true;
          this.getShipmentTemperatures({shipment_id: item.id})
            .then(data=>{

            })
            .catch(error =>{

            });
        },
        fitMap(){
            if(this.map){
                let bounds = new google.maps.LatLngBounds();
                bounds.extend({lat: this.shipment.origin.address.latitude, lng: this.shipment.origin.address.longitude});
                bounds.extend({lat: this.shipment.destination.address.latitude, lng: this.shipment.destination.address.longitude});
                if(this.shipment.position)
                    bounds.extend({lat: this.shipment.position.latitude, lng: this.shipment.position.longitude});                
                this.map.fitBounds(bounds);
            }
        },
        clearLoad(){
            this.load = null;
            this.setRouting(null);
        },
        onSidebarClose(){
          this.shipment = null;
          this.showSideBar = false;
          this.clearLoad();
        },
        onWindowClose(){
            this.clearLoad();
        },
    },
}
</script>

<style scope>
.gm-style-iw{
  border-top-right-radius: 0!important;
}
.gm-style-iw-tc{
  display:none!important;
}
.gm-style .gm-style-iw-c {
  border-radius: 18px;
}

    .main-board{
        height: calc(100%);
    }
.shipment-search{
  border:none;
  border-radius: 20px;
  box-shadow: 0 2px 10px 0 rgba(204,204,204,0.5);
}
.shipment-card {
  border-radius: 20px;
  /* background-color: #FFFFFF; */
  background-color: #F9F9F9;
  box-shadow: 0 2px 10px 0 rgba(204,204,204,0.5);
  height: 190px;
  width: 340px;
}
.b-form-tags.focus{
  box-shadow: 0 2px 10px 0 rgba(204,204,204,0.5)!important;
}

.temperature {
  height: 14px;
  color: #FFFFFF;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: 8px;
}
.sh-card-header{
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

    .loads-container{
        width: 375px;
        position: absolute;
        top: 0;
        height: calc(100%);
        background-color: rgba(255, 255, 255, 0.8);
    }
    .eta-status{
        width: 100%;
        border-radius: 20px;
    }
    .eta-bg{
        background-color: rgba(255, 255, 255, 0.8);
    }
    .eta_item.active *{
      color: white!important;
    }
    .eta_item{
      background-color:white;
      transition: all .5 s;
    }

    .eta-delayed{
      border-left: 8px solid #fcb92c;
    }
    .eta-delayed.active{
      background-color:#fcb92c;
      transition: all .5s;
    }
    .eta-late{
      border-left: 8px solid #FF0000;
    }
    .eta-late.active{
      background-color: #FF0000;
      transition: all .5s;
    }
    .eta-early{
      border-left: 8px solid #F70997;
    }
    .eta-early.active{
      background-color:#F70997;
      transition: all .5s;
    }
    .eta-on_time{
      border-left: 8px solid #1cbb8c;
    }
    .eta-on_time.active{
      background-color: #1cbb8c;
      transition: all .5s;
    }
    .eta-unknown{
      border-left: 8px solid #dddddd;
    }
    .eta-unknown.active{
      background-color: #dddddd;
      transition: all .5s;
    }

    .eta-icon-muted{
      color: #cccccc;
    }


    .load-sidebar{
        top: 75px!important;
    }
    .nav-tabs-load-detail{
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .nav-tabs-custom .nav-item {
        padding-bottom: 5px;
        padding-top: 5px;
    }
    .load-sidebar > .b-sidebar-body{
        margin-bottom: 70px;
    }
    .bg-gray{
        background-color: #f9f9f9;
    }
    .item-text{
        font-size: 11px;
    }
    .bg-white{
      background: white!important;
    }
</style>
