<template>
    <div class="row">
        <div class="col-md-3">
            <b>ID:</b> {{ resource.id }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Type')}}:</b> {{ resource.type ? resource.type.name : '--' }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Type')}}:</b> {{ resource.type ? resource.type.name : '--' }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Plate')}}:</b>  {{ resource.plate ?  resource.plate : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Location')}}:</b>  {{ resource.location ?  resource.location : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Truck')}}:</b>  {{ resource.truck ?  resource.truck.id + ':' +resource.truck.model : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Length')}}:</b>  {{ resource.length ?  resource.length.name : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Status')}}:</b>  {{ resource.enabled ?  $t('enabled') : $t('disabled')  }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Documents')}}:</b> {{ $t(resource.document_status) }}
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex';

export default {
    name: "DriverInformation",
    computed: {
        ...mapState('requirements', ['resource']),
    }
}
</script>
