<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h6>{{title}}</h6>
        </div>
        <div class="form-fields">

            <template v-for="(address,index) in addresses">
                <div :key="'address-header-'+ (address.id ? address.id : address.key)"  class="field-wrapper" :class="[(!addresses[index].collapse || !collapsed) ? 'border-bottom-none':'']">
                    <div class="row align-items-center ">
                        <div class="col-md-8">
                            <div class="field-label"><b>{{$t('Address')}} {{index+1}}</b></div>
                            <div class="field-help">{{ address | address-format }}</div>
                        </div>
                        <div class="col-md-4 d-flex justify-content-end">
                            <a href="javascript:void(0);" class="text-danger mr-2" @click="addresses.splice(index,1)" v-b-tooltip.hover :title="$t('Delete')" >
                                <i class="far fa-trash-alt btn-icon"  />
                            </a>
                            <a href="javascript:void(0);"  @click="setCollapse(index)" >
                                <i class="far  btn-icon" :class="[showLeg(index) ? 'fa-chevron-circle-up': 'fa-chevron-circle-down']" />
                            </a>
                        </div>
                    </div>
                </div>
                <address-components :id="id+'.'+index+'.address.'"
                                    v-show="showLeg(index)"
                                    :key="'address-'+ (address.id ? address.id : address.key)"
                                    v-model="addresses[index]" />
            </template>
            <div class="field-wrapper">
                <div class="row align-items-center ">
                    <div class="col-md-8"></div>
                    <div class="col-md-4 ">
                        <button type="button" :disabled="!add" class="btn btn-primary btn-sm float-right" @click="addAddress">{{$t('Add')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AddressComponents from "./AddressComponents";
    import Switches from 'vue-switches';
    import cryptoRandomString from 'crypto-random-string';

    export default {
        name: "AddressCollectionForm",
        components:{
            AddressComponents,
            Switches
        },
        props: {
            defaultCountry:{
                type: String,
                default(){
                    return 'MX'
                }
            },
            id:{
                type: String,
                default(){
                    return '';
                }
            },
            value:{
                required: true
            },
            collapsed: {
                type: Boolean,
                default(){
                    return false;
                }
            },
            title:{
                type: String,
                default(){
                    return this.$t('Addresses')
                }
            }
        },
        data(){
            return{
                addresses: [],
                add : false,
                testCol: false
            }
        },
        watch:{
            add(value){
                if(value === false){
                    this.addresses = [];
                }else{
                    if(this.addresses.length === 0){
                        this.addAddress();
                    }
                }
            },
            addresses: {
                deep: true,
                handler(newValue){
                    this.$emit('input', newValue)
                }
            },
            value(newValue){
                this.addresses = newValue;
                if(newValue.length > 0){
                    this.add = true;
                }
            }
        },
        created(){
            this.addresses = this.value.map(item => ({...item, collapse: this.collapsed}));
        },
        methods:{
            addAddress(){
                let addresses = this.addresses.map(item => ({...item, collapse: true}));
                addresses.push({
                    key: cryptoRandomString({length:5}),
                    collapse: false,

                    country: this.defaultCountry
                });
                this.addresses = addresses;
            },
            setCollapse(index){
                let address = {...this.addresses[index], collapse: this.addresses[index].collapse !== undefined ? !this.addresses[index].collapse : false};
                this.$set(this.addresses, index, address);
            },
            showLeg(index){
                if([true, false].indexOf(this.addresses[index].collapse) > -1){
                    return !this.addresses[index].collapse;
                }
                return !this.collapsed;
            }
        }
    }
</script>

<style scoped>

</style>
