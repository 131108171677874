<template>
  <div>
    <div class="" v-if="fetching">
      <loading />
    </div>
    <div class="table-responsive mt-2" v-else>
      <div class="card load_item" v-for="item in list.results" :key="'load-' + item.id">
        <div class="card_header">
          <div class="info_left">
            <div class="info_left_first">
              <p class="text-muted text-capitalize ">
                {{ $moment.utc(item.created_at).tz(profile.preferences.timezone).fromNow() }}</p>
              <badge-status :status="item.status" />
            </div>
            <div class="info_left_second">
              <p class="text-muted mb-2 textwrap">
                {{ item.tms_shipment_id ? 'TMS ID: ' : 'ID: ' }}<span class="font-weight-bold pr-2">{{
                  item.tms_shipment_id
                  ?? item.id }}</span>
                <span v-if="$route.params.profile == 'admin'" data-bs-toggle="tooltip" data-bs-placement="top"
                  :title="item.shipper.name">{{ $t('Shipper') }}: <span class="font-weight-bold">{{ item.shipper.name
                  }}</span></span>
              </p>
              <router-link :to="{ name: 'shipments.detail.general', params: { load_id: item.id } }">
                <h5 class="text-primary font_bold font-size-15 text-uppercase ">{{ item.pickup.address.city }},
                  {{ item.pickup.address.state }}, {{ item.pickup.address.country }} - {{ item.delivery.address.city }},
                  {{ item.delivery.address.state }}, {{ item.delivery.address.country }}</h5>
              </router-link>
              <h6 v-can="'shipments.showprice'" v-if="$route.params.profile !== 'driver'" class="font_bold">${{ item.price
                |
                money }} {{ item.currency }}</h6>
            </div>
          </div>
          <div class="info_right">
            <div class="info_right_first">
              <div class="info_item">
                <div class="info_item_icon"><i class="far fa-dolly-flatbed-alt" style="font-weight: 800;"></i></div>
                <div class="info_item_label">{{ $t('Pickup') }}</div>
                <div class="info_item_sub_label text-capitalize font_semi_bold">{{ item.pickup.date | human_date_min }}
                </div>
              </div>
              <div class="info_item">
                <div class="info_item_icon"><i class="far fa-shipping-fast" style="font-weight: 800;"></i></div>
                <div class="info_item_label">{{ $t('Delivery') }}</div>
                <div class="info_item_sub_label text-capitalize font_semi_bold">{{ item.delivery.date | human_date_min }}
                </div>
              </div>
              <div class="info_item">
                <div class="info_item_icon"><i class="fa fa-barcode" style="font-weight: 800"></i></div>
                <div class="info_item_label">{{ $t('Tracking Number') }}</div>
                <div class="info_item_sub_label font_semi_bold">{{ item.shipper_external_id ? item.shipper_external_id :
                  '--' }}
                </div>
              </div>
            </div>
            <div class="info_right_second">
              <button class="btn btn-sm btn-outline-dark mr-4 padding-btn-small text-uppercase" @click="showRow(item)">{{
                $t('Show Preview') }} <i class="fa fa-eye ml-1"></i></button>
              <router-link :to="{ name: 'shipments.detail.general', params: { load_id: item.id } }">
                <button class="btn btn-sm btn-primary_v2 padding-btn-small text-uppercase "> {{ $t('Show Detail') }} <i
                    class="fa fa-search ml-1"></i></button>
              </router-link>
            </div>
          </div>
        </div>
        <template v-if="load && load.id === item.id">
          <div class="card_footer">
            <route-map style="width:100%;height:300px;" :load="load"></route-map>
            <div class="info_detail">
              <div class="info_detail_data pt-4">
                <div class="card card-primary bg_gray detail_banner">
                  <div class="card_list">
                    <div class="card_list_item" v-if="['admin', 'staff'].indexOf($route.params.profile) > -1">
                      <div class="card_title font-weight-bold ">TMS ID</div>
                      <div class="">{{ item.tms_shipment_id ? item.tms_shipment_id : '- -' }}</div>
                    </div>
                    <div class="card_list_item">
                      <div class="card_title font-weight-bold  ">{{ $t('Tracking Number') }}:</div>
                      <div class=" ">{{ item.shipper_external_id ? item.shipper_external_id : '- -' }}</div>
                    </div>
                    <div class="card_list_item" v-if="$route.params.profile === 'admin'">
                      <div class="card_title font-weight-bold  ">{{ $t('Shipper') }}:</div>
                      <router-link v-if="item.shipper"
                        :to="{ name: 'shippers.detail', params: { shipper_id: item.shipper.id } }" class="two-lines mr-2"
                        target="_blank">
                        <div class="text-black ">{{ item.shipper.name }}</div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="field-wrapper border-bottom-none p-0">
                        <div class="field-label">{{ $t('Load type') }}</div>
                        <div class="field-help">{{ load.load_type.name }}</div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="field-wrapper border-bottom-none p-0">
                        <div class="field-label">{{ $t('Configuration') }}</div>
                        <div class="field-help">{{ load.configuration.name }} </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="field-wrapper border-bottom-none p-0">
                        <div class="field-label">{{ $t('Truck type') }}</div>
                        <div class="field-help"><i class="far fa-shipping-fast mr-2" />{{ load.truck_type ?
                          load.truck_type.name : '- -' }}</div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="field-wrapper border-bottom-none p-0">
                        <div class="field-label">{{ $t('Weight') }}</div>
                        <div class="field-help"><i class="far fa-weight mr-2" />{{ load.weight }} {{ load.weight_unit }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="field-wrapper border-bottom-none p-0">
                        <div class="field-label">{{ $t('Container type') }}</div>
                        <div class="field-help"><i class="far fa-container-storage mr-2" /> {{ load.container_type }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="field-wrapper border-bottom-none p-0" v-if="load.commodity">
                        <div class="field-label">{{ $t('Commodity') }}</div>
                        <div class="field-help">{{ load.commodity.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="info_detail_log ">
                <load-timeline class="" :load="load" :stops="stops" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="list.total > 0" class="row align-items-center">
      <div class="col-md-6 col-sm-6 ">
        <b-pagination v-model="page" :total-rows="list.total" :per-page="list.per_page" aria-controls="my-table"
          first-number last-number pills></b-pagination>
      </div>
      <div class="col-md-6 col-sm-6 text-sm-right">
        <p>{{ $t('pagination', { from: list.from, to: list.to, total: list.total }) }}</p>
      </div>
    </div>
    <div v-if="list.total === 0 && !fetching" class="row">
      <div class="col">
        <empty-results />
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from "vuex";

import selectAllMixin from "@/mixins/selectAllMixin";
import addressFormat from '@/helpers/address-format';
import RouteMap from "@/components/common/RouteMap";
import Loading from "../../components/common/Loading";
import LoadTimeline from "../../components/common/controltower/LoadTimeline";

export default {
  name: "ShipmentsTable",
  mixins: [selectAllMixin],
  components: {
    LoadTimeline,
    'loading': Loading,
    'route-map': RouteMap
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      }
    },
    tender: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      page: 1,
      init: false,
      selected: [],
      load: null,
      fetchingLoad: false,
      markers: []
    }
  },
  computed: {
    ...mapState('shipments', ['last_filters', 'shipments', 'fetching', 'stops']),
    ...mapState('profile', ['profile']),
    list() {
      return this.shipments
    }
  },
  created() {
    this.doSearch = _.debounce(this.fetchData, 50);
    this.page = this.filters.page ? Number(this.filters.page) : 1;
  },
  watch: {
    filters: {
      deep: true,
      handler(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal) || this.init === false) {
          this.fetchData();
        }
      }
    },
    page(newVal) {
      this.$emit('page-change', newVal);
    }
  },
  methods: {
    ...mapActions('shipments', ['getShipments', 'getShipment', 'getLoadsByTenders']),
    fetchData() {
      this.page = _.cloneDeep(this.filters.page);
      let filters = _.cloneDeep({ ...this.filters, ...this.defaultFilters });
      delete filters.tab;
      if (this.tender) {

        this.getLoadsByTenders(filters)
          .then(response => {
          })
          .catch(error => {
          })
          .finally(() => {
            this.init = true;
          })

      } else {

        this.getShipments(filters)
          .then(response => {
          })
          .catch(error => {
          })
          .finally(() => {
            this.init = true;
          })
      }
    },
    showRow(item) {
      if (this.load && this.load.id === item.id) {
        this.load = null;
        return
      }
      this.fetchingLoad = true;
      this.load = item;
      this.getShipment({ id: item.id })
        .then(data => {
          this.load = data;
        })
        .catch(error => {

        })
        .finally(error => {
          this.fetchingLoad = false;
        })
    },
    getAddressFormat(address) {
      return addressFormat(address)
    }
  }
}
</script>


<style scoped lang="scss">
.detail_banner {
  background: #f6f6f6 !important;
  border: 0;
  border-left: 7px solid #e3ed56;
}

.load_item {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #dddddd69 !important;
  box-shadow: 0 6px 10px rgb(204 204 204 / 50%);
}

@media (max-width: 1320px) {

  .info_right {
    .info_right_first {
      width: 75%;
    }

    .info_right_second {
      width: 25%;
      display: inline-grid;
    }
  }

}

@media (max-width: 1120px) {
  .card_header {
    flex-wrap: wrap;

    .info_left,
    .info_right {
      width: 100% !important;
    }
  }

  .detail_banner {
    margin-bottom: 0 !important;
    background: #f6f6f6 !important;
  }

  .info_right {
    flex-wrap: wrap;

    .info_right_first,
    .info_right_second {
      width: 100% !important;
      justify-content: center;
      display: flex;
    }

    .info_right_second {
      padding: 15px 0;
    }
  }

  .info_detail {
    flex-wrap: wrap;

    .info_detail_data,
    .info_detail_log {
      width: 100% !important;
    }
  }

}

.card_header {
  display: flex;
  width: 100%;

}

.info_left {
  width: 40%;
  display: flex;
  align-content: center;
  padding: 15px 15px;
  border-right: 1px solid #dddddd69;
}

.info_left_first {
  width: 30%;
  text-align: center;
  max-width: 180px;
}

.info_left_second {
  width: 70%;
}

.info_right {
  width: 60%;
  background: #F9F9F9;
  padding: 15px 15px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.info_right_first {
  width: 60%;
  display: flex;
  justify-content: flex-start;
}

.info_item {
  padding: 5px 15px;
  text-align: center;
  flex: 1;
}

.info_item_label {
  color: #666666 !important;
}

.info_item_icon {
  font-weight: bold;
  font-size: 18px;
}

.info_item_sub_label {
  font-weight: bold;
}

.info_right_second {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.info_detail_data {
  width: 70%;
  padding: 15px;
  border-right: 1px solid #DDDDDD;
}

.info_detail_log {
  width: 30%;
}

.info_detail {
  display: flex;
}

.card_list {
  display: flex;
  justify-content: flex-start;
}

.card_list_item {
  padding: 0 25px;
  border-right: 1px solid #cfcfcf;
}

.card_list_item:last-child {
  border: 0;
}

.card-primary {
  padding: 10px;
  background: #00b3e6;
}

.t-eta-delayed {
  color: #fcb92c;
}

.t-eta-late {
  color: #FF0000;
}

.t-eta-early {
  color: #09aaf7;
}

.t-eta-on_time {
  color: #1cbb8c;
}

.t-eta-unknown {
  color: #dddddd;
}

.t-eta-null {
  color: #dddddd;
}

.textwrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  /* some width */
}
</style>
