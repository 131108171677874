import axios from "@/api/client";

// initial state
export const state = () => ({
    fetching: false,
    routes : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    route: null,
    last_filters: {},
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchRoutes({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/routes', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getRoutes({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/routes',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setRoutes', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    storeRoute({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/routes`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getRoute({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/routes/${params.id}`)
                .then(response=>{
                    commit('setRoute', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateRoute({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/routes/${payload.id}`, payload)
                .then(response=>{
                    let routes = _.cloneDeep(state.routes);
                    let index = routes.results.findIndex(item => item.id === response.data.id);
                    if(index > -1){
                        routes.results[index] = response.data;
                        commit('setRoutes', routes);
                    }
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteRoute({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/routes/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    importRoutes({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/routes/import`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setRoutes(state, data){
        state.routes = data;
    },
    setRoute(state, data){
        state.route = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

