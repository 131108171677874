export default {
    data(){
        return {
            toolbarFilters: {},
            defaultFilters: {},
            queryFilters: {},
        }
    },
    computed: {
        filters(){
            return {...this.queryFilters, ...this.toolbarFilters, ...this.defaultFilters}
        }
    },
    watch : {
        queryFilters : {
            deep: true,
            handler(newValue, oldValue){
                this.$setRouteQuery(this.$router, this.$route, newValue);
            }
        },
        toolbarFilters:{
            deep : true,
            handler(newValue, oldValue){
                this.queryFilters = {...this.queryFilters, ...newValue};
            }
        },
        '$route': 'onRouteChange'
    },
    created(){
      this.onRouteChange();
    },
    methods: {
        onRouteChange(){
            this.queryFilters = {
                page: this.$route.query.page ? Number(this.$route.query.page) : 1,
                per_page: '10',
                ...this.$route.query,
            };
        },
        onFiltersChange(filters){
            this.queryFilters = {...this.queryFilters, ...filters};
        },
        filterRemoved(key){
            let filters = this.queryFilters;
            delete filters[key];
            this.queryFilters = _.cloneDeep(filters);
        },
        pageChange(page){
            this.queryFilters = {...this.queryFilters, page: page};
        }
    }
}
