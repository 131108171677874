import axios from "../../api/client";

// initial state
export const state = () => ({
    fetching: false,
    step: 1,
    load_recommendation: null
});

// getters
export const getters = {};

// actions
export const actions = {
    storeLoad({commit}, payload){
        console.log("desde mutation", payload);
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/load-recommendations`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
                .finally(() => {
                    commit('setFetching', false);
                });
        });

    },
    updateLoadRecommendation({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/load-recommendations/${payload.id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getLoadRecommendations({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/load-recommendations',{params: query})
                .then(response=>{
                    resolve(response.data.results[0]);
                    commit('setLoadRecommendation', response.data.results[0])
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setFetching(state, data){
        state.fetching = data;
    },
    setStep(state, data){
        state.step = data;
    },
    setLoadRecommendation(state, data) {
        state.load_recommendation = data
    }
};
