<template>
    <validation-observer ref="observer" v-slot="observer">
        <form @submit.stop.prevent="onSubmit(observer)" class="row form">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 offset-md-3">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <form-input rules="required" layout="vertical" class="col-md-6" :name="`${id}'_country'`" :disabled="true" :border="false"
                                    :title="$t('Country')" v-model="form.country" type="text" />

                        <form-input rules="required" layout="vertical" class="col-md-6"  :name="`${id}'_line_1'`" :disabled="disabled" :border="false"
                                    :title="$t('Street')" v-model="form.line_1" type="text" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row align-center">
                        <form-input rules="required" layout="vertical"  class="col-md-6"  :name="`${id}'_postal_code'`" :title="$t('Zip code')" :border="false"
                                    v-model="form.postal_code" @changew="onPostalCodeChange" v-on:keypress.enterw="onPostalCodeChange"
                                    type="text" />
                        <div class="col-md-6 px-3 d-flex align-items-center ">
                          <div type="button" class="btn btn-sm btn-outline-light upper btn_zip_code  " @click="onPostalCodeChange">
                            {{ $t("Valid Zip Code") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <form-input rules="required" class="col-md-6" layout="vertical" :name="`${id}'_state'`" :disabled="true" :border="false"
                                    :title="$t('State')" v-model="form.state" type="text" />
                        <form-input rules="required" layout="vertical" class="col-md-6"  :name="`${id}'_city'`" :disabled="true" :border="false"
                                    :title="$t('City')" v-model="form.city" type="text" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <form-input rules="required" layout="vertical" class="col-md-6 select_locality"   :name="`${id}'_locality'`" :title="$t('Locality')" :border="false"
                                    v-model="form.locality" :placeholder="$t('Select option')" :options="form.localityArray" type="select"
                                    @input="onChange" />
                        <form-input layout="vertical" :name="`${id}'_description'`" class="col-md-6"  placeholder="Referencias" :border="false"
                                    :title="$t('References')" v-model="form.description" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 buttons-container">
                <buttons-steps :back="true" :submit="true" :complete="true" @eventBack="$emit('addressFormBack')" />
            </div>
        </form>
    </validation-observer>
</template>
<script>
import { mapActions } from 'vuex';
import ButtonsSteps from './ButtonsSteps.vue';
import FormInput from '../form/FormInput.vue';

export default {
    name: "AddressForm",
    components: {
        ButtonsSteps,
        FormInput
    },
    props: {
        id: {
            type: String,
            default() {
                return '';
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        required: {
            type: Boolean,
            default() {
                return true;
            }
        },
        formProp: {
            type: Object
        },
        complete: {
            type: Boolean,
            default() {
                return true;
            }
        }
    },
    data() {
        return {
            form: {},
        }
    },
    created() {
        this.form = { ..._.cloneDeep(this.formProp) }
    },
    methods: {
        ...mapActions('addresses', ['fetchPostalCodesV2']),
        /**
         * Esta funcion se ejecuta cuando se necesita buscar una direccion mediante el codigo postal y de 
         * pais en el api interna
         * 
         * @param {String} codes Codigo postal de busqueda
         */
        onPostalCodeChange() {
            let codes = _.cloneDeep(this.form.postal_code);
            this.fetchPostalCodesV2({ code: codes, country: this.form.country })
                .then(response => {
                    if (response.data.length > 0) {
                        let form = _.cloneDeep(this.form);
                        form.city = response.data[0].city;
                        form.state = response.data[0].state;
                        form.country_code = response.data[0].country_code;
                        form.localityArray = response.data.map(item => {
                            return { text: item.locality, value: item.locality }
                        });

                        form.state_code = response.data[0].state_code;
                        this.form = _.cloneDeep(form);
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        /**
         * Esta funcion se ejecuta cuando el usuario selecciona el boton siguiente del formulario
         * 
         * @param {Object} observer Objeto que valida los campos del formulatio
         */
        async onSubmit(observer) {
            if (!(await observer.validate())) {
                this.stopsCollapsed = false;
                this.$bvToast.toast(this.$t('Please, check the form'), {
                    title: "Error",
                    variant: 'danger',
                    solid: true
                });
                return;
            } else {
                this.$emit('addressFormNext', _.cloneDeep(this.form));
            }
        },
        /**
         * Esta funcion se ejecuta cuando el usuario selecciona la localidad del formulario
         * 
         * @param {String} locality Localidad de la seleccion del usuario
         */
        onChange(locality) {
            this.form.locality = locality;
        }
    }

}
</script>
<style lang="scss">
.btn_zip_code{
  margin-top: 20px;
  height: 38px;
  display: flex;
  align-items: center;
  border-color: #ced4da;
  color: #606366;

}
.gm-style-iw-tc {
    display: none !important;
}

.form {
    display: flex;
    justify-content: center;
}

.input {
    border: none;
    border-radius: none;
    border-bottom: solid 1px #00B3E6;
    background: none;
}

.info-window {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 300px;

    .header {
        display: flex;
        padding: 1em;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.5);

        small {
            height: 13px;
            width: 197px;
            color: #000000;
            font-family: Helvetica;
            font-size: 11px;
            line-height: 13px;
            text-align: center;
        }

        span:hover {
            cursor: pointer;
        }
    }

    .body {
        padding: 1em;
        color: #000000;
        font-family: Helvetica;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .buttom {
        display: flex;
        padding: 1em;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        .btn {
            margin-top: 0 !important;
        }
    }
}

.container-maps {
    display: flex;
    flex-direction: column;

    .maps {
        position: relative;
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;

        .mapa {
            height: 100%;
        }

        .search {
            margin-top: 1em;
            height: 100px;
            padding-top: 1em;
            width: 90%;
            z-index: 1;
            border-radius: 20px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 10px 0 rgba(136, 136, 136, 0.5);
            position: absolute;

            small {
                height: 14px;
                width: 253px;
                color: #000000;
                font-family: Helvetica;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 14px;
            }
        }
    }

    .button-back {
        display: block;
    }
}

.buttons-container {
    display: flex;
    justify-content: flex-end;

    .buttons {
        display: flex;

        button {
            margin-left: 5px;
        }
    }
}
</style>