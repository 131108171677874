<template>
<div >
  <page-header :title="$t($route.meta.title)" :items="breadcrumb"/>
  <p>
    {{$t($route.meta.description)}}
  </p>

  <settlement-catalog-table :paginate="false"></settlement-catalog-table>
</div>
</template>

<script>
import {mapActions, mapState} from "vuex";

import SettlementCatalogTable from "../../../components/tables/SettlementCatalogTable";

export default {
  name: "SettlementCatalog",
  components: {SettlementCatalogTable},
  computed:{
    ...mapState("profile", ["profile"]),
    breadcrumb() {
      const profile = this.$route.params.profile;
      return [
        {
          text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
          to: { name: "profile" },
        },
        {
          text: this.$t("Settings"),
          to: { name: "settings" },
        },
        {
          text: this.$t("Settlement Catalog"),
          to: { name: "settings.settlement" },
          active: true,
        },
      ];
    }
  },
  created() {

  },
  methods:{
    ...mapActions('loads',['getSettlementCatalog']),

  }
}
</script>

<style scoped>

</style>