<template>
    <div class="main-content">
        <div class="page-content" style="height: 100%;width: 100%">
            <div class="row m-0">
                <div class="col-3 font-weight-bold">{{ $t('Equipment') }}</div>
                <div class="col-3 font-weight-bold">{{ $t('Pickup date') }}</div>
                <div class="col-3 font-weight-bold">{{ $t('Pickup places') }}</div>
                <div class="col-3 font-weight-bold">{{ $t('Delivery places') }}</div>
            </div>

            <div class="row m-0">
                <div class="col-3 font-weight-bold">
                    <multiselect class="p-0 pt-2"  v-model="form.equipments" :options="equipments_marketplace" :multiple="true" :close-on-select="false" :clear-on-select="false" deselectLabel="Remove" selectedLabel="" selectLabel="" :preserve-search="true" :placeholder="placeholder('equipments')" label="name" track-by="code">
                        <template slot="selection" slot-scope="{ values }"><span class="" v-if="values.length">{{ values.length }} {{ $t('options selected') }}</span></template>
                    </multiselect>
                </div>
                <div class="col-3 font-weight-bold">
                    <template>
                        <date-picker :disabled-date="disabledDates" class="p-0 pt-2" v-model="form.pickup_date" :placeholder="$t('Select date')"/>
                    </template>
                </div>
                <div class="col-3 font-weight-bold">
                    <multiselect class="p-0 pt-2"  v-model="form.origins" :options="catalogs.states" :multiple="true" :close-on-select="false" :clear-on-select="false" deselectLabel="Remove" selectedLabel="" selectLabel="" :preserve-search="true" :placeholder="placeholder('origins')" label="name" track-by="value">
                        <template slot="selection" slot-scope="{ values }"><span class="" v-if="values.length">{{ values.length }} {{ $t('options selected') }}</span></template>
                    </multiselect>
                </div>
                <div class="col-3 font-weight-bold">
                    <multiselect class="p-0 pt-2"  v-model="form.destinies" :options="catalogs.states" :multiple="true" :close-on-select="false" :clear-on-select="false" deselectLabel="Remove" selectedLabel="" selectLabel="" :preserve-search="true" :placeholder="placeholder('destinies')" label="name" track-by="value">
                        <template slot="selection" slot-scope="{ values }"><span class="" v-if="values.length">{{ values.length }} {{ $t('options selected') }}</span></template>
                    </multiselect>
                </div>
            </div>

            <div class="header_title_banner pt-4">
                <div class="header_title_banner_content">
                    <div class="header_title_banner_content_separated" v-for="i in 12" :key="i" :style="{left: (i*8.333)+'%'}"/>
                </div>
            </div>
            
            <div class="row main-board pt-3">
                <div class="col-md-12">
                    <div v-infinite-scroll="handleScroll" infinite-scroll-disabled="loading" infinite-scroll-distance="10">                        <div class="row m-0">
                            <div v-if="loads" class="col-md-12 mt-2">
                                <h5 >{{loads.total}} <small> {{$t('results')}}</small></h5>
                                    <empty-results v-if="loads.total === 0" />
                            </div>
                            <div class="col-12 col-md-6 col-lg-4" :key="'load'+item.id" v-for="item in items">
                                <load-card :load="item" />
                            </div>
                        </div>
                        <loading v-if="loading"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState,mapGetters, mapActions} from 'vuex';
import LoadCard from '@/components/common/search/LoadCard';
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker';
import queryBuilder from '@/utils/queryBuilder'

export default {
    components: {
        LoadCard,
        Multiselect,
        DatePicker
    },
    name: "Search",
    data(){
        return {
            collapsed: false,
            loads: {
                total: 0
            },
            load: null,
            items: [],
            loading: false,
            selected: [],
            size: 12,
            form: {
                equipments: [], 
                origins: [],
                destinies: [],
                pickup_date: null
            },
            next_token: null,
            filters: {}
        }
    },
    computed: {
        ...mapState('profile',['profile']),
        ...mapState('catalogs', ['catalogs']),
        ...mapGetters('catalogs',['truck_types_marketplace','equipments_marketplace']),
        truckTypes() {
            return this.truck_types_marketplace
        }
    },
    created () {
        this.fetchData()
    },
    watch: {
        form: {
            deep: true,
            handler(newValue, oldValue){
                this.filterChange();
            }
        }
    },
    methods: {
        ...mapActions('loads',['searchLoads']),
        filterChange(){
            this.filters = queryBuilder(this.form);
            this.next_token = null
            this.items = []
            this.fetchData()
        },
        fetchData(){
            let filters = _.cloneDeep(this.filters)
            if (this.loads.total >= this.items.length) {
                this.loading = true;
                this.searchLoads({...filters, ...{size: this.size},...(this.next_token ? {next_token: this.next_token}:{})})
                    .then(data=>{
                        this.loads = _.cloneDeep(data)
                        this.items = _.concat(this.items, _.cloneDeep(data.data))
                        this.next_token = _.cloneDeep(data.next_token)
                    })
                    .catch(error => {

                    })
                    .finally(()=>{
                        this.loading = false;
                    });
            }
        },
        handleScroll(){
            if(this.next_token){
                this.loading = true
                this.fetchData()
            }
        },
        placeholder(field) {
            return this.form[field].length > 0 ? '' : this.$t('Select')
        },
        disabledDates(date) {
            let yesterday = new Date((new Date).getTime() - 86400000)
            return date < yesterday;
        }
    },
}
</script>

<style scoped>
    .main-board{
        height: calc(100vh - 130px);
    }
</style>
