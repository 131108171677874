<template>
    <multiselect v-model="selected" :options="options" open-direction="bottom" :close-on-select="true"
                 :clear-on-select="false" :preserve-search="true" :disabled="disabled"
                 track-by="id" label="plate" :placeholder="$t('Select')" select-label="" :selected-label="$t('Selected')"
                 :searchable="true" :loading="isLoading" :internal-search="false" @search-change="onChange"
    >
      <template slot="singleLabel" slot-scope="props">
        <span class="option__title">{{ props.option.name }}</span>
      </template>
      <template slot="option" slot-scope="props">
        <span class="option__title">{{ props.option.name }}</span>
      </template>
    </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'

export default {
    name:'RouteInput',
    components: {
        Multiselect
    },
    props: {
        filters: {
            type: Object,
            default(){return {}}
        },
        value:{
            required: true
        },
        disabled:{
             type: Boolean,
              default(){
                 return false;
              }
        }
    },
    data(){
        return {
            selected : null,
            options: [],
            isLoading: false,
            query: null
        }
    },
    computed: {
        localFilters(){
            if(this.query)
                return {...this.filters, search: this.query};
            return this.filters;
        }
    },
    watch:{
        selected: {
            deep:true,
            handler(newValue, oldValue){
                this.$emit('input', newValue);
                this.$emit('change', newValue);
            }
        },
        value: {
            deep:true,
            handler(newValue){
                this.selected = newValue;
            }
        },
        filters:{
            deep:true,
            handler(newValue, oldValue){
                if(!_.isEqual(newValue, oldValue))
                    this.fetchData();
            }
        }
    },
    created(){
        this.selected = this.value;
        this.doSearch = _.debounce(this.fetchData, 500);
        this.fetchData();
    },
    methods: {
        ...mapActions('routes',['fetchRoutes']),
        onChange(query){
            this.query = query;
            this.doSearch();
        },
        fetchData(){
          this.isLoading = true;
          this.fetchRoutes(_.cloneDeep(this.localFilters))
            .then(data=>{
                this.options = data.results;
            })
            .catch(error =>{

            })
            .finally(()=>{
              this.isLoading = false;
            })
        }
    }
}
</script>
