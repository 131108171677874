<template>
  <div>
    <page-header :title="$t($route.meta.title)"/>
    <div class="alert alert-info" role="alert">
      {{$t('Views are a way to organize your loads by grouping them into lists based on certain criteria.')}}
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-12">
            <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
              <template  v-slot:right>
                <div class="btn-group" role="group" aria-label="toolbar">
                  <button  v-b-tooltip.hover :title="$t('Refresh')" @click="fetchFields" type="button" class="btn btn-light">
                    <i class="far fa-sync"></i>
                  </button>
                  <button  v-bind:disabled="selected.length === 0" v-b-tooltip.hover :title="$t('Delete')" @click="confirmDelete" type="button" class="btn btn-light">
                    <i class="far fa-trash-alt"></i>
                  </button>
                  <router-link v-b-tooltip.hover :title="$t('Add')" :to="{name: $route.meta.routes.add, params:{form_id: form_id}}" class="btn btn-light">
                    <i class="far fa-plus"></i>
                  </router-link>
                </div>
              </template>
            </filter-bar>
          </div>
        </div>

        <view-table v-model="selected" :filters="filters" v-on:page-change="pageChange" :edit="$route.meta.routes.edit"/>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import deleteManyMixin from "@/mixins/deleteMany";
import ViewTable from "@/components/tables/ViewTable";

export default {
  name:'LoadFields',
  components:{
    FilterBar,
    ViewTable
  },
  mixins: [filterMixin, deleteManyMixin],
  data(){
    return {
      options: [],
      selected: [],
      form_id: null
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
  },
  async created() {
    /*
    this.form_id = this.profile.profile.load_form_id
    if(!this.form_id){
      let payload = {name:'Load form',type:'load-form'};
      try{
        let form = await this.storeForm(payload);
        let account = _.cloneDeep(this.profile.account);
        account.profile.load_form_id = form.id;
        await this.updateAccount(account);
        await this.getProfile();
        this.form_id = form.id;
      }catch (e) {
        // TODO: Show error message
      }
    }

     */
    this.init();
  },
  watch:{
    '$route': 'init'
  },
  methods: {
    ...mapActions('views',['storeView','getViews','deleteManyViews']),
    init(){
      let filters = {form_id: this.form_id};

      // Use meta filters
      if(this.$route.meta.paramFilters){
        for(let key of this.$route.meta.paramFilters){
          filters[key] = this.$route.params[key];
        }
      }
      if(this.$route.meta.filters){
        filters = {...filters, ...this.$route.meta.filters};
      }

      this.defaultFilters = filters;

      this.options = [

      ];

      let toolbarFilters = {};
      for(let item of this.options){
        if(this.$route.query.hasOwnProperty(item.filterProperty)){
          toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
        }
      }
      this.toolbarFilters = toolbarFilters;
    },
    fetchFields(){
      this.getViews(this.filters)
          .then(data=>{

          })
          .catch(error =>{
          })
    },
    deleteMany(){
      return new Promise((resolve, reject)=>{
        this.deleteManyViews({ids: this.selected.join(',')})
            .then(data=>{
              this.selected = [];
              this.fetchFields();
              resolve(data);
            })
            .catch(error =>{
              reject(error);
            })
      })
    },
  }
}
</script>

<style>

</style>
