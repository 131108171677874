<template >
    <div class="card">
        <div class="row no-gutters">
            <div class="col-md-2 d-flex justify-content-center align-items-center">
                <img src="@/assets/images/marketplace/gatas.png" alt="gatas" width="100">
            </div>
            <div class="col-md-10 border-left card-body">
                <div class="row" v-if="!gatas">
                    <div class="col-5 pl-5">
                        <div class="text-muted font-size-12">{{$t('Product')}}</div>
                        <div class="font-weight-bold font-size-18">Gatas</div>
                        <div class="text-muted font-size-12">Lorem ipsum dolor sit amet</div>
                    </div>
                    <div class="col-4">
                        <div class="text-muted font-size-12">{{$t('Price')}}</div>
                        <div class="font-weight-bold text-primary">
                            $1,000.00 <span class="font-size-12">{{rate.currency}}</span> c/u
                        </div>
                    </div>
                    <div class="col-3 text-primary font-weight-bold d-flex justify-content-center align-items-center">
                        <div class="font-size-14 d-flex justify-content-between" role="button" @click="addGatas">
                            <i class="ri-add-circle-fill mr-1"></i>
                            <span>{{$t('Add')}}</span>
                        </div>
                    </div>
                </div>
                <div class="pl-5" v-else>
                    <h5 class="font-weight-bold text-primary card-title d-flex align-items-center">
                        <i class="ri-check-line font-size-22"></i>
                        <span class="font-size-16">{{$t('You successfully added an additional product')}}</span>
                    </h5>
                    <div class="row d-flex align-items-center">
                        <div class="col-2 font-weight-bold card-text font-size-18">Gatas</div>
                        <div class="col-2">
                            <input
                                type="number"
                                class="form-control form-control-sm"
                                value="1"
                                min="1"
                                v-model="total"
                            >
                        </div>
                        <div class="col-2 text-muted font-size-12">{{$t('Quantity')}}</div>
                        <div
                            class="text-primary font-weight-bold font-size-14"
                            role="button"
                            @click="removeGatas"
                        >
                            {{$t('Delete')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: "GatasCard",
    computed: {
        ...mapGetters('marketplace', ['accesories', 'rate']),
        gatas() {
            return this.accesories.find(e => e.code === 'O_GATAS');
        }
    },
    data() {
        return {
            total: 0,
        }
    },
    methods: {
        ...mapMutations('marketplace', ['setAccesories']),
        addGatas() {
            this.total = 1;
            let copy_accesories = _.cloneDeep(this.accesories);
            copy_accesories.push({name: 'Gatas', code: 'O_GATAS', quantity: 1, amount: 1000, unit_cost: 1000});
            this.setAccesories(copy_accesories);
        },
        removeGatas() {
            this.total = 0;
            let copy_accesories = _.cloneDeep(this.accesories);
            let new_accesories =  copy_accesories.filter(e => e.code !== 'O_GATAS');
            this.setAccesories(new_accesories);
        }
    },
    watch: {
        total(newValue, oldValue) {
            if(newValue === 0) return false;
            let copy_gatas = _.cloneDeep(this.gatas);
            copy_gatas.quantity = Number(newValue);
            copy_gatas.amount = newValue * copy_gatas.unit_cost;
            let copy_accesories = _.cloneDeep(this.accesories);
            let new_accesories =  copy_accesories.filter(e => e.code !== 'O_GATAS'); // remove old gatas
            new_accesories.push(copy_gatas) // push new gatas info
            this.setAccesories(new_accesories);
        }
    },
    created() {
        this.total = this.gatas ? this.gatas.quantity : 0;
    }
}
</script>

<style scoped>
.card{
    max-width: 640px;
    border-radius: 20px;
}

/* always show arrows for number input */
input[type="number"]:not([step="any"])::-webkit-inner-spin-button,
input[type="number"]:not([step="any"])::-webkit-outer-spin-button {
  opacity: 1;
}

</style>
