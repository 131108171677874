<template>
    <div class="overflow-auto">
        <div v-for="item in issues" :key="`issue-${item.id}`" class="card">
            <div class="card-body">
                <table>
                    <tr>
                        <td class="issue-table-title">
                            <div class="text-muted issue-text">{{$t('Date')}}</div>
                        </td>
                        <td>
                            <div class="issue-text">{{item.created_at | timestamp}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="issue-table-title">
                            <div class="text-muted issue-text" >{{$t('Category')}}</div>
                        </td>
                        <td>
                            <div class="issue-text">{{item.category ? item.category.name: '- -'}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="issue-table-title">
                            <div class="text-muted issue-text" >{{$t('Subject')}}</div>
                        </td>
                        <td>
                           <div class="issue-text">{{item.type.name}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="issue-table-title">
                            <div class="text-muted issue-text">{{$t('Description')}}</div>
                        </td>
                        <td>
                            <div class="issue-text">{{item.description}}</div>
                        </td>
                    </tr>
                    <tr v-if="item.position">
                      <td class="issue-table-title">
                        <div class="text-muted issue-text">{{$t('Temperature')}}</div>
                      </td>
                      <td>
                        <div class="issue-text">{{load.position.temperature}}</div>
                      </td>
                    </tr>
                </table>
            </div>
        </div>
        <router-link :to="{name:'loads.detail.issues.new', params:{load_id: load.id}}" target="_blank"  class="card cursor-pointer" >
            <button type="button" class="btn btn-primary btn-block ">
                <i class="far fa-plus mr-2" />{{$t('Add event')}}
            </button>
        </router-link>
         <!-- <issue-modal v-model="form" :load_id="load.id" :visible="showIssueModal" v-on:updateData="onModalClose" /> -->
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import IssueModal from '@/components/modals/EditIssue'

export default {
    name: 'LoadEvents',
    components:{IssueModal},
    props:{
        load:{
            type: Object
        }
    },
    data(){
        return {
            issues: [],
            showIssueModal: false,
            form: {}
        }
    },
    created(){
        this.fetchIssues();
    },
    methods: {
        ...mapActions('issues',['getIssues']),
        ...mapActions('loads',['getEvents']),
        fetchIssues()
        {
            this.getIssues({load_id: this.load.id})
                .then(data =>{
                    this.issues = data.results;
                })
                .catch(error =>{
                    
                });
        },
        addIssue(){
            this.form = {load_id: this.load.id};
            this.showIssueModal = true;
        },
        onModalClose(){
            this.showIssueModal = false;
            this.fetchIssues();
            this.getEvents({ id: this.load.id});
        },
        onIssueListClose(){
            this.showIssues = false;
        }
    }
}
</script>

<style scoped>
.issue-table-title{
    text-align: right;
    padding-right: 10px;
}
.issue-text{
    font-size: 11px;
}
</style>