<template>
    <div class="font-family-helvetica">
        <div class="row position-relative">
            <div class="col-6 col-md-5 offset-md-3 pl-md-5">
                <total-card :rate="shipment.rate" />
                <equipment-card :container="shipment.container" :equipment="shipment.equipment" />
                <div class="card date-card mb-3">
                    <div class="card-body">
                        <span class="text-muted font-size-12">{{$t('Created at')}}:</span>
                        <span class="font-weight-bold font-size-14 ml-1">{{shipment.created_at}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <route-card :stops="shipment.stops" :customer="shipment.customer" />
            </div>
        </div>
        <div class="text-center text-primary font-weight-bold font-size-14">Ver resumen completo</div>
    </div>
</template>

<script>

import Preview from "./Preview.vue";
import TotalCard from '@/components/marketplace/rate/TotalCard';
import EquipmentCard from '@/components/marketplace/rate/EquipmentCard';
import RouteCard from '@/components/marketplace/rate/RouteCard';

export default {
    name: "Preview",
    props: {
        shipment: Object,
    },
    components: {
        TotalCard,
        EquipmentCard,
        RouteCard,
    },
}
</script>

<style lang="scss" scoped>

.date-card{
    border: none;
    border-radius: 20px 20px 20px 0;
    background-color: #EBEBEB;
}

</style>
