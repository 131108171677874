<template>
    <div class="nopadding">
        <div class="bg-overlay"></div>
        <div class="modal-window bg-white fixed">
            <div class="modal-content">
                <div class="modal-header"></div>
                <div class="modal-body">
                    <div class="block">
                        <h2>¡Tenemos grandes noticias para ti!</h2>
                    </div>
                    <div class="block">
                        <p class="text-center">Hemos mejorado nuestra plataforma para ofrecerte </p>
                        <p class="text-center">un mejor servicio y experiencia.</p>
                    </div>
                    <div class="block"></div>
                    <div class="product-quickview" />
                </div>
                <div class="block"></div>
                <div class="feature-list">
                    <ul>
                        <li>Menú de navegación simplificado</li>
                        <li>Interfaz mucho más sencilla y limpia</li>
                        <li>Visualiza más cargas en menos espacio con el nuevo diseño de la tabla</li>
                    </ul>
                    <ul>
                        <li>Navega fácilmente entre los diferentes estados de carga</li>
                        <li>Actualiza el estado de las cargas fácilmente</li>
                    </ul>
                </div>

                <div class="block text-center">
                    <button class="button green" @click="goToNewTraxporta">
                        Ir al nuevo Traxporta
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import HeaderTitleVue from '../../components/common/HeaderTitle.vue';
import UpcomingLoads from '@/components/home/UpcomingLoads';
import InProgressLoads from '@/components/home/InProgressLoads';
import Documents from '@/components/home/Documents';
import ProfilePercentage from '@/components/home/ProfilePercentage';
import Calendar from '@/components/home/Calendar';
import Search from '@/components/home/Search';
import { mapState } from 'vuex';
import RecommendedLoads from "../../components/home/RecommendedLoads";
export default {
    name: "Home",
    components: {
        RecommendedLoads,
        HeaderTitle: HeaderTitleVue,
        UpcomingLoads,
        InProgressLoads,
        Documents,
        ProfilePercentage,
        Calendar,
        Search
    },
    methods:{
        goToNewTraxporta(){
            const { hostname } = window.location;
            const env = hostname === 'localhost' || hostname === 'web.dev.traxporta.com' ? 'dev' : 'prod'
            const next = env == 'dev' ? 'https://app.dev.traxporta.com' : 'https://app.traxporta.com'
            window.location.href = `${next}/carriers`;
        }
    },
    computed: {
        ...mapState('profile', ['profile']),
        profileCompleted() {
            return this.profile.profile.profile_percentage === 100;
        },
        role() {
            return this.profile.roles[0];
        },
        load_recommendation() {
            return this.profile.profile.load_recommendation;
        }
    }
}
</script>

<style scoped>
img {
    width: 6%;
}

.font-size-check {
    font-size: 1.9rem !important;
}

.h-400 {
    min-height: 400px;
}
</style>
