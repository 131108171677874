<template>
  <div>
    <div class="row mt-4">
      <div class="col-md-8 offset-md-2">
        <loading v-if="loading" />
        <div v-if="empty">
          <empty-results :subtitle="$t('This shipment does not have a checklist')"/>
        </div>
        <div class="card" :key="'stop'+instance.id" v-for="(instance, cIndex) in items">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0" >{{instance.form.name}} <span class="text-capitalize text-muted font-size-12 ">{{$t(instance.form.type)}} Checklist</span></h6>
              <div class="type_load" v-if="instance.form.owner">
                <span class="font-weight-bold" >{{$t('Shipper')}}</span>
                <span class="load_id font-weight-bold text-primary " style="color: #00B3E6 !important" >{{ instance.form.owner.name }}</span>
              </div>
            </div>
          </div>
          <div class="form-fields">
            <template v-for="(field, idx) in instance.form.fields">
              <template v-if="['gallery','pod'].indexOf(field.type) > -1">

                <div class="field-wrapper" :key="'field-'+field.id" >
                  <div >
                    <div class="row align-items-center ">
                      <div class="col-md-6">
                        <div class="field-label">{{field.title}}</div>
                        <div class="field-help">{{field.help}}</div>
                      </div>
                    </div>
                  </div>

                  <template v-if="field.answer">
                    <div class="d-flex justify-content-center flex-wrap p-3">
                      <a
                          v-for="(item, gIndex) in field.answer"
                          :key="field.id+'-'+gIndex"
                          @click="() => showGallery(cIndex, idx, gIndex)"
                          class="cursor-pointer"
                      >
                        <div class="img-fluid">
                          <img :src="`${item}`" alt width="80" height="80" style="object-fit: cover;" />
                        </div>
                      </a>
                    </div>
                    <vue-easy-lightbox
                        :visible="field.galleryVisible"
                        :index="index"
                        :imgs="field.answer"
                        @hide="field.galleryVisible = false"
                    ></vue-easy-lightbox>
                  </template>
                  <template v-else>
                    <empty-results :title="$t('No photos')" :subtitle="$t('No photos have been uploaded')" />
                  </template>
                </div>
              </template>

              <form-input
                  v-else
                  :key="'field-'+field.id"
                  :title="field.title"
                  :help="field.help"
                  v-model="field.answer"
                  :type="field.type"
                  :name="'field.'+idx+'.title'"
                  :options="field.options"
                  :read-only="readOnly"
                  v-on:change="(value)=>saveAnswer(instance.owner.id, instance.id, field.id, value)"
              />
            </template>

          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import ChecklistForm from "@/views/loads/ChecklistForm";
import {mapActions, mapState} from 'vuex';
import FileInput from "@/components/form/FileInput";
import VueEasyLightbox from "vue-easy-lightbox";
import Multiselect from 'vue-multiselect';
import addressFormat from "@/helpers/address-format";
import formMixin from '@/mixins/formMixin';
import Swal from "sweetalert2";

export default {
  name: "externalRequirements",
  components:{
    ChecklistForm,
    FileInput,
    VueEasyLightbox,
    Multiselect
  },
  mixins:[formMixin],
  data(){
    return {
      loading: false,
      items: [],
      index : 0,
      readOnly: true,
      stop: null,
      empty: false,
      downloading: false
    }
  },

  computed:{
    ...mapState('shipments',['externalRequirements','shipment']),
    ...mapState('profile',['profile']),
    isEditable(){
      return true;
    }
  },
  watch: {
    checklist: {
      deep: true,
      handler(value){
        this.items = _.cloneDeep(value);
      }
    }
  },
  created() {
    this.loading = true;
    this.getExternalRequirements({id: this.$route.params.load_id}).then(data=>{
      if(data.length === 0){
        this.empty = true;
      }else{
        this.items = _.cloneDeep(data);
      }

    }).catch(error =>{

    }).finally(()=>{
      this.loading = false;
    });
  },
  methods:{
    ...mapActions('shipments',['getExternalRequirements','storeChecklistAnswerShipment','downloadPOD','podCompleted','getLoad']),
    onClickGallery(slide){
      // TODO: remove image  (slide.index)

    },
    saveAnswer(stopId, checklistId,fieldId, value)
    {
      let payload = {
        load_id : this.$route.params.load_id,
        stop_id: stopId,
        checklist_id : checklistId,
        field_id: fieldId,
        value: value
      };
      this.storeChecklistAnswerShipment(payload);
    },
    appendImage(stopId, checklistId,fieldId, url){
      let checklist = _.cloneDeep(this.checklist);
      let form = checklist.find(item => item.id === checklistId);
      let field = form.form.fields.find(item => item.id === fieldId);
      let answer = field.answer ? field.answer : [];
      answer.push(url);
      this.saveAnswer(stopId, checklistId, fieldId, answer);
    },
    showGallery(checklistIndex, fieldIndex, index) {
      this.index = index;
      let items = _.cloneDeep(this.items);
      items[checklistIndex].form.fields[fieldIndex].galleryVisible = true;
      this.items = items;
    },
  }
}
</script>

<style scoped lang="scss">
.type_load{
  background: white;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgb(153 153 153 / 50%);
  border: 1px solid #DDDDDD;

  .load_id{
    border-left: 1px solid #DDDDDD;
    padding: 0 0 0 10px;
    margin-left: 10px;
  }

}
</style>

