import axios from "../../api/client";


// initial state
export const state = () => ({
    forms: {},
    transfer_methods: []
});

// getters
export const getters = {
    tenantForms: state=>{
        return state.forms;
    },
    productForm: state=>{
        return state.forms.product_form ? state.forms.product_form : null
    }
};

// actions
export const actions = {
    getConfiguration({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/configuration`)
                .then(response=>{
                    commit('setConfiguration', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setConfiguration(state, payload){
        state.forms = payload.forms;
        state.transfer_methods = payload.transfer_methods;
    }
};

