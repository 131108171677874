<template>
    <div class="row">
        <div class="col-md-3">
            <b>ID:</b> {{ resource.id }}
        </div>
        <div class="col-md-3">
        <b>{{$t('External ID')}}:</b> {{ resource.external_id ? resource.external_id : '--' }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Name')}}:</b> {{ resource.name + ' ' + resource.last_name }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Email')}}:</b> {{ resource.email }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Phone')}}:</b> {{ resource.phone }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Created at')}}:</b> {{ resource.account.created_at | timestamp('YYYY-MM-DD') }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Company')}}:</b> {{ resource.company }}
        </div>
        <div class="col-md-3">
            <b>{{$t('License')}}:</b> {{ resource.driver_license ? resource.driver_license : '--' }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Insurance company')}}:</b> {{ resource.insurance_company ? resource.insurance_company : '--' }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Insurance number')}}:</b> {{ resource.insurance_number ? resource.insurance_number : '--'}}
        </div>
        <div class="col-md-3">
            <b>{{$t('Documents')}}:</b> {{ $t(resource.document_status) }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Account')}}:</b> {{ resource.account.is_active ? $t('enabled'):$t('disabled') }}
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex';

export default {
    name: "DriverInformation",
    computed: {
        ...mapState('requirements', ['resource']),
    }
}
</script>
