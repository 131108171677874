<template>
    <b-modal
        id="confirm-email-modal"
        ref="confirm-email-modal"
        size="md"
        :title="$t('Account Confirmation')"
        :visible="visible"
        hide-footer
        v-on:hidden="setVisible(false)"
    >
        <div class="field-wrapper border-0 text-center">
            <img src="@/assets/images/txp/triangle.png" style="width: 80px;height: 80px;margin:10px;" />
            <div v-if="fetching">
                <b-card>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                </b-card>
                <b-skeleton type="button w-100"></b-skeleton>
            </div>
            <div v-if="!fetching && !response">
                <h2 class="card-title text-center font-size-22 font-weight-bolder">{{$t('Email confirmation')}}</h2>
                <div class="sub_message text-center text-muted mb-1">
                    {{$t('To confirm your account we will send a link to the email:')}}
                </div>
                <div class="text-primary font-weight-bolder mt-2 mb-3">{{ profile.email }}</div>
                <button @click="sendVerification" class="btn btn-primary_v2 btn-block">
                    {{$t('Send confirmation')}}
                </button>
                <div class="mt-4" :class="[this.$route.matched.some(({ name }) => name === 'settings.profile') && 'd-none']">
                    <span>{{$t('Is your email wrong?')}}</span> &nbsp;
                    <span class="text-primary" role="button" @click="goToProfile">{{$t('Edit')}}</span>
                </div>
            </div>
            <div v-if="response == 'success'">
                <h2 class="card-title text-center font-size-22 font-weight-bolder m-4">
                    {{$t('The confirmation link has been sent successfully.')}}
                </h2>
            </div>
            <div v-if="response == 'error'">
                <h2 class="card-title text-center font-size-22 font-weight-bolder">{{$t('Wrong email')}}</h2>
                <p class="sub_message text-center text-muted">
                    {{$t('Check that your email has no errors, in case you have entered it wrong you can')}}
                    <span class="text-primary font-weight-bolder" role="button" @click="goToProfile">{{$t('edit here')}}.</span>
                </p>
            </div>
            <button v-if="response" @click="$bvModal.hide('confirm-email-modal')" class="btn btn-primary_v2 btn-block">
                {{$t('Close')}}
            </button>
        </div>
    </b-modal>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import {sendEmailConfirmation} from "../../utils/auth";

export default {
    name: 'ConfirmEmail',
    props:{},
    data(){
        return {
            response: null,
        }
    },
    computed:{
        ...mapState('profile', ['profile']),
        ...mapState('emailverification', ['visible', 'fetching']),
        ...mapGetters('emailverification', ['email_verified']),
    },
    methods: {
        ...mapActions('emailverification', ['sendEmailVerification']),
        ...mapMutations('emailverification',['setVisible']),
        async sendVerification(){
          sendEmailConfirmation().then(()=>{
            this.response = 'success'
          }).catch(()=>{
            this.response = 'error'
          })
        },
        goToProfile(){
            this.setVisible(false)
            if(this.$route.name !== 'settings.profile'){
                this.$router.push({name:'settings.profile'});
            }
        }
    },
    watch: {
        visible: function(val){
            if(!val){ // reset value
                this.response = null
            }
        }
    }
}
</script>

<style lang="css" scoped>
.disabled{ pointer-events: none !important; }
</style>
