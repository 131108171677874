import axios from "../../api/client";
import moment from "moment";


// initial state
export const state = () => ({
    fetching: false,
    shipments : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    status: [],
    stops: [],
    shipment: null,
    checklist : [],
    externalRequirements : [],
    last_filters: {},
    documents: [],
    merchandises: {},
});

// getters
export const getters = {
    getByCategory: (state) => (category) => {
        return state.settlementCatalogSelectData.filter(settlementCatalog => {
            let existe = settlementCatalog.category.filter((e) => {
                return e.value == category
            });
            return existe.length>=1
        })
    }
};

// actions
export const actions = {
    getFares({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/fares`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    searchShipments({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/search', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    fetchShipments({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/shipments', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getShipments({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/shipments',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setShipments', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    storeShipment({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getShipment({commit, dispatch}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${params.id}`)
                .then(response=>{
                    dispatch('getStops', params);
                    commit('setShipment', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateShipment({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/shipments/${payload.id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteShipment({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/shipments/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getLoadsByTenders({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/tenders/loads',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setShipments', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updatePosition({commit, state}, payload){
        if(!state.load)
            return;
        if(payload.load_id === state.load.id){
            let load = _.cloneDeep(state.load);
            load.position = payload.position;
            commit('setShipment', load);
        }
    },
    updateShipmentFields({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/shipments/${payload.id}/fields`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getStops({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${params.id}/stops`)
                .then(response=>{
                    commit('setStops', response.data.results);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getStatus({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${params.id}/status`)
                .then(response=>{
                    commit('setStatus', response.data.status);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getAllChecklist({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${params.id}/checklist`)
                .then(response=>{
                    commit('setAllChecklist', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getMerchandises({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${query.id}/merchandises`,{params: query})
                .then(response=>{
                    commit('setMerchandises', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getExternalRequirements({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${query.id}/externalRequirements`,{params: query})
                .then(response=>{
                    commit('setExternalRequirements', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateMerchandises({commit}, query){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments/${query.id}/merchandises`,query)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteMerchandises({commit}, query){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/shipments/${query.id}/merchandises/${query.merchandiseId}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    importMerchandises({commit}, payload){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for(let [key, value] of Object.entries(payload)){
                formData.append(key, value);
            }
            axios.post(`/api/v2/shipments/${payload.shipment_id}/merchandises/import`, formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then((response)=>{
                    resolve(response.data);
                })
                .catch((error)=>{
                    reject(error.response);
                });
        });
    },
    downloadPOD({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${params.id}/checklist/download`)
                .then(response=>{
                    const fileLink = document.createElement('a');
                    fileLink.href = response.data.url;
                    fileLink.setAttribute('download', response.data.url.substring(response.data.url.lastIndexOf('/')+1) );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    resolve(response.data);

                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeChecklist({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments/${payload.load_id}/stops/${payload.stop_id}/checklist`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getChecklist({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${params.load_id}/stops/${params.stop_id}/checklist/${params.checklist_id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteChecklist({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/shipments/${payload.load_id}/stops/${payload.stop_id}/checklist/${payload.checklist_id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeChecklistAnswer({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments/${payload.load_id}/stops/${payload.stop_id}/checklist/${payload.checklist_id}/answer`,
                {field_id: payload.field_id, value: payload.value})
                .then(response=>{
                    let checklist = _.cloneDeep(state.checklist);
                    let checklistIndex = checklist.findIndex(item => item.id === payload.checklist_id);
                    let fieldIndex = checklist[checklistIndex].form.fields.findIndex(item => item.id === payload.field_id);
                    checklist[checklistIndex].form.fields[fieldIndex].answer = payload.value;
                    commit('setAllChecklist', checklist);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeChecklistAnswerShipment({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments/${payload.load_id}/externalRequirements/${payload.checklist_id}/answer`,
                {field_id: payload.field_id, value: payload.value})
                .then(response=>{
                    let checklist = _.cloneDeep(state.externalRequirements);
                    let checklistIndex = checklist.findIndex(item => item.id === payload.checklist_id);
                    let fieldIndex = checklist[checklistIndex].form.fields.findIndex(item => item.id === payload.field_id);
                    checklist[checklistIndex].form.fields[fieldIndex].answer = payload.value;
                    commit('setAllChecklist', checklist);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getDocuments({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${payload.id}/documents`,
                {params: {...payload,per_page: 100000} })
                .then(response=>{
                    commit('setDocuments', response.data.results);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeDocument({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments/${payload.id}/documents`,
                {params: payload })
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getEvents({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${payload.id}/events`,
                {params: {...payload,per_page: 100000} })
                .then(response=>{
                    commit('setEvents', response.data.results);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTenders({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${payload.id}/tenders`)
                .then(response=>{
                    commit('setTenders', response.data.results);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    acceptedTender({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments/${payload.id}/tenders/${payload.tender_id}/accept`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    rejectedTender({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments/${payload.id}/tenders/${payload.tender_id}/reject`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeTender({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments/${payload.id}/tenders`,payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    generateDocuments({}, payload) {
        return new Promise((resolve, reject) => {
            let request = {}

            if (payload.type === 'bol')
                request = axios.post(`/api/v2/shipments/${payload.id}/generate-bol`)
            else
                request = axios.post(`/api/v2/shipments/${payload.id}/generate-document/${payload.type}`)

            request
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },
    importShipments({commit}, payload){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for(let [key, value] of Object.entries(payload)){
                formData.append(key, value);
            }
            axios.post('/api/v2/shipments/import', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then((response)=>{
                    resolve(response.data);
                })
                .catch((error)=>{
                    reject(error.response);
                });
        });
    },
    exportShipments({commit}, query){
        return new Promise((resolve, reject) => {
           axios({
               url:'/api/v2/shipments/export',
               method:'get',
               responseType: 'blob',
               params: query
               }).then(response=>{
                   const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   const fileLink = document.createElement('a');
                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', `shipments-${moment().unix()}.xlsx`);
                   document.body.appendChild(fileLink);
                   fileLink.click();
                   resolve();
               })
               .catch(error =>{
                   console.log(error);
                   reject(error.response)
               });
       });
   },

   getShipmentLoads({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${params.id}/loads`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    saveBol({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments/${payload.id}/bol`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setFetching(state, data){
        state.fetching = data;
    },
    setFetchingCosts(state, data){
        state.fetchingCosts = data;
    },
    setShipments(state, data){
        state.shipments = data;
    },
    setShipment(state, data){
        state.shipment = data;
    },
    clearLoad(state){
        state.stops =  [];
        state.checklist =  [];
    },
    setStops(state, data){
        state.stops = data;
    },
    setStatus(state, data){
        state.status = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    setAllChecklist(state, data){
        state.checklist = data;
    },
    setExternalRequirements(state, data){
        state.externalRequirements = data;
    },
    setDocuments(state, data){
        state.documents = data;
    },
    setEvents(state, data){
        state.events = data;
    },
    setTenders(state, data){
        state.tenders = data;
    },
    setExpenses(state, data){
        state.expenses = data;
    },
    setSettlementCatalog(state, data){
        state.settlementCatalog = data;
    },
    setMerchandises(state, data){
        state.merchandises = data;
    },
    setSettlementCatalogSelectData(state, data){
        state.settlementCatalogSelectData = data.results;
    },
    expenses(state, data){
        state.expense = data;
    },

};

