<template>
    <div class="m-3">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <b>{{field.title}}</b>
                <button @click="addItem" type="button" class="btn btn-primary btn-sm">
                    <i class="far fa-plus-circle mr-1" />
                    {{$t('Add')}}
                </button>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <th
                        v-for="child in field.children"
                        :key="`title-child-${child.id}`"
                        >
                        {{child.title}}
                        </th>
                        <th></th>
                    </thead>
                    <tr v-if="items.length == 0">
                        <td colspan="100%">
                            <div class="text-center">
                                {{$t('No items')}}
                            </div>

                        </td>
                    </tr>
                    <tr
                    v-for="row,index in items"
                    :key="`row-${field.id}-${index}`"
                    >
                        <td
                        v-for="child, idx in field.children"
                        :key="`row-child-${child.id}-${idx}`"
                        >
                            {{row[child.id]}}
                        </td>
                        <td>
                            <a class=" cursor-pointer text-black mr-2" href="javascript:void(0);"  @click="editItem(index)">
                                <i class="far fa-edit btn-icon" />
                            </a>
                            <a class=" cursor-pointer text-black" href="javascript:void(0);" @click="confirmDelete(index)">
                                <i class="far fa-trash-alt btn-icon" />
                            </a>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <b-modal 
            :id="`edit-item-${field.id}`" 
            :title="field.title" 
            body-class="p-0 m-0"
            :ok-title="edit !== null ? $t('Save') : $t('Add')"
            cancel-title="Cancelar"
            cancel-variant="light"
            v-on:ok="onOk"
            >
             <div class="form-fields">
                <template 
                    v-for="child, idx in field.children"
                >
                    <form-input
                        :key="`row-child-field-${child.id}-${idx}`"
                        :help="child.help"
                        v-model="item[child.id]"
                        :title="child.title"
                        :type="child.type"
                        :name="'field.'+idx+'.title'"
                        :options="child.options"
                        :rules="child.required == true ? 'required':'' "
                        layout="vertical"
                    />
                </template>
             </div>
        </b-modal>
    </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
    name: 'TableInput',
    props:{
        field: {
            type: Object,
            required: true
        },
        value:{
            type: Array
        },
    },
    data(){
        return {
            items: [],
            item: {},
            edit: null
        }
    },
    watch: {
        items: {
            deep: true,
            handler(newValue, oldValue){
                this.$emit('input', newValue);
            }
        },
        value: {
            deep: true,
            handler(newValue, oldValue){
                //this.items = _.cloneDeep(newValue);
            }
        }
    },
    created(){
        if(this.value){
            this.setValue(this.value);
        }
    },
    methods: {
        setValue(val){
            this.items = _.cloneDeep(val);
        },
        addItem(){
            this.edit = null;
            let item = {};
            for(let f of this.field.children){
                item[f.id] = null
            }
            this.item = item;
            this.$bvModal.show(`edit-item-${this.field.id}`);
        },
        editItem(index){
            console.log(index);
            this.edit = index;
            this.item = _.cloneDeep(this.items[index]);
            this.$bvModal.show(`edit-item-${this.field.id}`);
            console.log("Edit item")
        },
        onOk(){
            console.log(this.edit)
            if(this.edit !== null){
                this.saveItem();
            }else{
                this.insertItem();
            }
        },
        insertItem(){
            this.items.push(_.cloneDeep(this.item));
        },
        saveItem()
        {
            console.log("save item", this.edit)
            let _items = _.cloneDeep(this.items);
            _items[this.edit] = this.item;
            this.items = _items;
        },
        confirmDelete(index){
            Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, delete it!"),
                cancelButtonText: this.$t("Cancel")
            }).then(result => {
                if(result.value) {
                    this.items.splice(index, 1);
                }
            });
        },
    }
}
</script>

<style scoped>
  .text-black{
    color: #343a40!important;
  }
</style>