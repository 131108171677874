<template>
  <div class="baody_componenet">
    <div class="wizard-header_navigations">
        <div class="wizard_navigation-step" v-for="(step,index) in steps" :key="'wizard_navigation-step'+index" @click="setStep(index)">
            <div class="wizard_navigation-step_body">
              <div class="wizard_navigation-step_body_circle mt-2 mr-3" :class="index===currentstep?'active':'error'">
                <i v-if=" currentstep  > index" class="fa fa-check"></i>
                <span v-else>{{index+1}}</span>
              </div>
              <div>
                <small> {{$t('Step')}} {{index+1}}</small> <br>
                <div :class="[index === currentstep ? 'font-weight-bold' : '']">{{step}}</div>
              </div>

            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wizardHeader",
  props: ['stepcount', 'currentstep',"steps"],
  methods:{
    setStep(index){
      this.$emit('step-change', index)
    }
  }
}
</script>

<style scoped>

.baody_componenet{
  /*box-shadow: -1px 4px 8px 0 #bfb9b9;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;*/
}

.wizard-header_navigations{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.wizard_navigation-step:hover{
  cursor: pointer;
}
.wizard_navigation-step_body_circle{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border: 1px solid  black;
  border-radius: 50%;
}
.wizard_navigation-step_body_circle.active{
  background: linear-gradient(90.3deg, #00B3E6 1.2%, #E3ED55 100%);
  border: 1px solid transparent;
  color: white;
}
.wizard_navigation-step_body_circle.error{
  /*background: #85fc2a;
  border: 1px solid transparent;
  color: white;*/
}
.wizard_navigation-step{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;
  width: 0;
}
.wizard_navigation-step_body{
  display: flex;
  justify-content: space-between;
  align-items: center;

}
</style>