<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <th class="text-center">ID</th>
                    <th class="text-center">{{ $t('Description') }}</th>
                    <th class="text-center">{{ $t('Type') }}</th>
                    <th class="text-center">{{ $t('Amount') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fetching">
                    <td colspan="100%">
                        <loading />
                    </td>
                </tr>

                <template v-for="(item, index) in concepts">
                    <tr :key="'settlementdetail-'+index"  class="settlementdetail-row">
                        <td class="text-center">
                            <b>{{index + 1}}</b>
                        </td>

                        <td class="text-center">
                            {{ item.description }}
                        </td>

                        <td class="text-center">
                            <span class="badge" :class="getTypeStyle(item.type)" >{{ getTypeName(item.type) }}</span>
                        </td>

                        <td class="text-center">
                            ${{ item.amount | money }} MXN
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div v-if="concepts.length === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapActions} from "vuex";

    export default {
        name: "SettlementDetailTable",
        data(){
            return {
                fetching: false
            }
        },
        props:{
            concepts: {
                type:Array
            },
        },
        methods: {
            ...mapActions('settlements'),
            getTypeStyle(item){
                switch (item) {
                    case 'percepcion':
                    return "badge-outline-success";
                    case 'deduccion':
                    return "badge-outline-danger";
                    default: return 'badge-outline-info'
                }
            },
            getTypeName(item) {
                return item === 'percepcion' ? this.$t('perception') : this.$t('deduction')
            }
        }
    }
</script>

<style scoped>
    .settlementdetail-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }
</style>
