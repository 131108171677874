<template>
    <div>
        <page-header :title="$t('Profile')" :items="breadcrumb"/>
        <validation-observer ref="observer" v-slot="{ validate }">
            <b-form @submit.stop.prevent="onSubmit(validate)">
                <div class="card">
                    <div class="card-header">
                        <h6>{{$t(('General'))}}</h6>
                    </div>
                    <div class="form-fields">
                        <form-input
                                :title="$t('Name')"
                                v-model="form.name"
                                rules="required"
                                type="text"
                                :name="'name'"
                        />
                        <form-input
                                :title="$t('Last name')"
                                v-model="form.last_name"
                                rules="required"
                                type="text"
                                :name="'last_name'"
                        />
                        <form-input
                                :title="$t('Email')"
                                :help="$t('Your email address')"
                                v-model="form.email"
                                rules="required|email"
                                :type="email_verified ? 'email' : 'email-group'"
                                :name="'email'"
                                :clickButtonGroup="showEmailVerification"
                        />
                        <form-input
                                :title="$t('Phone')"
                                v-model="form.phone"
                                rules="required"
                                type="tel"
                                :name="'phone'"
                        />
                        <form-input
                                v-if="isCarrier"
                                :title="$t('Percent Advance')"
                                v-model="form.profile.percent_advance"
                                type="number"
                                :name="'percent_advance'"
                        />
                        <form-input
                                :title="$t('Avatar')"
                                v-model="form.avatar"
                                rules=""
                                type="avatar"
                                :name="'avatar'"
                                v-on:change="onAvatarChange"
                        />
                    </div>
                </div>

                <div class="d-flex justify-content-start mb-4">
                    <button type="submit" class="btn btn-primary">{{$t('Save')}}</button>
                </div>

            </b-form>
        </validation-observer>
    </div>
</template>

<script>
    import {mapState, mapActions,mapGetters} from 'vuex'
    import formMixin from '@/mixins/formMixin';

    export default {
        name: "Profile",
        mixins:[formMixin],
        data(){
          return {
                loading : false,
                form: null,
                avatar: null
          }
        },
        computed: {
            ...mapState('profile',['profile']),
            ...mapGetters('emailverification',['email_verified']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Settings"),
                        to: {name:'settings'}
                    },
                    {
                        text: this.$t('Profile'),
                        to: {name:'settings.profile'},
                        active: true
                    }
                ]
            },
           isCarrier(){
              return ["carrier"].includes(this.profile.roles[0]);
           }
        },
        created() {
            this.form = _.cloneDeep(this.profile);
        },
        methods: {
            ...mapActions('profile',['updateMe','updateAvatar']),
            ...mapActions('emailverification', ['showEmailVerification']),
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    return;
                }
                this.loading = true;
                let form = _.cloneDeep(this.form);

                this.updateMe(form)
                    .then(()=>{
                        this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });

                    })
                    .catch(error=>{
                        this.processServiceError(error);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });

            },
            onAvatarChange(base64){
               this.updateAvatar({base64: base64})
                    .then(data=>{
                        this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                         this.form = _.cloneDeep(data);
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    });
            }
        },
    }
</script>

<style scoped>

</style>
