import axios from "../../api/client";


// initial state
export const state = () => ({

});

// getters
export const getters = {};

// actions
export const actions = {
    downloadFile({commit}, url){
        return new Promise(async (resolve, reject) => {
            const template = await fetch(url);
            const fileLink = document.createElement('a');
            fileLink.href = window.URL.createObjectURL(new Blob([await template.blob()]));
            fileLink.download = url.substring(url.lastIndexOf('/')+1);
            document.body.appendChild(fileLink);
            fileLink.click();
            resolve();
        });
    },
};

// mutations
export const mutations = {

};

