import axios from "../../api/client";


// initial state
export const state = () => ({
    addresses : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    fetching: false,
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchPostalCodesV2({}, params) {
        return new Promise((resolve, reject) => {
          axios
            .get(
              `https://api.dev.traxporta.com/postal-codes/${params.country.toLowerCase()}?postal_code=${
                params.code
              }`
            )
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response);
            });
        });
    },
    fetchPostalCodes({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/postal-codes',{params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getAddresses({commit}, params){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/addresses',{params: params})
                .then(response=>{
                    commit('setAddresses', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    storeAddresses({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/addresses`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    updateAddresses({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/addresses/${payload.id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    deleteAddresses({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/addresses/${payload.id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    importAddresses({commit}, payload){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for(let [key, value] of Object.entries(payload)){
                formData.append(key, value);  
            }
            axios.post('/api/v2/addresses/import', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then((response)=>{
                    resolve(response.data);
                })
                .catch((error)=>{
                    reject(error.response);
                });
        });
    },
};

// mutations
export const mutations = {
    setFetching(state, payload){
        state.fetching = payload;
    },
    setAddresses(state, payload){
        state.addresses = payload;
    },
};

