<template>
  <div>
    <loading v-show="loading"></loading>
    <div class="table-responsive" v-show="!loading" v-if="items.length > 0">
      <table class="table mb-0">
        <thead>
        <tr class="">
          <th class="text-center">ID</th>
          <th class="text-center">{{$t('Carrier')}}</th>
          <!--<th class="text-center">{{$t('Price')}}</th>-->
          <th class="text-center">{{$t('Date')}}</th>
          <th class="text-center">{{$t('Status')}}</th>
          <th class="text-center" style="width: 25px;" v-show="items.status !== 'accepted'"></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="item in items">
          <tr v-if="init" :key="'load-'+item.id"  class="product-row">
            <td class="text-center">
              <router-link :to="{name:'', params:{}}">
                <b>{{item.id}}</b>
              </router-link></td>
            <td class="text-center">{{item.carrier.name}}</td>
            <!--<td class="text-center">{{ (item.price) ? item.price: '---'}}</td>-->
            <td class="text-center">{{item.created_at | timestamp('DD-MMMM-YYYY hh:mm')}}</td>
            <td class="text-center">
              <badge-status :status="item.status" />
            </td>
            <td class="text-center" v-show="item.status !== 'accepted' && item.status !== 'rejected'">
              <button class="btn btn-primary btn-sm"
                      :disabled="item.status === 'accepted' || item.status === 'rejected'"
                      @click="acceptTender(item)">
                      {{$t('Accept')}}
              </button>
            </td>
            <td class="text-center" v-show="item.status !== 'accepted' && item.status !== 'rejected'">
              <button class="btn btn-danger btn-sm"
                      :disabled="item.status === 'accepted' || item.status === 'rejected'"
                      @click="rejectTender(item)">
                {{$t('Reject')}}
              </button>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div v-if="items.length < 1" class="row" v-show="!loading">
      <div class="col">
        <empty-results />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import selectAllMixin from "@/mixins/selectAllMixin";
import Swal from "sweetalert2";


export default {
  name: "LoadTender",
  mixins:[selectAllMixin],
  components: {},
  data() {
    return {
      page: 1,
      init: true,
      items: [],
      loads: null,
      loading:true,
    }
  },
  computed: {
    ...mapState('loads',['tenders','load']),
    ...mapState('profile',['profile']),
  },
  created() {
    this.loads = this.load;
    this.getTenders({ id: this.$route.params.load_id}).then(data =>{
      this.items = this.tenders;
      
      this.loading = false;
    });
  },
  methods: {
    ...mapActions('loads',['getTenders','acceptedTender','rejectedTender','getLoad']),
    acceptTender(item){
      Swal.fire({
        title: this.$t("Confirm"),
        text: this.$t("Are you sure to assign the load to ")+ item.carrier.name + "?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Accept"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.loading = true;
          this.acceptedTender({id: this.$route.params.load_id, tender_id : item.id})
              .then(data =>{
                  this.$root.$bvToast.toast(this.$t("Your load was assigned correctly", {
                    resource: this.$t("Tenders").toLowerCase()}), {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true
                  });
                  this.$router.push({name:'shipments.detail.general', params:{load_id :this.$route.params.load_id}});
              })
              .catch(error => {
                this.$bvToast.toast(this.$t("Something went wrong!"), {
                  title: "Error",
                  variant: 'danger',
                  solid: true
                });
              }).finally( () => {
                this.getTenders({ id: this.$route.params.load_id}).then(data => {});
                this.loading = false;
              });
        }
      });
    },
    rejectTender(item){
      Swal.fire({
        title: this.$t("Confirm"),
        text: this.$t("Are you sure to decline the offer?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Accept"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.loading = true;
          this.rejectedTender({id: this.$route.params.load_id, tender_id : item.id})
              .then(data =>{
                this.$root.$bvToast.toast(this.$t("Was rejected correctly", {
                  resource: this.$t("Tenders").toLowerCase()}), {
                  title: this.$t('Success'),
                  variant: 'warning',
                  solid: true
                });
                this.getTenders({ id: this.$route.params.load_id}).then(data => {});
                //this.$router.push({name:'loads.detail', params:{load_id :this.$route.params.load_id}});
              })
              .catch(error =>{
                this.$bvToast.toast(this.$t("Something went wrong!"), {
                  title: "Error",
                  variant: 'danger',
                  solid: true
                });
              }).finally( () => {
                  this.getTenders({ id: this.$route.params.load_id}).then(data => {});
                  this.loading = false;
              });
        }
      });
    }

  }
}
</script>

<style scoped>


</style>