<template>
<div>

    <div style="padding:1.25rem;">
        <div class="row align-items-center ">
            <div class="col-md-10 col-sm-10 ">
                <h6 class="text-muted">{{$t('Name')}}</h6>
            </div>
            <div class="col-md-2 text-center pr-0">
                <h6 class="text-muted">{{$t('Action')}}</h6>
            </div>
        </div>
    </div>
    <hr class="mt-0"/>
    <loading v-if="fetching" />

    <template v-for="item in list.results">
        <div class="card"  :key="'cost-'+item.id">
            <div class="card-body p-0">
                <div class="row align-items-center p-3">
                    <div class="col-md-10 col-sm-10">
                            <h6 class="one-line">{{item.name}}</h6>
                    </div>
                    <div class="col-md-2 col-sm-2 text-center pr-0">
                        <a class=" cursor-pointer text-black"  v-b-tooltip.hover :title="$t('Delete')"  @click="confirmDelete(item)" >
                            <i class="far fa-trash-alt btn-icon" />
                        </a>
                        <router-link class="ml-4 cursor-pointer text-black" :to="{name:'settings.teams.edit', params:{team_id: item.id}}">
                            <i class="far fa-edit btn-icon"  />
                        </router-link>
      
                    </div>
                </div>
            </div>
        </div>
    </template>


    <div  v-if="list.total > 0"  class="row align-items-center">
        <div class="col-md-6 col-sm-6 ">
            <b-pagination
                    v-model="page"
                    :total-rows="list.total"
                    :per-page="list.per_page"
                    aria-controls="my-table"
                    first-number
                    last-number
                    pills
            ></b-pagination>
        </div>
        <div class="col-md-6 col-sm-6 text-sm-right">
            <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
        </div>
    </div>
    <div v-if="list.total === 0 && !fetching" class="row">
        <div class="col">
            <empty-results />
        </div>
    </div>
</div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import Swal from "sweetalert2";

export default {
    name: 'TeamsTable',
    props: {
        filters : {
            type: Object,
            default(){
                return {}
            }
        },
    },
    data(){
        return {
            page: 1,
            init: false
        }
    },
    computed: {
        ...mapState('teams', ['teams','fetching']),
        list(){
            return this.teams;
        }
    },
    created() {
        this.page = this.filters.page ? Number(this.filters.page) : 1;
        this.fetchData();
    },
    watch: {
        filters : {
            deep: true,
            handler(newVal, oldVal){
                if(!_.isEqual(newVal, oldVal) || this.init === false){
                    this.fetchData();
                }
            }
        },
        page(newVal){
            this.$emit('page-change', newVal);
        }
    },
    methods: {
        ...mapActions('teams',['getTeams','deleteTeam']),
        fetchData(){
            this.getTeams(_.cloneDeep(this.filters))
                .then(response=>{
                })
                .catch(error =>{
                })
                .finally(()=>{
                    this.init = true;
                })
        },
            confirmDelete(item){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteTeam({id: item.id})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Team").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                        });
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            },
    }
}
</script>
