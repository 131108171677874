<template>
    <side-content :menu="menu" :loading="false"></side-content>
</template>

<script>
    import {mapState} from 'vuex';
    import SideContent from "../SideContent";
    import settingsMenu from "@/constants/settingsMenu";
    export default {
        name: "Settings",
        components:{
            SideContent
        },
        data(){
            return {
            }
        },
        computed:{
            ...mapState('profile',['profile']),
            menu(){
                return settingsMenu[this.$route.params.profile];
            }
        },
    }
</script>

<style scoped>

</style>
