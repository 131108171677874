<template>
    <b-modal 
        id="issues-list-modal" 
        ref="issues-list-modal"
        size="xl" 
        :title="$t('Events')"
        :visible="visible"
        hide-footer
        v-on:hidden="$emit('close')"
        >
         <issues-table v-model="selected" :filters="filters" :showFilterBar="false" :loadId="filters.load_id" />
    </b-modal>
</template>

<script>
import IssuesTable from '@/components/tables/IssuesTable';

export default {
    name: 'IssuesModal',
    components:{
        IssuesTable
    },
    props:{
        visible: {
            type: Boolean,
            default(){
                return false;
            }
        },
        filters: {
            type: Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return {
            selected: []
        }
    },
    methods: {

    }
}
</script>