<template>
    <div class="no-padding">
        <especial-project v-if="!complete" @cancel="cancel" @accept="accept" />
        <request-sent v-if="complete" />
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import EspecialProject from '@/components/common/EspecialProject.vue';
import RequestSent from '@/components/common/RequestSent.vue';

export default {
    name: "Special",
    components: {
        EspecialProject,
        RequestSent
    },
    computed: {
        ...mapState('marketplace', ['shipment']),
        ...mapState('profile', ['profile'])
    },
    methods: {
        ...mapMutations('marketplace', ['setStep']),
        ...mapActions('marketplace', ['specialProject']),
        cancel() {
            this.setStep(4);
        },
        async accept() {
            const shipment = _.cloneDeep(this.shipment)

            let origin = shipment.origin,
                destination = shipment.destination;

            origin.address.location = {
                lat: shipment.origin.address.location[0],
                lng: shipment.origin.address.location[1]
            };

            destination.address.location = {
                lat: shipment.destination.address.location[0],
                lng: shipment.destination.address.location[1]
            };

            const payload = {
                customer: {
                    name: this.profile.name,
                    email: this.profile.email,
                    phone: this.profile.phone
                },
                shipment: {
                    origin: origin,
                    destination: destination,
                    equipment_type: shipment.equipment_type,
                    commodity: shipment.commodity
                }
            }

            const response = await this.specialProject(payload)
            this.$root.$bvToast.toast(this.$t("Hemos enviado un correo"), {
                title: this.$t('Success'),
                variant: 'success',
                solid: true
            });
            this.complete = true;
        }
    },
    data() {
        return {
            complete: false
        }
    }
}
</script>

<style scoped>
.font-family-helvetica {
    font-family: Helvetica;
}

.font-size-title {
    font-size: 3.2rem;
}

.font-size-subtitle {
    font-size: 1.4rem;
}

.ri-bookmark-3-line {
    font-size: 2.1rem;
}

.w-5 {
    width: 5%;
}
</style>
