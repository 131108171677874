<template>
    <div>
        <page-header v-if="!isDocumentModule" :title="this.$t('Drivers')" :items="breadcrumb"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-12">
                        <filter-bar :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved">
                            <template v-if="!isDocumentModule" v-slot:right>
                                <div class="btn-group" role="group" aria-label="toolbar">
                                    <router-link v-can="'drivers.create'" v-b-tooltip.hover :to="{name: 'drivers.add', query:{role:'shipper'}}" class="btn btn-light">
                                        <i class="far fa-fw fa-plus"></i>
                                    </router-link>
                                    <button v-if="false" v-can="'drivers.delete'" v-bind:disabled="selected.length === 0" v-b-tooltip.hover :title="$t('Delete')" type="button" class="btn btn-light">
                                        <i class="far fa-fw fa-trash-alt"></i>
                                    </button>
                                </div>
                            </template>
                        </filter-bar>
                    </div>
                </div>

                <driver-table v-model="selected" :filters="filters" v-on:page-change="pageChange" :isDocumentModule="isDocumentModule" />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import DriverTable from "@/components/tables/DriverTable";
    import FilterBar from "@/components/filters/FilterBar";
    import filterMixin from "@/mixins/filterMixin";

    export default {
        name:'Drivers',
        components:{
            DriverTable,
            FilterBar
        },
        mixins: [filterMixin],
        data(){
            return {
                tabs: [],
                currentTab: null,
                options: [],
                selected: [],
                isDocumentModule:false
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t('Drivers'),
                        active: true,
                    }
                ]
            },
        },
        created() {
            this.init();
        },
        watch:{
            '$route': 'init'
        },
        methods: {
            init(){
                this.options = [
                    {
                        icon: 'far fa-key',
                        label: 'ID',
                        type: 'text',
                        filterProperty: 'id',
                    }
                ];

                let filters = {};

                if(this.$route.meta.filters){
                    filters = {...filters, ...this.$route.meta.filters};
                }

                this.defaultFilters = filters

                this.isDocumentModule = this.$route.meta.isDocumentModule ? this.$route.meta.isDocumentModule : false

                let toolbarFilters = {};
                for(let item of this.options){
                    if(this.$route.query.hasOwnProperty(item.filterProperty)){
                        toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
                    }
                }
                this.toolbarFilters = toolbarFilters;
            }
        }
    }
</script>

<style>

</style>
