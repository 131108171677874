<template>
    <div >
        <loading v-if="fetching" />
        <template>
            <div class="card" :key="index" v-for="(item, index) in documentByType">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-md-1 col-sm-1">
                            <template v-if="item.extension === 'pdf'">
                                <a class="mr-0" target="popup" href="javascript:void(0);" @click="showPdf(item.path)" >
                                    <i class="far fa-file-pdf" style="font-size: 2rem;" />
                                </a>
                            </template>
                            <template v-if="item.extension === 'xml'">
                                <a class="mr-0" target="popup" href="javascript:void(0);" @click="showPdf(item.path)" >
                                    <i class="far fa-file-excel" style="font-size: 2rem;" />
                                </a>
                            </template>
                            <template v-if="item.extension === 'xls' || item.extension === 'xlsx'">
                                <a class="mr-0" target="popup" href="javascript:void(0);" @click="showPdf(item.path)" >
                                    <i class="far fa-file-spreadsheet" style="font-size: 2rem;" />
                                </a>
                            </template>
                            <template v-if="imgExtensions.indexOf(item.extension) > -1">
                                <a class="mr-0"  href="javascript:void(0);" @click="showImage(index)" >
                                    <img :src="getPath(item.path)"
                                        alt
                                        width="25"
                                        height="32"
                                        style="object-fit: cover;" />
                                </a>

                            </template>

                        </div>
                        <div class="col-md-5 col-sm-5">
                            <div>
                            <h6 class="text-muted">{{$t('Name')}}</h6>
                            </div>
                            <div>
                                <h6 class="one-line">
                                    <template v-if="item.extension === 'pdf'">
                                        <a class="mr-0 text-primary" target="popup" href="javascript:void(0);" @click="showPdf(item.path)">
                                            {{item.name}}
                                        </a>
                                    </template>
                                     <template v-else-if="imgExtensions.indexOf(item.extension) > -1">
                                        <a class="mr-0 text-primary" href="javascript:void(0);" @click="showImage(index)">
                                            {{item.name}}
                                        </a>
                                     </template>
                                     <template v-else>
                                         <a class="mr-0 text-primary" target="popup" href="javascript:void(0);" @click="showPdf(item.path)">
                                            {{item.name}}
                                        </a>
                                     </template>
                                </h6>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3">
                            <div>
                            <h6 class="text-muted">{{$t('Created at')}}</h6>
                            </div>
                            <div>
                                <h6 class="one-line">{{item.created_at | timestamp('YYYY-MM-DD HH:mm')}}</h6>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-3">
                            <div>
                            <h6 class="text-muted">{{$t('Size')}}</h6>
                            </div>
                            <div>
                            <h6 class="one-line">{{Math.round((item.size/1000000) * 100) / 100}} MB</h6>
                            </div>
                        </div>
                        <div class="col-md-1 col-sm-1">
                            <div class="d-flex justify-content-sm-end px-2">
                                <a class=" cursor-pointer text-black"  v-b-tooltip.hover :title="$t('Delete')"  @click="confirmDelete(item)" >
                                    <i class="far fa-trash-alt btn-icon" />
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </template>
        <empty-results v-if="documents.length === 0 && init === true" />
            <file-input
                :path="'/api/v2/loads/'+ $route.params.load_id+'/documents'"
                :payload="{type : type}"
                :options="{dictDefaultMessage: 'Subir Documentos'}"
                v-on:success="fetchData"
                v-on:error="onError"
            />
                  <vue-easy-lightbox
                  :visible="showGallery"
                  :index="imgIndex"
                  :imgs="gallery"
                  @hide="showGallery = false">

                  </vue-easy-lightbox>
        </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import FileInput from "@/components/form/FileInput";
import Swal from "sweetalert2";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
    name: 'DocumentsComponent',
    components:{
        FileInput,
        VueEasyLightbox
    },
    props:{
        filters:{
            type: Object,
            default(){
                return {}
            }
        },
        title: {
            type: String,
            default(){
                return this.$t('Documents')
            }
        },
        type: {
            type: String,
            default(){
                return 'legal_doc'
            }
        },
    },
    data(){
        return {
            showGallery: false,
            fetching: false,
            init: false,
            imgIndex: 0,
            imgExtensions : ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'svg', 'svgz', 'cgm', 'djv', 'djvu', 'ico', 'ief','jpe', 'pbm', 'pgm', 'pnm', 'ppm', 'ras', 'rgb', 'tif', 'tiff', 'wbmp', 'xbm', 'xpm', 'xwd']
        }
    },
    computed: {
         ...mapState('loads', ['documents']),
        gallery(){
            return (this.documents.filter(item => this.imgExtensions.indexOf(item.extension) > -1)).map(item => ({src: item.path, title: item.name}));
        },
        documentByType() {
            return this.documents.filter(item => item.type == this.type)
        }
    },
    created(){
        this.fetchData();
    },
    methods:{
        ...mapActions('loads',['getDocuments','storeDocument','getLoad','deleteDocument']),
        fetchData(){
            this.fetching = true;
            this.getDocuments({id: this.$route.params.load_id})
                .catch(error =>{

                })
                .finally(()=>{
                    this.fetching = false;
                    this.init = true;
                });
        },
        getPath (path) {
        try {
            let data = JSON.parse(path)
            return data[0].normal
        }catch (e){
            return path;
        }
        },
        showImage(index){
            this.imgIndex = index;
            this.showGallery = true;
        },
        showPdf(url){
            window.open(url,'popup','width=600,height=600');
        },
        onError(msg){
            this.$root.$bvToast.toast(msg, {
                title: this.$t('Error'),
                variant: 'danger',
                solid: true
            });
        },
        confirmDelete(item){
            Swal.fire({
              title: this.$t("Are you sure?"),
              text: this.$t("You won't be able to revert this!"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: this.$t("Yes, delete it!"),
              cancelButtonText: this.$t("Cancel")
            }).then(result => {
              if(result.value) {
                this.deleteDocument({id: this.$route.params.load_id, document_id: item.id})
                    .then(data=>{
                      this.fetchData();
                      this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Document").toLowerCase()}), {
                        title: this.$t('Success'),
                        variant: 'success',
                        solid: true
                      });
                    })
                    .catch(error =>{
                      Swal.fire(
                          "Error",
                          this.$t("Something went wrong!"),
                          "error"
                      );
                    });
              }
            });
          },
        }
}
</script>
