<template>
    <div>
      <div class="row justify-content-center">
        <div class="col-md-12 header_background d-flex justify-content-center">
            <div class="wallet-card">
                <div class="card-badge d-flex justify-content-center align-items-center">
                    <b>{{$t('Current balance')}}</b>
                </div>

                <div class="d-flex bd-highlight" v-if="wallet">
                    <div class="p-2 flex-grow-1 bd-highlight text-right text-balance ">
                        ${{ wallet.balance | money }}
                    </div>
                    <div class="p-2 bd-highlight text-currency align-self-end" style="width: 70px;margin-bottom:15px">
                        {{wallet.currency}}
                    </div>
                </div>
                <div class="d-flex justify-content-center" v-else>
                    <loading />
                </div>
            </div>
        </div>
      </div>

      <template v-if="wallet">
            <div class="row" style="margin-top: 100px;">
                <div class="col-12 text-center">
                    <h4 class="pb-3">{{ $t("Last transactions") }}</h4>
                    <transaction-table
                        :filters="filters"
                        v-on:page-change="pageChange"
                    ></transaction-table>
                </div>
            </div>
      </template>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import TransactionTable from "@/components/tables/TransactionTable";
import filterMixin from "@/mixins/filterMixin";

export default {
    name: 'WalletCredit',
    components:{
        TransactionTable
    },
    mixins: [filterMixin],
    data(){
        return {
            wallet: null
        }
    },
    created(){
        this.init();
    },
    computed: {
        ...mapState('requirements',['resource']),
        driver(){
            return this.resource;
        }
    },
    methods: {
        ...mapActions('wallet', ['getWallet']),
        init(){
            this.getWallet({id: this.driver.credit_id })
                .then(data =>{
                    this.wallet = data;
                })
                .catch(error =>{

                })
        }
    }
}
</script>

<style scoped>
    .header_background{
      background: linear-gradient(90.3deg, #00B3E6 1.2%, #E3ED55 100%);
      height: 154px;
    }
    .wallet-card{
        width: 370px;
        height: 170px;
        margin-top: 54px;

        background: #00B3E6;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        border-radius: 30px;
    }
    .card-badge{
        width: 150px;
        height: 30px;

        background: #E3ED55;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        border-radius: 30px;
        margin-top: 21px;
        margin-left: -12px;
    }
    .text-balance{
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 70px;

        color: #FFFFFF;
    }
    .text-currency{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */


        color: #E3ED55;
    }
</style>