<template>
  <div class="register" style="">
    <div class="register_title_back register_section cursor-pointer" @click="back()"> <i class="fa fa-chevron-left"/>
      {{ $t('Back') }} </div>
    <div class="register_title register_section">{{$t('Sign Up')}}</div>
    <div class="register_label register_section">
      <div class="register_label_title">{{$t('Enter your full name:')}}</div>
    </div>
    <validation-observer ref="observer" v-slot="{ validate , errors}">
      <form  @submit.stop.prevent="submit(validate)" >
        <div class="" >
          <div class="register_input_generic register_section">
            <div class="register_input_generic_label">{{ $t('Name') }}</div>
            <validation-provider name="email" rules="required" v-slot="{ errors }">
              <p :class="{ 'control': true }">
                <input class="login_input_generic_input" v-model="model.name"   :class="{'input': true, 'is-danger': errors.length }"  name="name" type="text" placeholder="">
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </p>
            </validation-provider>
          </div>
          <div class="register_input_generic register_section">
            <div class="register_input_generic_label">{{ $t('Last name') }}</div>
            <validation-provider name="email" rules="required" v-slot="{ errors }">
              <p :class="{ 'control': true }">
                <input class="login_input_generic_input" v-model="model.last_name"   :class="{'input': true, 'is-danger': errors.length }"  name="last_name" type="text" placeholder="">
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </p>
            </validation-provider>
          </div>
        </div>
        <div class="register_section register_action" >
          <button  :class="{'disabled':(hasErrors(errors))}" class="btn btn-block btn-primary_v2 text-uppercase  register_action_btn" type="submit" > {{$t('continuar')}}</button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Register_Username",
  data(){
    return {
      model:{}
    }
  },
  created() {
    if (!this.getPhoneAndCodeValid()){
      this.$router.push({name:"register.index"})
    }
  },
  methods:{
    ...mapMutations('register',['setUsernameInfo']),
    ...mapGetters('register',['getPhoneAndCodeValid']),
    back(){
      this.$router.push({name:"register.code"})
    },
    async submit(validate) {
      if (!(await validate()) || this.loading) {
        this.$bvToast.toast(this.$t('Please, check the form'), {
          title: "Error",
          variant: 'danger',
          solid: true
        });
        return;
      }
      let data = _.cloneDeep(this.model)
      this.setUsernameInfo({
        name: data.name, last_name: data.last_name
      })
      this.$router.push({
        name: "register.user_info"
      })
    },
    hasErrors(err){
      return !!_.findKey(err,o=>o.length>0)
    },
  }
}
</script>

<style scoped>

</style>