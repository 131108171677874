import axios from "../../api/client";


// initial state
const defaultCatalogs = localStorage.getItem('catalogs');
const TRUCKS_TYPES = [
    {
        id: 1,
        name: "Camioneta 1.5 T",
        truck_type_code: "C1.5T",
        configuration_code: "SENCILLO",
        trailer_size_code: "",
        max_weight: '1200'
    },
    {
        id: 2,
        name: "Camioneta 3.5 T",
        truck_type_code: "C3.5T",
        configuration_code: "SENCILLO",
        trailer_size_code: "",
        max_weight: '3100'
    },
    {
        id: 3,
        name: "Rabón 8 T",
        truck_type_code: "RA8T",
        configuration_code: "SENCILLO",
        trailer_size_code: "",
        max_weight: '6800'
    },
    {
        id: 4,
        name: "Torton 12 T",
        truck_type_code: "TO12T",
        configuration_code: "SENCILLO",
        trailer_size_code: "",
        max_weight: '13400'
    },
    {
        id: 5,
        name: "Trailer sencillo 20 FT",
        truck_type_code: "TRA",
        configuration_code: "SENCILLO",
        trailer_size_code: "20FT",
        max_weight: '24000'
    },
    {
        id: 6,
        name: "Trailer sencillo 48 FT",
        truck_type_code: "TRA",
        configuration_code: "SENCILLO",
        trailer_size_code: "48FT",
        max_weight: '26000'
    },
    {
        id: 7,
        name: "Trailer sencillo 53 FT",
        truck_type_code: "TRA",
        configuration_code: "SENCILLO",
        trailer_size_code: "53FT",
        max_weight: '28000'
    },
    {
        id: 8,
        name: "Trailer Full 20 FT",
        truck_type_code: "TRA",
        configuration_code: "FULL",
        trailer_size_code: "20FT",
        max_weight: '40000'
    },
    {
        id: 9,
        name: "Trailer Full 40 FT",
        truck_type_code: "TRA",
        configuration_code: "FULL",
        trailer_size_code: "40FT",
        max_weight: '80000'
    },
];

export const state = () => ({
    catalogs: defaultCatalogs ? JSON.parse(defaultCatalogs):{},
    fetching: false,
    catalogList : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    catalog: null,
    last_filters: {},
});

// getters
export const getters = {
    brandTrucks: state=>{
        return state.catalogs.brand_trucks;
    },
    carrierSkills: state=>{
        return state.catalogs.carrier_skills
    },
    commodities: state=>{
        return state.catalogs.commodities
    },
    commodities_marketplace: (state) => {
        return state.catalogs.commodities_marketplace;
    },
    equipments_marketplace: (state) => {
        return state.catalogs.equipments_marketplace;
    },
    trailer_types_marketplace: (state) => {
        return state.catalogs.trailer_types_marketplace;
    },
    commodity_trailer_type: (state) => {
        return state.catalogs.commodity_trailer_type;
    },
    containerTypes: state=>{
        return state.catalogs.container_types;
    },
    countries: state=>{
        return state.catalogs.countries
    },
    distanceUnits: state=>{
        return state.catalogs.distance_units;
    },
    load_length: state=>{
        return state.catalogs.load_length
    },
    loadTypes: state=>{
        return state.catalogs.load_types
    },
    trailerTypes: state=>{
        return state.catalogs.trailer_types
    },
    truckSkills: state=>{
        return state.catalogs.truck_skills
    },
    truckTypes: state=>{
        return state.catalogs.truck_types
    },
    truck_types_marketplace: (state) => {
        return TRUCKS_TYPES;
    },
    weight_units: state=>{
        return state.catalogs.weight_units;
    },
    issueTypes: state=>{
        return state.catalogs.issue_types;
    },
    configuration: state=>{
        return [{id:1, name:'Sencillo'},{id:2,name:'Full'}]
        //return state.catalogs.configurations;
    },
    categories: state=>{
        return state.catalogs.categories;
    },
    resources: state=>{
        return [{value:'carrier', text:'Carrier'},{value:'shipper',text:'Shipper'},{value:'driver',text:'Driver'}, {value:'seller',text:'Seller'},{value:'load',text:'Load'},{value:'truck',text:'Truck'},{value:'trailer',text:'Trailer'}]
    },
    validators: state=>{
        return [{value:'blacktrust', text:'BlackTrust'}]
    },
    expensesTypes: state=>{
        return state.catalogs.expenses_types;
    },
    expensesStatus: state=>{
        return [{value:'pending', text:'Pending'},{value:'in_review', text:'In review'},{value:'accepted', text:'Accepted'},{value:'completed', text:'Completed'},{value:'rejected', text:'Rejected'}]
    },
    pendingStatusExpenses: state=>{
        return [{value:'pending', text:'Pending'},{value:'in_review', text:'In review'},{value:'rejected', text:'Rejected'}]
    },
    in_reviewStatusExpenses: state=>{
        return [{value:'pending', text:'Pending'},{value:'in_review', text:'In review'},{value:'accepted', text:'Accepted'},{value:'rejected', text:'Rejected'}]
    },
    acceptedStatusExpenses: state=>{
        return [{value:'accepted', text:'Accepted'},{value:'completed', text:'Completed'},{value:'rejected', text:'Rejected'}]
    },
    completedStatusExpenses: state=>{
        return [{value:'completed', text:'Completed'},{value:'rejected', text:'Rejected'}]
    },
    rejectedStatusExpenses: state=>{
        return [{value:'rejected', text:'Rejected'}]
    },
    operationalDeductionsDepartments: state=>{
        return state.catalogs.operational_deductions_deparments;
    },
};

// actions
export const actions = {
    getCatalogs({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/catalogs')
                .then(response=>{
                    commit('setCatalogs', response.data);
                    localStorage.setItem('catalogs', JSON.stringify(response.data));
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getCatalogList({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/catalog-list',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setCatalogList', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    importCatalogs({commit}, payload){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for(let [key, value] of Object.entries(payload)){
                formData.append(key, value);
            }
            axios.post('/api/v2/catalogs/import', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then((response)=>{
                    resolve(response.data);
                })
                .catch((error)=>{
                    reject(error.response);
                });
        });
    },
    fetchCatalogTax({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/catalog-tax',{params: query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    getEnum({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/enums/'+query)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
};

// mutations
export const mutations = {
    setCatalogs(state, data){
        state.catalogs = data;
    },
    setCatalogList(state, data){
        state.catalogList = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    setFetching(state, data){
        state.fetching = data;
    }
};
