<template>
    <b-button-group>
        <b-dropdown variant="light" ref="dropdown" class="d-inline-block " toggle-class="filter-button">
            <template #button-content>
                <table>
                    <tr>
                        <td valign="top" style="width:24px;">
                            <small><i class="pr-1 text-muted" :class="[filter.icon]"></i></small>
                        </td>
                        <td valign="top" class="text-left" >
                            <small class="text-muted">{{filter.label}}:</small> <div class="filter-button-value"> {{filterValue ? filterValue:'- -'}}</div>
                        </td>
                    </tr>
                </table>
            </template>
            <b-dropdown-form style="min-width: 200px;">
                <b-form-group  @submit.stop.prevent>
                    <b-form-input
                            autofocus
                            @paste="onPaste"
                            v-model="filterValue"
                            size="sm"
                            placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-dropdown-form>
        </b-dropdown>
        <b-button variant="light" @click="$emit('remove')"><i class="far fa-times"></i></b-button>
    </b-button-group>
</template>

<script>

    export default {
        name: 'TextFilter',
        props: {
            filter: {
                type: Object,
                required: true
            },
            value: {
                required:true
            }
        },
        data(){
            return {
                filterValue: null
            }
        },
        watch: {
            filterValue(newValue){
                this.$emit('input', newValue);
            },
        },
        created() {
            if(this.value){
                this.filterValue = this.value;
            }else{
                setTimeout(()=>{
                    this.$refs.dropdown.visible = true;
                },100);
            }
        },
        methods:{
          onPaste(e){
            if (this.filter.onPasteReplace){
              e.preventDefault();
              e.stopPropagation();
              let text = e.clipboardData.getData('text');
              this.filterValue = _.cloneDeep(text.replaceAll(' ',','));
            }
          }
        }
    }
</script>

