<template>
  <b-modal
      ref="modal-edit-issue"
      id="modal-edit-issue"
      size="md"
      :title="update?$t('Edit Comment'):$t('Add Comment')"
      :visible="visible"
      v-on:hidden="closeModal"
      body-class="p-0"
      cancel-variant="light"
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Save')"
      hide-footer
  >
    <validation-observer  ref="observer" v-slot="{ validate }">
      <b-form @submit.stop.prevent="onSubmit(validate)">
        <div class="row">

          <div class="col-md-12">
            <form-input
                :title="$t('Comment')"
                v-model="form.body"
                rules="required"
                type="textarea"
                name="description"
                layout="vertical"
            />
          </div>
        </div>
        <div class="d-flex justify-content-end m-4">
          <a @click="$bvModal.hide('modal-edit-issue')" :disabled="submit" class="btn btn btn-light mr-3 ">{{$t('Cancel')}}</a>
          <button type="submit" :disabled="submit" class="btn btn-primary">{{$t('Save')}}</button>
        </div>

      </b-form>
    </validation-observer>

  </b-modal>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: "EditComment",
  props: {
    comment: {
      type:Object
    },
    visible: {

    },
    update: {

    },
    resource_id: {

    },
    owner_id: {

    }
  },
  data() {
    return {
      submit: false,
      isUpdated: false,
      form :{
        body: null,
        type: "Nota",
      }
    }
  },
  watch:{
    visible(value){
      if (value){
        if (this.update){
          this.form =  _.cloneDeep(this.comment);
        }else{
          this.form.load_id = this.resource_id
          this.form.issue_id = this.owner_id
        }

      }
    }
  },
  methods:{
    ...mapActions('issues',['updateComment','storeComment']),
    closeModal(){
      let data = {id:this.form.id, updated: this.isUpdated}
      this.$emit('closeModal', data);
      this.form ={
        body: null,
        type: "Nota",
      }
      this.isUpdated = false;
      this.submit = false;
    },
    async onSubmit(validate){
      if(!(await validate())){
        return;
      }
      if(this.submit === true)
        return;

      this.submit = true;
      let form = _.cloneDeep(this.form);
      if (this.update){
        let request = {
          load_id: this.resource_id,
          issue_id: this.comment.owner_id,
          id: this.comment.id,
          body: form.body,
          type: form.type
        }
        this.updateComment(request)
            .then(data=>{
              this.isUpdated = true;
              this.$bvModal.hide('modal-edit-issue');
            })
            .catch(error =>{

            })
            .finally(()=>{
              this.submit = false;
            });
      }else{
        this.storeComment(form)
            .then(data=>{
              this.isUpdated = true;
              this.$bvModal.hide('modal-edit-issue');
            })
            .catch(error =>{

            })
            .finally(()=>{
              this.submit = false;
            });
      }

    },

  }
}
</script>

<style scoped>

</style>