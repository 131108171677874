<template >
    <div class="row">
        <div class="col-12 mb-3">
            <p class="font-weight-bolder">{{$t('What are you going to move?')}}</p>
        </div>
        <div class="col-md-4 col-12 font-size-12 font-weight-bolder">{{$t('Commodity')}}</div>
        <div class="col-md-8 col-12">
            <form-input
                placeholder="Seleccionar"
                :options="commodities_marketplace"
                type="select"
                layout="vertical"
                selectValue="external_id"
                selectText="name"
                class="p-0"
                open-direction="bottom"
                @input="onSelect"
            />
            <div class="h-100" />
            <buttons :back="back" :disabledNext="true" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Buttons from './Buttons';
export default {
    name: "SelectCommodity",
    components: { Buttons },
    computed: {
        ...mapGetters('catalogs', ['commodities_marketplace', 'equipments_marketplace', 'commodity_trailer_type', 'truck_types_marketplace']),
        ...mapGetters('marketplace', ['shipment']),
    },
    methods: {
        ...mapMutations('marketplace', ['setCommodity', 'setEquipmentType', 'setShipmentDetail', 'setAccesories']),
        ...mapMutations('shipmentform', ['setAvailable']),
        onSelect(code) {
            let commodity_name = this.commodities_marketplace.find(e => e.external_id === code).name;
            this.setCommodity({ code: code, name: commodity_name });
            let commodity_trailer = this.commodity_trailer_type.filter(e => e.code_commodity === code).map(e => e.trailer_type_code);
            let equipments = this.equipments_marketplace.filter(e => commodity_trailer.includes(e.trailer_type_code));
            let trailers = equipments.map(e => e.trailer_type_code);
            trailers = [...new Set(trailers)];
            let trucks = equipments.map(e => e.truck_type_code);
            trucks = [...new Set(trucks)];
            this.setAvailable({ trailers: trailers, trucks: trucks, equipments: equipments });
        },
        back(){
            this.$router.push({name:'shipments'})
        }
    },
    created() {
        this.setEquipmentType({
            code: null,
            name: null,
            configuration_code: null,
            truck_type_code: null,
            trailer_type_code: null,
            trailer_size_code: null,
        });
        this.setShipmentDetail({
            quantity: null,
            container_type: null,
            width: null,
            length: null,
            height: null,
            weight: null,
            stacking: false,
        });
        this.setAccesories([]);
        this.setAvailable({ trailers: null, trucks: null });
    },
}
</script>

<style scoped>

</style>
