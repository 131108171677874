import axios from 'axios'


import {refreshToken,getAccessToken} from '../utils/auth.js'


let isRefreshing = false;
let subscribers = [];

const subscribeTokenRefresh = (cb)=>{
    subscribers.push(cb);
};

const onRefreshToken = (token)=>{
    subscribers = subscribers.filter(cb => cb(token))
};
console.log(process.env.VUE_APP_STAGE)
const axiosInstance  = axios.create({
    baseURL: (process.env.VUE_APP_STAGE === 'dev' || process.env.VUE_APP_STAGE === 'staging') ? 'https://api.dev.traxporta.com' : process.env.VUE_APP_API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Workspace': 'dms:default'
    }
});

axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;

axiosInstance.interceptors.request.use(async function (config) {
    if (!config.withoutToken){
        config.headers = {
            ...config.headers,
            'Authorization': `Bearer ${await getAccessToken()}`
        };
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});


axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;

        if (error.response.status === 401){
            if(!isRefreshing){
                //Refresh token
                isRefreshing = true;
                refreshToken().then(async () => {
                    let token = await getAccessToken();
                    try {
                        Echo.options.auth.headers['Authorization'] = `Bearer ${token}`;
                    } catch (e) {
                        console.log(e)
                    }
                    isRefreshing = false;
                    onRefreshToken(token);
                }).catch(error => {

                });
            }

            return new Promise((resolve) => {
                subscribeTokenRefresh((token) => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    resolve(axiosInstance(originalRequest));
                });
            });
        }
        return Promise.reject(error);
    }
);


export default axiosInstance;
