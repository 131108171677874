<template>
    <multiselect v-model="selected" :disabled="disabled"  :multiple="multiple" :options="options" open-direction="bottom" :close-on-select="true"
                :clear-on-select="false" :preserve-search="true"
                label="name" track-by="id" :placeholder="$t('Select')" select-label="" :selected-label="$t('Selected')"
                :searchable="true" :loading="isLoading" :internal-search="false" @search-change="onChange">
        <template slot="singleLabel" slot-scope="props">
            <span class="option__title">{{ props.option.name }} {{ props.option.last_name }}</span>
        </template>
        <template slot="option" slot-scope="props">
            <span class="option__title">{{ props.option.name }} {{ props.option.last_name }}</span>
        </template>
    </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'

export default {
    name:'DriverInput',
    components: {
        Multiselect
    },
    props: {
        filters: {
            type: Object,
            default(){return {}}
        },
        value:{
            required: true
        },
        disabled:{
            type : Boolean,
            default(){
                return false;
            }
        },
        trackById:{
          type: Boolean,
          default(){return false}
        },
        multiple:{
          type: Boolean,
          default(){
            return false
          }
        }
    },
    data(){
        return {
            selected : null,
            options: [],
            isLoading: false,
            query: null
        }
    },
    computed: {
        localFilters(){
            if(this.query)
                return {...this.filters, search: this.query};
            return this.filters;
        }
    },
    watch:{
        selected: {
            deep:true,
            handler(newValue, oldValue){
              if(this.trackById){
                this.$emit('input', newValue.id);
                this.$emit('change', newValue.id);
              }else{
                this.$emit('input', newValue);
                this.$emit('change', newValue);
              }

            }
        },
        value: {
            deep:true,
            handler(newValue){
                if(this.trackById){
                  if(this.selected && this.selected.id !== newValue){
                    this.searchById(newValue);
                  }
                }else{
                  this.selected = newValue;
                }

            }
        }
    },
    created(){
      if(this.trackById){
        if(this.value){
          this.searchById(this.value);
        }
      }else{
        this.selected = this.value;
      }


        this.doSearch = _.debounce(this.fetchData, 500);
        this.fetchData();
    },
    methods: {
        ...mapActions('users',['fetchUsers']),
        onChange(query){
            this.query = query;
            this.doSearch();
        },
        fetchData(){
          this.fetchUsers(this.localFilters)
            .then(data=>{
                this.options = data.results;
            })
            .catch(error =>{

            })
        },

        searchById(id){
          this.fetchUsers({...this.localFilters, id: id})
              .then(data=>{
                if(data.results.length > 0){
                  this.selected = data.results[0];
                }
              })
              .catch(error =>{

            })
        }
    }
}
</script>
