export const menuItems = {
    shipper: [
        {
            id: 1,
            label: "Shipper",
            isTitle: true
        },
        /*
        {
            id: 'm-dashboard',
            label: 'Dashboard',
            icon: 'far fa-tachometer-alt-slowest',
            badge: {
                variant: "success",
                text: "3"
            },
            link: '/',
            route: 'dashboard',
        },
        */
        {
            id: 'm-tracking',
            label: 'Control tower',
            icon: 'far fa-satellite',
            route:{name: 'customer-control-tower'},
            link: '/customer-control-tower',
            permission: 'towercontrol.management'
        },
        {
            id: 'm-shipments',
            label: 'Shipments',
            icon: 'far fa-shipping-fast',
            route: {name: 'shipments.index'},
            permission: 'loads.read'
        },
        {
            id: 'm-history',
            label: 'History',
            icon: 'far fa-history',
            link: '/history',
            route: {name: 'history'},
            permission: 'loads.read'
        },
        {
            id: 'm-routes',
            label: 'Routes and Agreements',
            icon: 'far fa-route',
            link: '/routes',
            route: {name: 'routes.index'},
            permission: 'routes.read'
        },
        {
            id: 'm-views',
            label: 'Views',
            icon: 'far fa-list-alt',
            route:{name: 'views'},
            link: '/views'
        },
        // {
        //     id: 'm-wallet',
        //     label: 'Wallet',
        //     icon: 'far fa-wallet',
        //     link: '/wallet',
        //     route: {name: 'wallet'},
        //     permission: 'wallet.read'
        // },
        {
            id: 36,
            label: 'Configuration',
            isTitle: true,
        },
        {
            id: 3,
            label: 'Settings',
            icon: 'far fa-cogs',
            link: '/settings',
            route: {name: 'settings'}
        }
    ],
    carrier: [
        {
            id: 1,
            label: "Carrier",
            isTitle: true
        },
        {
            id: 'm-home',
            label: 'Home',
            icon: 'far fa-home-alt',
            link: '/home',
            route: {name: 'home'},
        },
        /*
        {
            id: 'm-dashboard',
            label: 'Dashboard',
            icon: 'far fa-tachometer-alt-slowest',
            badge: {
                variant: "success",
                text: "3"
            },
            link: '/',
            route: {name:'dashboard'}
        },
        */
        {
            id: 'm-tracking',
            label: 'Control tower',
            icon: 'far fa-satellite',
            route:{name: 'control-tower'},
            link: '/control-tower',
            permission: 'towercontrol.management'
        },
        // {
        //     id: 'm-search',
        //     label: 'Search loads',
        //     icon: 'far fa-search',
        //     route:{name: 'search'},
        //     link: '/search',
        //     permission: 'loads.read'
        // },
        // {
        //     id: 'm-marketplace',
        //     label: 'Marketplace',
        //     icon: 'far fa-handshake',
        //     route:{name: 'marketplace.index'},
        //     link: '/marketplace',
        //     permission: 'loads.read'
        // },
        {
            id: 'm-loads',
            label: 'Loads',
            icon: 'far fa-dolly-flatbed-alt',
            route:{name: 'loads'},
            link: '/loads',
            permission: 'loads.read'
        },
        {
            id: 'm-history',
            label: 'History',
            icon: 'far fa-history',
            link: '/history',
            route:{name: 'history'},
            permission: 'loads.read'
        },
        {
            id: 'm-views',
            label: 'Views',
            icon: 'far fa-list-alt',
            route:{name: 'views'},
            link: '/views'
        },
        // {
        //     id: 'm-wallet',
        //     label: 'Wallet',
        //     icon: 'far fa-wallet',
        //     link: '/wallet',
        //     route:{name: 'wallet'},
        //     permission: 'wallet.read'
        // },
        {
            id: 36,
            label: 'Configuration',
            isTitle: true,
        },
        {
            id: 'm-trucks',
            label: 'Trucks',
            icon: 'far fa-truck-moving',
            link: '/trucks',
            route:{name: 'trucks'},
            permission: ['trucks.read', 'trucks.create', 'trucks.update', 'trucks.delete']
        },
        {
            id: 'm-trailers',
            label: 'Trailers',
            icon: 'far fa-container-storage',
            link: '/trailers',
            route:{name: 'trailers'},
            permission: ['trailers.read', 'trailers.create', 'trailers.update', 'trailers.delete']
        },
        {
            id: 'm-drivers',
            label: 'Drivers',
            icon: 'far fa-steering-wheel',
            link: '/drivers',
            route:{name: 'drivers'},
            permission: ['drivers.read', 'drivers.create', 'drivers.update', 'drivers.delete']
        },
        {
            id: 'm-attendances',
            label: 'Attendance',
            icon: 'far fa-user-check',
            link: '/attendance',
            route:{name: 'attendance'},
            permission: 'drivers.read'
        },

        {
            id: 3,
            label: 'Settings',
            icon: 'far fa-cogs',
            link: '/settings',
            route:{name: 'settings'},
        }
    ],
    driver: [
        {
            id: 'm-home',
            label: 'Home',
            icon: 'far fa-home-alt',
            link: '/home',
            route: {name: 'home'},
        },
        {
            id: 1,
            label: "Driver",
            isTitle: true
        },
        {
            id: 'm-loads',
            label: 'Loads',
            icon: 'far fa-dolly-flatbed-alt',
            route:{name: 'loads'},
            link: '/loads',
            permission: 'loads.read'
        },
        {
            id: 'm-history',
            label: 'History',
            icon: 'far fa-history',
            link: '/history',
            route:{name: 'history'},
            permission: 'loads.read'
        },
        {
            id: 36,
            label: 'Configuration',
            isTitle: true,
        },
        {
            id: 3,
            label: 'Settings',
            icon: 'far fa-cogs',
            link: '/settings',
            route:{name: 'settings'},
        }
    ],
    admin: [
        {
            id: 1,
            label: "Admin",
            isTitle: true
        },
        /*
        {
            id: 'm-dashboard',
            label: 'Dashboard',
            icon: 'far fa-tachometer-alt-slowest',
            badge: {
                variant: "success",
                text: "3"
            },
            link: '/',
            route: 'dashboard',
        },
        */
        {
            id: 'm-search',
            label: 'Search loads',
            icon: 'far fa-search',
            route:{name: 'search'},
            link: '/search',
            permission: 'loads.search'
        },
        {
            id: 'm-tracking',
            label: 'Control tower',
            icon: 'far fa-satellite',
            route:{name: 'control-tower'},
            link: '/control-tower',
            permission: 'towercontrol.management'
        },
        {
            id: 'm-shipments',
            label: 'Shipments',
            icon: 'far fa-shipping-fast',
            route: {name: 'shipments.index'},
            permission: 'loads.read'
        },
        {
            id: 'm-loads',
            label: 'Loads',
            icon: 'far fa-dolly-flatbed-alt',
            route:{name: 'loads'},
            link: '/loads',
            permission: 'loads.read'
        },
        {
            id: 'm-views',
            label: 'Views',
            icon: 'far fa-list-alt',
            route:{name: 'views'},
            link: '/views'
        },
        {
            id: 's-accounts',
            label: 'Accounts',
            icon: 'far fa-id-card-alt',
            match: ['carriers','shippers'],
            permission: 'accounts.management',
            subItems: [
                {
                    id: 'm-carriers',
                    label: 'Carriers',
                    link: '/carriers',
                    route:{name: 'carriers'},
                },
                {
                    id: 'm-shippers',
                    label: 'Shippers',
                    link: '/shippers',
                    route:{name: 'shippers'},
                },
            ]
        },
        {
            id: 'm-trucks',
            label: 'Trucks',
            icon: 'far fa-truck-moving',
            link: '/trucks',
            route:{name: 'trucks'},
            permission: ['trucks.read', 'trucks.create', 'trucks.update', 'trucks.delete']
        },
        {
            id: 'm-trailers',
            label: 'Trailers',
            icon: 'far fa-container-storage',
            link: '/trailers',
            route:{name: 'trailers'},
            permission: ['trailers.read', 'trailers.create', 'trailers.update', 'trailers.delete']
        },
        {
            id: 'm-users',
            label: 'Users',
            icon: 'far fa-users',
            link: '/users',
            route:{name: 'users'},
            permission: 'user.module'
        },
        {
            id: 's-documents',
            label: 'Documents',
            icon: 'far fa-folder-open',
            match: ['documents.approval'],
            permission: 'documents.module',
            subItems: [
                {
                    id: 'm-approval',
                    label: 'Approval',
                    link: '/approval',
                    route: {name: 'documents.approval'},
                    permission: 'documents.approve',
                }
            ]
        },
        /*
        {
            id: 's-ecommerce',
            label: 'Ecommerce',
            icon: 'far fa-dumpster',
            match: ['products', 'branchoffices'],
            subItems: [
                {
                    id: 'm-products',
                    label: 'Products',
                    link: '/products',
                    route: {name: 'products.index'}
                },
                {
                    id: 'm-branchoffices',
                    label: 'Branch offices',
                    link: '/branchoffices',
                    route: {name: 'branchoffices.index'}
                }
            ]
        },
        */
        {
            id: 'm-finance',
            label: 'Finance',
            icon: 'far fa-sack-dollar',
            match: ['payments.management'],
            permission: 'settlement.module',
            subItems: [
                /*
                {
                    id: 's-factoring',
                    label: 'Factoring',
                    route: {name: 'finance.factoring'},
                    permission: 'payments.management',
                },
                */
                {
                    id: 's-advance',
                    label: 'Advances',
                    route: {name: 'finance.advance'},
                    permission: 'payments.management',
                },
                {
                    id: 's-transfers',
                    label: 'Transfers',
                    route: {name: 'finance.transfers'},
                    permission: 'payments.management',
                },
                {
                    id: 's-operational-deductions',
                    label: 'Operational deductions',
                    route: {name: 'finance.operational-deductions'},
                    permission: 'operational.deductions',
                },
                {
                    id: 's-fuel-vouchers',
                    label: 'Fuel vouchers',
                    route: {name: 'finance.fuelvouchers'},
                    //permission: 'operational.deductions',
                },
                /*
                {
                    id: 's-payments',
                    label: 'Payments',
                    route: {name: 'finance.payments'},
                    permission: 'payments.management',
                }
                */
            ]
        },
        {
            id: 3,
            label: 'Settings',
            icon: 'far fa-cogs',
            link: '/settings',
            route:{name: 'settings'},
        }
    ],
    seller: [
        {
            id: 1,
            label: "Seller",
            isTitle: true
        },
        {
            id: 'm-products',
            label: 'Products',
            icon: 'far fa-dumpster',
            link: '/products',
            route: {name: 'products.index'}
        },
        {
            id: 'm-branchoffices',
            label: 'Branch offices',
            icon: 'far fa-map-marked-alt',
            link: '/branchoffices',
            route: {name: 'branchoffices.index'}
        },
        {
            id: 'm-orders',
            label: 'Orders',
            icon: 'far fa-clipboard-list',
            link: '/orders',
            route: {name: 'orders.index'}
        },
        {
            id: 3,
            label: 'Settings',
            icon: 'far fa-cogs',
            link: '/settings',
            route:{name: 'settings'},
        }
    ]
};
