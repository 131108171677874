<template>
    <div>
        <navbar color="azufre" :title="$t('Routes (optional)')" image="map" />
        <div class="row mt-3" style="min-height: 300px;">
            <div class="col-8 offset-2">
                <div class="mt-2">
                    <span class="text-gray-888 font-size-13">{{$t('Look a specific route?')}}</span>
                    <br>
                    <b class="font-size-14">{{$t('Select origin points from which you start on a regular basis')}}</b>
                    <multiselect
                        :placeholder="$t('Select origin points')"
                        v-model="originsSelected"
                        track-by="value"
                        label="name"
                        :options="states"
                        :searchable="false"
                        :allow-empty="true"
                        :close-on-select="false"
                        :show-labels="false"
                        select-label=""
                        selected-label=""
                        deselect-label=""
                        @input="setOrigins"
                        class="my-2"
                        :multiple="true"
                    />
                </div>
                <div>
                    <b class="font-size-14">{{$t('Select destinations you work with most frequently')}}</b>
                    <multiselect
                        :placeholder="$t('Select destination points')"
                        v-model="destinationsSelected"
                        track-by="value"
                        label="name"
                        :options="states"
                        :searchable="false"
                        :allow-empty="true"
                        :close-on-select="false"
                        :show-labels="false"
                        select-label=""
                        selected-label=""
                        deselect-label=""
                        @input="setDestinations"
                        class="my-2"
                        :multiple="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import Navbar from '@/views/recommendations/Navbar.vue';
import Multiselect from 'vue-multiselect';

export default {
    name: "RoutesForm",
    components: { Navbar, Multiselect },
    props: {
        setOrigins: { type: Function },
        setDestinations: { type: Function },
        origins: { type: Array },
        destinations: { type: Array },
    },
    data(){
        return {
            originsSelected: [],
            destinationsSelected: [],
        }
    },
    mounted(){
        this.originsSelected = this.origins
        this.destinationsSelected = this.destinations
    },
    computed: {
        ...mapState('catalogs', ['catalogs']),
        states: function () {
            let copyStates = [...this.catalogs.states];
            if(!copyStates.find(element => element.value === '00')){
                copyStates.unshift({ "value": "00", "name": "Todos" });
            }
            return copyStates;
        }
    },
}
</script>

<style lang="css" scoped>
.bg-celeste{
    background: rgba(227, 237, 85, 0.36);
}

.logo{
    width: 6%;
}

.child {
  margin-left: auto;
  order: 2;
}

</style>
