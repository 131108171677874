<template>
    <div>
        <div class="row">
            <div class="col-md-4 col-6 font-size-12 text-dark font-weight-bold">
                {{$t('What type of truck is the right one for your cargo?')}}
            </div>
            <div class="col-md-8 col-6">
                <form-input
                    placeholder="Seleccionar"
                    rules="required"
                    :options="trucks"
                    type="select"
                    layout="vertical"
                    selectValue="id"
                    selectText="name"
                    class="border-0 p-0"
                    open-direction="bottom"
                    v-model="truck_type"
                />
            </div>
        </div>
        <div v-if="equipment_type.truck_type_code">
            <div class="row">
                <div class="col-12 mt-3">
                    <truck-card :truck="truck" />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="text-gray-888">{{$t('Select the type of trailer for your equipment')}}:</p>
                    <select-trailer :trailers="trailers" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-4 col-12 mt-4">
                <buttons
                    :back="previous"
                    :disabledNext="!equipment_type.code"
                    :next="confirm"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import TruckCard from './TruckCard';
import SelectTrailer from './SelectTrailer';
import Buttons from './Buttons';

export default {
    name: "SelectTruck",
    components: { TruckCard, SelectTrailer, Buttons },
    computed: {
        ...mapGetters('catalogs', ['truck_types_marketplace', 'trailer_types_marketplace']),
        ...mapGetters('marketplace', ['equipment_type']),
        ...mapGetters('shipmentform', ['available']),
        trucks(){
            return this.truck_types_marketplace.filter(e => this.available.trucks.includes(e.truck_type_code));
        },
    },
    data() {
        return {
            truck: {},
            trailers: [],
            truck_type: null
        }
    },
    methods: {
        ...mapMutations('marketplace', ['setEquipmentType', 'setCommodity', 'validateEquipment', 'setStep']),
        previous(){
            this.setCommodity({code: null, name: null});
        },
        confirm() {
            this.validateEquipment();
            this.setStep(2)
        },
        set_truck_type(truck_type){
            let local_equipment_type = { code: null, name: null, configuration_code: null,
                truck_type_code: null, trailer_type_code: null, trailer_size_code: null,
            }

            let truck_selected = this.truck_types_marketplace.find(e => e.id === truck_type);
            this.truck = truck_selected;
            local_equipment_type.truck_type_code = truck_selected.truck_type_code;
            local_equipment_type.trailer_size_code = truck_selected.trailer_size_code;
            local_equipment_type.configuration_code = truck_selected.configuration_code;

            this.setEquipmentType(local_equipment_type);

            let trailers = this.available.equipments.filter(entry => (
                entry.configuration_code === truck_selected.configuration_code &&
                entry.truck_type_code === truck_selected.truck_type_code ? truck_selected.truck_type_code : '' &&
                entry.trailer_size_code === truck_selected.trailer_size_code ? truck_selected.trailer_size_code : ''
            )).map(e => e.trailer_type_code);
            trailers = [...new Set(trailers)];
            let trailer_types = this.trailer_types_marketplace.filter(e => trailers.includes(e.external_id));
            this.trailers = trailer_types;
        }
    },
    watch: {
        truck_type(newValue, oldValue) {
            if(!newValue){ // reset truck_type
                this.setEquipmentType({ code: null, name: null, configuration_code: null,
                    truck_type_code: null, trailer_type_code: null, trailer_size_code: null,
                });
                return false;
            }

            this.set_truck_type(newValue)
        }
    },
    created() {
        if(this.equipment_type.code){
            let truck_selected = this.truck_types_marketplace.find(entry => (
                entry.configuration_code === this.equipment_type.configuration_code &&
                entry.truck_type_code === this.equipment_type.truck_type_code &&
                entry.trailer_size_code === this.equipment_type.trailer_size_code
            ));
            this.set_truck_type(truck_selected.id);
            this.truck_type = truck_selected.id;
        }
    }
}
</script>
