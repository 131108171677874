<template>
    <div class="card" >
        <div class="card-body">
            <div class="font-size-22 font-weight-bold text-center">Resumen General</div>
            <div class="text-muted font-size-14 text-center m-3">
                <a href="javascript:void(0);" class="card-link">Recolección y entrega</a>
                <a href="javascript:void(0);" class="card-link">Embalaje y equipo</a>
            </div>
        </div>
        <div class="card-body border-top">

            <div class="row">
                <div class="col-6">
                    <polyline-map :center="center" :path="path" :class="'map'" :markers="markers" />
                </div>
                <div class="col-6 pl-5">
                    <shipment-info />
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    Embalaje
                </div>
                <div class="col-6">
                    Equipo
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PolylineMap from "@/components/common/PolylineMap.vue";
import ShipmentInfo from "@/components/marketplace/ShipmentInfo.vue";

export default {
    name: "Overview",
    components: {
        PolylineMap,
        ShipmentInfo,
    },
    data() {
        return {
            center: {},
            markers: {},
            path: [],
        }
    },
    computed: {
        ...mapGetters('marketplace', ['route', 'shipment', 'origin', 'destination'])
    },
    created() {
        this.center = { lat: this.origin.address.location[0], lng: this.origin.address.location[1] };
        this.markers = [
            { lat: this.origin.address.location[0], lng: this.origin.address.location[1] },
            { lat: this.destination.address.location[0], lng: this.destination.address.location[1] }
        ]
        this.path = this.route.map((item) => {
            return {
                lat: item[0],
                lng: item[1]
            }
        });
    },
}
</script>

<style lang="scss" scoped>
.card{
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 5%;
}
</style>
