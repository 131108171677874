<template>
  <div class="nopadding">
    <header-title :title="$t($route.meta.title)" :headband="true">
      <template v-slot:action-button>
        <div>
          <button class="btn btn-primary_v2" @click="configmShipment">
            <i class="far fa-plus"></i> {{ $t('Create shipment') }}
          </button>
        </div>
      </template>
      <!-- <template v-slot:buttons>
        <div aria-label="toolbar">
          <button v-b-tooltip.hover :title="$t('Refresh')" @click="getShipments(filters)" type="button"
                  class="btn btn-light btn-space">
            <i class="far fa-sync"></i>
          </button>
          <button v-can="'loads.create'" v-b-tooltip.hover :title="$t('Import')" @click="$refs.import.click()"
                  type="button" class="btn btn-light btn-space">
            <i class="far fa-file-import"></i>
          </button>
        </div>
        <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv"
               style="display: none" />
      </template> -->
    </header-title>
    <ul v-if="tabs.length > 0" class="nav nav-tabs nav-tabs-custom mb-3">
      <li class="nav-item" :key="'tab-' + index" v-for="(tab, index) in tabs" @click="currentTab = tab">
        <a class="nav-link" :class="[currentTab.tab === tab.tab ? 'active' : '']" href="javascript:void(0);">{{
            $t(tab.title)
          }}</a>
      </li>
    </ul>
    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="row">
            <div class="col-md-12 px-4">
              <filter-bar :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange"
                          v-on:filter-removed="filterRemoved" @page-change="PageChange">
                
                <template v-slot:right v-if="['shipper','admin'].indexOf($route.params.profile) > -1 && $route.name.indexOf('history') === -1">
                    <div class="btn-group" role="group" aria-label="toolbar">
                        <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getShipments(filters)" type="button" class="btn btn-light">
                            <i class="far fa-sync"></i>
                        </button>
                        <button v-can="'shipments.download'" v-b-tooltip.hover :title="$t('Export')" type="button" class="btn btn-light" @click="download">
                            <i class="far fa-file-spreadsheet"></i>
                        </button>
                    </div>
                    <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
                </template>
               
              </filter-bar>
            </div>
          </div>

          <shipment-table class="px-3" :tender="currentTab ? !!currentTab.tender : false" v-model="selected"
                          :filters="filters" v-on:page-change="pageChange" />

          <b-modal no-close-on-backdrop id="modal-shipper" :title="$t('Select a shipper to continue')"
                   title-class="font-18" cancel-variant="light" :ok-title="$t('Upload')"
                   :cancel-title="$t('Cancel')" :ok-disabled="!shipper" v-on:ok="handleFile"
                   v-on:cancel="clearFile" v-on:close="clearFile">
            <div>
              <shipper-input v-model="shipper" />
            </div>
          </b-modal>

        </div>

      </div>
    </div>
    <create-shipment-modal :title="$t('Create shipment')"
                           :message="$t('Shipment creation corresponds to a Full Unit Service.')" :visible="confirmShimpmentModal"
                           v-on:close="configmShipment" :admin="admin" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ShipmentTable from "@/components/tables/ShipmentsTable";
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import ShipperInput from '@/components/form/ShipperInput';
import HeaderTitle from "@/components/common/HeaderTitle";
import CreateShipmentModal from '@/components/modals/CreateShipmentModal';

export default {
  name: 'Shipments',
  metaInfo() {
    return {
      title: this.$t('Shipments'),
    }
  },
  components: {
    HeaderTitle,
    ShipmentTable,
    FilterBar,
    ShipperInput,
    CreateShipmentModal
  },
  mixins: [filterMixin],
  data() {
    return {
      tabs: [],
      currentTab: null,
      selected: [],
      shipper: null,
      confirmShimpmentModal: false,
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapState('shipments', ['last_filters', 'shipments']),
    ...mapGetters('catalogs', ['truckTypes']),
    breadcrumb() {
      const profile = this.$route.params.profile;
      return [
        {
          text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
          to: { name: 'profile' }
        },
        {
          text: this.$route.name.indexOf('history') > -1 ? this.$t('History') : this.$t("Loads"),
          active: true,
          to: { name: 'loads.index' }
        }
      ]
    },
    admin() {
      if (this.$route.params.profile == 'admin')
        return true;
      else
        return false;
    },
    options() {
      return [
        {
          icon: 'far fa-key',
          label: 'ID',
          type: 'text',
          filterProperty: 'ids',
          onPasteReplace: true
        },
        {
            icon: 'far fa-key',
            label: 'TMS ID',
            type: 'text',
            filterProperty: 'tms_shipment_ids',
            onPasteReplace: true
        },
        {
          icon: 'far fa-sliders-h-square',
          label: this.$t('Configuration'),
          type: 'single',
          filterProperty: 'configuration_id',
          values: [
            {
              text: 'Sencillo',
              value: '1'
            },
            {
              text: 'Full',
              value: '2'
            }
          ],
          valueType: 'string'
        },
        {
          icon: 'far fa-truck-moving',
          label: this.$t('Truck type'),
          type: 'multiselect',
          filterProperty: 'truck_type_id',
          values: this.truckTypes ? this.truckTypes.map(item => ({ text: item.name, value: item.id })) : [],
          valueType: 'number'
        },
        {
          icon: 'far fa-calendar-alt',
          label: this.$t('Published since'),
          type: 'date-range',
          filterProperty: ['created_from', 'created_to']
        },
        {
          icon: 'far fa-calendar-alt',
          label: this.$t('Pickup date'),
          type: 'date-range',
          filterProperty: ['pickup_from', 'pickup_to']
        }
      ];
    }
  },
  created() {
    this.init();
  },
  watch: {
    currentTab(newValue, oldValue) {
      let filters = _.cloneDeep(newValue.filters);
      this.defaultFilters = _.cloneDeep(newValue.filters);

      if (!_.isEqual(newValue, oldValue)) {
        this.queryFilters = { ...this.queryFilters, tab: newValue.tab };
      }
    },
    '$route': 'init'
  },
  methods: {
    ...mapActions('addresses', ['fetchPostalCodes']),
    ...mapActions('shipments', ['exportShipments', 'importShipments', 'getShipments']),
    ...mapActions('accounts', ['fetchClients']),
    init() {
      if (this.$route.name.indexOf('history') === -1)
        switch (this.$route.params.profile) {
          case "shipper":
            this.tabs = [
              { title: 'My loads', tab: 'all', filters: {} },
              { title: 'Assigned', tab: 'assigned', filters: { status: 'assigned' } },
              { title: 'Not assigned', tab: 'not_assigned', filters: { status: 'not_assigned' } },
              { title: 'Pending to approve', tab: 'pending_to_approve', filters: { tender_created_by: 'carrier', status: 'not_assigned' } },
            ];

            break;
          case "carrier":
            this.tabs = [
              { title: 'Current', tab: 'current', filters: {} },
              { title: 'Tender', tab: 'tender', filters: { status: 'assigned', tender_status: 'pending' } },
              { title: 'Applied', tab: 'applied', tender: true, filters: { status: 'not_assigned', tender_status: 'pending', tender_created_by: 'carrier' } },
            ];
            break;
          case "admin":
            break;

        }
      if (this.$route.params.profile !== 'admin' && this.profile.roles.length > 1) {
        for (let i = 0; i < this.tabs.length; i++) {
          if (this.tabs[i].tab !== 'applied')
            this.tabs[i].filters[this.$route.params.profile + '_id'] = this.profile.profile.id;
        }
      }

      let tab = this.tabs.find(item => item.tab === this.$route.query.tab);
      if (tab) {
        // Carrier & shipper - tab filters
        this.currentTab = tab;
      } else {
        if (this.tabs.length > 0)
          this.currentTab = this.tabs[0];

        let filters = {};
        // Use meta filters
        if (this.$route.meta.paramFilters) {
          for (let key of this.$route.meta.paramFilters) {
            filters[key] = this.$route.params[key];
          }
        }
        if (this.$route.meta.filters) {
          filters = { ...filters, ...this.$route.meta.filters };
        }
        this.defaultFilters = filters;
      }

      let toolbarFilters = {};
      for (let item of this.options) {
        if (this.$route.query.hasOwnProperty(item.filterProperty)) {
          toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
        }
      }
      this.toolbarFilters = toolbarFilters;
    },
    onSearchPlace(search) {
      return new Promise((resolve, reject) => {
        this.fetchPostalCodes({ search: search })
            .then(data => {
              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
      });
    },
    clearFile() {
      this.$refs.import.value = null;
      this.shipper = null;
    },
    handleFile() {
      let payload = { template: this.$refs.import.files[0] };
      this.importShipments(payload)
          .then(data => {
            Echo.private(`jobs.${data.job_id}`)
                .listen('JobProcessed', (e) => {
                  this.getShipments(this.filters);
                  Echo.leave(`jobs.${data.job_id}`);
                });
            this.$root.$bvToast.toast(this.$t("File uploaded successfully", { resource: this.$t('Shipments') }), {
              title: this.$t('Success'),
              variant: 'success',
              solid: true
            });

          })
          .catch(error => {

          })
          .finally(() => {
            this.clearFile();
          });

    },
    download() {
      this.exportShipments(this.filters)
          .then(() => {

          })
          .catch(error => {

          });
    },
    async configmShipment(data) {
      if (data.visible)
        this.confirmShimpmentModal = false;
      else
        this.confirmShimpmentModal = true;
    }

  }
}
</script>

<style lang="scss">
.btn-space {
  margin-left: 20px;
}
</style>
