<template>
  <div class="day " >
    <template  v-if="event">
      <template v-if="event.type === 'better_time'">
        <div class="item item_better_time"  @click="event.editable ? active(): null"   :class="{'disable':isAvailable}">
          <div class="item_better_time_title">{{$t("Suggested date")}}</div>
          <div class="item_better_time_number_day"> <b>{{day}}</b>  </div>
          <div class="item_better_time_label">
            <div class="item_better_time_label_text">
              <span>{{ $t('Best time') }}</span>
            </div>
            <span class="item_better_time_label_icon coin"><i class="fas fa-check"></i></span>
          </div>
          <div class="item_better_time_icons"  v-if="event.isPickup">
            <i class="far fa-map-marker-alt"></i>
            <i class="fas fa-check"></i>
          </div>
        </div>
      </template>
      <template v-if="event.type === 'pickup'">
        <div class="item item_pickup"  :class="{'disable':isAvailable}">
          <div class="item_pickup_number_day"> <b>{{day}}</b>  </div>
          <div class="item_pickup_icons">
            <i class="far fa-map-marker-alt"></i>
            <i class="fas fa-check"></i>
          </div>
        </div>
      </template>
      <template v-if="event.type === 'pre_pickup'">
        <div class="item item_pre_pickup" @click="event.editable ? active(): null"   :class="{'disable':isAvailable}">
          <div class="item_pre_pickup_title">{{$t("Pickup")}}</div>
          <div class="item_pre_pickup_number_day"> <b>{{day}}</b>  </div>
          <div class="item_pre_pickup_label"> {{ timeFormat( event.from ) }} - {{ timeFormat( event.to)}}</div>
        </div>
      </template>
      <template v-if="event.type === 'delivery'">
        <div class="item item_delivery"  :class="{'disable':isAvailable}">
          <div class="item_delivery_title">{{$t("Delivery")}}</div>
          <div class="item_delivery_number_day"> <b>{{day}}</b>  </div>
          <div class="item_pre_pickup_label"> {{ timeFormat( event.from ) }} - {{ timeFormat( event.to)}}</div>
        </div>
      </template>
      <template v-if="event.type === 'extra_day'">
        <div class="item item_extra_day"  @click="event.editable ? active(): null"  :class="{'disable':isAvailable}">
          <div class="item_extra_day_title">{{$t("Extra day")}}</div>
          <div class="item_extra_day_number_day"> <b>{{day}}</b>  </div>
          <div class="item_extra_day_label">
            <div class="item_extra_day_label_text">
              <span>$2,500</span>
            </div>
            <span class="item_extra_day_label_icon coin"><i class="fa fa-dollar-sign"></i></span>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="item" @click="active" :class="{'disable':isAvailable}">
        <div class="item_number_day">
          <b>{{day}}</b>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import {getAccessToken} from "../../../utils/auth";

export default {
  name: "DayItem",
  data(){
    return {
      event:null,
      date: null
    }
  },
  props:{
    day: {
      type: Number,
      required: false,
    },
    month: {
      type: Number,
      required: false,
    },
    year: {
      type: Number,
      required: false,
    },
    events: {
      type: Array,
      default: [],
    }
  },
  created() {
    this.setDate();
  },
  computed:{
    format(){
      return this.date.format("DD MM YYYY")
    },
    isAvailable(){
      return this.date.diff(moment(),'days')<0;
    },
  },
  methods:{
    setDate(){
      this.event = null
      this.date = moment(new Date(this.year,this.month,this.day));
      if (this.events.length){
        let aux = this.events.filter(item=>moment(this.date.format('YYYY-MM-DD')).isSame(moment(item.date).format('YYYY-MM-DD')))
        let isPickup = aux.find(item =>  item.type === 'pickup')
        let isBetterTime = aux.find(item =>  item.type === 'better_time')
        let isDelivery = aux.find(item =>  item.type === 'delivery')

        if (isPickup && isBetterTime){
          this.event = {...isBetterTime, ...{isPickup: true}}
        } else{
          if (isDelivery) {
            this.event = isDelivery
          }else if (isPickup) {
            this.event = {...isPickup,...{type:'pre_pickup'}}
          }else{
            this.event = aux.length ? aux[0] : null;
          }
        }
      }

    },
    active(){
      this.$emit('setDate',moment(new Date(this.year,this.month,this.day)))
    },
    timeFormat(time){
      if (!time) {return '--'}
      let sub_time = time.split(':')
      return `${(sub_time[0]  % 12 || 12)<10?'0':''}${sub_time[0]  % 12 || 12} : ${sub_time[1]}  ${sub_time[0]>12? 'pm':'am'}`
    }
  },
  watch:{
    year(e){
      this.setDate()
    },
    month(e){
      this.setDate()
    },
    day(e){
      this.setDate()
    },
    events(e){
      this.setDate();
    },
  }
}
</script>

<style scoped lang="scss" >
.day{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;

  .item{
    text-align: center;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    height: 90px;
    width: 90%;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    background-color: #FFFFFF;
    &.disable{
      pointer-events: none;
      opacity: 0.5;
      background-color: #F2F2F2;
    }
    &.active{
      background: #00b3e6;
      border: 1px solid #00b3e6;
      color: white;
    }
    &_number_day{
      font-size: 18px;
    }
  }

  .item_pre_pickup{
    background: #00b3e6;
    border: 1px solid #00b3e6;
    color: white;
    &_title{
      font-size: 12px;
    }
    &_number_day{
      font-size: 18px;
    }
    &_label{
      font-size: 12px;
    }
  }
  .item_pickup{
    color: black;
    &_number_day{
      font-size: 18px;
    }
    &_icons{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 15px;
      right: 15px;
      i{
        font-size: 12px;
        color: #00B3E6;
        font-weight: bold;
        &:last-child{
          margin-top: 15px;
        }
      }
    }
  }
  .item_delivery{
    background: #1163EA;
    border: 1px solid #1163EA;
    color: white;
    &_title{
      font-size: 12px;
    }
    &_number_day{
      font-size: 18px;
    }
    &_label{
      font-size: 12px;
    }
  }
  .item_extra_day{
    background: #EDFBFF;
    border: 1px solid #DDDDDD;
    color: black;
    &_title{
      font-size: 12px;
      padding-bottom: 3px;
    }
    &_number_day{
      font-size: 18px;
    }
    &_label{
      padding-top: 3px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &_text{
        background-color: #FFFFFF;
        color: #434619;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 12px;
        padding: 5px;
        width: 80px;
        border-radius: 10px 0 0 10px;
        position: absolute;
      }
      &_icon{
        background-color: red;
        color: white;
        height: 25px;
        width: 25px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 40px;
      }
    }
  }
  .item_better_time{
    background: white;
    border: 1px solid #DDDDDD;
    color: black;
    &_title{
      font-size: 12px;
      padding-bottom: 3px;
    }
    &_number_day{
      font-size: 18px;
    }
    &_label{
      padding-top: 3px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &_text{
        font-family: Helvetica;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 12px;
        width: 100px;
        border-radius: 10px 0 0 10px;
        background-color: #E3ED55;
      }
      &_icon{
        background-color: #00B3E6;
        color: white;
        height: 15px;
        width: 15px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: -5px;
        i{
          font-size: 10px;
        }
      }
    }
    &_icons{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 15px;
      right: 15px;
      i{
        font-size: 12px;
        color: #00B3E6;
        font-weight: bold;
        &:last-child{
          margin-top: 15px;
        }
      }
    }
  }


}
</style>