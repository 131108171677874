<template>
    <b-modal
        id="modal-create-shipment"
        ref="modal-create-shipment"
        size="md"
        hide-footer
        :title="$t('Confirm Delivery')"
        :visible="visible"
        v-on:hidden="closeAction"
    >
        <loading v-if="fetching" class="py-lg-5" />
        <div v-else>
            <div v-if="step === 1">
                <div class="text-center font-size-24 font-weight-bolder mt-2">{{$t('How was the delivery of load?')}}</div>
                <div class="text-center text-muted font-size-16 text-gray-600 mt-2 mb-3">{{$t('Select one of the following options')}}:</div>
                <selectable-card
                    v-for="(catalog, index) in issueCatalogs"
                    :id="catalog.id"
                    :selected="() => onSelect(index)"
                    :title="catalog.name"
                    :src_img="getSrc(catalog.value)"
                    v-bind:key="index"
                />
            </div>
            <div class="text-center" v-if="step === 2">
                <div class="font-size-22 font-weight-bold my-3 subititle">{{$t('Complete the following information')}}:</div>
                <div class="text-left text-muted mb-1">{{$t('Description')}}:</div>
                <validation-observer ref="observer" v-slot="{ validate }">
                    <b-form @submit.stop.prevent="onSubmit(validate)">
                        <form-input
                            placeholder="Seleccionar"
                            rules="required"
                            type="textarea"
                            layout="vertical"
                            class="border-0 p-0 w-100"
                            v-model="description"
                        />
                        <div class="d-flex">
                            <button
                                type="button"
                                class="btn btn-block btn-light text-uppercase font-size-12 font-weight-bolder py-2 my-3 mr-2"
                                @click="onBack"
                            >
                                {{$t('Back')}}
                            </button>
                            <button
                                type="subit"
                                class="btn btn-block btn-primary_v2 text-uppercase font-size-12 font-weight-bolder py-2 my-3"
                            >
                                {{$t('Save')}}
                            </button>
                        </div>
                    </b-form>
                </validation-observer>
            </div>
            <div class="text-center" v-if="step === 3">
                <img src="@/assets/images/txp/triangle.png" class="mt-2" width="70" />
                <div class="font-size-24 font-weight-bold my-3 subititle">{{$t('Cargo delivery has been successfully confirmed')}}</div>
                <div class="font-size-15 text-muted">{{$t('Thanks for your notification')}}</div>
                <button
                    class="btn btn-block btn-primary_v2 text-uppercase font-size-12 font-weight-bolder py-2 my-3"
                    @click="closeAction"
                >
                    {{$t('Go Back')}}
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import SelectableCard from "@/components/cards/SelectableCard.vue";
import AlertModal from "@/components/modals/AlertModal";
export default {
    name: "ConfirmDeliveryModal",
    components:{ SelectableCard, AlertModal },
    props: {
        visible: Boolean
    },
    data() {
        return {
            fetching: false,
            step: 1,
            description: null,
            issue_selected: null
        }
    },
    computed: {
        ...mapGetters('reverselogistic', ['isReverseLogistic', 'issueCategories', 'issueCatalogs']),
        ...mapState('loads',['load']),
    },
    methods: {
        ...mapMutations('reverselogistic', ['setIsReverseLogistic', 'setIssueCatalog']),
        ...mapActions('reverselogistic', ['getReverseLogistics']),
        ...mapActions('loads', ['changeStatus', 'getStatus', 'getLoad', 'getEvents']),
        ...mapActions('issues', ['storeIssue']),
        closeAction() {
            this.$emit('close');
            setTimeout(() => {
                this.onBack();
            }, 500);
        },
        onSelect(index){
            let { status, name, id } = this.issueCatalogs[index];
            this.issue_selected = id;
            if(status !== 'delivering'){
                this.step = 2;
                return false;
            }
            this.callStoreIssue(name);
        },
        onBack(){
            this.step = 1;
            this.issue_selected = null;
            this.description = null;
        },
        onSelect_(id){
            this.setIssueCatalog(id);
            this.setIsReverseLogistic(true);
            this.$router.push({name:'loads.detail.issues.new', params: { load_id: this.$route.params.load_id }});
        },
        getSrc(code){
            return require('@/assets/images/reverselog/' + code + '.svg');
        },
        async onSubmit(validate) {
            if(!(await validate())){
                return;
            }
            this.callStoreIssue(this.description);
        },
        callStoreIssue(desc){
            let form = {
                type: null,
                description: desc,
                address: {},
                files: [],
                load_id: Number(this.$route.params.load_id),
                category: this.issueCategories[0].id,
                type: this.issue_selected
            }
            this.fetching = true;
            this.storeIssue(form)
                .then(result => {
                    this.getStatus({id : this.$route.params.load_id});
                    this.getLoad({id : this.$route.params.load_id});
                    this.getEvents({id : this.$route.params.load_id});
                    this.step = 3;
                })
                .catch(error => {
                    this.$root.$bvToast.toast(this.$t("Error while confirm delivery"), {
                        title: this.$t('Confirm Delivery'),
                        variant: 'danger',
                        solid: true
                    });
                })
                .finally(() => {
                    this.fetching = false
                });
        }
    },
    watch: {
        visible(newValue) {
            if(newValue && this.issueCategories.length === 0){
                this.fetching = true;
                this.getReverseLogistics({id : this.$route.params.load_id})
                    .then(data => {})
                    .catch(error => {
                        console.log("error", error);
                    })
                    .finally(() => {
                        this.fetching = false;
                    });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.subititle{
    letter-spacing: 0;
    line-height: 29px;
}
</style>
