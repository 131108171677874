<template>
    <div>
        <page-header :title="$t('Product detail')" :items="breadcrumb"/>
        <loading v-if="loading"></loading>
      <div v-else class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-xl-7">
                  <div class="product-detail">
                    <div class="row">
                      <div class="col-md-2" style="max-width: 100px">
                        <vue-perfect-scrollbar ref="loadList" class="scroll main-board" :settings="{ suppressScrollX: true, wheelPropagation: false }" @ps-y-reach-end="handleScroll">
                          <div
                              class="nav flex-column nav-pills mr-1"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                          >
                            <a
                                class="nav-link hover"
                                id="product-1-tab"
                                data-toggle="pill"
                                role="tab"
                                v-for="(image,index) in product.images"
                                @click="imageShow(index)"
                                :class="slide==index?'activeImg':''"
                            >
                              <img
                                  :src="image.path"
                                  alt
                                  class="img-fluid mx-auto d-block tab-img rounded"
                              />
                            </a>
                            <a
                                class="nav-link hover activeImg"
                                data-toggle="pill"
                                role="tab"
                            >
                              <img
                                  src="@/assets/images/product/product_general.png"
                                  alt
                                  class="img-fluid mx-auto d-block tab-img rounded opacity"
                              />
                            </a>
                          </div>
                        </vue-perfect-scrollbar>
                      </div>
                      <div class="col-md-10 col-9">
                        <div class="tab-content" id="v-pills-tabContent">
                          <div class="">
                            <b-carousel
                                style="text-shadow: 0px 0px 2px #000"
                                controls
                                indicators
                                img-width="00"
                                img-height="400"
                                v-model="slide"
                            >
                              <b-carousel-slide v-for="image in product.images" :key="'imagprod_'+image.id" >
                                <template #img >
                                  <img
                                      height="350" style="object-fit: cover;margin: auto"
                                      :src=image.path
                                  >
                                </template>
                              </b-carousel-slide>

                              <b-carousel-slide v-if="product.images.length===0" >
                                <template #img >
                                  <img
                                      height="350" style="object-fit: cover;margin: auto" class="opacity"
                                      src="@/assets/images/product/product_general.png"
                                  >
                                </template>
                              </b-carousel-slide>
                            </b-carousel>
                          </div>
                        </div>
                        <div class="row text-center mt-2">
                          <div class="col-sm-6">
                            <button
                                type="button"
                                class="btn btn-primary btn-block waves-effect waves-light mt-2 mr-1"
                            >
                              <i class="mdi mdi-cart mr-2"></i> Add to cart
                            </button>
                          </div>
                          <div class="col-sm-6">
                            <router-link v-can="'products.read'" :to="{name:'products.edit', params:{product_id: product.id}}" class="btn btn-outline-primary btn-block waves-effect waves-light mt-2 mr-1">
                              <i class="fa fa-edit mr-2"></i>Editar
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end product img -->
                </div>
                <div class="col-xl-5">
                  <div class="mt-4 mt-xl-3">
                    <a href="#" class="text-primary">{{ product.category.name | capitalize}}</a>
                    <h5 class="mt-1 mb-3">{{ product.name }}</h5>

                    <div class="d-inline-flex">
                      <div class="text-muted mr-3">
                        <span class="mdi mdi-star text-warning"></span>
                        <span class="mdi mdi-star text-warning"></span>
                        <span class="mdi mdi-star text-warning"></span>
                        <span class="mdi mdi-star text-warning"></span>
                        <span class="mdi mdi-star text-warning"></span>
                      </div>
                      <div class="text-muted">( 5 )</div>
                    </div>

                    <h5 class="mt-2">
                      ${{ product.price | money }}
                    </h5>
                    <p>{{$t("Max Price")}}: ${{product.max_price | money }}</p>
                    <p class="mt-3">{{ product.description | truncate(150, '...') }}</p>
                    <hr class="my-4" />
                    <h4 class="card-title">{{$t("Branch offices")}}</h4>
                    <ul>
                      <li v-for="(branchoffice,index) in product.branchoffice" :key="'prof'+index"><span class="font-weight-bold ">{{branchoffice.name}}</span> : {{branchoffice.price}}</li>
                    </ul>
                    <hr class="my-4" />

                    <div class="row">
                      <div v-if="product.features" class="col-md-6">
                        <div>
                          <h5 class="font-size-14">Feactures :</h5>
                          <ul class="list-unstyled product-desc-list">
                            <li :key="key" v-for="(feature, key) in product.features.features">
                              <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
                              {{ feature }}
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div v-if="product.tags" class="col-md-6">
                        <h5 class="font-size-14">Tags :</h5>
                        <ul class="list-unstyled product-desc-list">
                          <li :key="key" v-for="(tag, key) in product.tags.tags">
                            <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
                            {{ tag }}
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <!-- end row -->

              <div class="mt-4">
                <h5 class="font-size-14 mb-3">Product description:</h5>
                <div class="product-desc">
                  <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4">
                    <b-tab title="Description">
                      <div>
                        {{ product.description }}
                      </div>
                    </b-tab>
                    <b-tab title="Specifications">
                      <div class="table-responsive">
                        <table class="table table-nowrap mb-0">
                          <tbody>
                          <tr>
                            <th scope="row" style="width: 400px;">Category</th>
                            <td>{{ product.category.name | capitalize }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Brand</th>
                            <td>{{ product.brand | capitalize }}</td>
                          </tr>
                          <tr>
                            <th scope="row">SKU</th>
                            <td>{{ product.sku.toUpperCase() }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Condition</th>
                            <td>{{ $t(product.condition.charAt(0).toUpperCase() + product.condition.slice(1)) }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Type</th>
                            <td>{{ product.type | capitalize }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>

              <product-reviews v-show="false" :product_id="product.id" />
            </div>
          </div>
          <!-- end card -->
        </div>
      </div>
    </div>
    
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import ProductReviews from '@/views/ecommerce/ProductReviews'
    import VuePerfectScrollbar from "vue-perfect-scrollbar";
    import LoadingOverlay from "../../components/common/LoadingOverlay";

    export default {
        name:'ProductDetail',
        components:{
          LoadingOverlay,
            ProductReviews,
            VuePerfectScrollbar
        },
        mixins: [],
        data(){
            return {
                loading: false,
                 slide: 0,
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapState('products', ['product']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Product detail"),
                        to: {name:'products.detail'}
                    },
                    {
                        text: this.$route.params.product_id,
                        active: true
                    }
                ]
            },
        },
        created() {
          this.loading = true
            this.getProduct({id: this.$route.params.product_id})
                .then(data => {
                    this.loading = false
                })
        },
        methods: {
            ...mapActions('products', ['getProduct']),
          imageShow(index){
              this.slide = index
          },
          handleScroll(item){

          },
        },
    }
</script>

<style>
.carousel-control-prev, .carousel-control-next{
  background: rgba(0,0,0,.2);
  height: 50px;
  top: calc(50% - 25px);
}
.activeImg{
  border: 2px solid #109dea;
}
.hover:hover{
  cursor: pointer;
}
.main-board{
  height: calc(350px);
}
.opacity{
  opacity: .5;
}
</style>