<template>
    <div class="nopadding font-family-helvetica">
        <header-title :headband="true" :title="$t('Settings')" />
        <div class="card card-txp mx-4 pb-3">
            <div class="card-header font-weight-bolder font-size-16">
                <span v-if="step != 3">{{ $t('Customize upload recommendations') }}</span>
                <span v-else>{{ $t('Summary') }}</span>
                <span class="float-right font-size-13" v-if="step != 3">
                    <span class="step-bottom" :class="[step == 1 && 'border-primary']">
                        {{ $t('Step') }} <span class="text-primary">{{ step }}</span>
                    </span>&nbsp;
                    <span class="step-bottom" :class="[step == 2 && 'border-primary']">
                        de 2
                    </span>
                </span>
            </div>
            <div class="card-body p-0">
                <div class="row no-gutters" v-if="step != 3">
                    <div class="col-md-7 border-right">
                        <equipments-form v-if="step == 1" :addEquipments="addEquipments" :equipments="equipments" />
                        <routes-form v-if="step == 2" :setOrigins="setOrigins" :setDestinations="setDestinations"
                            :origins="origins" :destinations="destinations" />
                    </div>
                    <div class="col-md-5">
                        <loading class="middle" v-if="!loading" />
                        <equipments v-else :equipments="equipments" :removeEquipment="removeEquipment" />
                    </div>
                </div>
                <resume v-else :equipments="equipments" :origins="origins" :destinations="destinations" />
            </div>
        </div>
        <br>
    </div>
</template>


<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import HeaderTitleVue from '@/components/common/HeaderTitle.vue';
import EquipmentsForm from '@/views/recommendations/EquipmentsForm.vue';
import Equipments from '@/views/recommendations/Equipments.vue';
import RoutesForm from '@/views/recommendations/RoutesForm.vue';
import Resume from '@/views/recommendations/Resume.vue';

export default {
    name: "Recommendations",
    components: {
        HeaderTitle: HeaderTitleVue,
        EquipmentsForm,
        Equipments,
        RoutesForm,
        Resume
    },
    async created() {
        if (this.$route.path.indexOf('edit') > 1) {
            this.loading = false
            try {
                await this.getLoadRecommendations({ 'carrier': this.profile.id })
                this.equipments = this.catalogs.equipments_marketplace.filter(equipment => this.load_recommendation.equipments.includes(equipment.code))
                this.origins = this.catalogs.states.filter(state => this.load_recommendation.origins.includes(state.value))
                this.destinations = this.catalogs.states.filter(state => this.load_recommendation.destinations.includes(state.value))
                this.loading = true
            } catch (error) {
                console.log(error)
            }
        }
    },
    data() {
        return {
            equipments: [],
            origins: [],
            destinations: [],
            loading: true
        }
    },
    computed: {
        ...mapState('recommendations', ['fetching', 'step','load_recommendation']),
        ...mapState('profile', ['profile']),
        ...mapState('catalogs', ['catalogs'])
    },
    methods: {
        ...mapMutations('recommendations', ['setStep']),
        ...mapActions('recommendations',['getLoadRecommendations']),
        addEquipments(item) {
            this.equipments.push(item);
        },
        removeEquipment(indx) {
            this.equipments = this.equipments.filter((item, index) => index !== indx)
        },
        setOrigins(values) {
            this.origins = values;
        },
        setDestinations(values) {
            this.destinations = values;
        }

    }
}
</script>

<style scoped>
.font-family-helvetica {
    font-family: Helvetica;
}

.card-txp {
    /*height: 210px;*/
    border-radius: 15px;
    box-shadow: 1px 5px 3px #dcdcdc;
}

.card-header {
    border-radius: 15px 15px 0 0;
    background-color: white !important;
    line-height: 19px;
}

.w-10 {
    width: 10%;
}

.step-bottom {
    padding-bottom: 3px;
    border-bottom: 2.5px solid #DDD;
}

.middle {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
