export default (a) => {
  let lines = [];
  if (a.line_1) lines.push(a.line_1);
  if (a.line_2) lines.push(a.line_2);
  if (a.line_3) lines.push(a.line_3);
  if (a.city || a.state || a.postalCode) {
    let line = [];
    if (a.city) line.push(a.city);
    if (a.state) line.push(a.state);
    if (a.country) line.push(a.country);
    if (a.zip_code) line.push(a.zip_code);
    lines.push(line.join(", "));
  }
  return lines;
};

export const format = (a) => {
  let lines = [];
  if (a.line_1) lines.push(a.line_1);
  if (a.city || a.state || a.postalCode) {
    let line = [];
    if (a.city) line.push(a.city);
    if (a.state) line.push(a.state);
    if (a.postal_code) line.push(`C.P: ${a.postal_code}`);
    lines.push(line.join(", "));
  }
  return lines;
};

export const getState = (a) => {
  console.log(a, "aqu");
  return a;
};
