<template>
    <div>
        <page-header :title="edit ? $t('Edit route'): $t('New route')" />

        <div class="row justify-content-md-center">
            <div class="col-md-9">
                <validation-observer ref="observer" v-slot="observer">

                    <b-form @submit.stop.prevent="onSubmit(observer)">
                        <loading v-if="loading" />

                        <div v-if="$route.params.profile === 'admin'" class="card">
                            <div class="card-header">
                                <h6>{{$t('General')}}</h6>
                            </div>
                            <form-input
                                :title="$t('Type')"
                                :help="$t('Route type')"
                                v-model="form.type"
                                rules="required"
                                :options="types"
                                type="select"
                                :name="'type'"
                            />
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Carrier')}}</div>
                                        <div class="field-help">{{$t("The carrier assigned")}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="carrier_id"  v-slot="{ errors }">
                                            <carrier-input v-model="form.carrier" :disabled="loading"/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                          <form-input
                              title="TMS ID"
                              help=""
                              v-model="form.tms_id"
                              rules=""
                              type="text"
                              :name="'tms_id'"
                          />

                          <form-input
                              :title="$t('Name')"
                              :help="$t('The route name')"
                              v-model="form.name"
                              :rules="'required'"
                              type="text"
                              :name="'name'"
                          />
                          <form-input
                              :title="$t('Due date')"
                              help=""
                              v-model="form.expires_at"
                              :rules="''"
                              type="datetime"
                              :name="'expires_at'"
                              dateFormat="YYYY-MM-DD HH:mm:ss"
                          />
                            <form-input
                                    :title="$t('Has limit')"
                                    :help="$t('Enable if the route has limited loads')"
                                    v-model="form.has_limit"
                                    rules=""
                                    type="boolean"
                                    :name="'has_limit'"
                            />
                            <form-input
                                    v-if="form.has_limit"
                                    :title="$t('Límite')"
                                    :help="$t('Set the load limit')"
                                    v-model="form.limit"
                                    :rules="'integer|min_value:0'"
                                    type="number"
                                    :name="'limit'"
                                    :disabled="!form.has_limit"
                            />
                        </div>

                        <address-collection-form id="pickup_addresses" v-model="form.pickup_addresses" :collapsed="stopsCollapsed" :title="$t('Pickup addresses')"/>
                        <address-collection-form id="delivery_addresses" v-model="form.delivery_addresses" :collapsed="stopsCollapsed" :title="$t('Delivery addresses')"/>

                        <div class="card">
                            <div class="card-header">
                                <h6>{{$t('Technical information')}}</h6>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Configuration')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The truck configuration')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="configuration_id" rules="required" v-slot="{ errors }">
                                            <truck-configuration-input v-model="form.configuration" :disabled="loading"/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Truck type')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The truck type')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="type_id" rules="required" v-slot="{ errors }">
                                            <truck-type-input v-model="form.truck_type" :disabled="loading" />
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div  class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Trailer type')}}</div>
                                        <div class="field-help">{{$t('The trailer type')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="trailer_type_id"  v-slot="{ errors }">
                                            <trailer-type-input v-model="form.trailer_type" :disabled="loading" />
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div v-if="false" class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Size')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The truck size')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="size_id" rules="required" v-slot="{ errors }">
                                            <b-form-input :disabled="loading" v-model="form.size_id" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Weight')}}</div>
                                        <div class="field-help">{{$t('The load weight')}} (kg)</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="weight" rules="numeric" v-slot="{ errors }">
                                            <b-form-input :disabled="loading" v-model="form.weight" type="number" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>

                                        <div v-if="false" class="row">
                                            <div class="col-md-6">
                                                <validation-provider name="weight" rules="required|numeric" v-slot="{ errors }">
                                                    <b-form-input :disabled="loading" v-model="form.weight" type="number" placeholder=""></b-form-input>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="weight_unit" rules="required" v-slot="{ errors }">
                                                    <multiselect v-model="form.weight_unit" :options="weightUnits"
                                                                 placeholder="" select-label="" selected-label=""/>
                                                    <b-form-invalid-feedback >{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Dimensions')}}</div>
                                        <div class="field-help">{{$t('The load dimensions (meters)')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <validation-provider name="width" rules="numeric" v-slot="{ errors }">
                                                    <b-form-input :disabled="loading" v-model="form.width" type="number" ></b-form-input>
                                                    <b-form-text id="input-live-help">{{$t('Width')}}</b-form-text>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-4">
                                                <validation-provider name="height" rules="numeric" v-slot="{ errors }">
                                                    <b-form-input :disabled="loading" v-model="form.height" type="number"></b-form-input>
                                                    <b-form-text id="input-live-help">{{$t('Height')}}</b-form-text>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-4">
                                                <validation-provider name="length" rules="numeric" v-slot="{ errors }">
                                                    <b-form-input :disabled="loading" v-model="form.length" type="number"></b-form-input>
                                                    <b-form-text id="input-live-help">{{$t('Length')}}</b-form-text>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Container type')}}</div>
                                        <div class="field-help">{{$t('The container type of the load')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="container_type" rules="" v-slot="{ errors }">
                                            <multiselect v-model="form.container_type" :options="containers"
                                                         placeholder="" select-label="" selected-label=""/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <form-input
                                v-if="false"
                              :title="$t('Kilometers')"
                              help=""
                              v-model="form.distance"
                              rules="required|min_value:0"
                              type="number"
                              :name="'distance'"
                          />

                        </div>

                        <div class="card">
                            <div class="card-header">
                                <h6>{{$t('Carrier requirements')}}</h6>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Skills')}}</div>
                                        <div class="field-help">{{$t("Required carrier's skills")}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="carrier_skills" rules="" v-slot="{ errors }">
                                            <carrier-skills-input v-model="form.carrier_skills" :disabled="loading"/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <h6>{{$t('Service')}}</h6>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Load type')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t("The load type")}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="load_type_id" rules="required" v-slot="{ errors }">
                                            <load-type-input v-model="form.load_type" :disabled="loading"/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Commodity')}}</div>
                                        <div class="field-help">{{$t("The commodity")}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="commodity_id" rules="" v-slot="{ errors }">
                                            <commodity-input v-model="form.commodity" :disabled="loading"/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Consignee')}}</div>
                                        <div class="field-help">{{$t("The consignee")}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="consignee" rules="" v-slot="{ errors }">
                                                    <b-form-input :disabled="loading" v-model="form.consignee" type="text"></b-form-input>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Receiver')}}</div>
                                        <div class="field-help">{{$t("The receiver")}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="receiver" rules="" v-slot="{ errors }">
                                            <b-form-input  v-model="form.receiver" :disabled="loading" type="text"></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Additional information')}}</div>
                                        <div class="field-help">{{$t("Additional info like temperature or special requirements")}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="comments" v-slot="{ errors }">
                                            <b-form-textarea
                                                    v-model="form.comments"
                                                    placeholder=""
                                                    rows="3"
                                                    max-rows="15"
                                            ></b-form-textarea>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card" v-if="$route.params.profile === 'admin'">
                            <div class="card-header">
                                <h6>{{$t('Price')}}</h6>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Purchase price')}}</div>
                                        <div class="field-help"></div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="purchase_price" rules="" v-slot="{ errors }">
                                            <b-form-input id="purchase_price" v-model="form.purchase_price" type="number" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Price')}}</div>
                                        <div class="field-help"></div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="price" rules="" v-slot="{ errors }">
                                            <b-form-input id="price" v-model="form.price" type="number" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                          <div class="field-wrapper">
                            <div class="row align-items-center ">
                              <div class="col-md-6">
                                <div class="field-label">{{$t('Currency')}}<span class="field-required-badge"/></div>
                                <div class="field-help"></div>
                              </div>
                              <div class="col-md-6">
                                <validation-provider name="price" rules="required" v-slot="{ errors }">
                                  <currency-input id="currency" v-model="form.currency" />
                                  <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                </validation-provider>
                              </div>
                            </div>
                          </div>                        
                          
                        </div>


                        <div class="d-flex justify-content-end mb-4">
                            <button :disabled="loading" v-if="!edit" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew">{{$t('Save and create new')}}</button>
                            <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">
                              <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              {{$t('Save')}}
                            </button>
                        </div>

                    </b-form>
                </validation-observer>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';


    import formMixin from '@/mixins/formMixin';
    import Multiselect from 'vue-multiselect';
    import TrailerInput from '@/components/form/TrailerInput';
    import TruckTypeInput from '@/components/form/TruckTypeInput';
    import TrailerTypeInput from '@/components/form/TrailerTypeInput';
    import TruckConfigurationInput from '@/components/form/TruckConfigurationInput';
    import UserInput from '@/components/form/UserInput';
    import CarrierSkillsInput from "@/components/form/CarrierSkillsInput";
    import LoadTypeInput from "@/components/form/LoadTypeInput";
    import CommodityInput from "@/components/form/CommodityInput";
    import CountryInput from "@/components/form/CountryInput";
    import CurrencyInput from "@/components/form/CurrencyInput";
    import AddressForm from "@/components/form/AddressForm";
    import DateInput from "@/components/form/DateInput";
    import AddressCollectionForm from "../../components/form/AddressCollectionForm";
    import StopForm from "../../components/form/StopForm";
    import CarrierInput from '@/components/form/CarrierInput';
    import ShipperInput from '@/components/form/ShipperInput';
    import events from '@/constants/eventIcons';

    export default {
        name:'RouteForm',
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.from = from;
            });
        },
        components:{
            Multiselect,
            TrailerInput,
            TruckTypeInput,
            TrailerTypeInput,
            TruckConfigurationInput,
            UserInput,
            CarrierSkillsInput,
            LoadTypeInput,
            CommodityInput,
            CountryInput,
            AddressForm,
            DateInput,
            StopForm,
            CarrierInput,
            ShipperInput,
            CurrencyInput,
            AddressCollectionForm
        },
        mixins: [formMixin],
        data(){
            return {
                title: '...',
                edit: false,
                form: {
                    pickup_addresses:[{country:'MX'}],
                    delivery_addresses:[{country:'MX'}],
                    stops:[],
                    distance_unit: 'm',
                    weight_unit: 'kg',
                    carrier_skills: []
                },
                types:[
                    {text: this.$t('Agreement'), value: 'agreement'},
                    {text: this.$t('Template'), value: 'template'}
                ],
                stopsCollapsed : false,
                isFetchingTrailers: false,
                trailers: [],
                createNew: false,
                loading: false,
                from: null,
                addStops: false,
                status: Object.keys(events).map(item => ({text: this.$t(item), value: item})),
                fare: null,
                reject: false
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapGetters('catalogs',['weight_units','containerTypes']),
            weightUnits(){
                //return this.weight_units.map(item => item.value);
                return ['kg'];
            },
            containers(){
                return this.containerTypes.map(item => item.value);
            },
            minPrice(){
              if(this.fare){
                return this.fare.price * 0.8;
              }
              return 0;
            }
        },
        created() {
            if(this.$route.params.route_id){
                this.edit = true;
                this.loading = true;
                this.getRoute({id: this.$route.params.route_id})
                    .then(data=>{
                        let form = _.cloneDeep(data);
                        for(let i=0; i< form.stops.length;i++ ){
                            form.stops[i].collapse = true;
                        }
                        this.stopsCollapsed = true;
                        this.form = form;
                        this.loading = false;
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    });
            }else{
                // Use param filters
                if(this.$route.meta.paramFilters){
                    for(let key of this.$route.meta.paramFilters){
                        this.form[key] = this.$route.params[key];
                    }
                }
            }
        },
        methods: {
            ...mapActions('routes',['getRoute','storeRoute','updateRoute']),
            resetForm() {
                this.form = {
                    pickup_addresses:[{country:'MX'}],
                    delivery_addresses:[{country:'MX'}],
                    stops:[],
                    distance_unit: 'm',
                    weight_unit: 'kg',
                    carrier_skills: []
                };
                this.createNew = false;
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },
            getForm(){
              let form = _.cloneDeep(this.form);

              form.configuration_id = form.configuration.id;
              if(form.shipper){
                form.shipper_id = form.shipper.id;
              }
              if(form.carrier){
                form.carrier_id = form.carrier.id;
              }
              if(form.truck_type){
                form.truck_type_id = form.truck_type.id;
              }
              if(form.trailer_type){
                form.trailer_type_id = form.trailer_type.id;
              }
              if(form.truck){
                form.truck_id = form.truck.id;
              }
              if(form.load_type){
                form.load_type_id = form.load_type.id;
              }
              if(form.commodity){
                form.commodity_id = form.commodity.id;
              }
              let carrier_skills = [];
              for(let skill of form.carrier_skills){
                carrier_skills.push(skill.id);
              }
              form.carrier_skills = carrier_skills;
              delete form.configuration;
              delete form.truck_type;
              delete form.trailer_type;
              delete form.truck;
              delete form.load_type;
              delete form.commodity;
              delete form.shipper;
              delete form.carrier;
              return form;
            },
            saveAndCreateNew(){
                this.createNew = true;
                this.$refs.submit.click();
            },
            async onSubmit(observer) {
                if(!(await observer.validate()) || this.loading){
                    this.stopsCollapsed = false;
                    this.$bvToast.toast(this.$t('Please, check the form'), {
                        title: "Error",
                        variant: 'danger',
                        solid: true
                    });
                    return;
                }

                this.loading = true;

                if(this.edit){
                    this.updateRoute(this.getForm())
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            this.redirect();
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }else{
                    this.storeRoute(this.getForm())
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Created successfully'), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            if(this.createNew){
                                this.resetForm();
                            }else{
                                this.redirect();
                            }
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }
            },
            redirect(){
                if(this.from.name){
                    this.$router.back();
                }else{
                    this.$router.push({name: this.$route.meta.routes.list});
                }
            }
        }
    }
</script>

<style>

</style>
