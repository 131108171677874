<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <!--
                    <th class="text-center" style="width: 48px;">
                        <b-form-checkbox
                                v-model="selectAll"
                                name="checkbox-1"
                                button-variant="secondary"
                        >
                        </b-form-checkbox>
                    </th>
                    -->
                    <th class="text-center"  style="min-width: 100px;">{{$t('Title')}}</th>
                    <th class="text-center">{{$t('Type')}}</th>
                    <th class="text-center">{{$t('Required')}}</th>
                    <th class="text-center">{{$t('Updated at')}}</th>
                    <th class="text-center" style="min-width: 90px;max-width:100px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fetching">
                    <td colspan="100%">
                        <loading />
                    </td>
                </tr>
                <template v-for="item in list.results">
                    <tr :key="'field-'+item.id"  class="truck-row">
                        <!--
                        <td>
                            <b-form-checkbox
                                    v-model="selected"
                                    name="checkbox-1"
                                    :value="item.id"
                            >
                            </b-form-checkbox>
                        </td>
                        -->
                        <td class="text-center">
                            <router-link  :to="{name: edit, params:{field_id: item.id, form_id: formId}}">
                                {{item.title}}
                            </router-link>
                        </td>
                        <td class="text-center">
                             <span class="badge badge-outline-secondary">
                               {{$t('fields.'+item.type)}}
                            </span>
                        </td>
                        <td class="text-center">
                          <i class="far" :class="[item.required ? 'fa-check-circle text-success': 'fa-times-circle text-danger']"/>
                        </td>
                        <td class="text-center">{{item.updated_at | timestamp('YYYY-MM-DD')}}</td>
                        <td class="text-center">
                          <template>
                            <a @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-2">
                              <i v-b-tooltip.hover :title="$t('Delete')" class="far fa-trash-alt btn-icon"  />
                            </a>
                          </template>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div  v-if="list.total > 0"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    import Loading from "../common/Loading";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import Swal from "sweetalert2";

    export default {
        name: "FieldTable",
        mixins:[selectAllMixin],
        components: {
            'loading':Loading
        },
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            },
            edit: {
              type: String
            },
            formId: {
              type: String
            },
        },
        data(){
            return {
                page: 1,
                init: false,
                fetching: false,
            }
        },
        computed: {
            ...mapState('forms', ['fields']),
            ...mapState('profile',['profile']),
            list(){
                return this.fields
            }
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal) || this.init === false){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('forms',['getFields','deleteField']),
            fetchData(){
                this.fetching = true;
                this.getFields(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.fetching = false;
                        this.init = true;
                    })
            },
            confirmDelete(item){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteField({field_id: item.id, form_id: this.formId})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Field").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                        });
                            })
                            .catch(error =>{
                                console.log(error);
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .truck-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }
</style>
