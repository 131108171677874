<template>
    <div>
        <loading class="py-md-3" v-if="fetching" />
        <div class="row mt-3" v-else>
            <div class="col-12 mb-3">
                <router-link
                    v-can="'issue.create'"
                    v-b-tooltip.hover
                    :to="{name: 'loads.detail.returns.new'}"
                    class="btn btn-primary float-right text-uppercase px-4"
                    :class="[isAllShipmentsConfirmed ? 'disabled' : '']"
                >
                    <div class="font-size-13 font-weight-bold d-flex justify-content-center align-items-center">
                        <i class="ri-add-circle-line mr-1" />
                        {{$t('Add return')}}
                    </div>
                </router-link>
            </div>
            <div class="col-12 mt-3">
                <empty-results :title="$t('No returns created')" subtitle="" v-if="shipment_returns.length === 0" />
                <div v-else>
                    <shipment-return-card
                        v-for="(shipment, index) in shipment_returns.slice(start, end)"
                        :shipment="shipment"
                        :key="index"
                        :onUpdate="updateShipment"
                        :onDelete="deleteShipment"
                        :isEditable="isAllShipmentsConfirmed"
                        :shipper_external_id="getShipperExternalId(shipment.shipment_id)"
                        :total_quantity="getTotalQuantity(shipment.shipment_number, shipment.details[0])"
                    />
                    <div class="row">
                        <div class="col-6">
                            <b-pagination
                                v-model="page"
                                :total-rows="shipment_returns.length"
                                :per-page="per_page"
                                first-number
                                last-number
                                pills
                            />
                        </div>
                        <div class="col-6 text-right">
                            <p>{{$t('pagination', {from: start + 1, to: end, total: shipment_returns.length})}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="col-12"
                :class="[shipment_returns.length === 0 ? 'd-none' : '']"
                v-if="!isAllShipmentsConfirmed"
            >
                <button
                    class="btn btn-primary_v2 float-right text-uppercase px-4"
                    :class="[shipment_returns.length > 0 ? '': 'disabled']"
                    @click="loadConfirmation"
                >
                    <div class="font-size-13 font-weight-bold">{{$t('Confirm return')}}</div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Swal from "sweetalert2";
import ShipmentReturnCard from '@/components/shipmentreturn/ShipmentReturnCard.vue';
//import ConfirmModal from '@/components/modals/ConfirmModal.vue';


export default {
    name: "List",
    components: {
       ShipmentReturnCard,
    },
    data(){
        return {
            fetching: false,
            showModal: false,
            page: 1,
            per_page: 20,
            start: 0,
            end: 20
        }
    },
    methods: {
        ...mapActions('shipmentreturn', ['getLoadReturn', 'deleteShipmentReturn', 'getTypes', 'confirmLoadReturn', 'getShipmentsDetails']),
        ...mapActions('loads', ['getLoadShipments']),
        updateShipment(id){
            let params = {
                load_id: this.$route.params.load_id,
                shipment_return: id
            }
            this.$router.push({name: 'loads.detail.returns.edit', params });
        },
        deleteShipment(id){
            Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("Yes, delete it!"),
                cancelButtonText: this.$t("Cancel")
            }).then(result => {
                if(result.value){
                    this.fetching = true;
                    this.deleteShipmentReturn({id})
                        .then(result => {
                            this.$root.$bvToast.toast(this.$t('Deleted successfully'), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            this.callGetLoadReturn();
                        })
                        .catch(error => {
                            this.$root.$bvToast.toast(this.$t("Error al eliminar la devolución"), {
                                title: this.$t('Error'),
                                variant: 'danger',
                                solid: true
                            });
                        })
                        .finally(() => {
                            //this.fetching = false;
                        });
                }
            });
        },
        callGetLoadReturn(){
            this.fetching = true;
            this.getLoadReturn({load_id: this.load._id})
                .finally(() => {
                    this.fetching = false;
                });
        },
        loadConfirmation(){
            Swal.fire({
                title: this.$t("You will no longer be able to edit or add more returns")+", "+this.$t('Do you want to continue?'),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("Continue"),
                cancelButtonText: this.$t("Cancel")
            }).then(result => {
                if(result.value){
                    this.fetching = true;
                    this.confirmLoadReturn({load_id: this.load._id})
                        .then(result => {
                            this.$root.$bvToast.toast(this.$t('Confirmed successfully'), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            this.callGetLoadReturn();
                        })
                        .catch(error => {
                            this.fetching = false;
                            this.$root.$bvToast.toast(this.$t("Confirmed unsuccessfully"), {
                                title: this.$t('Error'),
                                variant: 'danger',
                                solid: true
                            });
                        })
                        .finally(() => {
                            //this.fetching = false;
                        });
                }
            });
        },
        getShipperExternalId(shipment_id){
            let find = this.load_shipments.find(e => e._id === shipment_id)
            return find ? find.shipper_external_id : ' - ';
        },
        getTotalQuantity(shipment_number, detail){
            const { code, lot } = detail
            if(!this.shipments_details.data) return 0;
            let find =  this.shipments_details.data.filter(e => (
                e.shipment_number === shipment_number &&
                e.details[0].code === code &&
                e.details[0].lot === lot
            ));

            if(find.length > 0){
                return find[0].details[0].quantity
            }
            return 0;
        }
    },
    computed: {
        ...mapState('loads', ['load', 'load_shipments']),
        ...mapGetters('shipmentreturn', ['load_return', 'shipments_details']),
        shipment_returns(){
            let { shipment_returns } = this.load_return;
            return shipment_returns ? shipment_returns : [];
        },
        isAllShipmentsConfirmed(){
            let { shipment_returns } = this.load_return;
            if(shipment_returns.length === 0) return false;
            return _.every(shipment_returns, ['status', 'confirmed']);
            // return false; // SHOW ALL
        },
    },
    created() {
        this.callGetLoadReturn();
        this.getTypes();
        this.getLoadShipments({ id: this.$route.params.load_id });
        this.getShipmentsDetails({id: this.load._id})
    },
    watch: {
        page(newValue, oldValue) {
            let current_end = newValue * this.per_page
            this.end = current_end
            this.start = current_end - this.per_page
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
