<template>
    <div class="nopadding overflow-x-hidden">
        <header-title :title="$t('Trucks')">
          <template v-slot:action-button>
            <div>
              <router-link v-can="'trucks.create'" v-b-tooltip.hover :to="{name: $route.meta.routes ? $route.meta.routes.add : 'trucks.add'}" class="btn btn-primary_v2 font_bold">
                <i class="far fa-plus"></i> {{$t('Create truck')}}
              </router-link>
            </div>
          </template>
          <template slot="buttons" v-if="['carrier','admin'].indexOf($route.params.profile) > -1 && !isDocumentModule">
            <div class="btn-group" role="group" aria-label="toolbar" v-if="false">
              <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getTrucks(filters)" type="button" class="btn btn-light">
                <i class="far fa-sync"></i>
              </button>
              <button v-can="'trucks.delete'" v-bind:disabled="selected.length === 0" v-b-tooltip.hover :title="$t('Delete')" @click="confirmDelete" type="button" class="btn btn-light">
                <i class="far fa-trash-alt"></i>
              </button>
              <button v-can="'trucks.create'" v-b-tooltip.hover :title="$t('Import')" @click="$refs.import.click()" type="button" class="btn btn-light">
                <i class="fa fa-upload"></i>
              </button>
              <button v-b-tooltip.hover :title="$t('Export')" type="button" class="btn btn-light" @click="download">
                <i class="fa fa-download"></i>
              </button>
              <a v-b-tooltip.hover :title="$t('Layout')" type="button" class="btn btn-light" :href="urlTemplate">
                <i class="far fa-file-spreadsheet"></i>
              </a>
            </div>
            <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
          </template>
        </header-title>
        <div class="row my-3">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-12 px-4">
                        <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
                            <template v-if="['carrier','admin'].indexOf($route.params.profile) > -1 && !isDocumentModule" v-slot:right>
                              <div class="btn-group" role="group" aria-label="toolbar">
                                <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getTrucks(filters)" type="button" class="btn btn-light">
                                  <i class="far fa-sync"></i>
                                </button>

                                <button v-can="'trucks.delete'" v-bind:disabled="selected.length === 0" v-b-tooltip.hover :title="$t('Delete')" @click="confirmDelete" type="button" class="btn btn-light">
                                  <i class="far fa-trash-alt"></i>
                                </button>
                                <button v-can="'trucks.create'" v-b-tooltip.hover :title="$t('Import')" @click="$refs.import.click()" type="button" class="btn btn-light">
                                  <i class="fa fa-upload"></i>
                                </button>
                                <button v-b-tooltip.hover :title="$t('Export')" type="button" class="btn btn-light" @click="download">
                                  <i class="fa fa-download"></i>
                                </button>
                                <a v-b-tooltip.hover :title="$t('Layout')" type="button" class="btn btn-light" :href="urlTemplate">
                                  <i class="far fa-file-spreadsheet"></i>
                                </a>
                              </div>
                              <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
                            </template>
                        </filter-bar>
                    </div>
                </div>
                <truck-table
                    v-model="selected"
                    :filters="filters"
                    v-on:page-change="pageChange"
                    :isDocumentModule="isDocumentModule"
                    :edit="$route.meta.routes ? $route.meta.routes.edit : null"
                />
                <b-modal no-close-on-backdrop
                    id="modal-carrier"
                    :title="$t('Select a carrier to continue')"
                    title-class="font-18"
                    cancel-variant="light"
                    :ok-title="$t('Upload')"
                    :cancel-title="$t('Cancel')"
                    :ok-disabled="!carrier"
                    v-on:ok="handleFile"
                    v-on:cancel="clearFile"
                    v-on:close="clearFile"
                >
                    <div>
                        <carrier-input v-model="carrier" />
                    </div>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import TruckTable from "@/components/tables/TruckTable";
    import FilterBar from "@/components/filters/FilterBar";
    import filterMixin from "@/mixins/filterMixin";
    import deleteManyMixin from "@/mixins/deleteMany";
    import CarrierInput from '@/components/form/CarrierInput';
    import HeaderTitle from "../../components/common/HeaderTitle.vue";

    export default {
        name:'Trucks',
        components:{
          HeaderTitle,
            TruckTable,
            FilterBar,
            CarrierInput
        },
        mixins: [filterMixin, deleteManyMixin],
        data(){
            return {
                tabs: [],
                currentTab: null,
                options: [],
                selected: [],
                isDocumentModule:false,
                carrier: null,
                urlTemplate: `${process.env.VUE_APP_STATIC_URL}/layouts/template_trucks.xlsx`
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapState('trucks', ['last_filters', 'trucks']),
            ...mapGetters('catalogs',['truckTypes','configuration']),
            ...mapState('accounts', ['carriers']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Trucks"),
                        active: true,
                        to: {name:'trucks.index'}
                    }
                ]
            },
        },
        created() {
            this.resourceName = this.$t("Trucks").toLowerCase();
            this.getCarriers({per_page:1000000}).finally(()=>{
              this.init();
            });

        },
        watch:{
            '$route': 'init'
        },
        methods: {
            ...mapActions('trucks',['exportTrucks','deleteManyTrucks','getTrucks','importTrucks']),
            ...mapActions('accounts',['getCarriers']),
            init(){

                let filters = {};
                if(this.$route.params.profile === 'carrier' && this.profile.roles.indexOf('admin') > -1){
                    filters = {carrier_id: this.profile.profile.id};
                }

                // Use meta filters
                if(this.$route.meta.paramFilters){
                    for(let key of this.$route.meta.paramFilters){
                        filters[key] = this.$route.params[key];
                    }
                }
                if(this.$route.meta.filters){
                    filters = {...filters, ...this.$route.meta.filters};
                }

                this.defaultFilters = filters;

                this.isDocumentModule = this.$route.meta.isDocumentModule ? this.$route.meta.isDocumentModule : false

                this.options = [
                    {
                        icon: 'far fa-key',
                        label: 'ID',
                        type: 'text',
                        filterProperty: 'id',
                    },
                    {
                        icon: 'far fa-key',
                        label: 'TMS ID',
                        type: 'text',
                        filterProperty: 'tms_id',
                    },
                    {
                        icon: 'far fa-sliders-h-square',
                        label: this.$t('Plate'),
                        type: 'text',
                        filterProperty: 'plate'
                    },
                    {
                        icon: 'far fa-sliders-h-square',
                        label: this.$t('Configuration'),
                        type: 'single',
                        filterProperty: 'configuration_id',
                        values: this.configuration,
                        valueType: 'string'
                    },
                    {
                        icon: 'far fa-truck-moving',
                        label: this.$t('Truck type'),
                        type: 'multiselect',
                        filterProperty: 'type_id',
                        values: this.truckTypes ? this.truckTypes.map(item => ({text: item.name, value: item.id})) : [],
                        valueType: 'number'
                    }
                ];

                if (this.profile.roles.indexOf('admin') > -1){
                  this.options.push({
                    icon: 'far fa-id-card-alt',
                    label: this.$t('Carrier'),
                    type: 'multiselect',
                    filterProperty: 'carriers',
                    values: this.carriers.results.length ? this.carriers.results.map(item => ({text: item.name, value: item.id})) : [],
                    valueType: 'number'
                  });
                }


                let toolbarFilters = {};
                for(let item of this.options){
                    if(this.$route.query.hasOwnProperty(item.filterProperty)){
                        toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
                    }
                }
                this.toolbarFilters = toolbarFilters;
            },
            download(){
                this.exportTrucks(this.filters)
                    .then(()=>{

                    })
                    .catch(error =>{

                    })

            },
            deleteMany(){
                return new Promise((resolve, reject)=>{
                    this.deleteManyTrucks({ids: this.selected.join(',')})
                        .then(data=>{
                            this.selected = [];
                            this.getTrucks(this.filters);
                            resolve(data);
                        })
                        .catch(error =>{
                            reject(error);
                        })
                })
            },
            clearFile(){
                this.$refs.import.value=null;
                this.carrier = null;
            },
            handleFile(){
                let payload= {template : this.$refs.import.files[0]};
                if(this.defaultFilters.carrier_id){
                    payload.carrier_id = this.defaultFilters.carrier_id;
                }else{
                    if(this.$route.params.profile ==='admin'){
                        if(this.carrier){
                            payload.carrier_id = this.carrier.id;
                        }else{
                            this.$bvModal.show('modal-carrier');
                            return;
                        }
                    }
                }
                this.importTrucks(payload)
                    .then(data=>{
                        Echo.private(`jobs.${data.job_id}`)
                            .listen('JobProcessed', (e) => {
                                this.getTrucks(this.filters);
                                Echo.leave(`jobs.${data.job_id}`);
                            });
                        this.$root.$bvToast.toast(this.$t("File uploaded successfully", {resource: this.resourceName}), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                                });

                    })
                    .catch(error =>{

                    })
                    .finally(()=>{
                        this.clearFile();
                    });

            }
        }
    }
</script>
