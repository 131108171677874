<template>
  <div class="mb-0">
    <div  class="row m-0">
      <div class="col-md-12 col-sm-12 p-4">
        <div class="row mb-4">
          <div class="col-6 col-md-3 item_data">
            <div class="field-wrapper border-bottom-none p-0">
              <div class="field-label">{{$t('Load type')}}</div>
              <div class="field-help">{{load.load_type ? load.load_type.name : '- -'}}</div>
            </div>
          </div>
          <div class="col-6 col-md-3 item_data">
            <div class="field-wrapper border-bottom-none p-0">
              <div class="field-label">{{$t('Configuration')}}</div>
              <div class="field-help">{{load.configuration ? load.configuration.name: '- -'}}</div>
            </div>
          </div>
          <div class="col-6 col-md-3 item_data">
            <div class="field-wrapper border-bottom-none p-0">
              <div class="field-label">{{$t('Truck type')}}</div>
              <div class="field-help"><i class="far fa-truck mr-2" />{{load.truck_type ? load.truck_type.name : '- -'}}</div>
            </div>
          </div>
          <div class="col-6 col-md-3 item_data">
            <div class="field-wrapper border-bottom-none p-0">
              <div class="field-label">{{$t('Weight')}}</div>
              <div class="field-help"><i class="far fa-weight mr-2" />{{load.weight}} {{load.weight_unit}}</div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row mb-4">
          <div class="col-6 col-md-3 item_data">
            <div class="field-wrapper border-bottom-none p-0">
              <div class="field-label">{{$t('Container type')}}</div>
              <div class="field-help"><i class="far fa-container-storage mr-2" />{{load.container_type ? load.container_type : '- -'}}</div>
            </div>
          </div>
          <div class="col-6 col-md-3 item_data">
            <div class="field-wrapper border-bottom-none p-0">
              <div class="field-label">{{$t('Commodity')}}</div>
              <div class="field-help">{{load.commodity ? load.commodity.name: '- -'}}</div>
            </div>
          </div>
          <div class="col-6 col-md-3 item_data">
            <div class="field-wrapper border-bottom-none p-0">
              <div class="field-label">{{$t('Temperature')}}</div>
              <div class="field-help"><i class="far fa-thermometer-half mr-2" />{{load.position ? `${load.position.temperature ? load.position.temperature:'- -'}` : '- -'}}</div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12 actions_options ">
            <button type="button" class="btn btn-outline-dark btn-sm padding-btn-small text-uppercase" @click="showIssues = true">
              <div class="d-flex justify-content-between px-2">
                <b>{{$t('Events')}}</b>
                <div class="ml-2"><span class="badge badge-pill badge-primary">{{issues.total}}</span></div>
              </div>
            </button>
            <button type="button" class="btn  btn-primary_v2 btn-sm padding-btn-small text-uppercase ml-4" @click="showIssueModal = true">
              <i class="fas fa-plus mr-2" /><b>{{$t('Event')}}</b>
            </button>
          </div>
        </div>
      </div>
    </div>
    <issues-list-modal :visible="showIssues"  :filters="{load_id: load.id}" v-on:close="onIssueListClose" />
    <issue-modal v-model="form" :load_id="load.id" :visible="showIssueModal" v-on:updateData="onModalClose" />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import LoadTimeline from '@/components/common/controltower/LoadTimeline'
import RouteMap from "@/components/common/RouteMap";
import IssueModal from '@/components/modals/EditIssue'
import IssuesListModal from '@/components/modals/IssuesModal'

export default {
  name: 'LoadPreview',
  components:{
    LoadTimeline,
    RouteMap,
    IssueModal,
    IssuesListModal
  },
  props:{
    load:{
      type: Object,
      required: true,
    },
    stops:{
      type: Array,
      default(){
        return [];
      }
    }
  },
  data(){
    return {
      form:{},
      showIssueModal: false,
      showIssues: false,
      shipments:[]
    }
  },
  computed:{
    ...mapState('issues',['issues'])
  },
  created(){
    let getIssues = this.getIssues({load_id: this.load.id, per_page: 100});

    Promise.all([getIssues])
  },
  methods:{
    ...mapActions('issues',['getIssues']),
    addIssue(){
      this.form = {load_id: this.load.id};
      this.showIssueModal = true;
    },
    onModalClose(){
      this.showIssueModal = false;
      this.fetchIssues();
    },
    onIssueListClose(){
      this.showIssues = false;
    },
    fetchIssues()
    {
      this.getIssues({load_id: this.load.id})
          .then(data =>{

          })
          .catch(error =>{

          });
    },
  }
}
</script>

<style  lang="scss">
@media (max-width: 992px) {
  .item_data{
    padding-top: 15px;
  }
  .actions_options{
    text-align: center!important;
  }
}

.actions_options{
  text-align: right;
}
</style>