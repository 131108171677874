<template>
    <div>
    <loading v-if="loading" />
        <div v-else class="row">
            <div class="col-md-12 my-3">
                <b-alert v-if="settlement" variant="success" show dismissible>
                    {{$t('The settlement has been sended.')}}
                </b-alert>
            </div>
            <div class="col-lg-12">
                <div class="row my-3">
                    <div class="col-md-6">
                        <h2 class="color_blue">{{ $t('Pre settlement')}}</h2>
                    </div>
                    <div class="col-md-6">
                        <div class="float-right" v-if="load.driver">
                            <button :disabled="settlement" type="button" @click="visible = true" class="btn btn-primary mr-2">
                                {{$t('Add bonus')}}
                            </button>
                            <button v-if="load.carrier.opeation ==='SPOT' && ['delivered','pod'].indexOf(load.status) > -1" :disabled="settlement" type="button" @click="confirmGetBonus" class="btn btn-primary mr-2">{{$t('Calculate bonus')}}</button>
                            <button v-if="!settlement && load.carrier.opeation ==='SPOT' && ['delivered','pod'].indexOf(load.status) > -1" type="button" @click="confirmSendSettlement" class="btn btn-primary mr-2">{{$t('Send settlement')}}</button>
                        </div>
                    </div>
                </div>
                <template v-if="false">
                    <div class="row pb-4 pt-4">
                        <div class="col-md-4 p-4">
                            <div class="row field-wrapper border-bottom-none ">
                            <div class="col-md-6 field-label">{{$t("Tractor initial Km.")}}</div>
                            <div class="col-md-6 field-help">{{ settlement.initial_km ? settlement.initial_km : '--'}}</div>
                            </div>
                            <div class="row field-wrapper border-bottom-none ">
                            <div class="col-md-6 field-label">{{$t("Tractor")}}</div>
                            <div class="col-md-6 field-help">{{ settlement.tractor ? settlement.tractor : '--'}}</div>
                            </div>
                            <div class="row field-wrapper border-bottom-none ">
                            <div class="col-md-6 field-label">{{$t("Tractor final Km.")}}</div>
                            <div class="col-md-6 field-help">{{ settlement.final_km ? settlement.final_km : '--'}}</div>
                            </div>
                            <div class="row field-wrapper border-bottom-none ">
                            <div class="col-md-6 field-label">{{$t("Traveled Km.")}}</div>
                            <div class="col-md-6 field-help">{{ settlement.km_traveled ? settlement.km_traveled : '--'}}</div>
                            </div>
                            <div class="row field-wrapper border-bottom-none ">
                            <div class="col-md-6 field-label">{{$t("Performance")}}</div>
                            <div class="col-md-6 field-help">{{ settlement.performance ? settlement.performance : '--'}}</div>
                            </div>
                            <div class="row field-wrapper border-bottom-none ">
                            <div class="col-md-6 field-label">{{$t("Cutoff Date")}}</div>
                            <div class="col-md-6 field-help">{{ settlement.created_at }}</div>
                            </div>
                            <div class="row field-wrapper border-bottom-none ">
                            <div class="col-md-6 field-label">{{$t("Settlement Date")}}</div>
                            <div class="col-md-6 field-help">{{ settlement.created_at }}</div>
                            </div>
                        </div>
                        <div class="col-md-8">
                        <div class="card p-4 h-100">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Elaborator name")}}</div>
                                        <div class="col-md-6 field-help">{{ settlement.elaborated_name }}</div>
                                    </div>
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Invoice")}}</div>
                                        <div class="col-md-6 field-help">{{ settlement.folio }}</div>
                                    </div>
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Status")}}</div>
                                        <div class="col-md-6 field-help">
                                        <span class="badge" style="height: fit-content;"
                                            :class="
                                                settlement.status === 'authorized' || settlement.status === 'settling' || settlement.status === 'settled'
                                                    ? 'badge-outline-success'
                                                    : settlement.status === 'authorized' === 'reject'
                                                    ? 'badge-outline-danger'
                                                    : 'badge-outline-primary'"
                                            >
                                            {{ $t(settlement.status) }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Travels")}}</div>
                                        <div class="col-md-6 field-help">1</div>
                                    </div>
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Total Salary")}}</div>
                                        <div class="col-md-6 field-help">${{ settlement.salary_operator | money }}</div>
                                    </div>
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Total to Pay")}}</div>
                                        <div class="col-md-6 field-help">${{ settlement.net_pay | money }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Advances")}}</div>
                                        <div class="col-md-6 field-help">${{ settlement.total_advances | money }}</div>
                                    </div>
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Deductions")}}</div>
                                        <div class="col-md-6 field-help">${{ settlement.total_deductions | money }}</div>
                                    </div>
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Perceptions")}}</div>
                                        <div class="col-md-6 field-help">${{ settlement.total_perceptions | money }}</div>
                                    </div>
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Expenses")}}</div>
                                        <div class="col-md-6 field-help">${{ settlement.verified_expenses | money }}</div>
                                    </div>
                                    <div class="row field-wrapper border-bottom-none ">
                                        <div class="col-md-6 field-label">{{$t("Bonus")}}</div>
                                        <div class="col-md-6 field-help">${{ settlement.total_bonuses | money }}</div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-md-9">
                        <div class="card text-dark bg-light mb-3 p-4">
                        <div class="row field-wrapper border-bottom-none pb-2 pt-0">
                            <div class="col-md-12 field-label">{{ settlement.driver.id }} {{ settlement.driver.name }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-9">
                            <div class="row field-wrapper border-bottom-none pb-0 pt-0">
                                <div class="col-md-6 field-label">{{$t("Total Salary")}} OP1</div>
                                <div class="col-md-6 field-help">${{ settlement.salary_operator | money }} {{settlement.currency}}</div>
                            </div>
                            <div class="row field-wrapper border-bottom-none pb-0 pt-0">
                                <div class="col-md-6 field-label">{{$t("Total to Pay")}} OP1</div>
                                <div class="col-md-6 field-help">{{ settlement.net_pay | money }} {{settlement.currency}}</div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div v-if="settlement.file" class="col-md-3">
                        <h5 class="text-center mb-0">{{$t("Download file")}}</h5>
                        <h6 class="text-primary text-center">completo</h6>
                        <div class="download_pdf_container">
                        <a target="_blank" :href="settlement.file">
                            <img width="60px" height="60px" src="@/assets/images/txp/common/pdf_donwload.svg" >
                        </a>
                        </div>
                        <a target="_blank" :href="settlement.file" class="download_link">{{$t("Download pdf")}}</a>
                    </div>
                    </div>
                </template>
                <create-edit-expense-modal ref="createEditExpenseModal" :hiddenDate="true" v-model="form" :visible="visible" v-on:close="visible = false" v-on:submit="onSubmit"  />
                
                <settlement-detail-table :concepts="concepts"/>

            </div>
        </div>
        
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import SettlementDetailTable from "@/components/tables/SettlementDetailTable";
import filterMixin from "@/mixins/filterMixin";
import CreateEditExpenseModal from "@/components/modals/CreateEditExpenseModal";
import Swal from "sweetalert2";

export default {
    name: 'Settlement',
    mixins: [filterMixin],
    components:{
        SettlementDetailTable,
        CreateEditExpenseModal,
    },
    data(){
        return {
            selected: [],
            options: [],
            form: {},
            visible: false,
            loading:true,
            fetching:false,
            concepts:[],
            expen:[]
        }
    },
    created () {
        this.init();
    },
    computed:{
        ...mapState('settlements', ['settlement']),
        ...mapState('loads', ['expenses','load']),
        ...mapState('telemetries', ['telemetry']),
        hasTelemetry() {
            if (this.telemetry && this.telemetry.assigned_distance && this.telemetry.real_distance && this.telemetry.performance)
                return true;
            else 
                return false;
        },
        hasPendingChargeVerifiable() {
            let items =  this.expen.filter((item) => item.status === 'pending' && item.settlement_catalog.is_verifiable);
            return items.length > 0
        }
    },
    methods:{
        ...mapActions('loads',['storeExpense','getExpenses', 'getBonus']),
        ...mapActions('telemetries', ['getTelemetry']),
        ...mapActions('settlements', ['sendSettlement','getSettlementByLoad','getConceptSettlement']),
        init(){
            let filters = {status: 'accepted,completed'};
            this.defaultFilters = filters;

            const getSettlementByLoad = this.getSettlementByLoad({id: this.$route.params.load_id});

            const getTelemetry = this.getTelemetry({id: this.$route.params.load_id});

            const getExpenses = this.getExpenses({load_id: this.$route.params.load_id}).then(data => this.expen = data.results);
            
            const getConceptSettlement = this.getConceptSettlement({id: this.$route.params.load_id})
                .then(data => {
                    this.concepts = data
                })
            

            Promise.all([getConceptSettlement,getSettlementByLoad,getTelemetry,getExpenses])
              .finally(() => {
                this.loading = false;
              });
           
        },
        onSubmit(){
            
            let extra_data = {load_id:this.$route.params.load_id, owner_id: this.$route.params.load_id, owner_type:'App\\Load', status:'accepted'}
            let form = {...this.form,...extra_data};

            this.storeExpense({load_id: form.load_id, form})
                .then(()=>{
                    this.visible = false;
                    this.$root.$bvToast.toast(this.$t('Created successfully'), {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true
                    });
                    this.getConceptSettlement({id: this.$route.params.load_id})
                        .then(data => {
                            this.concepts = data
                        })
                    this.resetModal()
                })
                .catch(error=>{
                    this.processServiceError(error);
                })
                .finally(()=>{
                });
        },
        confirmSendSettlement(){
            let message = this.$t("Are you sure to send settlement?");

            if (this.hasPendingChargeVerifiable) {
                message = this.$t("Are you sure to send settlement? There are verifiable cost pending!")
            }

            Swal.fire({
                title: message,
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, send it!"),
                cancelButtonText: this.$t("Cancel"),
                reverseButtons: true
            }).then(result => {
                if(result.value) {
                    if (this.expenses.total === 0) {
                        Swal.fire(
                                "Error",
                                this.$t("There are no concepts to liquidate, please create some!"),
                                "error"
                            );
                        return;
                    }

                    if (!this.hasTelemetry) {
                            Swal.fire(
                                "Error",
                                this.$t("There are no telemetry data, please create some!"),
                                "error"
                            );
                        return;
                    }

                    this.loading = true;
                    this.sendSettlement({load_id: this.$route.params.load_id})
                        .then(data=>{

                            Echo.private(`jobs.${data.job_id}`)
                                .listen('JobProcessed', (e) => {
                                    this.getSettlementByLoad({id: this.$route.params.load_id})
                                    Echo.leave(`jobs.${data.job_id}`);
                                });

                            this.$root.$bvToast.toast(this.$t("The settlement has been sended."), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                                });
                        })
                        .catch(error =>{
                            Swal.fire(
                                "Error",
                                this.$t("Something went wrong!"),
                                "error"
                            );
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
        confirmGetBonus(){
            Swal.fire({
                title: this.$t('Confirm'),
                text: this.$t("Are you sure to calculate bonus of the load?"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("Yes, continue"),
                cancelButtonText: this.$t("Cancel"),
                reverseButtons: true
            }).then(result => {
                if(result.value) {
                    this.getBonus({load_id: this.$route.params.load_id})
                        .then(data =>{
                            this.$root.$bvToast.toast(this.$t("The action was completed successfully"), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            this.getConceptSettlement({id: this.$route.params.load_id})
                                .then(data => {
                                    this.concepts = data
                                })
                        })
                        .catch(error =>{
                            Swal.fire(
                                "Error",
                                this.$t("Something went wrong!"),
                                "error"
                            );
                        })
                        .finally(()=>{
                            this.accepting = false;
                        });
                }
            });
        },
        resetModal(){
            this.$refs.createEditExpenseModal.createExpenseModal();
        },
    }
}
</script>
<style scoped>
  .download_pdf_container{
    display: flex;
    justify-content: center;
    border: 2px solid #eff2f7;
    padding: 1em;
    max-width: 200px;
    max-height: 160px;
    margin: auto;
    border-radius: 5px;
  }
  .download_link{
    display: block;
    color: black;
    font-weight: 900;
    margin: 1rem 0;
    text-align: center;
  }
  .color_blue{
    color: #00B3E6;
    }
</style>