<script>

import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
    name: 'LoadVin',
    components: {},
    data() {
        return {
            form: {
                vin: '',
            },
            carrierForm: {
                destinationCode: '',
            },
            isAddingNewVin: false,
            isEditingDestinationCode: false,
            loading: false,
            destinationCode: ''
        };
    },
    computed: {
        ...mapState('loads', ['load', 'loadVin', 'fetching']),
        ...mapState("ediCarrier", ["ediCarrier"]),
    },
    methods: {
        ...mapActions('loads', ['storeLoadVin', 'listLoadVin', 'deleteLoadVin']),
        ...mapActions('ediCarrier', ['storeEdiCarrierLocation']),
        async onSaveVin() {
            try {
                await this.storeLoadVin({ 'id': this.load._id, vin: this.form.vin.toUpperCase() });
                await this.listLoadVin();
                this.isAddingNewVin = false;
            } catch (error) {
                this.handleError(error);
            } finally {
                this.form.vin = '';
            }
        },
        async confirmDelete(item) {
            const result = await Swal.fire({
                title: this.$t('Are you sure?'),
                text: this.$t('You won\'t be able to revert this!'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: this.$t('Yes, delete it!'),
                cancelButtonText: this.$t('Cancel'),
            });
            if (!result.value) {
                return;
            }
            try {
                await this.deleteLoadVin(item.id);
                await this.listLoadVin();
            } catch (error) {
                this.handleError(error);
            }
        },
        handleError(error) {
            let msg = error && error.data && error.data.message;
            if (error && error.status === 422) {
                msg = 'Not a valid VIN';
            }
            this.$bvToast.toast(this.$t(msg), {
                title: 'Error',
                variant: 'danger',
                solid: true,
            });
        },
        async onSaveDestinationCode() {
            const destinationCode = this.carrierForm.destinationCode.trim()
            if (!destinationCode) {
                this.$bvToast.toast(this.$t("Destination Code is required"), {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return;
            }
            await this.storeEdiCarrierLocation(
                {
                    location_code: this.load.delivery.address.external_id,
                    destination_code: destinationCode,
                    location_type: "Dealer"
                }
            )
            this.destinationCode = destinationCode;
            this.isEditingDestinationCode = false;
        }
    },
    async mounted() {
        await this.listLoadVin();
        const locationConfiguration = this.ediCarrier.locations.find(x => x.location_code === this.load.delivery.address.external_id);
        if (locationConfiguration && locationConfiguration.destination_code) {
            this.destinationCode = locationConfiguration.destination_code;
            this.carrierForm.destinationCode = locationConfiguration.destination_code;
        } else {
            this.carrierForm.destinationCode = "";
            this.isEditingDestinationCode = true;
        }
    }
};
</script>

<template>
    <div class="mb-5">
        <div class="row">
            <div class="col-md-6">
                <h5 class="page-title">
                    <i
                        class="far mr-3 fa-shipping-fast"
                    />
                    <span>VIN <span>({{ $t('Vehicular Identification Number') }})</span></span>

                </h5>
            </div>

            <div class="col-12 d-flex align-items-center justify-content-start mb-2">
                <h6 class="mr-3 mb-0">{{ $t('Destination Code') }}: </h6>
                <template v-if="!isEditingDestinationCode">
                    <h6 class="mb-0">{{ destinationCode }}</h6> <i class="far fa-edit cursor-pointer ml-2"
                                             @click="isEditingDestinationCode = true" />
                </template>
                <template v-else>
                    <b-form>
                        <form-input type="text"
                                    name="destinationCode"
                                    layout="vertical"
                                    v-model="carrierForm.destinationCode"
                                    rules="required"
                        class="m-0 p-0"></form-input>
                    </b-form>
                    <i class="far fa-check cursor-pointer ml-3 mr-2" @click="onSaveDestinationCode"></i>
                    <i class="far fa-ban cursor-pointer" v-if="destinationCode" @click="isEditingDestinationCode=false"></i>
                </template>
                <button type="button" class="btn btn-primary ml-auto" @click="isAddingNewVin=true"
                        v-if="!isAddingNewVin && destinationCode">
                    {{ $t('Add new') }}
                </button>
            </div>

            <b-form class="w-100" @submit.stop.prevent="onSaveVin">
                <div class="form-fields" v-if="isAddingNewVin">
                    <form-input
                        type="text"
                        name="vin"
                        layout="vertical"
                        v-model="form.vin"
                        rules="required"
                        :placeholder="$t('Write here:')"
                        :title="$t('Add VIN number')"></form-input>
                </div>

                <loading v-if="fetching" class="w-100" />

                <div class="col-12" v-else-if="loadVin.length === 0">
                    <empty-results title="" :subtitle="$t('No items')" />
                </div>

                <div class="table-responsive" v-else>
                    <table class="table mb-0">
                        <thead>
                        <tr class="">
                            <th class="text-left">{{ $t('VIN number') }}</th>
                            <th class="text-left">{{ $t('Created at') }}</th>
                            <th class="text-left">{{ $t('Created by') }}</th>
                            <th class="text-center" style="min-width: 90px;max-width:100px;"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in loadVin" :key="item.id" class="">
                            <td class="text-left">
                                {{ item.vin }}
                            </td>
                            <td class="text-left">
                                {{ item.created_at | timestamp }}
                            </td>
                            <td class="text-left">
                                {{ $t(item.created_by) }}
                            </td>
                            <td class="text-center">
                                <a @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-4">
                                    <i class="far fa-trash-alt btn-icon" />
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="isAddingNewVin && !fetching"
                     class="field-wrapper d-flex justify-content-end align-items-center">
                    <button type="reset" class="btn btn-light mr-2" @click="form.vin='';isAddingNewVin=false">
                        {{ $t('Cancel') }}
                    </button>
                    <button :disabled="!form.vin" type="submit" class="btn btn-primary">{{ $t('Save') }}</button>
                </div>
            </b-form>

        </div>
    </div>
</template>

<style scoped>
.page-title {
    > span {
        font-size: 1.125rem;
        font-weight: 600;

        > span {
            font-size: 0.75rem;
            font-weight: 600;
        }
    }
}

.table-responsive {
    tbody {
        tr {
            td:first-child {
                letter-spacing: 0.125rem;
                font-weight: 600;
            }
        }
    }
}
</style>