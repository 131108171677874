<template>
    <div class="row">
        <div class="col-md-3">
            <b>ID:</b> {{ resource.id }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Brand')}}:</b>  {{ resource.brand ? resource.brand : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Model')}}:</b>  {{ resource.model ?  resource.model : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Year')}}:</b>  {{ resource.year ?  resource.year : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Plate')}}:</b>  {{ resource.plate ?  resource.plate : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Configuration')}}:</b>  {{ resource.configuration ?  resource.configuration.name : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Type')}}:</b>  {{ resource.type ?  resource.type.name : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Driver')}}:</b>  {{ resource.driver ?  resource.driver.name + ' ' + resource.driver.last_name : '--'  }}
        </div>
        <div class="col-md-3">
            <b>{{ $t('Status')}}:</b>  {{ resource.enabled ?  $t('enabled') : $t('disabled')  }}
        </div>
        <div class="col-md-3">
            <b>{{$t('Documents')}}:</b> {{ $t(resource.document_status) }}
        </div> 
    </div>
</template>

<script>

import {mapState} from 'vuex';

export default {
    name: "TruckInformation",
    computed: {
        ...mapState('requirements', ['resource']),
    }
}
</script>
