<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <!--
                    <th class="text-center" style="width: 48px;">
                        <b-form-checkbox
                                v-model="selectAll"
                                name="checkbox-1"
                                button-variant="secondary"
                        >
                        </b-form-checkbox>
                    </th>
                    -->
                    <th class="text-center">ID</th>
                    <th class="text-center">{{$t('Name')}}</th>
                    <th class="text-center">{{$t('Configuration')}}</th>
                    <th class="text-center">{{$t('External ID')}}</th>
                    <th class="text-center" style="min-width: 90px;max-width:100px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fetching">
                    <td colspan="100%">
                        <loading />
                    </td>
                </tr>
                <template v-for="item in list.results">
                    <tr :key="'truck-type-'+item.id"  class="truck-row">
                        <!--
                        <td>
                            <b-form-checkbox
                                    v-model="selected"
                                    name="checkbox-1"
                                    :value="item.id"
                            >
                            </b-form-checkbox>
                        </td>
                        -->
                        <td class="text-center">
                             {{item.id}}
                        </td>
                        <td class="text-center">
                           {{item.name}}
                        </td>
                        <td class="text-center">
                             {{$t(item.configuration ==='s' ? 'Simple':'Full')}}
                        </td>
                        <td class="text-center">
                             {{$t(item.external_id)}}
                        </td>
                        <td class="text-center">
                            <a @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-4">
                            <i class="far fa-trash-alt btn-icon"  />
                            </a>
                            <a @click="editItem(item)" href="javascript:void(0);" class="text-primary mr-4">
                                <i class="far fa-edit btn-icon"  />
                            </a>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div  v-if="list.total > 0"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    import Loading from "../common/Loading";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import Swal from "sweetalert2";

    export default {
        name: "TruckTypeTable",
        mixins:[selectAllMixin],
        components: {
            'loading':Loading
        },
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            },
        },
        data(){
            return {
                page: 1,
                init: false,
                truck: null,
                fetchingTruck: false,
            }
        },
        computed: {
            ...mapState('trucktypes', ['truck_types','fetching']),
            list(){
                return this.truck_types
            }
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal) || this.init === false){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('trucktypes',['getTruckTypes','deleteTruckType']),
            fetchData(){
                this.getTruckTypes(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.init = true;
                    })
            },
            confirmDelete(item){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteTruckType({id: item.id})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Truck type").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                        });
                            })
                            .catch(error =>{
                                console.log(error);
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            },
            editItem(item)
            {
                this.$emit('edit', item);
            }
        }
    }
</script>

<style scoped>
    .truck-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }
</style>
