<template>
    <div class="card card-txp">
        <div :class="[horizontal && 'row no-gutters']">
            <div :class="[horizontal && 'col-lg-3 col-md-2 col-sm-2']">
                <div class="card-header centered font-weight-bold bg-primary-linear-gradient rounded-top">
                    <div class="opacity-60 font-size-14"> {{$t('Next trip')}}: </div>
                </div>
            </div>
            <div style="margin-top: -0.7px;" :class="[horizontal && 'col-lg-4 col-md-4 col-sm-4 border-left']">
                <div class="card-header centered rounded-0 font-weight-bold font-size-18 bg-primary-linear-gradient">
                    <span class="text-white">{{ $moment().format('DD') }} </span> &nbsp;
                    <span class="text-yellow text-capitalize"> {{ $moment().format('MM') | human_date_month }} / </span> &nbsp;
                    <span class="text-white">{{ $moment().format('YYYY') }} </span>
                </div>
            </div>
            <div :class="[horizontal && 'col-lg-5 col-md-6 col-sm-6']">
                <div class="card-body">
                    <div v-if="loading">
                        <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                    </div>
                    <div v-else>
                        <div class="opacity-60 centered font-size-14 p-3" v-if="!total"> {{ $t('There are no upcoming trips') }} </div>
                        <div class="row" v-if="total">
                            <div class="col-1 p-1">
                                <button type="button" class="btn btn-primary btn-xs" @click="removeSlide" :disabled="active <= 0" v-if="total > 1"> < </button>
                            </div>
                            <div class="col-10">
                                <div
                                    v-for="(item, index) in loads"
                                    v-if="index == active"
                                    :key="index"
                                >
                                    <h5 class="card-title text-center font-weight-bold font-size-16">{{item.pickup_city | truncate(13, '')}} - {{item.delivery_city | truncate(13, '')}}</h5>
                                    <div class="font-size-12 d-flex justify-content-between mt-3">
                                        <span class="font-size-12">ID: {{item.id}}</span>
                                        <router-link :to="{name:'loads.detail.general', params:{load_id: item.id}}">
                                            <a href="#" class="font-weight-bold font-size-12">{{$t('Show Detail')}}</a>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1 p-1">
                                <button type="button" class="btn btn-primary btn-xs" @click="addSlide" :disabled="active+1 >= total" v-if="total > 1"> > </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: 'UpcomingLoads',
    props: {horizontal: Boolean},
    data(){
        return {
            loading: false,
            loads: [],
            total: 0,
            active: 0
        }
    },
    created(){
        this.loading = true;
        this.getUpcomingLoads({}).then(data => {
            this.loads = _.cloneDeep(data.results)
            this.total = _.cloneDeep(data.total)
        }).catch(() => {

        }).finally(() => {
            this.loading = false;
        });
    },
    methods: {
        ...mapActions('loads', ['getUpcomingLoads']),
        addSlide() {
            this.active++
        },
        removeSlide() {
            this.active--
        }
    }
}
</script>

<style scoped>
    .card-header{
        height: 100%;
    }
    .card-txp {
        border-radius: 15px;
        box-shadow: 1px 5px 3px #dcdcdc;
    }
    .centered{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-xs {
        padding: .4rem;
        font-size: .7rem;
        line-height: .5;
        border-radius: .8rem;
        width: 25px;
        height: 25px;
    }
</style>
