import axios from "../../api/client";
import i18n from "../../i18n";
import moment from 'moment-timezone';

import {localize} from "vee-validate";
import en from "@/locales/validate/en.json";
import es from "@/locales/validate/es.json";
 
// initial state
export const state = () => ({
    profile : null,
    requirements : null,
    show_control_tower_filter:false
});

// getters
export const getters = {
    userCan: (state)=>(permission)=>{
        if (!state.profile.enabled) return ['loads.read'].indexOf(permission) > -1;
        if(Array.isArray(permission)){
            return permission.some(item => state.profile.permissions.indexOf(item) >=0);
        }
        return state.profile.permissions.indexOf(permission) > -1;
    },
    timezone: state =>{
        return state.profile ? state.profile.preferences.timezone : 'UTC';
    },
    getProfileStatus: (state) => state.profile,
};

// actions
export const actions = {
    getProfile({commit}){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/me')
                .then(response=>{
                    i18n.locale = response.data.preferences.locale;
                    moment.locale(response.data.preferences.locale);
                    commit('setProfile', response.data);

                    switch (response.data.preferences.locale) {
                        case "en":
                            localize("en", en);
                            break;
                        case "es":
                            localize("es", es);
                            break;
                        default:
                            localize("es", es);
                            break;
                    }

                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateMe({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put('/api/v2/me', payload)
                .then(response=>{
                    commit('setProfile', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getProfileActions({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/user/get_user_status', payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updatePreferences({commit, dispatch}, payload){
        return new Promise((resolve, reject) => {
            axios.put('/api/v2/preferences', payload)
                .then(response=>{
                    dispatch('getProfile');
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updatePassword({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put('/api/v2/security', payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTokens({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('/oauth/tokens', payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    sendEmailVerification({commit}){
        return new Promise((resolve, reject) => {
            axios.post('/email/verification-notification')
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getPermissionsByRole({commit},payload){
        return new Promise((resolve, reject) => {
            axios.post('/api/v2/permisions/'+payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateAvatar({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post('/api/v2/me/avatar', payload)
                .then(response=>{
                    commit('setProfile', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    toggleControlTowerFilter({commit,state}, payload){
        let status = _.cloneDeep(state.show_control_tower_filter)
        commit('setShowControlTowerFilter', !status);
    },
    setControlTowerFilter({commit}, payload){
        commit('setShowControlTowerFilter', payload);
    },

};

// mutations
export const mutations = {
    setProfile(state, data){
        state.profile = data;
    },
    setRequirements(state, data) {
        state.requirements = data;
    },
    setShowControlTowerFilter(state, data) {
        state.show_control_tower_filter = data;
    }

};

