
<empty-results />
<template>
    <div>
        <div v-show="stops.length < 1">
            <empty-results :subtitle="$t('This shipment does not have a checklist')"/>
        </div>
        <div class="row mt-4" v-show="stops.length > 0"> 
            <div class="col-md-8 offset-md-2">
                <div class="card" :key="'pod-stop'+stop.id" v-for="(stop,idx) in stops">
                    <div class="card-header">
                        <h6>{{$t('Stop')}} {{stop.order}}</h6>
                        <span>{{stop.address | address-format}}</span>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-center flex-wrap" v-show="stop.gallery.length > 0">
                            <a
                                    v-for="(item, index) in stop.gallery"
                                    :key="index"
                                    @click="() => showImg(idx, index)"
                            >
                                <div class="img-fluid">
                                    <img :src="`${item}`" alt width="120" height="120" style="object-fit: cover;" />
                                </div>
                            </a>
                        </div>
                        <div v-show="stop.gallery.length < 1">
                            <empty-results :subtitle="$t('There are not information')"/>
                        </div>
                        <vue-easy-lightbox
                                :visible="stop.visible"
                                :index="index"
                                :imgs="stop.gallery"
                                @hide="stop.visible = false"
                        ></vue-easy-lightbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import VueEasyLightbox from "vue-easy-lightbox";

    export default {
        name: "POD",
        components:{
            VueEasyLightbox
        },
        data(){
            return {
                stops: [],
                visible: false,
                index: 0, // default: 0
            }
        },
        computed:{
            ...mapState('loads',['checklist']),
        },
        created() {
            if(this.checklist.length > 0){
                this.setStops(this.checklist);
            }
            this.getAllChecklist({id: this.$route.params.load_id})
                .then(data=>{
                    this.setStops(data);
                })
                .catch(error =>{
                })
                .finally(()=>{
                });
        },
        methods:{
            ...mapActions('loads',['getAllChecklist']),
            setStops(data){
                let checklist = _.cloneDeep(data);
                let stops = [];
                for(let form of checklist){
                    let _stop = stops.find(item => item.id === form.owner.id);
                    let pod = form.form.fields.filter(item => item.type === 'pod');
                    if(!_stop && pod.length > 0){
                        stops.push(form.owner);
                    }
                }

                this.stops = stops.map(stop => {
                    let gallery = [];
                    let items = checklist.filter(item => item.owner.id === stop.id);
                    for(let form of items){
                        for(let field of form.form.fields){
                            if(field.type === 'pod' && field.answer){
                               gallery =  gallery.concat(field.answer)
                            }
                        }
                    }
                    return {
                        ...stop,
                        visible: false,
                        gallery: gallery
                    }
                })
            },
            showImg(stopIndex, index) {
                this.index = index;
                let stops = _.cloneDeep(this.stops);
                stops[stopIndex].visible = true;
                this.stops = stops;
            }
        }
    }
</script>

<style scoped>

</style>
