<template>
  <div>
    <page-header  :title="$t($route.meta.title)"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-12">
            <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
              <template v-slot:right>
                <div class="btn-group" role="group" aria-label="toolbar">
                  <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getOperationalDeductions(filters)" type="button" class="btn btn-light">
                    <i class="far fa-sync"></i>
                  </button>
                  <router-link v-b-tooltip.hover :title="$t('Add')" :to="{name: $route.meta.routes.add }" class="btn btn-light">
                      <i class="far fa-plus"></i>
                  </router-link>
                </div>
              </template>
            </filter-bar>
          </div>
        </div>

        <operational-deduction-table v-model="selected" :filters="filters" v-on:page-change="pageChange" />

      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import OperationalDeductionTable from "@/components/tables/OperationalDeductionTable";

export default {
  name: "OperationalDeductions",
  components: {
    FilterBar,
    OperationalDeductionTable
  },
  mixins: [filterMixin],
  data(){
    return {
      selected: [],
      options: [],
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapState('operationaldeductions', ['operational_deductions']),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('operationaldeductions',['getOperationalDeductions']),
    init(){
      this.options = [
        {
          icon: 'far fa-key',
          label: 'ID',
          type: 'text',
          filterProperty: 'id',
        }
      ];

    }
  }
}
</script>

<style scoped>

</style>