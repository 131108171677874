<template >
    <div v-if="step == 1">
        <address-picker-map ref="address-components" :id="'origin'" :formProp="origin.address"
            @addressPickerBack="$emit('setStep', 1)" @addressPickerNext="saveAddress" />
    </div>
    <div v-else-if="step == 2">
        <address-form :id="'pickup-form'" v-model="form.address" @addressFormBack="backStep"
            @addressFormNext="confirmAddress" :formProp="origin.address" />
    </div>
    <div v-else-if="step == 3">
        <origin-calendar-view  :id="'pickup-date'" :originDate="originDate" @calendarBack="backStep"
                               @calendarNext="setDate" />
<!--        <calendar-picker :id="'pickup-date'" :originDate="originDate" @calendarBack="backStep"
            @calendarNext="setDate" />-->
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
/**
 * Formulario para las direcciones
 */
import AddressForm from '@/components/common/AddressForm.vue';
import AddressPickerMap from '@/components/common/AddressPickerMap.vue';
import CalendarPicker from '../CalendarPicker.vue';
import OriginCalendarView from "./OriginCalendarView.vue";

/**
 * Esta view se encarga de agregar información de la dirección de entrega al store
 *
 * Este componente solo recupera y setea los valores del state origin de marketplace,
 * renderiza un AddressPickerMap, FormAddress y CalendarPicker, es el paso 2 del wizard
 * de la creacion de envios FTL
 */
export default {
    name: "OriginView",
    components: {
      OriginCalendarView,
        AddressForm,
        CalendarPicker,
        AddressPickerMap
    },
    data() {
        return {
            step: 1,
            form: {},
            explaind: false,
            createNew: false,
            loading: false,
            stopsCollapsed: false,
            originDate: null
        }
    },
    computed: {
        ...mapGetters('marketplace', ['origin']),
    },
    created() {
        this.form = this.origin;
        this.originDate = this.origin.date
    },
    methods: {
        ...mapMutations('marketplace', ['setOriginAddress', 'setOriginDate', 'setDestinationDate', 'setOriginTypeAndStopNumber', 'validateOrigin']),
        /**
         *  Esta funcion se ejecuta dentro de las funciones para almacenar
         *  valores en el store de la aplicacion
         *
         * @param {Number} step Paso para setear
         */
        setComponetStep(step) {
            this.step = step;
        },
        /**
         * Funcion de control de retroceso de los steps internos de la view
         */
        backStep() {
            this.step--;
        },
        /**
         * Cuando el usuario selecciona un punto en el mapa, mueve el icono o ingresa una direccion
         * en la caja de busqueda, la informacion obtenida del api de google y el api interna
         * se almacena en el store
         *
         * @param {Object} address Objeto con la direccion
         * @see /src/state/modules/marketplace.js
         */
        saveAddress(address) {
            if (address != this.origin.address) {
                this.setDestinationDate({
                    schedule_from: null,
                    schedule_to: null,
                });
            }
            this.setOriginAddress(_.cloneDeep(address));
            this.setComponetStep(2);
        },
        /**
         * Cuando el usuario confirma la direccion directo desde el formulario
         * se ejecuta esta funcion y se almacenan en el store los datos recogidos
         * desde los inputs
         *
         * @param {Object} address Objeto con la direccion
         * @see /src/state/modules/marketplace.js
         */
        confirmAddress(address) {
            this.setOriginAddress(address);
            this.setOriginTypeAndStopNumber();
            this.setComponetStep(3);
        },
        /**
         * Una vez que el usuario selecciona una fecha de recoleccion, se setean los datos
         * seleccionados en el store
         *
         * @param {Object} date Objeto con los datos de la fecha de la recoleccion
         */
        setDate(date) {
            this.setOriginDate(date);
            this.validateOrigin();
            this.$emit('setStep', 3);
        }
    },
    watch: {
        step(newValue) {
            switch (newValue) {
                case 1:
                    this.$emit('setTitle', 2)
                    break;
                case 2:
                    this.$emit('setTitle', 90)
                    break;
                case 3:
                    this.$emit('setTitle', 91)
                    break;
            }
        }
    }
}
</script>
<style lang="scss">

</style>
