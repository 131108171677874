import axios from "../../api/client";


// initial state
export const state = () => ({
    sellers : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    seller: null,
    last_filters: {},
    fetchingOrders: false,
    orders: {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    }
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchSellers({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/sellers', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getSellers({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/sellers',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setSellers', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeSeller({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/sellers`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getSeller({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/sellers/${params.id}`)
                .then(response=>{
                    commit('setSeller', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateSeller({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/sellers/${payload.id}`, payload)
                .then(response=>{
                    let items = _.cloneDeep(state.sellers);
                    let index = items.results.findIndex(item => item.id === response.data.id);
                    if(index > -1){
                        items.results[index] = response.data;
                        commit('setSellers', products);
                    }
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteSeller({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/sellers/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteManySellers({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/sellers/bulk`,{params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getOrders({commit}, params){
        commit('setFetchingOrders', true);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/sellers/${params.seller_id}/orders`,{params: params})
                .then(response=>{
                    commit('setOrders', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetchingOrders', false);
                });
        });
    },
};

// mutations
export const mutations = {
    setSellers(state, data){
        state.sellers = data;
    },
    setSeller(state, data){
        state.seller = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    setFetchingOrders(state, data){
        state.fetchingOrders = data;
    },
    setOrders(state, data){
        state.orders = data;
    }
};

