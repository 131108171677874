export default (data, type)=>{
    if(type === 'string'){
        return String(data);
    }
    if(type === 'boolean'){
        return Boolean(data);
    }
    if(type === 'number'){
        return Number(data);
    }
    return data;
}
