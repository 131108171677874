<template>
    <div class="field-wrapper">
        <div class="row align-items-center ">
            <div :class="[layout ==='horizontal' ?'col-md-6':'col-md-12']">
                <div class="field-label">{{title}}</div>
                <div class="field-help">{{$t("Select or search an address")}}</div>
            </div>
            <div :class="[layout ==='horizontal' ?'col-md-6':'col-md-12']">
            <validation-provider :name="name" :rules="rules"  v-slot="{ errors }">
                <multiselect v-model="selected" :disabled="disabled" :multiple="multiple" :options="addresses" open-direction="bottom" :close-on-select="true"
                            :clear-on-select="false" :preserve-search="true"
                            track-by="id" label="plate" :placeholder="$t('Select')" select-label="" :selected-label="$t('Selected')"
                            :searchable="true" :loading="isLoading" :internal-search="defaultOptions.length > 0" @search-change="onChange"
                >
                    <template slot="singleLabel" slot-scope="props">
                        <span class="option__title">{{ props.option | address-format }}</span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <span class="option__title">{{ props.option | address-format }}</span>
                    </template>
                </multiselect>
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
            </validation-provider>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'

export default {
    name:'AddressInput',
    components: {
        Multiselect
    },
    props: {
        filters: {
            type: Object,
            default(){return {}}
        },
        value:{
            required: true
        },
        multiple:{
          type: Boolean,
          default(){
            return false
          }
        },
        disabled:{
            type : Boolean,
            default(){
                return false;
            }
        },
        defaultOptions: {
            type: Array,
            default(){
                return [];
            }
        },
        title:{
            type: String,
            default(){return null}
        },
        index:{
            type: Number,
            default(){return 0}
        },
        layout:{
            type: String,
            default(){
            return 'horizontal'
            }
        },
        help:{
            type: String,
            default(){return ''}
        },
        placeholder:{
            type: String,
            default(){return ''}
        },
        type:{
            type: String,
            default(){return 'text'}
        },
        rules:{
            type: String,
            default(){return ''}
        },
        name: {
            type: String,
        },
    },
    data(){
        return {
            selected : null,
            options: [],
            isLoading: false,
            query: null
        }
    },
    computed: {
        localFilters(){
            if(this.query)
                return {...this.filters, search: this.query};
            return this.filters;
        },
        addresses(){
            return this.defaultOptions.length > 0 ? this.defaultOptions : this.options;
        }
    },
    watch:{
        selected: {
            deep:true,
            handler(newValue, oldValue){
                this.$emit('input', newValue);
                this.$emit('change', newValue,this.index);
            }
        },
        value: {
            deep:true,
            handler(newValue){
                this.selected = newValue;
            }
        },
      multiple:{
        type: Boolean,
        default(){
          return false
        }
      }
    },
    created(){
        this.selected = this.value;
        this.doSearch = _.debounce(this.fetchData, 500);
        this.fetchData();
    },
    methods: {
        ...mapActions('shippinglocations', ['getShippingLocationg']),
        onChange(query){
            this.query = query;
            this.doSearch();
        },
        fetchData(){
          this.getShippingLocationg(_.cloneDeep(this.localFilters))
            .then(data=>{
                this.options = data.results;
            })
            .catch(error =>{

            })
        }
    }
}
</script>
