<template>
  <empty-results v-if="!images.length"></empty-results>
  <div class="gallery" v-else>
    <div class="" style="min-height: 150px">
      <b-carousel id="carousel-fade"
                  style="text-shadow: 0px 0px 2px #000"
                  controls
                  v-model="currentIndex"
                  img-height="480"
      >
        <b-carousel-slide :key="index+'-carousel-img'" v-for="(image,index) in images">
          <template #img >
            <img @click="showSingle(image)"
                 height="300" style="object-fit: cover;margin: auto"
                 :src=image.path
                 class="cursor-pointer"
            >
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="gallery_list mt-5">
      <div class="gallery_list_item" :class="index==currentIndex?'active':''" :key="image.id" v-for="(image,index) in images" @click="activeImage(index)" >
        <img height="50px" width="40px" style="object-fit: cover" :key="index" :src="image.path"/>
      </div>
    </div>

    <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: 'GalleryComponent',
  components:{
    VueEasyLightbox,
  },
  data() {
    return {
      currentIndex: 0,
      visible: false,
      imgs: "",
    }
  },

  props:{
    images:{
      type:Array
    }
  },

  created: function() {

  },

  methods: {
    showSingle(file_data) {
      this.imgs = file_data.path;
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    activeImage: function(index) {
      this.currentIndex = index
    }
  },

  computed: {
    currentImg: function() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    }
  }

}
</script>

<style >


.gallery_list{
  display: flex;
  width: 100%;
}
.gallery_list_item{
  margin-right: 10px;
  border: 2px solid white;
  transition: all .5s;
  opacity: .6;
  border-radius: 5px;
}
.gallery_list_item:hover{
  cursor: pointer;
  opacity: 1;
  border: 2px solid #2bb3e7;
}
.gallery_list_item.active{
  border: 2px solid #2bb3e7;
  opacity: 1;
}

.carousel-control-prev, .carousel-control-next{
  background: rgba(0,0,0,.2);
  height: 50px;
  top: calc(50% - 25px);
}

.carousel-item {
  text-align: center;
  max-height: 400px;
}

</style>
