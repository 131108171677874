<template>
  <transition name="fade">
    <div class="sidebar_filter_control_tower shadow-lg" v-show="show_control_tower_filter">
      <div class="close_item text-center py-2 cursor-pointer " @click="hidden">
        <h4 class="card-title mb-0">Cerrar <i class="fa fa-chevron-up"></i> </h4>
      </div>
      <div class="content_item px-5 pt-3">
        <div class="filters_item">
          <h4 class="card-title text-primary font-weight-bold">Filtros:</h4>
          <div class="filter_container">
            <div v-for="(item,index) in filters" :key="`fi-${index}`" class=" badge badge-outline-primary filter_tag">
              <div class="text-left ">
                <span class="font-weight-bold font-size-8  ">{{$t(index)  | uppercase }}</span>
                <p class="mb-0"> {{ item.value | uppercase }} </p>
              </div>
              <i class="fa fa-times cursor-pointer" @click="removeFilter(index)" />
            </div>
          </div>
        </div>
        <div class="input_filter_container mt-3">


          <div class="input_filter_item">
            <resource-input
                :title="$t('Carrier')"
                v-model="form.carrier"
                name="category_id"
                :placeholder="$t('Select an option')"
                label="name"
                :fetch-method="fetchCarriers"
                layout="vertical"
                :filters="{id:query.carrier}"
                :autoSelectFirst="false"
                :border="false"
            />
            <resource-input
                :title="$t('Shipper')"
                v-model="form.shipper"
                name="shipper_id"
                :placeholder="$t('Select an option')"
                label="name"
                :fetch-method="fetchShippers"
                layout="vertical"
                :autoSelectFirst="false"
                :filters="{id:query.shipper}"
                :border="false"
                :multiple="true"
            />
          </div>
          <div class="input_filter_item">
            <resource-input
                :title="$t('Truck')"
                v-model="form.truck"
                name="category_id"
                :placeholder="$t('Select an option')"
                label="plate"
                :fetch-method="getTrucks"
                :filters="{id:query.truck}"
                layout="vertical"
                :autoSelectFirst="false"
                :border="false"
            />
            <resource-input
                :title="$t('Trailer')"
                v-model="form.trailer"
                name="category_id"
                :placeholder="$t('Select an option')"
                label="plate"
                :fetch-method="fetchTrailers"
                :filters="{id:query.trailer}"
                layout="vertical"
                :autoSelectFirst="false"
                :border="false"
            />
          </div>
          <div class="input_filter_item">
            <resource-input
                :title="$t('Pickup')"
                v-model="form.pickup"
                :filters="{id:query.pickup}"
                name="category_id"
                :placeholder="$t('Select an option')"
                label="line_1"
                :fetch-method="getAddresses"
                layout="vertical"
                :autoSelectFirst="false"
                :border="false"
            />
            <resource-input
                :title="$t('Delivery')"
                v-model="form.delivery"
                :filters="{id:query.delivery}"
                name="category_id"
                :placeholder="$t('Select an option')"
                label="line_1"
                :fetch-method="getAddresses"
                layout="vertical"
                :autoSelectFirst="false"
                :border="false"
            />
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AddressInput from "../form/AddressInput";
import customFilterMixin from "../../mixins/customFilterMixin";
import Filter from "./Filter";
import ResourceInputFilter from "./types/ResourceInputFilter";

export default {
  name: "ControlTowerFilterSideBar",
  components:{
    ResourceInputFilter,
    AddressInput,
    'vue-filter': Filter,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapState("profile", ["show_control_tower_filter"]),
    options(){
      return [
        {
          label: 'name',
          track_by: 'id',
          type: 'resource',
          filterProperty: 'carrier',
        },
        {
          label: 'name',
          track_by: 'id',
          type: 'resource',
          filterProperty: 'shipper',
        },
        {
          label: 'line_1',
          track_by: 'id',
          type: 'resource',
          filterProperty: 'pickup',
        },
        {
          label: 'line_1',
          track_by: 'id',
          type: 'resource',
          filterProperty: 'delivery',
        },
        {
          label: 'plate',
          track_by: 'id',
          filterProperty: 'truck',
          type: 'resource',
        },
        {
          label: 'plate',
          track_by: 'id',
          filterProperty: 'trailer',
          type: 'resource',
        },
      ]
    },
    slugFilters(){
      let data = {};
      let form = _.cloneDeep(this.form);
      if (form.carrier){
        data.carrier = form.carrier.id;
      }
      if (form.shipper && form.shipper.length > 0){
        data.shipper = form.shipper.reduce((acc, cur)=>`${acc !== '' ? acc+',':acc}${cur.id}`, '')
      }
      if (form.trailer_type){
        data.trailer_type = form.trailer_type.id;
      }
      if (form.truck_type){
        data.truck_type = form.truck_type.id;
      }
      if (form.delivery){
        data.delivery = form.delivery.id;
      }
      if (form.pickup){
        data.pickup = form.pickup.id;
      }
      if (form.trailer){
        data.trailer = form.trailer.id;
      }
      if (form.truck){
        data.truck = form.truck.id;
      }
      return data;
    },
  },
  data(){
    return {
      filters: {},
      form: {
        shipper:[]
      },
      query: {}
    }
  },
  created() {
    for(let key in this.value){
      let filter = this.options.find(item => item.filterProperty === key);
      if(filter){
        filter.value = this.value[key];
        this.query[key] = this.value[key];
        this.filters[key] = filter;
      }
    }
  },
  methods:{
    ...mapActions('accounts',['fetchCarriers','fetchShippers']),
    ...mapActions('trucktypes',['getTruckTypes']),
    ...mapActions('trucks',['getTrucks']),
    ...mapActions('trailers',['fetchTrailers']),
    ...mapActions('trailertypes',['getTrailerTypes']),
    ...mapActions("profile",["toggleControlTowerFilter"]),
    ...mapActions("addresses",["getAddresses"]),
    hidden(){
      this.toggleControlTowerFilter();
    },
    removeFilter(index){
      let element = this.filters[index];
      this.$emit('filter-removed', element.filterProperty || index);
      delete this.query[element.filterProperty || index];
      delete this.filters[element.filterProperty || index];
      this.form[element.filterProperty || index] = null;
      this.query[element.filterProperty || index] = null;
    },
  },
  watch:{
    form: {
      deep: true,
      handler(newValue, oldValue){
        for(let key in newValue){
          if(newValue[key]){
            if (this.filters[key]){
              if(key === 'shipper'){
                if(newValue.shipper.length > 0){
                  this.filters[key] = {value: newValue.shipper.reduce((acc, cur)=>`${acc !== '' ? acc+',':acc}${cur.id}`, '')}
                }
                  
              }else{
                if (this.filters[key].label){
                  this.filters[key].value = String(newValue[key][this.filters[key].label]);
                }else{
                  this.filters[key].value = String(newValue[key]);
                }
              }

            }else{
              let filter = this.options.find(item => item.filterProperty === key);
              if (filter.label){
                filter.value =  String(newValue[key][filter.label]);
              }else{
                filter.value =  String(newValue[key]);
              }
              if(key === 'shipper'){
                if(newValue.shipper.length > 0){
                  this.filters[key] = {value: newValue.shipper.reduce((acc, cur)=>`${acc !== '' ? acc+',':acc}${cur.id}`, '')}
                }
              }else{
                this.filters[key] = filter;
              }
              
            }
          }
        }
        this.$emit('input', this.slugFilters);
      }
    }
  }


}
</script>

<style scoped>
.close_item{
  background: #f2f2f2;
  margin-top: 1px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.filter_tag{
  padding: 4px 12px;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}
.filter_tag > i {
  margin-left: 8px;
}
.input_filter_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.input_filter_item{
  width: 20%;
  height: 100%;
  flex: 1;
}




</style>
<style>
.sidebar_filter_control_tower{
  background: #fafafa;
  position: absolute;
  top: 75px;
  width: calc(100% - 80px);
  z-index: 1000;
  right: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.font-size-8{
  font-size: 8px;
  font-weight: 900;
}
</style>
