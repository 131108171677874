<template>
  <div class="login" style="">
    <div class="login_register mt-5">
      <p class="text-center">{{$t('New user?')}}
        <a class="text-info font-weight-bold cursor-pointer" @click="register">
            {{$t('Sign up')}}
        </a>
      </p>
    </div>
    <h4 class="login_section login_label">{{$t('Welcome Again')}}</h4>
    <div class="login_title login_section">{{$t('Log in')}}</div>

    <validation-observer ref="observer" v-slot="{ validate , errors}">
      <b-form @submit.stop.prevent="login(validate)">
        <div class="login_input_generic login_section">
          <div class="login_input_generic_label">{{$t('Email')}}</div>
          <validation-provider name="email" rules="required|email" v-slot="{ errors }">
            <p :class="{ 'control': true }">
              <input class="login_input_generic_input" v-model="model.email"   :class="{'input': true, 'is-danger': errors.length }"  name="email" type="text" placeholder="ejemplo@mail.com">
              <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
            </p>
          </validation-provider>
        </div>
        <div class="login_input_generic login_section">
          <div class="login_input_generic_label"> {{$t('Contraseña')}}</div>
          <validation-provider name="password" rules="required|min:4" v-slot="{ errors }">
            <p :class="{ 'control': true }">
              <input class="login_input_generic_input"  v-model="model.password"  :class="{'input': true, 'is-danger': errors.length }"  name="password" type="password" placeholder="">
              <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
            </p>
          </validation-provider>
        </div>
        <div class="check__item login_section mb-5">
          <label for="rememberMe">
            <input class="default__check" id="rememberMe" name="rememberMe" type="checkbox">
            <span class="custom__check"></span>
            <span class="custom__label"> {{$t('Remember me')}} </span>
          </label>
        </div>
        <div class="login_section">
          <button  :class="{'disabled':(hasErrors(errors))||(loading)}"   class="btn btn-primary_v2 btn-block text-uppercase login_btn" type="submit" >{{$t('Log in')}}</button>
        </div>
      </b-form>
    </validation-observer>
    <div class="text-center mt-5"> {{$t("Forgot your password?")}}
      <a class="text-info font-weight-bold cursor-pointer" @click="toResetPassword()">
        {{$t("Recover Password")}}
      </a>
    </div>
    <alert-modal :visible="notification.visible" :success="notification.success"  :title="notification.title" :message="notification.message" :sub_message="notification.sub_message" :reload_on_close="false" v-on:close="closeNotificationCustom" />
  </div>
</template>

<script>
import {signIn, getAccessToken} from '../../../utils/auth.js'
import {mapActions, mapMutations} from "vuex";
import AlertModal from "../../../components/modals/AlertModal";
import AlertModalMixin from "../../../mixins/AlertModalMixin";
import Echo from "laravel-echo";
import {useEventsTracker} from "@/composables/analytics";



export default {
  name: "Login",
  data(){
    return {
      model:{},
      loading: false,
      NEW_PASSWORD_REQUIRED: null,
    }
  },
  created() {
  },
  components: {
    AlertModal
  },
  mixins:[AlertModalMixin],
  methods:{
    ...mapActions('profile',['getProfile','getProfileActions']),
    ...mapMutations('register',['setUser']),
    ...mapActions('catalogs',['getCatalogs']),
    ...mapActions('forms',['getForm']),
    ...mapMutations('forms',['setCustomLoadForm','setCarrierLoadForm','setShipperLoadForm','setAdminLoadForm']),
    ...mapActions('views',['getActiveViews','executeViews']),
    ...mapActions('configuration',['getConfiguration']),
    async login(validate) {
      if(!(await validate()) || this.loading){
        this.$bvToast.toast(this.$t('Please, check the form'), {
          title: "Error",
          variant: 'danger',
          solid: true
        });
        return;
      }
      this.loading = true;
      let data = _.cloneDeep(this.model)
      signIn(data.email, data.password).then(async ({user,NEW_PASSWORD_REQUIRED}) => {
        if (NEW_PASSWORD_REQUIRED){
          this.setUser(user)
          await this.$router.push({name: "new_password_required"});
          return
        }
        window.Pusher = require('pusher-js');
        window.Echo = new Echo({
          broadcaster: 'pusher',
          authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          forceTLS: true,
          auth: {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              Accept: 'application/json',
            },
          }
        });
        const { trackEvent } = useEventsTracker(this);
        trackEvent('login_success', 'Login', 'Success');
        this.getProfileActions().then((d) => {
          d.status ? this.InitApp() : this.CompleteProfile();
        }).catch((d) => {
          this.CompleteProfile();
        })

      }).catch((err)=>{
        let message = err.message.replace("UserMigration failed with error ", "")
        this.notificationError("Error", this.$t(err.code), this.$t(message));
      })
    },
    register(){
      this.$router.push({name:"register"})
    },
    toResetPassword(){
      this.$router.push({name:"forgot_password"})
    },
    fetchCatalogs(user){
      this.getCatalogs();
      this.getConfiguration();
      if(['carrier','shipper','admin'].indexOf(user.profile.object) > -1 && user.profile.load_form_id){
        this.getForm({id: user.profile.load_form_id})
            .then(data =>{
              switch (user.profile.object){
                case "carrier":
                  this.setCarrierLoadForm(data);
                  break;
                case "shipper":
                  this.setShipperLoadForm(data);
                  break;
                case "admin":
                  this.setAdminLoadForm(data);
                  break;
              }
            })
            .catch(error=>{
            });
      }
      this.getActiveViews();
    },
    InitApp(){
      this.getProfile().then((data)=>{
        this.fetchCatalogs(data);
        try{
          const {trackUserProfile} = useEventsTracker(this);
            const userProfile = {
              userType: data.profile.object,
              email: data.email
            };
            trackUserProfile(userProfile);
          if(this.$route.params.profile !== data.profile.object){
            this.$router.push({name:'profile', params:{profile: data.profile.object}});
          }
        }catch(e){}
      });
    },
    CompleteProfile(){
      if (this.$route.name !== 'on_boarding'){
        this.$router.push({name:"on_boarding"});
      }
    },
    hasErrors(err){
      return !!_.findKey(err,o=>o.length>0)
    },
    closeNotificationCustom(){
      this.notification.visible = false;
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>