<template>
    <div class="row no-gutters h-400">
        <div class="border-right" :class="[origins.length ? 'col-6' : 'col-12']">
            <navbar color="celeste" :title="$t('Added equipment')" image="truck" :showEdit="true" :goToStep="1" />
            <equipments-list :equipments="equipments" class="offset-2" :twoColumns="origins.length ? false : true"
                v-if="!fetching" />
            <b-card class="skeleton-card mt-3" v-else>
                <b-skeleton animation="wave" width="95%"></b-skeleton>
                <b-skeleton animation="wave" width="75%"></b-skeleton>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
            </b-card>
        </div>
        <div :class="[origins.length ? 'col-6' : 'd-none']">
            <navbar color="azufre" :title="$t('Added routes')" image="map" :showEdit="true" :goToStep="2" />
            <routes-list :origins="origins" :destinations="destinations" class="offset-2"
                :twoColumns="origins.length ? false : true" v-if="!fetching" />
            <b-card class="skeleton-card mt-3" v-else>
                <b-skeleton animation="wave" width="95%"></b-skeleton>
                <b-skeleton animation="wave" width="75%"></b-skeleton>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
            </b-card>
        </div>

        <div class="col-6" :class="[origins.length && 'border-right']" />
        <div class="col-6 text-right">
            <button type="button" class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder btn-end"
                @click="submit" v-if="!fetching">
                {{ $t('Save and finish') }}
            </button>
            <b-skeleton type="button" class="w-25 float-right btn-end" v-else />
        </div>

        <alert-modal :visible="notification_visible" :success="true" :title="$t('Configure load recommendations')"
            :message="$t('Your configuration has been sent successfully')" sub_message="" :reload_on_close="false"
            v-on:close="closeNotification" />

    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import Navbar from '@/views/recommendations/Navbar.vue';
import EquipmentsList from '@/views/recommendations/EquipmentsList.vue';
import RoutesList from '@/views/recommendations/RoutesList.vue';
import AlertModal from "@/components/modals/AlertModal";

export default {
    name: "Resume",
    components: {
        Navbar,
        EquipmentsList,
        RoutesList,
        AlertModal
    },
    data() {
        return {
            notification_visible: false
        }
    },
    mounted() {
        if (this.equipments.length == 0) {
            this.setStep(1);
        }
    },
    props: {
        equipments: { type: Array },
        origins: { type: Array },
        destinations: { type: Array },
    },
    computed: {
        ...mapState('profile', ['profile']),
        ...mapState('recommendations', ['fetching','load_recommendation']),
        ...mapState('catalogs', ['catalogs'])
    },
    methods: {
        ...mapActions('recommendations', ['storeLoad','updateLoadRecommendation']),
        ...mapMutations('recommendations', ['setStep']),
        submit() {
            let equipmentsIds = this.equipments.map(entry => entry.code)
            let allOriginsSelected = this.origins.map(entry => entry.value).find(e => e === '00');
            let allDestinationsSelected = this.destinations.map(entry => entry.value).find(e => e === '00');
            let statesValues = this.catalogs.states.map(entry => entry.value);

            let origins = allOriginsSelected ? statesValues : this.origins.map(entry => entry.value);
            let destinations = allDestinationsSelected ? statesValues : this.destinations.map(entry => entry.value);

            let params = {
                "carrier_id": this.profile.id,
                "origins": origins,
                "destinations": destinations,
                "equipments": equipmentsIds
            }

            if (this.$route.path.indexOf('edit') > 1) {
                this.updateLoadRecommendation({...{"id":this.load_recommendation.id},...params})
                    .then(data => {
                            this.notification_visible = true;
                        })
                        .catch(error => {
                            this.$root.$bvToast.toast(error.data.message, {
                                title: "Error",
                                variant: 'danger',
                                solid: true
                            });
                        })
                        .finally(() => {

                        });
            } else {
                this.storeLoad(params)
                    .then(data => {
                        this.notification_visible = true;
                    })
                    .catch(error => {
                        this.$root.$bvToast.toast(error.data.message, {
                            title: "Error",
                            variant: 'danger',
                            solid: true
                        });
                    })
                    .finally(() => {

                    });
            }
        },
        closeNotification() {
            this.notification_visible = false;
            this.$router.push({
                name: 'home.index',
            });
        }
    }
}
</script>

<style lang="css" scoped>
.w-60 {
    width: 60%;
}

.h-400 {
    min-height: 400px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.double-column {
    border: 1px solid red;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.btn-end {
    margin-right: 10%;
    margin-top: calc(10%);
    margin-bottom: calc(3%);
}

.skeleton-card {
    margin-left: calc(15%);
    width: 65%;
}
</style>
