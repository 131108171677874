<template>
    <div class="tpx_calendar">
        <div class="actions_btn">
            <div  class="text-primary font_bold cursor-pointer" @click="lastMonth">
                <i class="ri-arrow-left-s-line font-size-24"></i>
            </div>
            <div  class="text-primary font_bold"> {{ localDate.format('MMMM YYYY') }} </div>
            <div  class="text-primary font_bold cursor-pointer" @click="nextMonth">
                <i class="ri-arrow-right-s-line font-size-24" />
            </div>
        </div>
        <div class="dates ml-n4" ref="month">
            <div class="item dow" v-for="dow in days">{{$t(dow)}}</div>
            <div v-for="blank in firstDayOfMonth" class="day day_blank"></div>
            <div
                v-for="numberDay in daysInMonth"
                @click="openday(numberDay)"
                :ref="'day_'+numberDay"
                class="day"
            >
                <div
                    class="item day"
                    :class="{'active': (numberDay === currentDay && Number(parentDate.format('MM')) === currentMonth) }"
                >
                    <b>{{numberDay}}</b>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "CalendarComponent",
        props: {
            setDate: { type: Function },
            parentDate: { type: Object },
        },
        data(){
            return {
                days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                localDate: moment(),
                currentDay: 0,
                currentMonth: 0
            }
        },
        computed: {
            daysInMonth: function () {
                return moment(this.localDate).daysInMonth();
            },
            firstDayOfMonth: function () {
                return moment(this.localDate).startOf('month').weekday();
            },
        },
        created(){
            this.setCurrentDay()
        },
        methods: {
            nextMonth: function () {
                this.localDate = moment(this.localDate).add(1, 'month');
                this.setCurrentDay();
            },
            lastMonth: function () {
                this.localDate = moment(this.localDate).subtract(1, 'month');
                this.setCurrentDay();
            },
            openday(d){
                let selectedDate = moment(this.localDate).set("date", d);
                this.setDate(selectedDate);
                this.localDate = selectedDate;
                this.setCurrentDay();
            },
            setCurrentDay(){
                this.currentDay = Number(this.localDate.format('DD'));
                this.currentMonth = Number(this.localDate.format('MM'));
            }
        },
    }
</script>

<style scoped lang="scss">

.tpx_calendar {

    .actions_btn {
        font-family: "Inter", sans-serif;
        max-width: 235px;
        color: #00B3E6;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 29px;
        display: flex;
        justify-content: space-between;
        text-transform: capitalize;
    }

    .dates {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .item {
        height: 60px;
        text-align: center;
        font-family: Helvetica;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dow {
        color: #888888!important;
        font-family: Helvetica;
        font-size: 14px;
    }

    .day {

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        &.active {
            background: #00B3E6;
            color: white;
            border-radius: 100%;
            width: 40px;
            height: 40px;

        }
    }
}


</style>
