<template>
  <div>
    <page-header :title="edit ? `${$t('Edit view')}: ${view && $route.params.view_id === view.id ? view.title: '- -'}`: $t('New view')"/>

    <validation-observer ref="observer" v-slot="{ validate }">
      <b-form @submit.stop.prevent="onSubmit(validate)">
        <loading v-if="loading" />
        <div class="card">
          <div class="card-header">
            <h6>{{$t('General')}}</h6>
          </div>
          <div class="form-fields">
            <form-input
                :title="$t('Title')"
                :help="$t('Enter the view title')"
                v-model="form.title"
                rules="required"
                type="text"
                :name="'title'"
            />
            <form-input
                :title="$t('Description')"
                :help="$t('Enter the view description')"
                v-model="form.description"
                rules=""
                type="text"
                :name="'description'"
            />
            <form-input
                v-if="false"
                :title="$t('Who has access')"
                v-model="form.type"
                rules=""
                type="select"
                :name="'type'"
                :options="[]"
                v-on:change="()=>onFieldTypeChange"
            />
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h6>{{$t('Conditions')}}</h6>
          </div>
          <div class="card-body">
            <b>{{$t('Loads must meet all of these conditions to appear in the view')}}</b>
          </div>

          <template v-for="(condition,index) in form.conditions.all">
            <condition-form :key="'c-all-'+index" v-model="form.conditions.all[index]" v-on:remove="form.conditions.all.splice(index,1)" :name="`condition.all.${index}.`" />
          </template>

          <div class="card-body">
            <button type="button" @click="form.conditions.all.push({})" class="btn btn-outline-primary">{{$t('Add condition')}}</button>
          </div>

          <div class="card-body">
            <b>{{$t('Loads must meet any of these conditions to appear in the view')}}</b>
          </div>
          <template v-for="(condition,index) in form.conditions.any">
            <condition-form :key="'c-any-'+index" v-model="form.conditions.any[index]" v-on:remove="form.conditions.any.splice(index,1)" :name="`condition.any.${index}.`" />
          </template>
          <div class="card-body">
            <button type="button" @click="form.conditions.any.push({})" class="btn btn-outline-primary">{{$t('Add condition')}}</button>
          </div>

        </div>

        <div class="d-flex justify-content-between pb-4">
          <div>
            <button :disabled="loading" type="button" class="btn btn-light mr-3 " @click="$router.push({name:$route.meta.routes.list})">{{$t('Cancel')}}</button>
          </div>
          <div>
            <button :disabled="loading" v-if="!edit" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew">{{$t('Save and create new')}}</button>
            <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary">{{$t('Save')}}</button>
          </div>
        </div>

      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import ConditionForm from "@/components/form/ConditionForm";

import formMixin from '@/mixins/formMixin';
export default {
  name: "FormField",
  components:{
    ConditionForm
  },
  props:{

  },
  mixins: [formMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  },
  data(){
    return {
      from: null,
      form: {
        conditions: {all:[], any:[]},
      },
      loading : false,
      edit: false,
      createNew: false,
    }
  },
  computed:{
    ...mapState('views',['view']),
  },
  created() {
    if(this.$route.params.view_id){
      this.edit = true;
      this.loading = true;
      this.getView({id: this.$route.params.view_id})
          .then(data=>{
            this.form = _.cloneDeep(data);
          })
          .catch(error =>{
            this.processServiceError(error);
          })
          .finally(()=>{
            this.loading = false;
          });
    }
  },
  methods: {
    ...mapActions('views',['storeView','getView','updateView']),
    onFieldTypeChange(){
      if(['checkbox','select'].indexOf(this.form.type)){
        this.form.options = [];
      }
    },
    setOptionText(optionIndex){
      this.form.options[optionIndex].text = this.form.options[optionIndex].value;
    },
    resetForm() {
      this.form = {conditions: {all:[], any:[]},};
      this.createNew = false;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    saveAndCreateNew(){
      this.createNew = true;
      this.$refs.submit.click();
    },
    async onSubmit(validate) {
      if (!(await validate()) || this.loading) {
        return;
      }

      this.loading = true;
      let form = _.cloneDeep(this.form);
      if(this.edit){
        this.updateView(form)
            .then(data=>{
              this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                title: this.$t('Success'),
                variant: 'success',
                solid: true
              });
              this.redirect();
            })
            .catch(error =>{
              this.processServiceError(error);
            })
            .finally(()=>{
              this.loading = false;
            });
      }else{

        this.storeView(form)
            .then(data=>{
              this.$root.$bvToast.toast(this.$t('Created successfully'), {
                title: this.$t('Success'),
                variant: 'success',
                solid: true
              });
              if(this.createNew){
                this.resetForm();
              }else{
                this.redirect();
              }
            })
            .catch(error =>{
              this.processServiceError(error);
            })
            .finally(()=>{
              this.loading = false;
            });
      }

    },
    redirect(){
      if(this.from.name){
        this.$router.back();
      }else{
        this.$router.push({name: this.$route.meta.routes.list});
      }
    }
  }
}
</script>

<style scoped>

</style>
