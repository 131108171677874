<template>
    <div class="font-family-helvetica">
        <hr>
        <div class="row px-4 py-2 mb-100">
            <div class="col-6">
                <div class="mb-4" style="width:660px">
                    <polyline-map :center="center" :path="path" :class="'map'" :markers="markers" />
                </div>
                <hr>
                <shipment-info />
                <hr>
                <summary-equipment :edit="false" />
            </div>
            <div class="col-4 offset-1 pl-5">
                <load-card-details :loadId="load.id" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import PayList from '@/components/marketplace/accesories/PayList.vue';
import SummaryEquipment from '@/components/marketplace/equipment/SummaryEquipment';
import PolylineMap from "@/components/common/PolylineMap.vue";
import ShipmentInfo from "@/components/marketplace/ShipmentInfo.vue";
import LoadCardDetails from '../../components/loads/LoadCardDetails.vue';

export default {
    name: "LoadAssign",
    components: {
        PayList,
        PolylineMap,
        SummaryEquipment,
        ShipmentInfo,
        LoadCardDetails
    },
    data() {
        return {
            acepted: false,
            fetching: false,
            center: {},
            markers: {},
            path: [],
        }
    },
    computed: {
        ...mapGetters('marketplace', ['shipment_request', 'origin', 'destination', 'route']),
        ...mapState('profile', ['profile']),
        ...mapState('loads', ['load'])
    },
    created() {
        let shipment = {
            ...this.load, destination: this.load.delivery, origin: this.load.pickup, equipment_type: {
                code: 'FTL',
                name: 'test'
            }
        }
        this.setShipment(shipment)
        this.center = { lat: this.load.pickup.address.latitude, lng: this.load.pickup.address.longitude };
        this.markers = [
            { lat: this.load.pickup.address.latitude, lng: this.load.pickup.address.longitude },
            { lat: this.load.delivery.address.latitude, lng: this.load.delivery.address.longitude }
        ]
        this.path = this.route.map((item) => {
            return {
                lat: item[0],
                lng: item[1]
            }
        });
    },
    methods: {
        ...mapMutations('marketplace', ['setStep', 'setShipment']),
        ...mapActions('marketplace', ['storeShipment']),
        submit() {
            let request = _.cloneDeep(this.shipment_request);
            request.customer_code = this.profile.account.profile.tms_id ? this.profile.account.profile.tms_id : null;

            if (!request.accessories.some(e => e.code === 'O_INSURANCE')) {
                delete request.consignment_value;
            }

            this.fetching = true;
            this.storeShipment(request)
                .then(data => {
                    this.setStep(8);
                })
                .catch(error => {
                    this.$root.$bvToast.toast(this.$t("Hubo un error al procesar su solicitud"), {
                        title: this.$t('Warning'),
                        variant: 'danger',
                        solid: true
                    });
                })
                .finally(() => {
                    this.fetching = false;
                });
        }
    }
}
</script>

<style scoped>
.mb-100 {
    margin-bottom: 100px;
}

.fixed-bottom {
    height: 100px;
    background: #FFF !important;
    border: 1px solid #DDDDDD;
}

.disabled {
    pointer-events: none !important;
}
</style>
