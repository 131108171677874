<template>
    <div>
        <div style="padding-bottom:1rem;">
            <div class="row align-items-center ">
                <div class="col-md-2 col-sm-2 ">
                    <h6 class="text-muted pl-3">{{$t('Order number')}}</h6>
                </div>
                <div class="col-md-1 col-sm-1">
                    <h6 class="text-muted">{{$t('Status')}}</h6>
                </div>
                <div class="col-md-2 col-sm-3 ">
                    <h6 class="text-muted">{{$t('Carrier')}}</h6>
                </div>
                <div class="col-md-1 col-sm-1">
                    <h6 class="text-muted">{{$t('Truck')}}</h6>
                </div>
                <div class="col-md-1 col-sm-1">
                    <h6 class="text-muted">{{$t('Product')}}</h6>
                </div>
                <div class="col-md-1 col-sm-1">
                    <h6 class="text-muted">{{$t('Quantity')}}</h6>
                </div>
                <div class="col-md-2 col-sm-2">
                    <h6 class="text-muted">{{$t('Amount')}}</h6>
                </div>

                <div class="col-md-1 col-sm-1">
                    <h6 class="text-muted">{{$t('Delivery')}}</h6>
                </div>
                <div class="col text-right">
                    <button type="button" @click="fetchData" class="btn btn-light">
                        <i class="far fa-sync"></i>
                    </button>
                </div>
            </div>
        </div>
        <hr class="mt-0"/>
        <loading v-if="fetchingOrders" />
        <template v-for="item in list.results">
            <div class="card"  :key="'order-'+item.id">
                <div class="card-body p-0">
                    <div class="row align-items-center py-4">
                        <div class="col-md-2 col-sm-2 ">
                            <h6 class="text-muted pl-4">{{item.order_number}}</h6>
                        </div>
                        <div class="col-md-1 col-sm-1">
                            <span
                                class="badge badge-outline-info"
                            >{{$t('status.orders.'+item.status)}}</span>
                        </div>
                        <div class="col-md-2 col-sm-3 ">
                            <div class="text-muted">{{item.load ? item.load.carrier.name : '- -'}}</div>
                        </div>
                        <div class="col-md-1 col-sm-1">
                            <div class="text-muted">{{item.load ? item.load.truck.plate : '- -'}}</div>
                        </div>
                        <div class="col-md-1 col-sm-1">
                            <div class="text-muted"> {{item.items.length > 0 ? `${item.items[0].product.name}`:''}}</div>
                        </div>
                        <div class="col-md-1 col-sm-1">
                            <div class="text-muted">
                                {{item.items.length > 0 ? `${item.items[0].quantity} L`:'- -'}}
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-2">
                            <div class="text-muted">$ {{item.total | money}} {{item.currency}}</div>
                        </div>

                        <div class="col-md-1 col-sm-1">
                            <span
                                class="badge badge-outline-info"
                            >
                             {{item.delivery ? $t(item.delivery.status) : '- -'}}
                            </span>
                        </div>
                        <div class="col text-right">
                            <a v-if="orderId === item.id" class="mr-3" href="javascript:void(0);" @click="orderId = null">
                                <i class="far fa-chevron-circle-up btn-icon"></i>
                            </a>
                            <a v-else class="mr-3" href="javascript:void(0);" @click="showRow(item)">
                                <i class="far fa-chevron-circle-down btn-icon"></i>
                            </a>
                        </div>
                    </div>
                    <template v-if="orderId === item.id" >
                        <hr class="my-0"/>
                        <fuel-order-detail class="p-4" :order="item" />
                    </template>
                </div>
            </div>
        </template>

        <div  v-if="list.total > 0"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetchingOrders" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FuelOrderDetail from '@/components/common/FuelOrderDetail';

export default {
    components: { FuelOrderDetail },
    name: 'FuelOrdersTable',
    props: {
        filters: {
            type: Object,
            default(){
                return {}
            }
        },
        action: {
            type: Function,
            required: true
        }
    },
    data(){
        return {
            init: false,
            page: 1,
            orderId : null,
            orders: {
                results: [],
                total: 0,
                per_page: 15,
                current_page: 1,
                last_page: 1,
                count: 0,
                next_page_url: null,
                prev_page_url: null,
                from: 1,
                to: 1
            },
            fetchingOrders: false
        }
    },
    computed: {
        list(){
            return this.orders;
        }
    },
    watch: {
        filters: {
            deep: true,
            handler(newVal, oldVal){
                this.fetchData(); 
            }
        }
    },
    methods: {
        fetchData(){
            this.fetchingOrders = true;
            this.action(this.filters)
                .then((data)=>{
                    this.orders = _.cloneDeep(data);
                })
                .catch(error=>{

                })
                .finally(()=>{
                    this.init = true;
                    this.fetchingOrders = false;
                });
        },
        showRow(item){
            this.orderId = item.id;
        }
    }
}
</script>
