<template>
  <b-modal
      id="modal-action-expense"
      ref="modal-action-expense"
      size="md"
      hide-footer
      :title="title"
      :visible="visible"
      v-on:hidden="closeAction"
      v-on:show="step=1"
  >
    <div class="">
      <loading-overlay :loading="loading">
        <div v-if="step===1">
          <validation-observer ref="observer" v-slot="{ validate }">
            <b-form @submit.stop.prevent="onSubmit(validate)">
              <div class="form-fields">
                <form-input
                    v-if="action === 'accept'"
                    :title="$t('Request Amount')"
                    v-model="form.amount"
                    :disabled="true"
                    type="number"
                    name="amount"
                    layout="vertical"
                />
                <form-input
                    v-if="action === 'accept'"
                    :title="$t('Final cost')"
                    v-model="form.final_amount"
                    type="number"
                    rules="required|min_value:1"
                    name="final_amount"
                    layout="vertical"
                />
                <form-input
                    v-if="action === 'reject' || action === 'cancelled' "
                    :title="$t('Tell us. Why do you reject the extra cost?')"
                    v-model="form.comment"
                    type="textarea"
                    rules="required"
                    name="final_amount"
                    layout="vertical"
                />
              </div>
              <div class="d-flex justify-content-end mt-4 mb-2  field-wrapper border-0 ">
                <button type="submit" class="btn btn-primary btn-block">{{$t('Send')}}</button>
              </div>
            </b-form>
          </validation-observer>
        </div>
        <template v-if="step===2">
          <div class=" field-wrapper border-0 text-center">
            <img v-if="success" src="@/assets/images/txp/triangle.png"   style="width: 80px;height: 80px;margin:10px;" />
            <img v-else src="@/assets/images/txp/common/alert_warning.svg"   style="width: 80px;height: 80px;margin:10px;" alt="">
            <h3 class="card-title text-center">{{message}}</h3>
            <p class="sub_message text-center text-muted" v-if="sub_message">
              {{sub_message}}
            </p>
          </div>
          <div class="d-flex justify-content-end mt-1 mb-2  field-wrapper border-0 ">
            <button @click="closeAction()" class="btn btn-primary btn-block">{{$t('Go Back')}}</button>
          </div>
        </template>


      </loading-overlay>

    </div>

  </b-modal>
</template>

<script>

import Swal from "sweetalert2";
import {mapActions} from "vuex";
import LoadingOverlay from "@/components/common/LoadingOverlay";

export default {
  name: "ExtraCostActions",
  components: {LoadingOverlay},
  data(){
    return {
      form :{

      },
      update: false,
      loading: false,
      message: "",
      sub_message: "",
      success: false,
      load_id: this.$route.params.load_id,
      step: 2,
    }
  },
  props: {
    visible: {
      type: Boolean,
      default(){
        return false;
      }
    },
    action: {
      type: String,
      default(){
        return "false";
      }
    },
    expense: {
      type: Object,
      default(){
        return {};
      }
    }
  },
  created() {
    this.form = _.cloneDeep(this.expense);
    this.step=1
    this.form.final_amount = _.cloneDeep(this.form.amount)
  },
  computed:{
    title(){
      return this.action==='accept'? this.$t('Accept extracost') : this.$t('Reject extracost')
    }
  },
  methods:{
    ...mapActions('extracost',['deleteExpense','updateExpense','rejectedExpense','acceptedExpense']),
    closeModal(){

    },
    closeAction(){
      if (this.step===2){
        this.$emit('updateData');
      }else{
        this.$emit('close');
      }
    },
    async onSubmit(validate) {
      if (!(await validate())) {
        return;
      }
      this.loading =true;
      var request = {}
      var message = ""
      let form = _.cloneDeep(this.form);
      switch (this.action){
        case 'accept':
          request = {
            status: "accepted",
            final_amount: form.final_amount,
            id:form.id
          }
          message = this.$t('The extra cost is accepted correctly')
          break;
        case 'reject':
          request = {
            status: "rejected",
            comments: form.comment,
            id:form.id
          }
          message = this.$t('The extra cost is rejected correctly')
          break;
        case 'cancelled':
          request = {
            status: "cancelled",
            comments: form.comment,
            id:form.id
          }
          message = this.$t('The extra cost is cancelled correctly')
          break;
      }
      if (this.action){
        this.updateItem(request,message)
      }

    },
    updateItem(form,message){
      if (this.action === 'accept'){
        this.acceptedExpense({load_id: this.load_id, expense_id: form.id,form:form})
            .then(data=>{
              this.message = this.$t(message);
              this.success = true;
            })
            .catch(error =>{
              this.message = this.$t('Something went wrong when action processed');
              this.sub_message = this.$t('Something went wrong try again, if problem persists contact us');
              this.success = false;
            }).finally(()=>{
          this.step=2;
          this.loading =false;
        });
      }else{
        this.rejectedExpense({load_id: this.load_id, expense_id: form.id,form:form})
            .then(data=>{
              this.message = this.$t(message);
              this.success = true;
            })
            .catch(error =>{
              this.message = this.$t('Something went wrong when action processed');
              this.sub_message = this.$t('Something went wrong try again, if problem persists contact us');
              this.success = false;
            }).finally(()=>{
          this.step=2;
          this.loading =false;
        });
      }



    },
  },
  watch:{
    expense: {
      deep: true,
      handler(value){
        this.step=1;
        this.loading =false;
        this.form = _.cloneDeep(value);
        this.form.final_amount = _.cloneDeep(this.form.amount)
      }
    },
    visible: {
      deep: true,
      handler(value){
        this.step=1;
      }
    }
  }
}
</script>

<style scoped>

</style>