<template>
  <b-modal
      id="modal-create-expense"
      ref="modal-create-expense"
      size="md"
      hide-footer
      :title="update?$t('Edit charge'):$t('Add charge')"
      :visible="visible"
      v-on:hidden="$emit('close')"
  >
    <validation-observer ref="observer" v-slot="{ validate }">
      <b-form @submit.stop.prevent="onSubmit(validate)">
        <div class="form-fields">
          <div>
            <form-input
                :title="$t('Type')"
                v-model="form.type"
                rules="required"
                type="select"
                :options="expensesCategories"
                name="type"
                @change="updateSettlementCatalogSelect"
                layout="vertical"
                :readOnly="filters.type ? true:false"
                :placeholder="$t('Select option')"
            />
            <form-input
                :title="$t('Concept')"
                v-model="form.settlement_catalog_id"
                rules="required"
                :options="settlementCatalogSelect"
                :searchable="true"
                type="select"
                :name="'settlement_catalog_id'"
                layout="vertical"
            />
            <form-input
                :title="$t('Description')"
                v-model="form.description"
                rules=""
                type="textarea"
                name="description"
                layout="vertical"
            />
            <div class="field-wrapper" v-if="showDate">
              <div class="field-label">{{$t('Date')}}<span class="field-required-badge"/></div>
              <div class="field-help"></div>
              <validation-provider name="schedule_to"  v-slot="{ errors }">
                <date-input v-model="form.date" type="date" />
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </validation-provider>
            </div>
            <div class="row align-items-center ">
              <div class="col-md-6">
                <form-input
                    :title="$t('Amount')"
                    v-model="form.amount"
                    rules="required"
                    type="number"
                    name="amount"
                    layout="vertical"
                />
              </div>
              <div class="col-md-6">
                <div class="field-wrapper">
                  <div class="field-label">{{$t('Currency')}}<span class="field-required-badge"/></div>
                  <div class="field-help"></div>
                  <validation-provider name="price" rules="required" v-slot="{ errors }">
                    <currency-input id="currency" v-model="form.currency" />
                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4 mb-2">
          <a @click="$bvModal.hide('modal-create-expense')" class="btn btn btn-light mr-3 ">{{$t('Cancel')}}</a>
          <button type="submit" class="btn btn-primary">{{update?$t('Update'):$t('Save')}}</button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import CurrencyInput from "@/components/form/CurrencyInput";
import DateInput from "../form/DateInput";

export default {
  name: "CreateEditExpenseModal",
  components:{
    CurrencyInput,
    DateInput
  },
  props: {
    filters: {
      type: Object,
      default(){
        return {}
      }
    },
    visible: {
      type: Boolean,
      default(){
        return false;
      }
    },
    types: {
      type: Array,
      default(){
        return ['bonus'];
      }
    },
    hiddenDate: {
      type: Boolean,
      default(){
        return false;
      }
    },
    value: {
      type: Object,
      default(){
        return {}
      }
    }

  },
  data(){
    return {
      settlementCatalogSelect: [],
      form :{},
      update: false,
    }
  },
  created() {
    this.form = _.cloneDeep(this.value);
    this.getDataSelectOption();
  },
  watch: {
    filters : {
      deep: true,
      handler(newVal, oldVal){
        if(!_.isEqual(newVal, oldVal)){
          this.updateSettlementCatalogSelect();
        }
      }
    },
    form: {
        deep: true,
        handler(value){
            this.$emit('input', value);
        }
    },
  },
  computed:{
    ...mapGetters('catalogs', ['expensesTypes']),
    ...mapGetters('loads', ['getByCategory']),
    ...mapState('loads', ['expenses',"settlementCatalogSelectData"]),
    expensesCategories() {
      let expenseType = this.expensesTypes.filter(item => (this.types.includes(item.value)))
      return expenseType.map(item=>({
        text:this.$t(item.text),
        value:item.value
      }))
    },
    showDate(){
      if (this.hiddenDate && !this.update){
        return false;
      }
      return  true
    }
  },
  methods:{
    ...mapActions('loads',['getSettlementCatalogSelectData']),
    async onSubmit(validate){
      if(!(await validate())){
        return;
      }
      let form = _.cloneDeep(this.form);
      this.$emit('submit', form);
      this.$emit('onSubmit', {form:form,update:this.update});
    },
    updateSettlementCatalogSelect(value=null){
      this.settlementCatalogSelect = this.getByCategory(value??this.filters.type).map(item=>({
        text:item.name,
        value:item.id
      }));
    },
    getDataSelectOption(){
      this.getSettlementCatalogSelectData({per_page:1000000}).then(()=>{
        this.updateSettlementCatalogSelect();
      });
    },
    createExpenseModal(){
      this.$refs["modal-create-expense"].show();
      this.update = false;
      this.form = {};
      this.form.status = 'pending';
      this.form.type= this.filters.type;
    },
    hide(){
      this.$refs["modal-create-expense"].hide();
    },
    updateExpenseModal(item){
      this.$refs["modal-create-expense"].show();
      this.update = true;
      this.form =  {...item};
      this.expense_status = this.form.status;
    },
  }
}
</script>

<style scoped>

</style>