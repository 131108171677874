export default {
        published:'far fa-newspaper',
        not_assigned:'far fa-hourglass-half',
        accepted:'far fa-handshake',
        assigned:'far fa-handshake',
        pending:'far fa-hourglass-half',
        cancelled:'far fa-engine-warning',
        disabled:'far fa-engine-warning',        
    
        started:'far fa-shipping-fast',
        checkin_pickup:'far fa-warehouse-alt',
        loading:'far fa-truck-loading',
        loaded:'far fa-inventory',
        in_transit:'far fa-shipping-fast',
        checkin_delivery:'far fa-warehouse-alt',
        unloading:'far fa-forklift',
        unloaded: 'far fa-forklift',
        delivered:'far fa-hand-holding-box',
        pod:'far fa-images',
        paid:'far fa-file-invoice-dollar',
};