<template>
  <div class="register" style="">
    <div class="register_title_back register_section cursor-pointer" @click="back()"> <i class="fa fa-chevron-left"/>
      {{ $t('Back') }} </div>
    <div class="register_title register_section">{{$t('Recover password')}}</div>
    <div class="register_label register_section">
      <div class="register_label_label mb-0">{{$t('Enter the linked email account:')}}</div>
    </div>
    <validation-observer ref="observer" v-slot="{ validate , errors}">
      <loading-overlay :loading="loading">
        <form  @submit.stop.prevent="submit(validate)" >
          <div class="register_input_generic register_section">
            <div class="register_input_generic_label">{{ $t('Email') }}</div>
            <validation-provider name="email" rules="required|email" v-slot="{ errors }">
              <p :class="{ 'control': true }">
                <input class="login_input_generic_input" v-model="model.email"   :class="{'input': true, 'is-danger': errors.length }"  name="email" type="text" placeholder="ejemplo@mail.com">
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </p>
            </validation-provider>
          </div>
          <div class="register_section register_action" >
            <button :class="{'disabled':(hasErrors(errors))}"   class="btn font-size-12 btn-primary_v2 btn-block text-uppercase login_btn" type="submit" >{{$t('continue')}}</button>
          </div>
        </form>
      </loading-overlay>
    </validation-observer>
    <a class="btn-outline-dark  btn btn-block mt-4 text-uppercase font-size-12  font_bold register_action_btn" @click="back()"  >
      {{$t('Cancel')}}
    </a>
    <alert-modal :visible="notification.visible" :success="notification.success"  :title="notification.title" :message="notification.message" :sub_message="notification.sub_message" :reload_on_close="false" v-on:close="closeNotificationCustom" />
  </div>
</template>

<script>
import {forgotPassword} from '../../../utils/auth.js'
import AlertModalMixin from "../../../mixins/AlertModalMixin";
import AlertModal from "../../../components/modals/AlertModal";
import LoadingOverlay from "../../../components/common/LoadingOverlay";


export default {
  name: "ResetPassword",
  data(){
    return {
      model: { },
      success: null,
      loading:false
    }
  },
  components: {
    LoadingOverlay,
    AlertModal
  },
  mixins:[AlertModalMixin],
  methods:{
    back(){
      this.$router.push({name:"login"})
    },
    async submit(validate) {
      if (!(await validate()) || this.loading) {
        this.$bvToast.toast(this.$t('Please, check the form'), {
          title: "Error",
          variant: 'danger',
          solid: true
        });
        return;
      }
      this.loading = true;
      let data = _.cloneDeep(this.model)
      forgotPassword(data.email).then((e) => {
        this.success = data.email;
        this.notificationSuccess(this.$t('Success'),this.$t('Mail sent'), this.$t("The password recovery link was sent, check your email"));
      }).catch((err) => {
        this.success = false;
        let message = err.message.replace('UserMigration failed with error','')
        this.notificationError("Error", "Reset password error",message);
      }).finally(()=>{
        this.loading = false;
      })
    },
    hasErrors(err){
      return !!_.findKey(err,o=>o.length>0)
    },
    closeNotificationCustom(err){
      this.notification.visible = false;
      if (this.success){
        this.$router.push({
          name: 'login.index',
        })
      }
    },
  }
}
</script>

<style scoped>

</style>