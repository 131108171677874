<template>
    <div>
        <page-header :title="titleComponent" />

        <div class="row">
            <div :class="[$route.meta.class ? $route.meta.class:'col-md-12']" >
                <loading v-if="loading" />
                <validation-observer ref="observer" v-slot="{ validate }">
                    <b-form @submit.stop.prevent="onSubmit(validate)">
                        <div class="card">
                            <div class="card-header">
                                <h6>General</h6>
                            </div>
                            <form-input
                                v-if="!$route.meta.profile && !$route.params.user_id"
                                :title="$t('Profile')"
                                :help="$t('Profile type')"
                                v-model="form.role"
                                rules="required"
                                :options="roles"
                                type="select"
                                :name="'role'"
                                :disabled="!!$route.params.user_id"
                            />
                            <resource-input
                                v-if="$route.meta.profile === 'staff'"
                                :title="$t('Team')"
                                v-model="form.team"
                                rules=""
                                name="team_id"
                                :placeholder="$t('Select an option')"
                                label="name"
                                :fetch-method="fetchTeams"
                            />
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Name')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The name user')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="name" rules="required" v-slot="{ errors }">
                                            <b-form-input  v-model="form.name" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Last name')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The lastname user')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="last_name" rules="required" v-slot="{ errors }">
                                            <b-form-input  v-model="form.last_name" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Phone')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The phone user')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="phone" rules="required|numeric|max:15" v-slot="{ errors }">
                                            <b-form-input  v-model="form.phone" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Email')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The email user')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="email" rules="required|email" v-slot="{ errors }">
                                            <b-form-input  v-model="form.email" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                     <div class="col-md-6">
                                        <div class="field-label">{{$t('Country')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The country user')}}</div>
                                        <div class="field-help"></div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="country" rules="required" v-slot="{ errors }">
                                            <country-input id="country" v-model="form.country" v-on:change="value=>form.profile.address.country = value"/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper" >
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Password')}}<span :class="!$route.params.user_id ? 'field-required-badge' : ''"/></div>
                                        <div class="field-help">{{$t('The password user')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="password" :rules="!$route.params.user_id ? 'required|min:6' : ''" v-slot="{ errors }">
                                            <b-form-input  v-model="form.password" type="password" ref="password"></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper" >
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Password confirmation')}}<span :class="!$route.params.user_id ? 'field-required-badge' : ''"/></div>
                                        <div class="field-help">{{$t('The password confirmation user')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="password_confirmation" :rules="!$route.params.user_id ? 'required|min:6|confirmed:password' : 'confirmed:password'" v-slot="{ errors }">
                                            <b-form-input v-model="form.password_confirmation" type="password" data-vv-as="password"></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <organization-form v-if="['carrier','shipper'].indexOf(form.role) > -1" v-model="form.profile" id="profile." >
                            <template v-slot:append>
                                <form-input
                                    v-if="['carrier'].indexOf(form.role) > -1"
                                    :title="$t('RFC')"
                                    :help="$t('RFC')"
                                    v-model="form.profile.rfc"
                                    type="text"
                                    rules="required|rfc"
                                    :name="form.id+'rfc'"
                                    placeholder=""
                                    :upper="true"
                                />
                                <form-input
                                        :title="$t('Is internal?')"
                                        :help="$t('Turn on if the organization is internal')"
                                        v-model="form.profile.is_internal"
                                        rules=""
                                        type="boolean"
                                        :name="form.id+'is_internal'"
                                />
                            </template>
                        </organization-form>

                        <div v-if="['admin', 'staff'].indexOf($route.params.profile) > -1 && form.role === 'carrier'" class="card">
                            <div class="card-header">
                                <h6>{{ $t('Mirror account') }}</h6>
                            </div>
                            <div class="form-fields">
                                <form-input
                                    :title="$t('URL')"
                                    :help="$t('Account url')"
                                    rules="url"
                                    v-model="form.profile.mirror_account.url"
                                    type="text"
                                    :name="'mirror_url'"
                                />

                                <form-input
                                    :title="$t('User')"
                                    :help="$t('Account user')"
                                    v-model="form.profile.mirror_account.user"
                                    rules=""
                                    type="text"
                                    :name="'mirror_user'"
                                />

                                <form-input
                                    :title="$t('Password')"
                                    :help="$t('Account password')"
                                    v-model="form.profile.mirror_account.password"
                                    rules=""
                                    type="password"
                                    :name="'mirror_password'"
                                />
                            </div>
                        </div>


                        <div v-if="form.role === 'driver'" class="card">
                            <div class="card-header">
                                <h6>{{$t('Driver information')}}</h6>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('External ID')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The external id driver')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="external_id" rules="required" v-slot="{ errors }">
                                            <b-form-input  v-model="form.profile.external_id" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <form-input
                                    v-if="false"
                                    :title="$t('TMS ID')"
                                    v-model="form.profile.tms_id"
                                    rules=""
                                    type="text"
                                    :name="'tms_id'"
                            />

                            <form-input
                                    :title="$t('RFC')"
                                    :help="$t('Driver RFC')"
                                    v-model="form.profile.rfc"
                                    rules="required|max:13|min:13|rfc"
                                    type="text"
                                    :name="'rfc'"
                                    :upper="true"
                            />

                            <form-input
                                    :title="$t('License')"
                                    :help="$t('The license driver')"
                                    v-model="form.profile.driver_license"
                                    rules="required"
                                    type="text"
                                    :name="'driver_license'"
                                    :upper="true"
                            />

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Policy number')}}</div>
                                        <div class="field-help">{{$t('The policy number of driver')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="insurance_number" rules="" v-slot="{ errors }">
                                            <b-form-input  v-model="form.profile.insurance_number" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Insurance company')}}</div>
                                        <div class="field-help">{{$t('The insurance company of vehicule driver')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="insurance_company" rules="" v-slot="{ errors }">
                                            <b-form-input  v-model="form.profile.insurance_company" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Insurance number')}}</div>
                                        <div class="field-help">{{$t('The insurance number of driver')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="corporate_insurance_number" rules="" v-slot="{ errors }">
                                            <b-form-input  v-model="form.profile.corporate_insurance_number" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Truck')}}</div>
                                        <div class="field-help">{{$t('Default truck')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="truck_id" v-slot="{ errors }">
                                            <truck-input v-model="form.profile.truck" :filters="{carrier_id: form.profile.carrier_id}" :disabled="loading" />
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <form-input
                                    :title="$t('Availability')"
                                    :help="$t('Turn off if resource is not available')"
                                    v-model="form.profile.is_active"
                                    rules=""
                                    type="boolean"
                                    :name="'is_active'"
                            />
                        </div>

                        <address-form v-if="['carrier','shipper','driver'].indexOf(form.role) > -1" v-model="form.profile.address" :title="$t('Address')" :required="form.role !=='driver'" prefix_name="profile.address." />

                        <div class="d-flex justify-content-between mb-4">
                            <div>
                                <button v-can="'users.delete'" v-if="$route.params.user_id" :disabled="loading" type="button" class="btn btn-danger mr-2 "
                                        @click="confirmDelete">{{$t('Delete')}}</button>
                                <button v-can="'users.update'" v-if="$route.params.user_id" :disabled="loading" type="button" class="btn btn-light mr-2 " style="text-transform:capitalize;"
                                        @click="confirmActivateDesactivate" >{{$t(form.enabled === true ? 'disable':'enable')}}</button>
                            </div>
                            <div>
                                <button :disabled="loading" type="button" class="btn btn-light mr-3 " @click="$router.push(list)">{{$t('Cancel')}}</button>
                                <button :disabled="loading" v-if="!$route.params.user_id" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew">{{$t('Save and create new')}}</button>
                                <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t('Save')}}</button>
                            </div>
                        </div>

                    </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import CountryInput from "@/components/form/CountryInput";
    import OrganizationForm from '@/components/form/OrganizationForm'
    import AddressForm from '@/components/form/AddressForm';
    import Swal from "sweetalert2";
    import TruckInput from '@/components/form/TruckInput';

    export default {
        name:'UserForm',
        beforeRouteEnter(to, from, next) {
            next((vm) =>  vm.from = from)
        },
        components:{
           CountryInput,
           OrganizationForm,
           AddressForm,
           TruckInput
        },
        mixins: [formMixin],
        data(){
            return {
                title: '...',
                form: {
                    profile: {
                        external_id: null,
                        address:{},
                        mirror_account: {}
                    },
                    role: null,
                },
                createNew: false,
                control_tower: false,
                loading: false,
                from: null
            }
        },
        computed: {
            ...mapState('profile',['profile']),
            roles(){
                let roles = [
                    {text: this.$t('Shipper'), value: 'shipper'},
                    {text: this.$t('Carrier'), value: 'carrier'}
                ]

                if (this.$route.params.user_id) {
                    return [...roles, {text: this.$t('Driver'), value: 'driver'}]
                }
                return roles
            },
            titleComponent(){
                let title = this.$route.meta.title ? this.$t(this.$route.meta.title) : '';
                if (this.$route.name.indexOf('drivers') > -1) {
                     title =  this.$route.params.user_id ? this.$t('Edit driver') : this.$t('New driver')
                }

                if (this.$route.name.indexOf('users') > -1) {
                     title = this.$route.params.user_id ? this.$t('Edit user') : this.$t('New user')
                }
                return title;
            },
            role(){
                let role = this.$route.name.indexOf('drivers') > -1 ? 'driver' : `${this.$route.params.profile}.user`;
                return role
            },
            userRole(){
              return this.form.role
            },
            list(){
                if(this.$route.meta.routes){
                    return {name: this.$route.meta.routes.list};
                }
                return {name: `${this.profile}.index`}
            }
        },
        created() {
            this.init();
        },
        methods: {
            ...mapActions('users',['getUser','storeUser','updateUser','deleteUser','updateUserStatus','attachPermision']),
            ...mapActions('teams',['fetchTeams']),
            init(){
                if(this.$route.params.user_id){
                    this.loading = true;
                    this.getUser({id: this.$route.params.user_id})
                        .then(data=>{
                          let form = _.cloneDeep(data);
                          this.control_tower = !(form.permissions.findIndex(e=>e==='towercontrol.management') === -1);
                          form.role = form.roles[0];
                          this.form = form;

                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        }).finally(()=>{
                          this.loading = false;
                          console.log("SE TERMINO DE CARGAR")
                        })
                }else{
                    switch(this.$route.meta.profile){
                        case "driver":
                            this.form.role = 'driver';
                            this.form.country = this.profile.country;
                            break;
                        case "staff":
                            if(this.$route.params.profile ==='carrier'){
                                this.form.role = 'carrier.user';
                            }else if(this.$route.params.profile ==='shipper'){
                                this.form.role = 'shipper.user';
                            }else if(this.$route.params.profile ==='admin'){
                                this.form.role = 'staff';
                            }
                            break;
                        default:
                            if(this.$route.query.role){
                                this.form.role = this.$route.query.role;
                            }
                            break;
                    }

                }
                // Use param filters
                if(this.$route.meta.paramFilters){
                    for(let key of this.$route.meta.paramFilters){
                        this.form[key] = this.$route.params[key];
                        this.form.profile[key] = this.$route.params[key];
                    }
                }
            },
            resetForm() {
                this.form = {
                    profile: {
                        external_id: null,
                        address:{}
                    },
                    role: null
                };
                this.createNew = false;
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },
            saveAndCreateNew(){
                this.createNew = true;
                this.$refs.submit.click();
            },
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    this.$bvToast.toast(this.$t('Please, check the form'), {
                        title: "Error",
                        variant: 'danger',
                        solid: true
                    });
                    return;
                }

                this.loading = true;
                let form = _.cloneDeep(this.form);
                if(form.profile.truck){
                    form.profile.truck_id = form.profile.truck.id;
                    delete form.profile.truck;
                }
                if (form.profile.rfc)
                    form.profile.rfc = form.profile.rfc.toUpperCase()

                if(form.team){
                    form.team_id = form.team.id;
                    delete form.team;
                }
                if(this.$route.params.user_id){
                    this.updateUser(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            //this.redirect();
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }else{
                    this.storeUser(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Created successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            if(this.createNew){
                                this.resetForm();
                            }else{
                                this.redirect();
                            }
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }
            },
            redirect(){
                if(this.from.name){
                    this.$router.back();
                }else{
                    this.$router.push(this.list);
                }
            },
            confirmDelete(){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteUser({id: this.form.id})
                            .then(data=>{
                                this.redirect();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t('User')}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                    });
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            },
            confirmActivateDesactivate(){
                let action;
                if(this.form.enabled === true){
                    action= 'disable';
                }else{
                    action = 'enable';
                }
                Swal.fire({
                    title: this.$t("Confirm"),
                    text: this.$t(`Do you want to ${action}?`),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t(`Yes, ${action}`),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.updateUser({id: this.form.id, enabled: !this.form.enabled})
                            .then(data=>{
                                this.init();
                                this.$root.$bvToast.toast(this.$t(`Your item has been updated.`, {resource: this.$t('user')}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                    });
                            })
                            .catch(data=>{
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            })
                    }
                })
            },
        },
        watch:{
            control_tower: {
              deep:true,
              handler(val, oldVal){
                  if (!this.loading){
                    console.log("SE CAMBIA")
                    this.attachPermision({
                      id:this.form.id, control_tower : val
                    })
                  }


              }
            }
        }
    }
</script>

<style>

</style>
