<template>
    <div class="row ml-md-3 font-family-helvetica min-h-400 p-0 mt-4">
        <div class="col-6">
            <div class="d-flex justify-content-between">
                <h5 class="text-primary font-size-18">{{$t('Suggested equipment')}}</h5>
                <div class="font-size-25 text-gray-888 d-none">
                    <i class="ri-arrow-left-s-line" role="button"></i>
                    <i class="ri-arrow-right-s-line" role="button"></i>
                </div>
            </div>
            <truck-card :truck="truck" />
            <p class="text-gray-888">{{$t('Select the type of trailer for your equipment')}}:</p>
            <select-trailer :trailers="trailers" />
            <div class="row mt-4">
                <div class="col-md-8 offset-md-4 col-12">
                    <buttons
                        :back="onEdit"
                        :labelNext="$t('Next')"
                        :disabledNext="!equipment_type.code"
                        :next="() => setStep(2)"
                    />
                </div>
            </div>
        </div>
        <div class="col-4 ml-4">
            <ul class="list-group font-size-12 shadow-lg">
                <li class="list-group-item d-flex justify-content-between align-items-center py-4">
                    <span class="font-weight-bold font-size-16">{{$t('Summary equipment')}}</span>
                    <span class="text-primary font-size-13" role="button" @click="onEdit">
                        {{$t('Edit')}} <i class="ri-pencil-fill"></i>
                    </span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="font-weight-bolder">{{$t('Packaging type')}}</span>
                    <span class="text-gray-888">{{shipment_detail.container_type}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="font-weight-bolder">{{$t('Weight of each')}} {{container_type_name}} Kg.</span>
                    <span class="text-gray-888">{{shipment_detail.weight}} Kg.</span>
                </li>
                <li class="list-group-item">
                    <span class="font-weight-bolder">{{$t('Dimensions of each')}} {{container_type_name}}</span>
                    <div class="d-flex justify-content-between mt-1">
                        <div>{{$t('Width')}}: <span class="text-gray-888 ml-1">{{shipment_detail.width}}m</span></div>
                        <div>{{$t('Height')}}: <span class="text-gray-888 ml-1">{{shipment_detail.height}}m</span></div>
                        <div>{{$t('Length')}}: <span class="text-gray-888 ml-1">{{shipment_detail.length}}m</span></div>
                    </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="font-weight-bolder">{{$t('Total of')}} {{container_type_name}}</span>
                    <span class="text-gray-888">{{shipment_detail.quantity}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="font-weight-bolder">{{$t('Stackable packaging')}}</span>
                    <span class="text-gray-888">{{shipment_detail.stacking ? 'Si' : 'No'}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import TruckCard from './TruckCard';
import SelectTrailer from './SelectTrailer';
import Buttons from './Buttons';

export default {
    name: "SuggestedEquipment",
    components: {
        TruckCard,
        SelectTrailer,
        Buttons,
    },
    data() {
        return {
            truck: {},
            trailers: []
        }
    },
    computed: {
        ...mapGetters('catalogs', ['truck_types_marketplace', 'trailer_types_marketplace']),
        ...mapGetters('marketplace', ['shipment_detail', 'equipment_type']),
        ...mapGetters('shipmentform', ['available']),
        container_type_name(){
            return this.shipment_detail.container_type ? this.shipment_detail.container_type.toLowerCase() : null;
        }
    },
    methods: {
        ...mapMutations('shipmentform', ['setSuggestedEquipment']),
        ...mapMutations('marketplace', ['setStep']),
        onEdit() {
            this.setSuggestedEquipment(null);
        }
    },
    created() {
        let truck_selected = this.truck_types_marketplace.find(e => (
            e.truck_type_code === this.equipment_type.truck_type_code &&
            e.trailer_size_code === this.equipment_type.trailer_size_code &&
            e.configuration_code === this.equipment_type.configuration_code
        ));
        this.truck = truck_selected;

        if(!truck_selected) return false;

        let trailers_available = this.available.equipments.filter(entry => (
            entry.configuration_code === truck_selected.configuration_code &&
            entry.truck_type_code === truck_selected.truck_type_code ? truck_selected.truck_type_code : '' &&
            entry.trailer_size_code === truck_selected.trailer_size_code ? truck_selected.trailer_size_code : ''
        ));
        trailers_available = trailers_available.map(e => e.trailer_type_code);
        trailers_available = [...new Set(trailers_available)];
        this.trailers = this.trailer_types_marketplace.filter(e => trailers_available.includes(e.external_id));
    }
}
</script>

<style scoped>
.list-group {
    max-width: 360px!important;
}
.rectangle {
    border-radius: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.5);
}

.check-icon {
    font-size: 1.7rem;
}

.list-group-item {
    /*background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%);*/
    background-color: #EDFBFF;
}
</style>
