<template>
    <div>
        <div class="d-flex justify-content-end float-right pb-2" role="group" aria-label="toolbar">
            <button 
                :disabled="!documentsPending" 
                v-can="'documents.update'" v-b-tooltip.hover 
                :title="$t('Approve all documents')"
                @click="confirmApprovalAllDocuments"
                class="btn btn-light mr-3">
                <i class="fas fa-check-double m-1"></i> {{$t('Approve all')}}
            </button>
            <button 
                v-if="resource.document_status === 'in_review'"
                v-can="'documents.update'" v-b-tooltip.hover 
                :title="$t('Finish review')"
                @click="confirmFinishReview"
                class="btn btn-primary float-right">
                <i class="fas fa-check-circle m-1"></i>{{$t('Finish review')}}
            </button>
        </div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <th class="text-center" style="width:48px;"></th>
                    <th class="text-center">ID</th>
                    <th class="text-center">{{$t('Name')}}</th>
                    <th class="text-center">{{$t('Format')}}</th>
                    <th class="text-center">{{$t('Required?')}}</th>
                    <th class="text-center">{{$t('Expiration?')}}</th>
                    <th class="text-center">{{$t('Date expiration')}}</th>
                    <th class="text-center">{{$t('Files')}}</th>
                    <th class="text-center">{{$t('State')}}</th>
                    <th class="text-center">{{$t('Comments')}}</th>
                    <th class="text-center" style="min-width: 90px;max-width:100px;"></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(item, index)  in list.results">
                    <tr :key="'requirement-'+item.id"  class="requirement-row">
                        <td class="text-center">
                            <span class="text-primary"><i class="fas fa-book"></i></span>
                        </td>
                        <td class="text-center" style="width:110px;">
                            <b>{{index + 1}}</b>
                        </td>
                        <td class="text-center">{{item.name}}</td>
                        <td class="text-center">{{item.format ? item.format : '--'}}</td>
                        <td class="text-center"><i :class="['far', item.required ?  'fa-check-circle text-success' : 'fa-times-circle text-danger']"></i></td>
                        <td class="text-center"><i :class="['far', item.expiration ?  'fa-check-circle text-success' : 'fa-times-circle text-danger']"></i></td>
                        <td class="text-center">{{item.document && item.expiration ? item.document.expiration ? item.document.expiration : '--' : '--'}}</td>
                        <td class="text-center">
                            <template v-if="item.document">
                                <div class="d-flex">
                                    <template v-for="document in item.document.files">
                                        <div class="d-flex" :key="document.id">
                                            <img  v-if="isImage(document.path)" 
                                                class="avatar-sm mr-0"
                                                :src="document.path"
                                                :alt="document.filename"
                                                v-b-tooltip.hover :title="document.filename"
                                            />

                                            <a v-else class="mr-0" target="new" :href="document.path">
                                                <i style="font-size: 3.2em" v-b-tooltip.hover :title="document.filename" :class="iconByExtension(document.path)"/>
                                            </a>
                                            <a v-can="'documents.update'" @click="confirmDeleteFile(item.document.id, document)" href="javascript:void(0);" class="text-danger mr-2">
                                                <i class="fas fa-times btn-icon "/>
                                            </a>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </td>
                        <td class="text-center">
                            <span :class="['badge ', item.document ? item.document.status == 'accepted' ? 'badge-outline-success' : item.document.status === 'pending' ? 'badge-outline-warning' : item.document.status == 'rejected' ? 'badge-outline-danger' : 'badge-outline-secondary' : 'badge-outline-primary']">
                                {{item.document ? $t(item.document.status) : $t('witout documents')}}
                            </span>
                        </td>
                        <th class="text-center">{{item.document ? item.document.comment ? item.document.comment : '--' : '--' }}</th>
                        <th class="text-center">
                            <template v-if="item.document && item.document.status === 'pending'">
                                <a v-can="'documents.update'" @click="confirmApproval(item)" href="javascript:void(0);" class="text-success mr-2">
                                    <i v-b-tooltip.hover :title="$t('Approve')" class="fas fa-check btn-icon"  />
                                </a>
                                <a v-can="'documents.update'" @click="confirmReject(item)" href="javascript:void(0);" class="text-danger mr-2">
                                    <i v-b-tooltip.hover :title="$t('Reject')" class="fas fa-times btn-icon"  />
                                </a>
                            </template>
                            <a v-can="'documents.update'" @click="confirmUploadFile(item)" href="javascript:void(0);" class="text-primary">
                                <i v-b-tooltip.hover :title="$t('Upload file')" class="fas fa-file-upload btn-icon"  />
                            </a>
                        </th>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div v-if="list.total === 0" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

import {mapState, mapActions} from "vuex";
import Swal from "sweetalert2";


export default {
    name: "DocumentTable",
    components: {

    },
    data(){
        return {
            resourceTxt: this.$route.params.resource,
            resource_id: this.$route.params.resource_id,
            comment: null,
            extension : {
                'pdf': ' fas fa-file-pdf',
                'ppt': ' fas fa-file-powerpoint',
                'pptx': ' fas fa-file-powerpoint',
                'doc': ' fas fa-file-word',
                'docx': ' fas fa-file-word',
                'xls': ' fas fa-file-excel',
                'xlsx': ' fas fa-file-excel'
            }
        }
    },
    computed: {
        ...mapState('requirements', ['requirements','resource']),
        list(){
            return this.requirements
        },
        documentsPending(){
            let documentPending = false;

            this.list.results.forEach(document => {
                if (document.document && document.document.status === 'pending') {
                    documentPending = true;
                }
            });

            return documentPending
        }
    },
    created() {
        this.fetchData(this.resourceTxt, this.resource_id);
    },
    methods:{
        ...mapActions('requirements',['getRequirementsByProfile', 'approveRequirement', 'rejectRequirement', 'approveAllRequirement', 'finishReview', 'deleteDocument', 'uploadDocument','getResource']),
            fetchData(resource, resource_id){
                this.getRequirementsByProfile({resource, resource_id})
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                    })
            },
            fetchResource(resource, resource_id){
                this.getResource({resource, resource_id})
                        .then(data=>{
                        })
                        .catch(error =>{
                        })
            },
        confirmApproval(item){
            Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, approve it!"),
                cancelButtonText: this.$t("Cancel")
            }).then(result => {
                if(result.value) {
                    this.approveRequirement({resource: this.resourceTxt, resource_id: this.resource_id, document_id: item.document.id })
                        .then(data=>{
                            this.fetchData(this.resourceTxt, this.resource_id);
                            this.$root.$bvToast.toast(this.$t("Your item has been approved.", {resource: this.$t("Document").toLowerCase()}), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                        })
                        .catch(error =>{
                            console.log(error);
                            Swal.fire(
                                "Error",
                                this.$t("Something went wrong!"),
                                "error"
                            );
                        });
                }
            });
        },
        confirmReject(item){
            Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You won't be able to revert this! if you want to reject the document you need to place a comment"),
                icon: "warning",
                input: "textarea",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, reject it!"),
                cancelButtonText: this.$t("Cancel"),
                preConfirm: textarea => {
                    if (textarea) {
                         this.rejectRequirement({resource: this.resourceTxt, resource_id: this.resource_id, document_id: item.document.id, comment:textarea})
                        .then(data=>{
                            this.fetchData(this.resourceTxt, this.resource_id);
                            this.$root.$bvToast.toast(this.$t("Your item has been rejected.", {resource: this.$t("Document").toLowerCase()}), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                        })
                        .catch(error =>{
                            Swal.showValidationMessage(this.$t("An error has ocurred") + ' ' + error) 
                        });
                    } else {
                         Swal.showValidationMessage(this.$t("The comment is required!")) 
                    }
                }
            });
        },
        confirmApprovalAllDocuments(){
            Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, approve all!"),
                cancelButtonText: this.$t("Cancel")
            }).then(result => {
                if(result.value) {
                    this.approveAllRequirement({resource: this.resourceTxt, resource_id: this.resource_id })
                        .then(data=>{
                            this.fetchData(this.resourceTxt, this.resource_id);
                            this.$root.$bvToast.toast(this.$t("Your items has been approved.", {resource: this.$t("Document").toLowerCase()}), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                        })
                        .catch(error =>{
                            console.log(error);
                            Swal.fire(
                                "Error",
                                this.$t("Something went wrong!"),
                                "error"
                            );
                        });
                }
            });
        },
        confirmFinishReview(){

            let name = this.resource.name ? this.resource.name : ''

            let html = `<div id="swal2-content" class="swal2-html-container" style="display: block;">
                    ${this.$t('It will not be possible to reverse this, check that the name of the legal representative is correct.')}
                  </div>
                  <div class="form-group row pt-4">
                    <label for="colFormLabel" class="col-sm-3 col-form-label"> ${this.$t('Name')}: </label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="name" value="${name}">
                    </div>
                    <label for="colFormLabel" class="col-sm-3 col-form-label">${this.$t('Position')}:</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="position" value="${this.$t('Legal Representative')}">
                    </div>
                  </div>`;

            Swal.fire({
                title: this.$t("Are you sure?"),
                icon: "question",
                html: html,
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, finish review!"),
                cancelButtonText: this.$t("Cancel"),
                preConfirm: () => {
                
                    let name = document.getElementById('name').value.trim()
                    let position = document.getElementById('position').value.trim()

                    if (name && position) {
                         this.finishReview({
                            resource: this.resourceTxt,
                            resource_id: this.resource_id,
                            name,
                            position
                          })
                        .then((data) => {

                            this.fetchData(this.resourceTxt, this.resource_id);
                            this.fetchResource(this.resourceTxt, this.resource_id);
                            this.$root.$bvToast.toast(this.$t("Your request has been completed.", {resource: this.$t("Document").toLowerCase()}), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                            });
                        })
                        .catch(error =>{
                            console.log(error);
                            this.$root.$bvToast.toast(error.data.message, {
                                        title: this.$t('Error'),
                                        variant: 'danger',
                                        solid: true
                                        });
                        });
                    } else {
                        let msj;
                            msj = this.$t("The name and position are required!")
                         Swal.showValidationMessage(msj) 
                    }
                }
            });
        },
        confirmDeleteFile(document_id, item){
            Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, delete it!"),
                cancelButtonText: this.$t("Cancel")
            }).then(result => {
                    if(result.value) {
                        this.deleteDocument({resource: this.resourceTxt, resource_id: this.resource_id, document_id: document_id, file_id:item.id })
                            .then(data=>{
                                this.fetchData(this.resourceTxt, this.resource_id);
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("File").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                        });
                            })
                            .catch(error =>{
                                console.log(error);
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
        },
        confirmUploadFile(item){

            let html = '<input type="file" id="swal-input1" class="swal2-input">';
            let expirationDate = (item.document) ? item.document.expiration : ''
            if (item.expiration) html += '<input value="'+expirationDate+'" type="date" id="swal-input2" class="swal2-input">';

            Swal.fire({
                title: this.$t("Are you sure?"),
                text: this.$t("You won't be able to revert this!"),
                icon: "warning",
                html: html,
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("Yes, upload it!"),
                cancelButtonText: this.$t("Cancel"),
                preConfirm: () => {
                
                    let file = document.getElementById('swal-input1').files[0];
                    
                    let expiration = (document.getElementById('swal-input2')) ? document.getElementById('swal-input2').value : null;

                    let req = false;
                    if (item.expiration && !expiration) req = true;

                    if (file && !req) {
                         this.uploadDocument({resource: this.resourceTxt, resource_id: this.resource_id, requirement_id: item.id, file, expiration})
                        .then(data=>{
                            this.fetchData(this.resourceTxt, this.resource_id);
                            this.$root.$bvToast.toast(this.$t("Your item has been uploaded.", {resource: this.$t("Document").toLowerCase()}), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                        })
                        .catch(error =>{
                            Swal.showValidationMessage(this.$t("An error has ocurred") + ' ' + error) 
                        });
                    } else {
                        let msj;
                        if (item.expiration)
                            msj = this.$t("The file and expiration is required!")
                        else 
                            msj = this.$t("The file is required!")
                         Swal.showValidationMessage(msj) 
                    }
                }
            });
        },
        getExtension(document) {
            let document_split = document.split('.');
            return document_split[document_split.length - 1]
        },
        iconByExtension(document) {
            return this.extension[this.getExtension(document)]
        },
        isImage(document) {
            switch(this.getExtension(document)) {
                case 'png': 
                case 'jpg':
                case 'jpeg':
                    return true;
                default: return false
            }
        }
    }
}
</script>