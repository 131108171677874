<template>
    <div class="py-2">
        <table class="w-100">
            <tr>
                <td style="width: 80px;">
                    <b-dropdown
                        v-if="options.length > 0"
                        variant="primary"
                        @show="dropdownClicked"
                >
                    <template v-slot:button-content>
                        <i class="far fa-fw fa-plus mr-1"></i>{{$t('Filter')}}
                    </template>
                    <template v-for="(item,index) in options" >
                        <button class="dropdown-item" :key="'df-'+index" :class="[filters.find(el => el.filterProperty === item.filterProperty) ? 'disabled':'']"  @click="itemClicked(item)">
                            <i class="mr-1" :class="[item.icon]"></i>
                            {{ item.label }}
                        </button>
                    </template>
                </b-dropdown>
            </td>
            <td class="pl-2">
                <div class="d-inline-block v-filter-value" :key="'filter-'+item.type+index" v-for="(item,index) in filters">
                    <vue-filter class="mr-2" :filter="item" v-model="query[item.filterProperty]" v-on:remove="removeFilter(index)"
                        v-on:setDateParent="(e) => setDateParent(e, item.filterProperty)"
                        v-on:removeDateParent="removeDateParent(index, item.filterProperty)"
                    />
                </div>
            </td>
            <td style="text-align: right;max-width: 250px;">
                <slot name="right"></slot>
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import Filter from './Filter';

export default {
    name: 'FilterBar',
    components: {
        'vue-filter': Filter
    },
    props: {
        options : {
            type: Array,
            default(){
                return []
            }
        },
        value: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            filters: [],
            query: {}
        }
    },
    watch:{
        filters: {
            deep: true,
            handler(newVal, oldVal){
                let query = {};
                for(let key of newVal){
                    if(this.query[key.filterProperty]){
                        query[key.filterProperty] = this.query[key.filterProperty]
                    }
                }
                if (query.length) {
                  this.query = query;
                }
            }
        },
        query: {
            deep: true,
            handler(newValue, oldValue){
                this.$emit('input', newValue);
                this.$emit('page-change', 1);
            }
        }
    },
    created() {
        for(let key in this.value){
            let filter = this.options.find(item => item.filterProperty === key);
            if(filter){
                filter.value = this.value[key];
                this.query[key] = this.value[key];
                this.filters.push(filter);
            }
        }
    },
    methods:{
        removeFilter(index){
            let element = this.filters[index];
            this.$emit('filter-removed', element.filterProperty);
            delete this.query[element.filterProperty];
            this.filters.splice(index, 1);
        },
        setDateParent(date, filterProperty){
            filterProperty.map(entry => {
                this.query[entry] = date[entry];
            });
            this.$emit('input', date);
        },
        removeDateParent(index, filterProperty){
            filterProperty.map(entry => {
                this.$emit('filter-removed', entry);
                delete this.query[entry];
            });
            this.filters.splice(index, 1);
        },
        dropdownClicked() {
            this.$emit('filters-dropdown-clicked');
        },
        itemClicked(item) {
            this.$emit('filters-dropdown-item-clicked', item);
            this.filters.push(item)
        }
    }
}
</script>

<style >
.btn_new_filter > .btn{
    background: white!important;
    color: #000000!important;
    border: none!important;
}
</style>
