<template>
    <div>
        <div class="row rectangle border line-height-18 font-size-13 py-3 mt-3 border-0">
            <div class="col-4 text-right text-gray-888">
                <div>{{$t('Origin points')}}: </div>
            </div>
            <div class="col-8">
                <div
                    class="font-weight-bolder"
                    v-for="(origin, index) in origins"
                    :key="`origin-${index}`"
                >
                    {{ origin.name }}
                </div>
            </div>
        </div>
        <div class="row rectangle border line-height-18 font-size-13 py-3 mt-3 border-0">
            <div class="col-4 text-right text-gray-888">
                <div>{{$t('Destination points')}}: </div>
            </div>
            <div class="col-8">
                <div
                    class="font-weight-bolder"
                    v-for="(destination, index) in destinations"
                    :key="`destination-${index}`"
                >
                        {{ destination.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RoutesList",
    props: {
        origins: Array,
        destinations: Array,
    },
}
</script>

<style lang="css" scoped>

.rectangle {
    max-width: 440px;
    border-radius: 20px;
    background-color: #F2F2F2;
}

</style>
