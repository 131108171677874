<template>
    <div class="bg-gray mb-3 py-3" style="border-radius: 20px;">
        <div class="load-timeline">
            <div v-if="loading" class="pb-4">
                <loading />
            </div>
            <div v-else class="wrapper-timeline pt-1">
                <div v-for="(item,index) in events" :key="`timeline-item-${item.id}`" class="wrapper-item ">
                    <div class="right-section " :class="[finished && index == (events.length -1) ? '':'timeline-border-left']" style="margin-left: 50px;">
                        <span class="dot" style="background:#00B3E6" />
                        <span :style="getTruckStyle(index)" class="truck-advance d-flex align-items-center justify-content-center">
                            <i class="fas fa-truck-moving text-primary" />
                        </span>
                        <div class="" style="color:#888888;font-family: Helvetica;font-size: 11px;font-weight: bold;">
                            {{$t(item.status)}}
                        </div>
                        <div class="text-body" style="font-family: Helvetica;font-size: 11px;font-weight: bold;">
                            {{item.created_at | timestamp('YYYY-MM-DD HH:mm:ss', item.stop ? item.stop.address.timezone : 'America/Mexico_City')}}
                        </div>
                    </div>
                </div>
                <div class="wrapper-item " v-if="finished == false">
                    <div class="right-section" style="margin-left: 50px;">
                        <span  class="dot" style="background: rgb(151, 151, 151);" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import addressFormat from '@/helpers/address-format';
import {mapActions} from "vuex";
export default {
    components: {

    },
    name: 'ShipmentTimeline',
    props:{
        shipmentId:{
            type: Number,
            required: true
        },
    },
    data(){
        return {
            showAddressTimezone: true,
            loading: false,
            events: []
        }
    },
    computed: {
        finished(){
            return this.events.filter(item => item.status === 'delivered').length > 0
        }
    },
    created(){
        this.fetchData();
    },
    methods: {
      ...mapActions('controltower',["changeTimeZone"]),
      ...mapActions('shipments', ['getEvents']),
        fetchData(){
            this.loading = true;
            this.getEvents({id: this.shipmentId})
                .then(data=>{
                    this.events = data.results.reverse();
                })
                .catch(error =>{

                })
                .finally(()=>{
                    this.loading = false;
                })
        },
        getAddressFormat(address){
            return addressFormat(address)
        },
        getTitleItem(index){
            if(index === 0){
                return 'Pickup';
            }
            if(index === (this.stops.length -1)){
                return 'Delivery'
            }
            return 'Stop';
        },
        getBackgroundColour(event) {
            if(event.status === 'in_transit'){
                return 'background:#979797'
            }
            return 'background:#00B3E6'
        },
        getTruckStyle(index){
            if(index === (this.events.length - 1 ) && this.finished === false){
                return 'top:0;'
            }else{
                return 'display:none!important;'
            }
        }
    },
    watch:{
      showAddressTimezone: {
        deep: true,
        handler(value){
          this.changeTimeZone(value);
        }
      },
      shipmentId(){
        this.fetchData();
      }
    }
}
</script>
<style lang="scss" scoped>
.bg-gray{
    background-color: #f9f9f9;
}
.load-timeline{
    .wrapper-timeline{
       
        .wrapper-item{
             display: flex;
            .left-section{
                width: 50%;
                padding: 0px 20px 20px 10px;
            }
            .right-section{
                width: 50%;
                padding: 0px 20px 20px 30px;
                position: relative;
            }
        }
    }
}
.timeline-border-left{
    border-left: 1px solid #00B3E6;
}
.dot {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: -5px;
    top: 0;
}
.item-text{
    font-size: 11px;
}
.truck-advance{
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    left: -16px;
    border: 1px solid #00B3E6;
    background-color: #f9f9f9;
}
</style>