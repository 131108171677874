<template>
    <div class="row font-size-14">
        <div class="col-md-6 col-12">
            <form-input
                :title="$t('Type')"
                v-model="type_value"
                rules="required"
                type="select"
                :options="types"
                layout="vertical"
                :border="false"
                :placeholder="$t('Select option')"
                class="p-0"
                selectValue="code"
                selectText="name"
                :loading="fetching"
            />
        </div>
        <div class="col-md-6 col-12">
            <form-input
                :title="$t('Reason')"
                v-model="reason_type"
                rules="required"
                type="select"
                :options="reasons"
                layout="vertical"
                :border="false"
                :placeholder="$t('Select option')"
                class="p-0"
                :disabled="fetching"
                selectValue="code"
                selectText="name"
            />
        </div>
    </div>
</template>

<script>

export default {
    name: "ReasonTypeSelect",
    props: {
        fetching: Boolean,
        type: String,
        types: Array,
        reason: String,
        reasons: Array,
        setType: Function,
        setReason: Function,
    },
    computed: {
        type_value: {
            get() { return this.type },
            set(type_value) {
                this.setType(type_value);
            },
        },
        reason_type: {
            get() { return this.reason },
            set(reason_type) {
                this.setReason(reason_type);
            },
        },
    },
}
</script>

<style lang="css" scoped>
</style>
