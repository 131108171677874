<template>
  <div class="">
    <page-header :title="$t($route.meta.title)"/>
    <div class="row">
      <div class="col-md-12 text-right mb-4">
        <div class="btn-group" role="group" aria-label="toolbar">
          <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getData" type="button" class="btn btn-light">
            <i class="far fa-sync"></i>
          </button>
          <button  v-b-tooltip.hover :title="$t('Add expense')"  @click="newForm" class="btn btn-light">
            <i class="far fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="">
      <form-table @getData="getData"/>
    </div>
  </div>

</template>

<script>
import FormTable from "../../../components/tables/FormTable";
import {mapActions, mapState} from "vuex";
export default {
  name: "externalRequirements",
  components: {FormTable},
  data(){
    return {
      loading: false,
      profile_id: false,
      forms: []
    }
  },
  computed:{
    ...mapState('profile',['profile']),
    ...mapState('accounts',['account']),
    ...mapState('forms',['externalRequirements','fetching']),
  },
  created() {
    this.loading=true;
    this.profile_id = this.$route.params.shipper_id;
    this.getData();
  },
  methods:{
    ...mapActions('forms',['fetchExternalRequirements']),
    getData(){
      this.fetchExternalRequirements({shipper_id:this.profile_id}).finally(()=>{
        this.loading=false;
      });
    },
    newForm(){
      this.$router.push({name:"shippers.externalRequirementsCreate"})
    }
  }


}
</script>

<style scoped>

</style>
