import axios from "../../api/client";


// initial state
export const state = () => ({
    user:{},
    phone:{},
    code:null,
    email:null,
    password:null,

});

// getters
export const getters = {
    getUser(state) {
        return state.user
    },
    getPhone(state) {
        return state.phone.phone
    },
    getPhoneInfo(state) {
        return state.phone
    },
    getCode(state) {
        return state.code
    },
    getMail(state) {
        return state.email
    },
    getPassword(state) {
        return state.password
    },
    getPhoneAndCodeValid(state) {
        return state.phone.phone && state.code
    },
    getPhoneAndCodeAndUsernameValid(state) {
        return state.phone.phone && state.code && state.name && state.last_name
    },
    getUserInfo(state){
        return {
            "phone_number": state.phone.phone,
            "name": state.name,
            "last_name": state.last_name,
        }
    }
};

// actions
export const actions = {
    fetchRequestCode({commit}, params){
        return new Promise((resolve, reject) => {
            axios.post('/phone-validation/request_code', params,{withoutToken:true})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error)
                });
        });
    },
    fetchValidCode({commit}, params){
        return new Promise((resolve, reject) => {
            axios.post('/phone-validation/validate_code', params,{withoutToken:true})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setPhoneInfo(state, payload){
        state.phone = payload;
    },
    setCode(state, payload){
        state.code = payload;
    },
    setUser(state, payload){
        state.user = payload;
    },
    setUsernameInfo(state, payload){
        state.name = payload.name;
        state.last_name = payload.last_name;
    },
};

