<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <h4 class="card-title mb-0">{{$t('Payment')}}</h4>
        <h4 class="card-title mb-0" v-if="payment">
          $ {{payment.amount | money}}
        </h4>
      </div>
      <div v-if="payment"><small class="text-muted">{{ $t('Payment date') }}:</small> <b>{{payment.date}}</b></div>

    </div>
    <div class="card-body" v-if="payment">

      <template v-if="payment.factoring && showOptions === false">
        <div class="card">
          <div class="card-body pt-2 pb-0">
            <div class="d-flex align-items-start">
              <h5 class="mr-2">{{payment.factoring.description}}</h5>
              <badge-status :status="payment.factoring.status" :status-name="$t('status.factoring.'+payment.factoring.status)" />
            </div>
            <div><small class="text-muted">{{ $t('Payment date') }}:</small> <b>{{payment.factoring.date}}</b></div>
            <div class="d-flex justify-content-end">
              <h5 class="text-primary">$ {{payment.factoring.amount | money}}</h5>
            </div>
          </div>
        </div>

        <empty-results
              v-if="payment.factoring.status === 'in_review'"
              :title="$t('We are reviewing your factoring request')"
              :subtitle="$t('You will receive a notification when it is ready')" />

        <div v-if="payment.factoring.status === 'in_review'">
          <div class="d-flex justify-content-between my-3">
            <button class="btn btn-secondary btn-sm" @click="confirmToCancel">{{$t('Cancel Request')}}</button>
            <button class="btn btn-secondary btn-sm" @click="changeOption">{{$t('Change payment date')}}</button>
          </div>
        </div>
      </template>

      <template v-if="showOptions">
        <template v-if="payment.options.length > 0">
          <div class="card cursor-pointer"  v-for="item in payment.options"
               :key="'factoring-'+item.product_id"
               @mouseover="hover = item.product_id"
               @mouseout="hover = null"
               :class="{'bg-secondary': hover === item.product_id || (option !== null ? (option.product_id === item.product_id) : false)}"
               @click="option = item"
          >
            <div class="card-body pt-2 pb-0">
              <h5>{{item.name}}</h5>
              <div><small class="text-muted">{{ $t('Payment date') }}:</small> <b>{{item.date}}</b></div>
              <div class="d-flex justify-content-end">
                <h5 class="text-primary">$ {{item.amount | money}}</h5>
              </div>
            </div>
          </div>
          <div>
            <button v-if="payment.factoring" @click="showOptions = false" class="btn btn-dark mr-2">{{$t('Cancel')}}</button>
            <button @click="confirm" :disabled="option === null" class="btn btn-secondary">{{$t('Factoring request')}}</button>
          </div>
        </template>
        <template v-else>
          <empty-results
              :title="$t('No factoring options')"
              subtitle="" />
          <button v-if="payment.factoring" @click="showOptions = false" class="btn btn-dark mr-2">{{$t('Cancel')}}</button>
        </template>
      </template>

    </div>
    <div class="card-body" v-else>
      <template  v-if="notFound">
        <empty-results
            :subtitle="$t('Payment has not been scheduled')" />
        <div v-can="'payments.management'" class="text-center">
          <button class="btn btn-primary btn-sm" @click="paymentConfirm">
            <i class="far fa-wallet mr-2" />{{$t('Pay')}}
          </button>
        </div>
      </template>
      <loading v-else />
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Swal from "sweetalert2";

export default {
  name: "LoadPayment",
  props: {
    load: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      payment: null,
      option : null,
      hover: null,
      showOptions : false,
      notFound : false
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('loads',['getPayment','requestFactoring','cancelFactoring','pay']),
    fetchData(){
      this.getPayment({id: this.load.id})
          .then(data =>{
            this.payment = _.cloneDeep(data);
            if(this.payment.factoring){
                this.showOptions = false;
            }else{
                this.showOptions = true;
            }
          })
          .catch(error =>{
            if(!error || (error && error.status === 404)){
              this.notFound = true;
            }
          });
    },
    confirm(){
      Swal.fire({
        title: this.option.name,
        html: this.$t("Are you sure to request factoring?",
              {load_id: this.load.id, amount: this.option.amount, currency: this.option.currency}),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Accept"),
        cancelButtonText: this.$t("Cancel"),
        reverseButtons: true
      }).then(result => {
        if(result.value) {
          this.requestFactoring({id: this.load.id, product_id: this.option.product_id})
              .then(data =>{
                this.fetchData();
                this.$root.$bvToast.toast(this.$t("The request has been successfully sent"), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });

    },
    confirmToCancel(){
      Swal.fire({
        title: this.$t("Are you sure?"),
        html: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Accept"),
        cancelButtonText: this.$t("Cancel"),
        reverseButtons: true
      }).then(result => {
        if(result.value) {
          this.cancelFactoring({id: this.load.id, factoring_id: this.payment.factoring.id})
              .then(data =>{
                this.fetchData();
                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t('Factoring')}), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    },
    changeOption(){
      this.option = null;
      this.showOptions = true;
    },
    paymentConfirm(){
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("Confirm to transfer"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, confirm"),
        cancelButtonText: this.$t("Cancel"),
        reverseButtons: true
      }).then(result => {
        if(result.value) {
          this.pay({id: this.load.id})
              .then(data=>{
                this.fetchData();
                this.$root.$bvToast.toast(this.$t("Transfer completed"), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                if(error){
                  Swal.fire(
                      "Error",
                      this.$t(error.data.message),
                      "error"
                  );
                }else{
                  Swal.fire(
                      "Error",
                      this.$t("Something went wrong!"),
                      "error"
                  );
                }
              });
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
