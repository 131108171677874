<template>
    <div class="row">
        <div class="col">
            <div
                v-if="!fetching"
                type="button"
                class="btn btn-outline-light border border-dark btn-block text-uppercase font-size-12 font-weight-bolder py-2"
                @click="back"
            >
                {{ labelPrevious ? labelPrevious : $t("Previous")}}
            </div>
            <b-skeleton type="button" width="100%" v-else />
        </div>
        <div class="col">
            <button
                v-if="!fetching"
                class="btn btn-block text-uppercase font-size-12 font-weight-bolder py-2"
                :class="[disabledNext ? 'btn-light disabled' : 'btn-primary_v2']"
                @click="next"
            >
                {{ labelNext ? labelNext : $t("Next")}}
            </button>
            <b-skeleton type="button" width="100%" v-else />
        </div>
    </div>
</template>

<script>
export default {
    name: "Buttons",
    props: {
        back: { type: Function },
        next: { // optional
            type: Function,
            default: () => 1
        },
        disabledNext: {
            type: Boolean,
            default() {
                return false
            }
        },
        labelNext: {
            type: String,
            default() {
                return null
            }
        },
        labelPrevious: {
            type: String,
            default() {
                return null
            }
        },
        fetching: {
            type: Boolean,
            default() {
                return false
            }
        },
    },
}
</script>

<style scoped>

</style>
