import axios from "../../api/client";

// initial state
export const state = () => ({
    isReverseLogistic: false,
    // showModal: false,
    // logisticCategories: [],
    issueCategories: [],
    issueCatalogs: [],
    issueCatalog: null
});


// getters
export const getters = {
    isReverseLogistic: (state) => {
        return state.isReverseLogistic;
    },
    issueCategories: (state) => {
        return state.issueCategories;
    },
    issueCatalogs: (state) => {
        return state.issueCatalogs;
    },
    issueCatalog: (state) => {
        return state.issueCatalog;
    },
}

// actions
export const actions = {
    getReverseLogistics({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/reverse-logistics/${params.id}`)
            .then((response) => {
                resolve(response.data);
                commit('setIssueCategories', [response.data]);
                let catalogs = [...response.data.catalogs].sort((a, b) => a.id > b.id ? 1 : -1);
                commit('setIssueCatalogs', catalogs);
            })
            .catch((error) => {
                reject(error.response);
            });
        });
    },
}

// mutations
export const mutations = {
    setIsReverseLogistic(state, data) {
        state.isReverseLogistic = data;
    },
    setIssueCategories(state, data){
        state.issueCategories = data;
    },
    setIssueCatalogs(state, data){
        state.issueCatalogs = data;
    },
    setIssueCatalog(state, data){
        state.issueCatalog = data;
    },
}
