<template>
  <div>
    <div class="row mt-4">
      <div class="col-md-8 offset-md-2">
            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': type === 'legal_doc'}" href="javascript:void(0);" @click="type = 'legal_doc'">{{$t('Legal')}}</a>
                    </li>
                    <li class="nav-item" v-if="this.$route.params.profile !== 'driver'">
                        <a class="nav-link" :class="{'active': type === 'invoice'}" href="javascript:void(0);" @click="type = 'invoice'">{{$t('Invoices')}}</a>
                    </li>
                    </ul>
                </div>
                <div class="card-body">
                  <div v-show="type === 'legal_doc'">
                    <documents :title="$t('Documents')" type="legal_doc" />
                  </div>
                  <div v-show="type === 'invoice'" v-if="this.$route.params.profile !== 'driver'">
                    <documents :title="$t('Invoices')" type="invoice" />
                  </div>
                </div>
            </div>
      </div>
    </div>
  </div>

</template>

<script>

import {mapActions, mapState} from 'vuex';

import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Documents from "@/components/common/LoadDocuments";


export default {
  name: "LoadDocument",
  components: {
    Documents
  },
  data() {
    return {
      loading:true,
      galleryVisible: false,
      items: [],
      index: 0,
      type: 'legal_doc'
    }
  },
  computed: {
    ...mapState('loads',['documents', 'load', 'merchandises','invoices']),
    ...mapState('profile',['profile']),
  },
  created() {
      this.getDocuments({ id: this.$route.params.load_id})
        .then(() => {
          this.loading = false;
        });
  },
  methods: {
      ...mapActions('loads',['getDocuments']),
    appendFile(){
      this.loading = true;
      this.getDocuments({ id: this.$route.params.load_id}).then(data =>{
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>