<template>
  <div  >
    <div class="setLoading" v-if="loading">
      <img style="width: 50px;" src="/loading-image.gif">
    </div>
    <div class="txp_layout" v-else>
      <div class="txp_header">
        <Topbar :isCondensed="showMenu"  v-on:toggleMenu="toggleMenu" />
      </div>
      <div class="txp_body">
        <div class="txp_sidebar"  :class="showMenu?'':'hidde'" >
          <SideBarResponsive v-on:toggleMenu="showMenu=false" />
        </div>
        <div class="txp_content ">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import {mapState} from 'vuex';
import Topbar from "@/components/topbar";
import SideBarResponsive from "../../components/SideBarResponsive";

/**
 * Dashboard component
 */
export default {
  name: 'Base',
  components: {
    SideBarResponsive,Topbar
  },
  data(){
    return{
      showMenu: false,
      loading: true
    }
  },
  computed: {
    ...mapState('profile',['profile'])
  },
  created(){

    if (this.profile){
      this.loading = false
    }
    this.init();
    if (window.screen.width >= 992) {
      this.showMenu = false;
    }

  },
  methods: {
    init(){
      if(this.$route.name === 'profile'){
        switch(this.$route.params.profile){
          case "admin":
          case "carrier":
          case "driver":
            this.$router.push({name: 'loads.index'});
            break;
          case "shipper":
            this.$router.push({name: 'shipments.index'});
            break;
          case "seller":
            this.$router.push({name: 'orders.index'});
            break;
        }
      }
    },
    toggleMenu(){
      this.showMenu = !this.showMenu;
    },
  },
  watch:{
    profile(newValue, oldValue){
      if (newValue){
        this.loading=false
      }
    }
  }
};
</script>

<style lang="scss">
.setLoading{
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
}


$bg-color: #5c5c5c;


@media (max-width: 992px) {
  .txp_sidebar{
    overflow-y: scroll;
  }
}



.txp_sidebar{
  min-width: 300px;
  max-width: 70%;
  height: calc(100vh - 70px);
  background: $bg-color;
  top: 70px;
  left: 0;
  position: fixed;
  z-index: 1000;
  transition: all .8s;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
}

.txp_sidebar.hidde{
  left: -100%;
  transition: all .8s;
}

.container-fluid > div:not(.nopadding){
  padding: 2rem 1rem;
}



@media (min-width: 992px) {
  .txp_sidebar{
    background: $bg-color!important;
    width: 80px!important;
    min-width: 80px!important;
  }
  .txp_sidebar.hidde{
    left: 0;
    transition: all .8s;
  }
  .txp_content{
    padding-left: 80px!important;
  }

}




</style>


