<template>
    <validation-observer ref="observer" v-slot="{ validate }">
        <form @submit.stop.prevent="onSubmit(validate)" >
            <div class="font-size-14 mt-4 font-weight-bold">Productos</div>
            <empty-results title="" :subtitle="$t('No items')" v-if="isEmptyProducts" />
            <div class="table-responsive" v-else>
                <table class="table table-striped table-hover font-size-14">
                    <thead>
                        <tr>
                            <th scope="col">{{$t('Shipment')}}</th>
                            <th scope="col">{{$t('Customer reference')}}</th>
                            <th scope="col">{{$t('Batch')}}</th>
                            <th scope="col">SKU</th>
                            <th scope="col">{{$t('Description')}}</th>
                            <th scope="col">{{$t('Remission order')}}</th>
                            <th scope="col">{{$t('Replenishment order')}}</th>
                            <th scope="col">{{$t('Quantity')}}</th>
                            <th scope="col" v-if="quantity_required">{{$t('Quantity')}} ({{ shipments[0].reason.name }})</th>
                            <th scope="col" v-if="comments_required">{{$t('Comments')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(shipment, index1) in shipments">
                            <tr :key="'shipment-'+index1+'-'+index2" v-if="detail.checked" v-for="(detail, index2) in shipment.details">
                                <td  class="col-2">{{shipment.shipment_number}}</td>
                                <td>{{detail.shipper_external_id}}</td>
                                <td>{{detail.lot}}</td>
                                <td>{{detail.code}}</td>
                                <td class="col-3">{{detail.description}}</td>
                                <td>{{detail.remission_order}}</td>
                                <td>{{detail.replenishment_order}}</td>
                                <td>{{ detail.quantity }}</td>
                                <td class="col-2" v-if="quantity_required">
                                    <validation-provider
                                        :name="'quantity-'+index1+'-'+index2"
                                        :rules="detail.quantity_available === 0 ? 'required|numeric|custom' : 'required|numeric|min_value:1|max_value:'+detail.quantity_available"
                                        v-slot="{ errors }"
                                    >
                                        <b-form-input
                                            v-model="shipments[index1].details[index2].quantity_send"
                                            :id="'quantity-'+index1+'-'+index2"
                                            type="number"
                                            step="any"
                                            :placeholder="$t('Quantity')"
                                            class="form-control form-control-sm"
                                            :min="0"
                                        />
                                        <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                    </validation-provider>
                                </td>
                                <td class="col-3" v-if="comments_required">
                                    <validation-provider :name="'comments-'+index1+'-'+index2" rules="required" v-slot="{ errors }">
                                        <b-form-input
                                            v-model="shipments[index1].details[index2].comments"
                                            :id="'comments-'+index1+'-'+index2"
                                            type="text"
                                            :placeholder="$t('Comments')"
                                            class="form-control form-control-sm"
                                        />
                                        <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                    </validation-provider>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="row mt-5">
                <div class="col-md-5 offset-md-7 col-12">
                    <div class="row">
                        <div class="col">
                            <button
                                type="button"
                                class="btn btn-outline-light btn-block border border-dark text-uppercase font-size-12 font-weight-bolder py-2 mr-2"
                                @click="onBack"
                            >
                                {{$t('Previous')}}
                            </button>
                        </div>
                        <div class="col">
                            <button
                                type="submit"
                                class="btn btn-primary_v2 btn-block text-uppercase font-size-12 font-weight-bolder py-2"
                                :disabled="disabledNext || isEmptyProducts"
                            >
                                {{$t('Save')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </validation-observer>
</template>

<script>

import { extend } from "vee-validate";
import i18n from '@/i18n'

extend('custom', {
    validate(value) {
        return false
    },
    message: i18n.t('Todas las piezas fueron ya registradas')
});

export default {
    name: "SelectProducts",
    props: {
        shipments: Array,
        onSave: Function,
        onBack: Function,
        fetching: Boolean,
        quantity_required: Boolean,
        comments_required: Boolean,
        disabledNext: Boolean,
    },
    data(){
        return {
            quantity: null,
        }
    },
    methods: {
        async onSubmit(validate){
            if(!(await validate())){
                return;
            }
            this.onSave();
        }
    },
    computed: {
        isEmptyProducts(){
            return this.shipments.length === 0 || !_.some(_.find(this.shipments, e => _.has(e, 'details')), Boolean);
        }
    },
}
</script>

<style lang="scss" scoped>
.table > thead > tr > th { border-top: 0; border-bottom: 0; }
</style>
