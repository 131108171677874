<template>
  <div>
    <page-header  :title="$t($route.meta.title)"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-12">
            <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
              <template v-slot:right>
                <div class="btn-group" role="group" aria-label="toolbar">
                  <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getTransfers(filters)" type="button" class="btn btn-light">
                    <i class="far fa-sync"></i>
                  </button>
                </div>
              </template>
            </filter-bar>
          </div>
        </div>

        <transfer-table v-model="selected" :filters="filters" v-on:page-change="pageChange" />

      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import TransferTable from "@/components/tables/TransferTable";

export default {
  name: "Transfers",
  components: {
    FilterBar,
    TransferTable
  },
  mixins: [filterMixin],
  data(){
    return {
      selected: [],
      options: [],
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapState('factoring', ['factoring']),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('transfers',['getTransfers']),
    init(){
      this.options = [
        {
          icon: 'far fa-key',
          label: 'ID',
          type: 'text',
          filterProperty: 'id',
        },
        {
            icon: 'far fa-sliders-h-square',
            label: this.$t('Status'),
            type: 'single',
            filterProperty: 'status',
            values: [
                {
                    text: this.$t('status.transfer.pending'),
                    value: 'pending'
                },
                {
                    text: this.$t('status.transfer.in_progress'),
                    value: 'in_progress'
                },
                {
                    text: this.$t('status.transfer.completed'),
                    value: 'completed'
                },
                {
                    text: this.$t('status.transfer.declined'),
                    value: 'declined'
                },
                {
                    text: this.$t('status.transfer.cancelled'),
                    value: 'cancelled'
                }
            ],
            valueType: 'string'
        },
        {
          icon: 'far fa-key',
          label: this.$t('Load'),
          type: 'text',
          filterProperty: 'load_id',
        },
      ];

      let filters = {};
      // Use meta filters
      if(this.$route.meta.paramFilters){
          for(let key of this.$route.meta.paramFilters){
              filters[key] = this.$route.params[key];
          }
      }
      if(this.$route.meta.filters){
          filters = {...filters, ...this.$route.meta.filters};
      }
      
      this.defaultFilters = filters;

      let toolbarFilters = {};
      for(let item of this.options){
          if(this.$route.query.hasOwnProperty(item.filterProperty)){
              toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
          }
      }
      if(!toolbarFilters.status){
        //toolbarFilters.status = 'in_progress';
      }
      this.toolbarFilters = toolbarFilters;

    }
  }
}
</script>

<style scoped>

</style>