<template>
  <div class="row align-items-center">
    <div class="col-md-4 pr-0">
      <form-input
          v-model="form.field"
          rules="required"
          type="group-select"
          :name="name+'field'"
          :options="fields"
          layout="vertical"
          class="border-0"
          :placeholder="$t('Field')"
          v-on:change="reset"
          group-values="items"
          group-label="group"
          :group-select="false"
          :multiple="false"
      />
    </div>
    <div class="col-md-3 px-0">
      <template v-if="form.field">
        <form-input
            v-model="form.operator"
            rules="required"
            type="select"
            :name="name+'operator'"
            :options="field ? field.operators : []"
            layout="vertical"
            class="border-0"
            :placeholder="$t('Operator')"
            v-on:change="onOperatorChange"
        />
      </template>

    </div>
    <div class="col-md-4 px-0" >
      <template v-if="form.operator">
        <div class="field-wrapper border-0" v-if="['driver_id','truck_id','trailer_id'].indexOf(form.field) > -1">
          <div class="row align-items-center ">
            <div class="col-md-12">
              <validation-provider v-if="form.field ==='driver_id'" name="driver_id" rules="required" v-slot="{ errors }">
                <user-input v-model="form.value" :track-by-id="true" :filters="{roles: 'driver'}" :multiple="['in','not_in'].indexOf(form.operator) > -1"/>
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </validation-provider>
              <validation-provider v-if="form.field ==='truck_id'" name="truck_id" rules="required" v-slot="{ errors }">
                <truck-input v-model="form.value" :track-by-id="true" :filters="{}" :multiple="['in','not_in'].indexOf(form.operator) > -1"/>
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </validation-provider>
              <validation-provider v-if="form.field ==='trailer_id'" name="trailer_id" rules="required" v-slot="{ errors }">
                <trailer-input v-model="form.value" :track-by-id="true" :filters="{}" :multiple="['in','not_in'].indexOf(form.operator) > -1"/>
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </div>
        </div>

        <form-input
            v-else
            v-model="form.value"
            rules="required"
            :type="field.type"
            :name="name+'value'"
            :options="field.options"
            layout="vertical"
            class="border-0"
            :placeholder="$t('Value')"
            :multiple="['in','not_in'].indexOf(form.operator) > -1"
        />
      </template>

    </div>
    <div class="col-sm-1 col-md-1">
      <a @click="$emit('remove')" href="javascript:void(0);" class="text-danger mr-2">
        <i v-b-tooltip.hover :title="$t('Remove')" class="far fa-times-circle btn-icon"  />
      </a>
    </div>
  </div>
</template>

<script>
import events from '@/constants/eventIcons';
import TruckInput from "@/components/form/TruckInput";
import TrailerInput from '@/components/form/TrailerInput';
import UserInput from '@/components/form/UserInput';
import CarrierSkillsInput from "@/components/form/CarrierSkillsInput";
import CarrierInput from '@/components/form/CarrierInput';
import ShipperInput from '@/components/form/ShipperInput';
import {mapGetters, mapState} from "vuex";
import Multiselect from 'vue-multiselect';
import {issues} from '@/constants/status';

export default {
  name: "ConditionForm",
  components:{
    TruckInput,
    TrailerInput,
    UserInput,
    CarrierSkillsInput,
    CarrierInput,
    ShipperInput,
    Multiselect
  },
  props:{
    value:{
      type: Object,
      required: true
    },
    name:{
      type: String,
      default(){
        return '';
      }
    },
  },
  data(){
    return {
      operators: [
        {text: this.$t('Equal'), value:'='},
        {text: this.$t('Not equal'), value:'!='},
        {text: this.$t('Less than'), value:'<'},
        {text: this.$t('Less equal than'), value:'<='},
        {text: this.$t('Greater than'), value:'>'},
        {text: this.$t('Greater equal than'), value:'>='},
        {text: this.$t('Contain'), value:'like'},
        {text: this.$t('Where in'), value:'in'},
        {text: this.$t('Where not in'), value:'not_in'}
      ],
      form:{
        field: null,
        operator: null,
        value: null
      },
      selected: null,
      op:null
    }
  },
  computed:{
    ...mapGetters('catalogs',['loadTypes','truckTypes','trailerTypes','configuration','commodities','issueTypes']),
    ...mapState('forms',['customLoadForm','carrierLoadForm','shipperLoadForm','adminLoadForm']),
    fields(){
      const items =[
        {text: this.$t('Status'), operators: this.getFieldOperators(['=','!=','<','<=','>','>=','in','not_in']), value: 'status',type:'select', options:Object.keys(events).map(item => ({text: this.$t(item), value: item}))},
        {text: this.$t('Load type'),operators: this.getFieldOperators(['=','!=','in','not_in']), value:'load_type_id',type:'select', options: this.loadTypes.map(item => ({text: item.name, value: item.id}))},
        {text: this.$t('Driver'),operators: this.getFieldOperators(['=','!=']), value: 'driver_id'},
        {text: this.$t('Truck'),operators: this.getFieldOperators(['=','!=']), value: 'truck_id'},
        {text: this.$t('Truck type'),operators: this.getFieldOperators(['=','!=','in','not_in']), value: 'truck_type_id',type:'select', options: this.truckTypes.map(item => ({text: item.name, value: item.id}))},
        {text: this.$t('Trailer'),operators: this.getFieldOperators(['=','!=']), value: 'trailer_id'},
        {text: this.$t('Trailer type'),operators: this.getFieldOperators(['=','!=','in','not_in']), value: 'trailer_type_id',type:'select', options: this.trailerTypes.map(item => ({text: item.name, value: item.id}))},
        {text: this.$t('Configuration'),operators: this.getFieldOperators(['=','!=','in','not_in']), value: 'configuration_id',type:'select', options: this.configuration.map(item => ({text: item.name, value: item.id}))},
        {text: this.$t('Commodity'),operators: this.getFieldOperators(['=','!=','in','not_in']), value: 'commodity_id',type:'select', options: this.commodities.map(item => ({text: item.name, value: item.id}))},
        {text: this.$t('Weight'),operators: this.getFieldOperators(['=','!=','<','<=','>','>=']), value: 'weight', type:'number'},
      ];
      let profileFields = [];
      if(this.$route.params.profile === 'admin' && this.adminLoadForm){
        profileFields = this.adminLoadForm.fields;
      }else if(this.$route.params.profile === 'carrier' && this.carrierLoadForm){
        profileFields = this.carrierLoadForm.fields;
      }else if(this.$route.params.profile === 'shipper' && this.shipperLoadForm){
        profileFields = this.shipperLoadForm.fields;
      }

      for(let field of profileFields){
        let ops = [];
        switch (field.type){
          case "text":
            ops = ['=','!=','<','<=','>','>=','like'];
            break;
          case "number":
            ops = ['=','!=','<','<=','>','>='];
            break;
          case "checkbox":
            ops = ['=','!=','in','not_in'];
            break;
          case "select":
            ops = ['=','!=','<','<=','>','>=','in','not_in'];
            break;
          default:
            ops = ['=','!=','<','<=','>','>=','like','in','not_in'];
            break;
        }
        items.push({
          text: field.title,
          operators: this.getFieldOperators(ops),
          value:`${this.$route.params.profile}_fields.${field.id}`,
          type:field.type, options: field.options
        });
      }
      
      const issuesItems = [
        {
          text: `${this.$t('Issue status')}`,
          operators: this.getFieldOperators(['=','!=','in','not_in']), 
          value: 'issue_status', 
          type:'select',
          options: issues.map(item => ({text: this.$t('status.issues.'+item), value: item}))
        },
        {
          text: `${this.$t('Issue type')}`,
          operators: this.getFieldOperators(['=','!=','in','not_in']), 
          value: 'issue_type', 
          type:'select',
          options: this.issueTypes.map(item => ({text: item.name, value: item.id}))
        },
      ];
      return [
        {
          group: this.$t('Load'),
          items: items
        },
        {
          group: this.$t('Issue'),
          items: issuesItems
        }
      ];
    },
    field(){
      let el;
      for(let item of this.fields){
          el = item.items.find(option => option.value === this.form.field);
          if(el){
              break;
          }
      }
      return el;
    }
  },
  watch:{
    form: {
      deep: true,
      handler(value){
        this.$emit('input', value)
      }
    },
  },
  created() {
    if(this.value){
      this.form = this.value;
    }
  },
  methods:{
    reset(){
      this.form.operator= null;
      this.form.value = null;
    },
    onOperatorChange(value){
      let form = _.cloneDeep(this.form);
      form.operator = value;
      if(value){
        if(['in','not_in'].indexOf(value) > -1){
          form.value = [];
        }else{
          form.value = null;
        }
      }

      this.form = form;
    },
    getFieldOperators(operators = ['=','!=','<','<=','>','>=','like','in','not_in']){
      return this.operators.filter(item => operators.indexOf(item.value) > -1);
    }
  }
}
</script>

<style scoped>

</style>
