<template>
  <div class="register" style="">
    <div class="register_title_back register_section cursor-pointer" @click="back()"> <i class="fa fa-chevron-left"/>
      {{ $t('Back') }} </div>
    <div class="register_title register_section mb-0">{{$t('Recover password')}}</div>
    <div class="register_label register_section">
      <div class="register_label_label mb-0">{{$t('Write your new password')}}</div>
    </div>
    <validation-observer ref="observer" v-slot="{ validate , errors}">
      <loading-overlay :loading="loading">
        <form  @submit.stop.prevent="submit(validate)" >
          <div class="register_input_generic register_section">
            <div class="register_input_generic_label">{{ $t('New Password') }}</div>
            <validation-provider name="new_password" rules="required|min:4" v-slot="{ errors }">
              <p :class="{ 'control': true }">
                <input class="login_input_generic_input"  v-model="model.new_password"  :class="{'input': true, 'is-danger': errors.length }"  name="new_password" type="password" placeholder="">
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </p>
            </validation-provider>
          </div>
          <div class="register_input_generic register_section">
            <div class="register_input_generic_label">{{ $t('Confirm Password') }}</div>
            <validation-provider name="password"   rules="required|min:4|confirmed:new_password" v-slot="{ errors }">
              <p :class="{ 'control': true }">
                <input class="login_input_generic_input"  v-model="model.confirm_password"  :class="{'input': true, 'is-danger': errors.length }"  name="password" type="password" placeholder="">
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </p>
            </validation-provider>
          </div>
          <div class="register_section register_action" >
            <button  :class="{'disabled':(hasErrors(errors))}"    class="btn btn-primary_v2 btn-block  text-uppercase  login_btn" type="submit" >{{$t('continue')}}</button>
          </div>
        </form>
      </loading-overlay>
    </validation-observer>
    <alert-modal :visible="notification.visible" :success="notification.success"  :title="notification.title" :message="notification.message" :sub_message="notification.sub_message" :reload_on_close="false" v-on:close="closeNotificationCustom" />
  </div>
</template>

<script>

import {resetPassword} from '../../../utils/auth.js'
import VueJwtDecode from "vue-jwt-decode";
import AlertModal from "../../../components/modals/AlertModal";
import AlertModalMixin from "../../../mixins/AlertModalMixin";
import LoadingOverlay from "../../../components/common/LoadingOverlay";

export default {
  name: "ResetPassword",
  data(){
    return {
      model: {},
      jwt:{},
      redirect: false,
      loading: false,
    }
  },
  components: {
    LoadingOverlay,
    AlertModal
  },
  mixins:[AlertModalMixin],
  created(){
    if (this.$route.query.token){
      this.jwt = VueJwtDecode.decode(this.$route.query.token.replace('?token=', ''));
    }else{
      this.$router.push({name:"login"})
    }
  },
  methods:{
    back(){
      this.$router.push({name:"login"})
    },
    async submit(validate) {
      if (!(await validate()) || this.loading) {
        this.$bvToast.toast(this.$t('Please, check the form'), {
          title: "Error",
          variant: 'danger',
          solid: true
        });
        return;
      }
      let data = _.cloneDeep(this.model)
      let username = this.jwt.email
      let code = this.jwt.code
      this.loading = true;
      resetPassword(username, code, data.new_password).then((e) => {
        this.notificationError(this.$t('Success'), this.$t('Reset password successfully'), '');
        this.redirect = 'login'
      }).catch((err) => {
        this.notificationError("Error", err.code, err.message);
        if (err.code==='ExpiredCodeException'){
          this.redirect = 'login'
        }
        if (err.code==='LimitExceededException'){
          this.redirect = 'login'
        }
      }).finally(()=>{
        this.loading = false;
      })
    },
    hasErrors(err){
      return !!_.findKey(err,o=>o.length>0)
    },
    closeNotificationCustom(err){
      this.notification.visible = false;
      if (this.redirect){
        this.$router.push({
          name: this.redirect
        })
      }
      this.redirect = null
    },
  }
}
</script>

<style scoped>

</style>