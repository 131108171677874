<template >
    <div class="px-md-5">
        <div class="text-muted color-gray-888 font-size-16">{{ $t('Select a unit that best suits your needs') }}:</div>
        <div class="row mt-4" v-if="fetching">
            <div class="col-md-5 col-sm-12 border p-0" :class="[item === 2 ? 'ml-5':'']" v-for="item in [1,2]" :key="item">
                <div class="text-center d-flex justify-content-center align-items-center">
                    <b-skeleton width="35%" class="my-3" />
                </div>
                <div class="text-center border-top border-bottom d-flex justify-content-center align-items-center">
                    <b-skeleton width="15%" class="my-3" />
                </div>
                <div class="font-size-12 px-4 py-1 border-bottom" v-for="entry in [75, 55, 35, 15]" :key="entry">
                    <b-skeleton :width="entry+'%'"/>
                </div>
                <div class="font-size-12 px-4 py-3 d-flex justify-content-center align-items-center">
                    <b-skeleton type="button" width="85%" />
                </div>
            </div>
        </div>
        <div class="row mt-4" v-else>
            <div class="col-md-5 col-sm-12">
                <div style="position:inherit;overflow:hidden">
                    <div class="bg-primary text-white text-center py-3 radius-right">
                        <div class="ribbon">{{$t('Best rate')}}</div>
                        {{ $t('Units of') }}
                        <br>
                        <span class="font-weight-bolder">0 a 10 {{ $t('years') }}</span>
                    </div>
                </div>
                <div class="text-center ml-n3 rectangle d-flex justify-content-center align-items-center">
                    <h1 class="h1 text-primary font-weight-bold pl-5">
                        ${{this.rates[1].sale_price | money}}
                        <span class="font-size-16">{{ this.rates[1].currency }}</span>
                    </h1>
                </div>
                <div class="bg-primary text-white text-center font-size-14 pt-3 pb-2">{{ $t('Benefits') }}:</div>
                <div class="bg-primary text-white font-size-12 px-4 py-2 border-bottom font-weight-lighter">
                    {{$t('Unit Service Requested (Standard)')}}.
                </div>
                <div class="bg-primary text-white font-size-12 px-4 py-2 border-bottom font-weight-lighter">
                    {{ $t("Type of equipment older than 10 years old") }}.
                </div>
                <div class="bg-primary text-white font-size-12 px-4 py-2 border-bottom font-weight-lighter">
                    {{ $t("Includes unit with GPS + Panic Button + Engine Stop") }}.
                </div>
                <div class="bg-primary text-white font-size-12 px-4 py-2 font-weight-lighter">
                    <p>{{ $t("SUA Operator and RControl") }}.</p>
                    <button
                        type="button"
                        class="btn btn-primary_v2 btn-block text-uppercase font-size-12 font-weight-bold mt-4 mb-2 text-dark"
                        @click="selectRate(1)"
                        v-if="rate !== 1"
                    >
                        {{ $t('Select') }}
                    </button>
                    <div
                        class="bg-white text-dark rounded-lg p-1 text-uppercase d-flex justify-content-center align-items-center mb-2"
                        role="button"
                        @click="selectRate(null)"
                        v-if="rate === 1"
                    >
                        <div class="font-size-12 font-weight-bold font-family-inter">{{$t('Selected rate')}}</div>
                        <div><i class="ri-checkbox-circle-line text-primary font-size-18 ml-1"/></div>
                    </div>
                </div>
            </div>

            <div class="col-md-5 col-sm-12 ml-5 border p-0">
                <div>
                    <div class="text-center py-3 radius-right">
                        {{ $t('Units greater') }}
                        <br>
                        <span class="font-weight-bolder">a 10 {{ $t('years') }}</span>
                    </div>
                </div>
                <div class="text-center border-top border-bottom d-flex justify-content-center align-items-center">
                    <h1 class="h1 text-primary font-weight-bold">
                        ${{this.rates[0].sale_price | money}}
                        <span class="font-size-16">{{ this.rates[1].currency }}</span>
                    </h1>
                </div>
                <div class="text-center font-size-14 pt-3 pb-2">{{ $t('Benefits') }}:</div>
                <div class="font-size-12 px-4 py-2 border-bottom font-weight-lighter text-gray-888">
                    {{$t('Unit Service Requested (Standard)')}}.
                </div>
                <div class="font-size-12 px-4 py-2 border-bottom font-weight-lighter text-gray-888">
                    {{ $t("Type of equipment older than 10 years old") }}.
                </div>
                <div class="font-size-12 px-4 py-2 border-bottom font-weight-lighter text-gray-888">
                    {{ $t("Includes Basic Security (GPS + Panic Button + Engine Stop)") }}.
                </div>
                <div class="font-size-12 px-4 py-2 font-weight-lighter text-gray-888">
                    <p>{{ $t("SUA Operator") }}.</p>
                    <button
                        type="button"
                        class="btn btn-outline-dark btn-block text-uppercase font-size-12 font-weight-bold mt-4 mb-2"
                        @click="selectRate(0)"
                        v-if="rate !== 0"
                    >
                        {{ $t('Select') }}
                    </button>
                    <div
                        class="btn-primary_v2 rounded-lg p-1 text-uppercase d-flex justify-content-center align-items-center mb-2"
                        role="button"
                        @click="selectRate(null)"
                        v-if="rate === 0"
                    >
                        <div class="font-size-12 font-weight-bold font-family-inter">{{$t('Selected rate')}}</div>
                        <div><i class="ri-checkbox-circle-line font-size-18 ml-1"/></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-5 col-sm-12" />
            <div class="col-md-5 offset-md-5 col-sm-12 ml-5 p-0">
                <buttons
                    :back="() => setStep(4)"
                    :labelNext="$t('Next')"
                    :next="() => setStep(6)"
                    :disabledNext="rate === null"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import RateLoading from "../../common/loadings/RateLoading";
import Buttons from '@/components/marketplace/equipment/Buttons';
/**
 * Este componente renderiza las dos cards con las tarifas que puede seleccionar
 * el usuario
 *
 */
export default {
    name: "ServiceDetailRates",
    components: { RateLoading, Buttons },
  props: {

    },
    data() {
        return {
            fetching: true,
            rate: null
        }
    },
    async created() {
        try {
            await this.getRates(this.shipment)
        } catch (error) {
            this.$root.$bvToast.toast(this.$t("Error while calculating rate"), {
                title: this.$t('Error rate'),
                variant: 'danger',
                solid: true
            });
        } finally {
            this.fetching = false
        }

    },
    computed: {
        ...mapState('marketplace', ['shipment']),
        ...mapState('rate', ['rates'])
    },
    methods: {
        ...mapActions('rate', ['getRates']),
        ...mapMutations('marketplace', ['setRate', 'setStep']),
        selectRate(option) {
            this.rate = option;
            let item = _.omit(this.rates[option], ['priority', 'void_porcent']);
            this.setRate(item)
            //this.$emit('next');
        }
    }
}
</script>
<style lang="scss">
.h1{ font-size: 55px!important; }
.radius-right{ border-radius: 10px 100px 0 0; }
.rectangle {
    min-height: 87px;
    width:106%;
    background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%);
    box-shadow: 0 2px 10px 0 rgba(153,153,153,0.5);
}

.ribbon {
    width: 160px;
    height: 32px;
    font-size: 12px;
    text-align: center;
    color: #000;
    font-weight: bold;
    box-shadow: 0px 2px 3px rgb(136 136 136 / 25%);
    background: #E3ED55;
    transform: rotate(320deg);
    position: absolute;
    right: 75%;
    top: 12px;
    padding-top: 7px;
}
</style>
