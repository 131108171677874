<template>
    <div style="margin: 0px; position: relative" class="main-content d-flex" :class="{'bgWhite' : $route.meta.bgWhite === true}">        
        <div  class="nav-sidebar" :class="[collapsed ? 'sidebar-collapsed':'']">
            <div class="d-flex">
                <div style="width: calc(100% - 28px);" id="sidebar-submenu">
                    <p class="title_types_expenses"> {{$t('Expenses Types')}} </p>
                    <slot name="prepend"></slot>
                    <ul id="side-menu" class="metismenu list-unstyled">
                        <template v-for="(item, index) in menu">
                            <li v-if="!item.hidden" :key="'sb-'+index">
                                <a
                                    class="side-nav-link-ref"
                                    :class="$route.query.type == item.value ? 'active submenu-active': ''"
                                    style="cursor:pointer"
                                    @click="changeType(item)"
                                >
                                    <span>{{ $t(item.text) }}</span>
                                </a>

                            </li>
                        </template>
                    </ul>
                </div>
                <div style="height: 100%;width: 28px;">
                    <button class="btn btn-light btn-sm rounded-circle buttonClose" @click="collapsed=!collapsed">
                        <i class="far " :class="[collapsed ? 'fa-chevron-right':'fa-chevron-left']"/>
                    </button>
                </div>
            </div>
        </div>
        <div class="page-content" :class="[collapsed ? 'side-collapsed':'side-expanded']" style="height: 100%">
            <div class="container-fluid">
                <loading v-if="loading" />

                <expense-table :filters="filters" :paginate="true" v-on:page-change="pageChange" v-else></expense-table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import filterMixin from "@/mixins/filterMixin";
import ExpenseTable from "@/components/tables/ExpenseTable";

export default {
    name: "Expenses",
    components: { ExpenseTable},
    mixins: [filterMixin],
    computed:{
        ...mapGetters('catalogs', ['expensesTypes']),
        ...mapState('loads', ['expenses']),
        menu() {
            return this.expensesTypes
        },
    },
    methods:{
        ...mapActions('loads', ['getExpenses']),
        init(){
            let filters = {};

            if (this.$route.meta.paramFilters) {
                for(let key of this.$route.meta.paramFilters){
                    filters[key] = this.$route.params[key];
                }
            }

            if(this.$route.meta.filters) {
                filters = {...filters, ...this.$route.meta.filters};
            }

            this.queryFilters = filters;

            this.getExpenses({...{load_id: this.$route.params.load_id},...this.filters})
                    .then(data => {
                        this.loading = false;
                    })
        },
        changeType(item) {
            this.queryFilters.type = item.value
        }
    },
    created(){
        this.init()
    },
    data(){
        return {
            collapsed: false,
            loading: true,
        }
    }
}
</script>

<style scoped>

    .has-sidebar{
        margin-left: 220px;
    }
    .nav-sidebar {
        position: absolute;
        height: calc(100vh);
        margin-left: -24px;
    }
    .sidebar-collapsed{
        margin-left: -200px;
    }

    .side-expanded{
        margin-left: 220px;
        width: calc(100% - 220px)
    }
    .side-collapsed{
        margin-left: 28px;
         width: calc(100% - 28px);
    }

    .buttonClose{
        height: 28px!important;
        width: 28px!important;
        position: absolute;
        right: -14px;
        top: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        border: 1px solid #E7E1EC;
    }

    .title_types_expenses{
      text-align: center;
      margin: 15px 0;
    }

    .nav-tabs-custom{
      margin-left: -25px;
    }

    .table th, .table td{
      vertical-align: middle!important;
    }

</style>
