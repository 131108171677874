<template>
    <div>
         <page-header :title="$t('Orders')"/>
    
         <fuel-orders-table :filters="filters" v-on:page-change="pageChange" :action="getAllFuelVouchers" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FilterBar from "@/components/filters/FilterBar";
import FuelOrdersTable from '@/components/tables/FuelOrdersTable.vue';
import filterMixin from "@/mixins/filterMixin";

export default {
    name: 'Orders',
    components:{
        FilterBar,
        FuelOrdersTable
    },
    mixins: [filterMixin],
    data(){
        return {
            options: []
        }
    },
    computed: {
        ...mapState('profile',['profile'])
    },
    created(){
        this.init();
    },
    methods:{
        ...mapActions('fuelvouchers',['getAllFuelVouchers']),
        init(){
            if(this.$route.params.profile === 'seller'){
                this.defaultFilters = {seller_id: this.profile.profile.id}
            }

            let toolbarFilters = {};
            for(let item of this.options){
                if(this.$route.query.hasOwnProperty(item.filterProperty)){
                    toolbarFilters[item.filterProperty] = this.$route.query[item.filterProperty];
                }
            }
            this.toolbarFilters = toolbarFilters;
        }
    }
}
</script>
