<template>
  <div class="form-fields">
    <div class="field-wrapper">
      <div class="row align-items-center ">
        <div class="col-md-6">
          <div class="field-label">{{$t('Country')}}<span v-if="required" class="field-required-badge"/></div>
          <div class="field-help"></div>
        </div>
        <div class="col-md-6">
          <validation-provider :name="prefix_name+'country'" :rules="required ? 'required':''" v-slot="{ errors }">
            <country-input :id="id+'country'" v-model="form.country" :disabled="disabled"/>
            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>
    </div>
    <div class="field-wrapper">
      <div class="row align-items-center ">
        <div class="col-md-6">
          <div class="field-label">{{$t('Street')}}<span v-if="required" class="field-required-badge"/></div>
          <div class="field-help"></div>
        </div>
        <div class="col-md-6">
          <validation-provider  :name="prefix_name+'line_1'" :rules="required ? 'required':''" v-slot="{ errors }">
            <input style="display: none" :id="id+'line_1'" v-model="form.line_1" />
            <gmap-autocomplete
                ref="gmap_autocomplete"
                :key="form.country+'_gm'"
                class="form-control"
                :placeholder="form.line_1 ? form.line_1:$t('Street')"
                @place_changed="setPlace"
                @change="change"
                :options="{componentRestrictions: { country: form.country}, types:['address']}"
                :disabled="disabled"
            />
            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>
    </div>
    <div class="field-wrapper">
      <div class="row align-items-center ">
        <div class="col-md-6">
          <div class="field-label">{{$t('Zip code')}}<span v-if="required" class="field-required-badge"/></div>
          <div class="field-help"></div>
        </div>
        <div class="col-md-6">
          <validation-provider :name="prefix_name+'zip_code'" :rules="required ? 'required':''" v-slot="{ errors }">
            <b-form-input :id="id+'zip_code'" :disabled="disabled" v-model="form.zip_code" @change="onPostalCodeChange" type="text" placeholder=""></b-form-input>
            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>
    </div>
    <div class="field-wrapper">
      <div class="row align-items-center ">
        <div class="col-md-6">
          <div class="field-label">{{$t('City')}}</div>
          <div class="field-help"></div>
        </div>
        <div class="col-md-6">
          <validation-provider :name="prefix_name+'city'"  v-slot="{ errors }">
            <b-form-input :id="id+'city'" disabled v-model="form.city" type="text" placeholder=""></b-form-input>
            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>
    </div>
    <div class="field-wrapper">
      <div class="row align-items-center ">
        <div class="col-md-6">
          <div class="field-label">{{$t('State')}}</div>
          <div class="field-help"></div>
        </div>
        <div class="col-md-6">
          <validation-provider :name="prefix_name+'state'" v-slot="{ errors }">
            <b-form-input :id="id+'state'" disabled v-model="form.state" type="text" placeholder=""></b-form-input>
            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>
    </div>
    <div class="field-wrapper">
      <div class="row align-items-center ">
        <div class="col-md-6">
          <div class="field-label">{{$t('Coordinates')}} <span v-if="required" class="field-required-badge"/></div>
          <div class="field-help">{{$t('Latitude')}} / {{$t('Longitude')}}</div>
        </div>
        <div class="col-md-6">
          <div class="row">
              <div class="col-md-6">
                <validation-provider :name="prefix_name+'latitude'" v-slot="{ errors }">
                  <b-form-input :id="id+'latitude'" :disabled="disabled" v-model="form.latitude" type="number" step="0.0000001" :placeholder="$t('Latitude')"></b-form-input>
                  <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <validation-provider :name="prefix_name+'longitude'" v-slot="{ errors }">
                  <b-form-input :id="id+'longitude'" :disabled="disabled" v-model="form.longitude" type="number" step="0.0000001"  :placeholder="$t('Longitude')"></b-form-input>
                  <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                </validation-provider>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field-wrapper">
      <div class="row align-items-center ">
        <div class="col-md-6">
          <div class="field-label">{{$t('References')}}</div>
          <div class="field-help">{{$t("Address references")}}</div>
        </div>
        <div class="col-md-6">
          <validation-provider :name="prefix_name+'description'" v-slot="{ errors }">
            <b-form-textarea
                :id="id+'description'"
                v-model="form.description"
                placeholder=""
                rows="2"
                max-rows="10"
                :disabled="disabled"
            ></b-form-textarea>
            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>
    </div>
    <div v-if="showMap">
      <GmapMap
          :center="{lat: form.latitude ? Number(form.latitude) :19.475867, lng: form.longitude ? Number(form.longitude):-99.225651}"
          :zoom="12"
          style="width: 100%; height: 250px"
          :options="mapOptions"
          ref="mapRef"
          @click="onClick"
      >
        <template v-if="form.latitude && form.longitude">
          <GmapMarker
              :position="{lat: Number(form.latitude), lng: Number(form.longitude)}"
              :icon="icon"
              :draggable="!disabled"
              @dragend="onDragEnd"
          ></GmapMarker>
        </template>
      </GmapMap>
    </div>
  </div>
</template>

<script>
import CountryInput from "./CountryInput";
import {mapActions} from "vuex";
import mapStyle from "@/constants/mapStyle";
export default {
  name: "AddressComponents",
  components:{
    CountryInput
  },
  props:{
    title:{
      type: String,
      default(){
        return 'Address';
      }
    },
    value:{
      type: Object,
      required: true
    },
    id:{
      type: String,
      default(){
        return '';
      }
    },
    disabled:{
        type : Boolean,
        default(){
            return false;
        }
    },
    showMap:{
        type : Boolean,
        default(){
            return true;
        }
    },
    required: {
      type: Boolean,
      default(){
          return true;
      }
    },
    prefix_name: {
        type: String,
        default(){
            return ''
        }
    },
  },
  data(){
    return {
      form: {
        line_1: null,
        line_2: null,
        line_3: null,
        city: null,
        state: null,
        country: 'MX',
        description: null,
        latitude: null,
        longitude: null
      },
      street : null,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
        styles: mapStyle
      },
      icon: {
        scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
        url: require('../../assets/images/txp/common/end_point.png'),
      },
      geocoder: null
    }
  },
  watch:{
    form: {
      deep: true,
      handler(value){
        this.$emit('input', value);
      }
    },
    value(newValue){
      this.form = newValue;
    }
  },
  created() {
    this.form = this.value;
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      this.geocoder = new google.maps.Geocoder();
    });
  },
  methods: {
    ...mapActions('addresses',['fetchPostalCodes']),
    clear(){
      this.$refs.gmap_autocomplete.$refs.input.value = "";
    },
    onPostalCodeChange(codes){
      this.form.zip_code = codes;
      this.fetchPostalCodes({codes: codes, country: this.form.country})
          .then(data=>{
            if(data.total > 0){
              let form = _.cloneDeep(this.form);
              form.city = data.results[0].city;
              form.state = data.results[0].state;
              if(!form.latitude && !form.longitude){
                form.latitude = data.results[0].toFixed(7);
                form.longitude = data.results[0].toFixed(7);
              }
              this.form = form;
            }
          })
          .catch(error =>{

          })
    },
    setPlace(place){
      this.form.city= null;
      this.form.state= null;
      this.form.latitude= null;
      this.form.longitude= null;

      let form = _.cloneDeep(this.form);
      form.latitude = Number(place.geometry.location.lat().toFixed(7));
      form.longitude = Number(place.geometry.location.lng().toFixed(7));
      form.line_1 = place.name;
      this.street = place.name;
      this.form = form;

      this.searchAddressComponent(place.address_components, 'postal_code', (element)=>{
        this.onPostalCodeChange(element.long_name);
      });
    },
    searchAddressComponent(components, type, cb){
      for(let element of components){
        if(element.types.indexOf(type) > -1){
          cb(element);
          break;
        }
      }
    },
    change(e){
      setTimeout(()=>{
        if(!this.street){
          let form = _.cloneDeep(this.form);
          form.line_1 = e.target.value;
          this.form = form;
        }
      },500);

    },
    onDragEnd(event){
      let form = _.cloneDeep(this.form);
      form.latitude = Number(event.latLng.lat().toFixed(7));
      form.longitude = Number(event.latLng.lng().toFixed(7));
      this.form = form;

      this.geocoder.geocode(
          { location: {lat: form.latitude, lng: form.longitude} },
          (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                this.searchAddressComponent(results[0].address_components,'country', (element)=>{
                  this.form.country = element.short_name;
                });
                this.searchAddressComponent(results[0].address_components, 'postal_code', (element)=>{
                  this.onPostalCodeChange(element.long_name);
                });
                this.searchAddressComponent(results[0].address_components,'route', (element)=>{
                  this.form.line_1 = element.long_name;
                });

              } else {
                //console.log("No results found");
              }
            } else {
              //console.log("Geocoder failed due to: " + status);
            }

          }
      );


    },
    onClick(event){
      if(!this.form.latitude  && !this.form.longitude){
        this.onDragEnd(event);
      }
    }
  }
}
</script>

<style scoped>

</style>
