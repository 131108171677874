<template>
    <div>
        <div class="card col-9 p-0 overflow-hidden" style="border-radius: 20px;">
            <div class="card-header p-2" style="background-color: #EDFBFF !important;">
                <span class="col-12 d-flex justify-content-center">
                    <small style="font-weight: bold;">{{ $t('Company name') }}:</small>
                </span>
                <span class="col-12 d-flex justify-content-center">
                    <div class="col-12 d-flex p-2 overflow-hidden" style="border-radius: 18px 0px 0px 0px;">
                        <div
                            class="rounded-circle bg-success text-white d-flex p-2 justify-content-center align-items-center">
                            <span><i class="fas fa-building"></i></span>
                        </div>
                        <div class="d-flex align-items-center ml-2">
                            <span class="font-weight-bold text-dark">
                                {{ load.shipper.name }}
                            </span>
                        </div>
                    </div>
                </span>
            </div>
            <div class="card-body text-align-center">
                <span class="d-flex justify-content-center">
                    <small style="font-weight: bold;">{{ $t('Purchase price') }}:</small>
                </span>
                <div class="text-primary d-flex justify-content-center align-items-center" style="font-weight: bold;">
                    <span class="h2 text-primary mr-2">${{ load.price }}</span><small>{{ load.currency }}</small>
                </div>
            </div>
        </div>
        <div class="col-9">
            <div class="col-12 mb-3">
                <div class="font-size-12 d-flex justify-content-start align-items-center mt-1">
                    <b-form-checkbox v-model="acepted" name="checkbox-1" button-variant="secondary" />
                    <div>
                        {{ $t('I have read and accept the') }}
                        <span class="text-primary">
                            {{ $t('Terms and Conditions') }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <button class="btn btn-primary_v2 form-control" :disabled="!acepted" @click="onAccept">
                    {{ $t('I want to carry this load') }}
                </button>
            </div>
        </div>
        <div class="col-8">
            <hr>
        </div>
        <div class="col-9">
            <div class="col-12 row m-3">
                <div class="col-3">
                    <div
                        class="rounded-circle bg-primary text-white d-flex p-2 justify-content-center align-items-center">
                        <span class="h4 text-white m-0"><i class="far fa-comment-dots"></i></span>
                    </div>
                </div>
                <div class="col-9 d-flex flex-column">
                    <small style="font-weight: bold;">{{ $t('Do you need help?') }}</small>
                    <span class="h6 text-primary">{{ $t('Customer service') }}
                        <span class="ml-3" style="color: grey !important;">
                            <i class="fas fa-chevron-right"></i>
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <span style="font-size: 12px;">{{ $t('Monday to Friday') }}</span>
                <span class="ml-2" style="font-weight: bold; font-size: 12px;">9:00 AM a 6:00 PM</span>
            </div>
        </div>
        <assign-modal :visible="visible" :title="`${$t('Load')}: ${loadId}`" @close="closeModal" />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import AssignModal from './AssignModal.vue';

export default {
    name: 'LoadCardDetails',
    components: {
        AssignModal
    },
    props: {
        loadId: Number
    },
    data() {
        return {
            acepted: false,
            visible: false
        }
    },
    computed: {
        ...mapState('loads', ['load'])
    },
    methods: {
        onAccept() {
            this.visible = true;
        },
        closeModal() {
            this.visible = false;
        }
    }
}
</script>
<style lang="">
    
</style>