<template>
    <div>
        <page-header :title="$t($route.meta.title)"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                <div class="col-md-12">
                    <filter-bar :options="options" v-model="toolbarFilters" v-on:filter-removed="filterRemoved">
                    <template  v-slot:right>
                        <div class="btn-group" role="group" aria-label="toolbar">
                            <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getTeams(filters)" type="button" class="btn btn-light">
                                <i class="far fa-sync"></i>
                            </button>
                            <router-link v-b-tooltip.hover :title="$t('Add')" :to="{name: 'settings.teams.add'}" class="btn btn-light">
                                <i class="far fa-plus"></i>
                            </router-link>
                        </div>
                       
                    </template>
                    </filter-bar>
                </div>
                </div>

                <teams-table :filters="filters" v-on:page-change="pageChange" />

            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import FilterBar from "@/components/filters/FilterBar";
import filterMixin from "@/mixins/filterMixin";
import deleteManyMixin from "@/mixins/deleteMany";
import formMixin from "@/mixins/formMixin";
import TruckTypeTable from "@/components/tables/TruckTypeTable";
import TeamsTable from '@/components/tables/TeamsTable.vue';

export default {
    name: 'Teams',
    components:{
        FilterBar,
        TruckTypeTable,
        TeamsTable
    },
    mixins: [filterMixin, deleteManyMixin, formMixin],
    data(){
        return {
            selected : [],
            options: [],
        }
    },
    created(){
        this.init();
    },
    methods: {
        ...mapActions('teams',['getTeams']),
        init(){

        },
        resetForm(){
            this.form = {};
        },
        editItem(item){
            this.form = _.cloneDeep(item);
            this.$refs['modal-edit'].show();
        }
    }
    
}
</script>
