<template>
  <div class="form_main">
    <div class="">
      <p class="back cursor-pointer" @click="back()" ><i class="far fa-chevron-left"></i> Regresar </p>
    </div>
    <h3 class="form_title" >Información de la compañía</h3>
    <p class="register_help_info">Por favor complata los campos faltantes:</p>


    <loading-overlay :loading="loading">
      <validation-observer ref="observer" v-slot="{ validate }">
        <b-form @submit.stop.prevent="onSubmit(validate)">
          <div class="" style="width: 100%;">

            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="form-group">
                  <validation-provider name="name" rules="required" v-slot="{ errors }">
                    <label for="name">¿Perteneces a alguna empresa?</label>
                    <b-form-radio-group
                        id="radio-group-2"
                        v-model="hasCompany"
                        class="text-left radio_button"
                        name="radio-sub-component"
                    >
                      <b-form-radio :value="item['value']"  v-for="(item,index) in hasCompanyOptions" :key="'-ra-f-'+index">{{item['text']}}</b-form-radio>
                    </b-form-radio-group>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div class="row" v-if="hasCompany">
              <div class="col-md-12">
                <div class="form-group">
                  <validation-provider name="name" rules="required" v-slot="{ errors }">
                    <label for="name">Nombre de la empresa &nbsp; <span>(*Campos obligatorios):</span></label>
                    <b-form-input v-model="form.name" id="name" type="text" ></b-form-input>
                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <validation-provider name="rfc" rules="required" v-slot="{ errors }">
                    <label for="rfc">RFC</label>
                    <b-form-input v-model="form.rfc" id="rfc" type="text" ></b-form-input>
                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-block login-btn btn-primary  font-weight-bold  mt-4 btn-md text-uppercase"  :disabled="false"  >Ingresar</button>
          </div>
        </b-form>
      </validation-observer>
    </loading-overlay>


    <alert-modal :visible="notification.visible" :success="notification.success"  :title="notification.title" :message="notification.message" :sub_message="notification.sub_message" :reload_on_close="false" v-on:close="closeNotificationMain" />

  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import AddressForm from "@/components/form/AddressForm";
import {mapActions, mapMutations, mapState} from "vuex";
import AlertModal from "@/components/modals/AlertModal";
import AlertModalMixin from "@/mixins/AlertModalMixin";
import {cloneDeep} from "lodash";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import {getUser} from "../../utils/auth";
export default {
  name: "FormOnboarding",
  mixins:[AlertModalMixin],
  components:{
    LoadingOverlay,
    AlertModal,
    Multiselect
  },
  data(){
    return {
      form: {},
      success: false,
      loading: false,
      hasCompany: true,
      hasCompanyOptions: [
        {text: this.$t('Yes'), value: true},
        {text: this.$t('No'), value: false}
      ]
    }
  },
  props:{
    profile:{
      type: String,
      default(){
        return "carrier"
      }
    }
  },
  created() {
  },
  methods:{
    ...mapActions('onboarding',['getStates','getCities','completeProfile']),
    ...mapActions('profile',['getProfile']),
    ...mapActions('catalogs',['getCatalogs']),
    ...mapActions('forms',['getForm']),
    ...mapMutations('forms',['setCustomLoadForm','setCarrierLoadForm','setShipperLoadForm','setAdminLoadForm']),
    ...mapActions('views',['getActiveViews','executeViews']),
    ...mapActions('configuration',['getConfiguration']),
    fetchCatalogs(user){
      this.getCatalogs();
      this.getConfiguration();
      if(['carrier','shipper','admin'].indexOf(user.profile.object) > -1 && user.profile.load_form_id){
        this.getForm({id: user.profile.load_form_id})
            .then(data =>{
              switch (user.profile.object){
                case "carrier":
                  this.setCarrierLoadForm(data);
                  break;
                case "shipper":
                  this.setShipperLoadForm(data);
                  break;
                case "admin":
                  this.setAdminLoadForm(data);
                  break;
              }
            })
            .catch(error=>{
            });
      }
      this.getActiveViews();


    },
    async onSubmit(validate) {
      if (!(await validate()) || this.loading) {
        return;
      }
      this.loading = true;
      let data =_.cloneDeep(this.form);
      let form = {}
      form.profile = {};
      form.role = this.profile;
      getUser().then((user)=>{
        form.name = user.attributes.given_name;
        form.last_name = user.attributes.family_name;
        form.email = user.attributes.email;
        if (data.name){
          form.profile.name = data.name;
        }else{
          form.profile.name = user.attributes.given_name;
        }
        this.completeProfile(form).then((data)=>{
          this.notificationSuccess(this.$t("Settings"),this.$t("Your information was sent successfully"),this.$t("Remember you can update your information in every time"))
          this.success = true;
        }).catch((data)=>{
          this.success = false;
          this.notificationErrorDefault();
        }).finally(()=>{
          this.loading = false;
        })
      }).catch((err)=>{
        this.notificationErrorDefault();
      })
    },
    closeNotificationMain(sel,id){
      this.closeNotification()
      if (this.success){
        this.getProfile().then((data)=>{
          this.fetchCatalogs(data);
        }).finally(()=>{
          try{
            this.$router.push({name:'welcome', params:{profile: this.profile}});
          }catch(e){
          }
        })
      }
    },
    back(){
      this.$emit('back')
    }
  },
  watch:{

  }
}
</script>

<style  >
.form_main{
  min-width: 55%;
  margin: auto;
}
</style>

<style >

.radio_button .custom-control-label{
  margin-bottom: 0;
  display: flex;
  align-items: center;
}


</style>
