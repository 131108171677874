<template>
  <div>
    <div class="">
      <table class="table mb-0">
        <thead>
        <tr class="">
          <th class="text-center">{{$t('Created at')}}</th>
          <th class="text-center">{{$t('Status')}}</th>
          <th class="text-center">{{$t('Date')}}</th>
           <th class="text-center">{{$t('Department')}}</th>
          <th class="text-center">{{$t('Subject')}}</th>
          <th class="text-center">{{$t('Driver')}}</th>
          <th class="text-center">{{$t('Load')}}</th>
          <th class="text-center">{{$t('Amount')}}</th>
          <th class="text-center">{{$t('Deferred payments')}}</th>
          <th class="text-center">{{$t('Payments')}}</th>
          <th class="text-center" style="min-width: 170px;max-width:200px;">
            {{$t('Actions')}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="fetching">
          <td colspan="100%">
            <loading />
          </td>
        </tr>
        <template v-for="item in list.results">
          <tr v-if="init || page === list.current_page" :key="'load-'+item.id"  class="item-row">
            <td class="text-center">{{item.created_at | timestamp}}</td>
            <td class="text-center">
                <span class="badge mr-1 " :class="[statusColor[item.status]]">
                                    {{$t('status.operating_deductions.'+item.status)}}
                </span>
            </td>
            <td class="text-center">{{item.date}}</td>
            <td class="text-center">{{item.department}}</td>
            <td class="text-center">{{item.subject}}</td>
            <td class="text-center">
              <router-link :to="{name: 'driver.detail.general', params:{user_id: item.driver.id}}" target="_blank">
                {{item.driver.fullname}}
              </router-link>
            </td>
            <td class="text-center">
              <router-link :to="{name:'loads.detail', params:{load_id: item.load_id}}" target="_blank">
                <b>{{item.load_id}}</b>
              </router-link>
            </td>
            <td class="text-center">
               $ {{item.amount}} {{item.currency}}
            </td>
            <td class="text-center">{{item.payments_number}}</td>
            <td class="text-center">{{item.payments}}</td>
            <td>
                <template v-if="item.payments === 0">
                  <a @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-2">
                    <i v-b-tooltip.hover :title="$t('Delete')" class="far fa-trash-alt btn-icon"  />
                  </a>
                </template>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div  v-if="list.total > 0"  class="row align-items-center">
      <div class="col-md-6 col-sm-6 ">
        <b-pagination
            v-model="page"
            :total-rows="list.total"
            :per-page="list.per_page"
            aria-controls="my-table"
            first-number
            last-number
            pills
        ></b-pagination>
      </div>
      <div class="col-md-6 col-sm-6 text-sm-right">
        <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
      </div>
    </div>
    <div v-if="list.total === 0 && !fetching" class="row">
      <div class="col">
        <empty-results />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState, mapActions} from "vuex";
import Loading from "../common/Loading";
import selectAllMixin from "@/mixins/selectAllMixin";
import Swal from "sweetalert2";

export default {
  name: "OperationalDeductionTable",
  mixins:[selectAllMixin],
  components: {
    'loading':Loading
  },
  props: {
    filters : {
      type: Object,
      default(){
        return {}
      }
    },
    edit: {
      type: String
    },
  },
  data(){
    return {
      page: 1,
      init: false,
      statusColor: {
        "cancelled": "badge-outline-danger",
        "in_review": "badge-outline-warning",
        "pending": "badge-outline-warning",
        "completed": "badge-outline-success"
      }
    }
  },
  computed: {
    ...mapState('operationaldeductions', ['operational_deductions','fetching']),
    list(){
      return this.operational_deductions
    }
  },
  created() {
    this.page = this.filters.page ? Number(this.filters.page) : 1;
  },
  mounted() {
    if(Object.keys(this.filters).length > 0){
      this.fetchData();
    }
  },
  watch: {
    filters : {
      deep: true,
      handler(newVal, oldVal){
        if(!_.isEqual(newVal, oldVal) || this.init === false){
          this.fetchData();
        }
      }
    },
    page(newVal){
      this.$emit('page-change', newVal);
    }
  },
  methods: {
    ...mapActions('operationaldeductions',['getOperationalDeductions','deleteOperationalDeduction']),
    fetchData(){
      this.getOperationalDeductions(_.cloneDeep(this.filters))
          .then(response=>{
          })
          .catch(error =>{
          })
          .finally(()=>{
            this.init = true;
          })
    },
    confirmDelete(item){
        Swal.fire({
            title: this.$t("Are you sure?"),
            text: this.$t("You won't be able to revert this!"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: this.$t("Yes, delete it!"),
            cancelButtonText: this.$t("Cancel")
        }).then(result => {
            if(result.value) {
                this.deleteOperationalDeduction({id: item.id})
                    .then(data=>{
                        this.fetchData();
                        this.$root.$bvToast.toast(this.$t("The action was completed successfully"), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                                });
                    })
                    .catch(error =>{
                        console.log(error);
                        Swal.fire(
                            "Error",
                            this.$t("Something went wrong!"),
                            "error"
                        );
                    });
            }
        });
    }
  }
}
</script>

<style scoped>
.item-row>td{
  vertical-align: middle !important;
  height: 50px!important;
  max-height: 50px!important;
  text-align: center !important;
}
</style>
