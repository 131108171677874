<template>
    <div class="card rounded-pill" role="button" @click="selected(id)">
        <div class="row no-gutters">
            <div class="col-md-3 rounded-left border-right h-80 bg-gradient-txp
                rounded-pill-left d-flex justify-content-center align-items-center"
            >
                <i class="text-white font-size-40" v-if="icon" :class="icon" />
                <img :src="src_img" width="35" class="py-2" v-if="src_img" />
            </div>
            <div class="col-md-7 offset-1 d-flex align-items-center">
                <div class="line-height-17">
                    <div class="font-size-15 text-muted" v-if="info">{{info}}</div>
                    <div class="font-size-19 font-weight-bold" v-if="title">{{title}}</div>
                </div>
            </div>
            <div class="col-1 text-center d-flex align-items-center">
                <i class="ri-arrow-right-s-line text-muted font-size-22" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectableCard",
    props: {
        id: Number,
        info: String,
        title: String,
        selected: Function,
        icon: String,
        src_img: String
    }
}
</script>

<style lang="scss" scoped>
.line-height-17{ line-height: 17px; }
.rounded-pill-left{
    border-radius: 50rem 0 0 50rem !important;
}
</style>
