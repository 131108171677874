<template>
    <div>
        <page-header :title="$t('Routes and Agreements')"/>
        
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <filter-bar :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved">
                                <template v-slot:right>
                                    <div class="btn-group" role="group" aria-label="toolbar">
                                        <button  v-b-tooltip.hover :title="$t('Refresh')" @click="getRoutes(filters)" type="button" class="btn btn-light btn-action">
                                            <i class="far fa-sync"></i>
                                        </button>
                                        <router-link v-if="$route.meta.routes.add" v-can="'routes.create'" v-b-tooltip.hover :title="$t('Add')" :to="{name: $route.meta.routes.add}" class="btn btn-light btn-action">
                                            <i class="far fa-plus"></i>
                                        </router-link>
                                        <b-dropdown toggle-class="btn-action" variant="light" class="" right>
                                            <template  #button-content>
                                                <i class="far fa-ellipsis-v"></i>
                                            </template>
                                            <b-dropdown-item @click="importRates">{{$t('Synchronize')}}</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </template>
                            </filter-bar>
                        </div>
                    </div>
                    <route-table v-model="selected" :filters="filters" v-on:page-change="pageChange" />
                </div>

            </div>
        </div>
    </div>
</template>

<style>
.btn-action{
    width: 40px!important;
}
</style>
<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import RouteTable from "@/components/tables/RouteTable";
    import FilterBar from "@/components/filters/FilterBar";
    import filterMixin from "@/mixins/filterMixin";
    import formMixin from "@/mixins/formMixin";

    export default {
        name: 'Routes',
        components:{
            RouteTable,
            FilterBar
        },
        mixins:[filterMixin, formMixin],
        data(){
            return {
                currentTab: null,
                selected : [],
                options: [
                 {
                     icon: 'far fa-key',
                     label: 'ID',
                     type: 'text',
                     filterProperty: 'id',
                 },
                 {
                        icon: 'far fa-search',
                        label: this.$t('Search'),
                        type: 'text',
                        filterProperty: 'search',
                    },
                 {
                     icon: 'far fa-sliders-h-square',
                     label: this.$t('Configuration'),
                     type: 'single',
                     filterProperty: 'configuration_id',
                     values: [
                         {
                             text: 'Sencillo',
                             value: '1'
                         },
                         {
                             text: 'Full',
                             value: '2'
                         }
                     ],
                     valueType: 'string'
                 },
                 {
                     icon: 'far fa-truck-moving',
                     label: this.$t('Truck type'),
                     type: 'multiselect',
                     filterProperty: 'truck_type_id',
                     values: this.truckTypes ? this.truckTypes.map(item => ({text: item.name, value: item.id})) : [],
                     valueType: 'number'
                 },
             ]
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapState('routes', ['last_filters', 'routes']),
            ...mapGetters('catalogs',['truckTypes'])
        },
        mounted() {
            this.init();
        },
        watch: {
            '$route': 'init'
        },
        methods: {
            ...mapActions('addresses',['fetchPostalCodes']),
            ...mapActions('routes',['getRoutes','importRoutes']),
            init(){
                let filters = {};
                // Use meta filters
                if(this.$route.meta.paramFilters){
                    for(let key of this.$route.meta.paramFilters){
                        filters[key] = this.$route.params[key];
                    }
                }
                if(this.$route.meta.filters){
                    filters = {...filters, ...this.$route.meta.filters};
                }
                
                this.defaultFilters = filters;
            },
            importRates(){
                this.importRoutes({source:'tms', ...this.defaultFilters})
                    .then(data =>{
                        this.$root.$bvToast.toast(this.$t("Task started successfully"), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                                });
                                
                    })
                    .catch(error =>{
                        if(error){
                            this.processServiceError(error);
                        }
                    });
            }
        }
    }
</script>

<style>

</style>
