import axios from "../../api/client";


// initial state
export const state = () => ({
    fetching: false,
    advances : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    advance: null
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchAdvance({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/advances`, {params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getAdvances({commit}, params){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/advances`, {params: params})
                .then(response=>{
                    commit('setAdvances', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                 });
        });
    },
    acceptAdvance({commit}, params){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/advances/${params.advance_id}/accept`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    rejectAdvance({commit}, params){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/advances/${params.advance_id}/reject`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    acceptAdvances({commit}, params){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/advances/accept`, params)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    rejectAdvances({commit}, params){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/advances/reject`, params)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    }
};

// mutations
export const mutations = {
    setAdvances(state, data){
        state.advances = data;
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

