import axios from "../../api/client";


// initial state
export const state = () => ({
    requirements : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    requirement: null,
    resource: null,
    last_filters: {},
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchRequirements({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/requirements', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getRequirements({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/requirements',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setRequirements', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeRequirement({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/requirements`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getRequirement({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/requirements/${params.id}`)
                .then(response=>{
                    commit('setRequirement', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateRequirement({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/requirements/${payload.id}`, payload)
                .then(response=>{
                    let requirements = _.cloneDeep(state.requirements);
                    let index = requirements.results.findIndex(item => item.id === response.data.id);
                    if(index > -1){
                        requirements.results[index] = response.data;
                        commit('setRequirements', requirements);
                    }
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error)
                    reject(error.response)
                });
        });
    },
    deleteRequirement({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/requirements/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getRequirementsByProfile({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/${payload.resource}/${payload.resource_id}/requirements`)
                .then(response=>{
                    commit('setRequirements', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    approveRequirement({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/${payload.resource}/${payload.resource_id}/documents/${payload.document_id}/approve`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    rejectRequirement({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/${payload.resource}/${payload.resource_id}/documents/${payload.document_id}/reject`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    approveAllRequirement({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/${payload.resource}/${payload.resource_id}/documents/approve-all`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    finishReview({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/${payload.resource}/${payload.resource_id}/documents/complete-review`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    sendReview({commit},payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/${payload.resource}/${payload.resource_id}/documents/review`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteDocument({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/${payload.resource}/${payload.resource_id}/documents/${payload.document_id}/files/${payload.file_id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    uploadDocument({commit}, payload){
        var formData = new FormData();
        formData.append("file", payload.file);
        formData.append("requirement_id", payload.requirement_id);
        formData.append("is_admin", true);
        if (payload.expiration) formData.append("expiration", payload.expiration);
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/${payload.resource}/${payload.resource_id}/documents`, formData)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateDocument({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/${payload.resource}/${payload.resource_id}/documents/${payload.document_id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getResource({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/${payload.resource}/${payload.resource_id}`)
                .then(response=>{
                    commit('setResource', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setRequirements(state, data){
        state.requirements = data;
    },
    setRequirement(state, data){
        state.requirement = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    updateRequirement(state, data){
        let item = state.requirements.results.find(item => item.id === data.id);
        if(item){
            item = data;
        }
    },
    setResource(state, data){
        state.resource = data;
    },
};

