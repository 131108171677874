<template>
  <div>
    <page-header :title="$t('Wallet')" :items="breadcrumb" />
    <div class="row">
      <div
        class="col-md-4 pb-3"
        v-for="(data, index) in walletData"
        :key="index"
      >
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-2 font-weight-bold">
                  {{ data.title }}
                </p>
                <h4 :class="`${data.class} mb-0`">${{ data.value | money }}</h4>
              </div>
              <div class="text-primary">
                <span :class="data.class">{{ wallet ? wallet.currency : '' }}</span>
                <i :class="`${data.icon} ${data.class} font-size-24 pl-2`"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading"></loading>

    <div v-else class="row">
      <div class="col-12">
          
        <h5 class="mb-0">{{ $t("Last transactions") }}</h5>
        <transaction-table
          :filters="filters"
          v-on:page-change="pageChange"
        ></transaction-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TransactionTable from "@/components/tables/TransactionTable";
import filterMixin from "@/mixins/filterMixin";

export default {
  name: "Wallet",
  components: {
    TransactionTable
  },
  mixins: [filterMixin],
  data() {
    return {
      loading:true
    };
  },
  computed: {
    ...mapState("wallet", ["wallet"]),
    breadcrumb() {
      const profile = this.$route.params.profile;
      return [
        {
          text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
          to: { name: "profile" },
        },
        {
          text: this.$t("Wallet"),
          active: true,
          to: { name: "wallet.index" },
        },
      ];
    },
    walletData(){
      return [
        {
          title: this.$t("Balance"),
          icon: "far fa-money-bill-wave",
          class: "text-success",
          value: this.wallet ? (this.wallet.balance) : '0.00',
        },
        {
          title: this.$t("Retained balance"),
          icon: "far fa-hand-holding-usd",
          class: "text-danger",
          value: this.wallet ? (this.wallet.preauthorized) : '0.00',
        },
        {
          title: this.$t("Total"),
          icon: "far fa-sack-dollar",
          class: "text-success",
          value: this.wallet ? (this.wallet.total) : '0.00',
        },
      ]
    }
  },
  created() {
    this.getWalletByProfile().then(data => {
      this.loading = false;
    });
  },
  watch: {
    $route: "init",
  },
  methods: {
    ...mapActions("wallet", ["getWalletByProfile"]),
  },
};
</script>

<style>
</style>
