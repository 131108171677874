<template>
    <b-button-group>
        <b-dropdown variant="light" ref="dropdown" class="d-inline-block " toggle-class="filter-button">
            <template #button-content>
                <table>
                    <tr>
                        <td valign="top" style="width:24px;">
                            <small><i class="pr-1 text-muted" :class="[filter.icon]"></i></small>
                        </td>
                        <td valign="top" class="text-left" >
                            <small class="text-muted">{{filter.label}}:</small> <div class="filter-button-value"> {{formattedValue}}</div>
                        </td>
                    </tr>
                </table>
            </template>
            <button class="dropdown-item" :key="'bd-f-'+index" v-for="(item,index) in filter.values"
                    @click="filterValue=item.value">
                {{item.text}}
            </button>
        </b-dropdown>
        <b-button variant="light" @click="$emit('remove')"><i class="far fa-times"></i></b-button>
    </b-button-group>
</template>

<script>
    import convertDataType from "@/helpers/convertDataType";
    export default {
        name: 'SingleFilter',
        props: {
            filter: {
                type: Object,
                required: true
            },
            value: {
                required:true
            }
        },
        data(){
            return {
                filterValue: null
            }
        },
        computed: {
            formattedValue(){
                if(this.filterValue){
                    let item = this.filter.values.find(item => (item.value === this.filterValue));
                    return item.text;
                }
                return '- -';
            }
        },
        watch: {
            filterValue(newValue){
                this.$emit('input', newValue);
            },
        },
        created() {
            if(this.value){
                this.filterValue = convertDataType(this.value, this.filter.valueType);
            }else{
                setTimeout(()=>{
                    this.$refs.dropdown.visible = true;
                },100);
            }
        },
    }
</script>

