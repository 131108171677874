<template>
  <div>
    <route-map
      style="
        width: calc(100% + 48px);
        height: 350px;
        top: -1px;
        left: -24px;
        right: -24px;
        margin-bottom: 24px;
      "
      :load="load"
    ></route-map>
    <div class="row" v-show="true">
      <div
        :class="'col-md-' + 12 / statData.length"
        :key="'stat-' + index"
        v-for="(item, index) in statData"
      >
        <div class="card">
          <div class="card-body p-3">
            <div class="media text-truncate">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-0">{{ $t(item.title) }}</p>
                <h4 class="mb-0">
                  <span v-b-tooltip.hover :title="item.help">{{ item.value }}</span>
                </h4>
              </div>
              <div class="text-secondary">
                <i class="font-size-24" :class="[item.icon]"></i>
              </div>
            </div>
          </div>
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: item.progress + '%' }"
              v-bind:aria-valuenow="item.progress"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>

          <div class="card-body p-3">
            <div class="text-truncate">
              <h6 class="mb-0">{{ $t(item.subtitle) }}</h6>
              <span class="text-muted">{{ item.subvalue }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs">
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: tab === 'detail' }"
                  href="javascript:void(0);"
                  @click="tab = 'detail'"
                  >{{ $t("Load") }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: tab === 'last_events' }"
                  href="javascript:void(0);"
                  @click="routeLogClicked"
                  >{{ $t("Route log") }}</a
                >
              </li>
              <li v-can="'telemetry.module'" class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: tab === 'telemetry' }"
                  href="javascript:void(0);"
                  @click="tab = 'telemetry'"
                  >{{ $t("Telemetry") }}</a
                >
              </li>
              <li v-can="'fuel.module'" class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: tab === 'fuel_vouchers' }"
                  href="javascript:void(0);"
                  @click="tab = 'fuel_vouchers'"
                  >{{ $t("Fuel vouchers") }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: tab === 'temperatures' }"
                  href="javascript:void(0);"
                  @click="temperaturesTabClicked"
                  >{{ $t("Temperature") }}</a
                >
              </li>
              <li
                v-if="['admin', 'staff'].indexOf($route.params.profile) > -1"
                class="nav-item"
              >
                <a
                  class="nav-link"
                  :class="{ active: tab === 'mirror_account' }"
                  href="javascript:void(0);"
                  @click="mirrorAccountTabClicked"
                  >{{ $t("Mirror account") }}</a
                >
              </li>
                <li
                    v-if="['admin', 'staff'].indexOf($route.params.profile) > -1 && isVINManagementAvailable"
                    class="nav-item"
                >
                    <a
                        class="nav-link"
                        :class="{ active: tab === 'vin' }"
                        href="javascript:void(0);"
                        @click="vinTabClicked"
                    >{{ $t("VIN") }}</a
                    >
                </li>
            </ul>
          </div>
          <div class="card-body">
            <div v-show="tab === 'detail'">
              <div class="mb-5">
                <div class="row">
                  <div class="col-md-6">
                    <h5>
                      <i
                        class="far mr-3"
                        :class="[
                          $route.meta.type === 'load'
                            ? 'fa-dolly-flatbed-alt'
                            : 'fa-shipping-fast',
                        ]"
                      />
                      {{ $t($route.meta.type === "load" ? "Load" : "Shipment") }}:
                      {{ load.id }}
                      <span
                        class="badge"
                        v-bind:class="[
                          load.type == 'RETURN'
                            ? 'badge-outline-danger'
                            : 'badge-outline-primary',
                        ]"
                        >{{ $t(load.type) }}</span
                      >
                    </h5>
                  </div>
                  <div class="col-md-6 text-right">
                    <b-dropdown
                      :text="$t('Event log')"
                      variant="light"
                      size="sm"
                      class="m-2"
                      v-if="
                        statusActive.length > 0 &&
                        (load.truck !== null || load.driver !== null)
                      "
                      v-show="
                        $route.params.profile !== 'shipper' &&
                        load.status !== 'not_assigned'
                      "
                      :disabled="isCompletedStatus"
                    >
                      <b-dropdown-item-button
                        href="#"
                        :key="'st-' + index"
                        :disabled="item.disabled"
                        v-for="(item, index) in statusActive"
                        @click="onChange(item)"
                      >
                        <strong :class="item.class">{{ item.event }}</strong>
                      </b-dropdown-item-button>
                    </b-dropdown>
                    <b-dropdown
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content>
                        <i class="fa fa-ellipsis-v text-black-50"></i>
                      </template>
                      <b-dropdown-item
                        v-can="'loads.update'"
                        v-if="
                          editableStatus.indexOf(load.status) > -1 ||
                          $route.params.profile === 'admin'
                        "
                        v-show="$route.params.profile !== 'carrier'"
                        @click="
                          setLinkAction({
                            name: 'loads.edit',
                            params: { load_id: load.id },
                          })
                        "
                      >
                        <i class="far fa-edit mr-2" />{{ $t("Edit") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          statusActive.length > 0 &&
                          (load.truck !== null || load.driver !== null)
                        "
                        @click="loadDispatch"
                      >
                        <i class="far fa-check-circle mr-2" /> {{ $t("Dispatch") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          ['SHIPMENT', 'SHIPMENT_LEG'].indexOf(load.type) > -1 &&
                          load.tms_shipment_id
                        "
                        v-can="'loads.create'"
                        v-show="
                          [
                            'checkin_delivery',
                            'unloaded',
                            'unloading',
                            'delivered',
                          ].indexOf(load.status) > -1
                        "
                        @click="
                          setLinkAction({
                            name: 'loads.add',
                            query: { type: 'RETURN', parent_id: load.id },
                          })
                        "
                      >
                        <i class="far fa-undo mr-2" /> {{ $t("Create Return") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-can="'loads.create'"
                        v-show="load.status == 'in_transit'"
                        @click="
                          setLinkAction({
                            name: 'loads.add',
                            query: {
                              type: 'DIVERT_IN_TRANSIT',
                              parent_id: load.parent_id,
                            },
                          })
                        "
                      >
                        <i class="far fa-truck mr-2" />
                        {{ $t("Create Divert in Transit") }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                          <div class="field-label">{{ $t("TMS ID") }}</div>
                          <div class="field-help">
                            {{ load.tms_id ? load.tms_id : "- -" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                          <div class="field-label">{{ $t("Load type") }}</div>
                          <div class="field-help">
                            {{ load.load_type ? load.load_type.name : "- -" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                          <div class="field-label">{{ $t("Configuration") }}</div>
                          <div class="field-help">
                            {{ load.configuration ? load.configuration.name : "- -" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label">{{ $t("Truck type") }}</div>
                      <div class="field-help">
                        <i class="far fa-truck mr-2" />{{
                          load.truck_type ? load.truck_type.name : "- -"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                          <div class="field-label">{{ $t("Trailer type") }}</div>
                          <div class="field-help">
                            <i class="far fa-container-storage mr-2" />{{
                              load.trailer_type ? load.trailer_type.name : "--"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                          <div class="field-label">{{ $t("Weight") }}</div>
                          <div class="field-help">
                            <i class="far fa-weight mr-2" />{{ load.weight }}
                            {{ load.weight_unit }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                          <div class="field-label">{{ $t("Container type") }}</div>
                          <div class="field-help">
                            <i class="far fa-container-storage mr-2" />{{
                              load.container_type
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="field-wrapper border-bottom-none p-0">
                          <div class="field-label">{{ $t("Commodity") }}</div>
                          <div class="field-help">
                            {{ load.commodity ? load.commodity.name : "- -" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4" v-if="load.carrier_skills.length > 0">
                  <div class="col-md-12">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label">{{ $t("Required carrier's skills") }}</div>
                      <div class="field-help">
                        <span
                          class="badge badge-outline-info mr-2"
                          :key="'skill-' + item.id"
                          v-for="item in load.carrier_skills"
                          >{{ item.name }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label">{{ $t("Additional information") }}</div>
                      <div class="field-help">{{ load.comments }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mb-5"
                :key="'stop-detail-' + index"
                v-for="(stop, index) in stops"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <h5>
                      <i class="far mr-3 fa-location-circle" />{{ $t("Stop") }}
                      {{ stop.order }}
                    </h5>
                    <div class="ml-3">
                      <span class="badge badge-outline-primary">{{
                        stop.drop ? $t("Delivery") : $t("Pickup")
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <badge-status :status="stop.status" :statusName="$t(stop.status)" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label">{{ $t("Date") }}</div>
                      <div class="field-help">{{ stop.schedule_from | date }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label">
                        {{ stop.pick ? $t("Pickup schedule") : $t("Arrival schedule") }}
                      </div>
                      <div class="field-help">
                        <i class="far fa-clock mr-2" />{{ stop.schedule_from | time }} -
                        {{ stop.schedule_to | time }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label">{{ $t("Address") }}</div>
                      <div
                        class="field-help"
                        :key="'pickup-line-' + index"
                        v-for="(item, index) in getAddressFormat(stop.address)"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="field-wrapper border-bottom-none p-0">
                      <div class="field-label">{{ $t("References") }}</div>
                      <div class="field-help">{{ stop.address.description }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-show="tab === 'last_events'">
              <RouteLog :load_id="load.id" :border="false" />
            </div>
            <div v-can="'telemetry.module'" v-show="tab === 'telemetry'">
              <telemetry-table
                v-if="$route.params.profile != 'shipper'"
                :load_id="load.id"
              ></telemetry-table>
            </div>
            <!-- <div v-can="'fuel.module'" v-show="tab === 'fuel_vouchers'">
                      <fuel-voucher-table :load="load" />
                  </div> -->
            <div v-show="tab === 'temperatures'">
              <temperatures-table :load="load" />
            </div>
            <div v-if="tab === 'mirror_account'">
              <b-form @submit.stop.prevent="onSubmitMirror()">
                <div class="form-fields">
                  <form-input
                    :title="$t('URL')"
                    rules="url"
                    :help="$t('Account url')"
                    v-model="form.mirror_account.url"
                    type="text"
                    :name="'mirror_url'"
                  />
                  <form-input
                    :title="$t('User')"
                    :help="$t('Account user')"
                    v-model="form.mirror_account.user"
                    rules=""
                    type="text"
                    :name="'mirror_user'"
                  />

                  <form-input
                    :title="$t('Password')"
                    :help="$t('Account password')"
                    v-model="form.mirror_account.password"
                    rules=""
                    type="password"
                    :name="'mirror_password'"
                  />
                </div>
                <div class="field-wrapper text-right">
                  <button
                    type="button"
                    @click="onSubmitMirror"
                    class="btn btn-primary btn-sm"
                  >
                    {{ $t("Save") }}
                  </button>
                </div>
              </b-form>
            </div>
              <div v-if="tab === 'vin'">
                  <load-vin></load-vin>
              </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <confirm-delivery-card />
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between mb-1">
              <h4 class="card-title">{{ $t("Customer") }}</h4>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-3 d-flex justify-content-center align-items-center">
                <img
                  v-if="shipper.logo"
                  class="rounded-circle"
                  :src="shipper.logo"
                  width="45"
                  height="45"
                  style="object-fit: cover; padding: 0 !important"
                />
                <vue-letter-avatar
                  v-else
                  :name="shipper.name"
                  size="45"
                  :rounded="true"
                />
              </div>
              <div class="col-9">
                <p class="">{{ $t("Company name") }}</p>
                <p class="h4">
                  <template v-if="$can('accounts.management')">
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'shippers.detail',
                        params: { shipper_id: shipper.id },
                      }"
                    >
                      {{ shipper.name }}
                    </router-link>
                  </template>
                  <template v-else>
                    {{ shipper.name }}
                  </template>
                </p>
              </div>
            </div>
          </div>
        </div>
        <validation-observer v-if="showTruckDriverForm" ref="observer" v-slot="observer">
          <b-form @submit.stop.prevent="onSubmit(observer)">
            <div class="card">
              <div class="card-header">
                <h6>{{ $t("Carrier") }}</h6>
              </div>
              <div class="form-fields">
                <div class="field-wrapper">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="field-label">{{ $t("Company") }}</div>
                      <div class="field-help">
                        <router-link
                          v-if="$can('accounts.management')"
                          :to="{
                            name: 'carriers.detail',
                            params: { carrier_id: load.carrier.id },
                          }"
                          target="_blank"
                          >{{ load.carrier ? load.carrier.name : "- -" }}</router-link
                        >
                        <span v-else>
                          {{ load.carrier ? load.carrier.name : "- -" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-wrapper">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="field-label">{{ $t("Driver") }}</div>
                      <div class="field-help">
                        <validation-provider
                          v-if="$can('loads.assets')"
                          name="drive_id"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <user-input
                            v-model="form.driver"
                            :filters="{ roles: 'driver', carrier_id: load.carrier.id }"
                          >
                          </user-input>
                          <b-form-invalid-feedback :state="false">{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </validation-provider>
                        <div v-else>
                          <span v-if="load.driver"
                            >{{ load.driver.name }} {{ load.driver.last_name }}</span
                          >
                          <span>- -</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-wrapper">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="field-label">{{ $t("Truck") }}</div>
                      <div class="field-help">
                        <validation-provider
                          v-if="$can('loads.assets')"
                          name="truck_id"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <truck-input
                            v-model="form.truck"
                            :filters="{ carrier_id: load.carrier.id }"
                          >
                          </truck-input>
                          <b-form-invalid-feedback :state="false">{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </validation-provider>
                        <span v-else>
                          {{ load.truck ? load.truck.plate : "- -" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="load.trailer_type" class="field-wrapper">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="field-label">{{ $t("Trailer") }}</div>
                      <div class="field-help">
                        <validation-provider
                          v-if="$can('loads.assets')"
                          name="trailer_id"
                          rules=""
                          v-slot="{ errors }"
                        >
                          <trailer-input
                            v-model="form.trailer"
                            :filters="{ carrier_id: load.carrier.id }"
                          >
                          </trailer-input>
                          <b-form-invalid-feedback :state="false">{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </validation-provider>

                        <span v-else>
                          {{ load.trailer ? load.trailer.plate : "- -" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="load.trailer_type" class="field-wrapper">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="field-label">{{ $t("Second Trailer") }}</div>
                      <div class="field-help">
                        <validation-provider
                          v-if="$can('loads.assets')"
                          name="second_trailer_id"
                          rules=""
                          v-slot="{ errors }"
                        >
                          <trailer-input
                            v-model="form.second_trailer"
                            :filters="{ carrier_id: load.carrier.id }"
                          >
                          </trailer-input>
                          <b-form-invalid-feedback :state="false">{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </validation-provider>

                        <span v-else>
                          {{ load.second_trailer ? load.second_trailer.plate : "- -" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-wrapper text-right" v-if="$can('loads.assets')">
                  <button type="submit" class="btn btn-primary btn-sm">
                    {{ $t("Save") }}
                  </button>
                </div>
              </div>
            </div>
          </b-form>
        </validation-observer>

        <div
          class="card"
          v-can="'loads.showprice'"
          v-if="$route.params.profile !== 'driver'"
        >
          <div class="card-header">
            <div class="d-flex justify-content-between mb-1">
              <h4 class="card-title">{{ $t("Price") }}</h4>
              <!--<button class="btn btn-success btn-sm"
                                @click="applyCarrierLoad"
                                v-can="'tenders.create'">
                            <i class="far fa-check-circle mr-2"></i>{{$t('Apply')}}
                        </button>-->
              <button
                class="btn btn-success btn-sm"
                @click="applyCarrierLoad"
                v-can="'tenders.create'"
                v-if="$route.params.profile === 'carrier' && load.tendered !== true"
                v-show="load.status === 'not_assigned'"
              >
                <i class="far fa-check-circle mr-2"></i>{{ $t("Apply") }}
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4">
                <p>{{ $t("Purchase price") }}</p>
              </div>
              <div class="col-sm-8">
                <h4 class="text-right text-primary">
                  $ {{ load.purchase_price | money }} {{ load.currency }}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <load-payment
          v-if="false && ['pod', 'delivered', 'paid'].indexOf(load.status) > -1"
          :load="load"
        />

        <!-- <salary-advance :load="load" /> -->

        <div v-if="profileForm && profile_fields" class="card">
          <div class="card-header">
            <h6>{{ $t("Custom fields") }}</h6>
          </div>
          <template v-for="(field, idx) in profileForm.fields">
            <form-input
              :key="'field-' + field.id"
              :title="field.title"
              :help="field.help"
              v-model="profile_fields[field.id]"
              :type="field.type"
              :name="'field.' + idx + '.title'"
              :options="field.options"
              layout="vertical"
            />
          </template>
          <div class="field-wrapper text-right">
            <button
              type="button"
              @click="updateProfileFields"
              class="btn btn-primary btn-sm"
            >
              {{ $t("Save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import addressFormat from "@/helpers/address-format";
import RouteMap from "@/components/common/RouteMap";
import TruckInput from "@/components/form/TruckInput";
import TrailerInput from "@/components/form/TrailerInput";
import UserInput from "@/components/form/UserInput";
import LoadPayment from "@/components/common/LoadPayment";
import Multiselect from "vue-multiselect";
import TelemetryTable from "@/components/tables/TelemetryTable";
//import FuelVoucherTable from "@/components/tables/FuelVoucherTable";
//import SalaryAdvance from "@/components/common/SalaryAdvance";
import Swal from "sweetalert2";
import RouteLog from "../../components/common/RouteLog";
import FormMixin from "@/mixins/formMixin";
import TemperaturesTable from "@/components/tables/TemperaturesTable";
import ConfirmDeliveryCard from "@/components/reverseLogistic/ConfirmDeliveryCard.vue";
import { useEventsTracker } from "@/composables/analytics";
import LoadVin from '@/components/common/LoadVin.vue';
import { allowedCarriersTMSID } from '@/constants/ediCarrier';

export default {
  name: "LoadGeneral",
  components: {
      LoadVin,
    RouteLog,
    "route-map": RouteMap,
    TruckInput,
    TrailerInput,
    UserInput,
    TelemetryTable,
    //SalaryAdvance,
    LoadPayment,
    //FuelVoucherTable,
    TemperaturesTable,
    ConfirmDeliveryCard,
  },
  mixins: [FormMixin],
  data() {
    return {
      editableStatus: ["pending", "published", "not_assigned"],
      activityData: [],
      visible: true,
      loading: true,
      form: {},
      profile_fields: {},
      payment: null,
      tab: "detail",
      editAssets: false,
      updatingAssets: false,
    };
  },
  computed: {
    ...mapState("profile", ["profile"]),
    ...mapState("loads", ["load", "events", "stops", "status"]),
    ...mapState("forms", [
      "customLoadForm",
      "carrierLoadForm",
      "shipperLoadForm",
      "adminLoadForm",
    ]),
    ...mapState("accounts", [""]),
    ...mapState("ediCarrier", ["ediCarrier"]),
    progressStatus() {
      return (
        (this.status.filter((item) => item.completed).length * 100) / this.status.length
      );
    },
    progressCarrier() {
      let advance = 0;
      let step = 100 / 3;
      if (
        this.load.carrier &&
        ["cancelled", "not_assigned", "pending"].indexOf(this.load.status) === -1
      ) {
        advance += step;
      }
      if (this.load.truck) {
        advance += step;
      }
      if (this.load.driver) {
        advance += step;
      }
      return advance > 99 ? 100 : advance;
    },
    progressPickup() {
      if (!this.pickup) return 0;
      let items = this.status.filter((item) => item.stop_id === this.pickup.id);
      let advance = (items.filter((item) => item.completed).length * 100) / items.length;
      return advance > 99 ? 100 : advance;
    },
    progressDelivery() {
      let stop = this.delivery;
      if (!stop) return 0;
      let items = this.status.filter((item) => item.stop_id === stop.id);
      let advance = (items.filter((item) => item.completed).length * 100) / items.length;
      return advance > 99 ? 100 : advance;
    },
    pickup() {
      return this.stops.length > 0 ? this.stops[0] : null;
    },
    delivery() {
      return this.stops.length > 1 ? this.stops[this.stops.length - 1] : null;
    },
    nextStatus() {
      let next = "- -";
      for (let item of this.status) {
        if (item.completed === false) {
          next = item.event;
          break;
        }
      }
      return next;
    },
    statData() {
      let card = [
        {
          title: "Status",
          icon: "far fa-ballot-check",
          progress: this.progressStatus,
          value: this.$t(this.load.status),
          subtitle: "Next event",
          subvalue: this.nextStatus,
        },
      ];
      if (this.load.type !== "SHIPMENT") {
        card.push({
          title: "Carrier",
          icon: "far fa-truck ",
          progress: this.progressCarrier,
          value: this.load.carrier ? this.load.carrier.name : "- -",
          subtitle: "Driver assigned",
          subvalue: this.load.driver
            ? `${this.load.driver.name} ${this.load.driver.last_name}`
            : "- -",
        });
      }
      card.push({
        title: "Pickup",
        icon: "far fa-dolly-flatbed-alt",
        progress: this.progressPickup,
        value: this.load.pickup.address.city,
        help: this.load.pickup.address.state,
        subtitle: "Pickup date",
        subvalue: this.load.pickup.date,
      });
      card.push({
        title: "Delivery",
        icon: "far fa-hand-holding-box",
        progress: this.progressDelivery,
        value: this.load.delivery.address.city,
        help: this.load.delivery.address.state,
        subtitle: "Delivery date",
        subvalue: this.load.delivery.date,
      });

      switch (this.$route.params.profile) {
        case "carrier":
          if (this.load.carrier === null) {
            this.visible = false;
            return [];
          }
          if (
            this.profile.account &&
            this.profile.account.name !== this.load.carrier.name
          ) {
            this.visible = false;
            return [];
          }
          this.visible = true;
          return card;
          break;
        case "driver":
          if (this.load.driver && this.profile.id !== this.load.driver.id) {
            this.visible = false;
            return [];
          }
          this.visible = true;
          return card;
          break;
        case "admin":
          return card;
          break;
      }
      return "";
    },
    statusActive() {
      let items = [];

      let last_complete = this.status.findLastIndex((item) => item.completed);
      for (let i = 0; i < this.status.length; i++) {
        if (i < last_complete) {
          items.push({
            ...this.status[i],
            class: "text-gray-600 strike",
            disabled: true,
          });
        } else if (i === last_complete + 1) {
          items.push({ ...this.status[i], class: "text-primary", disabled: false });
        } else {
          items.push({ ...this.status[i], class: "text-gray-600", disabled: true });
        }
      }

      if (last_complete === -1) {
        return items.slice(0, 3);
      }

      if (last_complete === items.length - 1) {
        return [];
      }

      return items.slice(last_complete, last_complete + 3);
    },
    isCompletedStatus() {
      if (this.load.status === "delivered") return true;
      return false;
    },
    showTruckDriverForm() {
      if (!this.load) return false;
      if (this.$route.params.profile === "admin" && this.load.carrier) {
        return true;
      }
      if (this.$route.params.profile === "carrier" && this.load.carrier) {
        return this.load.carrier.id === this.profile.profile.id;
      }
      if (this.$route.params.profile === "driver" && this.load.driver) {
        return this.load.driver.id === this.profile.profile.id;
      }
      return false;
    },
    profileForm() {
      if (this.$route.params.profile === "admin") {
        return this.adminLoadForm;
      } else if (this.$route.params.profile === "shipper") {
        return this.shipperLoadForm;
      } else if (this.$route.params.profile === "carrier" && this.load.carrier) {
        return this.carrierLoadForm;
      }
      return null;
    },
    viewDriverTruckAssigned() {
      return (
        ["admin"].indexOf(this.$route.params.profile) > -1 ||
        ["not_assigned", "assigned", "accepted"].indexOf(this.load.status) > -1
      );
    },
    shipper() {
      return this.load.shipments[0].shipper;
    },
    isVINManagementAvailable() {
        return this.ediCarrier &&
            this.ediCarrier.flows.find(x => x === 'save_vin') &&
            this.load.delivery.address.external_id &&
            this.load.pickup.address.external_id
    }
  },
  created() {
    this.init();
    if (
      this.load.status === "pod" ||
      (this.load.status === "paid" && this.load.carrier)
    ) {
      if (
        (this.$route.params.profile === "carrier" &&
          this.profile.profile.id === this.load.carrier.id) ||
        this.$route.params.profile === "admin"
      ) {
        this.payment = true;
      }
    }
    const { trackEvent } = useEventsTracker(this);
    this.trackEvent = trackEvent;
  },
  mounted() {
    Echo.private(`loads.${this.$route.params.load_id}`).listen("NewLoadPosition", (e) => {
      if (this.load) this.updatePosition({ load_id: this.load.id, position: e.position });
    });
    this.getCarrier({ carrier_id: this.load.carrier.id }).then(response => {
        if (allowedCarriersTMSID.includes(response.tms_id)) {
            this.getEdiCarrier(response.tms_id).then();
        }
    });
  },
  methods: {
    ...mapActions("loads", [
      "assignTruckAndDriver",
      "storeTender",
      "getLoad",
      "changeStatus",
      "getEvents",
      "getStatus",
      "updatePosition",
      "updateLoadFields",
      "updateMirrorAccount",
    ]),
    ...mapActions('accounts',['getCarrier']),
    ...mapActions("ediCarrier", ["getEdiCarrier"]),
    getAddressFormat(address) {
      return addressFormat(address);
    },
    routeLogClicked() {
      this.tab = "last_events";
      this.trackEvent("trip_log_tab", "Load details", "click");
    },
    temperaturesTabClicked() {
      this.tab = "temperatures";
      this.trackEvent("temperature_tab", "Load details", "click");
    },
    mirrorAccountTabClicked() {
      this.tab = "mirror_account";
      this.trackEvent("mirror_account_tab", "Load details", "click");
    },
      vinTabClicked() {
          this.tab = "vin";
          this.trackEvent("vin_tab", "Load details", "click");
      },
    setLinkAction(data) {
      this.$router.push(data);
    },
    init() {
      if (["carrier"].indexOf(this.$route.params.profile) > -1) {
        this.profile_fields = this.load[`${this.$route.params.profile}_fields`];
      }
      this.form.driver = this.load.driver;
      this.form.truck = this.load.truck;
      this.form.trailer = this.load.trailer;
      this.form.second_trailer = this.load.second_trailer;
      this.form.mirror_account = this.load.mirror_account;
    },
    async onSubmitMirror() {
      let form = {
        url: this.form.mirror_account.url,
        user: this.form.mirror_account.user,
        password: this.form.mirror_account.password,
        id: this.$route.params.load_id,
      };
      this.updatingAssets = true;
      this.updateMirrorAccount(form)
        .then((data) => {
          this.$root.$bvToast.toast(this.$t("Load has been updated success."), {
            title: this.$t("Success"),
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t("Something went wrong!"), {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.updatingAssets = false;
          this.getLoad({ id: this.$route.params.load_id })
            .then((data) => {})
            .catch((error) => {});
        });
    },
    async onSubmit(observer) {
      if (!(await observer.validate()) || this.updatingAssets) {
        this.$bvToast.toast(this.$t("Please, check the form"), {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }
      let form = {
        id: this.$route.params.load_id,
        driver_id: this.form.driver.id,
        truck_id: this.form.truck.id,
      };
      if (this.form.trailer) {
        form.trailer_id = this.form.trailer.id;
      }
      if (this.form.second_trailer) {
        form.second_trailer_id = this.form.second_trailer.id;
      }
      this.updatingAssets = true;
      this.assignTruckAndDriver(form)
        .then((data) => {
          this.$root.$bvToast.toast(
            this.$t("Load has been updated success.", {
              resource: this.$t("Assign").toLowerCase(),
            }),
            {
              title: this.$t("Success"),
              variant: "success",
              solid: true,
            }
          );
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t("Something went wrong!"), {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.updatingAssets = false;
          this.getLoad({ id: this.$route.params.load_id })
            .then((data) => {})
            .catch((error) => {});
        });
    },
    applyCarrierLoad() {
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("Are you sure to apply to this load?"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Yes, accepted it!"),
        cancelButtonText: this.$t("No"),
      }).then((result) => {
        if (result.value) {
          this.storeTender({
            id: this.$route.params.load_id,
            price: this.load.price,
            currency: this.load.currency,
          })
            .then((data) => {
              this.$root.$bvToast.toast(
                this.$t("The action was successful!", {
                  resource: this.$t("Tenders").toLowerCase(),
                }),
                {
                  title: this.$t("Success"),
                  variant: "success",
                  solid: true,
                }
              );
            })
            .catch((error) => {
              this.$bvToast.toast(this.$t("Something went wrong!"), {
                title: "Error",
                variant: "danger",
                solid: true,
              });
            })
            .finally(() => {
              this.getLoad({ id: this.$route.params.load_id })
                .then((data) => {})
                .catch((error) => {});
            });
        }
      });
    },
    onChange(item) {
      let payload = {
        id: this.$route.params.load_id,
        stop_id: item.stop_id,
        status: item.status,
      };
      this.changeStatus(payload)
        .then((data) => {
          this.$root.$bvToast.toast(this.$t("The action was completed successfully"), {
            title: this.$t("Success"),
            variant: "success",
            solid: true,
          });
          this.getStatus({ id: this.$route.params.load_id })
            .then((data) => {})
            .catch((error) => {});
          this.getLoad({ id: this.$route.params.load_id })
            .then((data) => {})
            .catch((error) => {});
          this.getEvents({ id: this.$route.params.load_id })
            .then((data) => {})
            .catch((error) => {});
        })
        .catch((error) => {
          this.processServiceError(error);
        })
        .finally(() => {});
    },
    updateProfileFields() {
      let payload = { id: this.$route.params.load_id };
      payload[`${this.$route.params.profile}_fields`] = this.profile_fields;
      this.updateLoadFields(payload)
        .then((data) => {
          this.$root.$bvToast.toast(this.$t("Updated successfully"), {
            title: this.$t("Success"),
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {});
    },
    loadDispatch() {
      // TODO: Llamada de despacho
      this.$root.$bvToast.toast(this.$t("Successfully dispatch"), {
        title: this.$t("Success"),
        variant: "success",
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss">
.progress {
  height: 10px !important;
  border-radius: 0 !important;
  .progress-bar {
    background-color: linear-gradient(
      270deg,
      rgb(0, 179, 230) 0%,
      rgb(227, 237, 85) 100%
    ) !important;
  }
}
.strike {
  text-decoration: line-through;
}
</style>
