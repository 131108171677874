<template>
    <validation-observer ref="observer" v-slot="{ validate }">
        <b-form @submit.stop.prevent="onSubmit(validate)">
            <div class="font-size-14 form-fields">
                <div class="row mb-4">
                    <div class="col-md-4 col-6 font-size-12 text-dark font-weight-bold">{{$t('Packaging type')}}</div>
                    <div class="col-md-8 col-6">
                        <form-input
                            placeholder="Seleccionar"
                            rules="required"
                            :options="containerTypes"
                            type="select"
                            layout="vertical"
                            selectValue="value"
                            selectText="text"
                            class="border-0 p-0"
                            open-direction="bottom"
                            v-model="container_type"
                        />
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-4 col-6 font-size-12 text-dark font-weight-bold">
                        {{$t('What is the weight of each')}} {{container_name}} (Kg.)?
                    </div>
                    <div class="col-md-8 col-6">
                        <validation-provider name="weight" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="weight" id="weight" type="number" min="0" step="0.0000001"></b-form-input>
                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                        </validation-provider>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-4 col-6 font-size-12 text-dark font-weight-bold">
                        {{$t('Dimensions of each')}} {{container_name}}
                    </div>
                    <div class="col-md-8 col-6">
                        <div class="row">
                            <div class="col-4 text-center">
                                <validation-provider name="width" rules="required" v-slot="{ errors }">
                                    <b-form-input v-model="width" id="width" type="number" min="0" step="0.0000001"></b-form-input>
                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                </validation-provider>
                                <span class="font-size-12">{{$t('Width')}}</span>
                            </div>
                            <div class="col-4 text-center">
                                <validation-provider name="height" rules="required" v-slot="{ errors }">
                                    <b-form-input v-model="height" id="height" type="number" min="0" step="0.0000001"></b-form-input>
                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                </validation-provider>
                                <span class="font-size-12">{{$t('Height')}}</span>
                            </div>
                            <div class="col-4 text-center">
                                <validation-provider name="length" rules="required" v-slot="{ errors }">
                                    <b-form-input v-model="length" id="length" type="number" min="0" step="0.0000001"></b-form-input>
                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                </validation-provider>
                                <span class="font-size-12">{{$t('Length')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4 col-6 font-size-12 text-dark font-weight-bold">
                        ¿{{$t('How many')}} {{container_name}} {{$t('you carry')}}?
                    </div>
                    <div class="col-md-8 col-6">
                        <validation-provider name="quantity" rules="required|min_value:1" v-slot="{ errors }">
                            <b-form-input v-model="quantity" id="quantity" type="number" min="0" ></b-form-input>
                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                        </validation-provider>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-8 offset-md-4 col-12 col-2 d-flex align-items-center justify-content-end">
                        <b-form-checkbox
                            v-model="stacking"
                            name="checkbox-1"
                            button-variant="secondary"
                        />
                        <span class="font-size-12 text-dark font-weight-bold">{{$t('Is the packaging stackable?')}}</span>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-8 offset-md-4 col-12">
                        <buttons
                            :back="reset"
                            :fetching="fetching"
                            :labelNext="$t('Search equipment')"
                        />
                    </div>
                </div>
            </div>
        </b-form>
    </validation-observer>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import Buttons from './Buttons';
export default {
    name: "FindTruck",
    components: { Buttons },
    data(){
        return{
            fetching: false,
        }
    },
    props: {
        reset: { type: Function },
    },
    computed: {
        ...mapGetters('catalogs', ['containerTypes']),
        ...mapGetters('marketplace', ['shipment_detail']),
        container_name(){
            return this.shipment_detail.container_type ? this.shipment_detail.container_type.toLowerCase() : null;
        },
        container_type: {
            get() { return this.shipment_detail.container_type },
            set(container_type) {
                let copy_shipment_detail = _.cloneDeep(this.shipment_detail);
                copy_shipment_detail.container_type = container_type;
                this.setShipmentDetail(copy_shipment_detail);
            },
        },
        weight: {
            get() { return this.shipment_detail.weight },
            set(weight) {
                let copy_shipment_detail = _.cloneDeep(this.shipment_detail);
                copy_shipment_detail.weight = weight;
                this.setShipmentDetail(copy_shipment_detail);
            },
        },
        width: {
            get() { return this.shipment_detail.width },
            set(width) {
                let copy_shipment_detail = _.cloneDeep(this.shipment_detail);
                copy_shipment_detail.width = width;
                this.setShipmentDetail(copy_shipment_detail);
            },
        },
        height: {
            get() { return this.shipment_detail.height },
            set(height) {
                let copy_shipment_detail = _.cloneDeep(this.shipment_detail);
                copy_shipment_detail.height = height;
                this.setShipmentDetail(copy_shipment_detail);
            },
        },
        length: {
            get() { return this.shipment_detail.length },
            set(length) {
                let copy_shipment_detail = _.cloneDeep(this.shipment_detail);
                copy_shipment_detail.length = length;
                this.setShipmentDetail(copy_shipment_detail);
            },
        },
        quantity: {
            get() { return this.shipment_detail.quantity },
            set(quantity) {
                let copy_shipment_detail = _.cloneDeep(this.shipment_detail);
                copy_shipment_detail.quantity = quantity;
                this.setShipmentDetail(copy_shipment_detail);
            },
        },
        stacking: {
            get() { return this.shipment_detail.stacking },
            set(stacking) {
                let copy_shipment_detail = _.cloneDeep(this.shipment_detail);
                copy_shipment_detail.stacking = stacking;
                this.setShipmentDetail(copy_shipment_detail);
            },
        },
    },
    methods:{
        ...mapMutations('marketplace', ['setEquipmentType', 'setShipmentDetail']),
        ...mapMutations('shipmentform', ['setSuggestedEquipment']),
        ...mapActions('shipmentform',['loadCalculator']),
        onChange(option){
            this.form.container_type = option;
        },
        async onSubmit(validate) {
            if(!(await validate())){
                return;
            }
            this.fetching = true;
            this.loadCalculator(this.shipment_detail)
                .then(data => {
                    this.setEquipmentType({
                        code: null,
                        name: null,
                        trailer_type_code: null,
                        configuration_code: data.configuration_code,
                        truck_type_code: data.truck_type_code,
                        trailer_size_code: data.trailer_size_code,
                    });
                    this.setSuggestedEquipment(true);
                })
                .catch(error => {
                    console.log("error", error);
                })
                .finally(()=>{
                    this.fetching = false;
                });

            // console.log("submit", JSON.parse(JSON.stringify(this.form)));
        },
    },
}
</script>

<style scoped>
.invalid-feedback{ text-align: left !important;}
</style>
