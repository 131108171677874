<template>
    <div class="mt-4">
      <div class="card">
        <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs">
              <li v-for="shipment in shipments" :key="shipment.id" class="nav-item">
                <a class="nav-link" :class="{'active': tab === shipment.id}" href="javascript:void(0);" @click="setShipment(shipment)">{{ $t('Shipment') }}: {{ shipment.id }}</a>
              </li>
            </ul>
        </div>
        <div class="card-body">
          <loading v-if="loading"></loading>
          <div v-else class="loadAssociated">
          <div class="loadAssociatedGroup" >
            <div class="mt-3 mb-5">
                <div class="row">
                    <div class="col-11 h30 text-right p-2">
                        <div class="font-weight-extra-bold font-size-14">
                            <span class="color_blue">Descargar</span>
                            <p>Archivo Excel</p>
                        </div> 
                    </div>
                    <div @click="download" class="col-md-1 h30 p-2">
                      <img style="cursor:pointer" width="40" src="@/assets/images/excel.png" />
                    </div>
                </div>
            </div>

            <div class="card font-size-14">
              <div class="row align-items-center pl-5 mt-3 py-1">
                <div class="col-3"><span class="font-weight-extra-bold color_blue">{{ $t('Carrier') }}:</span> {{ load.carrier.name }}</div>
                <div class="col-7">
                  <div class="row">
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">{{ $t('Permission') }}:</span> 
                      {{ bol.carta_porte.figura_transporte.transportista.permiso ? bol.carta_porte.figura_transporte.transportista.permiso : '--' }}
                    </div>
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">{{ $t('Permission number') }}:</span> 
                      {{ bol.carta_porte.figura_transporte.transportista.permiso_numero ? bol.carta_porte.figura_transporte.transportista.permiso_numero : '--' }}
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row align-items-center pl-5 py-1">
                <div class="col-3">
                  <span class="font-weight-extra-bold color_blue">{{ $t('Truck type') }}:</span>
                  {{ load.truck_type ?  load.truck_type.name : '--'}}
                </div>
                <div class="col-7">
                  <div class="row">
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">{{ $t('Insurance carrier') }}:</span> 
                      {{ bol.carta_porte.figura_transporte.transportista.vehiculo.aseguradora ? bol.carta_porte.figura_transporte.transportista.vehiculo.aseguradora : '--' }}
                    </div>
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">{{ $t('Policy') }}:</span>
                      {{ bol.carta_porte.figura_transporte.transportista.vehiculo.poliza ? bol.carta_porte.figura_transporte.transportista.vehiculo.poliza : '--' }}
                    </div>
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">ID:</span>
                      {{ load.truck ? load.truck.type.auto_transport.value +' '+ load.truck.type.auto_transport.text : '--' }}
                    </div>
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">{{ $t('Plate') }}:</span>
                      {{ bol.carta_porte.figura_transporte.transportista.vehiculo.placa ? bol.carta_porte.figura_transporte.transportista.vehiculo.placa : '--' }}
                    </div>
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">{{ $t('Year') }}:</span>
                      {{ bol.carta_porte.figura_transporte.transportista.vehiculo.year ? bol.carta_porte.figura_transporte.transportista.vehiculo.year : '--' }}
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row align-items-center pl-5 py-1">
                <div class="col-3">
                  <span class="font-weight-extra-bold color_blue">{{ $t('Trailer type') }} 1:</span>
                  {{ load.trailer ?  load.trailer.type.name : '--'}}
                </div>
                <div class="col-7">
                  <div class="row">
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">{{ $t('Plate') }}:</span>
                      {{ load.trailer ? load.trailer.plate : '--' }}
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row align-items-center pl-5 py-1">
                <div class="col-3">
                  <span class="font-weight-extra-bold color_blue">{{ $t('Trailer type') }} 2:</span>
                  {{ load.second_trailer ?  load.second_trailer.type.name : '--'}}
                </div>
                <div class="col-7">
                  <div class="row">
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">{{ $t('Plate') }}:</span>
                      {{ load.second_trailer ? load.second_trailer.plate : '--' }}
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row align-items-center pl-5 mb-5 py-1">
                <div class="col-3">
                  <span class="font-weight-extra-bold color_blue">{{ $t('Driver') }}:</span>
                  {{ bol.carta_porte.figura_transporte.operadores[0].nombre ? bol.carta_porte.figura_transporte.operadores[0].nombre : '--'}}
                </div>
                <div class="col-7">
                  <div class="row">
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">RFC:</span>
                      {{ bol.carta_porte.figura_transporte.operadores[0].rfc ? bol.carta_porte.figura_transporte.operadores[0].rfc : '--'}}
                    </div>
                    <div class="col-4 p-3">
                      <span class="font-weight-extra-bold">{{ $t('License') }}:</span>
                      {{ bol.carta_porte.figura_transporte.operadores[0].licencia ? bol.carta_porte.figura_transporte.operadores[0].licencia : '--'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div style="background-color:white !important;" class="card-header">
                <div class="row align-items-center">
                  <div class="col-4">
                    <h6>{{ $t('Stops') }} </h6>
                  </div>
                  <div class="col-8">
                    <div class="d-flex justify-content-sm-end px-2">
                      <a  class="ml-4 cursor-pointer" href="javascript:void(0);" @click="stopCard=!stopCard">
                          <i class="far fa-chevron-circle-down btn-icon"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="stopCard" class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="stops_list">
                      <div :class="[index===0 ? 'mt-2' : '']" class="stops_item" v-for="(substop,index) in stopsShipment" :key="substop.id">
                        <div class="stops_item_detail">{{index+1}}</div>
                        <div class="d-flex justify-content-between">
                          <div class="col-9">
                            <p>{{ $t('ID station') }} : <span class="text-muted font-size-11 ">{{ substop.id }}</span></p>
                            <h4 class="font-size-18">{{substop.address.city}}, {{substop.address.state}}</h4>
                            <p>{{substop.address | address-format}} <span class="text-muted font-size-11 ">{{substop.schedule_from}}</span> </p>
                            <div class="row">
                                <div class="col-4">
                                    <form-input
                                      v-if="substop.pick"
                                      class="p-0"
                                      :title="$t('RFC sender')"
                                      :border="false"
                                      rules="rfc"
                                      :upper="true"
                                      v-model="form.stop_shipments[index].rfc"
                                    />
                                    <form-input
                                      v-else
                                      class="p-0"
                                      :title="$t('RFC recipient')"
                                      :border="false"
                                      rules="rfc"
                                      :upper="true"
                                      v-model="form.stop_shipments[index].rfc"
                                    />
                                </div>
                                <div class="col-4">
                                    <form-input
                                      class="p-0"
                                      :title="$t('Distance traveled')"
                                      :border="false"
                                      v-model="form.stop_shipments[index].distance_traveled"
                                    />
                                </div>
                                <div class="col-4">
                                    <form-input
                                      class="p-0"
                                      :title="$t('Tax registration id')"
                                      :border="false"
                                      v-model="form.stop_shipments[index].tax_registration_id"
                                    />
                                </div>                                
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="stops_list pt-5">
                      <div class="d-flex justify-content-between">
                        <div class="col-8">
                          <form-input
                              class="p-0"
                              :title="$t('Observations')"
                              :border="false"
                              layout="vertical"
                              v-model="form.observations"
                            />
                        </div>
                        <div class="col-2 pt-4">
                          <button type="button" class="btn btn-primary btn-block text-uppercase" @click=saveBolInformation>
                            <b>{{$t('Save')}}</b>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h5 class="mb-5 mt-5">Mercancías</h5>

            <template v-if="this.products.length > 0">
              <div class="card" :key="index" v-for="(product,index) in this.products">
                <div class="card-header" style="background-color:white !important;">
                  <div class="row align-items-center" >
                    <div class="col-2">
                        <h6>{{ `${$t('Product')} ${index+1}`}} </h6>
                    </div>
                    <div class="col-4">
                      {{ product.product.text }} 
                    </div>
                    <div class="col-6">
                      <div class="d-flex justify-content-sm-end px-2">
                        <div>
                          <span v-if="product.is_dangerous" class="text-muted">{{ $t('Hazardous material') + ": " + product.dangerous.text }}</span>
                          <span v-else class="text-muted">{{ $t('No hazardous material')  }} </span>
                        </div>
                        <a  class="ml-4 cursor-pointer" href="javascript:void(0);" @click="product.toggle = !product.toggle">
                            <i class="far fa-chevron-circle-down btn-icon"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="product.toggle" class="card-body">
                  <div class="row text-center font-weight-extra-bold mt-3">
                    <div class="col-3">{{ $t('Product') }}</div>
                    <div class="col-3">{{ $t('Description') }}</div>
                    <div class="col-3">{{ $t('Quantity') }}</div>
                    <div class="col-3">{{ $t('Unit') }}</div>
                  </div>
                  <div class="row text-center mt-3">
                    <div class="col-3">{{ product.product.text }}</div>
                    <div class="col-3">{{ product.description }}</div>
                    <div class="col-3">{{ product.quantity }}</div>
                    <div class="col-3">{{ product.unit.text }}</div>
                  </div>
                  <hr class="mt-4">
                  <div class="row text-center font-weight-extra-bold mt-5">
                    <div class="col-3">{{ $t('Origin') }}</div>
                    <div class="col-3">{{ $t('Destiny') }}</div>
                    <div class="col-3">{{ $t('Packaging') }}</div>
                    <div class="col-3">{{ $t('Weight') }}</div>
                  </div>
                  <div class="row text-center mt-3">
                    <div class="col-3">{{ product.origin_address.city }}</div>
                    <div class="col-3">{{ product.destiny_address.city }}</div>
                    <div class="col-3">{{ product.packaging ? product.packaging.text : '- -' }}</div>
                    <div class="col-3">{{ product.weight }}</div> 
                  </div>
                  <hr class="mt-4">
                  <div class="row text-center font-weight-extra-bold mt-5">
                    <div class="col-3">SKU</div>
                    <div class="col-3">{{ $t('Tariff') }}</div>
                    <div class="col-3">{{ $t('UUID foreign trade') }}</div>
                    <div class="col-3">{{ $t('Motions') }}</div>
                  </div>
                  <div class="row text-center mt-3 mb-3">
                    <div class="col-3">{{ product.sku }}</div>
                    <div class="col-3">{{ product.tariff ? product.tariff.text  : '- -'}}</div>
                    <div class="col-3">{{ product.uuid_foreign_trade ? product.uuid_foreign_trade : '- -' }}</div>
                    <div class="col-3">{{ product.motions.length > 0 ? product.motions.join('|') : '- -'}}</div>
                  </div>
                </div>
                </div>
            </template>
            <div v-else>
              <h4>{{ $t("There are not merchandises") }}</h4>
            </div>
          </div>
        </div>
        <empty-results v-else></empty-results>
        </div>
      </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {useEventsTracker} from "@/composables/analytics";

export default {
  name: "Bol",
  computed:{
    ...mapState('loads',['load', 'stops','merchandises','bol']),
    count(){
      return this.shipments.length;
    },
    stopStatus(){
      return {
        "pending": this.$t("Stop not confirmed"),
        "delivered": this.$t("Stop confirmed"),
      }
    },
    stopsShipment(){
        return this.shipments.filter(load => load.type === 'SHIPMENT')[0].stops
    },
    shipment(){
      return this.shipments.filter(load => load.type === 'SHIPMENT')[0]
    }
  },
  data(){
    return{
      shipments:[],
      loading:true,
      stopCard:true,
      products:[],
      tab: null,
      current_shipment: null,
      form: {
        stop_shipments: [],
        observations: null
      }
    }
  },
  async created() {

    try {
      this.shipments = await this.getLoadShipments({id:this.load.id});
      let stop_shipments = _.cloneDeep(this.stopsShipment)
      this.form.stop_shipments = stop_shipments.map(stop => _.pick(stop, ['distance_traveled','rfc','tax_registration_id','order']))
      let shipment = this.shipments.filter(shipment => shipment.type === 'SHIPMENT')[0]
      await this.setShipment(shipment)
    } catch(e) {
      this.loading = false;
    }

    const { trackEvent } = useEventsTracker(this);
    trackEvent('waybill_tab', 'Load details', 'click');
  },
  methods:{
    ...mapActions('loads',['getLoadShipments','getMerchandises','exportBol','getBol']),
    ...mapActions('shipments', ['saveBol']),
    async setShipment(shipment) {
      try {
        this.loading = true;
        let getBol = this.getBol({ id: shipment.tms_shipment_id })
          .then(data => {
            this.form.observations = _.cloneDeep(data.carta_porte.observaciones)
          })
        this.tab = shipment.id
        this.current_shipment = shipment
        let getMerchandises = this.getMerchandises({ id: shipment.id })
          .then(data => {
            let products = _.cloneDeep(data.products)
            this.products = products.length > 0 ? products.map(product => {
              product['toggle'] = false
              return product;
            }) : []
          })
        await Promise.all([getBol, getMerchandises])
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    download(){
        this.exportBol({id: this.current_shipment.tms_shipment_id})
          .then(()=>{

          })
          .catch(error =>{ console.log(error) })
    },
    saveBolInformation() {
      this.saveBol({'id':this.shipment.id,...this.form})
        .then(data => {
          this.$bvToast.toast(this.$t('Bol information save successful'), {
            title: this.$t("Success"),
            variant: "success",
            solid: true,
          });
        })
        .catch(err=>{
          console.log(err)
          this.$bvToast.toast(this.$t('Error has occurred ' + err.statusText), {
            title: this.$t("Error"),
            variant: "danger",
            solid: true,
          });
        })
    }
  }
}
</script>

<style scoped>
.stops_list{
  padding-left: 60px;
}
.stops_item{
  margin-top: 50px;
  padding-right: 30px;
  position: relative;
}
.stops_item:last-child:before{
  display: none;
}
.stops_item:before{
  content: "";
  width: 25px;
  height: calc(100% + 25px);
  top: 25px;
  left: -39px;
  position: absolute;
  border-left: 2px dashed #00B3E6;
}
.stops_item_detail{
  color: white;
  text-align: center;
  position: absolute;
  left: -50px;
  top: 0;
  background: #00B3E6;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: bottom;
  line-height: 25px;
}
.fa-xxl{
  font-size: 30px;
  color: #00B3E6;
}
.table_loads{
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.font-weight-extra-bold{
  font-weight: 900;
  font-size: 15px;
}
.division{
  width: 1px;
  height: calc(100% - 20px);
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  top: 10px;
}
.h30{
  height: 40px;
}
.color_blue{
  color: #00B3E6;
}
.badge_custom{
  background: #f2f2f2;
  margin-left: 25px;
  padding: 1px 15px;
  border-radius: 15px;
}
.badge-outline-dark{
  color: black;
  background-color: white;
  border: 1px solid black;
  padding: 5px 10px;
}
.badge-outline-dark.disabled{
  color: #9d9d9c;
  background-color: white;
  border: 1px solid #9d9d9c;
}
</style>