<template>
    <div>
        <page-header :title="$t('Account')" :items="breadcrumb"/>

        <validation-observer v-if="form" ref="observer" v-slot="{ validate }">
            <b-form @submit.stop.prevent="onSubmit(validate)">
                <organization-form v-model="form.profile" id="profile." >
                    <template v-slot:append>
                        <form-input
                                :title="$t('Is internal?')"
                                :help="$t('Turn on if the organization is internal')"
                                v-model="form.is_internal"
                                rules=""
                                type="boolean"
                                :name="'is_internal'"
                        />
                    </template>
                </organization-form>
                <div v-if="['admin', 'staff'].indexOf($route.params.profile) > -1 && $route.name == 'carriers.account'" class="card">
                    <div class="card-header">
                        <h6>{{ $t('Mirror account') }}</h6>
                    </div>
                    <div class="form-fields">
                        <form-input
                            :title="$t('URL')"
                            :help="$t('Account url')"
                            rules="url"
                            v-model="form.profile.mirror_account.url"
                            type="text"
                            :name="'mirror_url'"
                        />

                        <form-input
                            :title="$t('User')"
                            :help="$t('Account user')"
                            v-model="form.profile.mirror_account.user"
                            rules=""
                            type="text"
                            :name="'mirror_user'"
                        />

                        <form-input
                            :title="$t('Password')"
                            :help="$t('Account password')"
                            v-model="form.profile.mirror_account.password"
                            rules=""
                            type="password"
                            :name="'mirror_password'"
                        />
                    </div>
                </div>
                <address-form v-model="form.profile.address" :title="$t('Address')" />

                <div class="d-flex justify-content-start pb-4">
                    <button type="submit" class="btn btn-primary">{{$t('Save')}}</button>
                </div>
            </b-form>
        </validation-observer>
        <loading v-else />
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import AddressForm from '@/components/form/AddressForm';
    import OrganizationForm from '@/components/form/OrganizationForm';
    export default {
        name: "Account",
        components:{
            AddressForm,
            OrganizationForm
        },
        mixins: [formMixin],
        data(){
            return {
                loading : false,
                form: null
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapState('accounts',['account']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                let accountType;
                let accountProfile;
                if(this.$route.meta.type === 'shipper'){
                    accountType = {text: this.$t('Shippers'), to: {name:'shippers'}};
                    accountProfile = {text: `${this.$route.params.shipper_id}`, active:true};
                }
                if(this.$route.meta.type === 'carrier'){
                    accountType = {text: this.$t('Carriers'), to: {name:'carriers'}};
                    accountProfile = {text: `${this.$route.params.carrier_id}`, active:true}; 
                }
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    accountType,
                    accountProfile
                ]
            }
        },
        watch:{
            '$route': 'init',
            account(newValue, oldValue){
                if(newValue){
                    this.form = _.cloneDeep(newValue);
                }
            }
        },
        mounted () {
            this.form = _.cloneDeep(this.account);
        },
        methods: {
            ...mapActions('accounts',['getAccount','updateAccount','fetchShippers','fetchCarriers']),
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    return;
                }
                this.loading = true;
                let form = _.cloneDeep(this.form);
                form.is_internal = form.profile.is_internal;

                if (form.profile.rfc)
                    form.profile.rfc = form.profile.rfc.toUpperCase()

                this.updateAccount(form)
                    .then(()=>{
                        this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                    
                    })
                    .catch(error=>{
                        this.processServiceError(error);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });

            }
        },
    }
</script>

<style scoped>

</style>
