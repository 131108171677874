<template>
    <div>
        <div class="my-3">
            <issues-table v-model="selected" :filters="filters" :loadId="$route.params.load_id" />
        </div>
        <div class="text-right" v-if="!isShipment">
            <router-link v-can="'issue.create'" v-b-tooltip.hover :to="{name: 'loads.detail.issues.new'}" class="btn btn-primary">
                {{$t('Add issue')}}
            </router-link>
        </div>

        <issue-modal v-model="form" :load_id="load_id" :visible="addIssue" v-on:updateData="updateData" />
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import IssuesTable from '@/components/tables/IssuesTable';
import IssueModal from '@/components/modals/EditIssue'
import filterMixin from "@/mixins/filterMixin";
import {useEventsTracker} from "@/composables/analytics";

export default {
    name: 'Issues',
    components:{
        IssuesTable,
        IssueModal
    },
    mixins:[filterMixin],
    computed:{
      ...mapState('loads',['load']),
      isShipment(){
        return this.$route.meta.type === 'shipment' ? true : false
      }
    },
    data(){
        return {
            selected: [],
            load_id: this.$route.params.load_id,
            form: {
                load_id: this.$route.params.load_id
            },
            addIssue: false
        }
    },
    created(){  
        this.defaultFilters = {load_id: this.$route.params.load_id};
        const { trackEvent } = useEventsTracker(this);
        trackEvent('events_tab', 'Load details', 'click');
    },
    methods: {
        ...mapActions('issues',['getIssues']),
        updateData(){
            this.addIssue = false;
            this.getIssues(this.filters);
        }
    }
}
</script>