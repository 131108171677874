import axiossls from "../../api/clientsls";

// initial state
export const state = () => ({
    types: [],
    reasons: [],
    load_return: [],
    shipments_details: [],
    shipment_return: [],
});

// getters
export const getters = {
    types: (state) => {
        return state.types;
    },
    reasons: (state) => {
        return state.reasons;
    },
    load_return: (state) => {
        return state.load_return;
    },
    shipments_details: (state) => {
        return state.shipments_details;
    },
    shipment_return: (state) => {
        return state.shipment_return;
    },
}

// actions

export const actions = {
    getTypes({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .post(`shipment-return/types`, payload)
                .then((response) => {
                    commit('setTypes', response.data.data);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
    getReasons({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .post(`shipment-return/types/${payload.id}/reasons`, payload)
                .then((response) => {
                    commit('setReasons', response.data.data[0].reasons);
                    resolve(response.data.data[0].reasons);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
    getLoadReturn({ commit }, payload) { // verificar
        return new Promise((resolve, reject) => {
            axiossls
                .get(`load-return/${payload.load_id}`, payload)
                .then((response) => {
                    commit('setLoadReturn', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
    getShipmentsDetails({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .get(`loads/${payload.id}/shipment-details`, payload)
                .then((response) => {
                    commit('setShipmentsDetails', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
    storeShipmentReturn({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .post(`shipment-return`, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
    deleteShipmentReturn({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .delete(`shipment-return/${payload.id}`, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
    getShipmentReturn({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .get(`shipment-return/${payload.id}`, payload)
                .then((response) => {
                    commit('setShipmentReturn', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
    confirmLoadReturn({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .post(`load-return/${payload.load_id}/confirm`, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
    putShipmentReturn({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
                .put(`shipment-return/${payload.id}`, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },
}

// mutations
export const mutations = {
    setTypes(state, data) {
        state.types = data;
    },
    setReasons(state, data){
        state.reasons = data;
    },
    setLoadReturn(state, data){
        state.load_return = data;
    },
    setShipmentsDetails(state, data){
        state.shipments_details = data;
    },
    setShipmentReturn(state, data){
        state.shipment_return = data;
    },
}
