<template>
  <div class="register" style="">
    <div class="register_title_back register_section cursor-pointer" @click="back()"> <i class="fa fa-chevron-left"/>
      {{ $t('Back') }} </div>
    <div class="register_title register_section">{{$t('Confirmation code')}}</div>
    <div class="register_section register_info">
      <div class="register_info_icon">
          <div class="register_info_icon_container">
            <i class=" far fa-phone  "></i>
          </div>
      </div>
      <div class="register_info_label">
        <p class="register_info_label_title">{{$t('Enter the code we sent to')}}: <span>{{phone}}</span></p>
        <p class="register_info_label_label">({{$t('Code valid for 30 minutes')}})</p>
      </div>
    </div>

    <div class="register_country register_section mb-5">
      <div class="register_country_label mb-2 ">{{ $t('Code') }}</div>
      <otp-input
          :isValid="isCodeValid"
          :isDisabled="isDisabled"
          @on-complete="onCompleteHandler"
          @on-changed="onChangedHandler" activeInputClass="opt_active" separateInputClass="opt_item"
          :digits="4" :autoFocus="true" :placeholder="''"
          @on-paste="onPasteHandler">
      </otp-input>
    </div>

    <div class="register_section register_resend">
      <p class="register_resend_label">{{$t("Didn't get the message?")}}</p>
      <p class="register_resend_link  cursor-pointer " @click="resendCode()">{{$t('SEND AGAIN')}}</p>
    </div>


    <alert-modal :visible="notification.visible" :success="notification.success"  :title="notification.title" :message="notification.message" :sub_message="notification.sub_message" :reload_on_close="false" v-on:close="closeNotificationMain" />


  </div>
</template>

<script>

import  OtpInput  from  "otp-input-vue2";


import {mapActions, mapGetters, mapMutations} from "vuex";
import AlertModal from "../../../components/modals/AlertModal";
import AlertModalMixin from "../../../mixins/AlertModalMixin";

export default {
  name: 'Register_OTPCode',
  components: {
    OtpInput,AlertModal
  },
  mixins:[AlertModalMixin],
  data(){
    return{
      isCodeValid: true,
      isDisabled: false,
      phone: "",
    }
  },
  created() {
    let phone = _.cloneDeep(this.getPhone());
    if (!phone){
      this.$router.push({name:"register.index"})
    }else{
      this.phone = phone
    }
  },
  methods: {
    ...mapGetters('register',['getPhone','getPhoneInfo']),
    ...mapActions('register',['fetchValidCode']),
    ...mapMutations('register',['setCode']),
    ...mapMutations('register',['setPhoneInfo']),
    ...mapActions('register',['fetchRequestCode']),
    onCompleteHandler(code){
      let phone = _.cloneDeep(this.getPhone())
      const data = {
        "phone": phone,
        "code": code
      };
      this.isDisabled = true;
      this.fetchValidCode(data).then((res)=>{
        this.setCode(code);
        this.$router.push({
          name:"register.username"
        })
      }).catch((err)=>{
        this.notificationError(
            "Error",
            this.$t("Wrong security code"),
            this.$t("Check that when entering your verification code there are no errors, in case the code has expired you can make the request so that we send you an updated one."));

      }).finally((d)=>{
        this.isDisabled = false;
      })
    },

    onChangedHandler(lastEnteredCode){
      this.isCodeValid = true;
    },

    onPasteHandler(code){

    },
    closeNotificationMain(){
      this.closeNotification()

    },
    back(){
      this.$router.push({
        name:"register.index"
      })
    },
    resendCode(){
      this.loading = true;
      let data = _.cloneDeep(this.getPhoneInfo());
      this.fetchRequestCode(data).then((response)=>{
        this.setPhoneInfo(data);
        this.notificationSuccess(this.$t('Success'),this.$t('Code sent'),'')
      }).catch((err)=>{
        console.log(err)
      }).finally(()=>{
        this.loading = false;
      })
    }
  },
}
</script>


<style lang="scss">



</style>
