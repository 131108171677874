<template>
    <div class="field-wrapper" :class="[border?'':'border-0',padding?'':'p-0',]">
        <div class="row align-items-center ">
            <div v-if="title" :class="[layout ==='horizontal' ?'col-md-6':'col-md-12']">
                <div class="field-label">{{title}} <span v-if="rules.indexOf('required') > -1" class="field-required-badge"/> </div>
                <div class="field-help">{{help}}</div>
            </div>
            <div :class="[layout ==='horizontal' ?'col-md-6':'col-md-12 text-center']">
                <validation-provider :name="name" :rules="rules" v-slot="{ errors }" :vid="name">
                    <template v-if="type === 'boolean'">
                        <switches v-model="selected" :disabled="disabled" theme="bootstrap" color="info" class="float-right" />
                    </template>
                    <template v-else-if="type === 'select'">
                        <multiselect :disabled="readOnly || disabled" v-model="selected" :options="options"
                                     :placeholder="placeholder" :track-by="selectValue" :label="selectText" select-label=""
                                     selected-label="" @select="onChange"
                                     :allow-empty="rules.indexOf('required') !== -1"
                                     deselect-label=""
                                     :multiple="multiple"
                                     :searchable="searchable"
                                     :loading="loading"
                        />
                    </template>
                    <template v-else-if="type === 'group-select'">
                        <multiselect :disabled="readOnly || disabled" v-model="selected" :options="options"
                                     :placeholder="placeholder" :track-by="selectValue" :label="selectText" select-label=""
                                     selected-label="" @select="onChange"
                                     :allow-empty="rules.indexOf('required') !== -1"
                                     deselect-label=""
                                     :multiple="multiple"
                                     :searchable="searchable"
                                     :loading="loading"
                                    :group-values="groupValues"
                                    :group-label="groupLabel"
                                    :group-select="groupSelect"
                        />
                    </template>
                    <template v-else-if="type === 'tag'">
                      <input-tag v-model="selected"></input-tag>
                    </template>
                    <template v-else-if="type === 'textarea'">
                        <b-form-textarea :disabled="disabled" name="" v-model="selected" :rows="rows" :placeholder="selected"></b-form-textarea>
                    </template>
                    <template v-else-if="type ==='sign'">
                        <div style="border: 1px solid #dad9d9;">
                            <img v-if="value" v-bind:src="selected" style="width: 100%;" />
                            <vueSignature v-if="!value" :disabled="readOnly || disabled" ref="signature" :sigOption="signature.option"
                                          :defaultUrl="typeof selected === 'string' ? selected : null" h="150px"></vueSignature>
                        </div>
                       <div v-if="!value && readOnly === false" class="mt-2" >
                           <button class="btn btn-light btn-sm mr-2" @click="clearSignature">{{$t('Clear')}}</button>
                           <button class="btn btn-light btn-sm" @click="saveSignature">{{$t('Save')}}</button>
                       </div>
                    </template>
                    <template v-else-if="type==='checkbox'">
                        <div class="row" v-for="(item,index) in options" :key="name+'-cb-f-'+index">
                            <div class="col-md-12">
                                <b-form-checkbox
                                        v-model="selected"
                                        name="checkbox-1"
                                        :disabled="readOnly || disabled"
                                        button-variant="secondary"
                                        :value="item[selectValue]"
                                        @change="onChange"
                                >
                                    {{item[selectText]}}
                                </b-form-checkbox>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="type==='radio'">
                        <div class="row">
                            <div class="col-md-12">
                                    <b-form-radio-group
                                        id="radio-group-2"
                                        v-model="selected"
                                        :class="align"
                                        name="radio-sub-component"
                                        :stacked="stacked"
                                    >
                                        <b-form-radio :value="item[selectValue]" v-for="(item,index) in options" :key="name+'-ra-f-'+index">{{item[selectText]}}</b-form-radio>
                                    </b-form-radio-group>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="type === 'avatar'">
                        <div  v-if="image" style="width: 300px;" class="mx-auto">
                          <cropper
                                class="cropper"
                                :src="image"
                                :stencil-props="{
                                aspectRatio: 1
                                }"
                                @change="onImageChange"
                            ></cropper>
                             <div class="text-center m-3">
                                  <button type="button" class="btn btn-primary btn-sm" @click="updateImage"><i class="far fa-cloud-upload-alt mr-2"/>{{$t('Update')}}</button>
                             </div>
                        </div>
                        <template v-else>
                            <file-input
                                    v-if="upload || !selected"
                                    v-on:success="data=>onFileChange(data)"
                                    v-on:file-added="onImageAdded"
                                    :multiple="false"
                            />
                            <div v-else class="text-center">
                                <img class="rounded-circle" :src="selected" width="100" height="100" style="object-fit: cover;" />
                                <div class="mt-2">
                                    <button type="button" class="btn btn-light btn-sm" @click="upload = true"><i class="far fa-cloud-upload-alt mr-2"/>{{$t('Change')}}</button>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template v-else-if="type === 'file'">
                        <file-input
                                    v-if="upload || !selected"
                                    path="/api/v2/upload"
                                    :options="optionFile"
                                    v-on:success="onFileChange"
                                    v-on:file-added="onFileAdded"
                        />
                        <div v-else class="text-center">
                            <img  v-if="['jpeg','png','jpg'].indexOf(extension) > -1" style="width: 100px;" class="mx-auto" :src="selected" />
                            <a v-else :href="selected"><i style="font-size: 60px;" class="fas fa-file-alt"></i></a>
                            <div>{{fileName}}</div>
                             <div class="mt-2">
                                 <button type="button" class="btn btn-light btn-sm" @click="upload = true"><i class="far fa-cloud-upload-alt mr-2"/>{{$t('Change')}}</button>
                             </div>
                        </div>
                    </template>

                    <template v-else-if="type === 'date'">
                        <date-input v-model="date" v-on:change="date => selected = $moment(date).format('YYYY-MM-DD')" format="YYYY-MM-DD" :disabled="disabled" type="date" />
                    </template>
                    <template v-else-if="type === 'datetime'">
                        <date-input v-model="date" v-on:change="date => selected = $moment(date).format('YYYY-MM-DD HH:mm:ss')" format="YYYY-MM-DD HH:mm:ss" :disabled="disabled" type="datetime" />
                    </template>
                    <template v-else-if="type === 'number'">
                        <div v-if="readOnly">
                            {{selected}}
                        </div>
                        <b-form-input :class="[{upper: upper}]" v-else v-model="selected" :disabled="disabled" type="number" :step="step" :placeholder="placeholder" @change="onChange"></b-form-input>
                    </template>
                    <template v-else-if="type === 'email-group'">
                       <div v-if="readOnly">
                           {{selected}}
                       </div>
                       <div class="input-group" v-else>
                           <b-form-input :class="[{upper: upper}]"  v-model="selected" :disabled="disabled" type="email" :placeholder="placeholder" @change="onChange"></b-form-input>
                           <div class="input-group-append">
                               <button class="btn btn-primary_v2" type="button" @click="clickButtonGroup(true)">Confirmar</button>
                           </div>
                       </div>
                   </template>
                    <template v-else>
                        <div v-if="readOnly">
                            {{selected}}
                        </div>
                        <b-form-input :class="[{upper: upper}]" v-else v-model="selected" :disabled="disabled" :type="type" :placeholder="placeholder" @change="onChange"></b-form-input>
                    </template>
                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                </validation-provider>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import VueSignature from "vue-signature";
    import {mapActions} from 'vuex';
    import Switches from 'vue-switches';
    import FileInput from '@/components/form/FileInput';
    import DateInput from "@/components/form/DateInput";
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';

    export default {
        name: "FormInput",
        components:{
            Multiselect,
            VueSignature,
            Switches,
            FileInput,
            DateInput,
            Cropper
        },
        props:{
            title:{
                type: String,
              default(){return null}
            },
            help:{
                type: String,
                default(){return ''}
            },
            placeholder:{
                type: String,
                default(){return ''}
            },
            type:{
                type: String,
                default(){return 'text'}
            },
            align:{
                type: String,
                default(){return 'text-left'}
            },
            rules:{
                type: String,
                default(){return ''}
            },
            name: {
                type: String,
            },
            options: {
                type: Array,
            },
            selectValue:{
                type: String,
                default(){return 'value'}
            },
            selectText:{
                type: String,
                default(){return 'text'}
            },
            value:{
                type: [String, Number, Object, Array, Boolean]
            },
            readOnly:{
                type: Boolean,
                default(){
                    return false
                }
            },
            disabled: {
                type: Boolean,
                default(){
                    return false
                }
            },
            stacked: {
              type: Boolean,
              default(){
                return false
              }
            },
            layout:{
              type: String,
              default(){
                return 'horizontal'
              }
            },
            border:{
              type: Boolean,
              default(){
                return true
              }
            },
            padding:{
              type: Boolean,
              default(){
                return true
              }
            },
            multiple:{
              type: Boolean,
              default(){
                return false
              }
            },
            groupValues:{
              type: String,
              default(){
                return null
              }
            },
            groupLabel:{
              type: String,
              default(){
                return null
              }
            },
            groupSelect:{
              type: Boolean,
              default(){
                return false
              }
            },
            loading:{
              type: Boolean,
              default(){
                return false
              }
            },
            rows:{
              type: Number,
              default(){
                return 5
              }
            },
            searchable:{
              type: Boolean,
              default(){
                return true
              }
            },
            upper:{
              type: Boolean,
              default(){
                return false
              }
            },
            step:{
              type: Number,
              default(){
                return 0.01
              }
            },
            optionFile: {
                type: Object,
                default() {
                    return {dictDefaultMessage: this.title}
                }
            },
            autoSelectFirst: {
                type: Boolean,
                default(){
                    return false
                }
            },
            clickButtonGroup:{
                type: Function
            }
        },
        data(){
            return {
                init: false,
                selected:null,
                extension:null,
                signature:{
                    option:{
                        penColor:"rgb(0, 0, 0)",
                        backgroundColor:"rgb(255,255,255)"
                    }
                },
                upload: false,
                fileName: null,
                date: null,
                image:null,
                imgSrc: null
            }
        },
        watch:{
            selected: {
                deep:true,
                handler(val, oldVal){
                    if(this.init === true || (val && !oldVal)){
                        if(this.type  === 'select' && val){
                            if(this.multiple){
                              val = val.map(item => item[this.selectValue])
                            }else{
                              val = val[this.selectValue];
                            }
                        }
                        if(this.type  === 'group-select' && val){
                            if(this.multiple){
                              val = val.map(item => item[this.selectValue])
                            }else{
                              val = val[this.selectValue];
                            }
                        }
                        if(this.type  === 'sign'){
                            if(typeof val === 'object'){
                                try {
                                    val = val[0];
                                }catch (e) {
                                }
                            }
                        }

                        if(!_.isEqual(val, oldVal)){
                            this.$emit('input', val);
                            //this.$emit('change', val);
                        }
                        this.init = true;
                    }else{
                        this.init = true;
                    }
                }
            },
            value: {
                deep:true,
                handler(value, oldValue){
                    if(value !== undefined){
                        if(!_.isEqual(value, oldValue)){
                            this.setValue(value);
                        }
                    }
                }
            },
            options: {
                deep: true,
                handler(newValue){
                    let defaultValue = this.value;
                    if(this.type  === 'select'){
                        defaultValue = newValue.find(item => (item[this.selectValue] === this.value));
                    }
                    if(this.type  === 'checkbox'){
                        if(this.value){
                            defaultValue = (newValue.filter(item => (this.value.indexOf(item[this.selectValue]) > -1 ))).map(item => item[this.selectValue]);
                        }
                    }
                    this.selected = defaultValue;
                }
            }
        },
        created() {
            this.setValue(this.value);
        },
        methods:{
            ...mapActions('uploads',['storeFile']),
            setValue(value){
                let defaultValue = value;
                if(this.type  === 'select'){
                  if(this.multiple){
                    defaultValue = this.options.filter(item => (value.indexOf(item[this.selectValue]) > -1));
                  }else{
                    defaultValue = this.options.find(item => (item[this.selectValue] === value));
                  }
                    if(!defaultValue && this.autoSelectFirst && this.options.length > 0){
                        defaultValue = this.options[0];
                    }
                }
                if(this.type  === 'group-select'){
                  if(this.multiple){
                    let values = [];
                    for(let item of this.options){
                        values = _.union(values, item[this.groupValues].filter(option => (value.indexOf(option[this.selectValue]) > -1)));
                    }
                    defaultValue = values;
                  }else{
                      for(let item of this.options){
                          let el = item[this.groupValues].find(option => option[this.selectValue] === value);
                          if(el){
                              defaultValue = el;
                              break;
                          }
                      }
                  }

                }
                if(this.type  === 'checkbox'){
                    if(value){
                        defaultValue = (this.options.filter(item => (value.indexOf(item[this.selectValue]) > -1 ))).map(item => item[this.selectValue]);
                    }
                }
                if(this.type  === 'sign'){
                    if(typeof value === 'object'){
                        try {
                            defaultValue = value[0];
                        }catch (e) {

                        }
                    }
                }
                if(this.type  === 'number'){
                    defaultValue = Number(value);
                }
                if(this.type  === 'avatar' && this.value === null){
                    this.init = true;
                }
                this.selected = defaultValue;
            },
            clearSignature(){
                this.$refs.signature.clear();
            },
            saveSignature(){
               let png = this.$refs.signature.save();
               // TODO: Store as base 64
                this.storeFile({base64: png})
                    .then(data=>{
                        this.selected = data.url;
                        this.onChange();
                    })
                    .catch(error =>{

                    })
            },
            onFileChange(data){
                this.selected = data.url;
                this.extension = data.extension;
                this.upload = false;


            },
            onChange(val){
                this.$nextTick(()=>{
                    let val = this.selected;
                    if(this.type  === 'select'){
                        val = val[this.selectValue];
                    }

                    this.$emit('change', val);
                });
            },
            onFileAdded(file){
                this.fileName = file.name
            },
            onImageAdded(file){
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                let encoded = reader.result.toString();
                   this.image = encoded;
                };
            },
            onImageChange(data){
                this.imgSrc = data.image.src;
            },
            updateImage(){
                this.selected = this.imgSrc;
                this.image = null;
                this.onChange();
                this.upload = false;
            }
        }
    }
</script>

<style scoped>
 .upper {
     text-transform: uppercase;
 }
</style>
