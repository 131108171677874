import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import vco from "v-click-outside"
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import es from "@/locales/validate/es.json";
import * as rules from "vee-validate/dist/rules";

import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import VueLetterAvatar from './components/common/VueLetterAvatar';
import EmptyResults from "./components/common/EmptyResults";
import BadgeStatus from "./components/common/BadgeStatus";
import FormInput from "./components/form/FormInput";
import ResourceInput from "./components/form/ResourceInput";
import PageHeader from "./components/page-header";
import moment from "moment-timezone";
import "@/assets/scss/app.scss";
import validateRfc from './helpers/validateRfc';
import VueGtm from '@gtm-support/vue2-gtm';



import Loading from "@/components/common/Loading";
import addressFormat from './helpers/address-format';
import InputTag from 'vue-input-tag'


import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

var infiniteScroll = require('vue-infinite-scroll');
//import {infiniteScroll} from "vue-infinite-scroll"

import VueGtag from "vue-gtag";

window._ = require('lodash')


Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

import VueIntercom from 'vue-intercom';

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(VueIntercom, { appId: 'ryxxfbsk' });
Vue.use(Vuelidate)
Vue.use(infiniteScroll)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_KEY,
    libraries: 'places,geometry',
  },
  installComponents: true
})

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,  // Your Google Tag Manager ID
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,  // Pass the router instance to automatically sync with router
});

if (process.env.NODE_ENV === "production") {
  Vue.use(VueGtag, {
    config: {
      id: "G-TS06B39Z4H",
    },
  }, router);
}

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
extend('minprice', {
  validate(value, { min }) {
    return Number(value) >= Number(min);
  },
  params: ['min'],
  message: i18n.t('Invalid amount')
});

extend('rfc', {
  validate(value) {
    return validateRfc(value.toUpperCase());
  },
  message: i18n.t('Invalid rfc')
});
extend('min_date', {
  validate(value, { min }) {
    return moment(value).isSameOrAfter(min);
  },
  params: ['min']
});
extend('max_date', {
  validate(value, { max }) {
    return moment(value).isSameOrBefore(max);
  },
  params: ['max']
});

extend('numeric', {
  validate(value) {
    let regexNumeric = /^[+-]?([0-9]*[.])?[0-9]+$/
    return regexNumeric.test(value);
  },
  message: i18n.t('Invalid number')
});

extend('url', {
  validate(value) {
    let regexNumeric = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    return regexNumeric.test(value);
  },
  message: i18n.t('Invalid url')
});

localize("es", es);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.component('vue-letter-avatar', VueLetterAvatar)
Vue.component('empty-results', EmptyResults);
Vue.component('apexchart', VueApexCharts)
Vue.component('page-header', PageHeader)
Vue.component('loading', Loading);
Vue.component('badge-status', BadgeStatus);
Vue.component('form-input', FormInput);
Vue.component('resource-input', ResourceInput);
Vue.component('input-tag', InputTag);
import VueScrollbar from 'vue-scrollbar-live';
Vue.component('scrollbar', VueScrollbar);

Vue.directive('can', {
  inserted(el, binding, vnode, old) {
    if (!binding.value) return true;
    if (!store.getters['profile/userCan'](binding.value)) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    }
  }
});

Vue.prototype.$can = (permission) => {
  return store.getters['profile/userCan'](permission);
}

Vue.filter('address-format', function (value) {
  if (!value) return '- -';
  let lines = addressFormat(value);
  return lines.length > 0 ? lines.join(', ') : '- -';
});

Vue.filter('uppercase', function (value) {
  if (value) return String(value).toUpperCase()
  return "";
});


Vue.filter('time', function (value) {
  if (!value) return '- -';
  return moment(value).format('h:mm A');
});

Vue.filter('date', function (value) {
  if (!value) return '- -';
  return moment(value).format('YYYY-MM-DD');
});

Vue.filter('human_date', function (value) {
  if (!value) return '- -';
  return moment(value).format(" D MMMM YYYY [a las] HH:mm");
});

Vue.filter('human_date_min', function (value) {
  if (!value) return '- -';
  return moment(value).format(" D MMM");
});

Vue.filter('human_date_month', function (value) {
  if (!value) return '- -';
  return moment(value).format(" MMMM ");
});

Vue.filter('timestamp', function (value, format = 'YYYY-MM-DD HH:mm:ss', timezone = null) {
  if (!value) return '- -';
  let tz = timezone ? timezone : store.getters['profile/timezone'];
  return moment.utc(value).tz(tz).format(format);
});

Vue.filter('money', function (value) {
  if (!value) return '0.00';
  let val = (value / 1).toFixed(2).replace(',', '.')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
});

Vue.filter('capitalize', function (value) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    return null
  }
});

Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

Vue.prototype.$setRouteQuery = (router, route, filters) => {
  let query = { ...filters };
  if ((filters.page)) {
    query.page = `${filters.page}`;
  }
  if (!_.isEqual(query, route.query)) {
    try {
      router.push({ query }).catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {

        }
      });
    } catch (error) {

    }
  }
};
//moment.locale(process.env.VUE_APP_I18N_LOCALE || 'es');
Vue.prototype.$moment = moment;


// Socket.IO
import Echo from "laravel-echo"



import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'

Amplify.configure({
  ...awsconfig,
})






new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')