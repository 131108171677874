<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <th class="text-center" style="width: 48px;">
                        <b-form-checkbox
                                v-model="selectAll"
                                name="checkbox-1"
                                button-variant="secondary"
                        >
                        </b-form-checkbox>
                    </th>
                    <th class="text-center">ID</th>
                    <th class="text-center">{{ $t('Name') }}</th>
                    <th class="text-center">{{ $t('Phone') }}</th>
                    <th class="text-center">{{ $t('Country') }}</th>
                    <th class="text-center">{{ $t('Street') }}</th>
                    <th class="text-center">{{ $t('Zip code') }}</th>
                    <th class="text-center">{{ $t('City') }}</th>
                    <th class="text-center">{{ $t('State') }}</th>
                    <th class="text-center" style="width: 90px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fetching">
                    <td colspan="100%">
                        <loading />
                    </td>
                </tr>

                <template v-for="item in list.results">
                    <tr v-if="init || page === list.current_page" :key="'branchoffice-'+item.id"  class="branchoffice-row">
                        <td>
                            <b-form-checkbox
                                    v-model="selected"
                                    name="checkbox-1"
                                    :value="item.id"
                            >
                            </b-form-checkbox>
                        </td>
                        <td class="text-center">
                            <router-link :to="{name:'branchoffices.detail', params:{branchoffice_id: item.id}}">
                                <b>{{item.id}}</b>
                            </router-link>
                        </td>

                        <td class="text-center">
                            {{ item.name }}
                        </td>

                        <td class="text-center">
                            {{ item.phone }}
                        </td>

                        <td class="text-center">
                            {{ item.address.country }}
                        </td>

                        <td class="text-center">
                            {{ item.address.line_1 }}
                        </td>

                        <td class="text-center">
                            {{ item.address.zip_code }}
                        </td>

                        <td class="text-center">
                            {{ item.address.city }}
                        </td>

                        <td class="text-center">
                            {{ item.address.state }}
                        </td>
                        
                        <td class="text-center">
                            <a v-can="'branchoffices.delete'" @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-2">
                                <i class="far fa-trash-alt btn-icon"  />
                            </a>
                            <router-link v-can="'branchoffices.update'"  :to="{name:'branchoffices.edit', params:{branchoffice_id: item.id}}" class="mr-2">
                                <i class="far fa-edit btn-icon"></i>
                            </router-link>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div  v-if="list.total > 0 && init"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    import Loading from "../common/Loading";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import Swal from "sweetalert2";

    export default {
        name: "BranchofficeTable",
        mixins:[selectAllMixin],
        components: {
            'loading':Loading
        },
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            },
        },
        data(){
            return {
                page: 1,
                init: false,
                fetching: false
            }
        },
        computed: {
            ...mapState('branchoffices', ['last_filters', 'branchoffices']),
            ...mapState('profile',['profile']),
            list(){
                return this.branchoffices
            }
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
            this.fetchData();
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal)){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('branchoffices',['getBranchoffices','getBranchoffice','deleteBranchoffice']),
            fetchData(){
                this.fetching = true;
                this.getBranchoffices(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.fetching = false;
                        this.init = true;
                    })
            },
            confirmDelete(item){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteBranchoffice({id: item.id})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Branch office").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                        });
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .branchoffice-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }
</style>
