<template>
    <div>
        <page-header :title="$route.params.trailer_id ? $t('Edit trailer'): $t('New trailer')" :items="breadcrumb"/>

        <div class="row">
            <div :class="[$route.meta.class ? $route.meta.class:'col-md-8 offset-md-2']">
                <validation-observer ref="observer" v-slot="{ validate }">
                    <b-form @submit.stop.prevent="onSubmit(validate)">
                        <div class="card">
                            <div class="card-header">
                                <h6>General</h6>
                            </div>
                             <div v-if="$route.params.profile === 'admin' && $route.name ==='trailers.add'" class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Carrier')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t("The carrier owner")}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="carrier_id" rules="required" v-slot="{ errors }">
                                            <carrier-input v-model="form.carrier" :disabled="loading"/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <form-input
                                    :title="$t('Custom ID')"
                                    :help="$t('Unique trailer identifier')"
                                    v-model="form.external_id"
                                    rules="required"
                                    type="text"
                                    :name="'external_id'"
                            />
                            <form-input
                                    v-if="false"
                                    :title="$t('TMS ID')"
                                    v-model="form.tms_id"
                                    rules=""
                                    type="text"
                                    :name="'tms_id'"
                            />

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Plate')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The license plate')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="plate" rules="required|max:8" v-slot="{ errors }">
                                            <b-form-input  v-model="form.plate" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                                
                            <form-input
                                    :title="$t('Model')"
                                    :help="$t('The trailer model')"
                                    v-model="form.model"
                                    rules="required"
                                    type="text"
                                    :name="'model'"
                            />

                            <form-input
                                    :title="$t('Brand')"
                                    :help="$t('The trailer brand')"
                                    v-model="form.brand"
                                    rules="required"
                                    type="text"
                                    :name="'brand'"
                            />

                            <form-input
                                    :title="$t('Availability')"
                                    :help="$t('Turn off if resource is not available')"
                                    v-model="form.is_active"
                                    rules=""
                                    type="boolean"
                                    :name="'is_active'"
                            />
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <h6>{{$t('Configuration')}}</h6>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Trailer type')}}<span class="field-required-badge"/></div>
                                        <div class="field-help"></div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="type_id" rules="required" v-slot="{ errors }">
                                            <trailer-type-input v-model="form.type" :disabled="loading" />
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-6">
                                        <div class="field-label">{{$t('Length')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The trailer length')}}</div>
                                    </div>
                                    <div class="col-6">
                                        <validation-provider name="length_id" rules="required" v-slot="{ errors }">
                                            <multiselect label="name" v-model="form.length" :options="loadLength"/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                        </div>

                         <address-form v-model="form.address" :title="$t('Address')" :required="false" :collapsed="true">
                            <template v-slot:section-start>
                                <address-input v-model="form.address" />
                            </template>
                         </address-form>

                        <div class="d-flex justify-content-end pb-4">
                            <button :disabled="loading" type="button" class="btn btn-light mr-3 " @click="$router.push({name:'trailers.index'})">{{$t('Cancel')}}</button>
                            <button :disabled="loading" v-if="!$route.params.trailer_id" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew">{{$t('Save and create new')}}</button>
                            <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t('Save')}}</button>
                        </div>

                    </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import Multiselect from 'vue-multiselect';
    import TruckInput from '@/components/form/TruckInput';
    import TrailerTypeInput from '@/components/form/TrailerTypeInput';
    import CarrierInput from '@/components/form/CarrierInput';
    import AddressForm from '@/components/form/AddressForm';
    import AddressInput from "@/components/form/AddressInput";

    export default {
        name:'TrailerForm',
        beforeRouteEnter(to, from, next) {
            next((vm) =>  vm.from = from)
        }, 
        components:{
            Multiselect,
            TruckInput,
            TrailerTypeInput,
            CarrierInput,
            AddressForm,
            AddressInput
        },
        mixins: [formMixin],
        data(){
            return {
                title: '...',
                form: {status: 1},
                createNew: false,
                loading: false,
                from: null
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapGetters('catalogs',['load_length']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Trailers"),
                        to: {name:'trailers.index'}
                    },
                   {
                        text: this.$route.params.trailer_id ? this.$t('Edit trailer'): this.$t('New trailer'),
                        active: true
                    }
                ]
            },
            loadLength(){
                return this.load_length
            },
        },
        created() {
            if(this.$route.params.trailer_id){
                this.loading = true;
                this.getTrailer({id: this.$route.params.trailer_id})
                    .then(data=>{
                        let form = {...data}
                        this.form = form;
                        this.loading = false;
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    })
            }else{
                // Use param filters
                if(this.$route.meta.paramFilters){
                    for(let key of this.$route.meta.paramFilters){
                        this.form[key] = this.$route.params[key];
                    }
                }
            }
        },
        methods: {
            ...mapActions('trailers',['getTrailer','storeTrailer','updateTrailer']),
            resetForm() {
                this.form = {};
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },
            saveAndCreateNew(){
                this.createNew = true;
                this.$refs.submit.click();
            },
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    this.$bvToast.toast(this.$t('Please, check the form'), {
                        title: "Error",
                        variant: 'danger',
                        solid: true
                    });
                    return;
                }

                this.loading = true;
                let form = {...this.form};

                if(form.truck){
                    form.truck_id = form.truck.id;
                }
                 if(form.type){
                    form.type_id = form.type.id;
                }
                if(form.length){
                    form.length_id = form.length.id;
                }

                if(form.carrier){
                    form.carrier_id = form.carrier.id
                }

                delete form.truck;
                delete form.type;
                delete form.length;

                if(this.$route.params.trailer_id){
                    this.updateTrailer(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            this.redirect();
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }else{
                    this.storeTrailer(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Created successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            if(this.createNew){
                                this.resetForm();
                            }else{
                                this.redirect();
                            }
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }
            },
            redirect(){
                if(this.from.name){
                    this.$router.back();
                }else{
                    this.$router.push({name:'trailers.index'});
                }                
            }
        }
    }
</script>

<style>

</style>
