<template>
    <div class="" :class="border?'p-3':''">
      <div class="" v-if="loading">
        <loading/>
      </div>
      <div class="" v-else>
        <div class="" v-if="events.length">
          <div  :class="border?'card':''" class="" v-for="(item,index) in events" :key="'vecl'+index" >
            <div class="card-body p-3">
              <div class="border-left-primary">
                <h5 class="font-size-13 mb-1">
                  {{item.created_at | timestamp}}
                  <small class="text-muted">{{item.name}}</small>
                </h5>
                <h4 class="font-size-14 text-primary mb-0">{{$t(item.description) || $t(item.status)}}
                  <span v-if="item.position" style="color: #74788d;font-size: .8rem;">{{item.position.temperature}}°{{item.position.temperature_unit?item.position.temperature_unit:''}}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <empty-results />
        </div>
      </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";

export default {
  name: "RouteLog",
  props:{
    border:{
      type: [Boolean],
      default(){
        return true
      }
    },
    load_id:{
      type: [Number,String],
    },
  },
  data(){
    return {
      loading: false
    }
  },
  computed:{
    ...mapState('loads', ['events']),
  },
  created() {
    this.getData();
  },
  methods:{
    ...mapActions('loads',['getEvents']),
    getData(){
      this.loading = true;
      this.getEvents({ id: this.load_id}).finally(()=>{
        this.loading = false;
      });
    }
  },
  watch:{
    load_id(){
      this.getData();
    }
  },
}
</script>

<style scoped>
.border-left-primary{
  border-left: 3px solid #00B3E6;
  padding-left: 15px;
}
</style>
