<template>
  <div class="main">
    <div class="success_register">
      <div class="success_register_title">
        <i class="fa fa-check"></i>
        Registro exitoso
      </div>
      <div class="success_register_sub_title">
        Gracias por unirte a nuestro gran equipo.
      </div>
    </div>

    <p class="register_help">Para continuar debes elegir uno de nuestros perfiles:</p>

    <div class="show_roles">
      <div class="rol " @click="selectRole('shipper')" >
        <div class="rol_content">
          <div class="rol_icon">
            <i class="bx far fa-dolly-flatbed-alt"></i>
          </div>
          <div class="rol_title">Soy Embarcador</div>
        </div>
      </div>
      <div class="rol " @click="selectRole('carrier')" >
        <div class="rol_content">
          <div class="rol_icon">
            <i class="bx far fa-truck-moving"></i>
          </div>
          <div class="rol_title">Soy Transportista</div>
        </div>
      </div>

    </div>

    <div class="info_roles">
      <div class="info_role" @click="infoRole('shipper')">
        <div class="info_role_icon">
          <i class="fa fa-info"></i>
        </div>
        <div class="info_role_text">
          Traxporta para <span>Embarcadores</span>
        </div>
      </div>
      <div class="info_role" @click="infoRole('carrier')">
        <div class="info_role_icon">
          <i class="fa fa-info"></i>
        </div>
        <div class="info_role_text">
          Traxporta para <span>Transportistas</span>
        </div>
      </div>
    </div>

    <div class="overlay" v-if="showShipper" >
      <div class="overlay_header">
        <img src="@/assets/images/txp/txp_gray.png" class="overlay_header-img"  alt="">
        <div class="overlay_header-close cursor-pointer " @click="showShipper=false">
          Close <i class="far fa-times fa-lg"/>
        </div>
      </div>
      <div @click="next" class="control next"><i class="fa fa-chevron-right"></i></div>
      <div @click="prev" class="control back"><i class="fa fa-chevron-left"></i></div>
      <div class="overlay_body">
        <div class="overlay_body_section_one">
          <div class="overlay_body_section_one_content">
            <div class="overlay_body_section_one_content-sub_title">
              Acerca de Traxporta / <span>Para Embarcadores</span>
            </div>
            <div class="overlay_body_section_one_content-title">
              Organiza, empaca y envia en una sola <span>app</span>.
            </div>
            <div class="overlay_body_section_one_content-tag_line">¡Publicar tus cargas es gratis!</div>
          </div>
        </div>
        <div class="overlay_body_section_two">
          <div class="overlay_body_section_two_content">
            <b-carousel id="carousel-fade" :interval="0"
                        style="text-shadow: 0px 0px 2px #000; margin-left: -40px"
                        v-model="currentIndex"
            >
              <b-carousel-slide >
                <template #img >
                  <img src="@/assets/images/onboarding/shipper/img_1.png" class="img_gallery"  alt="">
                </template>
              </b-carousel-slide>
              <b-carousel-slide >
                <template #img >
                  <img src="@/assets/images/onboarding/shipper/img_2.png" class="img_gallery"  alt="">
                </template>
              </b-carousel-slide>
              <b-carousel-slide >
                <template #img >
                  <img src="@/assets/images/onboarding/shipper/img_3.png" class="img_gallery"  alt="">
                </template>
              </b-carousel-slide>
              <b-carousel-slide >
                <template #img >
                  <img src="@/assets/images/onboarding/shipper/img_4.png" class="img_gallery"  alt="">
                </template>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>

    <div class="overlay" v-if="showCarrier" >
      <div class="overlay_header">
        <img src="@/assets/images/txp/txp_gray.png" class="overlay_header-img"  alt="">
        <div class="overlay_header-close cursor-pointer " @click="showCarrier=false">
          Close <i class="far fa-times fa-lg"/>
        </div>
      </div>
      <div @click="next" class="control next"><i class="fa fa-chevron-right"></i></div>
      <div @click="prev" class="control back"><i class="fa fa-chevron-left"></i></div>
      <div class="overlay_body">
        <div class="overlay_body_section_one">
          <div class="overlay_body_section_one_content">
            <div class="overlay_body_section_one_content-sub_title">
              Acerca de Traxporta / <span>Para Transportistas</span>
            </div>
            <div class="overlay_body_section_one_content-title">
              Embarca, traxporta y organiza, en una sola <span>app</span>.
            </div>
            <div class="overlay_body_section_one_content-tag_line">Conectamos transportistas y embarcadores para lograr una movilidad segura y efectiva.</div>
          </div>
        </div>
        <div class="overlay_body_section_two">
          <div class="overlay_body_section_two_content">
            <b-carousel id="carousel-fade" :interval="0"
                        style="text-shadow: 0px 0px 2px #000; margin-left: -40px"
                        v-model="currentIndex"
            >
              <b-carousel-slide >
                <template #img >
                  <img src="@/assets/images/onboarding/carrier/img_1.png" class="img_gallery"  alt="">
                </template>
              </b-carousel-slide>
              <b-carousel-slide >
                <template #img >
                  <img src="@/assets/images/onboarding/carrier/img_2.png" class="img_gallery"  alt="">
                </template>
              </b-carousel-slide>
              <b-carousel-slide >
                <template #img >
                  <img src="@/assets/images/onboarding/carrier/img_3.png" class="img_gallery"  alt="">
                </template>
              </b-carousel-slide>
              <b-carousel-slide >
                <template #img >
                  <img src="@/assets/images/onboarding/carrier/img_4.png" class="img_gallery"  alt="">
                </template>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapActions} from "vuex";
export default {
  name: "OverView",
  components:{
    Multiselect
  },
  data(){
    return {
      currentIndex:0,
      showCarrier:false,
      showShipper:false,
    }
  },
  computed:{

  },
  methods:{
    selectRole(role){
      console.log(role)
      this.$emit('next',role)
    },
    infoRole(role){
      if(role==='carrier'){
        this.showCarrier=true;
      }
      if(role==='shipper'){
        this.showShipper=true;
      }
    },
    next(){
      if (this.currentIndex < 4){
        this.currentIndex++
      }else {
        this.currentIndex=0
      }
    },
    prev(){
      if (this.currentIndex > 0 ){
        this.currentIndex--
      }else {
        this.currentIndex=3
      }
    },
  }
}
</script>

<style scoped>
</style>