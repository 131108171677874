<template>
    <div class="font-family-helvetica">
        <div class="rectangle d-flex justify-content-center align-items-start">
            <div class="mt-3 px-md-5">
                <div class="mb-3">
                    <i class="ri-map-pin-fill font-size-20 ml-n1" />
                    <span class="font-size-18 ml-3 font-weight-bold text-capitalize">{{$t('route')}}</span>
                </div>
                <ul>
                    <li :style="{'--my-color-var': bullets[index]}" v-for="(stop, index) in stops">
                        <span class="ml-3 font-size-12">{{stop}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="oval rounded-circle d-flex justify-content-center align-items-center">
            <div class="text-center">
                <img src="@/assets/images/companies/img-2.png" alt="traxporta" width="70">
                <div class="font-size-11 text-muted mt-2">
                    {{$t('Shipment')}}  <span class="text-lowercase">{{$t('Created by')}}</span>:
                </div>
                <div class="font-size-13 font-weight-bold text-break" v-if="customer">{{customer.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RouteCard",
    props: {
        stops: Array,
        customer: Object
    },
    data() {
        return {
            bullets: ['#00B3E6', '#1163EA', '#1CBB8C'],
        }
    },
}
</script>

<style lang="scss" scoped>

ul{
    list-style: none;
    margin:0;
    padding:0;
    position: relative;

    &:before{
        content:"";
        display: inline-block;
        width: 2px;
        position: absolute;
        left:3px;
        top:5px;
        height: calc(100% - 10px );
        border: dashed 1px #DDDDDD;
    }

    li{
        position: relative;
        padding-left: 15px;
        margin-bottom:15px;
        display: flex;
        &:before{
            content:"";
            display: inline-block;
            width: 8px;
            height: 8px;
            /*background: green;*/
            background-color: var(--my-color-var);
            position: absolute;
            left:0;
            top:5px;
            border-radius: 10px;
        }
    }
}

.rectangle {
    box-sizing: border-box;
    height: 300px;
    width: 220px;
    border: 1px solid #DDDDDD;
    border-radius: 0 20px 0 0;
    background-color: #FFFFFF;
}

.oval {
    margin-top: -5.3rem;
    box-sizing: border-box;
    height: 220px;
    width: 220px;
    border: 11px solid #F2F2F2;
    background-color: #FFFFFF;
}


</style>
