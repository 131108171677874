import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import { getUser } from '../utils/auth.js'
import { AmplifyEventBus } from 'aws-amplify-vue'

import routes from './routes'
Vue.use(VueRouter)
Vue.use(VueMeta);

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})


AmplifyEventBus.$on('authState', async (state) => {
  const pushPathes = {
    signedOut: () => {
      router.push({ name: 'login.index' })
    },
    signUp: () => {
      router.push({ name: 'register' })
    },
    confirmSignUp: () => {
      router.push({ name: 'confirm_register' })
    },
    resetPassword: () => {
      router.push({ name: 'reset_password' })
    }
  }
  if (typeof pushPathes[state] === 'function') {
    pushPathes[state]()
  }
})



router.beforeEach((to, from, next) => {
  if (to.meta.bgWhite === true) {
    document.body.classList.add('bgWhite');
  } else {
    document.body.classList.remove('bgWhite');
  }
  next();
})


router.beforeResolve(async (to, from, next) => {
  getUser().then((user) => {
    if (to.params.profile === 'carrier') {
      return next({
        name: 'new-dashboard',
      })
    }
    next()
  }).catch(err => {
    if (to.matched.some((record) => record.meta.freeAuth)) {
      return next()
    } else {
      return next({
        name: 'login.index',
      })
    }
  })
})


export default router
