<template>
  <b-modal
      id="modal-action-expense"
      ref="modal-action-expense"
      size="md"
      hide-footer
      :title="title"
      :visible="visible"
      v-on:hidden="closeAction"
  >
    <div class="">
      <div class=" field-wrapper border-0 text-center">
        <img v-if="success" src="@/assets/images/txp/triangle.png"   style="width: 80px;height: 80px;margin:10px;" />
        <img v-else src="@/assets/images/txp/common/alert_warning.svg"   style="width: 80px;height: 80px;margin:10px;" alt="">
        <h3 class="card-title text-center">{{message}}</h3>
        <p class="sub_message text-center text-muted">
          {{sub_message}}
        </p>
      </div>
      <div class="d-flex justify-content-end mt-1 mb-2  field-wrapper border-0 ">
        <button @click="closeAction()" class="btn btn-primary_v2 btn-block">{{$t('Close')}}</button>
      </div>

    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AlertModal",
  props: {
    visible: {
      type: Boolean,
      default(){
        return false;
      }
    },
    reload_on_close: {
      type: Boolean,
      default(){
        return false;
      }
    },
    success: {
      type: Boolean,
      default(){
        return false;
      }
    },
    message: {
      type: String,
      default(){
        return "";
      }
    },
    title: {
      type: String,
      default(){
        return "";
      }
    },
    sub_message: {
      type: String,
      default(){
        return "false";
      }
    },
  },
  methods:{
    closeAction(){
      this.$emit('close',{reload:this.reload_on_close});
    }
  }
}
</script>

<style scoped>

</style>
