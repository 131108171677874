<template>
  <div>
    <div class="card card-txp">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <div v-if="!profile">
                <b-skeleton animation="wave" width="100%" />
                <b-skeleton animation="wave" width="80%" />
            </div>
            <div v-else>
                <h5 class="font-weight-bold m-0">
                  {{ $t('Documents') }} <span v-if="profile.profile.document_status !== 'accepted'" class="dot"></span>
                </h5>
                <div class="opacity fz-14"> {{ $t(profile.profile.document_status) }}</div>
            </div>
          </div>
          <div class="col-6 c-right">
            <b-skeleton type="avatar" v-if="!profile" />
            <router-link :to="{name:'settings.documents'}" v-else>
                <i style="color: black;" class="fa fa-folder-open fa-2x"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "DocumentHome",
  computed: {
    ...mapState("profile", ["profile"]),
  },
};

</script>

<style scoped>
    .opacity { opacity: 0.60; }
    .fz-14{ font-size: 13px; }
    .card-txp {
        border-radius: 15px;
        box-shadow: 1px 5px 3px #dcdcdc;
    }
    .c-right{
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .badge-white {
        color: #00B3E6;
        background-color: #ffffff;
        border: solid 1px #dcdcdc;
    }
    .dot {
        height: 5px;
        width: 5px;
        background-color: red;
        border-radius: 50%;
        display: inline-block;
        margin: 0px 5px 3px 7px;
    }
</style>
