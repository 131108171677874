<template>
  <div class="txp_sidebar_content">
    <div>
      <template v-for="item in menuItems">

        <!--end Layouts menu -->
        <div v-if="!item.isTitle && !item.isLayout" class="txp_menu_item" :key="item.id" :class="{'item-active': $route.matched.some(({ name }) => (item.route ? name === item.route.name: (item.match ? item.match.includes(name) : false)))}">
          <a v-if="hasItems(item)" v-can="item.permission" href="javascript:void(0);" class="menu_item collapsed  is-parent"
             :class="{'has-arrow': !item.badge,
                  'has-dropdown': item.badge,
                  'show': item.id === item_active,
                  'menu-active': $route.matched.some(({ name }) => (item.route ? name === item.route.name: (item.match ? item.match.includes(name) : false)))
                }"
             @click="item_active=item.id"
          >
          <span class="menu_item_icon">
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
          </span>
            <div class="menu_item_label">
              <span>{{ $t(item.label) }}</span>
            </div>
          </a>

          <router-link
              v-can="item.permission"
              :to="item.route"
              v-if="!hasItems(item)"
              class=" menu_item"
              active-class="active menu-active"
              @click.native="InlineButtonClickHandler"

          >
            <div class="menu_item_icon">
              <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            </div>
            <div class="menu_item_label">
              <span>{{ $t(item.label) }}</span>
            </div>
          </router-link>

          <div v-if="hasItems(item)" class="sub_menu" :class="{'mm-show': $route.matched.some(({ name }) => item.match.includes(name))}" aria-expanded="false">
            <template v-for="(subitem, index) of item.subItems" >
              <router-link
                  :key="index"
                  v-can="item.permission"
                  :to="subitem.route"
                  v-if="!hasItems(subitem)"
                  class="sub_menu_item" active-class="active submenu_active">
                <span style="padding-left:10px;">{{ $t(subitem.label) }}</span>
              </router-link>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { menuItems } from "./menu";
import {mapState} from "vuex";
export default {
  name: "SideBarResponsive",
  data(){
    return {
      item_active:null
    }
  },
  computed:{
    ...mapState('profile',['profile']),
    menuItems(){
      return menuItems[this.$route.params.profile]
    }
  },
  methods:{
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    onRoutechange(item) {
      this.$emit('toggleMenu',false)
    },
    InlineButtonClickHandler(item) {
      this.item_active = null
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: false,
      deep: true,
    }
  }
}
</script>

<style scoped lang="scss">
$bg-color: #5c5c5c;
$active-bg-color: #4a4a4a;
$sub-menu-color: #e3ed56;
$color-accent: #e3ed56;
$color-text: #ffffff;
.txp_sidebar_content{
  padding-top: 25px;
  .txp_menu_item{
    list-style: none;
    position: relative;
    a{
      color: #77787b;
      i{
        font-size: 20px;
        color: #77787b;
        line-height: 1.5rem;
        opacity: .75;
      }
    }
  }
  .txp_menu_item.item-active{
    .menu_item_icon{
      i{
        font-weight: bold;
        color: $color-accent!important;
      }
    }
    .menu_item_label{
      background-color:  $active-bg-color !important;
    }
    background-color: $bg-color!important;
  }
  .menu_item{
    position: relative;
    display: block;
    .menu_item_icon{
      text-align: center;
      width: 80px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      *{
        color: $color-text;
      }
    }
    .menu_item_label{
      background:$bg-color;
      width: 220px;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      top: 0;
      left: 80px;
      color: $color-text;
      span{
        font-size: 15px;
      }
    }
  }
  .menu-active::before{
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    bottom: 0;
    width: 5px;
    background-color: $color-accent;
    transition: background-color 0.15s linear 0s;
    height: 50px;
    transform: translateY(-50%);
  }
  .txp_sub_item{
    position: relative;
  }
  .menu_item.menu-active{
    color: $color-accent!important;
    display: block;
    background-color: $bg-color!important;
    .menu_item_label {
      span{
        color: $color-accent!important;
      }
    }
    .menu_item_icon {
      background: $active-bg-color!important;
      i{
        color: $color-accent!important;
      }
    }
  }
  .sub_menu_item.active.submenu_active{
    color: $sub-menu-color!important;
    font-weight: bold;
    position: relative;
    &::before{
      display: block;
      content: "";
      position: absolute;
      left: -10px;
      top: 15px;
      width: 5px;
      background-color: #bdd152;
      transition: background-color 0.15s linear 0s;
      height: 100%;
      transform: translateY(-50%);
    }
  }
  .sub_menu{
    padding-left: 70px;
    width: 300px;
    background: white;
    height: 0;
    overflow: hidden;
    .sub_menu_item{
      line-height:2;
      font-size: 15px;
      display: block;
    }
  }
  .menu_item.collapsed.has-arrow:after{
    transform: rotate(-180deg);
  }
  .has-arrow:after {
    content: "󰅀";
    font-family: Material Design Icons;
    display: block;
    transition: transform .2s;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 15px;
  }
  .txp_menu_item.item-active .sub_menu{
    height: auto!important;
    overflow: auto!important;
  }
  .menu_item:hover + .sub_menu{
    height: auto!important;
    overflow: auto!important;
  }
  .menu_item.show + .sub_menu{
    height: auto!important;
    overflow: auto!important;
  }
  .menu_item.show > * {
    font-weight: bold;
  }
  .menu_item.show {
    font-weight: bold;
    color: $color-accent;
  }
  .sub_menu.show{
    height: auto!important;
    overflow: auto!important;
  }
}


@media (min-width: 992px) {


  .sub_menu_item.active.submenu_active{
    &::before{
      top: 18px!important;
    }
  }
  .txp_sidebar_content{
    .menu_item{
      .menu_item_label{
        left: -500px;
        width: 200px;
      }
    }
    .txp_menu_item:hover{
      .menu_item_icon{
        display: flex;
        background: $active-bg-color!important;
        *{
          color: $color-accent!important;
        }
      }
      .menu_item_label{
        box-shadow: 13px 0px 12px 0px #dddddd;
        left: 70px;
        background: $active-bg-color!important;
        *{
          color: $color-accent!important;
        }
      }
      .sub_menu{
        left: 80px;
      }
    }
    .sub_menu{
      width: 190px;
      background: white;
      left: -500px;
      position: absolute;
      padding-left: 10px;
      box-shadow: 13px 0px 12px 0px #dddddd;
      height: auto;
      overflow: auto;
      .sub_menu_item{
        line-height: 2.5;
      }
    }
    .has-arrow:after{
      display: none;
    }
  }
}
</style>