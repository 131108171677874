<template>
  <div class="col-6" v-if="!requirement.hide">
    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between mb-1">
          <div class="d-inline-flex">
            <span class="font-weight-bold h5">{{ requirement.name }}</span>
            <span
              class="badge ml-2"
              :class="['badge-outline-danger']"
              v-if="requirement.required"
              style="height: fit-content;"
            >
              <small>{{ $t("Required") }}</small>
            </span>
          </div>
          <div class="d-inline-flex ml-2">
            <span
              class="badge"
              style="height: fit-content;"
              :class="
                requirement.document
                  ? requirement.document.status === 'accepted'
                    ? 'badge-outline-success'
                    : requirement.document.status === 'reject'
                    ? 'badge-outline-danger'
                    : 'badge-outline-primary'
                  : 'badge-outline-primary'
              "
            >
              <small>{{
                requirement.document
                  ? $t(requirement.document.status)
                  : $t("pending")
              }}
              </small>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="mb-0"><small>{{ requirement.description }}</small></p>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="d-flex justify-content-between pb-4">
          <div class="d-inline-flex">
            <a
              :href="requirement.template"
              class="btn btn-primary btn-sm mr-2"
              style="line-height: 30px;"
              v-if="requirement.template"
              target="_blank"
            >
              <i class="far fa-download mr-2"></i>
              {{ $t("Download template") }}
            </a>
          </div>
          <div class="d-inline-flex">
            <b-dropdown
              variant="light"
              ref="dropdown"
              right
              class="d-inline-block"
              toggle-class="filter-button"
              v-if="requirement.document"
            >
              <template #button-content>
                <table>
                  <tr>
                    <td valign="top" style="width: 24px">
                      <small
                        ><i class="pr-1 text-muted far fa-calendar-alt"></i
                      ></small>
                    </td>
                    <td valign="top" class="text-left">
                      <small class="text-muted"
                        >{{ $t("Expiration date") }}:</small
                      >
                      <div class="filter-button-value">
                        {{
                          requirement.document.expiration
                            ? $moment(requirement.document.expiration).format(
                                "YYYY-MM-DD"
                              )
                            : "--"
                        }}
                      </div>
                    </td>
                  </tr>
                </table>
              </template>

              <b-dropdown-form>
                <div class="v-date-filter">
                  <date-picker
                    @change="updateDataDocument(requirement)"
                    v-model="requirement.document.expiration"
                    :inline="true"
                    type="date"
                  ></date-picker>
                </div>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>

        <div class="pb-4">
          <file-input
            :path="`/api/v2/${resource}/${resource_id}/documents`"
            :payload="{ requirement_id: requirement.id }"
            :options="{ dictDefaultMessage: 'Subir Documento' }"
            v-on:success="(data) => {
              uploadFile(requirement)
            }"
          />
        </div>
        <div
          class="table-responsive"
          style="height: 150px; overflow-y: scroll"
          v-if="requirement.document && requirement.document.files.length > 0"
        >
          <table class="table table-hover">
            <tr
              v-for="document in requirement.document.files"
              :key="'file_' + document.id"
            >
              <td>
                <a
                  v-if="document.path.substr(-3, 3) !== 'pdf'"
                  :href="document.path"
                  target="_blank"
                >
                  <img :src="document.path" width="50px" height="50px" />
                </a>
                <a v-else :href="document.path" target="_blank">
                  <i class="fas fa-file-pdf fa-3x" aria-hidden="true"></i>
                </a>
              </td>
              <td>
                <div
                  class="one-line"
                  v-b-tooltip.hover
                  :title="document.filename"
                >
                  {{ document.filename }}
                </div>
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  @click="removeImage(requirement, document)"
                  v-b-tooltip:hover
                  :title="$t('Remove')"
                >
                  <i
                    class="far fa-trash-alt text-danger"
                    aria-hidden="true"
                  ></i>
                </a>
              </td>
            </tr>
          </table>
        </div>
        <div v-else>
          <empty-results
            :subtitle="$t('This requirement does not have a files')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import FileInput from "@/components/form/FileInput";
import Swal from "sweetalert2";

export default {
  name: "RequirementCard",
  components: {
    DatePicker,
    FileInput,
  },
  props: {
    requirement: {
      type: Object,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    resource_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      
    };
  },
  methods: {
    ...mapActions("requirements", [
      "updateDocument",
      "deleteDocument",
      "sendReview",
    ]),
    fetchRequirements() {
      this.$emit("fetch");
    },
    updateDataDocument(requirement) {
      let payload = {
        resource: this.resource,
        resource_id: this.resource_id,
        document_id: requirement.document.id,
        expiration: requirement.document.expiration,
      };
      this.updateDocument(payload)
        .then((data) => {
          this.$root.$bvToast.toast(
            this.$t("The document has been updated sucessfully.", {
              resource: this.$t("Documents").toLowerCase(),
            }),
            {
              title: this.$t("Success"),
              variant: "success",
              solid: true,
            }
          );
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t("Something went wrong!"), {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {});
    },
    removeImage(requirement, document) {
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#D4DD55",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("Accept"),
        cancelButtonText: this.$t("Cancel"),
      }).then((result) => {
        if (result.value) {
          let payload = {
            resource: this.resource,
            resource_id: this.resource_id,
            document_id: requirement.document.id,
            file_id: document.id,
          };
          this.deleteDocument(payload)
            .then((data) => {
              this.$root.$bvToast.toast(
                this.$t("The document has been updated sucessfully.", {
                  resource: this.$t("Documents").toLowerCase(),
                }),
                {
                  title: this.$t("Success"),
                  variant: "success",
                  solid: true,
                }
              );
              this.fetchRequirements();
            })
            .catch((error) => {
              this.$bvToast.toast(this.$t("Something went wrong!"), {
                title: "Error",
                variant: "danger",
                solid: true,
              });
            })
            .finally(() => {});
        }
      });
    },
    uploadFile(requirement) {
      this.fetchRequirements();
      if (!requirement.document && requirement.expiration)
        setTimeout(() => this.$refs.dropdown.visible = true, 600); 
    }
  },
};
</script>

<style scoped>
.b-dropdown-form .mx-datepicker-main {
  background-color: #fff;
  border: none !important;
}
