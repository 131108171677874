import { render, staticRenderFns } from "./OperationalDeductions.vue?vue&type=template&id=a821d6a4&scoped=true&"
import script from "./OperationalDeductions.vue?vue&type=script&lang=js&"
export * from "./OperationalDeductions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a821d6a4",
  null
  
)

export default component.exports