<template>
  <div class="">
    <div class="time-picker-modal-header mt-1">
      <span class="title">Hora seleccionada:</span>
      <div class="picker_label mt-3">
        <div class="time_container">
          <input type="number" class="time_input" min="0" max="2"  v-model="model.hour" >
          <span class="time_span">{{$t('Hours')}}</span>
        </div>
        <div class="">
          <div class="time_separe">:</div>
        </div>
        <div class="time_container">
          <input type="number" class="time_input" min="0" max="2"  v-model="model.minute" >
          <span class="time_span">{{$t('Minutes')}}</span>
        </div>
        <div class="">
          <span class="text-white font-size-22 cursor-pointer  font_bold time_timezone " @click="updateTimeZone" > {{ timeZone }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeSelect",
  data(){
    return {
      model: {
        hour: 12,
        minute: 0,
      },
      step : 0,
      isDefault: true,
      timeZone: 'am'
    }
  },
  props:{
    value:{
      type: [Array, Object],
      default(){
        return {hour:12,minute:0};
      }
    },
  },
  created() {
    this.model = _.cloneDeep(this.value)
    this.timeZone = this.model.hour > 12 ? 'pm' : 'am'
    let aux = _.cloneDeep(this.model.hour)
    this.model.hour = aux % 12 || 12
    this.$emit('input', this.emitDate());
  },
  computed:{

  },
  methods:{
    updateTimeZone(){
        this.timeZone = this.timeZone === 'am' ? 'pm' : 'am'
        this.$emit('input', this.emitDate());
    },
    emitDate() {
      let model = _.cloneDeep(this.model)
      if (this.timeZone === 'pm'){
       model.hour = parseInt(model.hour) + 12;
      }
      return model
    }
  },
  watch:{
    'model.hour':function (e){
      if (!/(^0?[1-9]$)|(^1[0-2]$)/.test(e)){
        this.model.hour = this.model.hour ?  this.model.hour[0] : null;
      }
      if (this.model.hour) {
        this.$emit('input', this.emitDate());
      }
    },
    'model.minute':function (e){
      if (!/(^[0-5][0-9]$)/.test(e)){
        this.model.minute = this.model.minute ?  this.model.minute[0] : null;
      }
      if (this.model.minute) {
        this.$emit('input', this.emitDate());
      }
    }
  }
}
</script>

<style scoped lang="scss">

.time-picker-modal-header {
  background: #00B3E6;
  line-height: 1;
  text-align: center;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
}
.time-picker-modal-header > .title{
  font-size: 12px;
  color: white;
  font-weight: bold;
}
.time-picker-modal-header > .label{
  font-size: 40px;
  margin-top: 8px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif!important;
  font-weight: 600!important;
}
.time-picker-modal-header > .label > span{
  font-weight: bold;
}
.time-picker-modal-header .time-picker-header {
  cursor: pointer;
  color: #FFFFFF;
  opacity: .8;
}
.time-picker-modal-header .time-picker-header.active {
  color: #FFFFFF;
  cursor: default;
  opacity: 1;
}
.time-picker-modal-header .time-picker-header:hover {
  color: #FFFFFF;
  cursor: pointer;
}
.label_hours{
  margin-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  .label_hours_item{
    color: white;
    font-weight: bold;
    font-size: 23px;
    width: 20%;
    text-align: center;
    padding: 3px;
    opacity: 0.8;
    &:hover{
      cursor: pointer;
      opacity: 1;
    }
    
  }
}
.label_minute{
  margin-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  &_item{
    color: white;
    font-weight: bold;
    font-size: 23px;
    width: 20%;
    text-align: center;
    padding: 3px;
    opacity: 0.8;
    &:hover{
      cursor: pointer;
      opacity: 1;
    }

  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.time_input{
  width: 50px;
  height: 45px;
  font-size: 30px;
  color: #02b3e6;
  border: none;
  text-align: center;
  font-weight: bold;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  &:focus-visible{
    outline: none;
  }
}

.time_span{
  color: white;
  margin-top: 5px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
}
.time_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.time_separe{
  color: white;
  font-size: 50px;
  overflow: hidden;
  margin: 0 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  height: 75px;
}

.picker_label{
  display: flex;
  align-items: center;
  justify-content: center;
}
.time_timezone{
  margin-left: 10px;
}

</style>