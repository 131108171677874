<template>
  <div class="">
    <div class="card main_comment">
      <div class="card-body">
        <div class="comment_header">
          <div class="comment_header_avatar">
            <img
                v-if="comment.user.avatar"
                class="rounded-circle"
                :src="comment.user.avatar"
                alt="Avatar"
                width="30" height="30" style="object-fit: cover;padding:0!important"
            />
            <vue-letter-avatar v-else  :name="comment.user.name + ' '+comment.user.last_name" size='30' :rounded=true />
          </div>
          <div class="comment_header_info">
            <div class="">Publicado por: <span class="font-weight-bold ">{{ comment.user.name + ' '+comment.user.last_name }}</span></div>
            <div class="text-muted ">{{ date }}</div>
          </div>
          <div class="comment_header_actions"  v-if="profile.id == comment.user.id || isAdmin">
            <b-dropdown size="lg" right  variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fa fa-ellipsis-v text-black-50"></i>
              </template>
              <b-dropdown-item
                  @click="editComment=true"
              >
                <i class="far fa-edit mr-2" /> {{$t("Edit")}}
              </b-dropdown-item>
              <b-dropdown-item
                  @click="deleteComment"
              >
                <i class="far fa-trash-alt mr-2" /> {{$t("Delete")}}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="comment_body mt-3 pr-1">
          {{comment.body}}
        </div>
      </div>
    </div>
    <edit-comment :visible="editComment" :update="true"   :comment="comment"  :resource_id="resource_id"    v-on:closeModal="closeModal"   ></edit-comment>
  </div>
</template>

<script>
import moment from 'moment'
import Swal from "sweetalert2";
import EditComment from "./modals/EditComment";
import {mapActions, mapState} from "vuex";
export default {
  name: "CommentComponent",
  components:{
    EditComment
  },
  props: {
    comment: {
      type:Object
    },
    resource_id: {

    }
  },
  data(){
    return {
      editComment: false
    };
  },
  computed:{
    ...mapState('profile', ['profile']),
    date(){
      return moment(this.comment.updated_at).format("[El] D MMMM YYYY [a las] HH:mm");
    },
    isAdmin(){
      return this.profile.roles.includes("admin");
    }
  },
  methods:{
    closeModal(data){
      this.editComment = false;
      if (data.updated){
        this.$emit('updateComments', data);
      }

    },
    deleteComment(){
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.$emit('delete', {load_id: this.resource_id,issue_id: this.comment.owner_id,id:this.comment.id});
        }
      });

    }
  }
}
</script>

<style scoped>
  .main_comment{
      background: #F2F2F2;
      box-shadow: none;
      border: 0;
      width: calc(100% - 15px);
  }
  .comment_header{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .comment_header_info{
    width: calc(100% - 100px);
  }
  .comment_header_avatar{
    width: 50px;
  }
  .comment_header_actions{
    width: 50px;
    display: flex;
    justify-content: flex-end;
  }
</style>