<template>
  <div id="app">
    <div  v-if="false" class="loading" id="loading"></div>
    <div class="" v-else  >
      <router-view ></router-view>
    </div>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import {getUser} from '@/utils/auth.js'
import {mapActions, mapMutations, mapState} from 'vuex';
import Echo from "laravel-echo";
import {getAccessToken} from "./utils/auth";
import axios from 'axios'
import {useEventsTracker} from "@/composables/analytics";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  created() {
    getUser().then(async (user) => {
      if (user) {
        window.Pusher = require('pusher-js');
        window.Echo = new Echo({
          broadcaster: 'pusher',
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          forceTLS: true,
          authorizer: ({ name }) => ({
            authorize: async (socketId, callback) => {
              const config = {
                headers: {
                  Authorization: `Bearer ${await getAccessToken()}`
                }
              };
              axios.post(process.env.VUE_APP_API_URL+'/broadcasting/auth', {
                socket_id: socketId,
                channel_name: name
              },config).then(response => {
                callback(null, response.data);
              }).catch(error => {
                    callback(error);
                  });
            },
          }),
        });
        this.getProfileActions().then((d) => {
          d.status ? this.InitApp() : this.CompleteProfile();
        }).catch((d) => {
          this.CompleteProfile();
        })
      }
    }).catch(err=>{
      this.loading = false
    })
  },
  methods: {
    ...mapActions('profile',['getProfile','getProfileActions']),
    ...mapActions('catalogs',['getCatalogs']),
    ...mapActions('forms',['getForm']),
    ...mapMutations('forms',['setCustomLoadForm','setCarrierLoadForm','setShipperLoadForm','setAdminLoadForm']),
    ...mapActions('views',['getActiveViews','executeViews']),
    ...mapActions('configuration',['getConfiguration']),
    fetchCatalogs(user){
      this.getCatalogs();
      this.getConfiguration();
      if(['carrier','shipper','admin'].indexOf(user.profile.object) > -1 && user.profile.load_form_id){
        this.getForm({id: user.profile.load_form_id})
            .then(data =>{
              switch (user.profile.object){
                case "carrier":
                  this.setCarrierLoadForm(data);
                  break;
                case "shipper":
                  this.setShipperLoadForm(data);
                  break;
                case "admin":
                  this.setAdminLoadForm(data);
                  break;
              }
            })
            .catch(error=>{
            });
      }
      this.getActiveViews();
    },
    InitApp(){
      this.getProfile().then((data)=>{
        this.fetchCatalogs(data);
        this.loading = false;
        if (!this.$route.meta.optionalAuth){
          try{
            const {trackUserProfile} = useEventsTracker(this);
            const userProfile = {
              userType: data.profile.object,
              email: data.email
            };
            trackUserProfile(userProfile);
            if(this.$route.params.profile !== data.profile.object){
              this.$router.push({name:'profile', params:{profile: data.profile.object}});
            }
          }catch(e){}
        }

      });
    },
    CompleteProfile(){
      if (this.$route.name !== 'on_boarding'){
        this.$router.push({name:"on_boarding"});
      }
      this.loading = false
    },
  }
};
</script>
