<template>
  <div class="tpx_calendar_loading">
    <div class="dates ml-n4" ref="month">
      <div class="item dow" v-for="dow in days">{{ $t(dow) }}</div>
      <div v-for="blank in 30" class="day day_blank">
        <div class="item">
          <div class="mb-2">
            <b-skeleton width="80px"  height="10px" class="mb-1" />
          </div>
          <b-skeleton width="25px"  height="25px" />
          <div class="d-flex mt-2">
            <b-skeleton width="50px" class="mr-2" height="8px" />
            <b-skeleton width="50px"  height="8px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarLoading",
  data(){
    return{
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    }
  }
}
</script>

<style scoped lang="scss">

.tpx_calendar_loading {

  position: relative;

  .dates {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }


  .dow {
    color: #888888 !important;
    font-family: Helvetica;
    font-size: 14px;
  }

  .day {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    height: 90px;
    margin-top: 20px;
    .item {
      text-align: center;
      font-family: Helvetica;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      height: 90px;
      width: 90%;
      border: 1px solid #DDDDDD;
      border-radius: 20px;
      background-color: #FFFFFF;
    }
  }
}
</style>