<template>
  <div class="register" style="">
    <div class="register_title_back register_section cursor-pointer" @click="back()"> <i class="fa fa-chevron-left"/>
      {{ $t('Back') }} </div>
    <div class="register_title register_section">{{$t('Sign Up')}}</div>
    <div class="register_label register_section">
      <div class="register_label_title">{{$t('Enter your cell number')}}</div>
      <div class="register_label_label">{{$t('We will send you a code to confirm it')}}</div>
    </div>
    <div class="register_country register_section">
      <div class="register_country_label">{{$t('Country')}}</div>
      <vue-country-dropdown class="register_country_input"
          @onSelect="onSelect"
          :onlyCountries="['MX', 'US', 'CO']"
          :showNameInput="true"
      />
    </div>
    <div class="register_phone register_section">
      <div class="register_phone_label">{{$t('Phone number')}}</div>
      <div class="register_phone_input" :class="{'is-danger':!phoneValid}" >
        <div class="register_phone_input_code">+{{countryCode}}</div>
        <VuePhoneNumberInput :no-country-selector="true"
                             v-model="phoneNumber"
                             :no-example="true"
                             @update="update"
                             :default-country-code="country"   />
      </div>
      <span v-show="!phoneValid" class="help is-danger">Phone is invalid</span>
    </div>
    <div class="register_section register_sms" >
        <button :class="{'disabled':(!phoneValid)||(loading)}"   class="btn btn-block   register_sms_btn"  @click="code('sms')" > <i class="ri-message-2-fill"></i>   {{$t('RECEIVE CODE BY SMS')}}</button>
    </div>
    <!-- <div class="register_section register_wp">
        <button :class="{'disabled':(!phoneValid)||(loading)}"  class="btn btn-block   register_wp_btn" @click="code('wp')" > <i class="ri-whatsapp-line"></i>  {{$t('RECEIVE CODE BY WHATSAPP')}}</button>
    </div> -->
  </div>
</template>

<script>
import VueCountryDropdown from "vue-country-dropdown";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {mapActions, mapMutations} from "vuex";

export default {
  name: "Register_Phone",
  components:{
    VueCountryDropdown,VuePhoneNumberInput
  },
  data(){
    return {
      country: null,
      countryCode: null,
      phoneNumber: "",
      phoneFormatted: "",
      phoneValid:true,
      loading:false
    }
  },
  methods: {
    ...mapActions('register',['fetchRequestCode']),
    ...mapMutations('register',['setPhoneInfo']),
    onSelect(data) {
      this.country = data.iso2;
      this.countryCode = data.dialCode;
    },
    code(canal){
      let phone = _.cloneDeep(this.phoneFormatted)
      if (phone){
        let data = {
          "phone": phone,
          "type": canal,
        }
        this.loading = true;
        this.fetchRequestCode(data).then((response)=>{
          this.setPhoneInfo(data);
          this.$router.push({name:'register.code'})
        }).catch((err)=>{
          console.log(err)
        }).finally(()=>{
          this.loading = false;
        })
      }else{
        this.phoneValid = false
      }
    },
    update(data){
      let info = _.cloneDeep(data);
      if (info.isValid){
        this.phoneValid = true
        this.phoneFormatted = info.e164
      }else{
        if (info.phoneNumber){
          this.phoneFormatted = ""
          this.phoneValid = false
        }
      }

    },
    back(){
      this.$router.push({name:"login"})
    }
  },
}
</script>

<style>
.input-tel__input,.input-tel{
  width: 100%;
  height: 35px!important;
  min-height: 35px!important;
  border: none!important;
  border-radius: 0!important;
}
.vue-phone-number-input{
  width: 100%;
  height: 95%;
  overflow: hidden;
}
.input-tel__label{
  display: none;
}
.input-tel.has-hint .input-tel__input, .input-tel.has-value .input-tel__input{
  padding-top: 0!important;
}
.help.is-danger{
  color: #ce1b25;
  font-size: 13px;
  padding-top: 7px;
  display: block;
}
.register_phone_input.is-danger{
  border-color: #ce1b25!important;
}

</style>


