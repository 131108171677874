<template>
    <div class="">
        <div class="card">
            <div class="card-header">
                <h6>{{$t('General')}}</h6>
            </div>
            <div class="form-fields">
                <div class="field-wrapper">
                    <div class="row align-items-center ">
                        <div class="col-md-6">
                            <div class="field-label">{{$t('Name')}}</div>
                            <div class="field-help">{{$t('The form name')}}</div>
                        </div>
                        <div class="col-md-6">
                            <validation-provider name="name" rules="required" v-slot="{ errors }">
                                <b-form-input  v-model="form.name" type="text" placeholder=""></b-form-input>
                                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                            </validation-provider>
                        </div>
                    </div>
                </div>
            </div>
          <slot/>
        </div>


      <draggable v-model="form.fields"  @end="handleChange"  >
        <transition-group>
          <div class="card" v-for="(field, idx) in form.fields" :key="'form-field-'+idx">
            <div class="card-header hover_move ">
              <h6>{{$t("Field")}} {{idx+1}}</h6>
            </div>
            <div class="form-fields">
              <form-input
                  :title="$t('Title')"
                  :help="$t('The field title')"
                  v-model="field.title"
                  rules="required"
                  type="text"
                  :name="'field.'+idx+'.title'"
              />
              <form-input
                  :title="$t('Help')"
                  :help="$t('The help text to show')"
                  v-model="field.help"
                  rules="required"
                  type="text"
                  :name="'field.'+idx+'.help'"
              />
              <form-input
                  :title="$t('Type')"
                  :help="$t('The input type')"
                  v-model="field.type"
                  rules="required"
                  type="select"
                  :name="'field.'+idx+'.type'"
                  :options="fieldTypes"
                  v-on:change="()=>onFieldTypeChange(idx)"
              />
              <template v-if="['checkbox','select'].indexOf(field.type) > -1 ">
                <div class="field-wrapper">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="field-label">{{$t('Options')}}</div>
                      <div class="field-help">{{$t('Define the available options')}}</div>
                    </div>
                    <div class="col-md-6">
                      <div class="row mb-2" :key="'field-'+idx+'-op-'+optIdx" v-for="(option, optIdx) in field.options" >
                        <div class="col-md-12">
                          <validation-provider :name="'field.options.'+optIdx+'.value'" rules="required" v-slot="{ errors }">
                            <b-input-group >
                              <b-form-input v-model="option.value" @change="setOptionText(idx, optIdx)" type="text" />
                              <template #append>
                                <button class="btn btn-light" @click="field.options.splice(optIdx, 1)">
                                  <i class="far fa-minus-circle" />
                                </button>
                              </template>
                            </b-input-group>
                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="mt-2">
                        <button @click="field.options.push({text:'',value:''})" class="btn btn-light btn-sm ">{{$t('Add optioin')}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="field-wrapper">
                <b-row class="justify-content-end">
                  <b-col md="6" sm="6" class="text-right">
                    <b-button variant="empty" class="btn-icon far fa-copy" @click="copyField(field)"></b-button>
                    <b-button variant="empty" class="btn-icon far fa-trash-alt" @click="form.fields.splice(idx,1)"></b-button>
                    <div class="d-inline-block pl-3 pr-3" style="border-left: 1px solid #d7d7d7;">
                      <b-form-checkbox
                          v-model="field.required"
                          name="checkbox-1"
                      >
                        {{$t('Required')}}
                      </b-form-checkbox>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>



        <div class="mb-4">
            <button type="button" class="btn btn-primary" @click="addField" v-b-tooltip.hover :title="$t('Add field')">
                <i class="far fa-plus-circle" />
            </button>
        </div>

    </div>
</template>

<script>
    import Switches from 'vue-switches';
    import draggable from 'vuedraggable'
    export default {
        name: "DynamicForm",
        components:{
          Switches, draggable
        },
        props:{
            value:{
                required: true,
            }
        },
        data(){
            return {
                loading: false,
                form:{
                    fields: [
                        {
                            key: '',
                            type: 'text',
                            title: null,
                            help: '',
                            description: null,
                            options: [],
                            visible: true,
                            position: 0,
                            required: false,
                        }
                    ]
                },
                clone: false,
                fieldTypes: [],
                focus : {type: null, id: null, section_id:null},
            }
        },
        watch: {
            value: {
                deep: true,
                handler(newValue){
                    this.form = _.cloneDeep(newValue);
                }
            },
            form:{
                deep:true,
                handler(newValue){
                    if(!_.isEqual(newValue, this.value)){
                        this.$emit('input', newValue);
                    }
                }
            }
        },
        created() {
          this.fieldTypes = [
              {text: this.$t('field_types.Text'), value:'text'},
              {text: this.$t('field_types.Number'), value:'number'},
              {text: this.$t('field_types.Checkbox'), value:'checkbox'},
              {text: this.$t('field_types.Select'), value:'select'},
              {text: this.$t('field_types.POD'), value:'pod'},
              {text: this.$t('field_types.Gallery'), value:'gallery'},
              {text: this.$t('field_types.Signature'), value:'sign'},
          ];
          if(this.value){
              this.form = _.cloneDeep(this.value);
          }
          this.handleChange();
        },
        methods: {
            handleChange(e) {
              for (let i in this.form.fields) {
                this.form.fields[i].position = i;
              }
            },
            addField(){
                let field = {
                    key: '',
                    type: 'text',
                    title: null,
                    help: null,
                    description: null,
                    options: [],
                    visible: true,
                    required: false,
                };
                let form = _.cloneDeep(this.form);
                form.fields.push(field);
                this.form = form;
                this.handleChange();
            },
            copyField(item){
                this.form.fields.push(_.cloneDeep(item));
            },
            onFieldTypeChange(fieldIndex){

              if(['checkbox','select'].indexOf(this.form.fields[fieldIndex].type)){
                  this.form.fields[fieldIndex].options = [];
              }
            },
            setOptionText(fieldIndex, optionIndex){
                this.form.fields[fieldIndex].options[optionIndex].text = this.form.fields[fieldIndex].options[optionIndex].value;
            }
        }
    }
</script>

<style scoped>
    .center-screen {
        position: fixed;
        top: 50%;
        margin-top: -50px;
        width: 55px;
    }
    .hover_move:hover{
      cursor: move;
    }
</style>
