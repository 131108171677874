<template>
    <div>
        <page-header :title="$route.params.product_id ? $t('Edit product'): $t('New product')" :items="breadcrumb"/>

        <div class="card">
          <loading-overlay :loading="loading">
            <template >
              <div class="card-body">
                <wizard-header :currentstep="wizard.currentstep"
                               :stepcount="steps_count"
                               :steps="wizard.steps"
                               @step-change="stepChanged"
                ></wizard-header>
                <validation-observer ref="observer" v-slot="{ validate }">
                  <b-form @submit.stop.prevent="onSubmit(validate)"  ref="form" >
                    <wizard-step v-if="wizard.currentstep === 0 " >
                      <template  v-slot:content>
                        <h5 class="card-title pl-21">Información básica</h5>
                        <form-input
                            :title="$t('Name')"
                            v-model="form.name"
                            rules="required"
                            type="text"
                            layout="vertical"
                            :border="false"
                            :name="'name'"
                        />
                        <div class="row">
                          <div class="col-md-4">
                            <form-input
                                :title="$t('Price')"
                                layout="vertical"
                                v-model="form.price"
                                rules="required"
                                type="number"
                                :name="'price'"
                                :border="false"
                            />
                          </div>
                          <div class="col-md-4">
                            <form-input
                                :title="$t('Cost')"
                                layout="vertical"
                                v-model="form.cost"
                                rules=""
                                type="number"
                                :border="false"
                                :name="'cost'"
                            />
                          </div>
                          <div class="col-md-4">
                            <form-input
                                :title="$t('Currency')"
                                layout="vertical"
                                v-model="form.currency"
                                rules="required"
                                type="select"
                                :options="currency"
                                :name="'currency'"
                                :placeholder="$t('Select option')"
                                :border="false"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <form-input
                                :title="$t('Max Price')"
                                layout="vertical"
                                v-model="form.max_price"
                                rules=""
                                type="number"
                                :border="false"
                                :name="'max_price'"
                            />
                          </div>
                          <div class="col-md-4">
                            <form-input
                                :title="$t('Category')"
                                layout="vertical"
                                selectText='name'
                                selectValue='id'
                                v-model="form.category_id"
                                rules="required"
                                type="select"
                                :options="categories"
                                name="category"
                                :border="false"
                                :placeholder="$t('Select option')"
                            />
                          </div>
                          <div class="col-md-4">
                            <form-input
                                :title="$t('Condition')"
                                layout="vertical"
                                v-model="form.condition"
                                rules="required"
                                type="select"
                                :options="condition"
                                name="condition"
                                :border="false"
                                :placeholder="$t('Select option')"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <form-input
                                :title="$t('Type')"
                                layout="vertical"
                                v-model="form.type"
                                rules="required"
                                type="select"
                                :options="type"
                                name="type"
                                :placeholder="$t('Select option')"
                                :border="false"
                            />
                          </div>
                          <div class="col-md-4">
                            <form-input
                                :title="$t('Brand')"
                                layout="vertical"
                                v-model="form.brand"
                                rules=""
                                type="text"
                                :name="'brand'"
                                :border="false"
                            />
                          </div>
                          <div class="col-md-4">
                            <form-input
                                :title="$t('SKU')"
                                layout="vertical"
                                v-model="form.sku"
                                rules="required"
                                type="text"
                                :border="false"
                                :name="'sku'"
                            />
                          </div>
                        </div>
                        <form-input
                            :title="$t('Description')"
                            v-model="form.description"
                            rules="required"
                            layout="vertical"
                            type="textarea"
                            :border="false"
                            :name="'description'"
                        />
                      </template>
                    </wizard-step>
                    <wizard-step v-if="wizard.currentstep === 1 " >
                      <template  v-slot:content>
                        <h5 class="card-title pl-21">Fotos del producto</h5>
                        <div class="row">
                          <div class="col-md-12 mb-3 d-flex flex-wrap pt-4 pl-4 pr-4 justify-content-start  ">
                            <image-product
                                :key="'ima_por'+image.id"
                                v-for="(image,index) in images"
                                :image="image"
                                v-on:deleteImage="confirmDeleteFile"
                                v-on:showImage="showImage"
                            />
                            <vue-easy-lightbox
                                moveDisabled
                                :visible="visible"
                                :imgs="image"
                                @hide="visible = false"
                            ></vue-easy-lightbox>
                          </div>
                          <div class="col-md-12 mb-5">
                            <file-input
                                class="pt-4 pl-4 pr-4"
                                rules="required"
                                :path="uploadPath"
                                :options="{dictDefaultMessage: 'Titulo test', acceptedFiles: 'image/*'}"
                                v-on:success="fileUpload" v-on:error="errorUpload"
                            />
                            <div class="d-block invalid-feedback pl-4" v-if="showErrorImages">Debe agregar almenos una imagen del producto</div>
                          </div>
                        </div>
                      </template>
                    </wizard-step>
                    <wizard-step v-if="wizard.currentstep === 2">
                      <template slot="content" >
                        <h5 class="card-title  pl-21 mb-3">{{ $t('Add branch office') }}</h5>
                        <div class="row">
                          <div class="col-md-12 pl-4  pb-2  pr-4 pt-0">
                            <div class="card" >
                              <div class="p-0 row">
                                <div class="col-md-5">
                                  <div class="field-wrapper border-0">
                                    <div class="row align-items-center ">
                                      <div class="col-md-12">
                                        <div class="field-label">{{$t('Branch office')}}<span class="field-required-badge"/></div>
                                        <div class="field-help"></div>
                                      </div>
                                      <div class="col-md-12">
                                        <branch-office-input
                                            v-model="formBranchOffice.branchoffice"
                                            :options="branchOfficesOptions"
                                            ref="branch_office_input"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-5">
                                  <form-input
                                      :title="$t('Price')"
                                      layout="vertical"
                                      v-model="formBranchOffice.price"
                                      rules="required"
                                      type="number"
                                      :name="'price'"
                                      :border="false"
                                  />
                                </div>
                                <div class="col-md-2 d-flex justify-content-end align-items-center pr-4">
                                  <div class="btn btn-primary  mr-4" @click="addBranchOffice">{{$t("Save")}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h5 class="card-title  pl-21 mb-3">{{ $t('Branch offices') }}</h5>

                        <div class="row p-4 mb-5 mt-2">
                          <table class="table mb-0">
                            <thead>
                            <tr class="">
                              <th width="15" class="text-center">ID</th>
                              <th width="45" class="text-center">{{$t('Name')}}</th>
                              <th width="25" class="text-center">{{$t('Price')}}</th>
                              <th width="25" class="text-center">{{$t('Actions')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template  v-for="(branch,index) in form.branchoffice">
                              <tr class="truck-row" :key="'brw'+index">
                                <td class="text-center">{{branch.id}}</td>
                                <td class="text-center">{{branch.name}}</td>
                                <td class="text-center">
                                  <form-input
                                      layout="vertical"
                                      v-model="branch.price"
                                      rules=""
                                      type="number"
                                      :name="'brand'"
                                      :border="false"
                                      :padding="false"
                                  />
                                </td>
                                <td class="text-center d-flex justify-content-center"><div @click="deleteBranchOffice(branch)" class="btn btn-sm btn-danger mr-4">{{$t("Delete")}}</div></td>
                              </tr>
                            </template>
                            </tbody>
                          </table>
                        </div>
                      </template>
                    </wizard-step>
                    <button ref="submit"  type="submit" class="d-none">{{$t('Save')}}</button>
                  </b-form>
                </validation-observer>
                <wizard-buttons
                    :currentstep="wizard.currentstep"
                    :stepcount="steps_count"
                    @step-change="stepChanged"
                    @step-cancel="redirect"
                ></wizard-buttons>
              </div>
            </template>
          </loading-overlay>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import FileInput from "../../components/form/FileInput";
    import WizardHeader from "../../components/wizard/wizardHeader";
    import WizardStep from "../../components/wizard/wizardStep";
    import WizardButtons from "../../components/wizard/wizardButtons";
    import Swal from "sweetalert2";
    import LoadingOverlay from "../../components/common/LoadingOverlay";
    import Multiselect from "vue-multiselect";
    import BranchOfficeInput from "../../components/ecommerce/BranchOfficeInput";
    import ImageProduct from "../../components/ecommerce/imageProduct";
    import VueEasyLightbox from "vue-easy-lightbox";

    export default {
        name:'ProductForm',
        beforeRouteEnter(to, from, next) {
            next((vm) =>  vm.from = from)
        }, 
        components:{
          ImageProduct,
          BranchOfficeInput,
          LoadingOverlay,
          WizardButtons,
          FileInput,
          WizardHeader,
          WizardStep,
          Multiselect,
          VueEasyLightbox
        },
        mixins: [formMixin],
        data(){
            return {
                form: {
                  images: [],
                  branchoffice: []
                },
                images: [],
                formBranchOffice: {},
                showErrors: false,
                loading: false,
                visible: false,
                image: "",
                branchofficeData: [],
                value: {},
                branchoffice_Options: {},
                update: false,
                condition: [{value:'new', text: this.$t('New')},{value:'used',text: this.$t('Used')},{value:'renewed',text: this.$t('Renewed')}],
                type: [{value:'good', text: this.$t('Good')}, {value:'service', text: this.$t('Service')}, {value:'virtual', text: this.$t('Virtual')}, {value:'free_product', text: this.$t('Free product')}],
                currency: [{value:'MXN', text: 'MXN'}, {value:'USD', text: 'USD'}],
                wizard:{
                    currentstep:0,
                    steps:[
                      "Informacion basica",
                      "Fotpos del producto",
                      "Sucursales"
                    ]
                },
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapGetters('catalogs',['categories']),
            steps_count(){
              return this.wizard.steps.length
            },
            branchOfficesOptions(){
              return this.branchoffice_Options
            },
            showErrorImages(){
              return this.images.length === 0 && this.showErrors;
            },
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Products"),
                        to: {name:'products.index'}
                    },
                   {
                        text: this.$route.params.product_id ? this.$t('Edit product'): this.$t('New product'),
                        active: true
                    }
                ]
            },
            uploadPath(){
              if (this.update){
                return '/api/v2/products/'+this.form.id+'/files';
              }else{
                return '/api/v2/upload';
              }
            }
        },
        created() {
            this.loading=true;
            this.fetchBranchoffices({seller_id:this.profile.profile.id}).then((data)=>{
              this.branchoffice_Options  = _.cloneDeep(data.results);
              this.loading= false;
            });
            if(this.$route.params.product_id){
                this.loading = true;
                this.getProduct({id: this.$route.params.product_id})
                    .then(data=>{
                        let response = _.cloneDeep(data)
                        let form = {...response}
                        form.category_id = form.category.id
                        this.form = form;
                        this.images = response.images
                        this.update = true;
                        this.branchoffice_Options = this.branchoffice_Options.filter(item => !response.branchoffice.map((item)=>item.id).includes(item.id));
                        this.loading = false;
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    })
            }
        },
        methods: {
            ...mapActions('products',['getProduct','storeProduct','updateProduct','deleteFile']),
            ...mapActions('branchoffices',['fetchBranchoffices']),
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){

                    return;
                }
                this.loading = true;
                let form = {...this.form};
                if(this.$route.params.product_id){
                    this.updateProduct(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            this.redirect();
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                            this.wizard.currentstep = 0;
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }else{
                    this.storeProduct(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Created successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                              this.redirect();
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                            this.wizard.currentstep = 0;
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }
            },
            redirect(){
                if(this.update){
                    this.$router.back();
                }else{
                    this.$router.push({name:'products.index'});
                }                
            },
            deleteBranchOffice(data){
              this.form.branchoffice = _.cloneDeep(this.form.branchoffice.filter((item)=>item.id!==data.id))
              data = _.cloneDeep(data);
              delete data.price;
              this.branchoffice_Options.push(data);
            },
            fileUpload(data){
              this.showErrors = false;
              this.form.images = [...this.form.images,data.id]
              this.images = [...this.images,data]
            },
            errorUpload(data){
              alert("IMAGEN NO SE PUEDO SUBIR");
            },
            filteredOptions(data){

            },
            addBranchOffice() {
              let data = _.cloneDeep(this.formBranchOffice);
              if (Object.values(data).length===2){
                let auxData = {...data.branchoffice,...{price:data.price}}
                let branchesOffices = _.cloneDeep(this.form.branchoffice);
                branchesOffices.push(auxData);
                this.form.branchoffice = branchesOffices;
                this.branchoffice_Options = this.branchoffice_Options.filter(item => item.id !== data.branchoffice.id);
                this.formBranchOffice = {price:null};
                this.$refs.branch_office_input.clear();
              }else{
                this.$bvToast.toast( this.$t("Please, check the form"), {
                  title: "Error",
                  variant: 'danger',
                  solid: true
                });
              }
            },
            async stepsValidations() {
              switch (this.wizard.currentstep) {
                case 0:
                  return await this.$refs.observer.validate();
                case 1:
                  //this.showErrors = this.images.length === 0;
                  //return this.images.length;
                case 2:
                  return true;
                case 3:
                  return true;
              }
            },
            showImage(image) {
                this.image = image.path
                this.visible = true;
            },
            stepChanged: async function (step) {
              if (this.wizard.currentstep > step){
                this.wizard.currentstep = step;
              }else if (await this.stepsValidations()){
                if (step === this.steps_count) {
                  this.$refs.submit.click();
                }else{
                  this.wizard.currentstep = step;
                }
              }else{
                this.$bvToast.toast( this.$t("Please, check the form"), {
                  title: "Error",
                  variant: 'danger',
                  solid: true
                });
              }
            },
            confirmDeleteFile(data){
                Swal.fire({
                  title: this.$t("Are you sure?"),
                  text: this.$t("You won't be able to revert this!"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#34c38f",
                  cancelButtonColor: "#f46a6a",
                  confirmButtonText: this.$t("Yes, delete it!"),
                  cancelButtonText: this.$t("Cancel")
                }).then(result => {
                  if(result.value) {
                      this.deleteFile({id:data.id})
                          .then(response=>{
                            this.images = this.images.filter(item=> item.id !== data.id);
                          })
                          .catch(error =>{

                          })
                          .finally(()=>{

                          });
                  }
                });
            },
        }
    }
</script>

<style >
.pl-21{
  padding-left: 21px;
}

.delete-img{
  position: absolute;
  left: calc(50% - 10px);
  bottom: -30px;
}

.gallery_list_item img{
  width: 60px;
  height: 60px;
}

.gallery_list{
  display: flex;
  width: 100%;
  padding: 0 20px;
}
.gallery_list_item{
  margin-right: 10px;
  border: 2px solid white;
  transition: all .5s;
  border-radius: 5px;
  position: relative;
}
.gallery_list_item:hover{
  cursor: pointer;
  opacity: 1;
  border: 2px solid #2bb3e7;
}
.gallery_list_item.active{
  border: 2px solid #2bb3e7;
  opacity: 1;
}
</style>
