<template>
  <div>
    <div class="table-responsive">
      <table class="table mb-0">
        <thead>
        <tr class="">
          <th class="text-center">{{$t('Created at')}}</th>
          <th class="text-center">{{$t('Type')}}</th>
          <th class="text-center">{{$t('Status')}}</th>
          <th class="text-center">{{$t('Amount')}}</th>
          <th class="text-center">{{$t('Fee')}}</th>
          <th class="text-center">{{$t('Total')}}</th>
          <th class="text-center">{{$t('Description')}}</th>
          <th class="text-center">{{$t('Load ID')}}</th>
          <th class="text-center">{{$t('Carrier')}}</th>
          <th class="text-center" style="min-width: 170px;max-width:200px;">
            {{$t('Actions')}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="fetching">
          <td colspan="100%">
            <loading />
          </td>
        </tr>
        <template v-for="item in list.results">
          <tr v-if="init || page === list.current_page" :key="'load-'+item.id"  class="truck-row">
            <td class="text-center">{{item.created_at | timestamp}}</td>
            <td class="text-center">
                <span class="badge badge-outline-primary">
                    {{$t('advance_factoring_types.'+item.type)}}
                </span>
            </td>
            <td class="text-center">
                <span class="badge mr-1 " :class="[statusColor[item.status]]">
                                    {{$t('status.factoring.'+item.status)}}
                </span>
            </td>
            <td class="text-center">$ {{item.amount | money}} {{item.currency}}</td>
            <td class="text-center">$ {{item.fee | money}} {{item.currency}}</td>
            <td class="text-center">$ {{item.total | money}} {{item.currency}}</td>
            <td class="text-center">{{item.description}}</td>
            <td class="text-center">
              <router-link :to="{name:'loads.detail', params:{load_id: item.load_id}}">
                <b>{{item.load_id}}</b>
              </router-link>
            </td>
            <td class="text-center">
              <router-link v-if="item.carrier" :to="{name:'carriers.detail', params:{carrier_id: item.carrier.id}}">
                <b>{{item.carrier.name}}</b>
              </router-link>
            </td>
            <td>
              <template v-if="item.status === 'in_review'">
                <a href="javascript:void(0);" @click="confirmAccept(item)" class="text-primary mr-2"  v-b-tooltip.hover :title="$t('Accept')"  >
                  <i class="far fa-check-circle" style="font-size:20px;" />
                </a>
                <a href="javascript:void(0);" @click="confirmReject(item)" class="text-danger mr-2"  v-b-tooltip.hover :title="$t('Reject')"  >
                  <i class="far fa-times-circle" style="font-size:20px;" />
                </a>
              </template>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div  v-if="list.total > 0"  class="row align-items-center">
      <div class="col-md-6 col-sm-6 ">
        <b-pagination
            v-model="page"
            :total-rows="list.total"
            :per-page="list.per_page"
            aria-controls="my-table"
            first-number
            last-number
            pills
        ></b-pagination>
      </div>
      <div class="col-md-6 col-sm-6 text-sm-right">
        <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
      </div>
    </div>
    <div v-if="list.total === 0 && !fetching" class="row">
      <div class="col">
        <empty-results />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState, mapActions} from "vuex";
import Loading from "../common/Loading";
import selectAllMixin from "@/mixins/selectAllMixin";
import Swal from "sweetalert2";

export default {
  name: "FactoringTable",
  mixins:[selectAllMixin],
  components: {
    'loading':Loading
  },
  props: {
    filters : {
      type: Object,
      default(){
        return {}
      }
    },
    edit: {
      type: String
    },
  },
  data(){
    return {
      page: 1,
      init: false,
      statusColor: {
        "accepted":"badge-outline-primary",
        "rejected": "badge-outline-danger",
        "expired": "badge-outline-danger",
        "in_review": "badge-outline-warning",
        "pending": "badge-outline-primary",
        "completed": "badge-outline-success"
      }
    }
  },
  computed: {
    ...mapState('factoring', ['factoring','fetching']),
    ...mapState('profile',['profile']),
    list(){
      return this.factoring
    }
  },
  created() {
    this.page = this.filters.page ? Number(this.filters.page) : 1;
  },
  mounted() {
    if(Object.keys(this.filters).length > 0){
      this.fetchData();
    }
  },
  watch: {
    filters : {
      deep: true,
      handler(newVal, oldVal){
        if(!_.isEqual(newVal, oldVal) || this.init === false){
          this.fetchData();
        }
      }
    },
    page(newVal){
      this.$emit('page-change', newVal);
    }
  },
  methods: {
    ...mapActions('factoring',['getFactoring','acceptFactoring','rejectFactoring']),
    fetchData(){
      this.getFactoring(_.cloneDeep(this.filters))
          .then(response=>{
          })
          .catch(error =>{
          })
          .finally(()=>{
            this.init = true;
          })
    },
    confirmAccept(item){
      Swal.fire({
        title: this.$t("Confirm"),
        text: this.$t("I want to accept the request"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, continue"),
        cancelButtonText: this.$t("Cancel"),
        reverseButtons: true
      }).then(result => {
        console.log(result);
        if(result.value) {
          this.acceptFactoring({factoring_id: item.id})
              .then(data=>{
                this.fetchData();
                this.$root.$bvToast.toast(this.$t(`The request was accepted`), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error=>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    },
    confirmReject(item){
      Swal.fire({
        title: this.$t("Confirm"),
        text: this.$t("I want to reject the request"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, continue"),
        cancelButtonText: this.$t("Cancel"),
        reverseButtons: true
      }).then(result => {
        if(result.value) {
          this.rejectFactoring({factoring_id: item.id})
              .then(data=>{
                this.fetchData();
                this.$root.$bvToast.toast(this.$t(`The request was rejected`), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error=>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    },
    confirmDelete(item){
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.deleteTruck({id: item.id})
              .then(data=>{
                this.fetchData();
                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Truck").toLowerCase()}), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    }
  }
}
</script>

<style scoped>
.truck-row>td{
  vertical-align: middle !important;
  height: 50px!important;
  max-height: 50px!important;
  text-align: center !important;
}
</style>
