<template>
  <div class="register" style="">
    <div class="register_title_back register_section cursor-pointer" @click="back()"> <i class="fa fa-chevron-left"/>
      {{ $t('Back') }} </div>
    <div class="register_title register_section mb-0">{{$t('Update password')}}</div>
    <div class="register_label register_section">
      <div class="register_label_label mb-0">{{$t('Write your new password')}}</div>
    </div>
    <validation-observer ref="observer" v-slot="{ validate , errors}">
      <loading-overlay :loading="loading" >
        <form  @submit.stop.prevent="submit(validate)" >
          <div class="register_input_generic register_section">
            <div class="register_input_generic_label">{{ $t('New Password') }}</div>
            <validation-provider name="new_password" rules="required|min:4" v-slot="{ errors }">
              <p :class="{ 'control': true }">
                <input class="login_input_generic_input"  v-model="model.new_password"  :class="{'input': true, 'is-danger': errors.length }"  name="new_password" type="password" placeholder="">
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </p>
            </validation-provider>
          </div>
          <div class="register_input_generic register_section">
            <div class="register_input_generic_label">{{ $t('Confirm Password') }}</div>
            <validation-provider name="password" rules="required|min:4|confirmed:new_password" v-slot="{ errors }">
              <p :class="{ 'control': true }">
                <input class="login_input_generic_input"  v-model="model.confirm_password"  :class="{'input': true, 'is-danger': errors.length }"  name="password" type="password" placeholder="">
                <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
              </p>
            </validation-provider>
          </div>
          <div class="register_section register_action" >
            <button  :class="{'disabled':(hasErrors(errors))}"   class="btn btn-primary_v2 btn-block  text-uppercase  login_btn" type="submit" >{{$t('continue')}}</button>
          </div>
        </form>
      </loading-overlay>

    </validation-observer>
    <alert-modal :visible="notification.visible" :success="notification.success"  :title="notification.title" :message="notification.message" :sub_message="notification.sub_message" :reload_on_close="false" v-on:close="closeNotificationCustom" />
  </div>
</template>

<script>

import {completeNewPassword, getAccessToken} from '../../../utils/auth.js'
import VueJwtDecode from "vue-jwt-decode";
import AlertModal from "../../../components/modals/AlertModal";
import AlertModalMixin from "../../../mixins/AlertModalMixin";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Echo from "laravel-echo";
import LoadingOverlay from "../../../components/common/LoadingOverlay";

export default {
  name: "ResetPasswordRequired",
  data(){
    return {
      model: {},
      redirect: false,
      loading: false
    }
  },
  components: {
    LoadingOverlay,
    AlertModal
  },
  mixins:[AlertModalMixin],
  created(){
    if (!this.getUser()){
      this.$router.push({name:'login.index'})
    }
  },
  methods:{
    ...mapActions('profile',['getProfile','getProfileActions']),
    ...mapGetters('register',['getUser']),



    ...mapActions('catalogs',['getCatalogs']),
    ...mapActions('forms',['getForm']),
    ...mapMutations('forms',['setCustomLoadForm','setCarrierLoadForm','setShipperLoadForm','setAdminLoadForm']),
    ...mapActions('views',['getActiveViews','executeViews']),
    ...mapActions('configuration',['getConfiguration']),



    back(){
      this.$router.push({name:'login.index'})
    },
    async submit(validate) {
      if (!(await validate()) || this.loading) {
        this.$bvToast.toast(this.$t('Please, check the form'), {
          title: "Error",
          variant: 'danger',
          solid: true
        });
        return;
      }
      let data = _.cloneDeep(this.model);
      completeNewPassword(_.cloneDeep(this.getUser()),data.new_password).then((e) => {
        this.notificationSuccess(this.$t('Success'), this.$t('Reset password successfully'), '');
        this.redirect = null;
      }).catch((err) => {
        this.notificationError("Error", this.$t(err.code), this.$t(err.message));
        this.redirect = 'login.index'
      })
    },
    hasErrors(err){
      return !!_.findKey(err,o=>o.length>0)
    },
    async closeNotificationCustom(err) {
      this.notification.visible = false;
      if (this.redirect) {
        await this.$router.push({
          name: this.redirect
        })
      }
      window.Pusher = require('pusher-js');
      window.Echo = new Echo({
        broadcaster: 'pusher',
        authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
        key: process.env.VUE_APP_PUSHER_APP_KEY,
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        forceTLS: true,
        auth: {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            Accept: 'application/json',
          },
        }
      });
      this.loading=true;
      this.getProfileActions().then((d) => {
        d.status ? this.InitApp() : this.CompleteProfile();
      }).catch((d) => {
        this.CompleteProfile();
      })
      this.redirect = null
    },
    fetchCatalogs(user){
      this.getCatalogs();
      this.getConfiguration();
      if(['carrier','shipper','admin'].indexOf(user.profile.object) > -1 && user.profile.load_form_id){
        this.getForm({id: user.profile.load_form_id})
            .then(data =>{
              switch (user.profile.object){
                case "carrier":
                  this.setCarrierLoadForm(data);
                  break;
                case "shipper":
                  this.setShipperLoadForm(data);
                  break;
                case "admin":
                  this.setAdminLoadForm(data);
                  break;
              }
            })
            .catch(error=>{
            });
      }
      this.getActiveViews();
      this.loading=false;
    },
    InitApp(){
      this.getProfile().then((data)=>{
        this.fetchCatalogs(data);
        try{
          if(this.$route.params.profile !== data.profile.object){
            this.$router.push({name:'profile', params:{profile: data.profile.object}});
          }
        }catch(e){}
      });
    },
    CompleteProfile(){
      this.loading=false;
      if (this.$route.name !== 'on_boarding'){
        this.$router.push({name:"on_boarding"});
      }
    },
  }
}
</script>

<style scoped>

</style>