<template>
    <div class="text-center">
        <img src="@/assets/images/txp/triangle.png" style="width: 70px;height: 70px;margin:10px;" />
        <div class="mt-3">
            <h5>{{$t(title)}}</h5>
            <p>{{$t(subtitle)}}</p>
        </div>
    </div>
</template>

<script>
    export default {
      props:{
        title: {
          type: String,
          default(){
            return 'No results found'
          }
        },
        subtitle: {
          type: String,
          default(){
            return "We couldn't find what your are looking for"
          }
        }
      },
        name: "EmptyResults"
    }
</script>

<style scoped>

</style>
