<template>
<div class="">
    <!-- Start Table -->
    <div class="table-responsive">
      <table class="table mb-0">
        <thead>
        <tr class="">
          <th class="text-center" style="min-width: 60px"></th>
          <th class="text-center">ID</th>
          <th v-if="!edit" class="text-center">Type</th>
          <th class="text-center">{{ $t('Name') }}</th>
          <th class="text-center">{{ $t('Date') }}</th>
          <th v-if="!isDriver" class="text-center">{{ $t('Amount') }}</th>
          <th class="text-center">{{ $t('Description') }}</th>
          <th class="text-center">{{ $t('Status') }}</th>
          <th class="text-right ">
            <button   @click="fetchData()" type="button" class="btn btn-light">
              <i class="far fa-sync"></i>
            </button>
            <button v-if="canBeManagement"   @click="resetModal()" class="btn btn-light">
              <i class="far fa-plus"></i>
            </button>


          </th>
        </tr>
        </thead>
        <tbody>
        <template  v-if="fetching">
          <tr>
            <td colspan="6"><loading/></td>
          </tr>
        </template>
        <template v-else  v-for="item in list.results">
          <tr v-if="page === list.current_page" :key="'expense-'+item.id"  class="expense-row">
            <td>
              <div class="img_expense" >

                <img v-if="item.files.length" :src="`${item.files[0].path}`" alt width="30" height="40" style="object-fit: cover;" />
                <img v-else src="@/assets/images/txp/common/preview.svg" >
              </div>
            </td>
            <td class="text-center">
              <b>{{item.id}}</b>
            </td>
            <td v-if="!edit" class="text-center">
              {{$t(item.type)}}
            </td>
            <td class="text-center">
              {{ item.name }}
            </td>
            <td class="text-center">
              {{ item.date }}
            </td>
            <td v-if="!isDriver" class="text-center">
              {{ item.amount | money }}
            </td>
            <td class="text-center">
              {{ item.description }}
            </td>
            <td class="text-center">
              <span class="badge" :class="getStatusStyle(item)" >{{ getStatusName(item) }}</span>
            </td>
            <td class="text-center">
              <div class="expenses_actions">
                <a v-if="edit && canBeManagement" @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-2">
                  <i class="far fa-trash-alt btn-icon"  />
                </a>
                <a v-if="edit && canBeEdit(item)"  @click="updateExpenseModal(item)" class="mr-2">
                  <i class="far fa-edit btn-icon"></i>
                </a>
                <a v-if="voucherId==item.id" href="javascript:void(0);" @click="voucherId = false">
                  <i class="far fa-chevron-circle-up btn-icon"></i>
                </a>
                <a v-else href="javascript:void(0);" @click="showRow(item)">
                  <i class="far fa-chevron-circle-down btn-icon"></i>
                </a>
              </div>
            </td>
          </tr>
          <!-- Start Vouchers view -->
          <template v-if="voucherId === item.id">
            <tr :key="'file-detail-'+item.id">
              <td colspan="100%" class="p-0">
                <div class="card border-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-7">
                        <div class="row">
                          <div class="col-md-6 mt-5" v-if="!isDriver">
                            <div class="field-wrapper border-bottom-none p-0">
                              <div class="field-label font-weight-bold ">{{$t('Amount')}}</div>
                              <div class="field-help">{{ item.amount | money  }}</div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-5">
                            <div class="field-wrapper border-bottom-none p-0">
                              <div class="field-label font-weight-bold ">{{$t('Currency')}}</div>
                              <div class="field-help">{{ item.currency }}</div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-5">
                            <div class="field-wrapper border-bottom-none p-0">
                              <div class="field-label font-weight-bold ">{{$t('Expenses Amount')}}</div>
                              <div class="field-help">{{ item.final_amount | money }}</div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-5">
                            <div class="field-wrapper border-bottom-none p-0">
                              <div class="field-label font-weight-bold ">{{$t('Comments')}}</div>
                              <div class="field-help">{{ item.comments }}</div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-5">
                            <div class="field-wrapper border-bottom-none p-0">
                              <div class="field-label font-weight-bold ">{{$t('Date')}}</div>
                              <div class="field-help">{{ item.date }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-5" v-if="item.status == STATUS.PENDING && canBeManagement && item.files.length" >
                          <div class="col-md-6">
                            <a v-b-modal.modal-confirm-expense @click="approveExpenseModal(item)"  class="btn btn-primary  ">
                              {{$t('Approve expense')}}
                            </a>
                          </div>
                          <div class="col-md-6">
                            <a v-b-modal.modal-reject-expense @click="rejectExpenseModal(item)"  class="btn btn-outline-dark  ">
                              {{$t('Reject expense')}}
                            </a>
                          </div>
                        </div>
                        <div class="row mt-5" v-if="item.status == STATUS.REJECTED" >
                          <div class="col-md-12">
                              <alert icon="warning" :text="$t('The spending check expense_name did not proceed, upload another voucher to continue the review.',{expense_name: item.name})" ></alert>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5 pb-3">
                        <template v-if="edit && item.status !== STATUS.COMPLETED">
                          <h4 class="text-center">{{$t("Add images")}}</h4>
                          <p class="text-muted text-center">{{$t("Remember you can upload files in JPG or PNG format")}}</p>
                          <file-input
                              accept="image/png, image/jpeg, image/bmp"
                              :path="'/api/v2/loads/'+item.owner.owner_id+'/expenses/'+item.id+'/files'"
                              :options="{dictDefaultMessage: 'Subir Documentos'}"
                              v-on:success="successUploadFileModal"

                          />
                        </template>

                        <div v-for="file_data in item.files" :key="file_data.id" class="col-md-2 mini-img mb-2 text-center">
                          <div class="" v-if="file_data.path.substr(-3, 3) !== 'pdf'">
                            <div class="container-mini-over">
                              <img :src="file_data.path" width="60px" height="60px" style="object-fit: cover;" />
                              <a  @click="showSingle(item, file_data)" href="javascript:void(0);" class="text-white pointer-event view-img">
                                <i class="far fa-search btn-icon btn-icon-mini"></i>
                              </a>
                              <a  @click="confirmImageDelete(item, file_data)" href="javascript:void(0);" class="text-danger pointer-event delete-img">
                                <i class="far fa-times-circle btn-icon btn-icon-mini"></i>
                              </a>
                            </div>
                          </div>
                          <div class="container-mini" v-else>
                            <a  :href="file_data.path" target="_blank">
                              <i class="fas fa-file-pdf fa-3x" aria-hidden="true"></i>
                            </a>
                            <a  @click="confirmImageDelete(item, file_data)" href="javascript:void(0);" class="text-danger pointer-event delete-img">
                              <i class="far fa-times-circle btn-icon btn-icon-mini"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </td>
            </tr>
          </template>
          <!-- End Vouchers view -->
        </template>
        </tbody>
      </table>
    </div>

    <!-- End Table -->

    <!-- Start WithOut Results -->
    <div v-if="list.total === 0 && !fetching" class="row">
      <div class="col">
        <empty-results />
      </div>
    </div>
    <!-- End WithOut Results -->

    <!-- Start Paginate -->
      <div  v-if="list.total > 0 && !fetching && paginate"  class="row align-items-center">
        <div class="col-md-6 col-sm-6 ">
          <b-pagination
              v-model="page"
              :total-rows="list.total"
              :per-page="list.per_page"
              aria-controls="my-table"
              first-number
              last-number
              pills
          ></b-pagination>
        </div>
        <div class="col-md-6 col-sm-6 text-sm-right">
          <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p>
        </div>
      </div>
    <!-- End Paginate -->

    <!-- Start Create Modal -->
      <CreateEditExpenseModal ref="createEditExpenseModal" :hiddenDate="true" :filters="filters"  @onSubmit="onSubmit"  ></CreateEditExpenseModal>
    <!-- End Create Modal -->

    <!-- Start Reject Modal -->
      <b-modal
          id="modal-reject-expense"
          ref="modal-reject-expense"
          size="md"
          hide-footer
          title="Rechazar Gasto"
      >
        <validation-observer ref="observer" v-slot="{ validate }">
          <b-form @submit.stop.prevent="onReject(validate)"  >
            <div class="form-fields">
              <div>
                <form-input
                    :title="$t('Comments')"
                    v-model="form.comments"
                    rules="required"
                    type="textarea"
                    name="description"
                    layout="vertical"
                />
              </div>
              <div class="form-fields">
                <div class="field-wrapper pb-0">
                  <p class="text-muted mb-0 ">*{{$t("Add reject commentaries")}}</p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-4 mb-2">
              <a @click="$bvModal.hide('modal-reject-expense')"  class="btn btn btn-light mr-3 ">{{$t('Cancel')}}</a>
              <button type="submit" class="btn btn-primary">{{$t('Send')}}</button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    <!-- End Reject Modal -->

    <!-- Start Confirm Modal -->
      <b-modal
      id="modal-confirm-expense"
      ref="modal-confirm-expense"
      size="md"
      hide-footer
      title="Confirmar"
      @hide="unSetExpense"
  >
    <validation-observer ref="observer" v-slot="{ validate }">
      <b-form @submit.stop.prevent="onApprove(validate)"  >
        <div class="form-fields">
          <div>
            <form-input
                :title="$t('Expenses Amount')"
                v-model="form.final_amount"
                rules="required"
                type="number"
                name="final_amount"
                layout="vertical"
            />
            <form-input
                :title="$t('Comments')"
                v-model="form.comment"
                type="textarea"
                name="description"
                layout="vertical"
            />
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4 mb-2">
          <a @click="$bvModal.hide('modal-confirm-expense')" class="btn btn btn-light mr-3 ">{{$t('Cancel')}}</a>
          <button type="submit" class="btn btn-primary">{{$t('Confirm')}}</button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
    <!-- End Confirm Modal -->

  <div>
    <b-modal id="add_data_evidence"
             ref="add_data_evidence"
             size="lg"
             hide-footer
             no-close-on-esc
             no-close-on-backdrop
             :hideHeaderClose="false"
    >
      <template #modal-header="{}">
        <h5>{{$t('Add Expense Detail')}}</h5>
      </template>
      <div class="row">
        <div v-if="form.srcDetail" class="col-md-6">
          <img v-if="form.srcDetail.substr(-3, 3) !== 'pdf'" :src="form.srcDetail" class="img-fluid" alt="">
          <a v-else :href="form.srcDetail" target="_blank" class="pdf_container text-center d-block">
            <i class="fas fa-file-pdf fa-8x" aria-hidden="true"></i>
          </a>
        </div>
        <div class="col-md-6">
          <div class="field-wrapper">
            <div class="field-label">{{$t('Date')}}<span class="field-required-badge"/></div>
            <div class="field-help"></div>
            <validation-provider name="schedule_to" rules="required" v-slot="{ errors }">
              <date-input v-model="form.date" type="datetime" />
              <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
            </validation-provider>
          </div>
          <form-input
              :title="$t('Expenses Amount')"
              v-model="form.final_amount"
              rules="required"
              type="number"
              name="final_amount"
              layout="vertical"
          />
        </div>
      </div>
      <div class="d-flex justify-content-end mt-4 mb-2">
        <a @click="cancelUpdateFile()" class="btn btn btn-light mr-3 ">{{$t('Cancel')}}</a>
        <button @click="successUploadFile()" class="btn btn-primary">{{$t('Confirm')}}</button>
      </div>
    </b-modal>
  </div>



  <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
  ></vue-easy-lightbox>




</div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";

import formMixin from '@/mixins/formMixin';
import Swal from "sweetalert2";
import FileInput from "@/components/form/FileInput";
import CurrencyInput from "@/components/form/CurrencyInput";
import Alert from "../common/Alert";
import Multiselect from 'vue-multiselect'
import VueEasyLightbox from "vue-easy-lightbox";
import CreateEditExpenseModal from "../modals/CreateEditExpenseModal";
import DateInput from "../form/DateInput";


export default {
  name: "ExpenseTable",
  props: {
    filters: {
      type: Object,
      default(){
        return {}
      }
    },
    paginate :  {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default(){
        return true;
      }
    }
  },
  components:{
    CreateEditExpenseModal,
    Alert,
    CurrencyInput,
    FileInput,
    Multiselect,
    VueEasyLightbox,
    DateInput
  },
  mixins: [formMixin],
  data(){
    return {
      page: 1,
      imgs: '',
      index: 0,
      load_id: this.$route.params.load_id,
      fetching: false,
      form: {},
      settlementCatalogSelect: [],
      create_expense: false,
      voucherId: false,
      expense: null,
      update: false,
      visible: false,
    }
  },
  computed: {
    ...mapState('loads', ['expenses',"settlementCatalogSelectData"]),
    ...mapState('profile',['profile']),
    ...mapGetters('catalogs', ['expensesTypes','expensesStatus','pendingStatusExpenses','in_reviewStatusExpenses','acceptedStatusExpenses','completedStatusExpenses','rejectedStatusExpenses']),
    list(){
      return this.expenses
    },
    expensesCategories() {
      return this.expensesTypes.map(item=>({
        text:this.$t(item.text),
        value:item.value
      }))
    },
    canBeManagement(){
      return ["carrier","admin","staff"].includes(this.profile.roles[0]);
    },
    isDriver(){
      return ["driver"].includes(this.profile.roles[0]);
    },
    expenseStatus() {
      return this[this.expense_status+'StatusExpenses'].map(item=>({
        text:this.$t(item.text),
        value:item.value
      }))
    }
  },
  created() {
    this.STATUS = {
      "PENDING": "pending",
      "ACCEPTED": "accepted",
      "REJECTED": "rejected",
      "COMPLETED": "completed",
    };
    this.page = this.filters.page ? Number(this.filters.page) : 1;
    this.fetchData();
  },
  watch: {
    filters : {
      deep: true,
      handler(newVal, oldVal){
        if(!_.isEqual(newVal, oldVal)){
          this.fetching= false;
          this.fetchData();
        }
      }
    },
    page(newVal){
      this.$emit('page-change', newVal);
    },
  },
  methods: {
    ...mapActions('loads',['getExpenses','deleteExpense','getExpenseByID','updateExpense','storeExpense','getFiles','deleteImageExpense','acceptedExpense','rejectedExpense','getSettlementCatalogSelectData']),
    fetchData(){
      this.fetching =true;
      this.getExpenses({...{load_id: this.load_id},...this.filters}).finally(()=>{this.fetching = false;})
    },
    showRow(item){
      this.voucherId = item.id
    },
    canBeEdit(item){
      if (this.canBeManagement){
        return true
      }else if (this.isDriver && item.files.length && item.status != this.STATUS.ACCEPTED){
        return true
      }
      return false;
    },
    showSingle(item,file_data) {
      this.imgs = file_data.path;
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    getStatusStyle(item){
      switch (item.status) {
        case this.STATUS.PENDING:
          return "badge-outline-info";
        case this.STATUS.ACCEPTED:
          return "badge-outline-success";
        case this.STATUS.REJECTED:
          return "badge-outline-danger";
        case this.STATUS.COMPLETED:
          return "badge-outline-success";
      }
    },
    getStatusName(item){
      switch (item.status) {
        case this.STATUS.PENDING:
          return this.$t("Pending");
        case this.STATUS.ACCEPTED:
          return this.$t("Accepted");
        case this.STATUS.REJECTED:
          return this.$t("Rejected");
        case this.STATUS.COMPLETED:
          return this.$t("completed");
      }
    },
    updateRows(){
      this.fetching =false;
      this.getExpenses({...{load_id: this.load_id},...this.filters}).finally(()=>{this.fetching = false;})
    },
    unSetExpense(){
      this.expense = null;
    },
    resetModal(){
      this.$refs.createEditExpenseModal.createExpenseModal();
    },
    updateExpenseModal(item){
      this.update = true;
      if (this.canBeManagement){
        this.$refs.createEditExpenseModal.updateExpenseModal(item);
      }else{
        this.form = {...item}
        if (item.files.length){
          let lastItem = item.files[item.files.length - 1];
          this.form.srcDetail = lastItem.path;
        }else{
          this.form.srcDetail = "";
        }
        this.$refs["add_data_evidence"].show();
      }
    },
    approveExpenseModal(expense){
      this.form =  {...expense};
      this.form.final_amount = expense.amount;
      this.expense = expense;
    },
    rejectExpenseModal(expense){
      this.form = {...expense};
      this.expense = expense;
    },
    successUploadFileModal(data){
      if (this.isDriver && data.files.length == 1){
        this.form = {...data}
        let lastItem = data.files[data.files.length - 1];
        this.form.srcDetail = lastItem.path;
        this.$refs["add_data_evidence"].show();
      }else{
        this.updateRows();
      }
    },
    async successUploadFile(){
      let form = {...this.form};
      this.updateRows();
      await this.updateExpenseAction(form,false);
      this.$refs["add_data_evidence"].hide();
    },
    cancelUpdateFile(){
      if (!this.update){
        let expense = {...this.form};
        let item = expense.files[expense.files.length - 1];
        this.deleteImageExpense({...{id: item.id},...{load_id: this.load_id},...{expense_id: expense.id}})
            .then(data=>{
              this.update = false;
              this.updateRows();
              this.$refs["add_data_evidence"].hide();
            });
      }else{
        this.$refs["add_data_evidence"].hide();
      }
    },
    confirmImageDelete(expense, item){
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.deleteImageExpense({...{id: item.id},...{load_id: this.load_id},...{expense_id: expense.id}})
              .then(data=>{
                this.updateRows()
                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Expense").toLowerCase()}), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    },
    confirmDelete(item){
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("Cancel")
      }).then(result => {
        if(result.value) {
          this.deleteExpense({...{id: item.id},...{load_id: this.load_id}})
              .then(data=>{
                this.updateRows();
                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Expense").toLowerCase()}), {
                  title: this.$t('Success'),
                  variant: 'success',
                  solid: true
                });
              })
              .catch(error =>{
                Swal.fire(
                    "Error",
                    this.$t("Something went wrong!"),
                    "error"
                );
              });
        }
      });
    },
    async onApprove(validate){
      if(!(await validate())){
        return;
      }
      let form = _.cloneDeep(this.form);
      form.status = this.STATUS.ACCEPTED;
      this.acceptedExpense({...{load_id: this.load_id},...{expense_id: this.expense.id},...{form: form}})
          .then(data=>{
            this.$refs["modal-confirm-expense"].hide();
            this.updateRows()
            this.$root.$bvToast.toast(this.$t("Your item has been approved.", {resource: this.$t("Expense").toLowerCase()}), {
              title: this.$t('Success'),
              variant: 'success',
              solid: true
            });
          })
          .catch(error =>{
            Swal.fire(
                "Error",
                this.$t("Something went wrong!"),
                "error"
            );
          })
          .finally(()=>{
            this.expense = null;
          });
    },
    async onReject(validate){
      if(!(await validate())){
        return;
      }
      let form = _.cloneDeep(this.form);
      form.status = this.STATUS.REJECTED;
      this.rejectedExpense({...{load_id: this.load_id},...{expense_id: this.expense.id},...{form: form}})
          .then(data=>{
            this.$refs["modal-reject-expense"].hide();
            this.updateRows()
            this.$root.$bvToast.toast(this.$t("Your item has been rejected.", {resource: this.$t("Expense").toLowerCase()}), {
              title: this.$t('Success'),
              variant: 'success',
              solid: true
            });
          })
          .catch(error =>{
            Swal.fire(
                "Error",
                this.$t("Something went wrong!"),
                "error"
            );
          })
          .finally(()=>{
            this.expense = null;
          });
    },
    async onSubmit(data){
      this.update = data.update;
      let form = {...data.form};
      if (this.update){
        await this.updateExpenseAction(form);
      }else{
        await this.saveExpenseAction(form);
      }
    },
    async updateExpenseAction(form,show_notify=true){
      this.updateExpense({load_id: this.load_id, form:form})
          .then(()=>{
            this.$refs.createEditExpenseModal.hide();
            this.updateRows();
            if (show_notify){
              this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                title: this.$t('Success'),
                variant: 'success',
                solid: true
              });
            }
          })
          .catch(error=>{
            this.processServiceError(error);
          })
          .finally(()=>{
          });
    },
    async saveExpenseAction(form){
      let extra_data = {type: this.filters.type}
      form = {...form,...extra_data}
      this.storeExpense({load_id: this.load_id, form})
          .then(()=>{
            this.$refs.createEditExpenseModal.hide();
            this.updateRows();
            this.$root.$bvToast.toast(this.$t('Created successfully'), {
              title: this.$t('Success'),
              variant: 'success',
              solid: true
            });
          })
          .catch(error=>{
            this.processServiceError(error);
          })
          .finally(()=>{
          });
    }
  }
}
</script>

<style scoped>
  .mini-img{
    display:inline-block;
    margin-right: 10px;
  }

  .container-mini,.container-mini-over{
    width: 60px;
    height: 60px;
    margin: auto;
    position: relative;
  }

  .container-mini-over:hover:before{
    cursor: pointer;
    background: rgba(0,0,0,.7);
    width: 100%;
    height:  100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
  }
  .container-mini-over:hover .delete-img,.container-mini-over:hover .view-img{
    opacity: 1;
  }
  .container-mini-over .delete-img,.container-mini-over .view-img{
    opacity: 0;
  }
  .container-mini:hover .delete-img,.container-mini:hover .view-img{
    opacity: 1;
  }
  .container-mini .delete-img,.container-mini-over .view-img{
    opacity: 0;
  }
  .container-mini .delete-img{
    bottom: 20px;
  }
  .delete-img{
    width: 20px;
    height: 20px;
    position: absolute;
    left: calc(50% - 10px);
    bottom: 10px;
  }
  .view-img{
    width: 20px;
    height: 20px;
    position: absolute;
    left: calc(50% - 10px);
    top: 5px;
  }
  .btn-icon-mini{
    font-size: 15px;
  }
  .expenses_actions{
    display: flex;
    justify-content: flex-end;
  }
  .expenses_actions > a {
    margin-left: 2rem;
  }
  .img_expense{
    width: 30px;
    height: 40px;
    background-size: cover;
  }
  .table th, .table td{
    vertical-align: middle!important;
  }
  .field-wrapper{
    border-bottom: none;
  }
  a:disabled{
    background: #7c8a96;
    color: white;
  }
</style>