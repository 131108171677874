<template>
    <div>
        <page-header :title="edit ? $t('Edit truck'): $t('New truck')" :items="breadcrumb"/>

        <div class="row">
            <div :class="[$route.meta.class ? $route.meta.class:'col-md-8 offset-md-2']">
                <validation-observer ref="observer" v-slot="{ validate }">
                    <b-form @submit.stop.prevent="onSubmit(validate)">
                        <loading v-if="loading" />
                        <div class="card">
                            <div class="card-header">
                                <h6>General</h6>
                            </div>
                             <div v-if="$route.params.profile === 'admin' && $route.name ==='trucks.add'" class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Carrier')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t("The carrier owner")}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="carrier_id" rules="required" v-slot="{ errors }">
                                            <carrier-input v-model="form.carrier" :disabled="loading"/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <form-input
                                    v-if="false"
                                    :title="$t('GPS')"
                                    v-model="form.gps"
                                    rules=""
                                    :help="$t('GPS identifier')"
                                    type="text"
                                    :name="'gps'"
                                    :disabled="loading"
                                />
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Custom ID')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('Unique truck identifier')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="external_id"  v-slot="{ errors }">
                                            <b-form-input :disabled="loading" v-model="form.external_id" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <form-input
                                    v-if="false"
                                    :title="$t('TMS ID')"
                                    v-model="form.tms_id"
                                    rules=""
                                    type="text"
                                    :name="'tms_id'"
                                    :disabled="loading"
                            />
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Brand')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The brand truck')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="brand" rules="required" v-slot="{ errors }">
                                            <b-form-input :disabled="loading" v-model="form.brand" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Model')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The model truck')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="model" rules="required" v-slot="{ errors }">
                                            <b-form-input :disabled="loading" v-model="form.model" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Year')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The model year')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="year" rules="required|integer" v-slot="{ errors }">
                                            <b-form-input :disabled="loading" v-model="form.year" type="number" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Plate')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The vehicle plate')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="plate" rules="required|max:8" v-slot="{ errors }">
                                            <b-form-input :disabled="loading" v-model="form.plate" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <form-input
                                    :title="$t('Insurance carrier')"
                                    :help="$t('Name insurance carrier')"
                                    v-model="form.insurance_carrier"
                                    rules="required"
                                    type="text"
                                    :name="'insurance_carrier'"
                                    :disabled="loading" 
                            />

                            <form-input
                                    :title="$t('Policy')"
                                    :help="$t('Truck policy')"
                                    v-model="form.policy"
                                    rules="required"
                                    type="text"
                                    :name="'policy'"
                                    :disabled="loading" 
                            />


                            <form-input
                                    :title="$t('Availability')"
                                    :help="$t('Turn off if resource is not available')"
                                    v-model="form.is_active"
                                    rules=""
                                    type="boolean"
                                    :name="'is_active'"
                            />

                        </div>

                        <div class="card">
                            <div class="card-header">
                                <h6>{{$t('Configuration')}}</h6>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Configuration')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The truck configuration')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="configuration_id" rules="required" v-slot="{ errors }">
                                            <truck-configuration-input v-model="form.configuration" :disabled="loading"/>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Truck type')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The truck type')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="type_id" rules="required" v-slot="{ errors }">
                                             <truck-type-input v-model="form.type" :disabled="loading" />
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Size')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The truck size')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="size_id" rules="required" v-slot="{ errors }">
                                            <b-form-input :disabled="loading" v-model="form.size_id" type="text" placeholder=""></b-form-input>
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Weight')}}<span class="field-required-badge"/></div>
                                        <div class="field-help">{{$t('The truck weight limit')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <validation-provider name="weight" rules="required|numeric|min_value:10" v-slot="{ errors }">
                                                    <b-form-input :disabled="loading" v-model="form.weight" type="number" placeholder=""></b-form-input>
                                                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider name="weight_unit" rules="required" v-slot="{ errors }">
                                                    <multiselect :disabled="loading"  v-model="form.weight_unit" :options="weightUnits"
                                                                placeholder="" select-label="" selected-label=""/>
                                                    <b-form-invalid-feedback >{{errors[0]}}</b-form-invalid-feedback>
                                                </validation-provider>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="field-wrapper">
                                <div class="row align-items-center ">
                                    <div class="col-md-6">
                                        <div class="field-label">{{$t('Trailer type')}}</div>
                                        <div class="field-help">{{$t('The trailer type')}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <validation-provider name="trailer_type_id"  v-slot="{ errors }">
                                            <trailer-type-input v-model="form.trailer_type" :disabled="loading" />
                                            <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <address-form v-model="form.address" :title="$t('Address')" :required="false" :collapsed="true" >
                            <template v-slot:section-start>
                                <address-input v-model="form.address" />
                            </template>
                        </address-form>

                        <div class="card">
                            <div class="card-header">
                                <h6>{{$t('Owner details')}}</h6>
                            </div>

                            <form-input
                                :title="$t('Is leased?')"
                                :help="$t('Turn on if the truck is leased')"
                                v-model="form.is_leased"
                                type="boolean"
                                :name="'is_active'"
                            />

                            <form-input
                                v-if="form.is_leased"
                                :title="$t('Lessor RFC')"
                                :help="$t('lessor´s RFC')"
                                v-model="form.lessor_rfc"
                                type="text"
                                rules="rfc"
                                :name="'lessor_rfc'"
                                placeholder=""
                                :upper="true"
                            />

                            <form-input
                                v-if="form.is_leased"
                                :title="$t('Lessor business name')"
                                :help="$t('lessor´s business name')"
                                v-model="form.lessor_name"
                                type="text"
                                rules=""
                                :name="'lessor_business_name'"
                                placeholder=""
                                :upper="true"
                            />

                        </div>

                        <address-form  v-if="form.is_leased" v-model="form.fiscal_address" :title="$t('Fiscal address lessor')" required="true" :collapsed="true" />
                        <div v-if="['admin', 'staff'].indexOf($route.params.profile) > -1" class="card">
                            <div class="card-header">
                                <h6>{{ $t('Mirror account') }}</h6>
                            </div>
                            <form-input
                                :title="$t('URL')"
                                rules="url"
                                :help="$t('Account url')"
                                v-model="form.mirror_account.url"
                                type="text"
                                :name="'mirror_url'"
                                placeholder=""
                            />
                            <form-input
                                :title="$t('User')"
                                :help="$t('Account user')"
                                v-model="form.mirror_account.user"
                                type="text"
                                :name="'mirror_user'"
                                placeholder=""
                            />
                            <form-input
                                :title="$t('Password')"
                                :help="$t('Account password')"
                                v-model="form.mirror_account.password"
                                type="password"
                                rules=""
                                :name="'mirror_password'"
                                placeholder=""
                            />
                        </div>
                        <div class="d-flex justify-content-end pb-4">
                            <button :disabled="loading" type="button" class="btn btn-light mr-3 " @click="$router.push(list)">{{$t('Cancel')}}</button>
                            <button :disabled="loading" v-if="!edit" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew">{{$t('Save and create new')}}</button>
                            <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t('Save')}}</button>
                        </div>

                    </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import Multiselect from 'vue-multiselect';
    import TrailerInput from '@/components/form/TrailerInput';
    import TruckTypeInput from '@/components/form/TruckTypeInput';
    import TrailerTypeInput from '@/components/form/TrailerTypeInput';
    import TruckConfigurationInput from '@/components/form/TruckConfigurationInput';
    import UserInput from '@/components/form/UserInput';
    import CarrierInput from '@/components/form/CarrierInput';
    import AddressForm from '@/components/form/AddressForm';
    import AddressInput from "@/components/form/AddressInput";

    export default {
        name:'TruckForm',
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.from = from;
            });
        },
        components:{
            Multiselect,
            TrailerInput,
            TruckTypeInput,
            TrailerTypeInput,
            TruckConfigurationInput,
            UserInput,
            CarrierInput,
            AddressForm,
            AddressInput
        },
        mixins: [formMixin],
        data(){
            return {
                title: '...',
                edit: false,
                form: {weight_unit: 'kg', address: {}, fiscal_address: {}, mirror_account: {}},
                isFetchingTrailers: false,
                trailers: [],
                createNew: false,
                loading: false,
                from: null
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapGetters('catalogs',['weight_units']),
            breadcrumb(){
                const profile = this.$route.params.profile;
                return [
                    {
                        text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                        to: {name:'profile'}
                    },
                    {
                        text: this.$t("Trucks"),
                        to: {name:'trucks.index'}
                    },
                    {
                        text: this.edit ? this.$t('Edit truck'): this.$t('New truck'),
                        active: true
                    }
                ]
            },
            weightUnits(){
                return this.weight_units.map(item => item.value);
            },
            list(){
                if(this.$route.meta.routes){
                    return {name: this.$route.meta.routes.list};
                }
                return {name: `trucks.index`}
            }
        },
        created() {
            if(this.$route.params.truck_id){
                this.edit = true;
                this.loading = true;
                this.getTruck({id: this.$route.params.truck_id})
                    .then(data=>{
                        this.form = _.cloneDeep(data);
                        this.loading = false;
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    });
            }else{
                // Use param filters
                if(this.$route.meta.paramFilters){
                    for(let key of this.$route.meta.paramFilters){
                        this.form[key] = this.$route.params[key];
                    }
                }
            }
            
        },
        methods: {
            ...mapActions('trucks',['getTruck','storeTruck','updateTruck']),
            resetForm() {
                this.form = {address:{}, mirror_account: {}};
                this.createNew = false;
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },
            saveAndCreateNew(){
                this.createNew = true;
                this.$refs.submit.click();
            },
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    this.$bvToast.toast(this.$t('Please, check the form'), {
                        title: "Error",
                        variant: 'danger',
                        solid: true
                    });
                    return;
                }

                this.loading = true;
                let form = _.cloneDeep(this.form);
                form.configuration_id = form.configuration.id;
                form.type_id = form.type.id;
                if(form.trailer_type){
                    form.trailer_type_id = form.trailer_type.id;
                }
                if(form.driver){
                    form.driver_id = form.driver.id;
                }
                if(form.carrier){
                    form.carrier_id = form.carrier.id;
                }

                if(!form.is_leased) {
                    delete form.lessor_rfc
                    delete form.fiscal_address
                }

                if (form.lessor_rfc) {
                    form.lessor_rfc = form.lessor_rfc.toUpperCase()
                }

                delete form.configuration;
                delete form.type;
                delete form.trailer_type;
                delete form.driver;

                if(this.edit){
                    this.updateTruck(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            this.redirect();
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }else{
                    this.storeTruck(form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Created successfully'), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                    });
                            if(this.createNew){
                                this.resetForm();
                            }else{
                                this.redirect();
                            }
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }
            },
            redirect(){
                if(this.from.name){
                    this.$router.back();
                }else{
                    this.$router.push({name:this.$route.meta.routes.list ? this.$route.meta.routes.list:'trucks.index'});
                }
            }
        }
    }
</script>

<style>

</style>
