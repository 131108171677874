<template>
  <div >
    <page-header :title="$t($route.meta.title)" :items="breadcrumb"/>
    <p>
      {{$t($route.meta.description)}}
    </p>
    <div class="row mb-4">
      <div class="col-md-12">
        <filter-bar :options="options" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved">
          <template  v-slot:right>
            <div class="btn-group" role="group" aria-label="toolbar">
              <button  v-b-tooltip.hover :title="$t('Refresh')" @click="fetchData()" type="button" class="btn btn-light">
                <i class="far fa-sync"></i>
              </button>
              <button  v-b-tooltip.hover :title="$t('Add expense')"  @click="visible=true" class="btn btn-light">
                <i class="far fa-plus"></i>
              </button>
              <import-data :action="importAddresses" v-on:success="fetchData" />
            </div>
          </template>
        </filter-bar>
      </div>
    </div>
    <AddressesTable v-on:updateData="updateData"></AddressesTable>
    <EditAddress :visible="visible" :update="false" v-on:updateData="updateData"></EditAddress>

  </div>
</template>

<script>
    import AddressesTable from "../../../components/tables/AddressesTable";
    import EditAddress from "../../../components/modals/EditAddress";
    import FilterBar from "../../../components/filters/FilterBar";
    import filterMixin from "../../../mixins/filterMixin";
    import {mapActions, mapState} from "vuex";
    import ImportData from "@/components/common/ImportData";

    export default {
        name: "Addresses",
        components: {EditAddress, AddressesTable,FilterBar, ImportData},
        mixins: [filterMixin],
        data(){
          return{
            visible:false,
          }
        },
        created() {
          let filters = {};
          if(this.$route.meta.paramFilters){
            for(let key of this.$route.meta.paramFilters){
              filters[key] = this.$route.params[key];
            }
          }
          this.defaultFilters = filters
          this.fetchData();
          this.getCarriers({per_page:1000000})
          this.getShippers({per_page:1000000})
        },
        computed:{
          ...mapState('profile', ['profile']),
          ...mapState('accounts', ['carriers','shippers']),
          isAdmin(){
            return this.profile.roles.includes("admin");
          },
          breadcrumb() {
            const profile = this.$route.params.profile;
            return [
              {
                text: this.$t(profile.charAt(0).toUpperCase() + profile.slice(1)),
                to: { name: "profile" },
              },
              {
                text: this.$t("Settings"),
                to: { name: "settings" },
              },
              {
                text: this.$t("Addresses"),
                to: { name: "settings.addresses" },
                active: true,
              },
            ];
          },
          options(){
            let filters = [{
              icon: 'far fa-key',
              label: 'ID',
              type: 'text',
              filterProperty: 'id',
              },
              {
                icon: 'far fa-signature',
                label: this.$t('Name'),
                type: 'text',
                filterProperty: 'name'
              },
              {
                icon: 'far fa-search',
                label: this.$t('Search'),
                type: 'text',
                filterProperty: 'search'
              }];
            if (this.isAdmin && !this.$route.meta.paramFilters){
              filters.push({
                    icon: 'far fa-truck-moving',
                    label: this.$t('Carrier'),
                    filterProperty: 'carrier',
                    type: 'multiselect',
                    values: this.carriers ? this.carriers.results.map(item => ({text: item.name, value: item.id})) : [],
                    valueType: 'number'
                  })
              filters.push({
                    icon: 'far fa-dolly',
                    label: this.$t('Shipper'),
                    filterProperty: 'shipper',
                    type: 'multiselect',
                    values: this.shippers ? this.shippers.results.map(item => ({text: item.name, value: item.id})) : [],
                    valueType: 'number'
                  })
            }
            return filters
          },
        },
        methods:{
          ...mapActions('addresses',['getAddresses','importAddresses']),
          ...mapActions('accounts',['getShippers','getCarriers']),
          updateData(d){
            if (d.updated){
              this.fetchData()
            }
            this.visible = false
          },
          fetchData(){
            this.getAddresses({...this.filters}).then().catch();
          },
        },
      watch: {
        filters : {
          deep: true,
          handler(newVal, oldVal){
            if(!_.isEqual(newVal, oldVal)){
              this.fetchData();
            }
          }
        }
      }
    }
</script>

<style scoped>

</style>
