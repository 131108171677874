<template>
    <div class="nopadding">
        <header-title :headband="true" :title="$t('Home')">
            <div class="text-center mt-5">
                <h5 class="text-white d-flex justify-content-center align-items-center">
                    <i class="ri-check-fill font-weight-bolder mr-1 font-size-check text-yellow"></i>
                    {{$t('Welcome')}} / {{$t('Thanks you for joining our team')}}
                </h5>
                <h3 class="text-white font-weight-bolder mb-3">{{$t('What do you want to do?')}}</h3>
                <router-link
                    :to="{name:'settings.profile'}"
                    class="btn btn-light text-uppercase font-size-12 font-weight-bolder px-4"
                >
                    {{$t('Complete profile')}}
                </router-link>
                <router-link
                    :to="{name:'search'}"
                    class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder ml-3 px-4"
                >
                    {{$t('Search loads')}}
                </router-link>
            </div>
        </header-title>
        <div class="text-center mt-3 h-400">
            <img :src="require('@/assets/images/txp/triangle.png')" alt="traxporta">
            <h4 class="font-weight-bolder m-4">{{$t('What kind of loads would you like to visualize?')}}</h4>
            <router-link
                :to="{name:'recommendations'}"
                class="btn btn-primary_v2 text-uppercase font-size-14 font-weight-bolder px-4"
            >
                {{$t('Configure recommendations')}}
            </router-link>
        </div>
    </div>
</template>


<script>
import { mapMutations } from 'vuex';
import HeaderTitleLarge from '@/components/common/HeaderTitleLarge.vue';
export default {
    name: "Welcome",
    components:{
        HeaderTitle: HeaderTitleLarge,
    },
    methods: {
        ...mapMutations('recommendations', ['setStep']),
    },
    created(){
        this.setStep(1);
    }
}
</script>

<style scoped>
img {
    width: 6%;
}
.font-size-check{
    font-size: 1.9rem!important;
}
.h-400{
    min-height: 400px;
}
</style>
