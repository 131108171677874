<template>
    <div class="main-content bgWhite">
        <div class="page-content py-0" style="height: 100%;width: 100%">
            <control-tower-filter-side-bar v-if="this.$route.params.profile === 'admin'" v-model="toolbarFilters" v-on:change="onFiltersChange" v-on:filter-removed="filterRemoved"  />
            <div class="row main-board">

                <div class="col-md-12 px-0">
                    <GmapMap
                        class="main-board"
                        style="bottom: 0; top:0;width:100%;"
                        ref="mapRef"
                        :center="center"
                        :zoom="6"
                        :options="mapOptions"
                    >
                                <template v-if="load">
                                    <GmapMarker :position="{lat: load.pickup.address.latitude, lng: load.pickup.address.longitude}"
                                                :icon="pickupIcon"
                                    />
                                    <GmapMarker :position="{lat: load.delivery.address.latitude, lng: load.delivery.address.longitude}"
                                                :icon="deliveryIcon"
                                    />
                                    <template v-if="!loadingRoute">
                                        <GmapPolyline  v-if="pendingPath.length > 0" :path="pendingPath" :options="polylinePending" />
                                        <GmapPolyline  v-else :path="pending" :options="polylinePending" />
                                    </template>

                                    <GmapPolyline  v-if="traveledPath.length > 0" :path="traveledPath" :options="polylineCompleted" />
                                    <truck-marker v-if="load.position" :position="load.position" v-on:click="clearLoad" :icon="getIconUrl(load)" />
                                    <!--
                                    <load-detail-window :load="load" v-on:show="showSideBar = true" v-on:close="onWindowClose" />
                                    -->
                                </template>

                                <template v-for="(item,idx) in items">
                                    <truck-marker v-if="item.position" :visible="load === null" :key="'load-marker-'+item.id+'-'+idx"
                                            :position="item.position" v-on:click="setLoad(item)" :icon="getIconUrl(item)"  />
                                    <template v-if="search.length > 0 && loading === false && searchIds.indexOf(item.id) > -1">
                                        <GmapMarker :key="`pick-${item.id}`" :position="{lat: item.pickup.address.latitude, lng: item.pickup.address.longitude}"
                                                :icon="pickupIcon" @click="setLoad(item)"
                                        />
                                        <GmapMarker :key="`drop-${item.id}`" :position="{lat: item.delivery.address.latitude, lng: item.delivery.address.longitude}"
                                                    :icon="deliveryIcon" @click="setLoad(item)"
                                        />
                                    </template>
                                </template>
                                <template slot="visible">
                                    <div class="eta-container mb-3">
                                        <div class="eta-status eta-bg px-4 py-3">
                                            <div class="row justify-content-center ">
                                                <div class="col" v-for="item in status" :key="`card-${item.status}`">
                                                    <div class="card mb-0 eta_item" :class="[`eta-${item.status}`,currentEtaStatus.indexOf(item.status) > -1 ? 'active':'']">
                                                        <div class="card-body py-2">
                                                            <a href="javascript:void(0);" @click="setEtaStatus(item.status)">
                                                                <div class="d-flex justify-content-between ">
                                                                    <div>
                                                                        <h2>{{item.value}}</h2>
                                                                        <span class="text-muted">{{$t(`status.eta.${item.status}`)}}</span>
                                                                    </div>
                                                                    <div>
                                                                        <i :class="[item.icon] " class="eta-icon-muted" style="font-size: 18px;"/>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                    </GmapMap>

                </div>
            </div>
            <b-sidebar v-model="showSideBar" id="sidebar-right" aria-controls="sidebar-right" bg-variant="white"  title="Sidebar" right no-header shadow="lg" width="400px" sidebar-class="load-sidebar">
                <div v-if="load">
                    <div class="p-3">
                        <div class="d-flex justify-content-end mb-3" >
                            <a @click="onSidebarClose" href="javascript:void(0);" class="text-muted">
                                <b class="text-body">{{$t('Close')}}</b>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <h5>ID: {{load.id}}</h5>
                                <div v-if="false" class="ml-2">
                                    <badge-status :status="load.status" :statusName="$t(load.status)" />
                                </div>
                            </div>
                             <p v-if="load.tms_id">TMS ID: <b class="text-body">{{load.tms_id}}</b></p>
                        </div>
                    </div>

                    <b-tabs content-class="mt-3" nav-class="nav-tabs-custom nav-tabs-load-detail">
                        <b-tab :title="$t('Summary')" class="p-3" active>
                            <p>{{$t('Status')}}: <b class="text-body">{{$t(load.status)}}</b></p>

                            <div class="card">
                              <div class="card-header bg-white cursor-pointer d-flex align-items-center justify-content-between" @click="showInformation=!showInformation">
                                <h5 class="card-title font-size-13 mb-0">{{$t("General information")}}</h5>
                                <div class="btn btn-link btn-sm font-weight-bold">{{showInformation?$t("Hide"):$t("Show more")}}</div>
                              </div>
                              <div class="card-body" v-if="showInformation">
                                <div class="mb-3 p-2">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="text-primary item-text">
                                        {{$t('Carrier')}}
                                      </div>
                                      <div class="item-text">
                                        {{load.carrier ? load.carrier.name : '- -'}}
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="text-primary item-text">
                                        {{$t('Driver')}}
                                      </div>
                                      <div class="item-text">
                                        {{load.driver ? `${load.driver.name} ${load.driver.last_name}`: '- -'}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="text-primary item-text">
                                        {{$t('Truck')}}
                                      </div>
                                      <div class="item-text">
                                        {{load.truck ? load.truck.plate: '- -'}}
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="text-primary item-text">
                                        {{$t('Trailer')}}
                                      </div>
                                      <div class="item-text">
                                        {{load.trailer ? load.trailer.plate: '- -'}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                            <load-timeline class="mb-4" :load="load" :stops="stops" :loading="fetchingLoadDetail" />

                            <div class="bg-gray p-2">
                                <p class="m-0 font-weight-bold font-size-13">{{$t('Last location')}}:
                                  <span class="font-weight-medium font-size-13 text-dark">
                                      <a v-if="load.position" target="_blank" :href="`https://www.google.com/maps/search/?api=1&query=${load.position.latitude},${load.position.longitude}`">{{`${load.position.latitude},${load.position.longitude}`}}</a>
                                      <span v-else>- -</span>
                                  </span>
                                  <div @click="showPositionModal = true" class="badge badge-primary cursor-pointer">{{$t('Add')}} <i class="fa fa-plus"/></div>
                                </p>
                            </div>

                            <template v-if="load.position">
                                <div class="bg-gray p-2 mt-3">
                                    <p class="m-0 font-weight-bold font-size-13">{{$t('Last update')}}:
                                    <span class="font-weight-medium font-size-13">
                                        {{load.position.created_at | timestamp}}
                                    </span>
                                    </p>
                                </div>

                                <div class="bg-gray p-2 mt-3 mb-3">
                                    <p class="m-0 font-weight-bold font-size-13f">{{$t('Temperature')}}:
                                    <span class="font-weight-medium font-size-13">
                                        {{load.position.temperature ? load.position.temperature: '- -'}} {{load.position.temperature_unit ? '°'+load.position.temperature_unit: ''}}
                                    </span>
                                    </p>
                                </div>

                                <div id="wrapper" v-if="canBeShowChart">
                                  <div id="chart-line2">
                                    <apexchart type="area" height="230" :options="chartOptions" :series="series"></apexchart>
                                  </div>
                                </div>

                            </template>


                            <position-modal :loadId="load.id" :visible="showPositionModal" v-on:close="showPositionModal = false" />



                            <router-link target="_blank" :to="{name: 'loads.detail.general', params:{load_id: load.id}}" class="btn btn-primary btn-block">
                                {{$t('Show more')}}
                            </router-link>
                        </b-tab>
                        <b-tab :title="$t('Route log')" >
                            <route-log :load_id="load.id" />
                        </b-tab>
                        <b-tab :title="$t('Events')" class="p-3">
                            <load-events v-if="load" :load="load" />
                        </b-tab>
                    </b-tabs>
                </div>
            </b-sidebar>
        </div>


    </div>
</template>

<script>
import mapStyle from "@/constants/mapStyle";
import {mapState,mapGetters, mapActions, mapMutations} from 'vuex';
import BadgeStatus from '../../components/common/BadgeStatus.vue';
import simplebar from "simplebar-vue";
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import filterMixin from '@/mixins/filterMixin';
import FilterBar from "@/components/filters/FilterBar";
import LoadCard from '@/components/common/LoadCard';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import drawCurve from '@/helpers/drawCurve';
import TruckMarker from '@/components/common/TruckMarker'
import LoadDetailWindow from '@/components/common/controltower/LoadDetailWindow'
import LoadTimeline from '@/components/common/controltower/LoadTimeline'
import RouteLog from "../../components/common/RouteLog";
import LoadEvents from "../../components/common/controltower/LoadEvents";
import ControlTowerFilterSideBar from "../../components/filters/ControlTowerFilterSideBar";
import PositionModal from '@/components/modals/PositionModal';
import customFilterMixin from "../../mixins/customFilterMixin";
import moment from "moment-timezone";

window.Apex = {
  tooltip: {
    x: {
      format: "dd/MM/yyyy hh:mm:ss",
    },
    z: {
      formatter: undefined,
      title: 'Unidad: '
    },
  },
}

export default {
    components: {
      ControlTowerFilterSideBar,
      RouteLog,
        BadgeStatus, simplebar, GmapCluster, FilterBar, LoadCard,
        VuePerfectScrollbar, TruckMarker, LoadDetailWindow, LoadTimeline,LoadEvents,
        PositionModal
        },
    name: "Tracking",
    mixins: [customFilterMixin],
    metaInfo(){
        return {
        title: this.$t('Control tower'),
        }
    },
    data(){
        return {
            loadingRoute: false,
            showInformation: false,
            collapsed: false,
            showMap: true,
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                styles: mapStyle
            },
            load: null,
            items: [],
            loading: false,
            truckIcon: {
                scaledSize: {width: 48, height: 48, f: 'px', b: 'px'},
                url: require('../../assets/images/txp/common/truck.png'),

            },
            pickupIcon: {
                scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
                url: require('../../assets/images/txp/origen.png'),
                anchor:{x:20,y:20}
            },
            deliveryIcon: {
                scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
                url: require('../../assets/images/txp/destino.png'),
                 anchor:{x:20,y:20}
            },
            map: null,
            center: {lat:22.527438, lng:-101.506378},
            geo : null,
            lastFilters: {},
            selected: [],
            defaultFilters: {},
            page:1,
            infoWinOpen: false,
            polylineCompleted: {
                strokeColor: "#999999",
                strokeOpacity: 0.6,
                strokeWeight: 4,
            },
            polylinePending: {
                strokeColor: "#00b3e6",
                strokeWeight: 4,
            },
            showSideBar: false,
            showPositionModal: false,
            options: [

            ],
            etaInterval: null,
            fetchingLoadDetail: false
        }
    },
    computed: {
        ...mapGetters('profile',["timezone"]),
        ...mapState('profile',['profile']),
        ...mapGetters('catalogs',['truckTypes']),
        ...mapState('controltower',['stats','loads','routing','search','historyTemperature','timeZoneSelected']),
        ...mapState('loads',['stops','events']),
        requestFilters(){
          return {...this.filters, ...this.defaultFilters}
        },
        completed(){
            if(!this.load)
                return [];
            if(this.load.status === 'started' || !this.load.position){
                return [];
            }
            return drawCurve({lat: this.load.pickup.address.latitude, lng: this.load.pickup.address.longitude},
                {lat: this.load.position.latitude, lng: this.load.position.longitude});
        },
        pending(){
            if(!this.load)
                return [];
            if(this.load.status === 'started' || !this.load.position){
                return drawCurve({lat: this.load.pickup.address.latitude, lng: this.load.pickup.address.longitude},
                    {lat: this.load.delivery.address.latitude, lng: this.load.delivery.address.longitude});
            }
            return drawCurve({lat: this.load.position.latitude, lng: this.load.position.longitude},
                {lat: this.load.delivery.address.latitude, lng: this.load.delivery.address.longitude});
        },
      canBeShowChart(){
        return  (this.load?this.load.position.temperature:false) && this.historyTemperature.length;
      },
      historyTemperatureTimeZone(){
        let tz = this.timezone ? this.timezone : "UTC";
        return this.historyTemperature.map(e => {
          return [ moment.utc(e[0]).tz(tz).format("YYYY-MM-DD HH:mm:ss") , e[1], e[2]]
        });
      },
      series(){
        return [
          {
            name: "Temperatura",
            data: this.historyTemperatureTimeZone,
          },
        ];
      },
      chartOptions(){
        return {
          chart: {
            id: 'chart2',
            type: 'line',
            height: 230,
            toolbar: {
              autoSelected: 'pan',
              show: false
            }
          },
          colors: ['#008FFB'],
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 0.91,
              opacityTo: 0.1,
            }
          },
          stroke: {
            width: 1
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: false
            }
          }
        };
      },
      status(){
        let status = [
                {status: 'late', value: this.stats.late, icon: 'fas fa-exclamation-circle'},
                {status: 'delayed', value: this.stats.delayed, icon: 'fas fa-hourglass'},
                {status: 'early', value: this.stats.early, icon: 'fas fa-clock'},
                {status: 'on_time', value: this.stats.on_time, icon: 'fas fa-check-circle'},
                //{status: 'unknown', value: this.stats.unknown, icon: 'fas fa-check-circle'},
          ]

          if (this.$route.params.profile !== 'admin') {
            status.pop()
          }
          return status;
        },
        currentEtaStatus(){
            return this.filters.eta_status ? this.filters.eta_status.split(',') : [];
        },
        pendingPath(){
            return this.routing ? this.routing.pending_path.map(item =>({lat: item[0], lng: item[1]})) : [];
        },
        traveledPath(){
            return this.routing ? this.routing.traveled_path.map(item =>({lat: item.latitude, lng: item.longitude})) : [];
        },
        searchIds(){
            return this.search.map(item =>item.id);
        }
    },
    watch : {
      requestFilters:{
        deep: true,
        handler(newValue, oldValue){
          this.doSearch();
        }
      },
      load(newValue, oldValue){
            if(newValue){
                if(!oldValue){
                    this.getLoadDetail();
                }
            }else{
                this.showSideBar = false;
            }
        },
        loads(newValue){
            this.items = _.cloneDeep(newValue);
        }
    },
    created () {
        this.doSearch = _.debounce(this.fetchData,500);
        this.init();
        this.etaInterval = setInterval(this.getStats, 10000);
        this.getStats();
    },
    mounted() {
        this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = map;
        });
        Echo.private(`control.tower`)
            .listen('NewLoadPosition', (e) => {
                this.updatePosition(e.position);
            })
            .listen('RouteChange', (e) => {
                this.processControlTowerEvent(e);
            })
            .listen('StopEvent', (e) => {
                this.processControlTowerEvent(e);
            })
    },
    beforeRouteLeave(to, from, next) {
        clearInterval(this.etaInterval);
        next();
    },
    methods: {
        ...mapActions('loads',['getLoad','getStops','getEvents']),
        ...mapActions('controltower',['getStats','getLoads','getRouting','getHistoryTemperature']),
        ...mapMutations('controltower',['setRouting','setSearch']),
        ...mapActions("profile",["setControlTowerFilter"]),
        init(){
          this.toolbarFilters = this.$route.query;
        },
        processControlTowerEvent(e){
            if(this.load && this.load.id === e.load_id){
                this.getLoadDetail();
                this.getRouting({id: this.load.id});
                this.getStops({id: this.load.id});
            }
        },
        fetchData(){
            let filters = _.cloneDeep(this.filters);
            this.loading = true;
            if(filters.shipments || filters.loads){
                filters.any = [];
                if(filters.shipments){
                    filters.any.push({field:'shipments',operator:'in',value:filters.shipments.split(',')});

                }
                if(filters.loads){
                    filters.any.push({field:'ids',operator:'in',value:filters.loads.split(',')});
                }
                 delete filters.shipments;
                 delete filters.loads;
            }
            if(filters.eta_status){
                filters.any = filters.any || [];
                filters.any.push({field:'eta_status',operator:'in',value:filters.eta_status.split(',')});
                delete filters.eta_status;
            }

            this.getLoads(filters)
                .then(data=>{

                })
                .catch(error => {

                })
                .finally(()=>{
                    this.loading = false;
                });
        },
        setLoad(item){
            this.load = item;
            this.fitMap();
            this.loadingRoute = true;
            this.showInformation = false;
            this.getRouting({id: item.id})
                .finally(()=>{
                    this.loadingRoute = false;
                });
            this.getHistoryTemperature({id: item.id})
                .finally(()=>{
                    this.loadingRoute = false;
                });

            this.showSideBar = true;
        },
        fitMap(){
            if(this.map){
                let bounds = new google.maps.LatLngBounds();
                bounds.extend({lat: this.load.pickup.address.latitude, lng: this.load.pickup.address.longitude});
                bounds.extend({lat: this.load.delivery.address.latitude, lng: this.load.delivery.address.longitude});
                if(this.load.position)
                    bounds.extend({lat: this.load.position.latitude, lng: this.load.position.longitude});
                this.map.fitBounds(bounds);
            }
        },
        updatePosition(position){
            if(this.load && this.load.id === position.load_id){
                let load = _.cloneDeep(this.load);
                load.position = position;
                this.load = load;
                this.getStops({id: this.load.id});
            }
            let index = this.items.findIndex(item => item.id === position.load_id);
            if(index > -1){
                let loads = _.cloneDeep(this.items);
                loads[index].position = position;
                this.items = loads;
            }
        },
        setEtaStatus(status){
            const etaStatus = _.cloneDeep(this.currentEtaStatus);
            const index = etaStatus.findIndex(item => item === status);
            if(index === -1){
                etaStatus.push(status)
            }else{
                etaStatus.splice(index, 1);
            }
            if (etaStatus.length){
              this.onFiltersChange({eta_status:etaStatus.join(','), loads:null, shipments: null});
            }else{
              this.filterRemoved("eta_status");
            }
            this.clearLoad();
            this.setSearch([]);
        },
        getLoadDetail(){
            this.fetchingLoadDetail = true;
            this.getLoad({id: this.load.id})
                .then(data =>{
                    if(this.load)
                        this.load = _.cloneDeep(data);
                })
                .catch(error =>{

                })
                .finally(()=>{
                  this.fetchingLoadDetail = false
                })
        },
        clearLoad(){
            this.load = null;
            this.setRouting(null);
        },
        onSidebarClose(){
            this.showSideBar = false;
            this.clearLoad();
        },
        onWindowClose(){
            this.clearLoad();
        },
        getIconUrl(load){
            if(load.eta_status === 'late'){
                return require('../../assets/images/txp/late.png')
            }
            if(load.eta_status === 'delayed'){
                return require('../../assets/images/txp/delayed.png')
            }
            if(load.eta_status === 'early'){
                return require('../../assets/images/txp/early.png')
            }
            if(load.eta_status === 'on_time'){
                return require('../../assets/images/txp/on_time.png')
            }
            return null;
        }
    },
}
</script>

<style >
    .main-board{
        height: calc(100vh - 70px);
    }
    .eta-container{
        width: 80%;
        margin-left: calc(50% - calc(80%/2));
        position: absolute;
        bottom: 0px;
    }
    .eta-status{
        width: 100%;
        border-radius: 20px;
    }
    .eta-bg{
        background-color: rgba(255, 255, 255, 0.8);
    }
    .eta_item.active *{
      color: white!important;
    }
    .eta_item{
      background-color:white;
      transition: all .5 s;
    }

    .eta-delayed{
      border-left: 8px solid #fcb92c;
    }
    .eta-delayed.active{
      background-color:#fcb92c;
      transition: all .5s;
    }
    .eta-late{
      border-left: 8px solid #FF0000;
    }
    .eta-late.active{
      background-color: #FF0000;
      transition: all .5s;
    }
    .eta-early{
      border-left: 8px solid #F70997;
    }
    .eta-early.active{
      background-color:#F70997;
      transition: all .5s;
    }
    .eta-on_time{
      border-left: 8px solid #1cbb8c;
    }
    .eta-on_time.active{
      background-color: #1cbb8c;
      transition: all .5s;
    }
    .eta-unknown{
      border-left: 8px solid #dddddd;
    }
    .eta-unknown.active{
      background-color: #dddddd;
      transition: all .5s;
    }

    .eta-icon-muted{
      color: #cccccc;
    }


    .load-sidebar{
        top: 75px!important;
    }
    .nav-tabs-load-detail{
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .nav-tabs-custom .nav-item {
        padding-bottom: 5px;
        padding-top: 5px;
    }
    .load-sidebar > .b-sidebar-body{
        margin-bottom: 70px;
    }
    .bg-gray{
        background-color: #f9f9f9!important;
    }
    .item-text{
        font-size: 11px;
    }
    .bg-white{
      background: white!important;
    }
</style>
