import axiossls from "../../api/clientsls";

// initial state
export const state = () => ({
    commodity_trailer_type: null, // se usa para filtrar los commodities
    suggested_equipment: null, // se usa para mostrar equipo sugerido, true or false
    available: { trailers: null, trucks: null, equipments: null }
});

// getters
export const getters = {
    commodity_trailer_type: (state) => {
        return state.commodity_trailer_type;
    },
    available: (state) => {
        return state.available;
    },
};

// actions
export const actions = {
    loadCalculator({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axiossls
            .post(`/marketplace/load-calculator`, payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
        });
    },
};

// mutations
export const mutations = {
    setCommodityTrailerType(state, data) {
       state.commodity_trailer_type = data;
    },
    setSuggestedEquipment(state, data) {
       state.suggested_equipment = data;
    },
    resetShipmentform(state, data){
        state.commodity_trailer_type= null;
        state.suggested_equipment= null;
    },
    setAvailable(state, data) {
       state.available = data;
    },
};
