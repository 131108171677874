<template>
<div class="">
  <img
      v-if="item.avatar"
      class="rounded-circle"
      :src="item.avatar"
      alt="Avatar"
      :width="size" :height="size" style="object-fit: cover;padding:0!important"
  />
  <vue-letter-avatar v-else :name="name" :size="String(size)" :rounded="true" />
</div>
</template>

<script>
export default {
  name: "Avatar",
  props:{
    item:{
      default: {
        name:"",
        last_name:""
      }
    },
    size:{
        default: "30"
    }
  },
  computed:{
    name(){
      if (this.item.last_name){
        return this.item.name+ ' '+this.item.last_name;
      }
      return this.item.name;
    }
  }
}
</script>

<style scoped>

</style>
