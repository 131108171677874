import axios from "../../api/client";


// initial state
export const state = () => ({

});

// getters
export const getters = {};

// actions
export const actions = {
    deleteFile({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/upload/${params.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                });
        });
    }
};

// mutations
export const mutations = {

};

