<template>
  <b-modal
      ref="modal-edit-address"
      id="modal-edit-address"
      size="lg"
      :title="update?$t('Edit address'):$t('Add address')"
      :visible="visible"
      v-on:hidden="closeModal"
      body-class="p-0"
      cancel-variant="light"
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Save')"
      hide-footer
  >
    <loading v-if="fetchingIssue"/>
    <validation-observer ref="observer" v-slot="observer">
      <b-form @submit.stop.prevent="onSubmit(observer)" >
        <div class="row">
          <div class="col-md-12">
            <div class="field-wrapper">
              <div class="row align-items-center ">
                <div class="col-md-6">
                  <div class="field-label">{{$t('Name')}}<span class="field-required-badge"/></div>
                  <div class="field-help"></div>
                </div>
                <div class="col-md-6">
                  <validation-provider :name="'name'" :rules="'required'" v-slot="{ errors }">
                    <b-form-input :id="'name'"  v-model="form.customer_code"  type="text" placeholder=""></b-form-input>
                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="field-wrapper">
              <div class="row align-items-center ">
                <div class="col-md-6">
                  <div class="field-label">{{$t('External ID')}}<span class="field-required-badge"/></div>
                  <div class="field-help"></div>
                </div>
                <div class="col-md-6">
                  <validation-provider :name="'external_id'" :rules="'required'" v-slot="{ errors }">
                    <b-form-input :id="'external_id'"  v-model="form.external_id"  type="text" placeholder=""></b-form-input>
                    <b-form-invalid-feedback :state="false">{{errors[0]}}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <address-components ref="address_components"  v-model="form" :show-map="true" :required="true" />
        <div class="d-flex justify-content-end m-4">
          <a @click="$bvModal.hide('modal-edit-address')" :disabled="submit" class="btn btn btn-light mr-3 ">{{$t('Cancel')}}</a>
          <button type="submit" :disabled="submit" class="btn btn-primary">{{$t('Save')}}</button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import AddressComponents from "../form/AddressComponents";
import {mapActions} from "vuex";

export default {
  name: "EditAddress",
  components:{
    AddressComponents
  },
  props: {
    visible:{
      type: Boolean,
      default(){
        return true;
      }
    },
    update:{
      type: Boolean,
      default(){
        return false;
      }
    },
    address:{
      type: Object,
      default(){
        return {}
      }
    }
  },
  data() {
    return {
      fetchingIssue : false,
      dataRoles: {isAdmin:false},
      submit: false,
      isUpdated: false,
      form :{}
    }
  },
  created() {
    if(this.$route.meta.filters){
      this.dataRoles['resource'] = this.$route.meta.filters.roles
      if(this.$route.meta.paramFilters){
        for(let key of this.$route.meta.paramFilters){
          this.dataRoles['resource_id'] = this.$route.params[key];
        }
        this.dataRoles.isAdmin = true
      }
    }
  },
  watch:{
    visible(value){
      if (value){
        if (this.update){
          this.form = this.address;
        }
      }
    }
  },
  methods:{
    ...mapActions("addresses",["storeAddresses","updateAddresses"]),
    closeModal(){
      let data = {updated: this.isUpdated}
      this.$emit('updateData', data);
      this.form ={}
      this.isUpdated = false;
      this.submit = false;

    },
    async onSubmit(observer) {
      if(!(await observer.validate()) || this.loading){
        this.$bvToast.toast(this.$t('Please, check the form'), {
          title: "Error",
          variant: 'danger',
          solid: true
        });
        return;
      }
      if(this.submit === true)
        return;
      this.submit = true;
      this.isUpdated = true;
      let form = _.cloneDeep(this.form);

      if (this.update){
        this.updateAddresses(form)
            .then((d)=>{
              this.$bvModal.hide('modal-edit-address');
            })
            .catch(()=>{
            })
            .finally(()=>{
              this.submit=false
            })
      }else{
        if (this.dataRoles.isAdmin){
          form = {...form,...this.dataRoles}
        }
        console.log(form)
        this.storeAddresses(form)
            .then((d)=>{
              this.$bvModal.hide('modal-edit-address');
            })
            .catch(()=>{
            })
            .finally(()=>{
              this.submit=false
            })
      }

    }
  }
}
</script>

<style>
.pac-container {
  z-index: 100000 !important;
}
</style>