<template>
    <div class="row mt-4">
        <div class="col-md-8 offset-md-2">
            <loading v-if="loading" />
            <validation-observer v-if="form" ref="observer" v-slot="{ validate }">
                <b-form @submit.stop.prevent="onSubmit(validate)">

                    <div>
                        <div class="card">
                            <div class="card-header">
                                <div v-if="stop" class="d-flex justify-content-between">
                                    <div>
                                        <h6 style="display: inline;">{{edit ? $t('Edit form') : $t('New form')}}</h6>
                                        <span class="badge badge-outline-primary ml-3">{{$t('Stop')}} {{stop.order}}</span>
                                    </div>
                                </div>
                                <div v-if="stop" class="mt-1">{{stop.address | address-format}}</div>
                            </div>

                            <div v-if="!edit && false" class="form-fields" >
                                <form-input
                                        v-if="forms.length > 0"
                                        :title="$t('Template')"
                                        :help="$t('Select a template if you want to copy it')"
                                        v-model="form.form_id"
                                        rules=""
                                        type="select"
                                        :name="'form_id'"
                                        :options="forms"
                                        selectValue="id"
                                        selectText="name"
                                />
                                <form-input
                                        v-if="!form.form_id"
                                        :title="$t('Save as template')"
                                        :help="$t('Switch if you want to store as template and use later')"
                                        v-model="form.save"
                                        rules=""
                                        type="boolean"
                                        :name="'save'"
                                />
                            </div>
                        </div>

                        <dynamic-form v-if="!form.form_id" v-model="form.form" />
                    </div>

                    <div class="d-flex justify-content-between">
                        <div>
                            <button :disabled="loading" type="button" class="btn btn-danger float-right" @click="confirmDelete"><i class="far fa-exclamation-circle mr-2" />{{$t('Delete')}}</button>
                        </div>
                        <div>
                            <button :disabled="loading" type="button" class="btn btn-light mr-3 " @click="$router.push({name:'loads.detail.checklist'})">{{$t('Cancel')}}</button>
                            <button :disabled="loading" v-if="!edit" type="button" class="btn btn-light mr-3 " @click="saveAndCreateNew">{{$t('Save and create new')}}</button>
                            <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t('Save')}}</button>
                        </div>
                    </div>

                </b-form>
            </validation-observer>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex';
    import formMixin from '@/mixins/formMixin';
    import DynamicForm from "@/components/form/DynamicForm";
    import Switches from 'vue-switches';
    import Multiselect from 'vue-multiselect';
    import Swal from "sweetalert2";
    export default {
        name:'ChecklisForm',
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.from = from;
            });
        },
        components:{
            DynamicForm,
            Switches,
            Multiselect
        },
        mixins: [formMixin],
        data(){
            return {
                title: '...',
                edit: false,
                form: null,
                createNew: false,
                loading: false,
                from: null,
                clone: false,
                forms: []
            }
        },
        computed: {
            ...mapState('profile', ['profile']),
            ...mapState('loads',['stops']),
            stop(){
                return this.stops.find(item => item.id === Number(this.$route.params.stop_id));
            }
        },
        created() {
            if(this.$route.params.checklist_id){
                this.edit = true;
                this.loading = true;
                this.getChecklist({load_id: this.$route.params.load_id,
                    checklist_id: this.$route.params.checklist_id, stop_id: this.$route.params.stop_id})
                    .then(data=>{
                        this.form = _.cloneDeep(data);
                        this.loading = false;
                    })
                    .catch(error =>{
                        this.processServiceError(error);
                    });
            }else{
                /*
                this.fetchForms({type: 'instruction-letter', per_page: 50})
                    .then(data=>{
                        this.forms = _.cloneDeep(data.results)
                    })
                    .catch(error =>{

                    });
                */
                this.resetForm();
            }
        },
        methods: {
            ...mapActions('loads',['getChecklist','storeChecklist','deleteChecklist']),
            ...mapActions('forms',['updateForm', 'fetchForms']),
            resetForm() {
                this.form = {
                    form:{
                        name: this.$t('Checklist'),
                        clone: true,
                        fields: [
                            {
                                key: '',
                                type: 'text',
                                title: null,
                                help: null,
                                description: null,
                                options: [],
                                visible: true,
                                required: false,
                            }
                        ],
                        type: "instruction-letter"
                    }
                };
                this.createNew = false;
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },
            saveAndCreateNew(){
                this.createNew = true;
                this.$refs.submit.click();
            },
            async onSubmit(validate) {
                if(!(await validate()) || this.loading){
                    return;
                }

                this.loading = true;
                let form = _.cloneDeep(this.form);

                if(!form.form_id){
                    delete form.form_id;
                }

                if(this.edit){
                    this.updateForm(form.form)
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            this.redirect();
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }else{
                    this.storeChecklist({...form, load_id: this.$route.params.load_id, stop_id: this.$route.params.stop_id})
                        .then(data=>{
                            this.$root.$bvToast.toast(this.$t('Created successfully'), {
                                title: this.$t('Success'),
                                variant: 'success',
                                solid: true
                            });
                            if(this.createNew){
                                this.resetForm();
                            }else{
                                this.redirect();
                            }
                        })
                        .catch(error =>{
                            this.processServiceError(error);
                        })
                        .finally(()=>{
                            this.loading = false;
                        });
                }
            },
            redirect(){
                if(this.from.name){
                    this.$router.back();
                }else{
                    this.$router.push({name:'loads.detail.checklist', params: {load_id: this.$route.params.load_id}});
                }
            },
            confirmDelete(){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.loading = true;
                        this.deleteChecklist({
                            checklist_id: this.$route.params.checklist_id,
                            load_id: this.$route.params.load_id,
                            stop_id: this.$route.params.stop_id})
                            .then(data=>{
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Checklist").toLowerCase()}), {
                                    title: this.$t('Success'),
                                    variant: 'success',
                                    solid: true
                                });
                                this.redirect();
                            })
                            .catch(error =>{
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            })
                            .finally(()=>{
                                this.loading = false;
                            });
                    }
                });
            }
        }
    }
</script>

<style>

</style>
