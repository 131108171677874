<template>
  <loading v-if="loading"></loading>
      
  <div class="row" v-else-if="list.length > 0">
    <div v-if="resource.document_status === 'in_review'" class="col-12">
      <b-alert class="mb-0 text-center" dismissible show variant="warning">
        <i class="far fa-exclamation-triangle mr-2"></i>
        {{ $t('Your documents are under review, once they are reviewed you will be notified.') }}
      </b-alert>
    </div>
    <div v-if="resource.document_status === 'accepted'" class="col-12">
      <b-alert class="mb-0 text-center" dismissible show variant="success">
        <i class="far fa-check-circle mr-2"></i>
        {{ $t('Your documents have been approved.') }}
      </b-alert>
    </div>
    <div v-if="resource.document_status === 'rejected'" class="col-12">
      <b-alert class="mb-0 text-center" dismissible show variant="danger">
        <i class="far fa-times mr-2"></i>
        {{ $t('Some of your documents have been rejected, please check the comments.') }}
        <ul class="text-left pt-3">
          <li v-for="document in documentsErrors" :key="document.id"> {{ document.name }}: {{ document.document.comment }}</li>
        </ul>
      </b-alert>
    </div>
    <div class="col-12 mb-4">
      <div class="text-right">
        <button
          class="btn btn-primary btn-sm"
          @click="sendDocumentReview()"
          v-if="resource.document_status === 'pending'"
          :disabled="!isCompleted"
        >
          <i class="far fa-paper-plane mr-2"></i> {{ $t("Send Review") }}
        </button>
      </div>
    </div>

    <requirement-card
      :requirement="requirement"
      :resource="resourceTxt"
      :resource_id="resource_id"
      v-for="requirement in list"
      :key="requirement.id"
      v-on:fetch="fetchRequirements"
    ></requirement-card>

    <div class="col-12 mb-4">
      <div class="text-right">
        <button
          class="btn btn-primary btn-sm"
          @click="sendDocumentReview()"
          v-if="resource.document_status === 'pending'"
          :disabled="!isCompleted"
        >
          <i class="far fa-paper-plane mr-2"></i> {{ $t("Send Review") }}
        </button>
      </div>
    </div>
  </div>
  <div v-else class="col-12">
    <empty-results></empty-results>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RequirementCard from "@/views/documents/RequirementCard";
import Swal from "sweetalert2";

export default {
  name: "DocumentsGrid",
  components: {
    RequirementCard,
  },
  props: {
    resourceTxt: {
      type: String,
      required: true,
    },
    resource_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.fetchRequirements();
  },
  computed: {
    ...mapState("requirements", ["requirements", "resource"]),
    list() {
      return this.requirements.results;
    },
    isCompleted() {
      let items = this.requirements.results.filter((item) => item.required);
      let documents = this.requirements.results.filter((item) => {
        return item.required && item.document && item.document.files.length > 0
      });
      return items.length === documents.length;
    },
    documentsErrors() {
      return this.requirements.results.filter((item) => item.document.comment);
    }
  },
  methods: {
    ...mapActions("requirements", ["getRequirementsByProfile", "sendReview", "getResource"]),
    fetchRequirements() {
      this.getRequirementsByProfile({
        resource: this.resourceTxt,
        resource_id: this.resource_id,
      })
        .then((data) => {
          this.fetchResource()
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t(error.data.message), {
                title: "Error",
                variant: "danger",
                solid: true,
              });
        })
        .finally(() => (this.loading = false));
    },
    fetchResource() {
      this.getResource({resource: this.resourceTxt, resource_id: this.resource_id})
        .then((data) => {})
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    sendDocumentReview() {
      Swal.fire({
        title: this.$t("Confirm"),
        text: this.$t("Are you sure to send to review the documents?"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#D4DD55",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("Accept"),
        cancelButtonText: this.$t("Cancel"),
      }).then((result) => {
        if (result.value) {
          this.sendReview({
            resource: this.resourceTxt,
            resource_id: this.resource_id,
          })
            .then((data) => {
              this.fetchResource();

              if(data.hasOwnProperty('status'))
                if(data.status == 'ERROR')
                  reject(true);
              
              this.$root.$bvToast.toast(
                this.$t("The review of the documents has been requested.", {
                  resource: this.$t("Documents").toLowerCase(),
                }),
                {
                  title: this.$t("Success"),
                  variant: "success",
                  solid: true,
                }
              );
            })
            .catch((error) => {
              this.$bvToast.toast(this.$t("Something went wrong!"), {
                title: "Error",
                variant: "danger",
                solid: true,
              });
            })
            .finally(() => {});
        }
      });
    },
  },
};
</script>

<style scoped>
</style>