<template>
    <div class="ml-3" v-if="step < 7">
        <div class="d-flex justify-content-between align-items-center pb-2">
            <h4 class="font-weight-bolder">{{ $t('Create shipments') }} / <span class="text-primary">FTL</span></h4>
            <stepper :current="step" :total="5" @setStep="changeStep" :steps="steps" :isCompleted="validateStep" />
        </div>
        <hr>
        <div class="title">
            <div class="d-flex justify-content-start align-items-start">
                <div
                    class="badge badge-primary font-size-15 rounded-circle oval d-flex justify-content-center align-items-center">
                    {{ step }}</div>
                <h4 class="text-primary font-size-24 font-weight-bolder ml-2">
                    {{ $t(title) }}
                    <span class="font-size-13 text-gray-888 font-weight-lighter ml-2">{{ $t(subtitle) }}</span>
                </h4>
            </div>
            <!--
            <next-back-control :step="step" :total="5" @back="backControl" @next="nextControl"
                :nextBlocked="completed" />-->
        </div>
        <equipment-type v-if="step === 1" />
        <origin-view v-if="step === 2" @setStep="changeStep" @setTitle="setTitle" />
        <destination-view v-if="step === 3" @setStep="changeStep" @setTitle="setTitle" />
        <confirm-load-view v-if="step === 4" :next="detailStep" @preview="changeStep" :equipmentStep="equipmentStep"
            :originStep="pickUpstep" :destinationStep="deliveryStep" />
        <service-detail-rates @next="changeStep(6)" v-if="step === 5" />
        <details-view v-if="step === 6" @back="changeStep(5)" @next="changeStep(7)" :preview="detailStep" />
    </div>
    <div class="nopadding" v-else>
        <checkout class="padding-top-2" v-if="step === 7" />
        <summary-page class="padding-top-2" v-if="step === 8" />
        <special v-if="step === 9" />
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
/**
 * Controles del wizard del marketplace
 */
import Stepper from '@/components/marketplace/Stepper.vue';
/**
 * @todo Para conseguir el comportamiento del diseno podriamos agregar en este componente una propiedad
 * computada para que la podamos heredar y calcular en los componentes hijos, los puntos a considerar:
 *
 * - Evitar la re-renderizacion del componente padre
 * - Manipular la logica del cambio desde el componente padre
 * - Manipular la logica del cambio desde el componente hijo
 *
 * @note El componente queda desactivado por fines practicos
 */
//import NextBackControl from '../../components/common/NextBackControl.vue'

/**
 * Componentes controladores de vistas para el wizard
 */
import EquipmentType from '@/components/marketplace/equipment/EquipmentType.vue';
import OriginView from '@/components/marketplace/origin/OriginView.vue';
import DestinationView from '@/components/marketplace/destination/DestinationView.vue';
import DetailsView from '@/components/marketplace/details/DetailsView.vue';
import ConfirmLoadView from '../../components/marketplace/confirmation/ConfirmLoadView.vue';
import SummaryPage from '@/components/marketplace/details/SummaryPage.vue';
import Special from '@/components/marketplace/special/Special.vue';
//import RequestSent from '@/components/marketplace/special/RequestSent.vue';
import ServiceDetailRates from "../../components/marketplace/details/ServiceDetailsRates";
import Checkout from '@/components/marketplace/details/Checkout.vue';


/**
 * Este componente es el encargado de renderizar y mostrar el modulo de la creacion de un envio FTL
 *
 * Implementa componentes hijos con responsabilidades finitas de recoleccion de datos de acuerdo al store
 * marketplace
 *
 * @see /src/state/modules/marketplace.js
 */
export default {
    name: "ShipmentFTL",
    components: {
        ServiceDetailRates,
        Stepper,
        //NextBackControl,
        EquipmentType,
        OriginView,
        DestinationView,
        ConfirmLoadView,
        DetailsView,
        SummaryPage,
        Special,
        Checkout,
        //RequestSent
    },
    computed: {
        ...mapState('marketplace', ['step']),
        ...mapGetters('marketplace', ['equipmentValidate', 'originValidate', 'destinationValidate', 'confirmationValidate', 'rateValidate'])
    },
    data() {
        return {
            steps: [
                { step: 1, name: 'Packaging and equipment', icon: 'fas fa-box', },
                { step: 2, name: 'Pickup', icon: 'fas fa-map-marker-alt' },
                { step: 3, name: 'Delivery', icon: 'fas fa-truck-moving' },
                { step: 4, name: 'Confirmation', icon: 'fas fa-map-pin' },
                { step: 5, name: 'Rate', icon: 'fas fa-dollar-sign' },
                { step: 6, name: 'Accessories', icon: 'fa fa-list' },
            ],
            title: '',
            subtitle: '',
        }
    },
    methods: {
        ...mapMutations('marketplace', ['setStep']),
        equipmentStep() {
            this.setStep(1);
        },
        pickUpstep() {
            this.setStep(2)
        },
        deliveryStep() {
            this.setStep(3)
        },
        stopsSteps() {
            this.setStep(4)
        },
        detailStep() {
            this.setStep(5);
        },
        changeStep(step) {
            if (this.validateStep(step)) {
                this.setStep(step)
            }
            else {
                this.$bvToast.toast(this.$t('Necesitas confirmar este paso antes de continuar'), {
                    title: "Error",
                    variant: 'danger',
                    solid: true
                });
                return;
            }
        },
        validateStep(step) {
            switch (step) {
                case 1:
                    return true;
                case 2:
                    return this.equipmentValidate;
                case 3:
                    return this.originValidate;
                case 4:
                    return this.destinationValidate;
                case 5:
                    return this.confirmationValidate;
                case 6:
                    return this.rateValidate;
                case 7:
                    return true;
            }
        },
        //To do: Se necesita crear un modelo de navegacion que permita navegar entre
        // las views y los steps internos de cada view
        /*nextControl() {
            switch (this.step) {
                case 1:
                    this.componentStep = 1;
                    this.setStep(2);
                    break;
                case 2:
                case 3:
                    if (this.componentStep == 3) {
                        this.componentStep = 1;
                        this.setStep(this.step + 1);
                    }
                    else {
                        this.componentStep++;
                    }
                    break;
                case 4:
                    this.setStep(5);
                    break;

            }
        },
        backControl() {
            switch (this.step) {
                case 2:
                case 3:
                    if (this.componentStep == 1) {
                        this.componentStep = 3;
                        this.setStep(this.step - 1);
                    } else {
                        this.componentStep--;
                    }
                    break;
                case 4:
                    this.componentStep = 3;
                    this.setStep(3);
                case 5:
                    this.setStep(4);
                    break;

            }
        },*/
        setTitle(step) {
            this.subtitle = '';
            switch (step) {
                case 1: this.title = "Equipment type"; break;
                case 2: this.title = "Pickup address"; break;
                case 3: this.title = "Delivery address"; break;
                case 4: this.title = "¿Deseas confirmar los datos ingresados?"; break;
                case 5: this.title = "Detalles del servicio"; break;
                case 6: this.title = "Tarifa"; break;
                case 90: this.title = "Complete and confirm the pickup address"; break;
                case 91:
                    this.title = "Select the collection day";
                    this.subtitle = "*Select the date and time of collection on the calendar";
                    break;
                case 92: this.title = "Complete and confirm the delivery address"; break;
                case 93:
                    this.title = "Select the delivery date";
                    this.subtitle = "*Select the delivery date and time on the calendar";
                    break;
            }
        }
    },
    watch: {
        step(newValue, oldValue) {
            this.setTitle(Number(newValue));
        }
    },
    created() {
        this.setTitle(1);
    }
}
</script>

<style scoped lang="scss">
.title {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.oval {
    height: 30px;
    width: 30px;
}

.padding-top-2 {
    padding: 2rem 1rem;
}
</style>
