import axios from "../../api/client";


// initial state
export const state = () => ({
    fetching: false,
    operational_deductions : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    operational_deduction: null
});

// getters
export const getters = {};

// actions
export const actions = {
    getOperationalDeductions({commit}, params){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/operational-deductions`, {params: params})
                .then(response=>{
                    commit('setOperationalDeductions', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                 });
        });
    },
    getOperationalDeduction({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/operational-deductions/${params.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                 });
        });
    },
    storeOperationalDeduction({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/operational-deductions`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                 });
        });
    },
    deleteOperationalDeduction({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/operational-deductions/${params.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
};

// mutations
export const mutations = {
    setOperationalDeductions(state, data){
        state.operational_deductions = data;
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

