<template>
    <div class="row font-family-helvetica">
        <div class="col-12 text-center">
            <img src="@/assets/images/txp/triangle.png" width="60" class="mb-2" />
            <h3 class="font-weight-bolder d-flex justify-content-center align-items-center">
                <i class="ri-check-line text-primary mr-2" /> {{ $t('Shipment created successfully') }}
            </h3>
            <div>{{ $t('By official disposition and for security of the transfer of your') }}</div>
            <div>{{ $t('merchandise it is necessary to include your tax information and register the products') }}.
            </div>
            <button type="button"
                class="btn btn-primary_v2 text-uppercase font-size-12 font-weight-bolder px-5 mt-4"
                @click="goBack"
            >
                {{ $t('Go Back') }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
    name: "SummaryPage",
    methods: {
        ...mapMutations('marketplace', ['setStep', 'resetShipment']),
        ...mapMutations('shipmentform', ['resetShipmentform']),
        goBack(){
            this.resetShipment();
            this.resetShipmentform();
            this.$router.push({name:'shipments'});
        }
    }
}
</script>

<style scoped>
.ri-check-line { font-size: 2.5rem; }
</style>
