<template>
  <div class="">
    <div class="body" >
      <transition name="fade" >
        <div class="overlay" v-show="loading">
          <div class="overlay_container text-center">
            <img style="width: 70px;" src="/loading-image.gif"> <br>
            <h5 class="text-center">{{msg}}</h5>
          </div>
        </div>
      </transition>
      <div class="content_loading" :class="loading?'hidden_loading':''" ref="contentOverlay">
        <slot />
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "LoadingOverlay",
  data(){
    return {

    };
  },
  created(){

  },
  props:{
    loading:{
      type: Boolean,
      default(){
        return false;
      }
    },
    msg:{
      type: String,
      default(){
        return '';
      }
    },
  }
}
</script>

<style scoped>
.body{
  position: relative;
}
.overlay{
  /*background: rgb(255,255,255);*/
  background: linear-gradient(0deg, rgba(255,255,255,0.37718837535014005) 0%, rgba(255,255,255,0.6208858543417367) 100%);
  width: 100%;
  position: absolute;
  height: 100%;
  min-height: 300px;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.hidden_loading{
  opacity: .5!important;
  pointer-events: none!important;
  transition: all .2s;
}
.content_loading{
  opacity: 1;
  pointer-events: all;
  transition: all .2s;
}
</style>