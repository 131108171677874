<template>
<div>
  <page-header :title="$t($route.meta.title)"/>
  <loading v-if="loading" />

  <validation-observer v-if="form" ref="observer" v-slot="{ validate }">
    <div class="alert alert-info" role="alert">
      {{$t('Add all the required evidences to confirm delivery.')}}
    </div>
    <b-form @submit.stop.prevent="onSubmit(validate)">
      <dynamic-form v-model="form" />
      <div class="d-flex justify-content-end pb-5">
        <div>
          <button ref="submit" :disabled="loading" type="submit" class="btn btn-primary float-right">{{$t('Save')}}</button>
        </div>
      </div>
    </b-form>
  </validation-observer>
</div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import DynamicForm from "@/components/form/DynamicForm";
import formMixin from '@/mixins/formMixin';

export default {
  name: "ProofOfDeliveryForm",
  components:{
    DynamicForm
  },
  mixins: [formMixin],
  data(){
    return {
      form_id: null,
      form: null,
      loading: false
    }
  },
  computed:{
    ...mapState('profile',['profile']),
    ...mapState('accounts',['account']),
  },
  async created() {
    if(this.$route.meta.isAdmin){
        this.form_id = this.account.profile.drop_form_id;
    }else{
        this.form_id = this.profile.profile.drop_form_id;
    }
    if(!this.form_id){
      this.loading = true;
      // Create load form
      let payload = {name:'POD',type:'checklist',
        fields:[
          {
            title: this.$t('Proof of delivery'),
            help: null,
            required: true,
            visible: true,
            position: 1,
            type: 'pod'
          }
        ]};
      try{
        let form = await this.storeForm(payload);
        let account;
        if(this.$route.meta.isAdmin){
            account = _.cloneDeep(this.account);
        }else{
            account = _.cloneDeep(this.profile.account);
        }
       
        account.profile.drop_form_id = form.id;
        await this.updateAccount(account);
        await this.getProfile();
        this.form_id = form.id;
      }catch (e) {
        // TODO: Show error message
      }
    }
    this.init();
  },
  methods:{
    ...mapActions('forms',['storeForm','updateForm','getForm','getFields','storeField']),
    ...mapActions('accounts',['updateAccount','getAccount']),
    ...mapActions('profile',['getProfile']),
    init(){
      this.loading = true;
      this.getForm({id: this.form_id})
          .then(data=>{
            this.form = _.cloneDeep(data);
          })
          .catch(error =>{

          })
          .finally(()=>{
            this.loading = false;
          })
    },
    async onSubmit(validate) {
      if(!(await validate()) || this.loading){
        return;
      }

      this.loading = true;
      let form = _.cloneDeep(this.form);
      this.updateForm(form)
          .then(data=>{
            this.form.id = data.id
            if(this.$route.meta.isAdmin)
              this.getAccount({id: this.account.id});
            else
              this.getProfile();
            this.$root.$bvToast.toast(this.$t('Updated successfully'), {
              title: this.$t('Success'),
              variant: 'success',
              solid: true
            });
          })
          .catch(error =>{
            this.processServiceError(error);
          })
          .finally(()=>{
            this.loading = false;
          });
    }
  }
}
</script>

<style scoped>

</style>
