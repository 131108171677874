export default [
  {
    path: "loads",
    name: "loads",
    redirect: { name: "loads.index" },
    component: () => import("../views/Content"),
    meta: {
      breadcrumb: { text: "Loads", to: { name: "loads.index" } },
    },
    children: [
      {
        path: "",
        name: "loads.index",
        meta: {
          bgWhite: true,
          title: "Loads",
          filters: {},
        },
        component: () => import("../views/loads/Loads"),
      },
      {
        path: "add",
        name: "loads.add",
        component: () => import("../views/loads/LoadForm"),
        meta: {
          title: "New load",
          breadcrumb: { text: "New load", to: { name: "loads.index" } },
          routes: { list: "loads.detail" },
        },
      },
      {
        path: ":load_id",
        name: "loads.detail",
        component: () => import("../views/loads/LoadContainer"),
        redirect: { name: "loads.detail.general" },
        meta: {
          title: "Load",
          breadcrumb: {
            text: "Load",
            param: "load_id",
            to: { name: "loads.detail.general" },
          },
        },
        children: [
          {
            path: "general",
            name: "loads.detail.general",
            component: () => import("../views/loads/LoadGeneral"),
            meta: { title: "Load", type: "load" },
          },
          {
            path: "apply",
            name: "loads.detail.apply",
            component: () => import("../views/loads/LoadAssign"),
            meta: { title: "Load", type: "load" },
          },
          {
            path: "events",
            name: "loads.detail.events",
            meta: { title: "Load" },
          },
          {
            path: "pod",
            name: "loads.detail.pod",
            component: () => import("../views/loads/POD"),
            meta: { title: "Load" },
          },
          {
            path: "checklist",
            name: "loads.detail.checklist",
            component: () => import("../views/Wrapper"),
            redirect: { name: "loads.detail.checklist.index" },
            children: [
              {
                path: "",
                name: "loads.detail.checklist.index",
                component: () => import("../views/loads/Checklist"),
                meta: { title: "Load" },
              },
              {
                path: ":stop_id/add",
                name: "loads.detail.checklist.add",
                component: () => import("../views/loads/ChecklistForm"),
                meta: { title: "Checklist" },
              },
              {
                path: ":stop_id/:checklist_id/edit",
                name: "loads.detail.checklist.edit",
                component: () => import("../views/loads/ChecklistForm"),
                meta: { title: "Edit" },
              },
            ],
          },
          {
            path: "documents",
            name: "loads.detail.documents",
            component: () => import("../views/loads/LoadDocument"),
            meta: { title: "Documents" },
          },
          {
            path: "invoices",
            name: "loads.detail.invoices",
            component: () => import("../views/loads/LoadDocument"),
            meta: { type: "invoice", title: "Documents" },
          },
          {
            path: "tenders",
            name: "loads.detail.tenders",
            component: () => import("../views/loads/LoadTender"),
            meta: { title: "Load" },
          },
          {
            path: "costs",
            name: "loads.detail.costs",
            meta: { title: "Load", filters: {} },
            component: () => import("../views/loads/Costs"),
          },
          {
            path: "extracosts",
            name: "loads.detail.extracosts",
            component: () => import("../views/loads/Extracosts"),
            meta: { title: "Extra charges", bgWhite: true },
          },
          {
            path: "expenses",
            name: "loads.detail.expenses",
            meta: { title: "Load", filters: { type: "travel" }, bgWhite: true },
            component: () => import("../views/loads/Expenses"),
          },
          {
            path: "settlement",
            name: "loads.detail.settlement",
            meta: { title: "Load", filters: {}, bgWhite: true },
            component: () => import("../views/settlement/Settlement"),
          },
          {
            path: "shipments",
            name: "loads.detail.shipments",
            meta: { title: "Load", filters: {}, bgWhite: true },
            component: () => import("../views/loads/LoadShipments"),
          },
          {
            path: "payment",
            name: "loads.detail.payment",
            component: () => import("../views/loads/LoadPayment"),
            meta: { title: "Load" },
          },
          {
            path: "issues",
            name: "loads.detail.checklistcontainer",
            component: () => import("../views/Wrapper"),
            redirect: { name: "loads.detail.issues" },

            children: [
              {
                path: "",
                name: "loads.detail.issues",
                meta: { title: "Load" },
                component: () => import("../views/issues/Issues"),
              },
              {
                path: "new",
                name: "loads.detail.issues.new",
                meta: { title: "Load" },
                component: () => import("../views/issues/EditIssue"),
              },
              {
                path: ":issue_id",
                name: "loads.detail.issues.edit",
                meta: { title: "Load" },
                component: () => import("../views/issues/EditIssue"),
              },
            ],
          },
          {
            path: "bol",
            name: "loads.detail.bol",
            meta: { title: "Load", filters: {}, bgWhite: true },
            component: () => import("../views/bol/Bol"),
          },
          {
            path: "externalrequirements",
            name: "loads.detail.externalrequirements",
            meta: { title: "Load" },
            component: () =>
              import("../views/externalrequirements/externalRequirementsLoads"),
          },
          {
            path: "returns",
            name: "loads.detail.returns",
            component: () => import("../views/Wrapper"),
            redirect: { name: "loads.detail.returns" },
            children: [
              {
                path: "",
                name: "loads.detail.returns",
                meta: { title: "Load" },
                component: () => import("../views/loads/returns/List"),
              },
              {
                path: "new",
                name: "loads.detail.returns.new",
                meta: { title: "Load" },
                component: () => import("../views/loads/returns/New"),
              },
              {
                path: ":shipment_return",
                name: "loads.detail.returns.edit",
                meta: { title: "Load" },
                component: () => import("../views/loads/returns/Update"),
              },
            ]
          },
        ],
      },
      {
        path: ":load_id/edit",
        name: "loads.edit",
        component: () => import("../views/loads/LoadForm"),
        meta: {
          title: "Edit load",
          breadcrumb: {
            text: "Edit load",
            param: "load_id",
            to: { name: "loads.index" },
          },
          routes: { list: "loads.detail" },
        },
      },
    ],
  },
];
