<template>
  <div class="step-wrapper pl-3 pr-3" >
    <button class="btn btn-primary" @click="lastStep()" :disabled="firststep">{{ $t("Back") }}</button>
    <button class="btn btn-danger" @click="cancel()" >{{ $t("Cancel") }}</button>
    <button class="btn btn-primary" @click="nextStep()" v-show="!laststep">{{ $t("Next") }}</button>
    <button class="btn btn-primary" @click="save()" v-if="laststep">{{ $t("Save") }}</button>
  </div>
</template>

<script>
export default {
  name: "wizardButtons",
  props: ['stepcount', 'currentstep'],
  computed: {
    firststep: function() {
      return (this.currentstep === 0)
    },

    laststep: function() {
      return (this.currentstep === this.stepcount-1)
    }
  },
  methods: {
    nextStep: function() {
      this.$emit('step-change', this.currentstep + 1)
    },

    lastStep: function() {
      this.$emit('step-change', this.currentstep - 1)
    },

    save: function() {
      this.$emit('step-change', this.stepcount)
    },

    cancel: function() {
      this.$emit('step-cancel')
    }
  }
}
</script>

<style scoped>
.step-wrapper{
  display: flex;
  justify-content: space-between;
}
</style>