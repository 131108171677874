<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                <tr class="">
                    <!--
                    <th class="text-center" style="width: 48px;">
                        <b-form-checkbox
                                v-model="selectAll"
                                name="checkbox-1"
                                button-variant="secondary"
                        >
                        </b-form-checkbox>
                    </th>
                    -->
                    <th class="text-center" style="width:48px;"></th>
                    <th class="text-center">ID</th>
                    <th class="text-center">{{$t('Type')}}</th>
                    <th class="text-center">{{$t('Name')}}</th>
                    <th class="text-center">{{$t('Created at')}}</th>
                    <th class="text-center">{{$t('Trucks')}}</th>
                    <th class="text-center">{{$t('Trailers')}}</th>
                    <th class="text-center">{{$t('Drivers')}}</th>
                    <th class="text-center">{{$t('TMS ID')}}</th>
                    <th class="text-center">{{$t('SAP ID')}}</th>
                    <th class="text-center">{{$t('Document status')}}</th>
                    <th class="text-center">{{$t('Account')}}</th>
                    <th class="text-center" style="min-width: 90px;max-width:100px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fetching">
                    <td colspan="100%">
                        <loading />
                    </td>
                </tr>
                <template v-for="item in list.results">
                    <tr v-if="(init || page === list.current_page) && item.account" :key="'load-'+item.id"  class="truck-row">
                        <!--
                        <td>
                            <b-form-checkbox
                                    v-model="selected"
                                    name="checkbox-1"
                                    :value="item.id"
                            >
                            </b-form-checkbox>
                        </td>
                        -->
                        <td class="text-center">
                             <img v-if="item.account.logo" :src="item.account.logo" alt width="32" height="32" style="object-fit: cover;" />
                            <img v-else src="@/assets/images/txp/building.png" width="32px" />
                        </td>
                        <td class="text-center" style="width:110px;">
                            <router-link :to="{name:'carriers.detail', params:{carrier_id: item.id}}">
                                <b>{{item.id}}</b>
                            </router-link>
                        </td>
                        <td class="text-center">
                             <span class="badge mr-1 " :class="[item.account.is_internal ? 'badge-outline-secondary' : 'badge-outline-primary']">
                                    {{$t(item.account.is_internal ? 'Internal' : 'Public')}}
                            </span>
                        </td>
                        <td class="text-center">
                            <router-link v-if="item.account" :to="{name:'carriers.detail',params:{carrier_id: item.id}}" >
                                <div class="two-lines">
                                     {{item.name}}
                                </div>
                            </router-link>
                        </td>
                        <td class="text-center">{{item.account.created_at | timestamp('YYYY-MM-DD')}}</td>
                        <td class="text-center">
                           {{item.trucks_count}}
                        </td>
                        <td class="text-center">{{item.trailers_count}}</td>
                        <td class="text-center">{{item.drivers_count}}</td>
                        <td class="text-center">{{item.tms_id ? item.tms_id : '- -'}}</td>
                        <td class="text-center">{{item.sap_id ? item.sap_id : '- -'}}</td>
                        <td class="text-center">
                             <span class="badge mr-1 " :class="[getDocumentStatusColor(item.document_status)]">
                                    {{$t('status.documents.'+item.document_status)}}
                            </span>
                        </td>
                        <td class="text-center">
                            <span class="badge " :class="['badge-outline-'+ (item.account.is_active ? 'success':'danger')]">
                                <i class="far " :class="[(item.account.is_active ? 'fa-check-circle':'fa-times-circle')]" />
                                {{item.account.is_active ? $t('enabled'):$t('disabled')}}
                            </span>
                        </td>
                        <td class="text-center">
                            <template v-if="!isDocumentModule">
                                <a v-can="'users.delete'" @click="confirmDelete(item)" href="javascript:void(0);" class="text-danger mr-2">
                                    <i v-b-tooltip.hover :title="$t('Delete')" class="far fa-trash-alt btn-icon"  />
                                </a>
                            </template>
                            <template v-else>
                                <router-link v-can="'documents.read'" :to="{name:'documents.resource.detail', params:{resource: 'carriers', resource_id: item.id}}" class="mr-2">
                                    <i v-b-tooltip.hover :title="$t('Documents')" class="far fa-book-alt btn-icon"  />
                                </router-link>
                            </template>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div  v-if="list.total > 0"  class="row align-items-center">
            <div class="col-md-6 col-sm-6 ">
                <b-pagination
                        v-model="page"
                        :total-rows="list.total"
                        :per-page="list.per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        pills
                ></b-pagination>
            </div>
            <div class="col-md-6 col-sm-6 text-sm-right">
                <!-- <p>{{$t('pagination', {from: list.from, to: list.to, total: list.total})}}</p> -->
            </div>
        </div>
        <div v-if="list.total === 0 && !fetching" class="row">
            <div class="col">
                <empty-results />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    import Loading from "../common/Loading";
    import selectAllMixin from "@/mixins/selectAllMixin";
    import Swal from "sweetalert2";

    export default {
        name: "CarrierTable",
        mixins:[selectAllMixin],
        components: {
            'loading':Loading
        },
        props: {
            filters : {
                type: Object,
                default(){
                    return {}
                }
            },
            isDocumentModule: null
        },
        data(){
            return {
                page: 1,
                init: false,
                truck: null,
                fetchingTruck: false,
            }
        },
        computed: {
            ...mapState('accounts', ['carriers','fetching']),
            ...mapState('profile',['profile']),
            list(){
                return this.carriers
            }
        },
        created() {
            this.page = this.filters.page ? Number(this.filters.page) : 1;
        },
        watch: {
            filters : {
                deep: true,
                handler(newVal, oldVal){
                    if(!_.isEqual(newVal, oldVal) || this.init === false){
                        this.fetchData();
                    }
                }
            },
            page(newVal){
                this.$emit('page-change', newVal);
            }
        },
        methods: {
            ...mapActions('accounts',['getCarriers','deleteAccount']),
            fetchData(){
                this.getCarriers(_.cloneDeep(this.filters))
                    .then(response=>{
                    })
                    .catch(error =>{
                    })
                    .finally(()=>{
                        this.init = true;
                    })
            },
            confirmDelete(item){
                Swal.fire({
                    title: this.$t("Are you sure?"),
                    text: this.$t("You won't be able to revert this!"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("Yes, delete it!"),
                    cancelButtonText: this.$t("Cancel")
                }).then(result => {
                    if(result.value) {
                        this.deleteAccount({id: item.account.id})
                            .then(data=>{
                                this.fetchData();
                                this.$root.$bvToast.toast(this.$t("Your item has been deleted.", {resource: this.$t("Carrier").toLowerCase()}), {
                                        title: this.$t('Success'),
                                        variant: 'success',
                                        solid: true
                                        });
                            })
                            .catch(error =>{
                                console.log(error);
                                Swal.fire(
                                    "Error",
                                    this.$t("Something went wrong!"),
                                    "error"
                                );
                            });
                    }
                });
            },
            getDocumentStatusColor(status){
                if(status === 'accepted'){
                    return 'badge-outline-success';
                }
                if(status === 'rejected'){
                    return 'badge-outline-danger';
                }
                if(status === 'pending'){
                    return 'badge-outline-secondary';
                }
                if(status === 'in_review'){
                    return 'badge-outline-warning';
                }
            },
        }
    }
</script>

<style scoped>
    .truck-row>td{
        vertical-align: middle !important;
        height: 50px!important;
        max-height: 50px!important;
        text-align: center !important;
    }
</style>
