import axios from "../../api/client";
import axiossls from "../../api/clientsls";
import moment from "moment";


// initial state
export const state = () => ({
    fetching: false,
    loads : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    expenses: {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    status: [],
    stops: [],
    events: [],
    load: null,
    checklist : [],
    last_filters: {},
    documents: [],
    issues_pending: [],
    settlementCatalog: [],
    merchandises: {},
    bol: {},
    settlementCatalogSelectData: [],
    expense: null,
    fetchingCosts: false,
    invoices: [],
    load_shipments: [],
    loadVin: []
});

// getters
export const getters = {
    getByCategory: (state) => (category) => {
        return state.settlementCatalogSelectData.filter(settlementCatalog => {
            let existe = settlementCatalog.category.filter((e) => {
                return e.value == category
            });
            return existe.length>=1
        })
    }
};

// actions
export const actions = {
    getFares({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/fares`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    searchLoads({commit}, payload){
        return new Promise((resolve, reject) => {
            axiossls.post('/marketplace/loads/search', payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getSearchLoad({commit}, params){
        return new Promise((resolve, reject) => {
            axiossls.get(`/marketplace/loads/${params.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
        });
    },
    fetchLoads({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/loads', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getLoads({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/loads',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setLoads', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    getLoadsByTenders({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/tenders/loads',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setLoads', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getSettlementCatalog({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/settlementCatalog',{params: query})
                .then(response=>{
                    commit('setSettlementCatalog', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getMerchandises({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/shipments/${query.id}/merchandises`,{params: query})
                .then(response=>{
                    commit('setMerchandises', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateMerchandises({commit}, query){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/shipments/${query.id}/merchandises`,query)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteMerchandises({commit}, query){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/shipments/${query.id}/merchandises/${query.merchandiseId}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getSettlementCatalogSelectData({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/settlementCatalog',{params: query})
                .then(response=>{
                    commit('setSettlementCatalogSelectData', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getExpenseCategory({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/settlementCatalog/getExpenseCategory',{params: query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getRecommendedLoads({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/loadsRecommended',{params: query})
                .then(response=>{
                    commit('setLoads', response.data )
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeSettlementCatalog({commit}, query){
        return new Promise((resolve, reject) => {
            axios.post('/api/v2/settlementCatalog',{...query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateSettlementCatalog({commit}, query){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/settlementCatalog/${query.id}`,{...query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteSettlementCatalog({commit}, query){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/settlementCatalog/${query.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    importSettlementCatalog({commit}, payload){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for(let [key, value] of Object.entries(payload)){
                formData.append(key, value);
            }
            axios.post('/api/v2/settlementCatalog/import', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then((response)=>{
                    resolve(response.data);
                })
                .catch((error)=>{
                    reject(error.response);
                });
        });
    },
    storeLoad({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getLoad({commit, dispatch}, params){
        //commit('clearLoad');
        dispatch('getStops', params);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}`)
                .then(response=>{
                    commit('setLoad', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updatePosition({commit, state}, payload){
        if(!state.load)
            return;
        if(payload.load_id === state.load.id){
            let load = _.cloneDeep(state.load);
            load.position = payload.position;
            commit('setLoad', load);
        }
    },
    updateLoad({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/loads/${payload.id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateLoadFields({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/loads/${payload.id}/fields`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteLoad({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/loads/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getStops({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/stops`)
                .then(response=>{
                    commit('setStops', response.data.results);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    fetchStops({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/stops`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getStatus({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/status`)
                .then(response=>{
                    commit('setStatus', response.data.status);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getAllChecklist({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/checklist`)
                .then(response=>{
                    commit('setAllChecklist', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    downloadPOD({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/checklist/download`)
                .then(response=>{
                    const fileLink = document.createElement('a');
                    fileLink.href = response.data.url;
                    fileLink.setAttribute('download', response.data.url.substring(response.data.url.lastIndexOf('/')+1) );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    resolve(response.data);

                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    podCompleted({commit}, params){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${params.id}/checklist/completed`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeChecklist({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.load_id}/stops/${payload.stop_id}/checklist`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getChecklist({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.load_id}/stops/${params.stop_id}/checklist/${params.checklist_id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteChecklist({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/loads/${payload.load_id}/stops/${payload.stop_id}/checklist/${payload.checklist_id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeChecklistAnswer({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.load_id}/stops/${payload.stop_id}/checklist/${payload.checklist_id}/answer`,
                {field_id: payload.field_id, value: payload.value})
                .then(response=>{
                    let checklist = _.cloneDeep(state.checklist);
                    let checklistIndex = checklist.findIndex(item => item.id === payload.checklist_id);
                    let fieldIndex = checklist[checklistIndex].form.fields.findIndex(item => item.id === payload.field_id);
                    checklist[checklistIndex].form.fields[fieldIndex].answer = payload.value;
                    commit('setAllChecklist', checklist);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getDocuments({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${payload.id}/documents`,
                {params: {...payload,per_page: 100000} })
                .then(response=>{
                    commit('setDocuments', response.data.results);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeDocument({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.id}/documents`,
                {params: payload })
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteDocument({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/loads/${params.id}/documents/${params.document_id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getEvents({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${payload.id}/events`,
                {params: {...payload,per_page: 100000} })
                .then(response=>{
                    commit('setEvents', response.data.results);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTenders({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${payload.id}/tenders`)
                .then(response=>{
                    commit('setTenders', response.data.results);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    acceptedTender({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.id}/tenders/${payload.tender_id}/accept`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    rejectedTender({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.id}/tenders/${payload.tender_id}/reject`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeTender({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.id}/tenders`,payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    assignTruckAndDriver({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.id}/assign`,payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    changeStatus({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.id}/stops/${payload.stop_id}/status`,payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    generateDocuments({}, payload) {
        return new Promise((resolve, reject) => {
            let request = {}

            if (payload.type === 'bol')
                request = axios.post(`/api/v2/loads/${payload.id}/generate-bol`)
            else
                request = axios.post(`/api/v2/loads/${payload.id}/generate-document/${payload.type}`)

            request
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },
    getExpenses({commit}, query){
        commit('setFetchingCosts', true);
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${query.load_id}/expenses`,  {params:query})
                .then(response=>{
                    commit('setExpenses', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetchingCosts', false);
                });
        });
    },
    getIssuesPending({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${query.load_id}/issues/pending`,  {params:query})
                .then(response=>{
                    commit('setIssuesPending', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{

                });
        });
    },
    getExpenseByID({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${query.load_id}/expenses/${query.id}`)
                .then(response=>{
                    commit('setExpense', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log('error');
                    reject(error.response)
                });
        });
    },
    deleteExpense({commit}, query){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/loads/${query.load_id}/expenses/${query.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    acceptedExpense({commit}, query) {
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/loads/${query.load_id}/expenses/${query.expense_id}`,query.form)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    rejectedExpense({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/loads/${payload.load_id}/expenses/${payload.expense_id}`,payload.form)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateExpense({commit, state}, query){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/loads/${query.load_id}/expenses/${query.form.id}`, query.form)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    storeExpense({commit, state}, query){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${query.load_id}/expenses`, query.form)
                .then(response=>{
                    console.log("response")
                    console.log(response.data)
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteImageExpense({commit}, query){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/loads/${query.load_id}/expenses/${query.expense_id}/files/${query.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getBonus({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${query.load_id}/bonus`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    makePendingTransfers({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.load_id}/expenses/advance`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    importLoads({commit}, payload){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for(let [key, value] of Object.entries(payload)){
                formData.append(key, value);
            }
            axios.post('/api/v2/loads/import', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then((response)=>{
                    resolve(response.data);
                })
                .catch((error)=>{
                    reject(error.response);
                });
        });
    },
    exportLoads({commit}, query){
        return new Promise((resolve, reject) => {
           axios({
               url:'/api/v2/loads/export',
               method:'get',
               responseType: 'blob',
               params: query
               }).then(response=>{
                   const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   const fileLink = document.createElement('a');
                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', `loads-${moment().unix()}.xlsx`);
                   document.body.appendChild(fileLink);
                   fileLink.click();
                   resolve();
               })
               .catch(error =>{
                   console.log(error);
                   reject(error.response)
               });
       });
   },
   getSalaryAdvance({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.load_id}/salary-advance`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getLoadShipments({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/shipments`)
                .then(response=>{
                    resolve(response.data);
                    commit('setLoadShipments', response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
   getRelationship({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.load_id}/relationship/${params.type}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    acceptSalaryAdvance({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.load_id}/salary-advance/${payload.advance_id}/accept`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    rejectSalaryAdvance({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.load_id}/salary-advance/${payload.advance_id}/reject`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getPayment({commit}, params) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${params.id}/payment`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    pay({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.id}/payment`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    requestFactoring({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/loads/${payload.id}/factoring`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    cancelFactoring({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/loads/${payload.id}/factoring/${payload.factoring_id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    exportBol({commit}, payload){
        return new Promise((resolve, reject) => {
           axios({
               url:`/api/v2/loads/${payload.id}/bol/export`,
               method:'get',
               responseType: 'blob',
               }).then(response=>{
                   const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   const fileLink = document.createElement('a');
                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', `bol.xlsx`);
                   document.body.appendChild(fileLink);
                   fileLink.click();
                   resolve();
               })
               .catch(error =>{
                   reject(error.response)
               });
       });
   },
   getBol({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${payload.id}/bol`)
                .then(response=>{
                    commit('setBol', response.data.bol);
                    resolve(response.data.bol);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getInvoices({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/loads/${payload.id}/invoices`)
                .then(response=>{
                    commit('setInvoices', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getUpcomingLoads({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/upcoming-loads', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getInProgressLoads({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/inprogress-loads', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getCalendarLoads({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/calendar-loads', {params: query})
                .then(response=>{
                    resolve(response.data);
                    commit('setLoads', response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    updateMirrorAccount({ commit }, params) {
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            const { id, ...payload } = params;
            axios
                .put(`/api/v2/loads/${id}/mirror`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response);
                })
                .finally(() => {
                    commit('setFetching', false);
                });
        });
    },
    storeLoadVin({ commit, state }, payload) {
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios
                .post(`/staff/loads/${state.load._id}/vins`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response);
                })
                .finally(() => {
                    commit('setFetching', false);
                });
        });
    },
    listLoadVin({ commit, state }, _) {
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios
                .get(`/staff/loads/${state.load._id}/vins`)
                .then(response => {
                    commit('setLoadVin', response.data.data);
                    resolve(response.data);
                })
                .catch(error => {
                    commit('setLoadVin', []);
                    reject(error.response);
                })
                .finally(() => {
                    commit('setFetching', false);
                });
        });
    },
    deleteLoadVin({ commit, state }, loadVinId) {
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios
                .delete(`/staff/loads/${state.load._id}/vins/${loadVinId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response);
                })
                .finally(() => {
                    commit('setFetching', false);
                });
        });
    },
};

// mutations
export const mutations = {
    setFetching(state, data){
        state.fetching = data;
    },
    setFetchingCosts(state, data){
        state.fetchingCosts = data;
    },
    setLoads(state, data){
        state.loads = data;
    },
    setLoad(state, data){
        state.load = data;
    },
    clearLoad(state){
        state.stops =  [];
        state.checklist =  [];
    },
    setStops(state, data){
        state.stops = data;
    },
    setStatus(state, data){
        state.status = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    setIssuesPending(state, data){
        state.issues_pending = data;
    },
    setAllChecklist(state, data){
        state.checklist = data;
    },
    setDocuments(state, data){
        state.documents = data;
    },
    setEvents(state, data){
        state.events = data;
    },
    setTenders(state, data){
        state.tenders = data;
    },
    setExpenses(state, data){
        state.expenses = data;
    },
    setSettlementCatalog(state, data){
        state.settlementCatalog = data;
    },
    setMerchandises(state, data){
        state.merchandises = data;
    },
    setSettlementCatalogSelectData(state, data){
        state.settlementCatalogSelectData = data.results;
    },
    expenses(state, data){
        state.expense = data;
    },
    setBol(state, data){
        state.bol = data;
    },
    setInvoices(state, data){
        state.invoices = data;
    },
    setLoadShipments(state, data){
        state.load_shipments = data;
    },
    setLoadVin(state, data) {
        state.loadVin = data;
    }
};
