import axios from "@/api/client";
import moment from 'moment';

// initial state
export const state = () => ({
    fetching: false,
    teams : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    team: null,
    last_filters: {},
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchTeams({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/teams', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTeams({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/teams',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setTeams', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    storeTeam({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/teams`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTeam({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/teams/${params.id}`)
                .then(response=>{
                    commit('setTeam', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateTeam({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/teams/${payload.id}`, payload)
                .then(response=>{
                    let items = _.cloneDeep(state.teams);
                    let index = items.results.findIndex(item => item.id === response.data.id);
                    if(index > -1){
                        items.results[index] = response.data;
                        commit('setTeams', items);
                    }
                    resolve(response.data);
                })
                .catch(error =>{
                    console.log(error)
                    reject(error.response)
                });
        });
    },
    deleteTeam({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/teams/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteManyTeams({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/teams/bulk`,{params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    }
};

// mutations
export const mutations = {
    setTeams(state, data){
        state.teams = data;
    },
    setTeam(state, data){
        state.team = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

