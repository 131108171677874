<template>

  <div class="row mt-4">
      <div class="col-xl-6 offset-xl-3 col-sm-12  col-12 col-xl-4">
          <div class="card">
              <div class="card-header">
                  <div class="d-flex justify-content-betweenr">
                      <h6 class="mb-0" >{{$t('Issue')}}</h6>
                  </div>
              </div>
              <div class="form-fields">
                  <loading v-if="fetchingIssue"/>
                  <validation-observer v-else ref="observer" v-slot="{ validate }">
                      <b-form @submit.stop.prevent="onSubmit(validate)">
                          <div class="row">
                              <div class="col-md-12">
                                      <form-input
                                          :title="$t('Category')"
                                          v-model="form.category"
                                          rules="required"
                                          type="select"
                                          :options="issue_categories"
                                          name="type"
                                          :disabled="update"
                                          :placeholder="$t('Select an option')"
                                          v-on:change="onCategoryChange"
                                          selectValue="id"
                                          selectText="name"
                                          :autoSelectFirst="selectFirstCategory"
                                      />

                                      <form-input
                                          :title="$t('Subject')"
                                          v-model="form.type"
                                          rules="required"
                                          type="select"
                                          :options="issueTypes"
                                          name="type"
                                          :disabled="!form.category || update"
                                          :placeholder="$t('Select option')"
                                          v-on:change="onTypeChange"
                                          :autoSelectFirst="selectFirstCatalog"
                                      />

                                      <form-input
                                          :title="$t('Description')"
                                          v-model="form.description"
                                          rules="required"
                                          type="textarea"
                                          name="description"
                                          layout="vertical"
                                          :disabled="!form.type"
                                          :rows="2"
                                      />
                                      <loading v-if="fetchingForm"/>
                                      <template v-else v-for="(field, idx) in customForm.fields">
                                          <table-input v-if="field.type === 'table'"
                                              :key="'field-'+field.id"
                                              :field="field"
                                              v-model="form.custom_fields[field.id]"
                                               />
                                          <form-input
                                              v-else
                                              :key="'field-'+field.id"
                                              :title="field.title"
                                              :help="field.help"
                                              v-model="form.custom_fields[field.id]"
                                              :type="field.type"
                                              :name="'field.'+idx+'.title'"
                                              :options="field.options"
                                              :rules="field.required == true ? 'required':'' "
                                          />
                                      </template>

                                      <file-input
                                              class="p-4"
                                              :path="uploadPath"
                                              :options="{dictDefaultMessage: '', acceptedFiles: 'image/*'}"
                                              v-on:success="fileUpload" v-on:error="errorUpload"
                                      />
                              </div>
                              <div class="col-md-12">
                              <div  class="gallery_list">
                                  <div class="gallery_list_item" :key="image.id" v-for="(image,index) in images"  >
                                  <img height="35px" width="35px" :key="index" :src="image.path"/>
                                  <a  @click="confirmImageDelete(image)" href="javascript:void(0);" class="text-danger pointer-event delete-img">
                                      <i class="far fa-times-circle btn-icon btn-icon-mini"></i>
                                  </a>
                                  </div>
                              </div>
                              </div>
                          </div>

                          <div class="d-flex justify-content-end m-4">
                              <router-link :to="{name: 'loads.detail.issues'}" class="btn btn btn-light mr-3">
                                  {{$t('Cancel')}}
                              </router-link>
                              <button type="submit" :disabled="submit" class="btn btn-primary">{{$t('Save')}}</button>
                          </div>

                      </b-form>
                  </validation-observer>
              </div>
          </div>

      </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations, mapGetters} from 'vuex';
import FileInput from "@/components/form/FileInput";
import TableInput from '@/components/form/TableInput';
import {useEventsTracker} from "@/composables/analytics";

export default {
  name: 'EditIssue',
  components:{
      FileInput,
      TableInput
  },
  props: {
      issue_id:{
        type: Number,
        default(){
          return null
        }
      },
  },
  data() {
      return {
          fetchingIssue : false,
          images: [],
          submit: false,
          isUpdated: false,
          form :{
            type: null,
            description: null,
            address: {},
            files: [],
          },
          fetchingForm: false,
          customForm: {fields:[]},
          customFields: {},
          type: null,
          issue_categories: [],
          selectFirstCategory: false,
          selectFirstCatalog: false,
          issue_types: []
      }
  },
  computed: {
      ...mapState('issues',['types']),
      ...mapGetters('reverselogistic', ['isReverseLogistic', 'issueCategories', 'issueCatalogs', 'issueCatalog']),
      issueTypes(){
          if(this.isReverseLogistic){
              this.selectFirstCatalog = true
              let item = this.issueCatalogs.find(e => e.id === this.issueCatalog);
              return [{text: item.name, value: item.id}];
          }
          return this.types.map(item =>({text: item.name, value: item.id}))
      },
      uploadPath(){
          if (this.update){
            return '/api/v2/loads/'+this.load_id+'/issues/'+this.$route.params.issue_id+'/files';
          }else{
            return '/api/v2/upload';
          }
      },
      load_id(){
          return this.$route.params.load_id
      },
      update(){
        return !!this.$route.params.issue_id
      }
  },
  mounted(){
    if(this.update){
      this.fetchIssue(this.$route.params.issue_id, this.load_id);
    }
  },
  methods:{
      ...mapActions('issues',['getIssueTypes','storeIssue','updateIssue','getIssue','deleteFile','getIssueCategories','getForm']),
      ...mapMutations('issues',['setIssueTypes']),
      ...mapMutations('reverselogistic', ['setIsReverseLogistic']),
      async onSubmit(validate){
        console.log("On submit")
        if(!(await validate())){
          window.scrollTo(0, 0);
          this.$bvToast.toast(this.$t('Please, check the form'), {
            title: "Error",
            variant: 'danger',
            solid: true
              });
          return;
        }
        if(this.submit === true)
          return;

        this.submit = true;
        let form = _.cloneDeep(this.form);
        form.load_id = this.load_id
        if (this.update){
          let formUpdate = {
            type:form.type,
            description:form.description,
            load_id:form.load_id,
            id:form.id,
            custom_fields: form.custom_fields}
          this.updateIssue(formUpdate)
              .then(data=>{
                this.isUpdated = true;
                this.$router.push({name: 'loads.detail.issues'});
              })
              .catch(error =>{

              })
              .finally(()=>{
                this.submit = false;
              });
        }else{
          this.storeIssue(form)
              .then(data=>{
                this.isUpdated = true;
                this.$router.push({name: 'loads.detail.issues'});
              })
              .catch(error =>{

              })
              .finally(()=>{
                this.submit = false;
              });
        }

      },
      fileUpload(data){
        if (!this.update){
          this.form.files = [...this.form.files,data.id]
        }
        this.images = [...this.images,data]
        this.isUpdated = true;
      },
      errorUpload(data){
        alert("IMAGEN NO SE PUEDO SUBIR");
      },
      confirmImageDelete(data){
        this.deleteFile({id:data.id})
            .then(response=>{
              this.isUpdated = true;
              this.images = this.images.filter(item=> item.id !== data.id);
            })
            .catch(error =>{

            })
            .finally(()=>{
              this.submit = false;
            });
      },
      fetchIssue(id,load_id){
        this.fetchingIssue = true;
        this.getIssue({id:id,load_id:load_id}).then((data)=>{
          let customFields = {};
          if(data.custom_fields){
            for(let field of data.custom_fields){
              if(field.type === 'table'){
                customFields[field.field_id] = field.value.map(item => (
                  item.reduce((previous, current)=>({...previous, [current.field_id]: current.value}), {})
                ))
              }else{
                customFields[field.field_id] = field.value;
              }
            }
          }
          data.custom_fields = customFields;
          this.type = _.cloneDeep(data.type);
          this.form = {
            ...data,
            type: data.type.id,
            category: data.category.id,
            custom_fields: customFields
          };
          this.images = data.files
          this.getIssueTypes({load_id: this.load_id, category_id: this.form.category}).then(data => {
            this.fetchingIssue = false;
          })
        });
      },
      onCategoryChange(value){
        if(this.update){
          this.getIssueTypes({load_id: this.load_id, category_id: this.form.category});
          this.getCustomForm(this.type.form_id);
        }else{
          if(value){
            this.setIssueTypes([]);
            this.form.type = null;
            this.getIssueTypes({load_id: this.load_id, category_id: value});
          }
        }

      },
      onTypeChange(value){
        console.log("on type change")
        let issueType = this.types.find(item => item.id == value)
        if (issueType.form_id){
          this.form.custom_fields = {};
          this.getCustomForm(issueType.form_id);
        }
      },
      getCustomForm(form_id){
        this.fetchingForm = true;
        this.getForm({form_id: form_id, load_id: this.load_id})
                .then(data=>{
                    if(!this.update){
                      for(let field of data.fields){
                          switch(field.type){
                              case "boolean":
                                  this.form.custom_fields[field.id] = field.default_value === 'true' ? true: false;
                                  break;
                              case "number":
                                  if(field.default_value){
                                      this.form.custom_fields[field.id] = Number(field.default_value);
                                  }
                                  break;
                              default:
                                  this.form.custom_fields[field.id] = null;
                                  break;
                          }
                      }
                    }

                    this.customForm =  _.cloneDeep(data);
                })
                .catch(error =>{
                    console.log(error);
                })
                .finally(()=>{
                    this.fetchingForm = false;
                });
      },
      async fetchIssueCategories(){
          this.fetchingIssue = true
          this.getIssueCategories()
              .then(data => {
                  this.selectFirstCategory = false
                  this.selectFirstCatalog = false
                  this.issue_categories = data
              })
              .catch(error => {
                  console.log(error);
              })
              .finally(()=>{
                  this.fetchingIssue = false;
              });
      },
  },
  created(){
      if(this.isReverseLogistic){
          this.issue_categories = this.issueCategories;
          this.selectFirstCategory = true;
      }else{
          this.fetchIssueCategories();
      }
      const { trackEvent } = useEventsTracker(this);
      trackEvent('add_issue_btn', 'Load details', 'click');
  },
}
</script>
<style scoped>

.delete-img{
  position: absolute;
  left: calc(50% - 10px);
  bottom: -30px;
}

.gallery_list_item img{
  width: 45px;
  height: 45px;
}

.gallery_list{
  display: flex;
  width: 100%;
  padding: 0 20px;
}
.gallery_list_item{
  margin-right: 10px;
  border: 2px solid white;
  transition: all .5s;
  border-radius: 5px;
  position: relative;
}
.gallery_list_item:hover{
  cursor: pointer;
  opacity: 1;
  border: 2px solid #2bb3e7;
}
.gallery_list_item.active{
  border: 2px solid #2bb3e7;
  opacity: 1;
}
</style>
