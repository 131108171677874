<template>
    <multiselect  v-model="selected" :options="carrierSkills" track-by="id" label="name"
                  :multiple="true" :placeholder="$t('Select')" select-label="" selected-label="">
    </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'
import {mapGetters} from 'vuex';

export default {
    name:'CarrierSkillsInput',
    components: {
        Multiselect
    },
    props: {
        value:{
            required: true
        }
    },
    data(){
        return {
            selected : undefined,
        }
    },
    computed: {
        ...mapGetters('catalogs',['carrierSkills'])
    },
    watch:{
        selected: {
            deep:true,
            handler(newValue, oldValue){
                this.$emit('input', newValue);
            }
        },
        value: {
            deep:true,
            handler(newValue){
                this.selected = newValue;
            }
        }
    },
    created(){
        this.selected = this.value;
    }
}
</script>
