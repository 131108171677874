import axios from "../../api/client";


// initial state
export const state = () => ({
    fetching: false,
    trailers : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    trailer: null,
    last_filters: {},
});

// getters
export const getters = {};

// actions
export const actions = {
    fetchTrailers({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/trailers', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
        });
    },
    getTrailers({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/trailers',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setTrailers', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    storeTrailer({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/trailers`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getTrailer({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/trailers/${params.id}`)
                .then(response=>{
                    commit('setTrailer', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateTrailer({commit, state}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/trailers/${payload.id}`, payload)
                .then(response=>{
                    let trailers = _.cloneDeep(state.trailers);
                    let index = trailers.results.findIndex(item => item.id === response.data.id);
                    if(index > -1){
                        trailers.results[index] = response.data;
                        commit('setTrailers', trailers);
                    }
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteTrailer({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/trailers/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteManyTrailers({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/trailers/bulk`,{params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    importTrailers({commit}, payload){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for(let [key, value] of Object.entries(payload)){
                formData.append(key, value);  
            }
            
            axios.post('/api/v2/trailers/import', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then((response)=>{
                    resolve(response.data);
                })
                .catch((error)=>{
                    reject(error.response);
                });
        });
    },
    exportTrailers({commit}, query){
         return new Promise((resolve, reject) => {
            axios({
                url:'/api/v2/trailers/export',
                method:'get',
                responseType: 'blob',
                params: query
                }).then(response=>{
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `trailers.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    resolve();
                })
                .catch(error =>{
                    reject(error.response)
                });
        });       
    }
};

// mutations
export const mutations = {
    setTrailers(state, data){
        state.trailers = data;
    },
    setTrailer(state, data){
        state.trailer = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

