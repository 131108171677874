<template>
    <div class="row">
      <div class="col-6">
        <div class="card mt-5">
          <div class="card-body">
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('ID')}}</div>
                                <div class="field-help">{{ resource.id }}</div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Plate')}}</div>
                                <div class="field-help">{{ resource.plate }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="field-wrapper border-bottom-none p-0">
                        <div class="field-label">{{$t('Length')}}</div>
                        <div class="field-help">{{ resource.length ? resource.length.name : '--' }}</div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Location')}}</div>
                                <div class="field-help">{{ resource.location }}</div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Truck')}}</div>
                                <div class="field-help">{{ resource.truck ? resource.truck.plate : '--' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="field-wrapper border-bottom-none p-0">
                        <div class="field-label">{{$t('Container type')}}</div>
                        <div class="field-help">{{ resource.type ? resource.type.name : '--' }}</div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Model')}}</div>
                                <div class="field-help">{{ resource.model ? resource.model: '--' }}</div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="field-wrapper border-bottom-none p-0">
                                <div class="field-label">{{$t('Brand')}}</div>
                                <div class="field-help">{{ resource.brand ? resource.brand: '--' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

    import {mapActions, mapState} from 'vuex';

    export default {
        name: "TrailerGeneral",
        components:{

        },
        data(){
            return {

            }
        },
        computed: {
            ...mapState('requirements', ['resource'])
        }
    }
</script>

<style>

</style>
