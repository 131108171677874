<template>
    <div class="container-maps">
        <div class="maps">
            <div class="search">
                <div class="field-wrapper">
                    <small>
                        {{ id == 'origin' ?
                                $t('Enter or select the pickup address') : $t('Enter or select the delivery address')
                        }}
                    </small>
                    <div class="row align-items-center ">
                        <div class="col-md-12">
                            <validation-provider :name="`${id}_line_1`" :rules="required ? 'required' : ''"
                                v-slot="{ errors }">
                                <input style="display: none" :id="`${id}_line_1`" />
                                <gmap-autocomplete ref="gmap_autocomplete" :key="form.country + '_gm'"
                                    class="form-control input" :placeholder="form.line_1 ? form.line_1 : $t('Street')"
                                    @place_changed="setPlace" @change="change"
                                    :options="{ componentRestrictions: { country: ['mx', 'us'] }, types: ['address'] }"
                                    :disabled="disabled" />
                                <b-form-invalid-feedback :state="false">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                        </div>
                    </div>
                </div>
            </div>
            <GmapMap
                :center="{ lat: form.location[0] ? Number(form.location[0]) : 19.475867, lng: form.location[1] ? Number(form.location[1]) : -99.225651 }"
                :zoom="16" :options="mapOptions" ref="mapRef" @click="onClick"
                :style="{ height: '500px', width: '100%' }">
                <template v-if="form.location[0] && form.location[1]">
                    <GmapMarker :position="{ lat: Number(form.location[0]), lng: Number(form.location[1]) }"
                        :icon="icon" :draggable="!disabled" @dragend="onDragEnd">

                        <GmapInfoWindow v-if="infoWindow" :opened="infoWindow" :options="infoWindowOptions">
                            <div v-if="loading" class="info-window">
                                <div class="header">
                                    <small class="title">Ubica el puntero en la dirección correcta</small>
                                    <span @click="closeInfoWindow()"><i class="far fa-times"></i></span>
                                </div>
                                <div class="body">
                                    <address-picker-loading />
                                </div>
                            </div>

                            <div v-if="errorCountry && !loading" class="info-window">
                                <div class="header">
                                    <small class="error">Error no se encontro una ubicacion correcta</small>
                                    <span @click="closeInfoWindow()"><i class="far fa-times"></i></span>
                                </div>
                                <div class="body">
                                    <strong>
                                        No se ha encontrado una direccion, debes mover el puntero a una ubicacion
                                        correcta
                                    </strong>
                                </div>
                            </div>

                            <div v-if="postalCodeError && !loading && !errorCountry" class="info-window">
                                <div class="header">
                                    <small class="title">Ubica el puntero en la dirección correcta</small>
                                    <span @click="closeInfoWindow()"><i class="far fa-times"></i></span>
                                </div>
                                <div class="body">
                                    <strong>
                                        No se ha encontrado una direccion, ¿esta seguro de realizar su entrega aqui?
                                    </strong>
                                </div>
                                <div class="buttom">
                                    <button @click="$emit('addressPickerNext', form)"
                                        class="btn btn-primary_v2 btn-block text-uppercase font-size-4 font-weight-bolder float-right mt-3">
                                        {{ id == 'origin' ? $t('Make collection here') : $t('Make delivery here') }}
                                    </button>
                                </div>
                            </div>

                            <div v-if="!loading && !postalCodeError && !errorCountry" class="info-window">
                                <div class="header">
                                    <small class="title">{{ $t('Put the pointer in the right direction') }}</small>
                                    <span @click="closeInfoWindow()"><i class="far fa-times"></i></span>
                                </div>
                                <div class="body">
                                    <strong>{{ formtatAddress }}</strong>
                                </div>
                                <div class="buttom">
                                    <button @click="$emit('addressPickerNext', form)"
                                        class="btn btn-primary_v2 btn-block text-uppercase font-size-4 font-weight-bolder float-right mt-3">
                                        {{ id == 'origin' ? $t('Make collection here') : $t('Make delivery here') }}
                                    </button>
                                </div>
                            </div>

                        </GmapInfoWindow>
                    </GmapMarker>
                </template>
            </GmapMap>
        </div>
        <buttons-steps :next="false" :back="true" @eventBack="$emit('addressPickerBack')" />
    </div>
</template>
    
<script>
import { mapActions } from "vuex";
/**
 * Componentes 
 */
import CountryInput from "@/components/form/CountryInput";
import ButtonsSteps from "./ButtonsSteps.vue";
import FormInput from "../form/FormInput.vue";
/**
 * Funciones generales de personalizacion
 */
import mapStyle from "@/constants/mapStyle";
import addressFormat from '@/helpers/address-format';
import AddressPickerLoading from "./loadings/AddressPickerLoading";


/**
 * Este componente tiene la responsabilidad de almacenar los datos de la direccion en el state
 * que es recibido por props
 */
export default {
    name: "AddressPickerMap",
    components: {
        AddressPickerLoading,
        CountryInput,
        ButtonsSteps,
        FormInput
    },
    props: {
        /**
         * Identificador unico del componente
         */
        id: {
            type: String,
            default() {
                return '';
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        formProp: {
            type: Object
        }
    },
    data() {
        return {
            infoWindow: true,
            infoWindowOptions: {
                pixelOffset: {
                    width: 190,
                    height: 230,
                },
            },
            required: true,
            form: this.formProp,
            street: "calle",
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                styles: mapStyle,
            },
            icon: {
                scaledSize: { width: 52, height: 67, f: 'px', b: 'px' },
                url: require('../../assets/images/txp/common/location.png'),
            },
            geocoder: null,
            loading: true,
            loadingError: false,
            streetName: '',
            addressError: false,
            errorCountry: false,
            postalCodeError: false
        }
    },
    mounted() {
        this.$refs.mapRef.$mapPromise.then((map) => {
            this.geocoder = new google.maps.Geocoder();
        });
        if (this.form.city || this.form.country || this.form.line_1) {
            this.loading = false;
            this.loadingError = false;
        }
    },
    computed: {
        formtatAddress() {
            return addressFormat(this.form).join(" ")
        }
    },
    methods: {
        ...mapActions('addresses', ['fetchPostalCodesV2']),
        /**
         * Almacena los datos recogidos por el componente y ejecuta la funcion de siguiente
         * para avanzar en el flujo de informacion
         */
        confirm() {
            this.$emit('addressPickerNext', this.form);
        },
        /**
         * Limpia los valores del autocomplete del mapa
         */
        clear() {
            this.$refs.gmap_autocomplete.$refs.input.value = "";
        },
        /**
         * Cierra el componente de infowindow en el mapa
         */
        closeInfoWindow() {
            this.infoWindow = false;
        },
        change(e) {
            this.loading = true;
            setTimeout(() => {
                if (!this.street) {
                    let form = _.cloneDeep(this.form);
                    form.line_1 = e.target.value;
                    this.form = form;
                }
            }, 500);
        },
        setPlace(place) {
            this.errorCountry = false;
            this.postalCodeError = false;
            let form = _.cloneDeep(this.form);
            form.city = null;
            form.state = null;
            form.location[0] = Number(place.geometry.location.lat().toFixed(7));
            form.location[1] = Number(place.geometry.location.lng().toFixed(7));
            form.line_1 = place.name;
            form.street = place.name;
            form.postal_code = null;
            form.country = null;
            form.locality = null;
            form.localityArray = [];
            this.form = form;
            this.setAddress(place)

        },
        async setAddress(place) {
            this.form.localityArray = [];
            let streetName = '',
                streetNumber = '',
                line_2 = '',
                line_3 = '';
            for (let element of place.address_components) {
                if (element.types.indexOf('country') > -1) {
                    this.form.country = element.short_name;
                }
                if (element.types.indexOf('route') > -1) {
                    streetName = element.long_name;
                }
                if (element.types.indexOf('street_number') > -1) {
                    streetNumber = element.long_name;
                }
                if (element.types.indexOf('neighborhood') > -1) {
                    line_2 = element.long_name;
                }
                if (element.types.indexOf('sublocality') > -1) {
                    line_3 = element.long_name;
                }
                if (element.types.indexOf('postal_code') > -1) {
                    await this.onPostalCodeChange(element.long_name);
                }
            }
            let form = _.cloneDeep(this.form);
            form.line_1 = `${streetName} ${streetNumber}`
            form.line_2 = line_2;
            form.line_3 = line_3;
            this.form = form;

            if (!this.form.country) {
                this.loading = false;
                this.errorCountry = true;
            }
            else {
                this.errorCountry = false;
            }
            if (!this.form.postal_code) {
                this.loading = false;
                this.postalCodeError = true;
            }
            else {
                this.postalCodeError = false;
            }
        },
        /**
         * Esta funcion se ejecuta cuando se necesita buscar una direccion mediante el codigo postal y de 
         * pais en el api interna
         * 
         * @param {String} codes Codigo postal de busqueda
         */
        onPostalCodeChange(codes) {
            this.form.postal_code = codes;
            this.infoWindow = true;
            this.fetchPostalCodesV2({ code: codes, country: this.form.country })
                .then(response => {
                    if (response.data.length > 0) {
                        let form = _.cloneDeep(this.form);
                        form.city = response.data[0].city;
                        form.state = response.data[0].state;
                        form.country_code = response.data[0].country_code;
                        form.localityArray = response.data.map(item => {
                            return { text: item.locality, value: item.locality }
                        });
                        form.postal_code = response.data[0].postal_code;
                        this.form = form;
                        this.loading = false;
                        this.postalCodeError = false;

                    } else {
                        this.loading = false;
                        this.postalCodeError = true;
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        /**
         * Esta funcion se ejecuta cuando el usuario arrastra el indicador hacia punto en el mapa, 
         * rellena los datos recibidos por el api de google y setea los valores en 
         * el state interno del componente
         * 
         * @todo Hay que hacer un algoritmo para hacer una seleccion de paises y realizar el request
         * en funcion de las preferencias del usuario
         * 
         * @param {Event} event Evento de arastre en el mapa
         */
        onDragEnd(event) {
            this.infoWindow = true;
            this.loading = true;
            let form = _.cloneDeep(this.form);
            form.location[0] = Number(event.latLng.lat().toFixed(7));
            form.location[1] = Number(event.latLng.lng().toFixed(7));
            form.postal_code = null;
            form.city = null;
            form.country = null;
            form.locality = null;
            form.localityArray = [];
            form.state = null;
            this.form = form;
            const componentRestrictions = {
                location: { lat: form.location[0], lng: form.location[1] },
            }
            this.geocoder.geocode(componentRestrictions,
                (results, status) => {
                    if (status === "OK" && results[0]) {
                        this.setAddress(results[0]);
                    }
                }
            );
        },
        /**
         * Esta funcion se ejecuta cuando el usuario selecciona un punto en el mapa e invoca
         * la funcion onDragEnd para setear los valores recogidos por el api de google
         * 
         * @param {Event} event Evento de seleccion de un punto en el mapa
         */
        onClick(event) {
            if (!this.form.location[0] && !this.form.location[1]) {
                this.onDragEnd(event);
            }
        },
    }
}
</script>
    
<style lang="scss">
.gm-style-iw {
    border-radius: 0px 20px 20px 20px !important;
    padding: 0 !important;
}

.gm-style-iw-tc {
    display: none !important;
}

.field-wrapper {
    border-bottom: none !important;
}

.input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #00B3E6;
    background: none;
}

.info-window {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    width: 300px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px !important;
        border-bottom: solid 1px #DDDDDD;

        .waiting {
            color: cyan;
            width: 100%;
        }

        .error {
            color: red;
            width: 100%;
        }

        .title {
            width: 100%;
            color: #000000;
            font-family: Helvetica;
            font-size: 11px;
            letter-spacing: 0;
            line-height: 13px;
            text-align: center;
        }

        small {
            height: 13px;
            width: 197px;
            color: #000000;
            font-family: Helvetica;
            font-size: 11px;
            line-height: 13px;
            text-align: center;
        }

        span {
            font-size: 2em;
            color: #979797;
        }

        span:hover {
            cursor: pointer;
        }
    }

    .body {
        padding: 1em;
        color: #000000;
        font-family: Helvetica;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .buttom {
        display: flex;
        padding: 1em;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        .btn {
            margin-top: 0 !important;
        }
    }
}

.container-maps {
    display: flex;
    flex-direction: column;

    .maps {
        position: relative;
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;

        .mapa {
            height: 100%;
        }

        .search {
            height: 100px;
            margin-top: 1em;
            width: 90%;
            z-index: 1;
            padding-top: 0 !important;
            border-radius: 20px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 10px 0 rgba(136, 136, 136, 0.5);
            position: absolute;

            small {
                height: 14px;
                width: 253px;
                color: #000000;
                font-family: Helvetica;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 14px;
            }
        }
    }
}
</style>
    