import axios from "../../api/client";


// initial state
export const state = () => ({
    fetching: false,
    users : {
        results: [],
        total: 0,
        per_page: 15,
        current_page: 1,
        last_page: 1,
        count: 0,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1
    },
    user: null,
    last_filters: {},
});

// getters
export const getters = {};

// actions
export const actions = {
    attachPermision({commit}, query){
        return new Promise((resolve, reject) => {
            axios.post('/api/v2/user/'+query.id+'/control_tower', query)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    fetchUsers({commit}, query){
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/users', {params:query})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getUsers({commit}, query){
        commit('setFetching', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/v2/users',{params: query})
                .then(response=>{
                    commit('setLastFilters', _.cloneDeep(query));
                    commit('setUsers', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                })
                .finally(()=>{
                    commit('setFetching', false);
                });
        });
    },
    storeUser({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v2/users`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    getUser({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get(`/api/v2/users/${params.id}`)
                .then(response=>{
                    commit('setUser', response.data);
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateUser({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/users/${payload.id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteUser({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/users/${payload.id}`)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    deleteManyUsers({commit}, params){
        return new Promise((resolve, reject) => {
            axios.delete(`/api/v2/users/bulk`,{params: params})
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    updateUserStatus({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.put(`/api/v2/users/${payload.id}`, payload)
                .then(response=>{
                    resolve(response.data);
                })
                .catch(error =>{
                    reject(error.response)
                });
        });
    },
    importUsers({commit}, payload){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for(let [key, value] of Object.entries(payload)){
                formData.append(key, value);  
            }

            axios.post('/api/v2/users/import', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then((response)=>{
                    resolve(response.data);
                })
                .catch((error)=>{
                    reject(error.response);
                });
        });
    },
    exportUsers({commit}, query){
         return new Promise((resolve, reject) => {
            axios({
                url:'/api/v2/users/export',
                method:'get',
                responseType: 'blob',
                params: query
                }).then(response=>{
                    const name = query.roles === 'driver' ? 'drivers' : 'users';
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `${name}.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    resolve();
                })
                .catch(error =>{
                    reject(error.response)
                });
        });       
    },
};

// mutations
export const mutations = {
    setUsers(state, data){
        state.users = data;
    },
    setUser(state, data){
        state.user = data;
    },
    setLastFilters(state, data){
        state.last_filters = data;
    },
    setFetching(state, data){
        state.fetching = data;
    }
};

