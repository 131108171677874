<template>
    <div class="navbar" :class="color">
        <div class="d-flex justify-content-center align-items-center w-90 pl-4">
            <img :src="require(`@/assets/images/${image}.png`)" class="img-responsive logo" v-if="image">
            <div class="ml-3 font-size-14 font-weight-bolder">{{title}}</div>
            <div class="badge badge-primary oval font-size-13 rounded-circle ml-2" v-if="total || total == 0">
                {{ total }}
            </div>
        </div>
        <div
            class="w-10 text-right font-size-14 font-weight-bolder text-primary"
            role="button"
            v-if="showEdit"
            @click="setStep(goToStep)"
        >
            <i class="ri-pencil-fill"></i> {{$t('Edit')}}
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    name: "Navbar",
    props: {
        color: String,
        title: String,
        image: String,
        total: Number,
        showEdit: Boolean,
        goToStep: Number,
    },
    methods: {
        ...mapMutations('recommendations', ['setStep']),
    }
}
</script>

<style scoped>
.celeste{
    background: rgba(0, 179, 230, 0.1);
}

.azufre{
    background: rgba(227, 237, 85, 0.36);
}

.logo{
    width: auto;
    height: 50px;
}

.navbar{
    height: 70px;
}

.w-10 { width: 10%; }
.w-90 { width: 90%; }

.oval {
    height: 25px;
    width: 25px;
    line-height: 17px;
    box-shadow: 0 2px 4px 0 rgba(136,136,136,0.5);
}

</style>
