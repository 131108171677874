<template>
    <button @click="$refs.import.click()" type="button" class="btn btn-light" v-b-tooltip.hover :title="$t('Import')" >
        <i class="far fa-file-import"></i>
        <input type="file" id="import" ref="import" v-on:change="handleFile()" accept=".xls,.xlsx,.csv" style="display: none" />
    </button>
</template>

<script>

export default {
    name: 'ImportData',
    props:{
        action:{
            type: Function,
            required: true
        },
        payload:{
            type: Object,
            default(){
                return {}
            }
        }
    },
    methods: {
        handleFile(){
            let payload= {template : this.$refs.import.files[0], ...this.payload};
            this.action(payload)
                .then(data=>{
                    Echo.private(`jobs.${data.job_id}`)
                        .listen('JobProcessed', (e) => {
                            this.$emit('success');
                            Echo.leave(`jobs.${data.job_id}`);
                        });
                    this.$root.$bvToast.toast(this.$t("File uploaded successfully"), {
                            title: this.$t('Success'),
                            variant: 'success',
                            solid: true
                            });
                            
                })
                .catch(error =>{

                })
                .finally(()=>{
                    this.$refs.import.value=null;
                });
        }
    }
}
</script>
